import ReactTooltip from "react-tooltip";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavigationContext} from "../../Contexts";
import HealthProfile from "./HealthProfile";

const HealthProfileDropdown = ({onChangeUserProfile,userProfile,errorMessage}) => {
    const {t} = useTranslation(['app'])
    const {isMobile} = useContext(NavigationContext)

    const [isHealthProfileOpen, setIsHealthProfileOpen] = useState(false)

    return (
        <div className={"zs-tab-card pcy-3 mcb-3 pcx-3 "}>
            <div className={isMobile ?"monitoring-title2 fs-14":"monitoring-title2"}
                 data-tip={""}
                 data-for="trainingZoneHelper"
            >
                {t('app:settings.profil.physical_form')}
            </div>
            <ReactTooltip key="tooltip-training-zone"
                          id="trainingZoneHelper"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip"
                          effect="solid"
                          place="bottom"
            />
            <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                  onClick={() => setIsHealthProfileOpen(isHealthProfileOpen => !isHealthProfileOpen)}
            >
                <i className={isHealthProfileOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
            </span>
            {isHealthProfileOpen ?
                <>
                    <div className="grey-line-2 mcy-3 pcx-3"></div>

                    <HealthProfile userProfile={userProfile}
                                   onChangeUserProfile={(field,value) => onChangeUserProfile(field,value)}
                                   errorMessage={errorMessage}
                    />
                </>
                :
                <></>
            }

        </div>
    )
}
export default  HealthProfileDropdown