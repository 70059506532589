import {
    cap_set, cap_unset, nat_set, nat_unset, other_set, other_unset, velo_set, velo_unset,
    kayak_set, muscu_set, ski_set, vtt_set,
    kayak_unset, muscu_unset, ski_unset, vtt_unset
} from "../../../img/img";
import {MetricValues, MetricUnits} from "../../../Functions/Metrics";
import {CriticalIntensityStatistics} from "../../TrainingConcept/Utils";

export function getDisciplineIconParams(isRealized, disciplineCode)
{
    let iconBgColor, disciplineIcon

    switch (disciplineCode) {
        case 'cap':
            iconBgColor = isRealized ? 'bcolor-cap' : 'cube-white'
            disciplineIcon = isRealized ? cap_set : cap_unset
            break
        case 'velo':
            iconBgColor = isRealized ? 'bcolor-velo' : 'cube-white'
            disciplineIcon = isRealized ? velo_set : velo_unset
            break
        case 'nat':
            iconBgColor = isRealized ? 'bcolor-nat' : 'cube-white'
            disciplineIcon = isRealized ? nat_set : nat_unset
            break
        case 'vtt':
            iconBgColor = isRealized ? 'bcolor-vtt' : 'cube-white'
            disciplineIcon = isRealized ? vtt_set : vtt_unset
            break
        case 'muscu':
        case 'renfo':
            iconBgColor = isRealized ? 'bcolor-muscu' : 'cube-white'
            disciplineIcon = isRealized ? muscu_set : muscu_unset
            break
        case 'ski-fond':
        case 'ski-alpin':
        case 'ski-rando':
            iconBgColor = isRealized ? 'bcolor-ski' : 'cube-white'
            disciplineIcon = isRealized ? ski_set : ski_unset
            break
        case 'kayak':
            iconBgColor = isRealized ? 'bcolor-kayak' : 'cube-white'
            disciplineIcon = isRealized ? kayak_set : kayak_unset
            break
        default:
            iconBgColor = isRealized ? 'bcolor-other' : 'cube-white'
            disciplineIcon = isRealized ? other_set : other_unset
            break
    }
    return {
        iconBgColor: iconBgColor,
        disciplineIcon: disciplineIcon
    }
}


export function formatCadenceData(cadenceMin, cadenceMax)
{
    let cadence
    if (cadenceMin && cadenceMax) {
        if(cadenceMin === cadenceMax) {
            cadence = cadenceMin
        }
    else {
            cadence = cadenceMin + '-' + cadenceMax
        }
    }
    else if (cadenceMax && cadenceMin === null) {
        cadence = '<' + cadenceMax
    }
    else if (cadenceMin && cadenceMax === null) {
        cadence = '>' + cadenceMin
    }

    return cadence
}

const pacingDataTypes = {
    TYPE_PACING_RUN : 1,
    TYPE_PACING_SWIM : 2,
    TYPE_PACING_CYCLE : 3,
    TYPE_PACING_FREQUENCY : 4
}

export function formatPacingData(pacingData, suffixes = null, withUnit = true, metricValue = MetricValues.international)
{
    let pacing
    switch(pacingData.type) {
        case pacingDataTypes.TYPE_PACING_RUN:
            pacing = pacingData.runningCriticalIntensityStatistic === CriticalIntensityStatistics.SPEED ? showRunningSpeedFromPacing(pacingData, withUnit, metricValue) : showRunningPaceFromPacing(pacingData, withUnit, metricValue)
            break
        case pacingDataTypes.TYPE_PACING_SWIM:
            pacing = showSwimmingPaceFromPacing(pacingData, withUnit, metricValue)
            break
        case pacingDataTypes.TYPE_PACING_CYCLE:
            pacing = showCyclingPowerFromPacing(pacingData, suffixes, withUnit)
            break
        case pacingDataTypes.TYPE_PACING_FREQUENCY:
            pacing = showHeartrateFromPacing(pacingData, suffixes, withUnit)
            break
        default:
            break;
    }

    return pacing;
}



function showRunningSpeedFromPacing(pacingData, withUnit = true, metricValue = MetricValues.international)
{
    let string = '';
    let unit = metricValue === MetricValues.imperial ? MetricUnits.imperial.speed_cap_velo : MetricUnits.international.speed_cap_velo;
    let unitString = withUnit ? (' '+ unit) : '';

    if(pacingData.single && pacingData.value1){
        let value1 = metricValue === MetricValues.imperial ? pacingData.value1_uk : pacingData.value1_si
        string = value1 + unitString
            + (metricValue === MetricValues.imperial ? ' (+/- 0.3mph)' : ' (+/- 0.5km/h)')
    }
    else {
        let min_value1 = pacingData.min_value1;
        let max_value1 = pacingData.max_value1;
        if(min_value1 && max_value1 ) {
            min_value1 = metricValue === MetricValues.imperial ? pacingData.min_value1_uk : pacingData.min_value1_si
            max_value1 = metricValue === MetricValues.imperial ? pacingData.max_value1_uk : pacingData.max_value1_si
            string = min_value1 + '-' + max_value1 + unitString
        }
        else if(min_value1) {
            min_value1 = metricValue === MetricValues.imperial ? pacingData.min_value1_uk : pacingData.min_value1_si
            string = '> ';
            string += min_value1 + unitString
        }
        else if(max_value1) {
            max_value1 = metricValue === MetricValues.imperial ? pacingData.max_value1_uk : pacingData.max_value1_si
            string = '< ';
            string += max_value1 + unitString
        }
    }

    return string;
}

function showRunningPaceFromPacing(pacingData, withUnit = true, metricValue = MetricValues.international)
{
    let string = '';
    let unit = metricValue === MetricValues.imperial ? MetricUnits.imperial.allure_cap_velo : MetricUnits.international.allure_cap_velo;
    let unitString = withUnit ? (' '+ unit) : '';

    if(pacingData.single && pacingData.value2){
        let value2 = metricValue === MetricValues.imperial ? pacingData.value2_uk : pacingData.value2_si
        string = value2 + unitString
        + (metricValue === MetricValues.imperial ? ' (+/- 0.3mph)' : ' (+/- 0.5km/h)')
    }
    else {
        let min_value2 = pacingData.min_value2;
        let max_value2 = pacingData.max_value2;
        if(min_value2 && max_value2 ) {
            min_value2 = metricValue === MetricValues.imperial ? pacingData.min_value2_uk : pacingData.min_value2_si
            max_value2 = metricValue === MetricValues.imperial ? pacingData.max_value2_uk : pacingData.max_value2_si
            string = min_value2 + '-' + max_value2 + unitString
        }
        else if(min_value2) {
            min_value2 = metricValue === MetricValues.imperial ? pacingData.min_value2_uk : pacingData.min_value2_si
            string = '> ';
            string += min_value2 + unitString
        }
        else if(max_value2) {
            max_value2 = metricValue === MetricValues.imperial ? pacingData.max_value2_uk : pacingData.max_value2_si
            string = '< ';
            string += max_value2 + unitString
        }
    }

    return string;
}

function showSwimmingPaceFromPacing(pacingData, withUnit = true, metricValue = MetricValues.international)
{
    let string = '';
    let unit = metricValue === MetricValues.imperial ? MetricUnits.imperial.allure_nat : MetricUnits.international.allure_nat;
    let unitString = withUnit ? (' '+ unit) : '';

    if(pacingData.single && pacingData.value2){
        let value2 = metricValue === MetricValues.imperial ? pacingData.value2_uk : pacingData.value2_si
        string = value2 + unitString
        +' (+/- 4sec)'
    }
    else {
        let min_value2 = pacingData.min_value2;
        let max_value2 = pacingData.max_value2;
        if(min_value2 && max_value2 ) {
            min_value2 = metricValue === MetricValues.imperial ? pacingData.min_value2_uk : pacingData.min_value2_si
            max_value2 = metricValue === MetricValues.imperial ? pacingData.max_value2_uk : pacingData.max_value2_si
            string = min_value2 + '-' + max_value2 + unitString
        }
        else if(min_value2) {
            min_value2 = metricValue === MetricValues.imperial ? pacingData.min_value2_uk : pacingData.min_value2_si
            string = '> ';
            string += min_value2 + unitString
        }
        else if(max_value2) {
            max_value2 = metricValue === MetricValues.imperial ? pacingData.max_value2_uk : pacingData.max_value2_si
            string = '< ';
            string += max_value2 + unitString
        }
    }

    return string;

}

function showCyclingPowerFromPacing(pacingData, suffixes = null, withUnit = true)
{
    let string = '';
    let suffix1 = suffixes !== null ? '<span class="mcl-1 font-weight-light">('+ suffixes.flat + ')</span>' : '';
    let suffix2 = suffixes !== null ? '<span class="mcl-1 font-weight-light">('+ suffixes.ascent + ')</span>' : '';
    let unitString = withUnit ? ' '+ MetricUnits.international.power_velo : '';

    if(pacingData.single){
        suffix1 = suffix2 = ''
        let value1 = pacingData.value1;
        let value2 = pacingData.value2;
        if(!(value1 || value2 )) return ''
        string = (value1 ? value1  + unitString + suffix1 : '')
            + (value1 && value2 ? '<br/>':'')
            + (value2 ? value2 + unitString + suffix2 : '')
            +' (+/- 10)'
    }
    else {
        let min_value1 = pacingData.min_value1;
        let max_value1 = pacingData.max_value1;
        let min_value2 = pacingData.min_value2;
        let max_value2 = pacingData.max_value2;
        if(min_value1 === null && min_value2 && max_value2) {
            string = min_value2 + '-' + max_value2 + unitString
        }
        else if(min_value1 && max_value1 || min_value2 && max_value2 ) {
            string = (min_value1 ? min_value1 + '-' + max_value1 + unitString + suffix1 : '')
                + (min_value1 && min_value2 ? '<br/>':'')
                + (min_value2 ? min_value2 + '-' + max_value2 + unitString + suffix2 : '');
        }
        else if(min_value1 || min_value2) {
            let prefix = '> ';
            string += (min_value1 ? prefix + min_value1 + unitString + suffix1 : '')
                + (min_value1 && min_value2 ? '<br/>':'')
                + (min_value2 ? prefix + min_value2 + unitString + suffix2 : '');
        }
    }

    return string
}

function showHeartrateFromPacing(pacingData, suffixes = null, withUnit = true)
{
    let string = '';
    let suffix1 = suffixes !== null ? '<span class="mcl-1 font-weight-light">('+ suffixes.flat + ')</span>' : '';
    let suffix2 = suffixes !== null ? '<span class="mcl-1 font-weight-light">('+ suffixes.ascent + ')</span>' : '';
    let unitString = withUnit ? ' '+ MetricUnits.international.heartrate : '';

    if(pacingData.single){
        let value1 = pacingData.value1;
        let value2 = pacingData.value2;
        if(!(value1 || value2 )) return '';
        string = (value1 ? value1  + unitString + suffix1 : '')
            + (value1 && value2 ? '<br/>':'')
            + (value2 ? value2 + unitString + suffix2 : '');
    }
    else {
        let min_value1 = pacingData.min_value1;
        let max_value1 = pacingData.max_value1;
        let min_value2 = pacingData.min_value2;
        let max_value2 = pacingData.max_value2;
        if(min_value1 && max_value1 || min_value2 && max_value2 ) {
            string = (min_value1 ? min_value1 + '-' + max_value1 + unitString + suffix1 : '')
                + (min_value1 && min_value2 ? '<br/>':'')
                + (min_value2 ? min_value2 + '-' + max_value2 + unitString + suffix2 : '');
        }
        else if(min_value1 || min_value2) {
            let prefix = '> ';
            string += (min_value1 ? prefix + min_value1 + unitString + suffix1 : '')
                + (min_value1 && min_value2 ? '<br/>':'')
                + (min_value2 ? prefix + min_value2 + unitString + suffix2 : '');
        }
        else if(max_value1 || max_value2) {
            let prefix = '< ';
            string += (max_value1 ? prefix + max_value1 + unitString + suffix1 : '')
                + (max_value1 && max_value2 ? '<br/>':'')
                + (max_value2 ? prefix + max_value2 + unitString + suffix2 : '');
        }
    }

    return string;
}

export function convertMetersToYards (meters) {
    return meters * 1.09361;
}

export function convertKilometersToMiles (kilometers) {
    return kilometers * 0.621371;
}

export function convertMilesToKilometers (miles) {
    return miles * 1.60934;
}

export function getWorkoutColors (workout) {

    let color, bcolor, opacolor, checkboxcolor
    let disciplines = workout.disciplines

    if (disciplines.length > 1) {
        color = "color-multi"
        bcolor = "btn-outline-multi bcolor-multi"
        opacolor = "color-opa-multi"
        checkboxcolor = "orange"
    } else {
        switch (disciplines[0].code) {
            case 'velo' :
                color = "color-velo"
                bcolor = "btn-outline-velo bcolor-velo"
                opacolor = "color-opa-velo"
                checkboxcolor = "green"
                break
            case 'cap' :
            case 'trail':
                color = "color-cap"
                bcolor = "btn-outline-cap bcolor-cap"
                opacolor = "color-opa-cap"
                checkboxcolor = "orange"
                break
            case 'nat' :
                color = "color-nat"
                bcolor = "btn-outline-nat bcolor-nat"
                opacolor = "color-opa-nat"
                checkboxcolor = "blue"
                break
            case 'kayak' :
                color = "color-kayak"
                bcolor = "btn-outline-kayak bcolor-kayak"
                opacolor = "color-opa-kayak"
                break
            case 'ski-fond':
            case 'ski-alpin':
            case 'ski-rando':
                color = "color-ski"
                bcolor = "btn-outline-ski bcolor-ski"
                opacolor = "color-opa-ski"
                break
            case 'muscu' :
            case 'renfo':
                color = "color-muscu"
                bcolor = "btn-outline-muscu bcolor-muscu"
                opacolor = "color-opa-muscu"
                break
            case 'vtt' :
                color = "color-vtt"
                bcolor = "btn-outline-vtt bcolor-vtt"
                opacolor = "color-opa-vtt"
                break
            default:
                color = "color-other"
                bcolor = "btn-outline-other bcolor-other"
                opacolor = "color-opa-other"
                checkboxcolor = "blue"
                break
        }
    }

    return {
        color: color,
        bcolor: bcolor,
        opacolor: opacolor,
        checkboxcolor: checkboxcolor
    }
}
