import React, {useContext, useEffect, useState} from "react";
import {validate_blue} from "../../img/img";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Loader from "react-loader-spinner";
import {AnimateOnChange} from 'react-animation'
import axios from "axios/index";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import generateHeaders from "../../Functions/generateHeaders";
import {useIsMounted} from "../Tools/CustomHooks";
import {Element as ScrollerElement, scroller} from "react-scroll";
import {DashboardContext} from "../../Contexts";

const Sponsorship = ({isSponsorshipOpen}) => {

    const {settingsAction,updateSettingsAction} = useContext(DashboardContext)

    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()

    const [nbSponsorRequest, setNbSponsorRequest] = useState(null)
    const [nbSponsorConfirmed, setNbSponsorConfirmed] = useState(null)
    const [rewardAmount, setRewardAmount] = useState(null)
    const [coupon, setCoupon] = useState(null)

    const [generatingCoupon, setGeneratingCoupon] = useState(false)

    useEffect(() => {
        getSponsorshipAccount()
    }, []);

    useEffect(() => {
        if(isSponsorshipOpen) {
            switch (settingsAction) {
                case 'subscription':
                    // Scroll down
                    scroller.scrollTo('sponsorshipPanelElement', {
                        duration: 500,
                        delay: 500,
                        smooth: 'easeOutQuart',
                        offset: 500
                    })
                    // Bounce
                    const timer = setTimeout(() => updateSettingsAction(null), 2500);
                    return () => clearTimeout(timer);

                    break;
                default:
                    break;
            }
        }
    }, [settingsAction])

    const onGenerateRewardCoupon = () => {
        if(rewardAmount && !generatingCoupon) {
            generateRewardCoupon(rewardAmount)
            setGeneratingCoupon(true)
        }
    }

    const getSponsorshipAccount = async () => {
        await axios.get(
            config+"api/my_profile/sponsorship_account",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            let dataSponsorshipAccount = response.data
            let lastValidCoupon = null
            if(dataSponsorshipAccount && dataSponsorshipAccount.coupons.length) {
                for(let couponData of dataSponsorshipAccount.coupons){
                    if(couponData.is_valid === true) {
                        lastValidCoupon = couponData
                        break;
                    }
                }

                setNbSponsorRequest(dataSponsorshipAccount.nb_sponsor_request ? dataSponsorshipAccount.nb_sponsor_request : null)
                setNbSponsorConfirmed(dataSponsorshipAccount.nb_sponsor_confirmed ? dataSponsorshipAccount.nb_sponsor_confirmed : null)
                setRewardAmount(dataSponsorshipAccount.amount ? dataSponsorshipAccount.amount : null)
                setCoupon(lastValidCoupon ? lastValidCoupon : null)
            }


        }).catch(error => {
            handleEndpointError(error)
        })
    }
    const generateRewardCoupon = async (value) => {

        await axios.post(
            config+"api/reward_coupons/new",
            {
                amount: value
            },
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
        .then(response => {
            setGeneratingCoupon(false)
            if(response.data.code) {
                getSponsorshipAccount()
            }
        })
        .catch(error => {
            console.log('generateRewardCoupon NOK', error.response)
            // TO DO: affichage du message d'erreur
            handleEndpointError(error)
            setGeneratingCoupon(false)
        })

    }



    return (
        <div>
            <ScrollerElement name="sponsorshipPanelElement"
                             className="w-100 text-left"
            >
            <h1 className="title-pref">{t('app:settings.subscription.sponsorship.sponsorship')}</h1>
            <div className=" justify-content-between pcx-3 show-input-settings mcb-3 pct-2 pcb-2 sponsorship-advantage-text">
                <p className="mct-2">{t('app:settings.subscription.sponsorship.advantages')} :</p>
                <p><strong>{t('app:settings.subscription.sponsorship.bold_advantage_1')}</strong> {t('app:settings.subscription.sponsorship.advantage_1')}</p>
                {/*<p>*/}
                    {/*<strong>{t('app:settings.subscription.sponsorship.bold_advantage_2')}</strong>*/}
                    {/*{t('app:settings.subscription.sponsorship.advantage_2')}*/}
                {/*</p>*/}
                <p>{t('app:settings.subscription.sponsorship.advantage_3')}</p>
                <p className="fw-bold">{t('app:settings.subscription.sponsorship.message')}</p>
                <Link to="/sponsorship/new">
                    <Button className="btn-sponsorship  mcb-3 justify-content-center w-100">
                        <img className="mcr-2 icon-24" src={validate_blue}/>{t('app:settings.subscription.sponsorship.btn_sponsor_a_relative')}
                    </Button>
                </Link>
            </div>
            </ScrollerElement>
            <h1 className="title-pref">{t('app:settings.subscription.sponsorship.statistics.statistics')}</h1>
            <div className="d-flex justify-content-between align-items-center pcx-3 show-input-settings mcb-3 sponsorship-advantage-text">
                <p className="text-settings fs-14">{t('app:settings.subscription.sponsorship.statistics.nb_invitations')}</p>
                <p className="subscription-item-text text-blue mb-0">{nbSponsorRequest ? nbSponsorRequest :'-'}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pcx-3 show-input-settings mcb-3 sponsorship-advantage-text">
                <p className="text-settings fs-14">{t('app:settings.subscription.sponsorship.statistics.nb_sponsors')}</p>
                <p className="subscription-item-text text-blue mb-0">{nbSponsorConfirmed ? nbSponsorConfirmed : '-'}</p>
            </div>
            <div className="d-flex justify-content-between align-items-center pcx-3 show-input-settings mcb-3 sponsorship-advantage-text">
                <p className="text-settings fs-14">{t('app:settings.subscription.sponsorship.statistics.amount_reward')}</p>
                <p className="subscription-item-text text-blue mb-0">{rewardAmount ? rewardAmount : 0}€</p>
            </div>
            {rewardAmount &&
            <Button className="btn-sponsorship mcb-3 justify-content-center w-100"
                    disabled={generatingCoupon}
                    onClick={() => onGenerateRewardCoupon()}
            >
                {!generatingCoupon ?
                    <>
                        <img className="mcr-2 icon-24" src={validate_blue}/>
                        {t('app:settings.subscription.sponsorship.statistics.btn_generate_my_coupon')}
                    </>
                    :
                    <Loader
                        type="ThreeDots"
                        color="white"
                        height={10}
                        width={40}
                    />
                }
            </Button>
            }
            <div className="d-flex justify-content-between align-items-center pcx-3 show-input-settings mct-3 mcb-3 sponsorship-advantage-text">
                <p className="text-settings fs-14">{t('app:settings.subscription.sponsorship.statistics.last_valid_coupon')}</p>
                <p className="subscription-item-text text-blue mb-0">
                    <AnimateOnChange
                        animationIn="bounceIn"
                        animationOut="bounceOut"
                        durationOut={500}
                    >
                        {coupon ? coupon.code : '-'}
                    </AnimateOnChange>
                </p>
            </div>
        </div>
    )
}

export default Sponsorship