import React, {useContext, useState, useEffect} from "react";
import {Button} from "react-bootstrap";
import {AuthContext, LoggedInContext} from "../Contexts";
import Register from "../Components/Forms/Register";
import {logo_cube, validate, validate_blue} from "../img/img";

import Login from "../Components/Forms/Login"
import {useTranslation} from "react-i18next";

const CartAuthentication = ({cart, color, updateUserAuthenticated}) => {

    const {token, updateToken} = useContext(AuthContext)
    const {username} = useContext(LoggedInContext)
    const {t} = useTranslation(['app'])

    const [authenticationType, setAuthenticationType] = useState('register')

    const onSuccess = (email) => {
        updateUserAuthenticated(email)
    }

    const switchToLogin = () => {
        setAuthenticationType('login')
    }

    const switchToRegister = () => {
        setAuthenticationType('register')
    }

    const changeUserConnected = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        localStorage.removeItem("profile")
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("username")
        sessionStorage.removeItem("profile")

        updateToken(null)
    }


    const UserConnected = () => {
        return (
            <div className="subscription-logged-card d-flex mct-3 w-100 justify-content-start">
                <div className="subscription-logged-black-cube mcr-3">
                    <img className="icon-40" alt="logo icon" src={logo_cube}/>
                </div>
                <div className="flex-column justify-content-between">
                    <div className="d-flex justify-content-start subscription-logged-email">
                        {username}
                    </div>
                    <div className="d-flex justify-content-start dotted-password">
                        .........
                    </div>
                </div>

            </div>
        )
    }
    return (
        <>
            <h4 className="text-left w-100 label-form-subscription mct-4">Identification</h4>
            <div className="w-100">
                {token && username ?
                    <div id="block-btn" className="flex-column">
                        <div className="w-100 d-flex">
                            <UserConnected />
                        </div>
                        <Button className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5'
                                onClick={() => onSuccess(username)}
                        >
                            <img className="mcr-2 icon-24" alt="valid icon" src={validate}/>
                            <p className="my-auto home-btn-p-text">{t('checkout.next')}</p>
                        </Button>
                        <div className="row d-flex justify-content-center align-items-center mcy-2">
                            <div className="grey-line mcr-2"></div>
                            <p className="mb-0 grey-line-text">ou</p>
                            <div className="grey-line mcl-2"></div>
                        </div>
                        <p className="grey-line-text mcb-2 mct-2">{t("login.not_this_account")}</p>
                        <Button className='link-btn mx-auto home-btn-white home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcb-2'
                                onClick={() => changeUserConnected()}
                        >
                            <img className="mcr-2 icon-24" alt="valid icon" src={validate_blue} />
                            <p className="my-auto home-btn-p-text">{t("login.change_account")}</p>
                        </Button>

                    </div>
                    :
                    (authenticationType == 'register' ?
                    <Register
                        useSuspense={false}
                        onSuccess={(email) => onSuccess(email)}
                        switchToLogin={() => switchToLogin()}
                    />
                    :
                    <Login
                        onSuccess={(email) => onSuccess(email)}
                        switchToRegister={() => switchToRegister()}
                    />)
                }
            </div>
        </>
    )
}
export default CartAuthentication