import React, {useContext} from 'react';

import axios from "axios";
import config from "../../api";

import {logo_garmin_training} from "../../img/img"

import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from '../../Functions/Alert';
import {NavigationContext} from "../../Contexts";


function PreferenceGarminTraining({isGarminTrainingSynced, updateIsGarminTrainingSynced}) {

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)


    const onChangeGarminTrainingSynced = () => {
        if(isGarminTrainingSynced === true) {
            // Désynchroniser
            updateIsGarminTrainingSynced(false)
            unsyncGarminTraining()
        }
        else {
            // Synchroniser
            updateIsGarminTrainingSynced(true)
            syncGarminTraining()
        }
    }

    // Synchroniser le compte Garmin Training
    const syncGarminTraining = async () => {
        await axios.get(
            config+"api/my_profile/sync_garmin_training_url",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            // Rediriger l'utilisateur vers la page de synchronisation
            window.location.href=response.data
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    // Désynchroniser le compte Garmin Training
    const unsyncGarminTraining = async () => {
        await axios.get(
            config+"api/my_profile/unsync_garmin_training",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            updateIsGarminTrainingSynced(!response.data.unsynced)
        })
        .catch(error => {
            handleEndpointError(error)
        })
    }

    let widthLogo = isMobile ? '160px': '90px'

    return (
        <>
            <div className="w-100 d-inline-flex align-items-center justify-content-between show-input-settings mcb-3">
                <img
                    src={logo_garmin_training}
                    className="settings-provider-logo pcy-2 pcy-1 align-self-center"
                    style={{width: widthLogo }}
                    alt="Garmin training logo"
                />
                <span className="toggle-switch">
                    <input type="checkbox" checked={isGarminTrainingSynced}/>
                    <span className="switch"  onClick={() => onChangeGarminTrainingSynced()} />
                </span>
            </div>
        </>
    )
}

export default PreferenceGarminTraining;