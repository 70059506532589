import React, {useMemo, useState, useEffect} from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
import {useTranslation} from "react-i18next";
import Loader from 'react-loader-spinner'
import {tic} from "../img/img";
import {animateScroll} from "react-scroll";

const useOptions = () => {
    const options = useMemo(
        () => ({
            style: {
                base: {
                    font: "12px",
                    color: "#424770",
                    letterSpacing: "0.025em",
                    fontFamily: "Source Code Pro, monospace",
                    "::placeholder": {
                        color: "#aab7c4"
                    }
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        }),
        []
    );

    return options;
};

const CardForm = ({setupIntentClientSecret, updateValidCardForm, submitForm, savePaymentMethod, reloadSetupIntent}) => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();


    const {t} = useTranslation(['app'])

    const [isSubmitted, setIsSubmitted] = useState(false)
    const [validatesFields, setValidatesFields] = useState({
        cardNumber: false,
        cardExpiration: false,
        cardCrypto: false
    })
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => {
        let isValid = true
        for(let validatesField of Object.values(validatesFields)) {
            if(validatesField === false){
                isValid = false
                break
            }
        }
        updateValidCardForm(isValid)

    }, [validatesFields])

    // useEffect(() => {
    //     setTimeout(() => {
    //         animateScroll.scrollToBottom()
    //     }, 1000)
    // }, [setupIntentClientSecret])


    useEffect(() => {
        // Envoyer la demande d'enregistrement du moyen de paiement dans stripe
        if(submitForm && setupIntentClientSecret) {
            handleSubmit()
        }
    }, [submitForm, setupIntentClientSecret])


    const handleSubmit = async () => {

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        let cardNumberElement = elements.getElement(CardNumberElement)

        // Afficher le loader
        setTimeout(()=> {
            setIsSubmitted(true)
        }, 300)

        await stripe
            .confirmCardSetup(setupIntentClientSecret, {
                payment_method: {
                    card: cardNumberElement,
                    billing_details: {
                        name: 'Titulaire de la carte',
                    },
                },
            })
            .then(result => {
                if(result.setupIntent) {
                    // Déclencher l'enregistrement du moyen de paiement pour le client dans GUTAÏ
                    let paymentMethod = result.setupIntent.payment_method
                    savePaymentMethod(paymentMethod)
                }
                else if(result.error) {
                    setErrorMessage(result.error.message)
                    setIsSubmitted(false)
                    setValidatesFields({
                        cardNumber: false,
                        cardExpiration: false,
                        cardCrypto: false
                    })
                    reloadSetupIntent()
                }

            })
            .catch(error => {
                console.log(error)
                // handleEndpointError(error)
            })
    };

    const onChangeCardNumber = (event) => {
        if (event.complete !== validatesFields.cardNumber) {
            setValidatesFields((prevCurrentFields) => {
                const newCurrentFields = {...prevCurrentFields}
                newCurrentFields.cardNumber = event.complete
                return newCurrentFields
            })
        }
    }

    const onChangeCardExpiration = (event) => {
        if (event.complete !== validatesFields.cardExpiration) {
            setValidatesFields((prevCurrentFields) => {
                const newCurrentFields = {...prevCurrentFields}
                newCurrentFields.cardExpiration = event.complete
                return newCurrentFields
            })
        }
    }

    const onChangeCardCrypto = (event) => {
        if (event.complete !== validatesFields.cardCrypto) {
            setValidatesFields((prevCurrentFields) => {
                const newCurrentFields = {...prevCurrentFields}
                newCurrentFields.cardCrypto = event.complete
                return newCurrentFields
            })
        }
    }

    return (
        <>
            {isSubmitted ?
                <Loader
                    type="TailSpin"
                    color='#007DFF'
                    height={50}
                    width={50}
                    className="my-5"
                />
            :
            <form className="w-100 flex-column align-items-start">
                {errorMessage &&
                <p className="stripe-card-error">{errorMessage}</p>
                }
                <div className="form-input-height form-input-text d-flex align-items-end mcb-3">
                    <div className="h-100 w-100">
                        <p className="mcb-2 text-left little-text-stripe">{t('app:settings.subscription.card_number')} *</p>
                        <label className="w-100 flex-column align-items-start mb-0">
                            <CardNumberElement
                                options={options}
                                className="h-100 flex-column align-items-end justify-content-end"
                                onChange={event => {
                                    onChangeCardNumber(event)
                                }}
                            />
                        </label>
                    </div>
                    {validatesFields.cardNumber &&
                    <div className="d-flex justify-content-end align-self-center w-25">
                        <img
                            src={tic}
                            alt="tic picto"
                            className="icon-24"
                        />
                    </div>
                    }
                </div>
                <div className="form-input-height form-input-text d-flex align-items-end mcb-3">
                    <div className="h-100 w-75">
                        <p className="mcb-2 text-left little-text-stripe">{t('app:settings.subscription.card_expiration')} *</p>
                        <label className="w-100 flex-column align-items-start mb-0">
                            <CardExpiryElement
                                options={options}
                                className="h-100 flex-column align-items-end justify-content-end"
                                onChange={event => {
                                    onChangeCardExpiration(event)
                                }}
                            />
                        </label>
                    </div>
                    {validatesFields.cardExpiration &&
                    <div className="d-flex justify-content-end align-self-center w-25">
                        <img
                            src={tic}
                            alt="tic picto"
                            className="icon-24"
                        />
                    </div>
                    }
                </div>
                <div className="form-input-height form-input-text d-flex align-items-end mcb-3">
                    <div className="h-100 w-75">
                        <p className="mcb-2 text-left little-text-stripe">CVC *</p>
                        <label className="w-100 flex-column align-items-start mb-0">
                            <CardCvcElement
                                options={options}
                                className="h-100 flex-column align-items-end justify-content-end"
                                onChange={event => {
                                    onChangeCardCrypto(event)
                                }}
                            />
                        </label>
                    </div>
                    {validatesFields.cardCrypto &&
                    <div className="d-flex justify-content-end align-self-center w-25">
                        <img
                            src={tic}
                            alt="tic picto"
                            className="icon-24"
                        />
                    </div>
                    }
                </div>

            </form>
            }
        </>
    );
};

export default CardForm;
