import React, {useState, useEffect} from 'react';
import CustomSelect from "../Tools/CustomSelect"

const SelectDiscipline = ({disciplines, defaultDiscipline, onChange}) => {

    const [value, setValue] = useState(defaultDiscipline)
    const [defaultValue, setDefaultValue] = useState(defaultDiscipline)

    // Sélectionner la valeur par défaut lors de la mise à jour de la liste
    useEffect(() => {
        if(disciplines && disciplines.length > 0) {
            let disciplineValue = defaultDiscipline ? defaultDiscipline : disciplines[0]
            setDefaultValue(disciplineValue)
            onChangeValue(disciplineValue)
        }
    }, [disciplines, defaultDiscipline])

    const onChangeValue = (value) => {
        setValue(value)
        onChange(value)
    }

    if(disciplines === null || disciplines.length === 0 || !defaultValue)
        return (<>Aucune discipline</>)

    return (
        <CustomSelect
            className="w-100 text-left"
            name="discipline"
            options={disciplines}
            defaultValue={defaultValue}
            value={value}
            onChange={(value) => onChangeValue(value)}
        />
    )
}

export default SelectDiscipline;