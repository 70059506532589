import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../Components/Tools/CustomHooks";
import {HeaderHome} from "../../src/Components/Home/HeaderHome";
import {helpCircle} from "../../src/img/img";
import {AuthContext, LanguageContext, NavigationContext} from "../Contexts";
import '../Css/subscription.css';
import Checkout from "./Checkout";
import {scroller, Element as ScrollerElement } from "react-scroll";
import queryString from 'query-string';

import TrainingProgram from "../SearchTrainingProgram/TrainingProgram";
import axios from "axios/index";
import config from "../api";
import handleEndpointError from "../Functions/Alert";
import generateHeaders from "../Functions/generateHeaders";
import {format as formatDate} from "date-fns";
import Loader from "react-loader-spinner";
import Button from "react-bootstrap/Button";

import {updateLanguageFromSearch} from "../Functions/Utils";

import ReactPixel from 'react-facebook-pixel';


const SubscriptionProgram = (props) => {


    const {t} = useTranslation(['app']);
    const isMounted = useIsMounted()

    const {isMobile} = useContext(NavigationContext)
    const {token} = useContext(AuthContext)
    const {language, updateLanguage} = useContext(LanguageContext)

    const {themeValue} = props.match.params

    const {coupon} = queryString.parse(props.location.search)

    const [type, setType] = useState(null)
    const [sport, setSport] = useState(null)
    const [backgroundDesktopStyle, setBackgroundDesktopStyle] = useState(null)
    const [backgroundMobileStyle, setBackgroundMobileStyle] = useState(null)
    const [themeDatas, setThemeDatas] = useState(null)
    const [displaySummary, setDisplaySummary] = useState(false)
    const [displayErrorDate, setDisplayErrorDate] = useState(false)
    const [summary, setSummary] = useState(null)

    const [trainingProgram, setTrainingProgram] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [cart, setCart] = useState({
        id: null,
        reduction_amount: 0,
        original_price: null,
        confirmation_price: null,
        coupon: coupon ? coupon : null, // Prise en compte du coupon indiqué dans l'url d'appel
        currency_symbol: null,
        recurring: null
    })

    const [loaded, setLoaded] = useState(false)

    const [purchaseId, setPurchaseId] = useState(null)
    const [purchaseAmountData, setPurchaseAmountData] = useState(null)

    // Affilae
    var _ae = {'pid': process.env.REACT_APP_AFFILAE_TAG_ID};

    useEffect(() => {
        if((window.location.host).indexOf("web.gutai.training") !== -1) {
            // Google Tag Manager
            window.dataLayer.push({
                event: 'pageview'
            });
        }

        // Affilae
        (function() {
            var element = document.createElement('script');
            element.type = 'text/javascript';
            element.async = true;
            element.src = '//static.affilae.com/ae-v3.5.js';

            // var scr = document.getElementsByTagName('script')[0];
            // scr.parentNode.insertBefore(element, scr);
            document.body.appendChild(element)
        })();

    }, [])

    useEffect(() => {
        // Afficher le formulaire d'achat
        setDisplaySummary(trainingProgram ? true : false)
        // Créer ou mettre à jour le panier d'achat
        if(trainingProgram) {
            if(cart.id === null) {
                newCartTraining20(trainingProgram.id)
            }
            else {
                editCartTraining20(trainingProgram.id)
            }
        }


    }, [trainingProgram])

    useEffect(() =>  {
        // Mettre à jour la langue en prenant en compte l'url lorsque l'utilisateur n'est pas connecté
        if(token === null) {
            updateLanguageFromSearch(updateLanguage, props.location.search)
        }
    }, [token])

    useEffect(() => {
        // Scroller vers le formulaire d'achat
        if(displaySummary && cart.id !== null) {
            scroller.scrollTo('cartElement', {
                duration: 1000,
                delay: 50,
                smooth: 'easeOutQuart',
                offset: 50
            })
        }
    }, [displaySummary, cart.id])

    useEffect(() => {
        getTrainingProgramTheme()
    }, [language])


    const getTrainingProgramTheme = async () => {
        await axios.get(
            config+"public/api/training_program_themes/"+themeValue,
            {
                params: {
                    locale: language
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            let theme = response.data

            setType(theme.type)
            setSport(theme.sport)

            let datas = {
                title : theme.title,
                subtitle: theme.subtitle,
                baseline: theme.baseline,
                cta_url: theme.website_url,
                image_mobile_url : theme.mobile_image_url,
                image_desktop_url : theme.desktop_image_url
            }
            let styleDesktop = {
                background: `url(${datas.image_desktop_url}) no-repeat top left`,
                backgroundSize: "50% auto"
            }

            let styleMobile = {
                background: `url(${datas.image_mobile_url}) no-repeat`,
                backgroundSize: "100% 100%"
            }

            setBackgroundDesktopStyle(styleDesktop)
            setBackgroundMobileStyle(styleMobile)
            setThemeDatas(datas)
            setLoaded(true)
        }).catch(error => {
            handleEndpointError(error)
        })
    }

    const newCartTraining20 = async (trainingProgramId) => {
        await axios.post(
            config+"public/api/cart/new/training_20/"+trainingProgramId,
            {
                start_date: formatDate(startDate, 'yyyy-MM-dd'),
                locale: language
            },
        )
        .then(response => {
            if(!isMounted.current){
                return 0;
            }
            let data = response.data

            setCart({
                id: data.id,
                reduction_amount: data.reduction_amount,
                original_price: data.original_price,
                confirmation_price: data.confirmation_price,
                coupon: data.coupon,
                currency_symbol: data.currency_symbol,
                recurring: data.recurring
            })

            // let fbPixel = process.env.REACT_APP_FACEBOOK_PIXEL_KEY_TEST
            let fbPixel = process.env.REACT_APP_FACEBOOK_PIXEL_KEY
            ReactPixel.init(fbPixel, {}, {
                autoConfig: true,
                debug: false
            });
            ReactPixel.pageView();



        })
        .catch(error => {
            handleEndpointError(error)
        })
    }


    const editCartTraining20 = async (trainingProgramId) => {
        await axios.put(
            config+"public/api/cart/"+ cart.id +"/training_20/"+trainingProgramId,
            {
                locale: language
            },
        )
        .then(response => {
            if(!isMounted.current){
                return 0;
            }
            // Récupérer les données du panier d'achat
            updateCart()
        })
        .catch(error => {
            handleEndpointError(error)
        })
    }

    const updateCart = async () => {
        await axios.get(
            config+"public/api/cart/"+cart.id
        )
        .then(response => {
            if(!isMounted.current){
                return 0;
            }
            let data = response.data
            setCart({
                id: data.id,
                reduction_amount: data.reduction_amount,
                original_price: data.original_price,
                confirmation_price: data.confirmation_price,
                coupon: data.coupon,
                currency_symbol: data.currency_symbol,
                recurring: data.recurring
            })
        })
        .catch(error => {
            handleEndpointError(error)
        })
    }

    const purchaseCart = async () => {
        await axios.post(
            config+"api/cart/"+ cart.id +"/purchase",
            {
                start_date: formatDate(startDate, 'yyyy-MM-dd')
            },
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
        .then(response => {
            // Enregistrer le numéro de l'achat créé
            let purchaseData = response.data
            setPurchaseId(purchaseData.id)
            setPurchaseAmountData({
                amount_without_tax: purchaseData.amount_without_tax,
                currency: purchaseData.currency,
                coupon: purchaseData.coupon
            })
        })
        .catch(error => {
            handleEndpointError(error)
        })
    }


    const onChangeTrainingProgram = (value) => {
        setTrainingProgram(value)
    }
    const onChangeStartDate = (value) => {
        let currentDate = new Date()
        setStartDate(value)
        setDisplayErrorDate(value < currentDate)
    }

    const onChangeSummary = (value) => {
        setSummary(value)
    }

    const infosCard = () => {
        return (
            <div className={`${isMobile ? "" : "infos-card-desktop "}infos-card pcy-3 pcx-4`}>
                <p className={`infos-card-title${isMobile ? "-mobile" : ""} mcb-3`}>{themeDatas.title}</p>
                <p className={`infos-card-text${isMobile ? "-mobile" : ""} mcb-3`}>{themeDatas.subtitle}</p>
                <p className={`infos-card-text${isMobile ? "-mobile" : ""} mcb-3`}>{themeDatas.baseline}</p>
                <a className="infos-card-link" href={themeDatas.cta_url+(language === 'en' ? '?lang=en':'')}>
                    <Button className="infos-card-btn pcy-2 pcx-3">{t('athlete.training_load.more_information')}</Button>
                </a>
            </div>
        )
    }

    return (
        <>
            {loaded ?
                <div className={isMobile ? "" : "d-flex justify-content-end min-vh-100 "} style={isMobile ? {} : backgroundDesktopStyle}>
                    {!isMobile &&
                        infosCard()
                    }
                    <div className={isMobile ?
                        "pc-4 d-flex flex-column justify-content-center align-items-center container" :
                        "w-40 pc-4 d-flex flex-column align-items-center container-desktop"}>
                        <HeaderHome withoutLanguageSwitch={token ? 1 :0}/>
                        {isMobile ?
                            <div className="vw-100 pc-4" style={backgroundMobileStyle} >
                                {infosCard()}
                            </div>

                            :
                            ""
                        }
                        <h3 className= "text-left w-100 mt-5 subscription-title">{t('settings.subscription.define_your')} <span style={{textDecoration:'underline'}}>PROGRAM</span> {isMobile ? "":<img className="icon-24 mb-2" src={helpCircle}/>}</h3>
                        <p className= "text-left w-100 text-grey">{t('settings.subscription.fill_fields')} :</p>
                        {sport &&
                        <TrainingProgram
                            title={"PROGRAM " + themeDatas.title}
                            theme={themeValue}
                            type={type}
                            sport={sport}
                            onChangeTrainingProgram={(trainingProgram) => onChangeTrainingProgram(trainingProgram)}
                            onChangeStartDate={(startDate) => onChangeStartDate(startDate)}
                            onChangeSummary={(summary) => onChangeSummary(summary)}
                        />
                        }
                        {displaySummary ?
                            displayErrorDate ?
                                <div className="form-checkout-blue form-checkout">
                                    <h3 className="w-100 fs-16 subscription-title">{t('cart.not_available')}</h3>
                                    <div className="fs-12 text-left">
                                        <p>{t('cart.modify_duration')}</p>
                                    </div>
                                </div>
                                :
                                <React.Fragment key="cart-summary">
                                    <h3 className={isMobile ? "w-100 subscription-title": "w-100 subscription-title text-left"}>{t('cart.recap')}</h3>
                                    <div className="row d-flex">
                                        <div className="col-12 text-left p-0">
                                            {Object.entries(summary).map((enabledFilter,index) => {
                                                if(enabledFilter[1] === null) {
                                                    return <React.Fragment key={"no-param-"+index}></React.Fragment>
                                                }

                                                let name = enabledFilter[0] === 'title' ? enabledFilter[1] : enabledFilter[1].label
                                                return (
                                                    name ?
                                                        <span key={"param-"+index}
                                                              className={"btn-checkout-blue mr-2 mct-2 d-inline-block label-summary"}
                                                              style={{ fontWeight: 'bold'}}
                                                        >
                                                            {name}
                                                        </span>
                                                        :
                                                        <React.Fragment key={"no-label-"+index}></React.Fragment>

                                                )
                                            })}

                                        </div>
                                    </div>
                                    <ScrollerElement name="cartElement"
                                                     className="w-100"
                                    >
                                        <Checkout
                                            type="training_program"
                                            color="blue"
                                            cart={cart}
                                            updateCart={() => updateCart()}
                                            purchaseCart={() => purchaseCart()}
                                            purchaseId={purchaseId}
                                            purchaseAmountData={purchaseAmountData}
                                        />
                                    </ScrollerElement>
                                </React.Fragment>
                            :
                            <div className="pc-5"></div>
                        }
                    </div>
                </div>
                :
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={50}
                    width={50}
                    className="my-5"
                />
            }
        </>
    )
}
export default SubscriptionProgram