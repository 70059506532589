import React, {useContext, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {HeaderHome} from "../Components/Home/HeaderHome";
import {NavigationContext} from "../Contexts";
import {Button} from "react-bootstrap";
import SponsorForm from "./SponsorForm";
import {useTranslation} from "react-i18next";
import Loader from "react-loader-spinner";
import axios from "axios/index";
import config from "../api";
import handleEndpointError from "../Functions/Alert";
import generateHeaders from "../Functions/generateHeaders";

const NewSponsorship = (props) => {
    const {isMobile} = useContext(NavigationContext)
    const {t} = useTranslation(['app'])

    let history = useHistory()

    const newSponsor = {
        name: "",
        firstname: "",
        gender: null,
        email: "",
        emailOk: false,
        valid: false
    }

    const [isSubmitable, setIsSubmitable] = useState(false)
    const [sponsors, setSponsors] = useState([newSponsor])
    const [nbSubmitted, setNbSubmitted] = useState(0)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        for (let i=0; i<sponsors.length; i++) {
            if (sponsors[i].valid) {
                setIsSubmitable(true)
            } else {
                setIsSubmitable(false)
                return
            }
        }

    }, [sponsors]);

    useEffect(() => {
        if(sponsors.length && sponsors.length === nbSubmitted) {
            // redirection vers la page de confirmation
            history.push("/sponsorship/confirmation")
        }
    }, [nbSubmitted, sponsors])


    const onChangeSponsor = (index, value) => {
        setSponsors(oldArray => {
            let newArray = [...oldArray]
            newArray[index] = value
            newArray[index].valid = newArray[index].name !== "" && newArray[index].firstname !== "" && newArray[index].gender !== null && newArray[index].emailOk;
            return newArray
        })
    }

    const addSponsor = () => {
        setSponsors(oldArray => [...oldArray, newSponsor])
    }
    const onRemoveSponsor = (index) => {
        console.log('onRemoveSponsor', index)
        setSponsors(oldArray => {
            let newArray = [...oldArray]
            newArray.splice(index,1)
            return newArray
        })
    }


    const submitAllSponsor = () => {
        setLoading(true)
        for(let sponsor of sponsors) {
            postSponsorship(sponsor)
        }
    }

    const postSponsorship = async (sponsor) => {

        await axios.post(
            config+"api/sponsorships/new",
            {
                email: sponsor.email,
                firstname: sponsor.firstname,
                lastname: sponsor.name,
                gender: sponsor.gender
            },
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
            .then(response => {
                setNbSubmitted(nbSubmitted => nbSubmitted + 1)
            })
            .catch(error => {
                console.log('postSponsorship NOK', [sponsor, error.response])
                // TO DO: affichage du message d'erreur
                handleEndpointError(error)

                setNbSubmitted(nbSubmitted => nbSubmitted + 1)
            })

    }

    return (
        <div className={isMobile ? "" : "d-flex justify-content-end background-sponsorship min-vh-100"}>
            <div className={"pc-4 mcb-5 d-flex flex-column justify-content-center "+ (isMobile ? "container" : " w-30 container-desktop")}>
                <HeaderHome withoutLanguageSwitch={1} />
                <h1 className={isMobile ? "pcb-5 mct-3 sponsorship-title-mobile" :"pcb-5 mct-3 sponsorship-title"}>{t('app:sponsorship.new_sponsorship.sponsor_your_relative')}</h1>
                {loading ?
                    <div className="w-100 justify-content-center align-items-center d-flex py-5" >
                        <Loader
                            type="Hearts"
                            color="red"
                            height={80}
                            width={80}
                        />
                    </div>
                    :
                    <>
                        {sponsors && sponsors.map((sponsor, index) => {
                            return <SponsorForm
                                sponsor={sponsor}
                                index={index}
                                onChangeSponsor={(value) => onChangeSponsor(index, value)}
                                isRemovable={sponsors.length > 1 ? true : false}
                                onRemoveSponsor={(value) => onRemoveSponsor(value)}

                            />
                        })}
                        <p className="add-new-filleul-link text-left" onClick={() => addSponsor()}>
                            <i className="bi bi-plus-circle pr-2"/>{t('app:sponsorship.new_sponsorship.add_new_sponsor')}
                        </p>

                        <hr/>
                        <p className="sponsorship-text text-left">{t('app:sponsorship.new_sponsorship.required_fields')}</p>
                        <div className="mct-3">
                            <Button className="sponsorship-btn"
                                    disabled={!isSubmitable}
                                    onClick={() => submitAllSponsor()}
                            >
                                <i className="bi bi-share pcr-3"/>{t('app:sponsorship.new_sponsorship.btn_sponsor')}
                            </Button>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default NewSponsorship