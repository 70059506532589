import React from "react";
import {useTranslation} from "react-i18next";
import "../../Css/TrainingLoad.css"
import {
    brace_down
} from "../../img/img";
import TrainingLoadBar from "./TrainingLoadBar";


const TrainingLoadMore = ({levelsString, weekRatios, loading, scoreStyle, score, average}) => {
    const {t} = useTranslation(['app'])

    const getColsBars = (weekRatios, loading) => {
        let cols = []
        for (let i=4; i>=0; i--) {
            cols.push(
                <div className="p-0 col d-flex flex-column align-items-center">
                    <span className="average-training-load mcb-1">
                        {i === 0 ?
                            t('app.date.today')
                            :
                            t('app.date.week_initial')+weekRatios[i].week_number
                        }
                    </span>
                    <TrainingLoadBar levelsString={levelsString} level={weekRatios[i].level} loading={loading} more={true}/>
                    <span className="mct-1 value-training-load">{parseInt(weekRatios[i].value)} U</span>
                    {i===0 &&
                    <span className={scoreStyle+ " score-training-load-more"} >
                        {score !== null && score>=0 && "+"}{score ? score : <>&nbsp;</>}
                        {score !== null && '%'}
                        </span>
                    }

                </div>
            )
        }
        return cols
    }

    return (
        <>
            {getColsBars(weekRatios, loading)}
            <div className="row">
                <div className="brace-down">
                    <img src={brace_down} alt="brace down img" className="w-100"/>
                </div>
                <div className="pct-2 d-flex justify-content-center" style={{width : "80%"}}>
                    <span className="average-training-load">{t('app:athlete.training_load.average')} {t('app.date.week_initial')+weekRatios[4].week_number+"-"+weekRatios[1].week_number}</span>
                </div>
                <div className="d-flex justify-content-center" style={{width : "80%"}}>
                    <span className="value-training-load">{average} U</span>
                </div>
            </div>
        </>

    )
}

export default TrainingLoadMore