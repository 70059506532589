import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import {Link, Redirect} from "react-router-dom";
import {Button} from "react-bootstrap";

import {validate, gutai_login_desktop} from '../img/img'
import '../Css/Home.css';

import LanguageSwitch from "../Components/LanguageSwitch";
import {AuthContext, NavigationContext} from "../Contexts";

const Home = () => {
    const {t} = useTranslation(['app'])

    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    const {token} = useContext(AuthContext)
    if(token) {
        return <Redirect to="/dashboard"/>
    }

    return (
        <div className="home-container vh-100 d-flex flex-column justify-content-center align-items-center">
            <LanguageSwitch />
            <div>
                <img
                    src={gutai_login_desktop}
                    className={isMobile ? "logo-gutai-home-mobile" : "logo-gutai-home"}
                    alt="Gutai logo"
                />
            </div>
            <div className="mct-5">
                <Link to={"/login"} className="text-decoration-none">
                    <Button className='home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center'>
                        <img className="mcr-2 icon-24" alt="valid icon" src={validate} /><p className="my-auto home-btn-p-text">{t('app:login.btn')}</p>
                    </Button>
                </Link>
                <div className="row d-flex justify-content-center align-items-center mcy-3">
                    <div className="grey-line mcr-2"></div>
                    <p className="mb-0 grey-line-text">{t('app:login.or')}</p>
                    <div className="grey-line mcl-2"></div>
                </div>
                <Link to={"/registration"} className="text-decoration-none">
                    <Button className='home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcb-5'>
                        <img className="mcr-2 icon-24" alt="valid icon" src={validate} /><p className="my-auto home-btn-p-text">{t('app:register.btn')}</p>
                    </Button>
                </Link>
            </div>
        </div>
    )
}

export default Home

