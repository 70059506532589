import React, {useEffect, useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import Loader from "react-loader-spinner";
import "../../Css/TrainingLoad.css"
import {
    alert_triangle,
    arrow_down_right,
    arrow_right,
    arrow_up_right,
    helpCircle,
    trending_down,
    trending_up
} from "../../img/img";
import axios from "axios/index";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import ReactTooltip from "react-tooltip";
import TrainingLoadMore from "./TrainingLoadMore";
import {NavigationContext} from "../../Contexts";


const TrainingLoad = (currentDate) => {
    const {t} = useTranslation(['app'])

    const {isMobile, isTablet} = useContext(NavigationContext)
    // Niveau de charge d'entraînement
    const levelsString = ['too_low', 'low', 'ideal', 'high', 'intense','too_high']

    // const [score, setScore] = useState(null)
    const [level, setLevel] = useState(null)
    const [average, setAverage] = useState(null)
    const [score, setScore] = useState(null)
    const [weekRatios, setWeekRatios] = useState(null)
    const [message1, setMessage1] = useState(null)
    const [message2, setMessage2] = useState(null)
    const [loading, setLoading] = useState(true)

    const [scoreStyle, setScoreStyle] = useState("color-too-low")
    const [scoreIcon, setScoreIcon] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [isInit, setIsInit] = useState(false)


    useEffect(() => {
        if (isOpen && !isInit) {
            getTrainingLoadRatio()
            setIsInit(true)
            setLoading(true)
        }
    }, [isOpen])

    useEffect(() => {
        // Ne rien modifier lors du chargement
        if(!loading) {
            let colorStyle = ""
            let icon = null
            let levelPosition = levelsString.indexOf(level)

            if(levelPosition > -1) {
                colorStyle = "color-too-low"
                icon = trending_down
            }
            if(levelPosition > 0) {
                colorStyle = "color-low"
                icon = arrow_down_right
            }
            if(levelPosition > 1) {
                colorStyle = "color-ideal"
                icon = arrow_right
            }
            if(levelPosition > 2) {
                colorStyle = "color-high"
                icon = arrow_up_right
            }
            if(levelPosition > 3) {
                colorStyle = "color-intense"
                icon = trending_up
            }
            if(levelPosition > 4) {
                colorStyle = "color-too-high"
                icon = alert_triangle
            }

            setScoreStyle(colorStyle)
            setScoreIcon(icon)
        }
    }, [loading])

    const getDataTip = () => {
        return (
            <div className="fs-12 text-left">
                <p>{t('app:athlete.training_load.show_more_content.0')}</p>
                <p>{t('app:athlete.training_load.show_more_content.1')}</p>
                <p>{t('app:athlete.training_load.show_more_content.2')}</p>
                <p>{t('app:athlete.training_load.show_more_content.3')}</p>
            </div>
        )
    }

    const getTrainingLoadRatio = async () => {
        await axios.get(
            config+"api/my_training_load/ratio",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(async (response) => {
            let trainingLoad = response.data
            let ratios = trainingLoad.training_load_ratio
            if(trainingLoad.no_activity) {
                setMessage1(t('athlete.training_load.no_activity_message'))
                setMessage2(null)
                setLevel(null)
                setScore(null)
            }
            else {
                setMessage1(trainingLoad.messages[0])
                setMessage2(trainingLoad.messages[1])
                setLevel(ratios.current.level)
                setScore(parseInt(ratios.current.score))
            }

            let weekRatiosArray = [
                {level: ratios.current.level, value: ratios.current.value, week_number: ratios.current.week_number},
                {level: ratios.before_1.level, value: ratios.before_1.value, week_number: ratios.before_1.week_number},
                {level: ratios.before_2.level, value: ratios.before_2.value, week_number: ratios.before_2.week_number},
                {level: ratios.before_3.level, value: ratios.before_3.value, week_number: ratios.before_3.week_number},
                {level: ratios.before_4.level, value: ratios.before_4.value, week_number: ratios.before_4.week_number}
            ]
            setWeekRatios(weekRatiosArray)

            setAverage(Math.round(trainingLoad.average))
            setLoading(false)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    return (
        <div className="dashboard-card pc-3 mcb-4">
            <div className="d-flex justify-content-start"
            >
                <p className={"monitoring-title mb-0 fs-14"}>
                    {t('app:athlete.training_load.training_load')}
                </p>
                <img
                    src={helpCircle} className={(!isMobile && !isTablet ? 'ml-1':'ml-3')+" icon-24"}
                    alt="help icon"
                    data-tip={""}
                    data-for="trainingLoadHelper"
                    data-event='click'
                />
            </div>
            <ReactTooltip key="tooltip-training-load"
                          id="trainingLoadHelper"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip"
                          effect="solid"
                          place="bottom"
                          getContent={() => getDataTip()}
            />
            <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                  onClick={() => setIsOpen(isOpen => !isOpen)}
            >
                <i className={isOpen ? "bi bi-chevron-up": "bi bi-chevron-down"} />
            </span>
            {isOpen &&
            <>
                <div className={"my-3"}>
                    {loading ?
                        <Loader
                            type="TailSpin"
                            color="#0069d9"
                            height={50}
                            width={50}
                            className="m-auto"
                        />
                        :
                        <>
                            <div className="p-0 mct-2">
                                <p className="text-training-load text-left">
                                    {scoreIcon &&
                                    <img src={scoreIcon} alt="score icon" className="icon-24 mcr-2"/>
                                    }
                                    {message1}. {message2}
                                </p>
                            </div>
                            <div className="row mx-0">
                                <TrainingLoadMore
                                    levelsString={levelsString}
                                    weekRatios={weekRatios}
                                    loading={loading}
                                    scoreStyle={scoreStyle}
                                    score={score}
                                    average={average}
                                />
                            </div>
                        </>

                    }
                </div>
            </>
            }
        </div>
    )
}

export default TrainingLoad