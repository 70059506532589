import React, {useContext, useEffect, useState} from 'react';
import {getDisciplineAnalyzable} from "../Discipline/Utils";
import TrainingConceptDiscipline from "./TrainingConceptDiscipline";
import TrainingConceptDisciplineConfig from "./TrainingConceptDisciplineConfig";
import CriticalIntensityStatistic from "./CriticalIntensityStatistic";
import {PrioritySportContext} from "../../Contexts";
import {cap_set, nat_set, velo_set} from "../../img/img";

const TrainingConceptManager = ({submitRunningCIStatistic}) => {
    const {prioritySport} = useContext(PrioritySportContext)
    const [discipline, setDiscipline] = useState(null)
    const disciplines = getDisciplineAnalyzable()


    useEffect(() => {
        if (prioritySport) {
            setDiscipline(prioritySport)
        } else {
            setDiscipline(disciplines[0])
        }
    }, [prioritySport])

    const onChangeDiscipline = (disciplineToSet) => {
        setDiscipline(disciplineToSet)
    }

    const updateRunningCIStatistic = (value) => {
        if(discipline === 'cap') {
            submitRunningCIStatistic(value)
        }
    }

    return (
        <div className=" mct-3 pcb-1">
            <TrainingConceptDiscipline discipline={discipline}
                                       onChangeDiscipline={(discipline) => onChangeDiscipline(discipline)}
                                       disciplines={disciplines}
            />
            {discipline === "cap" &&
                <CriticalIntensityStatistic discipline={discipline}
                                            updateRunningCIStatistic={(value) => updateRunningCIStatistic(value)}
                />
            }

            <TrainingConceptDisciplineConfig discipline={discipline}/>
        </div>
    );
};

export default TrainingConceptManager;
