import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../../Tools/CustomHooks";
import {Button} from "react-bootstrap";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";
import {validate} from "../../../img/img";
import Loader from "react-loader-spinner";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {format, formatISO, isAfter, isBefore, setSeconds} from "date-fns"
import {utcToZonedTime} from 'date-fns-tz'
import moment from "moment-timezone";

import {NavigationContext, WeekCalendarContext} from "../../../Contexts";
import AthleteAvailibilityContexts from "./AthleteAvailibilityContexts";



/**
 * Afficher un formulaire de création de Compte-rendu
 *
 * @param date
 */
const EditAthleteAvailability = ({date, closeAthleteAvailabilityModal}) => {
    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()

    const {updateCalendarEventToRefresh, athleteAvailabilityEventHandled, updateAthleteAvailabilityEventHandled} = useContext(WeekCalendarContext)

    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    // Liste des disciplines
    const disciplineCodes = ["nat","velo","cap"]
    const [disciplines, setDisciplines] = useState(null)
    // Liste des contextes pour Natation
    const [swimmingContexts, setSwimmingContexts] = useState(null)
    // Liste des contextes pour Vélo
    const [cyclingContexts, setCyclingContexts] = useState(null)
    // Liste des contextes pour Course à pied
    const [runningContexts, setRunningContexts] = useState(null)

    const [startDate, setStartDate] = useState(utcToZonedTime(new Date(athleteAvailabilityEventHandled.start_date), 'Europe/Paris'))
    const [endDate, setEndDate] = useState(utcToZonedTime(new Date(athleteAvailabilityEventHandled.end_date), 'Europe/Paris'))
    const [allDay, setAllDay] = useState(athleteAvailabilityEventHandled.all_day)
    const [contexts, setContexts] = useState([])
    const [dayName, setDayName] = useState(null)

    const [errorMessage, setErrorMessage] = useState()
    const [ready, setReady] = useState(false)

    const pickerRef = useRef(null)
    const [requiredFields, setRequiredFields] = useState({
        end_date:false,
        start_date:false,
        contexts:false,
        all_day:false
    })

    useEffect(() => {
            getAthleteAvailability()
    }, []);


    useEffect(() => {
        // Liste des disciplines
        axios.get(
            config+"api/disciplines",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            let options = []

            for(let d of response.data.disciplines) {
                if(disciplineCodes.indexOf(d.code) != -1) {
                    let option = {
                        'id': d.id,
                        'label': d.name,
                        'code' : d.code
                    }
                    options.push(option)
                }
            }
            setDisciplines(options)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    },[])

    useEffect(() => {
        if (isMobile && pickerRef.current !== null) {
            pickerRef.current.input.readOnly = true;
        }
    }, [isMobile, pickerRef,contexts]);

    useEffect(() => {
        if(disciplines) {
            for(let discipline of disciplines) {
                setContextsByDiscipline(discipline)
            }
        }
    }, [disciplines])


    useEffect(() => {
        if(allDay) {
            let year = parseInt(format(startDate,'y'))
            let month = parseInt(format(startDate,'M'))
            let day = parseInt(format(startDate,'d'))
            setStartDate(new Date(year, month-1, day, 0, 0))
            setEndDate(new Date(year, month-1, day, 23, 59))
        }
    }, [allDay])

    // Vérifier les champs obligatoires
    useEffect(() => {
            if(requiredFields.start_date == true
                || requiredFields.end_date == true
                || requiredFields.contexts == true
                || requiredFields.all_day == true){
                setReady(true)
            }

    }, [requiredFields])

    const updateRequiredFields = (action, key) => {
        let editValue = action == 'edit' ? true : false
        if(requiredFields[key] !== editValue) {
            let newRequiredFields = {...requiredFields}
            newRequiredFields[key] = editValue
            setRequiredFields(newRequiredFields)
        }

    }

    // Suvegarder disponibilité
    const postAthleteAvailability = async () => {
        const headers = generateHeaders()

        let startDateISO = formatISO(startDate)
        let endDateISO = formatISO(endDate)

        let datas = {
            start_date: startDateISO,
            end_date: endDateISO,
            all_day: allDay,
            context_ids: contexts
        }
        await axios
            .put(
                config+"api/athlete_availabilities/"+athleteAvailabilityEventHandled.id,
                datas,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then((response) => {
                if(!isMounted.current){
                    return 0;
                }
                let newAthleteAvailability = athleteAvailabilityEventHandled
                athleteAvailabilityEventHandled.start_date =  datas.start_date
                athleteAvailabilityEventHandled.end_date =  datas.end_date
                athleteAvailabilityEventHandled.all_day =  datas.all_day
                updateAthleteAvailabilityEventHandled(newAthleteAvailability)

                // Déclencher le rechargement le calendrier des disponibilité
                updateCalendarEventToRefresh("athlete-availibility-" + datas.context_ids)

                // Fermer le formulaire de création
                let dateYMD = format(startDate, 'yyyy-MM-dd')
                closeAthleteAvailabilityModal(dateYMD)

            })
            .catch(error => {
                handleEndpointError(error)
                if(error.response !== undefined) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.data.errors)
                    }
                }
            })
    }

    const getAthleteAvailability = async () => {
        const headers = generateHeaders()

        await axios
            .get(
                config+"api/athlete_availabilities/"+athleteAvailabilityEventHandled.id,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then((response) => {
                if(!isMounted.current){
                    return 0;
                }
                let data = []
                for(let j=0; j < response.data.contexts.length; j++) {
                    data.push(response.data.contexts[j].id)
                    setContexts(data)
                }

            })
            .catch(error => {
                handleEndpointError(error)
                if(error.response !== undefined) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.data.errors)
                    }
                }
            })
    }


    const setContextsByDiscipline = async (discipline) => {
        await axios.get(
            config+"api/contexts?discipline_id="+discipline.id+"&only_without_parent=1",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            let options = []
            for(let c of response.data.contexts) {
                let option = {
                    'id': c.id,
                    'label': c.fullname,
                    'code' : c.code
                }
                options.push(option)
            }
            switch(discipline.code) {
                case 'nat':
                    setSwimmingContexts(options)
                    break
                case 'velo':
                    setCyclingContexts(options)
                    break
                case 'cap':
                    setRunningContexts(options)
                    break
                default:
                    break;
            }
        })
            .catch(error => {
                handleEndpointError(error)
            })

    }

    const onChangeStartDate = (value) => {
        setStartDate(value)
        setDayName(moment(format(value, 'yyyy-MM-dd')).format('dddd'))

        value = setSeconds(value,1)
        let sYear = parseInt(format(value,'y'))
        let sMonth = parseInt(format(value,'M'))
        let sDay = parseInt(format(value,'d'))

        let eHour = endDate ? parseInt(format(endDate,'H')) : 0
        let eMinute = endDate ? parseInt(format(endDate,'m')) : 0
        let newDate = new Date(sYear, sMonth-1, sDay, eHour, eMinute)
        if(isAfter(value, newDate)) {
            let sHour = parseInt(format(value,'H'))
            let sMinute = parseInt(format(value,'m'))
            let newHour = sHour+2
            let newMinute = sMinute
            if(sHour >= 22) {
                newHour = 23
                newMinute = 30
            }
            newDate = new Date(sYear, sMonth-1, sDay, newHour, newMinute)
        }
        setEndDate(newDate)
        updateRequiredFields('edit', 'start_date')
    }

    const onChangeEndDate = (value) => {
        setEndDate(value)
        if(isBefore(value, startDate)) {
            updateRequiredFields('remove', 'end_date')
            onChangeStartDate(value)
        }
        else {
            updateRequiredFields('edit', 'end_date')
        }
    }

    const onChangeAllDay = (value) => {
        setAllDay(value)
        updateRequiredFields('edit', 'all_day')
    }

    const onChangeContexts = (value) => {
        setContexts(value)
    }

    return (
        <div className="mcb-7">
            <div className="modal-card pcx-4 pcy-3">
                <div className="mcb-4">
                    {disciplines && swimmingContexts && cyclingContexts && runningContexts ?
                        <>
                            <div className="row mcb-3">
                                <div className="col-5">
                                    <span className="label-input-athlete-event mcb-2">Date</span>
                                    {/* Date */}
                                    <DatePicker
                                        wrapperClassName="w-100"
                                        className="custom-input w-100"
                                        dateFormat="P"
                                        selected={startDate}
                                        ref={pickerRef}
                                        onChange={(date) => onChangeStartDate(date)}
                                    />
                                </div>
                                {allDay === false &&
                                <>
                                    <div className="col-3 pr-0">
                                        {/* Heure début */}
                                        <span className="label-input-athlete-event mcb-2">{t('app:athlete.athlete_availability.start_date')}</span>
                                        <DatePicker
                                            wrapperClassName="w-100"
                                            className="custom-input w-100"
                                            dateFormat="p"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            selected={startDate}
                                            timeCaption="Horaire"
                                            onChange={(date) => onChangeStartDate(date)}
                                        />
                                    </div>
                                    <div className="col-3 pr-0">
                                        {/* Heure fin */}
                                        <span className="label-input-athlete-event  mcb-2">{t('app:athlete.athlete_availability.end_date')}</span>
                                        <DatePicker
                                            wrapperClassName="w-100"
                                            className="custom-input w-100"
                                            dateFormat="p"
                                            showTimeSelect
                                            showTimeSelectOnly
                                            selected={endDate}
                                            timeCaption="Horaire"
                                            onChange={(date) => onChangeEndDate(date)}
                                        />
                                    </div>
                                </>
                                }
                            </div>
                            <div className="row mcb-3">
                                <div className="col-12">
                                    <div className={"checkbox-container checkbox-container-debriefing conditions-options-blue mb-2 w-100"}>
                                        <input type="checkbox" id="all_day" name="all_day" checked={allDay} onChange={(e) => onChangeAllDay(e.target.checked)}/>
                                        <label htmlFor="all_day">{t('app:athlete.athlete_availability.all_day')}</label>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                                <AthleteAvailibilityContexts
                                    selectedValues={contexts}
                                    disciplines={disciplines}
                                    swimmingContexts={swimmingContexts}
                                    cyclingContexts={cyclingContexts}
                                    runningContexts={runningContexts}
                                    onChange={(selectedContexts) => onChangeContexts(selectedContexts)}
                                    setReady={(value) => setReady(value)}
                                />
                        </>
                        :
                        <Loader
                            type="TailSpin"
                            color="blue"
                            height={50}
                            width={50}
                            className="my-1"
                        />
                    }
                    <div className="w-100 mct-5 d-flex justify-content-center">
                        {errorMessage &&
                        <div className="error-login-text mcb-3">
                            {errorMessage}
                        </div>
                        }
                        <Button className="background-black py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text"
                                onClick={() => postAthleteAvailability()}
                                disabled={!ready}
                        >
                            <img className="mr-2 icon-24" alt="valid icon" src={validate} />{t('app:athlete.saving.save')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditAthleteAvailability;