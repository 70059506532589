import React, {useState, useEffect} from 'react';
import CustomSelect from "../Tools/CustomSelect"

const SelectContext = ({contexts, defaultContext, onChange}) => {

    const [value, setValue] = useState(defaultContext)
    const [defaultValue, setDefaultValue] = useState(defaultContext)

    // Sélectionner la valeur par défaut lors de la mise à jour de la liste
    useEffect(() => {
        if(contexts && contexts.length > 0){
            let inContexts = false
            if(defaultValue) {
                for(let c of contexts) {
                    if(c.code === defaultValue.code) {
                        inContexts = true
                        break
                    }
                }
            }

            if(!inContexts) {
                let contextValue = contexts[0]
                setDefaultValue(contextValue)
                onChangeValue(contextValue)
            }
        }
    }, [contexts])

    const onChangeValue = (value) => {
        setValue(value)
        onChange(value)
    }

    if(contexts === null || contexts.length === 0) {
        return (<>Aucune condition</>)
    }

    return (
        <CustomSelect
            className="w-100 text-left"
            name="context"
            options={contexts}
            defaultValue={defaultValue}
            value={value}
            onChange={(value) => onChangeValue(value)}
        />
    )
}

export default SelectContext;