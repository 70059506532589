import React from "react";

import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";


const ReviewDebriefing = ({reviewTitle, changeReviewTitle, reviewDescription, changeReviewDescription}) => {
    const {t} = useTranslation(['app'])

    return (
        <div className="col-12">
            <p className={"rpe-title text-left mcb-3"}>{t('athlete.debriefing.review.title')}</p>
            <p className="text-left text-p-cr mcb-4">
                {t('athlete.debriefing.review.content')}
            </p>
            <p className="text-left text-p-cr mcb-3 font-weight-bold">
                {t('athlete.add_event.name')}
            </p>
            <Form.Group controlId="form-title">
                <Form.Control className="mcb-4 form-login-input pc-3 background-white"
                              as="textarea"
                              rows={1}
                              name="review-title"
                              placeholder={t('app:athlete.debriefing.comment.placeholder')}
                              value={reviewTitle}
                              onChange={event => changeReviewTitle(event.target.value)}
                >
                </Form.Control>
            </Form.Group>
            <p className="text-left text-p-cr mcb-3 font-weight-bold">
                {t('athlete.debriefing.review.title')}
            </p>
            <Form.Group controlId="form-desc">
                <Form.Control className="mcb-4 form-login-input pc-3 background-white"
                              as="textarea"
                              rows={1}
                              name="review-description"
                              placeholder={t('app:athlete.debriefing.comment.placeholder')}
                              value={reviewDescription}
                              onChange={event => changeReviewDescription(event.target.value)}
                >
                </Form.Control>
            </Form.Group>

        </div>
    )
}

export default ReviewDebriefing