import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../../Tools/CustomHooks";
import {Row} from "react-bootstrap";
import Loader from "react-loader-spinner";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";

import MapGPS from './Map';
import { useDispatch, useSelector } from "react-redux";
import { setMarkerCoordinates } from "../../../redux/features/map/mapSlice";

const ActivitySessionMap = ({activitySessionId, pathType, trainingConceptCode}) => {

    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()

    const [pathing, setPathing] = useState(null)
    const [legendInfos, setLegendInfos] = useState(null)

    const { markerDuration } = useSelector(app => app.map)
    const dispatch = useDispatch()

    useEffect(() => {
        if (pathing && markerDuration !== null) {
            pathing.forEach(mapPlot => {
                for (const zoneName in mapPlot) {
                    mapPlot[zoneName].forEach(plot => {
                        if (plot.duration === markerDuration) {
                            dispatch(setMarkerCoordinates({ lat: plot.lat, long: plot.long }))
                        }
                    })
                }
            })
        } else if (pathing) {
            try {
                dispatch(setMarkerCoordinates({ lat: pathing[0][Object.keys(pathing[0])[0]][0].lat, long: pathing[0][Object.keys(pathing[0])[0]][0].long }))
            } catch(e) {
                console.log('Error while trying to set start marker', e)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markerDuration, pathing])

    const getMapDatas = () => {
        let headers = generateHeaders()
        if (headers) {
            axios
                .get(
                    config+"api/activity_sessions/"+activitySessionId+"/map",
                    {
                        headers: {
                            'X-WSSE': headers
                        },
                        params: {
                            path_type: pathType,
                            training_concept: trainingConceptCode
                        }
                    },
                )
                .then(response => {
                    if(!isMounted.current){
                        return 0;
                    }
                    setPathing(response.data.mapPlots)
                    setLegendInfos(response.data.legendInfos)
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    useEffect(() => {
        if(trainingConceptCode) {
            getMapDatas()
        }
    }, [pathType, trainingConceptCode])


    if(!(pathing && legendInfos)) {
        return(
            <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={50}
                    width={50}
                    className="my-5"
                />
            </div>
        )
    }

    return (
        <>
            <Row className=" mr-1 mb-2 justify-content-start d-flex text-left ">
                <h2 className="seance-title2">{t('app.activity_session.map')}</h2>
            </Row>
            <Row className="justify-content-center my-3 d-flex">
                <MapGPS
                    legendInfos={legendInfos}
                    pathing={pathing}
                />
            </Row>
        </>
    )
}

export default ActivitySessionMap;