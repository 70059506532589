import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import moment from "moment";
import {iconAthleteEvent, getCompetitionIcon} from "../Utils";
import {getAthleteEventClassNames} from "./Utils";
import {more, x_circle} from "../../../img/img";
import {AthleteEventTypes, getAthleteEventTypeLabel} from "../AthleteEvent/Utils";
import AthleteEventPanel from "./AthleteEventPanel";

/**
 * Afficher un évènement compétition/vacance/stage/blessure dans le calendrier
 *
 * @param athleteEvent : événément du calendrier
 * @param activeDate: date du calendrier
 * @param changeActiveDate : handler pour modifier la date du calendrier (WeekCalendar)
 * @param openCloseAthleteEvent : handler pour ouvrir/fermer la modal
 */
const AthleteEvent = ({athleteEvent, activeDate, changeActiveDate, openAthleteEventModal}) => {

    const {t} = useTranslation(['app'])

    // Indique si le panel est ouvert
    const [isPanelOpen, setIsPanelOpen] = useState(false)

    let athleteEventKey = athleteEvent.key
    let athleteEventType = athleteEvent.type
    let athleteEventDate = moment(athleteEvent.date)
    let columnOpen = activeDate ? activeDate.format("YYYY-MM-DD") === athleteEventDate.format("YYYY-MM-DD" ) : false

    const {athleteEventClass} = getAthleteEventClassNames(athleteEventType)

    if(!columnOpen) {
        return (
            <div key={athleteEventKey} className="d-flex justify-content-center">
                <Button variant="outline-light"
                        className={athleteEventClass}
                        onClick={() => changeActiveDate(athleteEventDate)}
                >
                    {iconAthleteEvent(athleteEventType, false, athleteEvent.sport)}
                </Button>
            </div>
        )
    }


    let athleteEventName = athleteEvent.name

    if(athleteEventType === AthleteEventTypes.COMPETITION) {
        return (
            <div key={athleteEventKey} className="d-flex justify-content-center">
                <div className={"d-flex justify-content-center compet-case-active mcb-3 mx-auto align-items-center"}
                >
                    {isPanelOpen ?
                        <>
                            <AthleteEventPanel athleteEvent={athleteEvent}
                                               openAthleteEventModal={(date) => openAthleteEventModal(date)}
                                               closePanel={() => setIsPanelOpen(false)}
                            />
                            <img
                                src={x_circle}
                                alt="close panel icon"
                                className="more mcr-2 mct-2 icon-24"
                                onClick={() => setIsPanelOpen(false)}
                            />
                        </> :
                        <img
                            src={more}
                            alt="more icon"
                            className="more mcr-2 mct-2 icon-24"
                            onClick={() => setIsPanelOpen(true)}
                        />
                    }
                    <img src={getCompetitionIcon(athleteEvent.sport, true)} alt="compet img active" className="compet-img-active img-responsive" />
                    <p className="m-0 position-absolute align-self-end pcb-4 fs-12 compet-case-active-p">{athleteEventName}</p>
                </div>
            </div>
        )
    }

    return (
        <div key={athleteEventKey} className="d-flex justify-content-center" >
            <div className="div-workout-todo mcb-3 d-block pc-2" style={{height:'auto'}}>
                {isPanelOpen ?
                        <>
                            <AthleteEventPanel athleteEvent={athleteEvent} />
                            <img
                                src={x_circle}
                                alt="close panel icon"
                                className="more mcr-2 mct-2 icon-24"
                                onClick={() => setIsPanelOpen(false)}
                            />
                        </> :
                        <img
                            src={more}
                            alt="more icon"
                            className="more mcr-2 mct-2 icon-24"
                            onClick={() => setIsPanelOpen(true)}
                        />
                }
                <div className="">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                        <div className={"agenda-cube cube-white " + athleteEventClass}>
                            {iconAthleteEvent(athleteEventType, true)}
                        </div>
                        <p className={"text-workout-active text-left mb-3  ml-2"}>
                            {t("app:app.athlete_event." + getAthleteEventTypeLabel(athleteEventType))}
                        </p>
                    </div>
                </div>
                <div className="d-flex justify-content-center pcb-2 mcb-1">

                    <p className="text-workout-item mb-0">
                        {athleteEventName}
                    </p>
                </div>
            </div>
        </div>


    )

}

export default AthleteEvent;


