import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../Components/Tools/CustomHooks";
import {HeaderHome} from "../../src/Components/Home/HeaderHome";
import {helpCircle} from "../../src/img/img";
import {AuthContext, LanguageContext, NavigationContext} from "../Contexts";
import '../Css/subscription.css';
import Checkout from "./Checkout";
import {scroller, Element as ScrollerElement } from "react-scroll";
import Cart from "./Cart";
import axios from "axios/index";
import config from "../api";
import handleEndpointError from "../Functions/Alert";
import generateHeaders from "../Functions/generateHeaders";
import Loader from "react-loader-spinner";
import Button from "react-bootstrap/Button";

import {updateLanguageFromSearch} from "../Functions/Utils";
import ReactPixel from 'react-facebook-pixel';

const SubscriptionMembership = (props) => {

    const {t} = useTranslation(['app']);
    const isMounted = useIsMounted()

    const {isMobile} = useContext(NavigationContext)
    const {token} = useContext(AuthContext)
    const {language, updateLanguage} = useContext(LanguageContext)

    const {coachId} = props.match.params

    const [product, setProduct] = useState(null)
    const [defaultOptions, setDefaultOptions] = useState([])
    const [productWithOptionsData, setProductWithOptionsData] = useState(null)


    const [cart, setCart] = useState({
        id: null,
        reduction_amount: 0,
        original_price: null,
        confirmation_price: null,
        coupon: null,
        currency_symbol: null,
        recurring: null
    })

    const [loaded, setLoaded] = useState(false)
    const [purchaseId, setPurchaseId] = useState(null)
    const [purchaseAmountData, setPurchaseAmountData] = useState(null)



    // Affilae
    var _ae = {'pid': process.env.REACT_APP_AFFILAE_TAG_ID};
    useEffect(() => {
        if((window.location.host).indexOf("web.gutai.training") !== -1) {
            // Google Tag Manager
            window.dataLayer.push({
                event: 'pageview'
            });
        }

        // Affilae
        (function() {
            var element = document.createElement('script');
            element.type = 'text/javascript';
            element.async = true;
            element.src = '//static.affilae.com/ae-v3.5.js';

            // var scr = document.getElementsByTagName('script')[0];
            // scr.parentNode.insertBefore(element, scr);
            document.body.appendChild(element)
        })();

    }, [])



    useEffect(() => {
        // Création du panier d'achat
        newCart(coachId)
    }, [coachId])

    useEffect(() =>  {
        // Mettre à jour la langue en prenant en compte l'url lorsque l'utilisateur n'est pas connecté
        if(token === null) {
            updateLanguageFromSearch(updateLanguage, props.location.search)
        }
    }, [token])


    useEffect(() => {
        if(product){
            // Récupérer les informations du produit et des options
            getProduct(product.id, coachId)
        }
    }, [language, product])

    const newCart = async (coachId) => {
        await axios.post(
            config+"public/api/cart/new",
            {
                product_code: 'membership',
                entity_id: coachId,
                recurring: 'month',
                locale: language
            },
        )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let data = response.data

                setCart({
                    id: data.id,
                    reduction_amount: data.reduction_amount,
                    original_price: data.original_price,
                    confirmation_price: data.confirmation_price,
                    coupon: data.coupon,
                    currency_symbol: data.currency_symbol,
                    recurring: data.recurring
                })

                let productPriceData = data.lines[0].product_price
                setProduct({
                    id: productPriceData.product_id,
                    product_price: {
                        id: productPriceData.id,
                        amount: productPriceData.amount,
                        currency_symbol: productPriceData.currency_symbol,
                        recurring: productPriceData.recurring
                    }
                })

                // let fbPixel = process.env.REACT_APP_FACEBOOK_PIXEL_KEY_TEST
                let fbPixel = process.env.REACT_APP_FACEBOOK_PIXEL_KEY
                ReactPixel.init(fbPixel, {}, {
                    autoConfig: true,
                    debug: false
                });
                ReactPixel.pageView();


            })
            .catch(error => {
                handleEndpointError(error)
            })
    }


    const getProduct = async (productId, coachId) => {
        await axios.get(
            config+"public/api/products/"+productId,
            {
                params: {
                    coach_id: coachId,
                    locale: language
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            let data = response.data

            let pWithOptionsData = {
                'title': t('subscription.product_title') + " " + data.name,
                'code': data.code,
                'name' : data.name,
                'id': data.id,
                'product_price': {
                    'id': product.product_price.id,
                    'amount': product.product_price.amount,
                    'currency_symbol': product.product_price.currency_symbol,
                    'recurring': product.product_price.recurring
                },
                'options': []
            }
            for(let pOption of data.options)
            {
                pWithOptionsData.options.push(pOption)
            }

            setProductWithOptionsData(pWithOptionsData)

            setLoaded(true)
        }).catch(error => {
            handleEndpointError(error)
        })
    }

    const updateCart = async () => {
        await axios.get(
            config+"public/api/cart/"+cart.id
        )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let data = response.data
                setCart({
                    id: data.id,
                    reduction_amount: data.reduction_amount,
                    original_price: data.original_price,
                    confirmation_price: data.confirmation_price,
                    coupon: data.coupon,
                    currency_symbol: data.currency_symbol,
                    recurring: data.recurring
                })
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const purchaseCart = async () => {
        await axios.post(
            config+"api/cart/"+ cart.id +"/purchase",
            {},
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
            .then(response => {
                // Enregistrer le numéro de l'achat créé
                let purchaseData = response.data
                setPurchaseId(purchaseData.id)
                setPurchaseAmountData({
                    amount_without_tax: purchaseData.amount_without_tax,
                    currency: purchaseData.currency,
                    coupon: purchaseData.coupon
                })
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }



    return (
        <>
        {loaded ?
            <div className={isMobile ? "" : "d-flex justify-content-end min-vh-100 background-membership"}>
                <div className={isMobile ? "pc-4 d-flex flex-column justify-content-center align-items-center container"
                                         : "w-40 pc-4 d-flex flex-column align-items-center container-desktop"}
                >
                    <HeaderHome withoutLanguageSwitch={token ? 1 :0}
                                showLogoM={1}
                    />

                    <React.Fragment key="cart-summary">
                        <Cart
                            cart={cart}
                            productWithOptionsData={productWithOptionsData}
                            defaultOptions={defaultOptions}
                            color="orange"
                            onUpdate={() => updateCart()}
                        />
                        <ScrollerElement name="cartElement"
                                         className="w-100"
                        >
                            <Checkout
                                type="subscription"
                                color="orange"
                                cart={cart}
                                updateCart={() => updateCart()}
                                purchaseCart={() => purchaseCart()}
                                purchaseId={purchaseId}
                                purchaseAmountData={purchaseAmountData}
                            />
                        </ScrollerElement>
                    </React.Fragment>
                </div>
            </div>
            :
            <Loader
                type="TailSpin"
                color="#0069d9"
                height={50}
                width={50}
                className="my-5"
            />
        }
    </>
    )
}
export default SubscriptionMembership