import ReactTooltip from "react-tooltip";
import React, {useContext, useEffect, useState} from "react";
import {plus_circle_grey, trash_grey} from "../../img/img";
import {useTranslation} from "react-i18next";
import {NavigationContext} from "../../Contexts";
import CustomSelect from "../Tools/CustomSelect";

const AthleteSports = ({userProfile, onChangeUserProfile}) => {
    const {t} = useTranslation(['app'])
    const {isMobile} = useContext(NavigationContext)

    const sportsAvailables = [{
        id: 200,
        label: t('finalize_registration.sports.cap'),
        value: "cap"
    }, {
        id: 201,
        label: t('finalize_registration.sports.velo'),
        value: "velo"
    }, {
        id: 202,
        label: t('finalize_registration.sports.nat'),
        value: "nat"
    }, {
        id: 203,
        label: t('finalize_registration.sports.triathlon'),
        value: "triathlon"
    }
    ]
    const sportLevels = [{
        id: 100,
        label: t('finalize_registration.level.beginner'),
        value: "beginner"
    }, {
        id: 101,
        label: t('finalize_registration.level.intermediate'),
        value: "intermediate"
    }, {
        id: 102,
        label: t('finalize_registration.level.advanced'),
        value: "advanced"
    }, {
        id: 103,
        label: t('finalize_registration.level.athlete'),
        value: "athlete"
    },
    ]
    const sportsLevelsIds = {
        sport:{
            cap: sportsAvailables[0].id,
            velo: sportsAvailables[1].id,
            nat: sportsAvailables[2].id,
            triathlon: sportsAvailables[3].id,
        },
        level:{
            beginner: sportLevels[0].id,
            intermediate: sportLevels[1].id,
            advanced: sportLevels[2].id,
            athlete: sportLevels[3].id,
        }
    }

    const [levels, setLevels] = useState([])
    const [sports, setSports] = useState([])
    const [userSports, setUserSports] = useState([])
    const [sportCounter, setSportCounter] = useState(userProfile && userProfile.sports.length > 0 ? userProfile.sports.length : 0)

    useEffect(() => {
        if(userProfile && userProfile.sports.length>0) {
            let newSports = []
            let newLevels = []
            for (let i=0; i<userProfile.sports.length; i++) {
                let disciplineCode = userProfile.sports[i].discipline.code
                let levelValue = userProfile.sports[i].level
                newSports.push({
                    id: sportsLevelsIds.sport[disciplineCode],
                    label: t('finalize_registration.sports.'+disciplineCode),
                    value: disciplineCode
                })
                newLevels.push({
                    id: sportsLevelsIds.level[levelValue],
                    label: t('finalize_registration.level.'+levelValue),
                    value: levelValue
                })
            }
            setSports(newSports)
            setLevels(newLevels)
        }
    }, [])

    useEffect(() => {
        if ((sports.length>0 && levels.length>0) && (sports.length === levels.length)) {
            let newUserSports = []
            for (let i = 0; i < sports.length; i++) {
                newUserSports.push({
                    discipline: sports[i].value,
                    level: levels[i].value
                })
            }
            setUserSports(newUserSports)
        }
    }, [sports, levels])

    useEffect(() => {
        if (userSports && userSports.length>0) {
            onChangeUserProfile('sports', userSports)
        }
    }, [userSports]);



    const onChangeSport = (value, counter) => {
        setSports((oldSports) => {
            let newSports = [...oldSports]
            newSports[counter] = value
            return newSports
        })
    }

    const addLine = () => {
        setSports(oldSports => {
            let newSports = [...oldSports]
            newSports.push(sportsAvailables[0])
            return newSports
        })
        setLevels(oldLevels => {
            let newLevels = [...oldLevels]
            newLevels.push(sportLevels[0])
            return newLevels
        })
        setSportCounter(sportCounter => sportCounter + 1)
    }

    const deleteSport = (sportPosition) => {
        setSports((oldSports) => {
            let newSports = [...oldSports]
            newSports.splice(sportPosition, 1)
            return newSports
        })
        setLevels((oldLevels) => {
            let newLevels = [...oldLevels]
            newLevels.splice(sportPosition, 1)
            return newLevels
        })
        setSportCounter(sportCounter => sportCounter - 1)
    }

    const onChangeLevel = (value, counter) => {
        setLevels((oldLevels) => {
            let newLevels = [...oldLevels]
            newLevels[counter] = value
            return newLevels
        })
    }

    const generateSportsDiv = () => {
        let sportsDivs = []
        if (sports) {
            for (let i = 0; i < sports.length; i++) {
                if (sports[i]) {
                    sportsDivs.push(<SportDiv key={sports[i].value+i} sport={sports[i]} level={levels[i]} counter={i}/>)
                }
            }
        }
        return sportsDivs
    }

    const SportDiv = ({sport, counter, level}) => {
        if (sport) {
            let notFirst = counter > 0
            return (
                <div key={"sport-" + sport.id} className="w-100 row mcy-2 justify-content-center">
                    <div className="col-5 pr-0">
                        <CustomSelect
                            className="w-100"
                            name="sport"
                            options={sportsAvailables}
                            defaultValue={sportsAvailables[counter]}
                            value={sport}
                            onChange={(value) => onChangeSport(value, counter)}
                        />
                    </div>
                    <div className="col-5 pr-0">
                        <CustomSelect
                            className="w-100"
                            name="sportLevel"
                            options={sportLevels}
                            defaultValue={sportLevels[counter]}
                            value={level}
                            onChange={(value) => onChangeLevel(value, counter)}
                        />
                    </div>
                    {notFirst ?
                        <div className="pl-2 d-flex justify-content-center align-items-center">
                            <div className="w-40 h-40 d-flex justify-content-center align-items-center">
                                <img id={"trash-" + sport.id} src={trash_grey} alt="trash icon" className="icon-24 pointer"
                                     onClick={() => {
                                         deleteSport(counter)
                                     }
                                     }/>
                            </div>
                        </div>
                        :
                        sports.length>1 && <div className="empty-trash" />
                    }
                </div>
            )
        } else {
            return null
        }
    }

    return (
                <>
                    {generateSportsDiv()}

                    {sportsAvailables.length > sportCounter &&
                    <p className="label-home d-flex align-items-center pointer" onClick={addLine}>
                        <img src={plus_circle_grey} alt="plus icon"
                             className="icon-24"/> {t('finalize_registration.add_sport')}
                    </p>
                    }
                </>
    )
}
export default AthleteSports