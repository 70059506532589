import React from "react";
import {useTranslation} from "react-i18next";
import {tic, tic_orange} from "../../../img/img";
import {Form} from "react-bootstrap";


const ConditionsDebriefing = ({setConditions, conditions, color, hurtDetails, setHurtDetails}) => {
    const {t} = useTranslation(['app'])

    const handleCheckChange = (e) => {
        const target = e.target
        setConditions((oldChecks) => {
            const newChecks = {...oldChecks}
            newChecks[target.name] = target.checked
            if (target.name === "fever" && target.checked) {
                newChecks["sickness"] = true
            } else if (target.name === "sickness" && !target.checked) {
                newChecks["fever"] = false
            }
            return newChecks
        })
    }


    return (
        <>
            <div className="col-12">
                <div className="d-flex justify-content-start">
                    <p className="h2 rpe-title  d-flex justify-content-start align-items-center">
                        Conditions
                    </p>
                </div>

                <p className="text-left text-p-cr">{t('app:athlete.debriefing.rpe.content.4')}</p>

                <div className="rpe-content my-3">
                    <div className="checkbox-section mx-2 pt-3 row mb-3 justify-content-center">
                        <div className={"checkbox-container checkbox-container-debriefing conditions-options-"+color+" mb-2"}>
                            <input type="checkbox" id="sickness" name="sickness" checked={conditions.sickness} onChange={handleCheckChange}/>
                            <label htmlFor="sickness">{t('app:athlete.debriefing.rpe.conditions.disease')}</label>
                        </div>
                        <div className={"checkbox-container checkbox-container-debriefing conditions-options-"+color+" mb-2"}>
                            <input type="checkbox" id="hurt" name="hurt" checked={conditions.hurt} onChange={handleCheckChange}/>
                            <label htmlFor="hurt">{t('app:athlete.debriefing.rpe.conditions.injury')}</label>
                        </div>
                        <div className={"checkbox-container checkbox-container-debriefing conditions-options-"+color+" mb-2"}>
                            <input type="checkbox" id="fever" name="fever" checked={conditions.fever} onChange={handleCheckChange}/>
                            <label htmlFor="fever">{t('app:athlete.debriefing.rpe.conditions.fever')}</label>
                        </div>
                    </div>
                </div>
            </div>
            {conditions.hurt ?
            <div className="col-12">
                <Form.Group controlId="form-comment">
                    <Form.Control className="mcb-3 form-login-input pc-3 background-white"
                                  as="textarea"
                                  rows={2}
                                  name="comment"
                                  placeholder={t('app:athlete.debriefing.rpe.conditions.placeholder_injury')}
                                  value={hurtDetails}
                                  onChange={event => setHurtDetails(event.target.value)}
                    >
                    </Form.Control>
                </Form.Group>
            </div> :
                <></>
            }
        </>
    )
}

export default ConditionsDebriefing;