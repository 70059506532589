import {arrow_left, x_circle,arrow_left_circle,arrow_right_circle} from "../../img/img";
import React, {useContext, useEffect, useState} from "react";
import {NavigationContext, DashboardContext, WeekCalendarContext} from "../../Contexts";
import {useTranslation} from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import {Element as ScrollerElement, scroller} from "react-scroll";
import {TRAININGSUMMARYPERIODS, TRAININGSUMMARYTYPES} from "../TrainingSummary/Utils";
import moment from "moment";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import Loader from "react-loader-spinner";
import {getMessageWellnessType} from "./Utlis";

const WellnessEvolution = ({openCloseWellnessEvolution}) => {
    const TYPES = {
        DAILY: "daily",
        WEEKLY: "weekly",
        MONTHLY: "monthly"
    }

    const {wellnessEvolutionAction,updateWellnessEvolutionAction} = useContext(DashboardContext)

    const {isMobile} = useContext(NavigationContext)
    const {t} = useTranslation(['app'])

    const {activeWeek} = useContext(WeekCalendarContext)

    const chronoFormat = 'H[h]mm'
    const dateFormat = "YYYY-MM-DD"
    const initEndDate = activeWeek ? activeWeek.isoWeekday(7) : moment().isoWeekday(7)
    
    const [type, setType] = useState(TYPES.DAILY)

    const [loaded, setLoaded] = useState(false)

    const [categories, setCategories] = useState([])
    const [wellnessesDatas, setWellnessesDatas] = useState()

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(initEndDate)

    const [optionsHigh, setOptionsHigh] = useState(null)
    const [datasToDisplay, setDatasToDisplay] = useState({
        mood: true,
        sleep_quality: true,
        muscular_soreness: true,
        stress: true,
        fatigue: true
    })

    const backgroundColor = '#F5F5F5';

    const colors = {
        mood: '#FFA800',
        sleep_quality: '#007DFF',
        muscular_soreness: '#00D254',
        stress: '#F15700',
        fatigue: '#3EC6FF'
    }

    useEffect(() => {
        if(isMobile){
            switch(wellnessEvolutionAction) {
                case 'show_wellness_evolution':
                    // Scroll up
                    scroller.scrollTo('wellnessEvolutionElement', {
                        duration: 500,
                        delay: 200,
                        smooth: 'easeOutQuart',
                        offset: -400
                    })
                    const timer = setTimeout(() => updateWellnessEvolutionAction(null), 2500);
                    return () => clearTimeout(timer);
                    break;

                default:
                    break;

            }
        }

    }, [wellnessEvolutionAction])

    useEffect(() => {
        // Récupérer les données Volume & Kilométrage
        if (startDate && type) {
            getWellnesses()
        }

    }, [startDate])
    

    useEffect(() => {
        if(categories && wellnessesDatas && type) {
            let options = {
                title: {
                    text: null
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                chart: {
                    height: 280,
                    spacingLeft:isMobile ? 10 : 64,
                    spacingRight:isMobile ? 10 : 64,
                    plotBackgroundColor: backgroundColor,
                    marginRight: isMobile ? 36 : null,
                    marginLeft: isMobile ? 36 : null,
                },
                xAxis: {
                    labels: {
                        style: {
                            fontSize: isMobile ? 8 : 11
                        }
                    },
                    categories:categories,
                    crosshair: true,
                    lineWidth: 0,
                },
                yAxis: {
                    labels: {
                        enabled: false
                    },
                    gridLineWidth: 0,
                    title: {
                        enabled: false
                    }
                },
                scrollbar: {
                    enabled: true
                },
                tooltip: {
                    useHTML: true,
                    borderRadius:8,
                    borderWidth:0,
                    background:'#F5F5F5',
                    formatter: function(){
                        let contentToDisplay = ''
                        let pointIndex = this.point.index
                        if (wellnessesDatas['mood'][pointIndex]+wellnessesDatas['sleep_quality'][pointIndex]+wellnessesDatas['muscular_soreness'][pointIndex]+wellnessesDatas['fatigue'][pointIndex]+wellnessesDatas['stress'][pointIndex]=== 0) {
                            contentToDisplay +=
                                '<span class="text"> ' +
                                t('athlete.wellness.no_wellness') +
                                '</span>'
                        } else {
                            {
                                Object.keys(datasToDisplay).map((key) => {
                                        if (wellnessesDatas[key] && datasToDisplay[key] === true) {
                                            contentToDisplay +=
                                                '<span class="text"> ' +
                                                '<span style="font-weight: bold;color:' + wellnessesDatas[key].color + '">' +
                                                t('athlete.wellness.wellness_evolution.' + key) + '' +
                                                '</span> : ' +
                                                getMessageWellnessType(key, wellnessesDatas[key][pointIndex] * 25, t) +
                                                '</span>' +
                                                '<br>'
                                        }
                                    }
                                )
                            }
                        }

                        return '<div class="cursor"><span class="dome"></span>' + contentToDisplay + '</div>'
                    },
                    hideDelay:0
                },

                plotOptions: {
                    series: {
                        marker: {
                            enabled: false
                        }
                    },
                },
                series: [
                    {
                        data: wellnessesDatas.mood,
                        color: colors.mood,
                        opacity: datasToDisplay.mood === true ? 1 : 0,
                    }, {
                        data: wellnessesDatas.sleep_quality,
                        color: colors.sleep_quality,
                        opacity: datasToDisplay.sleep_quality === true ? 1 : 0
                    }, {
                        data: wellnessesDatas.muscular_soreness,
                        color: colors.muscular_soreness,
                        opacity: datasToDisplay.muscular_soreness === true ? 1 : 0
                    }, {
                        data: wellnessesDatas.stress,
                        color: colors.stress,
                        opacity: datasToDisplay.stress === true ? 1 : 0
                    }, {
                        data: wellnessesDatas.fatigue,
                        color: colors.fatigue,
                        opacity: datasToDisplay.fatigue === true ? 1 : 0
                    }
                ]
            }
            setOptionsHigh(options)
            setLoaded(true)
        }
    }, [wellnessesDatas, categories, datasToDisplay])

    useEffect(() => {
        if (endDate && type) {
            let newStartDate = moment(endDate)

            switch (type) {
                case TYPES.WEEKLY:
                    newStartDate.subtract(3, 'months').add(1, 'days').date(1)
                    newStartDate.isoWeekday(1)
                    break
                case TYPES.MONTHLY:
                    newStartDate.subtract(6, 'months').add(1, 'days').date(1)
                    break
                default:
                    newStartDate.isoWeekday(1)
                    break
            }

            setStartDate(newStartDate)
        }
    }, [type])

    const onChangePeriod = (action) => {
        setLoaded(false)
        let newEndDate = moment(endDate)
        let newStartDate = moment(startDate)
        let key = 'weeks'
        let step = 1
        switch (type) {
            case TYPES.DAILY:
                key = 'weeks'
                break
            case TYPES.WEEKLY:
                step = 6
                key = 'weeks'
                break
            case TYPES.MONTHLY:
                step = 6
                key = 'months'
                break
            default:
                break;
        }
        if (action == 'previous') {
            newEndDate.subtract(step, key)
            newStartDate.subtract(step, key)
        } else if (action == 'next') {
            newEndDate.add(step, key)
            newStartDate.add(step, key)
        }

        let newDates = handleType(type, newEndDate, newStartDate)
        newEndDate = newDates.newEndDate
        newStartDate = newDates.newStartDate

        setEndDate(newEndDate)
        setStartDate(newStartDate)
    }

    const handleType = (type, newEndDate, newStartDate) => {
        switch (type) {
            case TYPES.WEEKLY:
                // On se place sur la fin de la semaine
                if (newEndDate.isoWeekday() !== 7) {
                    newEndDate.isoWeekday(7)
                }
                if (newStartDate.isoWeekday() !== 1) {
                    newStartDate.isoWeekday(1)
                }
                break
            case TYPES.MONTHLY:
                // On se place sur la fin du mois
                newEndDate.endOf('month')
                newStartDate.startOf('month')
                break
            default:
                break;
        }
        return {newEndDate: newEndDate, newStartDate: newStartDate}
    }

    const getWellnesses = async () => {
        console.log(startDate.format(dateFormat))
        console.log(endDate.format(dateFormat))
        await axios.get(
            config + "api/my_wellness_evolution",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                },
                params: {
                    start_date: startDate.format(dateFormat),
                    end_date: endDate.format(dateFormat),
                    type: type
                }
            },
        ).then((response) => {
            let datas = response.data

            console.log(datas)

            let dataCategories = []
            let dataMuscularSoreness = []
            let dataFatigue = []
            let dataStress = []
            let dataSleepQuality = []
            let dataMood = []

            for(let data of datas) {
                switch(type) {
                    case TYPES.DAILY:
                        dataCategories.push(moment(data.date).format('DD/MM'))
                        break
                    case TYPES.WEEKLY:
                        dataCategories.push(t('app.date.week_initial')+"."+data.week_number)
                        break
                    case TYPES.MONTHLY:
                        dataCategories.push(moment(data.month_number, "MM").format('MMM'))
                        break
                    default:
                        break
                }

                let dataScores = data.wellnesses

                if (!dataScores){
                    dataScores=emptyData
                }

                dataMuscularSoreness.push(dataScores.muscular_soreness)
                dataFatigue.push(dataScores.fatigue)
                dataStress.push(dataScores.stress)
                dataSleepQuality.push(dataScores.sleep_quality)
                dataMood.push(dataScores.mood)

            }
            let newScores = {
                muscular_soreness: dataMuscularSoreness,
                fatigue: dataFatigue,
                stress: dataStress,
                sleep_quality: dataSleepQuality,
                mood: dataMood,
            }
            setWellnessesDatas(newScores)
            if(dataCategories.length) {
                setCategories(dataCategories)
            }

        }).catch(error => {
            handleEndpointError(error)
        })
    }

    const emptyData = {
        fatigue: 0,
        mood: 0,
        muscular_soreness: 0,
        sleep_quality: 0,
        stress: 0
    }

    const onChangeType = (typeToSet) => {
        setLoaded(false)
        let newType = null
        if (typeToSet === TYPES.DAILY){
            newType = TYPES.DAILY
        }
        else if (typeToSet === TYPES.WEEKLY) {
            newType = TYPES.WEEKLY
        }
        else if (typeToSet === TYPES.MONTHLY) {
            newType = TYPES.MONTHLY
        }
        setType(newType)
    }

    const handleCheckChange = (e) => {
        const target = e.target
        setDatasToDisplay((oldChecks) => {
            const newChecks = {...oldChecks}
            newChecks[target.name] = target.checked
            if (target.name === "mood" && target.checked) {
                newChecks["mood"] = true
            } else if (target.name === "sleep_quality" && target.checked) {
                newChecks["sleep_quality"] = true
            }
            else if (target.name === "muscular_soreness" && target.checked) {
                newChecks["muscular_soreness"] = true
            }
            else if (target.name === "stress" && target.checked) {
                newChecks["stress"] = true
            }
            else if (target.name === "fatigue" && target.checked) {
                newChecks["fatigue"] = true
            }
            return newChecks
        })

    }

    const checkBoxStyle = {
        mood: "checkbox-container-orange",
        sleep_quality: "checkbox-container-blue",
        muscular_soreness: "checkbox-container-green",
        stress: "checkbox-container-dark-orange",
        fatigue: "checkbox-container-light-blue",
    }

    const checkBoxWellnessType = (wellnessType) => {
        return (
            <div className={isMobile ? checkBoxStyle[wellnessType]+` ${wellnessType === 'muscular_soreness' ? "col-12" : "col-6"} mb-2`: checkBoxStyle[wellnessType]+" col mb-2 justify-content-center"}>
                <input type="checkbox" id={wellnessType} name={wellnessType}
                       checked={datasToDisplay[wellnessType]}
                       onChange={handleCheckChange}/>
                <label className="fs-12 fw-bold" style={{color: colors[wellnessType]}} htmlFor={wellnessType}>{t('app:athlete.wellness.wellness_evolution.'+wellnessType)}</label>
            </div>
        )
    }

    return (
            <div className="more-stats-card">
                <ScrollerElement name="wellnessEvolutionElement"
                                 className="w-100 text-left"
                >
                    <div className="d-flex pc-3 bg-white more-stats-header">
                        {isMobile &&
                        <img src={arrow_left} alt="arrow left" className="icon-24 mcr-2 pointer"
                             onClick={() => openCloseWellnessEvolution(false)}/>
                        }
                        <p className="more-stats-header-text mb-0">
                            {t('app:athlete.wellness.wellness_evolution.title')}
                        </p>
                        {!isMobile &&
                        <div className="position-absolute close-training-event-modal">
                            <img
                                className="icon-24 pointer"
                                src={x_circle}
                                alt="close right circle icon"
                                onClick={() => openCloseWellnessEvolution(false)}
                            />
                        </div>
                        }
                    </div>
                    <div className={"more-stats-container mcy-3"}>
                        <div className="d-flex justify-content-center w-100 mct-2 pointer">
                            <div className={`${isMobile ? "w-100 mcx-2" : "w-50"} more-stats-select-type row`}>
                                <div
                                    className={(type === TYPES.DAILY ? "more-stats-type-nat" : "more-stats-type") + " col"}
                                    onClick={() => onChangeType(TYPES.DAILY)}
                                >
                                    {t('app:athlete.wellness.wellness_evolution.daily')}
                                </div>
                                <div
                                    className={(type === TYPES.WEEKLY ? "more-stats-type-nat" : "more-stats-type") + " col"}
                                    onClick={() => onChangeType(TYPES.WEEKLY)}
                                >
                                    {t('app:athlete.wellness.wellness_evolution.weekly')}
                                </div>
                                <div
                                    className={(type === TYPES.MONTHLY ? "more-stats-type-nat" : "more-stats-type") + " col"}
                                    onClick={() => onChangeType(TYPES.MONTHLY)}
                                >
                                    {t('app:athlete.wellness.wellness_evolution.mensual')}
                                </div>
                            </div>
                        </div>
                        <div className={`d-flex pct-3 ${isMobile ? "justify-content-start pcl-5 row" : "justify-content-center"}`}>
                            {Object.keys(datasToDisplay).map((key) =>
                                checkBoxWellnessType(key)
                            )}
                        </div>
                        {loaded && optionsHigh && categories ?
                            <div className="justify-content-center d-flex mt-3">
                                <div
                                    style={{ width: '100%' }}
                                >
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={optionsHigh}
                                    />
                                    <div className={`d-flex justify-content-between w-100 mcb-4 ${isMobile ? "pcx-2" : "pcx-4"} arrows-relatives`}>
                                        <span onClick={() => onChangePeriod('previous')} className="change-week">
                                            <img src={arrow_left_circle}
                                                 className="grey-arrow pointer icon-24 change-week"
                                                 alt="arrow left circle icon"
                                            />
                                        </span>
                                        <span onClick={() => onChangePeriod('next')} className="change-week">
                                            <img src={arrow_right_circle}
                                                 className="grey-arrow pointer icon-24 change-week"
                                                 alt="arrow right circle icon"
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="pcb-5">
                                <Loader
                                    type="TailSpin"
                                    color="#0069d9"
                                    height={80}
                                    width={80}
                                    className="my-5"
                                />
                            </div>
                        }
                    </div>
                </ScrollerElement>
            </div>
    )
}
export default WellnessEvolution;