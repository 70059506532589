import { useState } from "react"
import axios from "axios"
import config from "../../api"
import generateHeaders from "../../Functions/generateHeaders"

const useExternalActivityUpload = () => {
  const [uploadedFileName, setUploadedFileName] = useState('')
  const uploadExternalActivity = (file, provider) => new Promise((resolve, reject) => {
    let bodyFormData = new FormData();
    bodyFormData.append('provider', +provider.value)
    bodyFormData.append('file', file)

    axios.post(
      `${config}api/activities/upload`,
      bodyFormData,
      {
        headers: {
            'X-WSSE': generateHeaders(),
            'Content-Type': 'multipart/form-data'
        }
      }
    ).then((response) => {
      try {
        setUploadedFileName(response.data.provider_id)
        resolve()
      } catch(error) {
        reject(error)
      }
    }).catch((error) => {
      setUploadedFileName(file.name)
      try {
        reject(error.response.data.error)
      } catch(e) {
        reject(error.message)
      }
    })
  })

  return [uploadedFileName, uploadExternalActivity]
}

export default useExternalActivityUpload
