import React, {useContext, useState,useEffect} from "react";
import {helpCircle} from "../../img/img";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import {NavigationContext} from "../../Contexts";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import moment from "moment";
import {MetricContext} from "../../Contexts";
import {MetricValues} from "../../Functions/Metrics";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import Loader from "react-loader-spinner";
import WeightInput from "./WeightInput";

const Weight = ({openCloseWeightEvolution}) => {

    const {t} = useTranslation(['app']);
    const {isMobile} = useContext(NavigationContext)

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)

    const [categories, setCategories] = useState()
    const [weightDatas, setWeightDatas] = useState()
    const [inEdit, setInEdit] = useState(false)
    const activeWeekNValue = moment().format('W')

    const [isOpen, setIsOpen] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [optionsHigh, setOptionsHigh] = useState(null)


    useEffect(() => {
        if (isOpen) {
            getWeightEvolution()
        }
    }, [isOpen])


    useEffect(() => {
        if (weightDatas) {
            let options = {
                title: {
                    text: null
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                chart: {
                    height: 100,
                    backgroundColor: 'white',
                    plotBackgroundColor:'#F1F8FF',
                    borderRadius: 8,
                    events: {
                        load: function () {
                            this.series.forEach(function (series) {
                                series.points[series.points.length - 1].update({
                                    dataLabels: {
                                        style:{
                                            color:'#007DFF'
                                        }
                                    }
                                });
                            });
                        }
                    }
                },
                xAxis: {
                    labels:{
                        formatter:function(){
                            let newWeeklyValue = 'S.'+activeWeekNValue
                            if( this.value === newWeeklyValue){
                                return '<span style="color: #007DFF">'+this.value+'</span>'
                            }
                            else {
                                return '<span>'+this.value+'</span>'
                            }
                        },
                        style:{
                            fontFamily:'Gotham',
                            color:'#05121D'
                        }
                    },
                    categories: categories,
                    gridLineWidth: 0,
                    gridLineColor: 'white'
                },
                yAxis: {
                    labels: {
                        enabled: false
                    },
                    gridLineWidth: 0,
                    title: {
                        enabled: false
                    }
                },
                plotOptions: {
                    series: {
                        marker: {
                            fillColor: '#A1A6AA',
                        }
                    },
                    line: {
                        dataLabels: {
                            enabled: true,
                            style:{
                                fontFamily:'Caprina',
                                color:'#A1A6AA'
                            },
                        },
                        enableMouseTracking: false,
                    }
                },
                series: [{
                    data: metric === MetricValues.international ? weightDatas.values : weightDatas.uk_value,
                    color: '#CCD4DA'
                }],
            }
            setOptionsHigh(options)
            setLoaded(true)
        }
    }, [weightDatas])

    const getWeightEvolution = async () => {
        await axios.get(
            config + "api/my_weight_evolution",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                },
                params: {
                    start_date: moment().subtract(6, "weeks").isoWeekday(1).format("YYYY-MM-DD"),
                    end_date: moment().format("YYYY-MM-DD"),
                    type: "weekly"
                }
            },
        ).then((response) => {
            let datas = response.data
            let dataCategories = []
            let dataValues = []
            let dataUkValues = []
            for(let data of datas) {
                dataCategories.push(t('app.date.week_initial')+"."+data.week_number)
                let weight = data.weight
                dataValues.push(weight ? weight.value : null)
                dataUkValues.push(weight ? weight.uk_value : null)
            }
            setCategories(dataCategories)

            let newWeightDatas = {
                values: dataValues,
                uk_values: dataUkValues,
            }

            setWeightDatas(newWeightDatas)

        }).catch(error => {
            handleEndpointError(error)
        })
    }

    const openCloseEdit = () => {
        setInEdit(!inEdit)
    }

    return (
        <div className="dashboard-card pc-3 mcb-4">
            <div className="d-flex justify-content-start">
                <p className="monitoring-title mb-0">
                    {t('app:athlete.weight_summary.title')}
                </p>
            </div>
            <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                  onClick={() => setIsOpen(isOpen => !isOpen)}
            >
                <i className={isOpen ? "bi bi-chevron-up": "bi bi-chevron-down"} />
            </span>
            {isOpen &&
            (inEdit ?
                    <WeightInput openCloseEdit={openCloseEdit} getWeightEvolution={getWeightEvolution}/>
                :
                    <>
                        <p className="text-left mct-2 fs-12">{t('app:athlete.weight_summary.weight_question')}</p>
                        <div className="justify-content-center d-flex mt-3">
                            <div style={{ width: '100%' }}>
                                {loaded ?
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={optionsHigh}
                                    />
                                    :
                                    <Loader
                                        type="TailSpin"
                                        color="#0069d9"
                                        height={80}
                                        width={80}
                                        className="my-5"
                                    />
                                }
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mcy-3">
                            <span className="btn-white-blue" onClick={openCloseEdit}>{t('app:athlete.weight_summary.edit_weight')}</span>
                        </div>
                        <span onClick={() => openCloseWeightEvolution()} className="edit-wellness pointer w-100 pct-2">{t('app:athlete.weight_summary.see_weight_evolution')}</span>
                    </>
            )

            }
        </div>
    )
}
export default Weight