import React, {useContext,useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavigationContext} from "../Contexts";


import CartOption from "./CartOption"
import config from "../api";
import axios from "axios/index";
import handleEndpointError from "../Functions/Alert";
import {useIsMounted} from "../Components/Tools/CustomHooks";

const Cart = ({cart, productWithOptionsData, defaultOptions, color, onUpdate}) => {

    // Traduction
    const {t} = useTranslation(['app']);
    const isMounted = useIsMounted()
    const {isMobile} = useContext(NavigationContext)


    const [checkedOptions, setCheckedOptions] = useState(defaultOptions ? defaultOptions : [])
    const [disabledOptions, setDisabledOptions] = useState([])
    const [cartLines, setCartLines] = useState([])

    const priceRecurring = (productWithOptionsData.product_price.currency_symbol ? (t('checkout.price.currency.'+productWithOptionsData.product_price.currency_symbol)) : null) +  (productWithOptionsData.product_price.recurring ? ("/" + t('checkout.price.recurring.'+ productWithOptionsData.product_price.recurring)) : null)

    useEffect(() => {
        editCart(checkedOptions)

        let newCartLines = generateCartLines()
        setCartLines(newCartLines)

    }, [checkedOptions])

    const editCart = async () => {
        await axios.put(
            config+"public/api/cart/"+ cart.id + '/options',
            {
                product_price_ids: checkedOptions
            },
        )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                // Récupérer les données du panier d'achat
                onUpdate()
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const onOptionChange = (productOption, isChecked) => {

        let id = productOption.product_price.id

        let newCheckedOptions = [...checkedOptions]
        let foundedOptionIndex = newCheckedOptions.indexOf(id)

        let newDisabledOptions = null
        // Ajouter l'option
        if(isChecked) {
            if(foundedOptionIndex === -1) {
                newCheckedOptions.push(id)
            }
            // Gérer désactivation des autres options
            if(productOption.code === 'option_unlimited_chat') {
                newDisabledOptions = ['option_message_extra_30']
            }
            else if(productOption.code === 'option_message_extra_30') {
                newDisabledOptions = ['option_unlimited_chat']
            }
        }
        // Retirer de l'option
        else{
            if(foundedOptionIndex !== -1) {
                newCheckedOptions.splice(foundedOptionIndex,1)
            }
            if(['option_message_extra_30', 'option_unlimited_chat'].indexOf(productOption.code) !== -1) {
                newDisabledOptions = []
            }
        }
        setCheckedOptions(newCheckedOptions)
        if(newDisabledOptions !== null) {
            setDisabledOptions(newDisabledOptions)
        }
    }

    // Générer une ligne du panier d'achat
    const getCartLine = (cartProduct) => {
        return {
            'id': cartProduct.product_price.id,
            'code': cartProduct.code,
            'name': cartProduct.name,
            'amount': cartProduct.product_price.amount,
            'quantity': 1
        }
    }

    // Gérer les lignes panier d'achat en fonction du produit et des options séléctionnées
    const generateCartLines = () => {
        let newCartLines = []
        // Ajouter la ligne du produit
        newCartLines.push(getCartLine(productWithOptionsData))

        // Parcourir la liste des options du produit
        for(let productOption of productWithOptionsData.options) {
            // Si option est cochée alors on ajoute une ligne produit
            if(isCheckedOptions({ id: productOption.product_price.id })) {
                newCartLines.push(getCartLine(productOption))
            }
        }

        return newCartLines
    }


    // Vérifier si une option est cochée
    const isCheckedOptions = (option) => {
        let options = [...checkedOptions]

        for(let checkedOption of options) {
            if (option.id === checkedOption) {
                return true;
            }
        }
        return false;
    }

    // Vérifier si une option est désactivée
    const isDisabledOptions = (option) => {
        let options = [...disabledOptions]

        for(let disabledOption of options) {
            if (option.code === disabledOption) {
                return true;
            }
        }
        return false;
    }

    const getProductDescriptionLabels = (productCode) => {
        let labels = []
        let number = 0
        switch(productCode) {
            case 'coaching_smart':
                number = 3
                break;
            case 'coaching_pro':
                number = 5
                break;
            case 'membership':
                number = 13
                break;
            default:
                break;
        }
        for(let i = 0; i < number; i++) {
            labels.push('subscription.'+productCode+"."+i)
        }

        return labels
    }
    const productDescriptionLabels = getProductDescriptionLabels(productWithOptionsData.code)




    return (
        <>
            <h3 className={(isMobile ? "d-flex align-items-center justify-content-between mt-5 text-left ":"")+"subscription-title mt-4"}>
                {productWithOptionsData.title}
                <span className={(isMobile ? "text-right w-100 ":"d-block ") +"checkout-total-amount checkout-"+color}>{productWithOptionsData.product_price.amount} {priceRecurring}</span>
            </h3>
            <div className="text-left">
                {productDescriptionLabels.length > 0 &&
                    <>
                        <p>{t('subscription.description.begin')} <span className="text-uppercase">{ productWithOptionsData.name }</span> {t('subscription.description.end')} :</p>
                        {productDescriptionLabels.map((label, index) =>
                            <span key={"cart-label-"+index}
                                className={"btn-checkout-"+color+" d-inline-block label-summary mt-2 fs-13"}
                                style={{ fontWeight: 'bold'}}
                            >
                                {t(label)}
                            </span>
                        )}
                    </>
                }
            </div>
            <hr/>
            <div className="options w-100">
                <h3 className={isMobile ? "w-100 text-left subscription-title subscription-title-mobile" : "w-100 text-left subscription-title"}>+ {t('cart.more_options')}</h3>
                <div className="mx-2 pt-3 mb-3 justify-content-center text-left">
                    {productWithOptionsData.options.length > 0 && productWithOptionsData.options.map((productOption) =>
                        <CartOption key={productOption.code+'-'+productOption.id}
                                    productOption={productOption}
                                    currency={productWithOptionsData.product_price.currency_symbol}
                                    checked={isCheckedOptions(productOption)}
                                    disabled={isDisabledOptions(productOption)}
                                    onChange={(productOption,isChecked) => onOptionChange(productOption,isChecked)}
                                    color={color}
                        />
                    )}
                </div>
            </div>
            <hr/>
            <h3 className={isMobile ? "text-left w-100 subscription-title subscription-title-mobile": "text-left w-100 subscription-title"}>{t('cart.recap')}</h3>
            <div className="row mct-4 w-100" >
                <div className="col-12 text-left p-0">
                    {cartLines.length > 0 && cartLines.map((cartLine,index) =>
                        <span key={"cart-line-"+index}
                            className={"btn-checkout-"+color+" mr-2 mct-2 d-inline-block label-summary"}
                             style={{ fontWeight: 'bold'}}
                        >
                            {cartLine.name}
                        </span>
                    )}
                </div>
            </div>
        </>
    )
}
export default Cart