import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {cap_set, nat_set, velo_set} from "../../../img/img";



const TypeDebriefing = ({type, onChangeType, color}) => {
    const {t} = useTranslation(['app'])

    return (
        <>
            <div className="col-3">
                <p className="gotham-15-black text-left">{t('app.notification.type')}</p>
            </div>
            <div className="col-9">
                <div className={"checkbox-container-"+color+" w-100 mcb-2"}>
                    <input
                        id={"training_radio"}
                        type="radio"
                        name="training"
                        value={3}
                        checked={type===3}
                        onChange={() => onChangeType(3)}
                    />
                    <label htmlFor={"training_radio"} className="gotham-15-black">{t('app:athlete.debriefing.type.3')}</label>
                </div>
                <div className={"checkbox-container-"+color+" w-100 mcb-2"}>
                    <input
                        id={"competition_radio"}
                        type="radio"
                        name="competition"
                        value={2}
                        checked={type===2}
                        onChange={() => onChangeType(2)}
                    />
                    <label htmlFor={"competition_radio"} className="gotham-15-black">{t('app.notification.type_label.competition')}</label>
                </div>
            </div>
        </>
    )
}

export default TypeDebriefing;