import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form} from "react-bootstrap";
import {arrow_left, validate, x_circle} from "../../../../img/img";
import {NavigationContext, WeekCalendarContext} from "../../../../Contexts";
import {getVerboseDate} from "../../../../Functions/DatesCalendar";
import axios from "axios";
import config from "../../../../api";
import generateHeaders from "../../../../Functions/generateHeaders";
import handleEndpointError from "../../../../Functions/Alert";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {format, formatISO} from "date-fns"

/**
 * Afficher un formulaire de création de blessure
 *
 * @param date
 * @param closeTraineeshipModal
 */
const NewTraineeship = ({date, closeTraineeshipModal}) => {

    const {t} = useTranslation(['app'])

    const {updateCalendarEventToRefresh} = useContext(WeekCalendarContext)

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const [name, setName] = useState("")
    const [startDate, setStartDate] = useState(date.toDate())
    const [endDate, setEndDate] = useState(date.toDate())
    const [description, setDescription] = useState("")

    const [requiredFields, setRequiredFields] = useState({
        name: false,
        start_date: true,
        end_date: true,
        description: true
    })
    const [errorMessage, setErrorMessage] = useState()

    const [ready, setReady] = useState(false)

    // Vérifier les champs obligatoires
    useEffect(() => {
        let isReady = true
        for(let requiredField of Object.values(requiredFields)) {
            if(requiredField === false){
                isReady = false
                break
            }
        }
        setReady(isReady)
    }, [requiredFields])

    const updateRequiredFields = (key, action) => {
        let value = action === 'add';
        if(requiredFields[key] !== value) {
            let newRequiredFields = {...requiredFields}
            newRequiredFields[key] = value
            setRequiredFields(newRequiredFields)
        }
    }

    const onChangeName = (value) => {
        setName(value)
        updateRequiredFields('name', value ? 'add' : 'remove')
    }

    const onChangeDescription = (value) => {
        setDescription(value)
    }
    
    const onChangeStartDate = (value) => {
        setStartDate(value)
        updateRequiredFields('start_date', value ? 'add' : 'remove')
        if(value > endDate) {
            onChangeEndDate(value)
        }
    }

    const onChangeEndDate = (value) => {
        setEndDate(value)
        updateRequiredFields('end_date', value ? 'add' : 'remove')
        if(value < startDate) {
            onChangeStartDate(value)
        }
    }
    

    // Enregister la blessure
    const postTraineeship = () => {
        const headers = generateHeaders()

        let startDateISO = formatISO(startDate)
        let endDateISO = formatISO(endDate)
        let datas = {
            name: name,
            start_date : startDateISO,
            end_date: endDateISO,
            description: description
        }

        axios
            .post(
                config+"api/traineeships/new",
                datas,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then((response) => {
                // Fermer le formulaire de création de compte-rendu
                closeTraineeshipModal(format(startDate, 'yyyy-MM-dd'))

                updateCalendarEventToRefresh("traineeship-" + response.data.id)
            })
            .catch(error => {
                handleEndpointError(error)
                if(error.response !== undefined) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.data.errors)
                    }
                }
            })
    }

    return (
        <div id="traineeship-card" className={!isMobile ? "col-xl-4 p-3 mb-4 card-float-dashboard" : undefined}>
            <div id="alert-card-header" className="dashboard-card background-white">
                <div className={"d-flex pct-3 pcx-3 "+ (isMobile ? 'justify-content-start': 'justify-content-between')}>
                    {isMobile &&
                    <img
                        className="icon-24 mcr-2 pointer"
                        src={arrow_left}
                        alt="arrow left icon"
                        onClick={() => closeTraineeshipModal()}
                    />
                    }
                    <div>
                        <p className="settings-title mb-0">
                            {t('app:athlete.add_event.traineeship')}
                        </p>
                        <p className="gotham-12 color-grey-light text-left">
                            {getVerboseDate(date)}
                        </p>
                    </div>
                    {!isMobile &&
                    <img
                        className="icon-24 pointer right"
                        src={x_circle}
                        alt="close icon"
                        onClick={() => closeTraineeshipModal()}
                    />
                    }
                </div>
                <div id="rpe-card" className="rpe-card pcx-3 pcy-3">
                    <div className="activity-CR mcb-4">
                        <div className="row">
                            {/* Nom de la blessure */}
                            <div className="col-3 mcy-2 d-flex align-items-center pr-0">
                                <span className="label-input-athlete-event">{t('app:athlete.add_event.name')}</span>
                            </div>
                            <div className="col-9 mcy-2">
                                <input type="text"
                                       className="w-100 form-input-text pcx-3 pcy-2 background-white"
                                       name="name"
                                       placeholder={t('app:athlete.add_event.name')}
                                       value={name}
                                       onChange={event => onChangeName(event.target.value)}
                                />
                            </div>
                            {/* Date de début */}
                            <div className="col-3 mcy-2 d-flex align-items-center pr-0">
                                <span className="label-input-athlete-event">{t('app:athlete.add_event.start_date')}</span>
                            </div>
                            <div className="col-9 mcy-2">
                                <DatePicker
                                    wrapperClassName="w-100"
                                    className="custom-input w-100"
                                    dateFormat="P"
                                    selected={startDate}
                                    onChange={(date) => onChangeStartDate(date)}
                                    selectsStart
                                    startDate={startDate}
                                    endDate={endDate}
                                />
                            </div>
                            {/* Date de fin */}
                            <div className="col-3 mcy-2 d-flex align-items-center pr-0">
                                <span className="label-input-athlete-event">{t('app:athlete.add_event.end_date')}</span>
                            </div>
                            <div className="col-9 mcy-2">
                                <DatePicker
                                    wrapperClassName="w-100"
                                    className="custom-input w-100"
                                    dateFormat="P"
                                    selected={endDate}
                                    onChange={(date) => onChangeEndDate(date)}
                                    selectsEnd
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                />
                            </div>

                            {/* Description pour une blessure */}
                            <div className="col-12 mct-2">
                                <p className="rpe-title text-left">{t('app:athlete.add_event.description')}</p>
                            </div>
                            <div className="col-12">
                                <Form.Group controlId="form-description">
                                    <Form.Control className="mcy-2 form-login-input pc-3 background-white"
                                                  as="textarea"
                                                  rows={2}
                                                  name="description"
                                                  placeholder={t('app:athlete.debriefing.comment.placeholder')}
                                                  value={description}
                                                  onChange={event => onChangeDescription(event.target.value)}
                                    >
                                    </Form.Control>
                                </Form.Group>
                            </div>

                            <div className="w-100 mct-5 d-flex justify-content-center">
                                {errorMessage &&
                                <div className="error-login-text mcb-3">
                                    {errorMessage}
                                </div>
                                }
                                <Button className="background-black py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text"
                                    onClick={() => postTraineeship()}
                                        disabled={!ready}
                                >
                                    <img className="mr-2 icon-24" alt="valid icon" src={validate} />{t('app:athlete.saving.save')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewTraineeship;