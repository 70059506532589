import React, {useState, useEffect} from 'react';
import CustomSelect from "../Tools/CustomSelect"

const SelectSportCompetition = ({sportCompetitions, defaultSportCompetition, onChange, sportValue}) => {

    const [defaultValue, setDefaultValue] = useState(defaultSportCompetition)


    // Sélectionner la valeur par défaut lors de la mise à jour de la liste
    useEffect(() => {
        if(sportCompetitions && sportCompetitions.length > 0){
            let inSportCompetitions = false
            if(defaultValue) {
                for(let c of sportCompetitions) {
                    if(c.id === defaultValue.id) {
                        inSportCompetitions = true
                        break
                    }
                }
            }

            if(!inSportCompetitions) {
                let sportCompetitionValue = sportCompetitions[0]
                setDefaultValue(sportCompetitionValue)
                onChangeValue(sportCompetitionValue)
            }
        }
    }, [sportCompetitions])

    const onChangeValue = (value) => {
        onChange(value)
    }

    // if(sportCompetitions === null || sportCompetitions.length === 0) {
    //     return (<>Aucun format</>)
    // }

    return (
        <CustomSelect
            className="w-100 text-left"
            name="sport_competition"
            options={sportCompetitions}
            defaultValue={defaultValue}
            value={sportValue}
            onChange={(value) => onChangeValue(value)}
        />
    )
}

export default SelectSportCompetition;