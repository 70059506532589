import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {helpCircle} from "../../src/img/img";
import {scroller, Element as ScrollerElement } from "react-scroll";
import Loader from "react-loader-spinner";
import ReactTooltip from "react-tooltip";
import { animations } from 'react-animation'
import {LanguageContext, NavigationContext} from "../Contexts";
import '../Css/subscription.css';
import axios from "axios";
import config from "../api";
import handleEndpointError from "../Functions/Alert";
import DatePicker from "react-datepicker";
import {format as formatDate} from "date-fns";
import moment from "moment";
import generateHeaders from "../Functions/generateHeaders";
import {TrainingProgramTypes, TrainingProgramSports} from "./Utils"
import TrainingProgramDuration from "../SearchTrainingProgram/TrainingProgramDuration";
import TrainingProgramVolume from "../SearchTrainingProgram/TrainingProgramVolume";
import TrainingProgramFormat from "../SearchTrainingProgram/TrainingProgramFormat";
import TrainingProgramPhase from "../SearchTrainingProgram/TrainingProgramPhase";

const TrainingProgram = ({title, theme, type, sport, onChangeTrainingProgram, onChangeStartDate, onChangeSummary}) => {
    const {t} = useTranslation(['app']);

    const {isMobile} = useContext(NavigationContext)
    const {language} = useContext(LanguageContext)
    const isProgression = type === TrainingProgramTypes.PROGRESSION ? true : false
    const isCompetition = type === TrainingProgramTypes.COMPETITION ? true : false
    const currentDate = new Date()
    const hasPower = [TrainingProgramSports.TRIATHLON, TrainingProgramSports.CYCLISME, TrainingProgramSports.DUATHLON, TrainingProgramSports.RAID].indexOf(sport) !== -1 ? true : false
    const defaultPowerSensor = hasPower === false ? {id:0, value: 0, label: null} : null
    const isVolumeInHour = [TrainingProgramSports.TRIATHLON, TrainingProgramSports.DUATHLON, TrainingProgramSports.RAID].indexOf(sport) !== -1 ? true : false

    const [displayDate, setDisplayDate] = useState(false)
    const [displayDuration, setDisplayDuration] = useState(false)
    const [displayVolume, setDisplayVolume] = useState(false)
    const [displayTest, setDisplayTest] = useState(false)
    const [displayPowerSensor, setDisplayPowerSensor] = useState(false)

    const [filteredFormats, setFilteredFormats] = useState([])
    const [filteredPhases, setFilteredPhases] = useState([])
    const [filteredDurations, setFilteredDurations] = useState([])
    const [filteredTests, setFilteredTests] = useState([])
    const [filteredVolumes, setFilteredVolumes] = useState([])
    const [filteredPowerSensors, setFilteredPowerSensors] = useState([])

    const [trainingProgramFilters, setTrainingProgramFilters] = useState(null)

    // const [sportName, setSportName] = useState(null)
    const [format, setFormat] = useState(null)
    const [phase, setPhase] = useState(null)
    const [competitionDate, setCompetitionDate] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [duration, setDuration] = useState(null)
    const [volume, setVolume] = useState(null)
    const [test, setTest] = useState(null)
    const [powerSensor, setPowerSensor] = useState(defaultPowerSensor)
    const [powerSensorValue, setPowerSensorValue] = useState(null)
    const [enabledFilters, setEnabledFilters] = useState({
        title: title,
        format: null,
        phase: null,
        duration: null,
        volume : null,
        test : null,
        powerSensor: defaultPowerSensor
    });

    const [trainingProgram, setTrainingProgram] = useState(null)

    const [minDateCompetition, setMinDateCompetition] = useState(null)


    const validEnabledFilters = () => {
        if(    enabledFilters.title !== null
            && enabledFilters.duration !== null
            && enabledFilters.volume !== null
            && enabledFilters.test !== null
            && enabledFilters.powerSensor !== null
            && (   isProgression && enabledFilters.phase
                || isCompetition && enabledFilters.format)
        ) {
            return true
        }

        return false
    }

    useEffect(() => {
        // Récupérer les filtres
        initTrainingProgramFilters()
    }, [language])


    useEffect(() => {
        onChangeSummary(enabledFilters)
    }, [enabledFilters])

    useEffect(() => {
        if (isCompetition && filteredDurations.length>0 && minDateCompetition === null) {
            let minStartDate = moment().isoWeekday(8)
            setMinDateCompetition(minStartDate.add(filteredDurations[0].value, "week").toDate())
        }
    }, [filteredDurations])

    useEffect(() => {
        if (isCompetition && duration) {
            let minStartDate = moment().isoWeekday(8)
            setMinDateCompetition(minStartDate.add(duration.value, "week").toDate())
        }
    }, [duration])

    // useEffect(() => {
    //     let name = null
    //     if(isProgression && phase) {
    //         name = phase.label
    //     }
    //     else if(isCompetition && format) {
    //         name = format.label
    //     }
    //     if(name) {
    //         let value = sportName + " " +name
    //         setTitle(value)
    //         updateEnabledFilters('title', value)
    //     }
    // }, [format, phase])


    useEffect(() => {
        // Mettre à jour les filtres disponibles
        if(trainingProgramFilters) {
            updateAvailableFilters()
        }

        if(    sport !== null
            && duration !== null
            && test !== null
            && volume !== null
            && powerSensor !== null
            && (   isProgression && phase !== null
                || isCompetition && format !== null)
        ) {
            getTrainingProgram()
        }
    }, [trainingProgramFilters, format, phase, duration, volume, test, powerSensor])


    useEffect(() => {
        // Réinitialiser le choix de la durée s'il n'est pas disponible
        if(format !== null) {
            let founded = false
            for(let element of filteredFormats) {
                if(element.id === format.id) {
                    founded = true
                    break
                }
            }
            if(!founded) {
                onChangeFormat(null)
            }
        }
    }, [filteredFormats, format])

    useEffect(() => {
        // Réinitialiser le choix de la durée s'il n'est pas disponible
        if(phase !== null) {
            let founded = false
            for(let element of filteredPhases) {
                if(element.id === phase.id) {
                    founded = true
                    break
                }
            }
            if(!founded) {
                onChangePhase(null)
            }
        }
    }, [filteredPhases, phase])


    useEffect(() => {
        // Réinitialiser le choix de la durée s'il n'est pas disponible
        if(duration !== null) {
            let founded = false
            for(let element of filteredDurations) {
                if(element.id === duration.id) {
                    founded = true
                    break
                }
            }
            if(!founded) {
                onChangeDuration(null)
            }
        }
    }, [filteredDurations, duration])

    useEffect(() => {
        // Réinitialiser le choix de la période de test s'il n'est pas disponible
        if(test !== null) {
            let founded = false
            for(let element of filteredTests) {
                if(element.id === test.id) {
                    founded = true
                    break
                }
            }
            if(!founded) {
                onChangeTest(null)
            }
        }
    }, [filteredTests, test])

    useEffect(() => {
        // Réinitialiser le choix du volume s'il n'est pas disponible
        if(volume !== null) {
            let founded = false
            for(let element of filteredVolumes) {
                if(element.id === volume.id) {
                    founded = true
                    break
                }
            }
            if(!founded) {
                onChangeVolume(null)
            }
        }
    }, [filteredVolumes, volume])

    useEffect(() => {
        if(hasPower) {
            // Réinitialiser le choix du capteur s'il n'est pas disponible
            if(powerSensor !== null) {
                let founded = false
                for(let element of filteredPowerSensors) {
                    if(element.id === powerSensor.id) {
                        founded = true
                        break
                    }
                }
                if(!founded) {
                    onChangePowerSensor(null)
                }
            }
        }
    }, [filteredPowerSensors, powerSensor])


    // Type Progression
    useEffect(() => {
        // Calculer la date de fin en fonction de la date de début et de la durée du programme
        if(isProgression && startDate && duration) {
            let date = moment(startDate.toString()).add(duration.value, 'w').subtract(1, 'days')
            setEndDate(date)
        }
    }, [duration, startDate])

    // Type Compétition
    useEffect(() => {
        // Calculer la date de début en fonction de la date de compétition et de la durée du programme
        if(isCompetition && competitionDate && duration) {
            let date = moment(competitionDate.toString()).subtract(duration.value, 'w')
            let isoWeekdayNumber = date.isoWeekday()
            if(isoWeekdayNumber === 7) {
                date.add(1, 'days')
            }
            else if (isoWeekdayNumber !==1) {
                date.subtract(isoWeekdayNumber-1, 'days')
            }
            updateStartDate(date.toDate())
        }
    }, [duration, competitionDate])


    useEffect(() => {
        let value = validEnabledFilters() ? trainingProgram : null
        onChangeTrainingProgram(value)
    }, [trainingProgram])


    useEffect(() => {
        // Scroller vers le choix suivant
        let elementName = null
        if(displayDate) {
            elementName = 'dateElement'
        }
        if(displayDuration) {
            elementName = 'durationElement'
        }
        else if(displayTest) {
            elementName = 'testElement'
        }
        else if(displayVolume) {
            elementName = 'volumeElement'
        }
        else if(displayPowerSensor) {
            elementName = 'powerSensorElement'
        }
        if(elementName) {
            scroller.scrollTo(elementName, {
                duration: 1000,
                delay: 50,
                smooth: 'easeOutQuart',
                offset: 50
            })
        }
    }, [displayDate, displayDuration, displayTest, displayVolume, displayPowerSensor])


    const showTestRadioButton = (value) => {
        for (let element of filteredTests) {
            if(element.value === value) {
                return element
            }
        }
        return false
    }

    const showPowerSensorsRadioButton = (value) => {
        for (let element of filteredPowerSensors) {
            if(element.value === value) {
                return element
            }
        }
        return false
    }

    const updateCompetitionDate = (value) => {
        setCompetitionDate(value)
    }

    // Date de début
    const isMonday = date => {
        const day = date.getDay();
        return day === 1;
    };
    const updateStartDate = (value) => {
        setStartDate(value)
        onChangeStartDate(value)
    }

    const initTrainingProgramFilters = async () => {
        await axios.get(
            config+"public/api/training_program_filters",
            {
                params: {
                    training_program_theme_id: theme,
                    locale: language
                }
            }
        )
            .then(response => {
                for (let typeData of response.data.filters.type) {
                    if (typeData.value === type) {
                        for (let sportData of typeData.sport) {
                            if (sportData.value === sport) {
                                let sportDatas = []
                                if(isProgression) {
                                    sportDatas = sportData.phase
                                }
                                else if(isCompetition) {
                                    sportDatas = sportData.format
                                }
                                setTrainingProgramFilters(sportDatas)
                                break
                            }
                        }
                        break
                    }
                }
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const getTrainingProgram = async() => {

        let params = {
            training_program_theme_id: theme,
            type: type,
            locale: 'fr',
            sport: sport,
            test_period: test.value,
            power_sensor: powerSensor.value,
            duration: duration.id,
            volume_in_hour: isVolumeInHour ? volume.id : null,
            volume_in_session: !isVolumeInHour ? volume.id : null,
        }
        switch(type) {
            case TrainingProgramTypes.PROGRESSION:
                params.phase = phase.value
                break
            case TrainingProgramTypes.COMPETITION:
                params.format = format.value
                break
            default:
                break
        }

        await axios.get(
            config+"public/api/training_programs",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                },
                params: params
            },
        ).then(response => {
            let trainingPrograms = response.data.trainingPrograms
            setTrainingProgram(trainingPrograms.length ? trainingPrograms[0] : null)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const updateAvailableFilters = () => {

        let sportData = isProgression ? phase : (isCompetition ? format : null)

        let sportDatas = []
        for(let filteredSportData of trainingProgramFilters) {
            let sportDataObject = {
                label: filteredSportData.name,
                value: filteredSportData.value,
                id: filteredSportData.value
            }
            sportDatas.push(sportDataObject)
            if(sportData !== null && filteredSportData.value === sportData.value) {
                let durations = []
                for (let filteredDuration of filteredSportData.duration) {
                    let durationObject = {
                        label: filteredDuration.name,
                        value: filteredDuration.value,
                        id: filteredDuration.value
                    }
                    durations.push(durationObject)
                    if (duration !== null && filteredDuration.value === duration.value) {
                        let tests = []
                        for (let filteredTest of filteredDuration.test) {
                            let testObject = {
                                label: filteredTest.name,
                                value: filteredTest.value,
                                id: filteredTest.value
                            }
                            tests.push(testObject)
                            if (test !== null && filteredTest.value === test.value) {
                                let volumes = []
                                let filteredTestVolumes = isVolumeInHour ? filteredTest.volume_in_hour : filteredTest.volume_in_session;
                                for (let filteredVolume of filteredTestVolumes) {
                                    let volumeObject = {
                                        label: filteredVolume.name,
                                        value: filteredVolume.value,
                                        id: filteredVolume.value
                                    }
                                    volumes.push(volumeObject)
                                    if (volume !== null && filteredVolume.value == volume.value) {
                                        let powersSensors = []
                                        for (let filteredPowerSensor of filteredVolume.power_sensor) {
                                            let powerSensorObject = {
                                                label: filteredPowerSensor.name,
                                                value: filteredPowerSensor.value,
                                                id: filteredPowerSensor.value
                                            }
                                            powersSensors.push(powerSensorObject)
                                        }
                                        // if (powersSensors.length > 0) {
                                        setFilteredPowerSensors(powersSensors)
                                        // }
                                    }
                                }
                                // if (volumes.length > 0) {
                                setFilteredVolumes(volumes)
                                // }
                            }
                        }
                        // if (tests.length > 0) {
                        setFilteredTests(tests)
                        // }

                    }
                }
                // if (durations.length > 0) {
                setFilteredDurations(durations)
                // }
            }

        }
        if(isProgression) {
            setFilteredPhases(sportDatas)
        }
        else if(isCompetition) {
            setFilteredFormats(sportDatas)
        }
    }

    const updateEnabledFilters = (key, value) => {
        if(enabledFilters[key] !== value) {
            let newEnabledFilters = {...enabledFilters}
            newEnabledFilters[key] =  value
            setEnabledFilters(newEnabledFilters)
            return newEnabledFilters
        }
    }

    const onChangeFormat = (value) => {
        setFormat(value)
        updateEnabledFilters('format', value)
        setDisplayDate(true)
    }

    const onChangePhase = (value) => {
        setPhase(value)
        updateEnabledFilters('phase', value)
        setDisplayDate(true)
    }

    const onChangeDuration = (value) => {
        setDuration(value)
        updateEnabledFilters('duration', value)
        setDisplayTest(true)
    }


    const onChangeTest = (value) => {
        setTest(value)
        updateEnabledFilters('test', value)
        setDisplayVolume(true)
    }
    const onChangeVolume = (value) => {
        setVolume(value)
        updateEnabledFilters('volume', value)
        setDisplayPowerSensor(true)
    }

    const onChangePowerSensor = (value) => {
        setPowerSensorValue(value)

        // Faker proxy
        let element = null
        if(value === 0 || value === 1) {
            element = showPowerSensorsRadioButton(value)
            if(element === false) {
                element = showPowerSensorsRadioButton(value? 0 : 1)
                element.label = null
            }
        }
        setPowerSensor(element)
        updateEnabledFilters('powerSensor', element)
    }

    const getDataTipDuration = () => {
        return (
            <div className="fs-12 text-left">
                <p className="mb-0">{t('training_program.tooltip.duration.0')}</p>
                {isCompetition &&
                <p className="mb-0">{t('training_program.tooltip.duration.1')}</p>
                }
            </div>
        )
    }

    const getDataTipVolume = () => {
        return (
            <div className="fs-12 text-left">
                <p>{t('training_program.tooltip.volume')}</p>
            </div>
        )
    }

    const getDataTipTests = () => {
        return (
            <div className="fs-12 text-left">
                <p className="mb-0">{t('training_program.tooltip.tests.0')}</p>
                <p className="mb-0">{t('training_program.tooltip.tests.1')}</p>
                <p className="mb-0">{t('training_program.tooltip.tests.2')}</p>
            </div>
        )
    }

    const getDataTipPowerSensors = () => {
        return (
            <div className="fs-12 text-left">
                <p className="mb-0">{t('training_program.tooltip.tests.0')}</p>
                <p className="mb-0">{t('training_program.tooltip.tests.1')}</p>
                <p className="mb-0">{t('training_program.tooltip.tests.2')}</p>
            </div>
        )
    }

    function getLoader () {
        return (
            <Loader
                type="ThreeDots"
                color="#0069d9"
                height={50}
                width={50}
                className="my-2"
            />
        )
    }

    return (
        <div className="text-left w-100 program-choices my-3">
            { isProgression &&
            <>
                {filteredPhases.length ?
                    <React.Fragment key="choose-phase">
                        <p className="text-left">
                            {t("app:training_program.phase")}
                        </p>
                        <div className="d-flex phase-program">
                            <TrainingProgramPhase
                                phase={phase}
                                onChangePhase={(value) => onChangePhase(value)}
                                phases={filteredPhases}
                            />
                        </div>
                    </React.Fragment>
                    :
                    getLoader()
                }
            </>
            }
            { isProgression && displayDate &&
            <ScrollerElement name="dateElement" key="choose-start-date">
                <label htmlFor={"date-progression"}
                       className="text-left mct-3"
                >
                    {t("app:training_program.start_date")}
                </label>
                <DatePicker
                    wrapperClassName="w-100"
                    className="custom-input w-50 mcb-3"
                    dateFormat="P"
                    name="date-progression"
                    selected={startDate}
                    onChange={date => {
                        updateStartDate(date)
                        setDisplayDuration(true)
                    }}
                    filterDate={isMonday}
                    minDate={new Date()}
                />
            </ScrollerElement>
            }
            { isCompetition &&
            <>
                {filteredFormats.length ?
                    <React.Fragment key="choose-format">
                        <p className="text-left" >
                            {t("app:training_program.sport_competition")}
                        </p>
                        <div className="d-flex format-program">
                            <TrainingProgramFormat
                                format={format}
                                onChangeFormat={(value) => onChangeFormat(value)}
                                formats={filteredFormats}
                            />
                        </div>
                    </React.Fragment>
                    :
                    getLoader()
                }
            </>
            }
            { isCompetition && displayDate &&
            <ScrollerElement name="dateElement" key="choose-competition-date">
                <label htmlFor={"date-competition"}
                       className="text-left mct-3"
                >
                    {t("app:training_program.compet_date")}
                </label>
                <DatePicker
                    wrapperClassName="w-100"
                    className="custom-input w-50 mcb-3"
                    dateFormat="P"
                    name="date-competition"
                    selected={competitionDate}
                    onChange={date => {
                        updateCompetitionDate(date)
                        setDisplayDuration(true)
                    }}
                    minDate={minDateCompetition}
                />

            </ScrollerElement>
            }
            { displayDuration &&
            <ScrollerElement name="durationElement" key="choose-duration">
                <p className="text-left"
                   data-for="trainingProgramDurationHelper"
                   data-tip={""}
                   data-event='click'
                >
                    {t("app:training_program.prepa_duration")}
                    <img className="icon-16 mcb-2 mcl-2"
                         src={helpCircle}
                    />
                </p>
                <ReactTooltip key="tooltip-training-program-duration"
                              id="trainingProgramDurationHelper"
                              type="light"
                              multiline={true}
                              textColor="#000000"
                              backgroundColor="#F5F5F5"
                              className="tooltip"
                              effect="solid"
                              place="bottom"
                              clickable={true}
                              getContent={() => getDataTipDuration()}
                />
                <div className="d-flex w-100 duration-program">
                    <TrainingProgramDuration
                        className="pct-0"
                        duration={duration}
                        onChangeDuration={(value) => onChangeDuration(value)}
                        durations={filteredDurations}
                    />
                    {type == TrainingProgramTypes.COMPETITION && duration && startDate &&

                    <p className="text-subscription-program text-left mcl-2"
                       style={startDate < currentDate
                           ? {animation: `${animations.fadeIn} 1s infinite`, animationDuration: "2s", color: 'red'}
                           : {color: "#007DFF"}}
                    >
                        {t("app:training_program.start_prepa")} {formatDate(startDate, 'dd/MM/yyyy')}
                    </p>
                    }
                </div>
            </ScrollerElement>
            }
            { displayTest &&
            <ScrollerElement name="testElement" key="choose-test-period">
                <p className="text-left mct-3"
                   data-for="trainingProgramTestsHelper"
                   data-tip={""}
                   data-event='click'
                >
                    {t("app:training_program.tests_zi")}
                    <img className="icon-16 mcb-2 mcl-2"
                         src={helpCircle}
                    />
                </p>
                <ReactTooltip key="tooltip-training-program-tests"
                              id="trainingProgramTestsHelper"
                              type="light"
                              multiline={true}
                              textColor="#000000"
                              backgroundColor="#F5F5F5"
                              className="tooltip"
                              effect="solid"
                              place="bottom"
                              clickable={true}
                              getContent={() => getDataTipTests()}
                />
                <div className="d-flex">
                    <div className="checkbox-container-blue w-100 mcb-2">
                        <input
                            id="yes_test_intensity_radio"
                            name="test_intensity"
                            type="radio"
                            value="1"
                            checked={test !== null && test.value === 1}
                            onClick={() => onChangeTest(showTestRadioButton(1))}
                            onChange={() => {}}
                            disabled={!showTestRadioButton(1)}
                        />
                        <label htmlFor={"yes_test_intensity_radio"}
                               className={isMobile ? "text-subscription-program align-items-center" : "align-items-center text-subscription-program-desktop"}
                        >
                            {t("app:training_program.yes")}
                        </label>
                    </div>

                    <div className="checkbox-container-blue w-100 mcb-2">
                        <input
                            id="no_test_intensity_radio"
                            name="test_intensity"
                            type="radio"
                            value="0"
                            checked={test !== null && test.value === 0}
                            onClick={() => onChangeTest(showTestRadioButton(0))}
                            onChange={() => {}}
                            disabled={!showTestRadioButton(0)}
                        />
                        <label htmlFor={"no_test_intensity_radio"}
                               className={isMobile ? "text-subscription-program align-items-center" : "align-items-center text-subscription-program-desktop"}
                        >
                            {t("app:training_program.no")}
                        </label>
                    </div>
                </div>
            </ScrollerElement>
            }
            { displayVolume &&
            <ScrollerElement name="volumeElement" key="choose-volume">
                <p className="text-left"
                   data-for="trainingProgramVolumeHelper"
                   data-tip={""}
                   data-event='click'
                >
                    {t("app:training_program.volume_training_hebdo")}
                    <img className="icon-16 mcb-2 mcl-2"
                         src={helpCircle}

                    />
                </p>
                <ReactTooltip key="tooltip-training-program-volume"
                              id="trainingProgramVolumeHelper"
                              type="light"
                              multiline={true}
                              textColor="#000000"
                              backgroundColor="#F5F5F5"
                              className="tooltip"
                              effect="solid"
                              place="bottom"
                              clickable={true}
                              getContent={() => getDataTipVolume()}
                />
                <div className="d-flex volume-program">
                    <TrainingProgramVolume
                        volume={volume}
                        onChangeVolume={(value) => onChangeVolume(value)}
                        volumes={filteredVolumes}
                    />
                </div>
            </ScrollerElement>
            }
            { hasPower && displayPowerSensor &&
            <ScrollerElement name="powerSensorElement" key="choose-power-sensor">
                <p className="text-left mct-3"
                   data-for="trainingProgramPowerSensorsHelper"
                   data-tip={""}
                   data-event='click'
                >
                    {t("app:training_program.power_sensors")}
                    <img className="icon-16 mcb-2 mcl-2"
                         src={helpCircle}
                    />
                </p>
                <ReactTooltip key="tooltip-training-program-power-sensors"
                              id="trainingProgramPowerSensorsHelper"
                              type="light"
                              multiline={true}
                              textColor="#000000"
                              backgroundColor="#F5F5F5"
                              className="tooltip"
                              effect="solid"
                              place="bottom"
                              clickable={true}
                              getContent={() => getDataTipPowerSensors()}
                />
                <div className="power-sensors d-flex">
                    <div className="checkbox-container-blue w-100 mcb-2">
                        <input
                            id="yes_power_sensors_radio"
                            type="radio"
                            name="power_sensors"
                            value="1"
                            checked={powerSensorValue !== null && powerSensorValue === 1}
                            // onClick={() => onChangePowerSensor(showPowerSensorsRadioButton(1))}
                            onClick={() => onChangePowerSensor(1)}
                            onChange={() => {}}
                            // disabled={!showPowerSensorsRadioButton(1)}
                        />
                        <label htmlFor={"yes_power_sensors_radio"}
                               className={isMobile ? "text-subscription-program align-items-center":"align-items-center text-subscription-program-desktop"}
                        >
                            {t("app:training_program.i_have")}
                        </label>
                    </div>

                    <div className="checkbox-container-blue w-100 mcb-2">
                        <input
                            id="no_power_sensors_radio"
                            type="radio"
                            name="power_sensors"
                            value="0"
                            checked={powerSensorValue !== null && powerSensorValue === 0}
                            // onClick={() => onChangePowerSensor(showPowerSensorsRadioButton(0))}
                            onClick={() => onChangePowerSensor(0)}
                            onChange={() => {}}
                            // disabled={!showPowerSensorsRadioButton(0)}
                        />
                        <label htmlFor={"no_power_sensors_radio"}
                               className={isMobile ? "text-subscription-program align-items-center" : "align-items-center text-subscription-program-desktop"}
                        >
                            {t("app:training_program.i_have_not")}
                        </label>
                    </div>
                </div>
            </ScrollerElement>
            }
        </div>
    )
}
export default TrainingProgram