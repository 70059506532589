export const TrainingZoneTypes = {
    PRIMARY: 'primary_zones',
    SECONDARY: 'secondary_zones'
}

export const DataZones = [
    {
        name: "Supramax",
        color: "#95370E",
        path_type_only: null,
        disciplines_only: null,
        rpe: "9-10",
        code: "supramax",
        hr_code: null
    },
    {
        name: "Z3ii",
        color: "#BE0912",
        path_type_only: TrainingZoneTypes.SECONDARY,
        disciplines_only: null,
        rpe: "8",
        code: "z3ii",
        hr_code: "hr_z3ii"
    },
    {
        name: "Z3i",
        color: "#F15700",
        path_type_only: TrainingZoneTypes.SECONDARY,
        disciplines_only: null,
        rpe: "7",
        code: "z3i",
        hr_code: "hr_z3i"
    },
    {
        name: "Z3",
        color: "#F15700",
        path_type_only: TrainingZoneTypes.PRIMARY,
        disciplines_only: null,
        rpe: "7-8",
        code: "z3",
        hr_code: "hr_z3"
    },
    {
        name: "Z2ii",
        color: "#FBAFA2",
        path_type_only: TrainingZoneTypes.SECONDARY,
        disciplines_only: null,
        rpe: "6",
        code: "z2ii",
        hr_code: null
    },
    {
        name: "Z2i",
        color: "#FFA800",
        path_type_only: TrainingZoneTypes.SECONDARY,
        disciplines_only: null,
        rpe: "5",
        code: "z2i",
        hr_code: null
    },
    {
        name: "Z2",
        color: "#FFA800",
        path_type_only: TrainingZoneTypes.PRIMARY,
        disciplines_only: null,
        rpe: "5-6",
        code: "z2",
        hr_code: "hr_z2"
    },
    {
        name: "Tempo",
        color: "#3EC6FF",
        path_type_only: TrainingZoneTypes.SECONDARY,
        disciplines_only: ["cap", "velo"],
        rpe: "4",
        code: "tempo",
        hr_code: null
    },
    {
        name: "Pacing 70.3",
        color: "#3EC6FF",
        path_type_only: TrainingZoneTypes.SECONDARY,
        disciplines_only: ["cap", "velo"],
        rpe: "4",
        code: "pacing_70_3",
        hr_code: null
    },
    {
        name: "Z1ii Pacing",
        color: "#3EC6FF",
        path_type_only: TrainingZoneTypes.SECONDARY,
        disciplines_only: ["nat"],
        rpe: "3-4",
        code: "z1ii_pacing",
        hr_code: null
    },
    {
        name: "Z1ii",
        color: "#3EC6FF",
        path_type_only: TrainingZoneTypes.SECONDARY,
        disciplines_only: ["cap", "velo"],
        rpe: "3-4",
        code: "z1ii",
        hr_code: "hr_z1ii",
    },
    {
        name: "Z1i",
        color: "#00D254",
        path_type_only: TrainingZoneTypes.SECONDARY,
        disciplines_only: null,
        rpe: "1-2",
        code: "z1i",
        hr_code: "hr_z1i",
    },
    {
        name: "Z1",
        color: "#00D254",
        path_type_only: TrainingZoneTypes.PRIMARY,
        disciplines_only: null,
        rpe: "1-4",
        code: "z1",
        hr_code: "hr_z1",
    }
]

export const getCycleValues = (param = 'distance') =>
{
    let values;
    switch(param) {
        case 'duration':
            values = [10,30,60,3*60,5*60,7*60,10*60,15*60,20*60,30*60,45*60,3600,1.5*3600,2*3600,2.5*3600,3*3600,3.5*3600,4*3600,4.5*3600,5*3600,5.5*3600,6*3600,6.5*3600,7*3600];
            break;
        case 'distance':
            values = [200, 500, 1000, 3000, 4000, 10000, 20000, 30000, 40000, 50000, 80000, 90000, 100000, 120000, 140000, 180000, 200000];
            break;
        default:
            break;
    }
    return transformWithLabel(param, values);
}

export const getRunningValues = (param = 'distance') => {
    let values;
    switch(param) {
        case 'duration':
            values = [6, 10, 30, 60, 3*60, 5*60, 7*60, 10*60, 15*60, 20*60, 30*60, 45*60, 3600, 1.5*3600, 2*3600,2.5*3600,3*3600,3.5*3600,4*3600,4.5*3600,5*3600,5.5*3600];
            break;
        case 'distance':
            values = [100,200,400,500,800,1000,1500,3000,5000,10000,20000,21100,42195,50000,100000];
            break;
        default:
            break;
    }
    return transformWithLabel(param, values);
}

export const getSwimValues = (param = 'distance') => {
    let values;
    switch(param) {
        case 'distance':
            values = [25, 50, 100, 200, 400, 800, 1000, 1500, 1900, 2000, 3800];
            break;
        default:
            break;
    }
    return transformWithLabel(param, values, 'nat');
}

const transformWithLabel = (param, values, discipline = 'other') => {
    let arrayValues = []
    switch(param) {
        case 'duration':
            for (let i=0;i<values.length;i++) {
                let obj = {
                    id: i,
                    label: secondsToOther(values[i]),
                    value: values[i]
                }
                arrayValues.push(obj)
            }
            break;
        case 'distance':
                for (let i=0;i<values.length;i++) {
                    let label = discipline === 'nat' || values[i] < 1000 ? values[i]+' m' : values[i]/1000+' km'
                    let obj = {
                        id: i,
                        label: label,
                        value: values[i]
                    }
                    arrayValues.push(obj)
                }
            break;
        default:
            break;
    }
    return arrayValues
}

const secondsToOther = (sec) => {
    let hours = 0
    let minutes = 0
    let seconds = 0
    if (sec >= 3600) {
        hours   = Math.floor(sec / 3600); // get hours
        minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    }
    else if (sec >= 60) {
        minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    } else {
        seconds = sec - (minutes * 60); //  get seconds
    }

    // if (hours !== '' && hours < 10) {hours = "0"+hours;}
    // if (minutes !== '' && minutes < 10) {minutes = "0"+minutes;}
    // if (seconds !== '' && seconds < 10) {seconds = "0"+seconds;}
    // if (hours !== 0) {hours = hours+"h"}
    // if (minutes !== 0) {minutes = minutes+"min"}
    // if (seconds !== 0) {seconds = seconds+"sec"}

    hours = hours > 0 ? hours + "h" : ''
    minutes = minutes > 0 ? (minutes + (hours === '' ? " min" : "")) : ''
    seconds = seconds > 0 ? seconds + " sec" : ''

    return hours+minutes+seconds
}