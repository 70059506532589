import React from 'react';

import axios from "axios";
import config from "../../api";

import {logo_wahoo} from "../../img/img"

import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from '../../Functions/Alert';


function PreferenceWahoo({isWahooSynced, updateIsWahooSynced}) {


    const onChangeWahooSynced = () => {
        if(isWahooSynced === true) {
            // Désynchroniser
            updateIsWahooSynced(false)
            unsyncWahoo()
        }
        else {
            // Synchroniser
            updateIsWahooSynced(true)
            syncWahoo()
        }
    }

    // Synchroniser le compte Wahoo
    const syncWahoo = async () => {
        await axios.get(
            config+"api/my_profile/sync_wahoo_url",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            // Rediriger l'utilisateur vers la page de synchronisation
            window.location.href=response.data
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    // Désynchroniser le compte Wahoo
    const unsyncWahoo = async () => {
        await axios.get(
            config+"api/my_profile/unsync_wahoo",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            updateIsWahooSynced(!response.data.unsynced)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    return (
        <>
            <div className="w-100 d-inline-flex align-items-center justify-content-between show-input-settings mcb-3">
                <img
                    src={logo_wahoo}
                    className="settings-provider-logo pcy-2"
                    alt="Wahoo logo"
                />
                <span className="toggle-switch">
                    <input type="checkbox" checked={isWahooSynced} onChange={()=>{}}/>
                    <span className="switch"  onClick={() => onChangeWahooSynced()} />
                </span>
            </div>
        </>
    )
}

export default PreferenceWahoo;