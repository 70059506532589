import React, {useContext, useState} from "react";
import {white_help_circle} from "../img/img";
import ReactTooltip from "react-tooltip";
import {NavigationContext} from "../Contexts";
import {useTranslation} from "react-i18next";
const WidgetHelp = ({isSettingsHelper}) => {
    const {isMobile} = useContext(NavigationContext)
    const {t} = useTranslation(['app']);

    const [isHelperOpen,setIsHelperOpen] = useState(false)

    const getDataTip = () => {
        return (
            <div className="fs-12 text-left d-flex flex-column">
                {/*<a href="#" className="pcb-1 mb-0">FAQ</a>*/}
                <a href="https://www.youtube.com/c/GUTA%C3%8FTraining/playlists" target="_blank" className="pcb-1 mb-0">{t('settings.profil.help.youtube_tutorial')}</a>
                <a href="https://gutai-training.com/cgu-cgv/" target="_blank" className="pcb-1 mb-0">{t('settings.profil.help.legal_notices')}</a>
                <a href="https://www.facebook.com/groups/176707192916657/?ref=bookmarks" target="_blank" className="pcb-1 mb-0">{t('settings.profil.help.gutai_forum')}</a>
                <a href="mailto:contact@gutai-training.com" className="pcb-1 mb-0">{t('settings.profil.help.contact')}</a>
               {/* <a href=# className="pcb-1 mb-0">LiveChat</a>*/}
            </div>
        )
    }

    return (
        <>
        {isSettingsHelper === false ?
                <div className="dashboard-card widget-help align-items-center"
                     data-for="widgetHelpHelper"
                     data-tip={""}
                     data-event='click'
                >
                    <div className="d-flex justify-content-start pc-3">
                        <p className="monitoring-title mb-0 text-white">
                            <img
                                src={white_help_circle}
                                className="mr-2 icon-24 "
                                alt="help icon"

                            />
                            {t('settings.profil.help.title')}
                        </p>
                    </div>
                    <ReactTooltip key="tooltip-widgetHelpHelper"
                                  id="widgetHelpHelper"
                                  type="light"
                                  multiline={true}
                                  textColor="#007DFF"
                                  backgroundColor="#FFFFFF"
                                  className="tooltip"
                                  effect="solid"
                                  place="bottom"
                                  getContent={() => getDataTip()}
                    />
                </div>
                :
            <>
                <div className={"zs-tab-card pcy-3 mcb-3 pcx-3 "}>
                    <div className={isMobile ?"monitoring-title2 text-blue fs-14":"monitoring-title2 text-blue"}
                         data-tip={""}
                         data-for="helper"
                    >
                        {t('settings.profil.help.title')}
                    </div>
                    <ReactTooltip key="tooltip-helper"
                                  id="helper"
                                  type="light"
                                  multiline={true}
                                  textColor="#000000"
                                  backgroundColor="#F5F5F5"
                                  className="tooltip"
                                  effect="solid"
                                  place="bottom"
                    />
                    <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                          onClick={() => setIsHelperOpen(isHelperOpen => !isHelperOpen)}
                    >
                <i className={isHelperOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
            </span>
                    {isHelperOpen ?
                        <>
                            <div className="grey-line-2 mcy-3 pcx-3"></div>
                            <div className="row mct-5 ">
                                <div className="pcl-3 pr-0 fs-16 gotham-12 text-left d-flex flex-column">
                                    {/*<a href="#" className="pcb-1 mb-0 color-black">FAQ</a>*/}
                                    <a href="https://www.youtube.com/c/GUTA%C3%8FTraining/playlists" target="_blank" className="pcb-1 mb-0 color-black">{t('settings.profil.help.youtube_tutorial')}</a>
                                    <a href="https://gutai-training.com/cgu-cgv/" target="_blank" className="pcb-1 mb-0 color-black">{t('settings.profil.help.legal_notices')}</a>
                                    <a href="https://www.facebook.com/groups/176707192916657/?ref=bookmarks" target="_blank" className="pcb-1 mb-0 color-black">{t('settings.profil.help.gutai_forum')}</a>
                                    <a href="mailto:contact@gutai-training.com" className="pcb-1 mb-0 color-black">{t('settings.profil.help.contact')}</a>
                                    {/* <a href=# className="pcb-1 mb-0 color-black">LiveChat</a>*/}
                                </div>
                            </div>
                        </>
                        :
                        <></>
                    }

                </div>
            </>
        }
        </>
    )
}
export default WidgetHelp