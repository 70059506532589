import React, {useEffect, useState, useContext} from 'react';
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../Tools/CustomHooks";
import axios from "axios"
import config from "../../api";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {arrow_left, search} from "../../img/img"

import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import NotificationMessage from "./NotificationMessage";

import {NavigationContext} from "../../Contexts"
import {Tab, Tabs} from "react-bootstrap";
import DebriefingEventCard from "./DebriefingEventCard";
import moment from "moment";
import {getVerboseDateWithClock} from "../../Functions/DatesCalendar";


/**
 *
 * Afficher la liste des notification
 * -> pendant la récupération des données un loader est affiché
 *
 * @param t : traducteur
 * @param openCloseNotificationMessageList : fonction de rappel pour gestion ouverture/fermeture du widget
 */
function NotificationMessageList ({openCloseNotificationMessageList}) {

    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()


    let minDate = moment().subtract(2, 'month').format('YYYY-MM-DD')

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    // Statut de chargement de la liste
    const [loaded, setLoaded] = useState(false)
    // Liste des notifications
    const [notifications, setNotifications] = useState([])

    // Liste des commentaires
    const [debriefingEvents, setDebriefingEvents] = useState(null)
    const [debriefingEventsFiltered, setDebriefingEventsFiltered] = useState(null)
    const [debriefingId, setDebriefingId] = useState(null)
    const [activitySessionId, setActivitySessionId] = useState(null)
    const [inputSearch, setInputSearch] = useState(null)


    // Mettre à jour la liste des notifications
    // - au démarrage uniquement
    useEffect(() => {
        // Récupérer la liste des notifications
        getNotifications()


        // Récupérer la liste des compte-rendus (le résumé)
        getDebriefingEvents()
    }, [])

    // Récupérer les notifications
    const getNotifications = async () => {
        await axios.get(
            config + "api/notifications",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            let notifications = response.data.notifications
            setNotifications(notifications)

            // Marquer les notification comme lus
            readAllNotifications()

        }).catch(error => {
            handleEndpointError(error)
        })
    }

    // Marquer toutes les notifications comme non-lus
    const readAllNotifications = async () => {
        await axios.put(
            config + "api/notifications/read_all",
            {},
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            // Cacher le marqueur bleu sur l'icône du menu
        })
        .catch(error => {
            handleEndpointError(error)
        })
    }

    // Récupérer les id debriefing
    const getDebriefingEvents = async () => {
        await axios.get(
            config + "api/debriefings/short_list?min_date="+minDate,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            let debriefingsTab = []
            for (let debriefing of response.data.debriefings) {
                // if (debriefing.is_complete && debriefing.has_activity_sessions ) {
                if (debriefing.is_complete) {
                    debriefing.verbose_date = getVerboseDateWithClock(debriefing.date)
                    debriefingsTab.push(debriefing)
                }
            }
            setDebriefingEvents(debriefingsTab)
            setDebriefingEventsFiltered(debriefingsTab)
            setLoaded(true)

        }).catch(error => {
            handleEndpointError(error)
        })
    }

    const onChangeInputSearch = (value) => {
        setInputSearch(value)
    }

    // Mettre à jour la liste des notifications
    // - au démarrage uniquement
    useEffect(() => {
        getNotifications()
        getDebriefingEvents()
    }, [])

    useEffect(() => {
        if (inputSearch) {
            const result = debriefingEvents.filter(debriefingEvent =>
                debriefingEvent.name.toLowerCase().includes(inputSearch.toLowerCase()) ||
                (debriefingEvent.description && debriefingEvent.description.toLowerCase().includes(inputSearch.toLowerCase())) ||
                (debriefingEvent.comments.length>0 && debriefingEvent.comments[0].content.toLowerCase().includes(inputSearch.toLowerCase())) ||
                (debriefingEvent.verbose_date && debriefingEvent.verbose_date.toLowerCase().includes(inputSearch.toLowerCase())))
            setDebriefingEventsFiltered(result)
        } else {
            setDebriefingEventsFiltered(debriefingEvents)
        }
    }, [inputSearch])

    return (
        <div className={"col-xl-3 pb-5 mb-4 " + (isMobile ? "px-0 pt-3" : "p-3")}>
            <div id="alert-card-header" className="dashboard-card background-white">
                <div className="d-flex justify-content-start pct-3 pcx-3">
                    <img
                        className="icon-24 mcr-2 pointer" src={arrow_left} onClick={openCloseNotificationMessageList}
                        alt="arrow left icon"
                    />
                    <p className="settings-title">
                        {t('app:settings.preferences.notifications')}
                    </p>
                </div>
                {loaded ?
                    <Tabs
                        transition={false}
                        defaultActiveKey="alerts"
                        id="tab-notifications"
                        className=""
                    >
                        <Tab eventKey="alerts" title={t('app:alerts.title')}>
                            {notifications.length > 0 ?
                                <div id="alerts-list"
                                     className={"pct-3 pcb-1 " + (isMobile ? "mobile-content-scrollable" : "content-scrollable")}
                                >
                                    {notifications.map((notification) =>
                                        <NotificationMessage key={notification.id}
                                                             notification={notification}
                                                             openCloseNotificationMessageList={() => openCloseNotificationMessageList()}
                                        />
                                    )}
                                </div>
                                :
                                <div className="pct-3 pcb-3">
                                    <p className="fs-12 mb-0">{t('app:alerts:empty_alerts')}</p>
                                </div>
                            }
                        </Tab>
                        <Tab eventKey="comments" title={t('app:comments:title')} >
                            <div id="comments-list" className={"pct-3 pcb-1 " +(isMobile ? "mobile-content-scrollable" : "content-scrollable")}>
                                <div className="w-100 pcx-3 pcb-3">
                                    <div className="relative-div d-flex search-icon-container">
                                        <img className="icon-24" src={search} alt="search icon"/>
                                    </div>
                                    <input type="text" className="search-bar-notification w-100" onChange={(e) => onChangeInputSearch(e.target.value)}/>
                                </div>
                                {debriefingEventsFiltered && debriefingEventsFiltered.length > 0 && debriefingEventsFiltered.map((debriefingEvent) =>
                                    <DebriefingEventCard key={"debriefing-event-"+debriefingEvent.id}
                                                         debriefingEvent={debriefingEvent}
                                                         openCloseNotificationMessageList={() => openCloseNotificationMessageList()}
                                    />
                                )}
                                {(debriefingEvents === null || debriefingEvents.length === 0) &&
                                    <p className="fs-12 mcy-5 mcx-5">{t('app:comments:empty_list')}</p>
                                }
                            </div>
                        </Tab>
                    </Tabs>
                     :
                    <div className="row justify-content-center align-items-center d-flex mb-5 py-5">
                        <Loader
                            type="TailSpin"
                            color="#0069d9"
                            height={80}
                            width={80}
                            className="my-5"
                        />
                    </div>
                }
            </div>
        </div>
    )
}
export default NotificationMessageList