import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../../Tools/CustomHooks";
import Loader from "react-loader-spinner";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {secondsToChronoForChart} from "../Utils"
import InputSwitch from "../../Tools/InputSwitch";
import {helpCircle} from "../../../img/img";
import ReactTooltip from "react-tooltip";
// import {HighchartsOptions} from "./HighchartsOptions";

const ActivitySessionRjBalChart = ({activitySessionId, discipline}) => {

    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()

    const isCyclingDiscipline = ['velo', 'vtt'].indexOf(discipline) !== -1 ? 1 : 0
    const percentUnit = '%'
    const absoluteUnit =  isCyclingDiscipline ? 'kJ' : 'm'


    const [plotBands, setPlotBands] = useState([])
    const [datas, setDatas] = useState([])
    const [xAxis, setXAxis] = useState('duration')
    const [valueType, setValueType] = useState('percent')
    const [selectsOpt, setSelectsOpt] = useState({1: true, 2:false})
    const [showMarkerLabels, setShowMarkerLabels] = useState(false)

    const [loaded, setLoaded] = useState(false)
    const [loadedGraph, setLoadedGraph] = useState(false)


    useEffect( () => {
        // setTimeout(() => {
            getChartWbal(xAxis, valueType)
        // }, 5000 )

    }, []);


    const onXChange = async () => {
        setLoadedGraph(false)
        if (xAxis==='distance') {
            setXAxis('duration')
            setSelectsOpt((prevSelectsOpt) => {
                let newSelectsOpt = {...selectsOpt}
                newSelectsOpt[2] = false
                return newSelectsOpt
            })
            await getChartWbal('duration', valueType)
        } else {
            await setXAxis('distance')
            setSelectsOpt((prevSelectsOpt) => {
                let newSelectsOpt = {...prevSelectsOpt}
                newSelectsOpt[2] = true
                return newSelectsOpt
            })
            await getChartWbal('distance', valueType)
        }
    }

    const onYChange = async () => {
        setLoadedGraph(false)
        if (valueType==='percent') {
            await setValueType('absolute')
            await setSelectsOpt((prevSelectsOpt) => {
                let newSelectsOpt = {...prevSelectsOpt}
                newSelectsOpt[1] = false
                return newSelectsOpt
            })
            getChartWbal(xAxis, 'absolute')
        } else {
            await setValueType('percent')
            await setSelectsOpt((prevSelectsOpt) => {
                let newSelectsOpt = {...prevSelectsOpt}
                newSelectsOpt[1] = true
                return newSelectsOpt
            })
            getChartWbal(xAxis, 'percent')
        }
    }

    const handleShowMarkerLabelsChange = (value) => {
        setShowMarkerLabels(value)
    }



    const getChartWbal = async (xAxis, valueType) => {
        await axios.get(
            config+"api/activity_sessions/"+activitySessionId+"/chart_wbal?x_axis="+ xAxis +"&value_type="+valueType,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(res => {
            if(!isMounted.current){
                return 0;
            }
            let data = res.data
            let plots = []
            for (let i = 0; i < data.plotBands.length; i++) {
                plots.push({
                    from: data.plotBands[i].from,
                    to: data.plotBands[i].to,
                    color: data.plotBands[i].color,
                    zIndex: 1,
                })
            }
            setPlotBands(plots)
            delete data.plotBands;
            setDatas(data)

            setLoaded(true)
            setLoadedGraph(true)
        })
            .catch(error => {
                handleEndpointError(error)
            })

    }

    const optionsHigh = () => {
        let datasSeries, unitY, yMinValue
        let forAbsoluteCycling = valueType === "absolute"  && isCyclingDiscipline ? 1 : 0

        if (valueType === "percent") {
            datasSeries = datas.wbalPercentValues
            yMinValue = datas.yAxisMinPercentValue
            unitY = percentUnit
        } else {
            datasSeries = datas.wbalValues
            yMinValue = datas.yAxisMinValue
            unitY = absoluteUnit
        }
        let options = {
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                }
            },
            chart: {
                type: 'line',
                zoomType: 'x',
                resetZoomButton: {
                    position: {
                        y: -10
                    }
                },
                height: 250,
                backgroundColor: '#F5F5F5'
            },
            credits: {
                enabled: false
            },
            title: {
                text: null
            },
            legend: {
                enabled: false
            },
            xAxis: {
                lineWidth: 0,
                tickLength: 0,
                title: {
                    enabled: false,
                },
                crosshair: {
                    width: 2,
                    color: '#0079ff'
                },
                labels: {
                    formatter: function () {
                        if (xAxis === 'duration') {
                            return secondsToChronoForChart(this.value)
                        }

                        let value = this.value
                        if(this.axis.max - this.axis.min > 1000) {
                            value = (value/1000).toFixed(1) + ' k'
                        }
                        return value
                    }
                },
                plotBands: plotBands
            },
            tooltip: {
                shared: false,
                crosshairs: true,
                shadow: true,
                useHTML: true,
                borderWidth: 0,
                background: 'transparent',
                positioner: function () {
                    return { x: 80, y: 120 };
                },
                formatter: function(){
                    let xValue = '';
                    if (xAxis === 'distance') {
                        xValue = Math.round(this.x) + 'm';
                    } else {
                        xValue = secondsToChronoForChart(this.x);
                    }

                    let yValue = Math.round(this.y);
                    if(forAbsoluteCycling) {
                        yValue = (yValue/1000).toFixed(1);
                    }

                    let toDisplay = '<div class="cursor"><span class="dome"></span>'
                    toDisplay+='<span class="text"> '+t("app:rjbal.level")+' : '+ yValue + ' ' + unitY +'</span><br>'
                    toDisplay+='<span class="text text-chrono">'+ xValue +'</span></div>'
                    return toDisplay
                },
                hideDelay: 0
            },
            plotOptions: {
                series: {
                    lineWidth: 0,
                    cursor: 'pointer',
                    turboThreshold: 200000,
                    states: {
                        hover: {
                            enabled: false
                        }
                    },
                    marker: {
                        enabled: false,
                        radius: 1,
                    },
                }
            },
        }

        let yAxisOptions = {
            yAxis: [{
                gridLineWidth: 0,
                title: {
                    text: false
                },
                labels: {
                    formatter: function () {
                        let value = this.value;
                        if(value <= yMinValue) {
                            value = '<b>' + unitY + '</b>';
                        }
                        else if(forAbsoluteCycling) {
                            value = Math.round(value / 1000);
                        }
                        return value
                    }
                },
                min: yMinValue
            }],
            series: [{
                name : '',
                type: 'line',
                data: datasSeries,
                zIndex:7,
                lineWidth: 2,
                color: '#FFFFFF',
                gridLineWidth: 0,
                shadow: {
                    color: "#3e3e3e",
                    width: 3,
                    offsetY:1,
                    opacity:0.2
                },
                showInLegend: false
            }]
        }

        return Object.assign({}, options, yAxisOptions)
    }

    
    if(!loaded) {
        return(
            <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={50}
                    width={50}
                    className="my-5"
                />
            </div>
        )
    }

    const getDataTip = () => {
        return (
            <div className="fs-12 text-left">
                <p>{t('app:rjbal.rjbal_info.0')}</p>
                <p>{t('app:rjbal.rjbal_info.1')}</p>
                <p>{t('app:rjbal.rjbal_info.2')}</p>
            </div>
        )
    }


    // Ne fonctionne pas :D
    // HighchartsOptions(Highcharts, {
    //     lang: {
    //         numericSymbols:["k"]
    //     }
    // })

    return (
        <>
            {datas &&
            <div className={(showMarkerLabels ? "" : "rjbal-section-hide-label " )+"rjbal-section"}
            >
                <div className="row mct-4 mr-1 mb-2 justify-content-start d-flex text-left "
                     data-tip={""}
                     data-for="rjbal-helper"
                     data-event='click'
                >
                    <h2 className="seance-title2">{t('app:rjbal.title')}</h2>
                    <img
                         src={helpCircle} className="ml-3 icon-24"
                         alt="help icon"
                    />
                </div>
                <ReactTooltip key="tooltip-rjbal"
                              id="rjbal-helper"
                              type="light"
                              multiline={true}
                              textColor="#000000"
                              backgroundColor="#F5F5F5"
                              className="tooltip"
                              effect="solid"
                              place="bottom"
                              getContent={() => getDataTip()}
                />
                <div className="row justify-content-center d-flex stats-card mb-5">
                    <div className="row">
                        <InputSwitch addClassName="pc-3" onSwitchChange={onXChange} labelLeft={t('app:rjbal.time')} labelRight={t('app:rjbal.distance')} selectOpt={selectsOpt[2]}  isThird={true}/>
                        <InputSwitch addClassName="pc-3" onSwitchChange={onYChange} labelLeft={absoluteUnit} labelRight={percentUnit} selectOpt={selectsOpt[1]} isSecond={true}/>
                    </div>
                    {loadedGraph ?
                        <>
                            <div style={{height: '250px', width: '100%'}}>
                                <HighchartsReact highcharts={Highcharts} options={optionsHigh()}/>
                            </div>
                            <div className="row w-100">
                                <div className="col-12">
                                    <div className={"checkbox-container checkbox-container-blue mcl-4 mb-2 w-100"}
                                         style={{opacity: 0.7}}
                                    >
                                        <input type="checkbox" id="rjbal_chart_marker_label" name="rjbal_chart_marker_label"
                                               checked={showMarkerLabels}
                                               onChange={(event) => handleShowMarkerLabelsChange(event.target.checked)}
                                        />
                                        <label className="fs-11" htmlFor="rjbal_chart_marker_label">{t('app:rjbal.chart.show_marker_label')}</label>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <div className="col-12">
                            <Loader
                                type="TailSpin"
                                color="#0069d9"
                                height={80}
                                width={80}
                                className="my-5"
                            />
                        </div>
                    }
                </div>
            </div>
            }
        </>
    )
}

export default ActivitySessionRjBalChart;