import React, {useContext, useEffect, useState} from 'react';
import {HeaderHome} from "../Components/Home/HeaderHome";
import {useTranslation} from "react-i18next";
import {NavigationContext} from "../Contexts";
import {arrow_right_circle_white, welcome_app, helpCircle, onboarding_end} from "../img/img";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import axios from "axios";
import config from "../api";
import generateHeaders from "../Functions/generateHeaders";
import handleEndpointError from "../Functions/Alert";
import {useIsMounted} from "../Components/Tools/CustomHooks";
import AthleteSports from "../Components/Settings/AthleteSports";
import HealthProfile from "../Components/Settings/HealthProfile";

const Onboarding = () => {
    const STEP = {
        START: 0,
        SPORTS: 1,
        SYNCHRO: 2,
        PROFIL: 3,
        END: 4
    }
    const {t} = useTranslation(['app'])
    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)
    const isMounted = useIsMounted()

    const [step, setStep] = useState(0)

    const [user, setUser] = useState(null)
    const [userProfile, setUserProfile] = useState(null)
    const [errorMessage, setErrorMessage] = useState()

    const onChangeUserProfile = (field, value) => {
        const newUserProfile = {...userProfile}
        newUserProfile[field] = value
        setUserProfile(newUserProfile)
    }

    // Récuperer les informations de l'utilisateur à l'initialisation
    useEffect( () => {
        getMyProfile()
    }, []);

    // Enregistrer les informations de l'utilisateur dans le buffer
    useEffect(() => {
        if(user) {
            setUserProfile({
                name: user.lastname,
                firstname: user.firstname,
                username: user.email,
                birthdate: moment(user.birth_date).toDate(),
                city: user.city,
                country: user.country,
                phone: user.cell_phone,
                gender: user.gender,
                height: user.height,
                weight: user.weight.value,
                weightUk: user.weight.uk_value,
                weightValue: null,
                sports: user.sports,
            })
        }
    }, [user])


    // Récupérer les données du profil utilisateur
    const getMyProfile = async () => {
        await axios.get(
            config+"api/my_profile",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((res) => {
            if(!isMounted.current){
                return 0;
            }
            setUser(res.data)
        }).catch(error => {
            handleEndpointError(error)
        })
    }

    useEffect(() => {

    }, [])

    const changeStep = (nextOrPrev) => {
        let newStep = step
        if (nextOrPrev === "next") {
            newStep += 1
        } else {
            newStep -= 1
        }
        setStep(newStep)
    }

    const getDataTip = (step) => {
        switch (step) {
            case "sports":
                return (
                    <div className="fs-12 text-left">
                        <p className="mb-0">{t('onboarding_screen.help_sport')}</p>
                    </div>
                )
            case "synchro":
                return (
                    <div className="fs-12 text-left">
                        <p className="mb-0">{t('onboarding_screen.help_synchro')}</p>
                    </div>
                )
            case "profil":
                return (
                    <div className="fs-12 text-left">
                        <p className="mb-0">{t('onboarding_screen.help_profil')}</p>
                    </div>
                )
        }

    }



    const progressPoints = () => {
        return (
            <div className={`d-flex ${isMobile ? " mcb-5" : " mct-5"}`}>
                <div className={`${step === STEP.SPORTS ? "progress-point-active" : "progress-point-inactive"} mcr-2`} />
                <div className={`${step === STEP.SYNCHRO ? "progress-point-active" : "progress-point-inactive"} mcr-2`} />
                <div className={`${step === STEP.PROFIL ? "progress-point-active" : "progress-point-inactive"} mcr-2`} />
                <div className={`${step === STEP.END ? "progress-point-active" : "progress-point-inactive"}`} />
            </div>
        )
    }

    const stepFooter = (textBtnRight) =>
        <>
            <div className={`mt-auto d-flex flex-column align-items-center w-100 ${isMobile ? "mcb-4" : ""}`}>
                {!isMobile &&
                <div className={`d-flex w-100 justify-content-${(step !== (0||1)) ? 'between' : 'center'}`}>
                    {step !== (STEP.START||STEP.SPORTS) &&
                    <p  className="onboarding-previous pointer"
                        onClick={() => changeStep("prev")}
                    >
                        {t('onboarding_screen.previous')}
                    </p>
                    }
                    <div className="btn-blue-white" onClick={() => changeStep("next")}>
                        <img className="icon-24 mcr-2" src={arrow_right_circle_white} alt="start"/>
                        {textBtnRight}
                    </div>
                </div>
                }

                {progressPoints()}

                {isMobile &&
                <div className={`previous-next-onboarding d-flex w-100 justify-content-${(step !== (STEP.START||STEP.SPORTS)) ? 'between' : 'center'}`}>
                    {step !== (STEP.START||STEP.SPORTS) &&
                    <p  className="onboarding-previous pointer"
                        onClick={() => changeStep("prev")}
                    >
                        {t('onboarding_screen.previous')}
                    </p>
                    }
                    <div className="btn-blue-white position-absolute onboarding-next-mobile" onClick={() => changeStep("next")}>
                        <img className="icon-24 mcr-2" src={arrow_right_circle_white} alt="start"/>
                        {textBtnRight}
                    </div>
                </div>
                }
            </div>
            {!isMobile &&
                <p className="onboarding-ignore mct-5 pointer">{t('onboarding_screen.ignore')}</p>
            }
        </>

    return (
        <div>
            <div className={isMobile ? "" : "d-flex justify-content-end background-onboarding"}>
                <div className={isMobile ? "pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-30 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                    {step === STEP.START ?
                        <>
                            <HeaderHome />
                            <img src={welcome_app} alt="welcome" className="welcome-app-img mcy-5"/>
                            <p className="onboarding-title">{t('onboarding_screen.title')}</p>
                            <p className="onboarding-desc mcy-5">{t('onboarding_screen.desc')}</p>
                            <div className="btn-blue-white mcb-5" onClick={() => changeStep("next")}>
                                <img className="icon-24 mcr-2" src={arrow_right_circle_white} alt="start"/>
                                {t('confirmation_page.start')}
                            </div>
                            <p className="onboarding-ignore mt-auto pointer">{t('onboarding_screen.ignore')}</p>
                        </>
                        :
                        <>
                            {isMobile ?
                                step !== STEP.END &&
                                    <div className="d-flex w-100 justify-content-end">
                                        <p className="onboarding-ignore mt-auto pointer">{t('onboarding_screen.ignore_short')}</p>
                                    </div>
                                :
                                <HeaderHome />
                            }
                            {step === STEP.SPORTS &&
                            <>
                                <p className="onboarding-title">
                                    {t('finalize_registration.sport')}
                                    <img className="icon-24 pointer mcl-2"
                                         src={helpCircle}
                                         alt="help circle"
                                         data-for="sportsHelper"
                                         data-tip={""}
                                         data-event='click'
                                    />
                                    <ReactTooltip key="tooltip-sports"
                                                  id="sportsHelper"
                                                  type="light"
                                                  multiline={true}
                                                  textColor="#000000"
                                                  backgroundColor="#F5F5F5"
                                                  className="tooltip"
                                                  effect="solid"
                                                  place="bottom"
                                                  getContent={() => getDataTip("sports")}
                                    />
                                </p>
                                <p className="onboarding-desc">
                                    {t('onboarding_screen.step.sport.0')}
                                </p>
                                <AthleteSports userProfile={userProfile}
                                               onChangeUserProfile={(field, value) => onChangeUserProfile(field, value)}
                                />
                                {stepFooter(t('onboarding_screen.next'))}
                            </>
                            }
                            {step === STEP.SYNCHRO &&
                            <>
                                <p className="onboarding-title">
                                    {t('settings.preferences.synchronization')}
                                    <img className="icon-24 pointer mcl-2"
                                         src={helpCircle}
                                         alt="help circle"
                                         data-for="synchroHelper"
                                         data-tip={""}
                                         data-event='click'
                                    />
                                    <ReactTooltip key="tooltip-synchro"
                                                  id="synchroHelper"
                                                  type="light"
                                                  multiline={true}
                                                  textColor="#000000"
                                                  backgroundColor="#F5F5F5"
                                                  className="tooltip"
                                                  effect="solid"
                                                  place="bottom"
                                                  getContent={() => getDataTip("synchro")}
                                    />
                                </p>
                                <p className="onboarding-desc">
                                    {t('onboarding_screen.step.synchro.0')}
                                </p>

                                {stepFooter(t('onboarding_screen.next'))}
                            </>
                            }
                            {step === STEP.PROFIL &&
                            <>
                                <p className="onboarding-title">
                                    {t('onboarding_screen.step.profil.0')}
                                    <img className="icon-24 pointer mcl-2"
                                         src={helpCircle}
                                         alt="help circle"
                                         data-for="profilHelper"
                                         data-tip={""}
                                         data-event='click'
                                    />
                                    <ReactTooltip key="tooltip-profil"
                                                  id="profilHelper"
                                                  type="light"
                                                  multiline={true}
                                                  textColor="#000000"
                                                  backgroundColor="#F5F5F5"
                                                  className="tooltip"
                                                  effect="solid"
                                                  place="bottom"
                                                  getContent={() => getDataTip("profil")}
                                    />
                                </p>
                                <p className="onboarding-desc mcb-5">
                                    {t('onboarding_screen.step.profil.1')}
                                </p>
                                <HealthProfile userProfile={userProfile}
                                               onChangeUserProfile={(field,value) => onChangeUserProfile(field,value)}
                                               errorMessage={errorMessage}
                                />

                                {stepFooter(t('onboarding_screen.next'))}
                            </>
                            }
                            {step === STEP.END &&
                            <>
                                <img src={onboarding_end} alt="onboarding end" className="onboarding-end-img mcy-5"/>
                                <p className="onboarding-title">{t('onboarding_screen.step.end.0')}</p>
                                <p className="onboarding-desc mct-5">{t('onboarding_screen.step.end.1')}</p>
                                <p className="onboarding-desc mcb-5">{t('onboarding_screen.step.end.2')}</p>
                                {stepFooter(t('onboarding_screen.go'))}
                            </>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Onboarding;