import React, {useContext, useEffect, useState} from 'react';
import generateHeaders from "../../Functions/generateHeaders";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import {TimeZoneContext} from "../../Contexts";
import CustomSelect from "../Tools/CustomSelect";
import moment from "moment-timezone";

const TimeZoneSelect = ({timeZone, onChangeTimeZone}) => {
    const {updateTimeZone} = useContext(TimeZoneContext)
    const [timeZonesOptions, setTimeZonesOptions] = useState(null)
    const [timeZoneValue, setTimeZoneValue] = useState(null)

    useEffect(() => {
        const timeZones = moment.tz.names()
        const options = []
        timeZones.map((item, i) => {
            options.push({
                id: i,
                label: item
            })
        })
        setTimeZonesOptions(options)
    }, [])

    useEffect(() => {
        setTimeZoneValue({
            id: 99999,
            label: timeZone
        })
    }, [timeZone])

    const submitTimeZone = async (timeZoneValue) => {
        let headers = generateHeaders()
        if(headers){
            await axios
                .put(
                    config+"api/my_profile",
                    {
                        timezone: timeZoneValue
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                ).then(response => {
                    // Utilisation du contexte pour mettre à jour la valeur du réglage Métrique
                    // updateTimeZone(timeZoneValue)
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }
    const onChangeValue = (value) => {
        submitTimeZone(value)
        onChangeTimeZone(value)
    }
    return (
        <CustomSelect
            className="w-100 text-left"
            name="timezone"
            options={timeZonesOptions}
            defaultValue={timeZoneValue}
            value={timeZoneValue}
            onChange={(value) => onChangeValue(value.label)}
        />
    );
};

export default TimeZoneSelect;
