import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {Metrics, MetricValues} from "../../Functions/Metrics";
import {MetricContext, NavigationContext, WeekCalendarContext} from "../../Contexts";
import {
    allure_icon,
    distance_icon,
    duration_icon, heart_icon, pool_length, power_icon,
    rpe0,
    rpe12,
    rpe34,
    rpe56,
    rpe78,
    rpe910,
    vitesse_icon
} from "../../img/img";
import {time_convert} from "../../Functions/TextMaths";
import {getDisciplineIconParams} from "../AthleteCalendar/Workout/Utils";
import {TrainingEventTypes, createTrainingEventFromDebriefing} from "../AthleteCalendar/TrainingEvent/Utils";
import {Col, Row} from "react-bootstrap";
import {getVerboseDateWithClock} from "../../Functions/DatesCalendar";
import {format as formatDate} from "date-fns";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import axios from "axios/index";
import config from "../../api";
import {useIsMounted} from "../Tools/CustomHooks";

function DebriefingEventCard({debriefingEvent, openCloseNotificationMessageList}) {
    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()

    // Résolution pour mobile ?
    const {isMobile, isTablet} = useContext(NavigationContext)

    const {updateDayToOpen, updateTrainingEvent, updateTrainingEventOpen} = useContext(WeekCalendarContext)

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    const [datas, setDatas] = useState(null);

    let numberOfDisciplines = debriefingEvent.disciplines.length

    let nameColor = 'color-multi'
    let borderColor = 'multi-border'
    if(numberOfDisciplines === 1) {
        switch (debriefingEvent.disciplines[0].code) {
            case 'nat':
                nameColor = "color-nat"
                borderColor = "nat-border"
                break;
            case 'vtt':
            case 'velo':
                nameColor = "color-velo"
                borderColor = "velo-border"
                break;
            case 'cap':
                nameColor = "color-cap"
                borderColor = "cap-border"
                break;
            case 'kayak':
                nameColor = "color-kayak"
                borderColor = "kayak-border"
                break;
            case 'ski-fond':
            case 'ski-alpin':
            case 'ski-rando':
                nameColor = "color-ski"
                borderColor = "ski-border"
                break;
            case 'muscu':
            case 'renfo':
                nameColor = "color-muscu"
                borderColor = "default-border-debrief"
                break;
            default:
                nameColor = "color-other"
                borderColor = "default-border-debrief"
                break;
        }
    }

    let rpeIcon = rpe910
    switch (debriefingEvent.rpe) {
        case 0:
        case 0.5:
            rpeIcon = rpe0
            break
        case 1:
        case 1.5:
        case 2:
        case 2.5:
            rpeIcon = rpe12
            break
        case 3:
        case 3.5:
        case 4:
        case 4.5:
            rpeIcon = rpe34
            break
        case 5:
        case 5.5:
        case 6:
        case 6.5:
            rpeIcon = rpe56
            break
        case 7:
        case 7.5:
        case 8:
        case 8.5:
            rpeIcon = rpe78
            break
        default:
        case 9:
        case 9.5:
        case 10:
            rpeIcon = rpe910
            break
    }


    useEffect(() => {
        if(debriefingEvent && debriefingEvent.disciplines.length) {
            let d = {
                duration: {
                    unit: debriefingEvent.duration >= 60 ? "" : "min",
                    value: debriefingEvent.duration >= 60 ?
                            time_convert(debriefingEvent.duration)
                            :
                            debriefingEvent.duration,
                    icon: duration_icon
                }
            }

            if(debriefingEvent.first_activity_statistic) {
                switch(debriefingEvent.disciplines[0].code) {
                    case 'cap':
                        d.distance = {
                            unit: metrics.distance_cap_velo,
                            value: metric === MetricValues.international ?
                                (debriefingEvent.first_activity_statistic.distance/1000).toFixed(1)
                                :
                                debriefingEvent.first_activity_statistic.distance_uk,
                            icon: distance_icon
                        }
                        d.pace = {
                            unit: metrics.allure_cap_velo,
                            value: metric === MetricValues.international ?
                                debriefingEvent.first_activity_statistic.pace_avg_string
                                :
                                debriefingEvent.first_activity_statistic.pace_avg_uk,
                            icon: allure_icon
                        }
                        if(isTablet) {
                            d.speed = {
                                unit: metrics.speed_cap_velo,
                                value: debriefingEvent.first_activity_statistic.speed_avg ?
                                        (metric === MetricValues.international ?
                                            (debriefingEvent.first_activity_statistic.speed_avg).toFixed(1)
                                            :
                                            (debriefingEvent.first_activity_statistic.speed_avg_uk).toFixed(1))
                                    : null,
                                icon: vitesse_icon
                            }

                        }
                        break
                    case 'velo':
                        d.power = {
                            unit: metrics.power_velo,
                            value: Math.round(debriefingEvent.first_activity_statistic.power_avg),
                            icon: power_icon
                        }
                        if(isTablet) {
                            d.speed = {
                                unit: metrics.speed_cap_velo,
                                value: debriefingEvent.first_activity_statistic.speed_avg ?
                                    (metric === MetricValues.international ?
                                        (debriefingEvent.first_activity_statistic.speed_avg).toFixed(1)
                                        :
                                        (debriefingEvent.first_activity_statistic.speed_avg_uk).toFixed(1))
                                    : null,
                                icon: vitesse_icon
                            }
                        }
                        d.hr = {
                            unit: metrics.heartrate,
                            value: Math.round(debriefingEvent.first_activity_statistic.h_r_avg),
                            icon: heart_icon
                        }
                        break
                    case 'nat':
                        d.distance = {
                            unit: metrics.distance_nat,
                            value: metric === MetricValues.international ?
                                debriefingEvent.first_activity_statistic.distance
                                :
                                debriefingEvent.first_activity_statistic.distance_uk,
                            icon: distance_icon
                        }
                        d.pace = {
                            unit: metrics.allure_nat,
                            value: metric === MetricValues.international ?
                                debriefingEvent.first_activity_statistic.pace_avg_string
                                :
                                debriefingEvent.first_activity_statistic.pace_avg_uk,
                            icon: allure_icon
                        }
                        if(isTablet) {
                            d.speed = {
                                unit: metrics.speed_cap_velo,
                                value: debriefingEvent.speed_avg ?
                                    (metric === MetricValues.international ?
                                        (debriefingEvent.first_activity_statistic.speed_avg).toFixed(1)
                                        :
                                        (debriefingEvent.first_activity_statistic.speed_avg_uk).toFixed(1)) : null,
                                icon: vitesse_icon
                            }
                            d.pool = {
                                unit: metrics.distance_nat,
                                value: metric === MetricValues.international ?
                                    debriefingEvent.first_activity_statistic.pool_length
                                    :
                                    debriefingEvent.first_activity_statistic.pool_length_uk,
                                icon: pool_length
                            }
                        }
                        break
                    default:
                        break;
                }
            }
            d.rpe = {
                unit : "RPE",
                value : debriefingEvent.rpe,
                icon: rpeIcon
            }
            setDatas(d)
        }
    }, [metric, debriefingEvent])

    const openTrainingEvent = async () => {
        const headers = generateHeaders()
        await axios
            .get(
                config+"api/debriefings/"+debriefingEvent.id,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let debriefing = response.data
                let trainingEvent = createTrainingEventFromDebriefing(debriefing, "debriefing-event-"+debriefingEvent.id)

                updateDayToOpen(formatDate(new Date(debriefingEvent.date), "yyyy-MM-dd"))
                updateTrainingEvent(trainingEvent)
                updateTrainingEventOpen(true)

                if(isMobile) {
                    openCloseNotificationMessageList()
                }

            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    return (
        <div className={"pc-3 mcb-3 mcx-3 alert-div "+borderColor}>
            <Row className="mcb-2 align-items-center d-flex">
                {debriefingEvent.disciplines.map((discipline, index) => {
                    let {iconBgColor, disciplineIcon} = getDisciplineIconParams(debriefingEvent.type !== TrainingEventTypes.SEANCE_PLANIFIEE, discipline.code)

                    return (
                        <div key={"discipline-"+discipline.id}
                             className={"ml-2 col-2 training-event-cube "+ iconBgColor + " align-items-center justify-content-center d-flex " + (numberOfDisciplines === index + 1 ? (!isMobile ? "mcl-3":"mx-0") : (!isMobile ? "ml-3":"mcr-2 ml-0"))}
                        >
                            <img
                                className="icon-24"
                                src={disciplineIcon} alt={discipline+" set icon"}
                            />
                        </div>
                    );
                })}
                <div className="align-items-start d-flex col-9">
                    <Row xs={1} className=" align-items-start d-flex">
                        <Col className={nameColor +" seance-title"}>{debriefingEvent.name}{"\n"}</Col>
                        <Col className="dateWorkout">{debriefingEvent.verbose_date}</Col>
                    </Row>
                </div>
            </Row>
            <div className="w-100 mct-4 debriefing-event-title-label text-left mcb-2">{t('app.activity_session.statistics')}</div>
            <div className="row justify-content-center">
                {datas && Object.keys(datas).map((key, i) =>
                    <div key={key+i} className="col d-flex flex-column align-items-center">
                        <img src={datas[key].icon} alt="duration icon" className="icon-24 mcb-2"/>
                        <span className="debriefing-event-stat-value font-weight-bold">{datas[key].value}</span>
                        <span className="debriefing-event-stat-value">{datas[key].unit}</span>
                    </div>
                )}
            </div>

            {debriefingEvent.description ?
                <>
                    <div className="w-100 mct-4 debriefing-event-title-label text-left mcb-2">{t('app:personal_note')}</div>
                    <div className="w-100 debriefing-event-little-text text-left">{debriefingEvent.description}</div>
                </>
                :
                <></>
            }

            {debriefingEvent.comments.length>0 ?
                <>
                    <div className="w-100 mct-4 debriefing-event-title-label text-left mcb-2">{t('app:athlete.debriefing.comment.one_comment')}</div>
                    <div className="w-100 debriefing-event-little-text text-left">{debriefingEvent.comments[0].content}</div>
                </>
                :
                <></>
            }

            <div className="mct-3">
                <span className="debriefing-event-see-session pointer"
                      onClick={() => openTrainingEvent()}
                >
                    {t('app:workout.panel.session')}
                </span>
            </div>
        </div>
    )
}
export default DebriefingEventCard;