import React, {useEffect, useContext} from 'react';
import {Redirect} from "react-router-dom";
import {AuthContext} from "../Contexts";

function Logout (...rest) {

    // Mettre à jour l'authentification
    const {updateToken} = useContext(AuthContext)

    useEffect(() => {
        localStorage.removeItem("token")
        localStorage.removeItem("username")
        localStorage.removeItem("profile")
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("username")
        sessionStorage.removeItem("profile")

        updateToken(null)
        console.log("Logout executed")

    }, [])


    return (
        <Redirect to="/home"/>
    )
}

export default Logout