import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../../Tools/CustomHooks";
import {Row} from "react-bootstrap";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useScreenshot } from 'use-react-screenshot'
import config from "../../../api";
import {DataZones} from "../../TrainingZones/Utils";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";
import {secondesToHHmm, secondesToMinutes, calculPercent} from "../../../Functions/TextMaths";
import InputSwitch from "../../Tools/InputSwitch";

import ActivitySessionChart from "./Charts/ActivitySessionChart"
import {NavigationContext} from "../../../Contexts";
import ActivitySessionIntensityTab from "./ActivitySessionIntensityTab";
import { useDispatch, useSelector } from "react-redux";
import { setIntensity } from "../../../redux/features/share/shareSlice";

const ActivitySessionIntensity = ({activitySessionId, ease,discipline, pathType, setPathType, trainingConceptCode}) => {

    const {t} = useTranslation(['app'])
    const {isMobile} = useContext(NavigationContext)
    const isMounted = useIsMounted()

    // Données de la distribution des intensités
    const [datasChart, setDatasChart] = useState(null)

    // Réglage de la distribution des intensités : Zone I ou Zone II
    const [selectOpt, setSelectOpt] = useState(false)

    // Réglage distance ou durée
    const [xAxis, setXAxis] = useState('duration')
    const [selectOptX, setSelectOptX] = useState(false)

    // Légendes/Pourcentages pour la distribution des intensités
    const [tabPercents, setTabPercents] = useState([])
    const [displayTab, setDisplayTab] = useState(false)

    
    const shouldTakeScreenshots = useSelector(app => app.share.shouldTakeScreenshots)
    const dispatch = useDispatch()

    useEffect(() => {
        if (shouldTakeScreenshots && tabPercents) {
            dispatch(setIntensity(tabPercents))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldTakeScreenshots])

    useEffect(() => {
        if(trainingConceptCode) {
            getChart(pathType, xAxis)
        }
    }, [pathType, xAxis, trainingConceptCode])

    const onChangePathType = (pathTypeToSet) => {
        setPathType(pathTypeToSet)
    }

    // Préparer la légende et les pourcentages de la distribution des intensités
    useEffect(() => {
        if(datasChart) {
            let percentPlotBands = datasChart.percentPlotBands
            let total = 0
            let tab = []
            for (let i=0; i<percentPlotBands.length; i++) {
                total += percentPlotBands[i].data[0]
            }

            // Calculer les pourcentages
            let totalPercent = 0
            for (let i=0; i<percentPlotBands.length; i++) {
                let zoneDuration = percentPlotBands[i].data[0]
                let percent = calculPercent(total, zoneDuration)
                if (zoneDuration !== 0 && percentPlotBands[i].name !== '') {
                    if (percent === 0) {
                        percent = 1
                    }
                    totalPercent += percent
                    tab.push([percentPlotBands[i].name, zoneDuration, percent])
                }
            }
            let tabPercentDatas = []
            for (let element of tab.reverse()) {
                tabPercentDatas.push({
                    'zone': element[0],
                    'name': (element[0] !== 'Supramax' || (element[0] === 'Supramax' && element[2] > 12)) ? element[0] : "Sup.",
                    'value': xAxis === 'distance' ? ((element[1]/1000).toFixed(1) + "km") : (element[1] > 5400 ? secondesToHHmm(element[1]):(secondesToMinutes(element[1]) + "min")),
                    'percent': element[2]
                })
            }
            if(tabPercentDatas.length && totalPercent < 100) {
                tabPercentDatas[0].percent += 100-totalPercent
            }
            setTabPercents(tabPercentDatas)
        }
    }, [datasChart])

    // Récuperer les données de la distribution des intensités
    const getChart = async (pathType, xOpt) => {
        await axios.get(
            config + "api/activity_sessions/" + activitySessionId + "/chart",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                },
                params: {
                    chart_type: pathType,
                    training_concept: trainingConceptCode,
                    ease: ease * ease,
                    x_axis: xOpt
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            setDatasChart(response.data)
        })
        .catch(error => {
            handleEndpointError(error)
        })
    }

    const clearTabPercents = () => {
        setTabPercents([])
    }
    // Gérer le réglage de distance ou durée
    const onXChange = () => {
        if (xAxis==='distance') {
            setXAxis('duration')
            setSelectOptX(false)
            clearTabPercents()
        } else {
            setXAxis('distance')
            setSelectOptX(true)
            clearTabPercents()
        }
    }

    // Gérer le réglage de la distribution des intensités
    const onSwitchChange = () => {
        if (selectOpt) {
            setSelectOpt(false)
            setPathType('primary_zones')
            clearTabPercents()
        } else {
            setSelectOpt(true)
            setPathType('secondary_zones')
            clearTabPercents()
        }
    }

    if(datasChart === null) {
        return(
            <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={50}
                    width={50}
                    className="my-5"
                />
            </div>
        )
    }

    return (
        <>
            {datasChart.isValid &&
            <div className="intensity-section">
                <Row className="mr-1 mt-4 mb-2 justify-content-between d-flex text-left ">
                    <h2 className="seance-title2">{t('app:intensity.intensities')}</h2>
                    <div className="d-flex">
                        <InputSwitch
                            addClassName="pb-3 mcr-3"
                            labelLeft={t('app:rjbal.time')}
                            selectOpt={selectOptX}
                            labelRight={t('app:rjbal.distance')}
                            onSwitchChange={onXChange}
                        />

                        <InputSwitch
                            addClassName="pb-3"
                            labelLeft="Zone I"
                            selectOpt={selectOpt}
                            labelRight="Zone II"
                            onSwitchChange={onSwitchChange}
                        />
                    </div>

                </Row>
                <div id={"intensity_distribution"} className="row">
                    <div className="offset-lg-2 col-lg-8 col-md-12 justify-content-center stats-card mcb-2 pc-2">
                        <div className=" justify-content-center d-flex mc-2">
                            {tabPercents.length === 0 &&
                            <Loader
                                type="ThreeDots"
                                color="#0069d9"
                                height={33}
                                width={33}
                                className="my-2"
                            />
                            }
                            {tabPercents.length > 0 && tabPercents.map((object, index) =>
                                <div key={object.zone} id={"intensity_" + object.zone}
                                     className={tabPercents.length === 1 ? "intensity_element radius-total" :
                                         index === 0 ? "intensity_element radius-left" : index === tabPercents.length - 1 ? "intensity_element radius-right" : "intensity_element"}
                                     style={{width: object.percent + "%"}}>
                                    {object.percent > 5 &&
                                    <>

                                        <p className="text-intensity-bar mt-auto mb-1">
                                            {object.name}
                                        </p>
                                        <p className="text-intensity-bar mb-auto">
                                            <>{object.value}</>
                                        </p>
                                    </>
                                    }
                                </div>
                            )}
                        </div>
                        {displayTab ?
                            <ActivitySessionIntensityTab
                                displayTab={displayTab}
                                setDisplayTab={setDisplayTab}
                                pathType={pathType}
                                discipline={discipline}
                                onChangePathType={onChangePathType(pathType)}

                            />
                            :
                            <span className="pointer more-information" onClick={() => {
                                setDisplayTab(true)
                            }}>{t('app:athlete.training_zone.btn_zones_info')}</span>
                        }
                    </div>
                </div>
                <ActivitySessionChart
                    discipline={discipline}
                    datasChart={datasChart}
                    xAxis={xAxis}
                    trainingConceptCode={trainingConceptCode}
                    activitySessionId={activitySessionId}
                />
            </div>
            }
        </>
    )
}

export default ActivitySessionIntensity;