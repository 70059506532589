import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form} from "react-bootstrap";
import {
    eye,
    eye_slash,
    tic_green,
    tic_grey,
    validate
} from "../../img/img";
import generateHeaders from "../../Functions/generateHeaders";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import {handleFocus, showPassword} from "../Home/InputChecking";

const ChangePassword = ({handleEditPassword}) => {
    const {t} = useTranslation(['app'])

    const [currentPassword, setCurrentPassword] = useState("")
    const [plainPassword, setPlainPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [passwordOk, setPasswordOk] = useState({
        newPassword: false,
        oldPassword : false,
        confirmPassword: false
    })

    const checkPassword = () => {
        let pwdformat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{8,}$/
        let inputPassword = document.getElementById("formBasicPassword")
        let pwdMaj = /^(?=.*[A-Z])/
        let pwdMin = /^(?=.*[a-z])/
        let pwdLong = /^.{8,}/
        let pwdSpe = /^(?=.*\W)/
        let pwdNum = /^(?=.*[0-9])/

        if(inputPassword.value.match(pwdMaj)) {
            document.getElementById("ticGrey-checkMaj").setAttribute("style", "display:none!important")
            document.getElementById("ticGreen-checkMaj").setAttribute("style", "display:block!important")
        } else if (!inputPassword.value.match(pwdMaj)) {
            document.getElementById("ticGrey-checkMaj").setAttribute("style", "display:block!important")
            document.getElementById("ticGreen-checkMaj").setAttribute("style", "display:none!important")
        }

        if(inputPassword.value.match(pwdMin)) {
            document.getElementById("ticGrey-checkMin").setAttribute("style", "display:none!important")
            document.getElementById("ticGreen-checkMin").setAttribute("style", "display:block!important")
        } else if (!inputPassword.value.match(pwdMin)) {
            document.getElementById("ticGrey-checkMin").setAttribute("style", "display:block!important")
            document.getElementById("ticGreen-checkMin").setAttribute("style", "display:none!important")
        }

        if(inputPassword.value.match(pwdLong)) {
            document.getElementById("ticGrey-checkLong").setAttribute("style", "display:none!important")
            document.getElementById("ticGreen-checkLong").setAttribute("style", "display:block!important")
        } else if (!inputPassword.value.match(pwdLong)) {
            document.getElementById("ticGrey-checkLong").setAttribute("style", "display:block!important")
            document.getElementById("ticGreen-checkLong").setAttribute("style", "display:none!important")
        }

        if(inputPassword.value.match(pwdSpe)) {
            document.getElementById("ticGrey-checkSpe").setAttribute("style", "display:none!important")
            document.getElementById("ticGreen-checkSpe").setAttribute("style", "display:block!important")
        } else if (!inputPassword.value.match(pwdSpe)) {
            document.getElementById("ticGrey-checkSpe").setAttribute("style", "display:block!important")
            document.getElementById("ticGreen-checkSpe").setAttribute("style", "display:none!important")
        }

        if(inputPassword.value.match(pwdNum)) {
            document.getElementById("ticGrey-checkNum").setAttribute("style", "display:none!important")
            document.getElementById("ticGreen-checkNum").setAttribute("style", "display:block!important")
        } else if (!inputPassword.value.match(pwdNum)) {
            document.getElementById("ticGrey-checkNum").setAttribute("style", "display:block!important")
            document.getElementById("ticGreen-checkNum").setAttribute("style", "display:none!important")
        }

        if(inputPassword.value.match(pwdformat))
        {
            setPasswordOk((oldPasswordOk) => {
                let newPasswordOk = {... oldPasswordOk}
                newPasswordOk.newPassword = true
                return newPasswordOk
            })
        }
        else
        {
            setPasswordOk((oldPasswordOk) => {
                let newPasswordOk = {... oldPasswordOk}
                newPasswordOk.newPassword = false
                return newPasswordOk
            })
        }
    }

    const handleChange = (event) => {
        setPlainPassword(event.target.value)
        if (event.target.name === "passwordChange") {
            checkPassword(event)
        } else {
            if (event.target.value !== "") {
                document.getElementById("ticImg-"+event.target.id).setAttribute("style", "visibility:visible!important");
            } else {
                document.getElementById("ticImg-"+event.target.id).setAttribute("style", "visibility:hidden!important");
            }
        }

        if (event.target.value === "") {
            event.target.setAttribute("style", "padding-top:0!important")
            document.getElementById("little-texte-"+event.target.id).setAttribute("style", "display:none!important")
        } else {
            document.getElementById("little-texte-"+event.target.id).setAttribute("style", "display:block!important")
            event.target.setAttribute("style", "padding-top:16px!important")
        }
    }

    const onChangeOldPassword = (event) => {
        setCurrentPassword(event.target.value)
        setPasswordOk((oldPasswordOk) => {
            let newPasswordOk = {... oldPasswordOk}
            newPasswordOk.oldPassword = true
            return newPasswordOk
        })
    }

    const onChangeConfirmPassword = (event) => {
        setConfirmPassword(event.target.value)
        setPasswordOk((oldPasswordOk) => {
            let newPasswordOk = {... oldPasswordOk}
            newPasswordOk.confirmPassword = true
            return newPasswordOk
        })
    }

    const putPassword = () => {
        let headers = generateHeaders()
        if (headers) {
            axios
                .put(
                    config + "api/my_profile/change_password",
                    {
                        current_password: currentPassword,
                        plain_password: plainPassword,
                        confirm_password: confirmPassword
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    }
                )
                .then(response => {
                    if (response.status === 200) {
                        sessionStorage.setItem("token", response.data["secret"])
                        handleEditPassword(false)
                    }


                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }

    }

    return (
        <>
            <div className="pcy-2">
                <input placeholder={t('app.profile.update_password.current_password')+' *'}
                       type="password"
                       value={currentPassword}
                       name="currentPassword"
                       onChange={(e) => setCurrentPassword(e.target.value)}
                       className="d-flex align-items-center pcx-3 grey-border radius-8 form-login-input input-change-password mcb-3 w-100"
                />
                    <>
                        <Form.Group controlId="formBasicPassword" className="mcb-2 form-input-height">
                        <Form.Control className="form-login-input grey-border radius-8 input-change-password pcl-3 py-0"
                                      type="password"
                                      name="passwordChange"
                                      placeholder={t('app.profile.update_password.new_password')+" *"}
                                      value={plainPassword}
                                      onChange={handleChange}
                                      onFocus={handleFocus}
                                      onBlur={handleFocus}
                                      required
                        />
                        <div className="test-eye ml-auto mcr-3">
                            <img
                                id="eyeSlashImg"
                                src={eye_slash}
                                alt="eye slash picto"
                                className="icon-24"
                                onClick={showPassword}
                            />
                            <img
                                id="eyeImg"
                                src={eye}
                                alt="eye picto"
                                className="icon-24 d-none"
                                onClick={showPassword}
                            />
                        </div>
                        <p id="little-texte-formBasicPassword" className="little-text-input grey-border radius-8 mb-0 mcl-3 mct-2 text-left d-none">{t('app:login.password')+" *"}</p>
                    </Form.Group>
                    <div id="check-pwd" className="w-100 text-left mcb-4 mcl-3 d-none" >
                        <div id="checkLong" className="d-flex align-items-center mcb-1">
                            <img
                                id="ticGrey-checkLong"
                                src={tic_grey}
                                alt="tic grey picto"
                                className="icon-12"
                            />
                            <img
                                id="ticGreen-checkLong"
                                src={tic_green}
                                alt="tic grey picto"
                                className="icon-12 d-none"
                            />
                            <p className="validate-pwd mb-0 mcl-2">
                                {t('app:register.8_caracteres')}
                            </p>
                        </div>
                        <div id="checkMin" className="d-flex align-items-center mcb-1">
                            <img
                                id="ticGrey-checkMin"
                                src={tic_grey}
                                alt="tic grey picto"
                                className="icon-12"
                            />
                            <img
                                id="ticGreen-checkMin"
                                src={tic_green}
                                alt="tic grey picto"
                                className="icon-12 d-none"
                            />
                            <p className="validate-pwd mb-0 mcl-2">
                                1 {t('app:register.minuscule')}
                            </p>
                        </div>
                        <div id="checkMaj" className="d-flex align-items-center mcb-1">
                            <img
                                id="ticGrey-checkMaj"
                                src={tic_grey}
                                alt="tic grey picto"
                                className="icon-12"
                            />
                            <img
                                id="ticGreen-checkMaj"
                                src={tic_green}
                                alt="tic grey picto"
                                className="icon-12 d-none"
                            />
                            <p className="validate-pwd mb-0 mcl-2">
                                1 {t('app:register.majuscule')}
                            </p>
                        </div>
                        <div id="checkNum" className="d-flex align-items-center mcb-1">
                            <img
                                id="ticGrey-checkNum"
                                src={tic_grey}
                                alt="tic grey picto"
                                className="icon-12"
                            />
                            <img
                                id="ticGreen-checkNum"
                                src={tic_green}
                                alt="tic grey picto"
                                className="icon-12 d-none"
                            />
                            <p className="validate-pwd mb-0 mcl-2">
                                1 {t('app:register.number')}
                            </p>
                        </div>
                        <div id="checkSpe" className="d-flex align-items-center mcb-1">
                            <img
                                id="ticGrey-checkSpe"
                                src={tic_grey}
                                alt="tic grey picto"
                                className="icon-12"
                            />
                            <img
                                id="ticGreen-checkSpe"
                                src={tic_green}
                                alt="tic grey picto"
                                className="icon-12 d-none"
                            />
                            <p className="validate-pwd mb-0 mcl-2">
                                1 {t('app:register.spec_carac')} (%, #, -, ?, !)
                            </p>
                        </div>
                    </div>
                    </>
            </div>
            <div className="">
                    <input placeholder={t('app.profile.update_password.new_password_confirmation')+' *'}
                           type="password"
                           value={confirmPassword}
                           onChange={(e) => setConfirmPassword(e.target.value)}
                           className="w-100 grey-border radius-8 d-flex align-items-center pcx-3 form-login-input input-change-password"
                    />
                    <Button className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mct-3'
                            type="submit"
                            onClick={putPassword}
                            disabled={passwordOk.oldPassword && passwordOk.newPassword && passwordOk.confirmPassword}>
                        <img className="mr-2 icon-24" alt="valid icon" src={validate} />{t('app.profile.update_password.save')}
                    </Button>
                    <span onClick={() => handleEditPassword(false)} className="settings-btn mcb-5 d-block" style={{ float: "none" }} >{t('app.profile.update_password.cancel')}</span>
            </div>
        </>
    )
}
export default ChangePassword