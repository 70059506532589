import React, {useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import Loader from "react-loader-spinner";
import {TalkJSContext} from "../../Contexts";

const AddConversation = ({className, decrementRemainingCoachMessageNumber}) => {

    const {t} = useTranslation(['app']);

    const [showAddButton, setShowAddButton] = useState(true)
    const [showConversationForm, setShowConversationForm] = useState(true)
    const [conversationTitle, setConversationTitle] = useState("")
    const [errorMessage, setErrorMessage] = useState(null)

    const {updateConversationId} = useContext(TalkJSContext)

    const addConversation = (event) => {
        setShowAddButton(false)
        setShowConversationForm(true)
    }

    const newConversation = (e) => {
        e.stopPropagation()
        if(conversationTitle === "" || conversationTitle === null) {
            return
        }

        setShowConversationForm(false)

        let datas = {
            title: conversationTitle
        }

        axios
            .post(
                config+"api/conversations/new",
                datas,
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    }
                },
            )
            .then(response => {
                // Ouverture de la conversation dans le chat
                updateConversationId(response.data.conversation_id)

                // Cacher le formulaire & afficher le bouton
                setConversationTitle(null)
                setErrorMessage(null)
                setShowConversationForm(false)
                setShowAddButton(true)
                setShowConversationForm(true)

                // Mettre à jour le nombre de message disponible
                decrementRemainingCoachMessageNumber()
            })
            .catch(error => {
                handleEndpointError(error)
                setShowConversationForm(true)
                if(error.response !== undefined) {
                    if (error.response.status === 403) {
                        setErrorMessage(t("athlete.conversation.send.error"))
                    }
                }
            })

    }


    return (
        <div className={className}
             onClick={e => e.stopPropagation()}
        >
            {showAddButton ?
                <Button onClick={(event) => addConversation(event)}
                        className='add-chat-conversation mx-auto d-flex align-items-center justify-content-center'>
                    <p className="my-auto"><i className="bi bi bi-envelope-open mcr-2"></i>{t("athlete.conversation.new.cta")}</p>
                </Button>
                :
                <div className="d-flex justify-content-center align-items-center">
                    {showConversationForm &&
                    <>
                        <input className="add-chat-conversation-input form-login-input pc-3 background-white"
                               name="conversation"
                               value={conversationTitle}
                               onChange={event => setConversationTitle(event.target.value)}
                               autoFocus
                        />
                        <Button className='add-chat-conversation mb-1 mcl-2 d-flex align-items-center justify-content-center'
                                onClick={(e) => newConversation(e)}
                        >
                            <p className="my-auto"><i className="bi bi-envelope-fill mcr-2"></i>{t("athlete.conversation.send.cta")}</p>
                        </Button>
                    </>
                    }
                    {!showConversationForm &&
                    <Loader
                        type="TailSpin"
                        color="#0069d9"
                        height={40}
                        width={40}
                    />
                    }
                </div>
            }
            {errorMessage &&
            <span className="fs-9 red-text">{errorMessage}</span>
            }
        </div>
    )
}

export default AddConversation