import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {validate} from "../../../../img/img";
import SelectSport from "../../SelectSport";
import SelectSportCompetition from "../../SelectSportCompetition";
import DatePicker from "react-datepicker";
import {Button, Form} from "react-bootstrap";
import {dataSports} from "../Utils";
import {WeekCalendarContext} from "../../../../Contexts";
import axios from "axios";
import config from "../../../../api";
import generateHeaders from "../../../../Functions/generateHeaders";
import handleEndpointError from "../../../../Functions/Alert";
import {format, formatISO} from "date-fns"

/**
 * Afficher un formulaire de modification de compétition
 *
 * @param date
 * @param closeCompetitionModal
 */

const EditCompetition = ({closeCompetitionModal}) => {

    const {t} = useTranslation(['app'])
    const sports = dataSports(t)

    const {
        athleteEventHandled,
        updateAthleteEventHandled
    } = useContext(WeekCalendarContext)

    const [startDate, setStartDate] = useState(new Date(athleteEventHandled.date))
    const [name, setName] = useState(athleteEventHandled.name)
    const [sport, setSport] = useState(sports.find(x => x.id === athleteEventHandled.sport))
    const [sportCompetition, setSportCompetition] = useState(null)
    const [sportCompetitions, setSportCompetitions] = useState(null)
    const [goal, setGoal] = useState(null)

    const [comment, setComment] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const [disciplinesSportCompetitions, setDisciplinesSportCompetitions] = useState(null)

    const [ready, setReady] = useState(false)

    useEffect(() => {
        setReady(ready)
    }, [ready]);

    useEffect(() => {
        // getSportEvents()
        getSportCompetitions()
    }, []);

    useEffect(() => {
        if (athleteEventHandled) {
            getCompetition()
        }
    }, [athleteEventHandled]);

    useEffect(() => {
        if (sport && disciplinesSportCompetitions) {
            setSportCompetitions(disciplinesSportCompetitions[sport.code])
        }
    }, [sport, disciplinesSportCompetitions]);

    const onChangeName = (value) => {
        setName(value)
        setReady(true)
    }

    const onChangeComment = (value) => {
        setComment(value)
        setReady(true)
    }

    const onChangeSport = (value) => {
        setSport(value)
        setReady(true)
    }

    const onChangeSportCompetition = (value) => {
        setSportCompetition(value)
        setReady(true)
    }

    const onChangeStartDate = (value) => {
        setStartDate(value)
        setReady(true)
    }
    const onChangeGoal = (value) => {
        setGoal(value)
        setReady(true)
    }

    const getCompetition = async () => {
        await axios.get(
            config + "api/competitions/" + athleteEventHandled.id,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            let data = response.data
            let newSportCompetition = {
                id: data.sport_competition.id,
                label: data.sport_competition.name,
                sport: data.sport_competition.sport
            }
            setSportCompetition(newSportCompetition)
            setGoal(data.goal)
            setComment(data.description)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const getSportCompetitions = () => {
        axios.get(
            config + "api/sport_competitions?type=1",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            let options = {
                triathlon: [],
                cap: [],
                trail: [],
                velo: [],
            }

            for (let f of response.data.sport_competitions) {
                let option = {
                    id: f.id,
                    label: f.name,
                    sport: f.sport
                }
                switch (f.sport) {
                    case 1:
                        options.triathlon.push(option)
                        break
                    case 2:
                        options.cap.push(option)
                        break
                    case 3:
                        options.trail.push(option)
                        break
                    case 4:
                        options.velo.push(option)
                        break
                    default:
                        break;
                }
            }
            setDisciplinesSportCompetitions(options)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    // Enregister la competition
    const saveCompetition = () => {
        const headers = generateHeaders()

        let sportId = sport ? sport.id : null
        let sportCompetitionId = sportCompetition ? sportCompetition.id : null

        let dateISO = formatISO(startDate)
        let datas = {
            name: name,
            sport: sportId,
            sport_competition_id: sportCompetitionId,
            start_date: dateISO,
            end_date: dateISO,
            goal: goal,
            description: comment
        }
        axios
            .put(
                config + "api/competitions/" + athleteEventHandled.id,
                datas,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then((response) => {

                let newAthleteEventHandled = athleteEventHandled
                athleteEventHandled.name = datas.name
                athleteEventHandled.sport = datas.sport
                athleteEventHandled.date = datas.start_date
                updateAthleteEventHandled(newAthleteEventHandled)

                // Fermer le formulaire de création de compte-rendu
                closeCompetitionModal(format(startDate, 'yyyy-MM-dd'))
            })
            .catch(error => {
                handleEndpointError(error)
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.data.errors)
                    }
                }
            })
    }

    return (
        <div className="mcb-7">
            <div id="rpe-card" className="rpe-card pcx-3 pcy-3">
                <div className="activity-CR mcb-4">
                    <div className="row">
                        {/* Evenement sportif */}
                        <div className="col-3 mcy-2 d-flex align-items-center">
                            <span className="label-input-competition">{t('app:athlete.competition.sport_event')}</span>
                        </div>
                        <div className="col-9 mcy-2">
                            {/*<SelectSportEvent sportEvents={sportEvents}*/}
                            {/*defaultSportEvent={sportEvent}*/}
                            {/*onChange={(value) => onChangeSportEvent(value)}*/}
                            {/*/>*/}
                            <input type="text"
                                   className="w-100 form-input-text pcx-3 pcy-2 background-white"
                                   name="name"
                                   placeholder={t('app:athlete.competition.name')}
                                   value={name}
                                   onChange={event => onChangeName(event.target.value)}
                            />
                        </div>
                        {/* Sport select avec Tools/CustomSelect*/}
                        <div className="col-3 mcy-2 d-flex align-items-center">
                                    <span className="label-input-competition" onClick={() => {
                                    }}>{t('app:athlete.competition.sport')}</span>
                        </div>
                        <div className="col-9 mcy-2">
                            {sport ?
                                <>
                                    <SelectSport sports={sports}
                                                 defaultSport={sport}
                                                 onChange={(value) => onChangeSport(value)}
                                    />
                                </>
                                :
                                <input type="text"
                                       className="w-100 form-input-text pcx-3 pcy-2 background-white"
                                       name="sport"
                                       value={sport.label}
                                       disabled={1}
                                />
                            }
                        </div>
                        {/* SportCompetition select avec Tools/CustomSelect */}
                        <div className="col-3 mcy-2 d-flex align-items-center">
                                    <span className="label-input-competition" onClick={() => {
                                    }}>{t('app:athlete.competition.sport_competition')}</span>
                        </div>
                        <div className="col-9 mcy-2">
                            {sportCompetitions ?
                                <SelectSportCompetition sportCompetitions={sportCompetitions}
                                                        defaultSportCompetition={sportCompetition}
                                                        sportValue={sportCompetition}
                                                        onChange={(value) => onChangeSportCompetition(value)}
                                />
                                :
                                sportCompetition &&
                                <input type="text"
                                       className="w-100 form-input-text pcx-3 pcy-2 background-white"
                                       name="sport_competition"
                                       value={sportCompetition.label}
                                       disabled={1}
                                />
                            }
                        </div>
                        {/* Date */}
                        <div className="col-3 mcy-2 d-flex align-items-center">
                            <span className="label-input-competition">Date</span>
                        </div>
                        <div className="react-datepicker-lefted col-9 mcy-2">
                            <DatePicker
                                wrapperClassName="w-100"
                                className="custom-input w-100"
                                dateFormat="P"
                                selected={startDate}
                                onChange={(date) => onChangeStartDate(date)}
                            />
                        </div>
                        {/* Priorité :  3 boutons radios */}
                        <div className="col-3 mcy-2 d-flex align-items-start">
                                    <span
                                        className="label-input-competition">{t('app:athlete.competition.goal.label')}</span>
                        </div>
                        <div className="col-9 mcy-2">
                            <div className="checkbox-goal w-100 mcb-2">
                                <input
                                    id={"major_radio"}
                                    type="radio"
                                    name="major"
                                    value={1}
                                    checked={goal === 1}
                                    onChange={() => onChangeGoal(1)}
                                />
                                <label htmlFor={"major_radio"}
                                       className="gotham-15-black">{t('app:athlete.competition.goal.1')}</label>
                            </div>
                            <div className="checkbox-goal w-100 mcb-2">
                                <input
                                    id={"minor_radio"}
                                    type="radio"
                                    name="minor"
                                    value={2}
                                    checked={goal === 2}
                                    onChange={() => onChangeGoal(2)}
                                />
                                <label htmlFor={"minor_radio"}
                                       className="gotham-15-black">{t('app:athlete.competition.goal.2')}</label>
                            </div>
                            <div className="checkbox-goal w-100 mcb-2">
                                <input
                                    id={"training_radio"}
                                    type="radio"
                                    name="training"
                                    value={3}
                                    checked={goal === 3}
                                    onChange={() => onChangeGoal(3)}
                                />
                                <label htmlFor={"training_radio"}
                                       className="gotham-15-black">{t('app:athlete.competition.goal.3')}</label>
                            </div>
                        </div>

                        {/* Commentaire pour une competition */}
                        <div className="col-12 mct-2">
                            <p className="rpe-title text-left">{t('app:athlete.competition.comment')}</p>
                        </div>
                        <div className="col-12">
                            <Form.Group controlId="form-comment">
                                <Form.Control className="mcy-2 form-login-input pc-3 background-white"
                                              as="textarea"
                                              rows={2}
                                              name="comment"
                                              placeholder={t('app:athlete.debriefing.comment.placeholder')}
                                              value={comment}
                                              onChange={event => onChangeComment(event.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>
                        </div>

                        <div className="w-100 mct-5 d-flex justify-content-center">
                            {errorMessage &&
                            <div className="error-login-text mcb-3">
                                {errorMessage}
                            </div>
                            }
                            <Button
                                className="background-black py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text"
                                onClick={() => saveCompetition()}
                                disabled={!ready}
                            >
                                <img className="mr-2 icon-24" alt="valid icon"
                                     src={validate}/>{t('app:athlete.saving.save')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default EditCompetition