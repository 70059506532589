import React from 'react';
import '../../Css/Home.css';


export default function InputSwitch({labelRight, labelLeft, onSwitchChange, selectOpt, addClassName}) {

    return (
        <div className={"d-flex input-switch justify-content-start align-items-center "+ addClassName}>
            <p className={(selectOpt ? "out-in grey-language" : "out-in font-weight-bold") + " pcr-2"}>{labelLeft}</p>
            <span className="toggle-switch">
                <input type="checkbox" checked={selectOpt} onChange={()=>{}}/>
                <span className="switch" onClick={() => onSwitchChange()} />
            </span>
            <p className={(selectOpt ? "out-in font-weight-bold" : "out-in grey-language") + " pcl-2"}>{labelRight}</p>
        </div>
    )
}