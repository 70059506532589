import React from 'react';
import Select from 'react-select'

/**
 * Propriété à utiliser à minima:
 *  options : est un tableau d'objet qui doivent avoir au moins les clés id et label
 *      * 'id': contient la valeur (value),
 *      * 'label': ce qui doit être affiché
 *
 * Doc react-select : https://react-select.com/home
 *
 */
function CustomSelect(props) {
    return (
        <Select
            {...props}
            classNamePrefix="react-select"
            getOptionValue={({ id }) => id}
            placeholder={"-"}
            components={{
                IndicatorSeparator: () => null
            }}
        />
    )
}

export default CustomSelect;
