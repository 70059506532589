import React from "react";
import {Container, Row, Col} from 'react-bootstrap';
import {useTranslation} from "react-i18next";

import WorkoutExercise from "./WorkoutExercise";
import {getDisciplineIconParams} from "./Utils";

const WorkoutSection = ({section, isRealized, runTrainingConceptCode, swimTrainingConceptCode, cycleTrainingConceptCode}) => {

    const {t} = useTranslation(['app'])
    let sectionName = section.is_wbal ? t('workout.section.wbal') : section.name
    return (
        <Container fluid="md" className="mb-3">
            <Row>
                <Col className="text-left mb-4">
                    <h2 className="seance-title2">{section.repetitions}x {sectionName}</h2>
                </Col>
            </Row>
            <Row>
                {section.exercises.map((exo, index) => {
                    let disciplineCode = exo.discipline.code
                    let disciplineIconParams = getDisciplineIconParams(isRealized, disciplineCode)
                    let trainingConceptCode = null
                    switch(disciplineCode) {
                        case 'nat':
                            trainingConceptCode = swimTrainingConceptCode
                            break
                        case 'velo':
                            trainingConceptCode = cycleTrainingConceptCode
                            break
                        case 'cap':
                            trainingConceptCode = runTrainingConceptCode
                            break
                        default:
                            break
                    }
                    return (
                        <WorkoutExercise
                            key={exo.id}
                            exo={exo}
                            discipline={disciplineCode}
                            trainingConceptCode={trainingConceptCode}
                            {...disciplineIconParams}
                        />
                    )
                })}
            </Row>
        </Container>
    )
}


export default WorkoutSection;