import React, {useEffect, useState, useContext} from 'react'
import {useTranslation} from "react-i18next";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Loader from 'react-loader-spinner'
import '../../Css/Intensites.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {Metrics} from "../../Functions/Metrics";
import {TRAININGSUMMARYPERIODS, TRAININGSUMMARYTYPES} from "./Utils"
import moment from 'moment';


import { MetricContext, NavigationContext} from "../../Contexts";
import {convertSecondsToChrono, secondsToChronoForChart} from "../AthleteCalendar/Utils";

function TrainingSummaryChart ({datas, disciplines, xAxis, period}) {

    const {t} = useTranslation(['app'])

    // métrique utilisateur
    const {metric} = useContext(MetricContext)
    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const metrics = Metrics(metric)

    // const [activeWeek, setActiveWeek] = useState(moment(activeDayProp))
    // const activeWeekNValue = activeWeek.format('W')

    const [loaded, setLoaded] = useState(0)

    const [color, setColor] = useState(null)
    const [backgroundColor, setBackgroundColor] = useState(null)
    const [dataColor, setDataColor] = useState(null)
    const [dataHoverColor, setDataHoverColor] = useState(null)
    const [categories, setCategories] = useState(null)
    const [summary, setSummary] = useState(null)
    const [distanceUnit, setDistanceUnit] = useState(null)
    const [showDistance, setShowDistance] = useState(true)

    const [optionsHigh, setOptionsHigh] = useState(null)
    const yAxisMargin = 5;
    const durationColor = '#CCD4DA'
    const altitudeColor = '#4D4D4D'

    const [isNat, setIsNat] = useState(false)

    const totalAscentUnit = metrics.altitude

    useEffect(() => {
        if(disciplines) {
            let colorCode, backgroundColor, dataColor, dataHoverColor

            if (disciplines.length===1) {
                switch(disciplines[0]) {
                    case 'nat':
                        colorCode = '#007DFF'
                        backgroundColor = 'rgba(0, 125, 255, 0.05)'
                        dataColor = '#B3D8FF'
                        dataHoverColor = colorCode
                        break
                    case 'velo':
                        colorCode = '#00D254'
                        backgroundColor = 'rgba(0, 210, 84, 0.05)'
                        dataColor = '#7DDDA4'
                        dataHoverColor = colorCode
                        break
                    case 'cap':
                        colorCode = '#FFA800'
                        backgroundColor = 'rgba(241, 87, 0, 0.05)'
                        dataColor = '#FFC3A2'
                        dataHoverColor = '#F15700'
                        break
                    default:
                        colorCode = '#000000'
                        break
                }
            } else {
                colorCode = '#F15700'
                backgroundColor = 'rgba(241,87,0,0.05)'
                dataColor = '#e77f44'
                dataHoverColor = colorCode
            }
            setColor(colorCode)
            setBackgroundColor(backgroundColor)
            setDataColor(dataColor)
            setDataHoverColor(dataHoverColor)

            setDistanceUnit((disciplines.length===1 && disciplines[0] === "nat") ? metrics.distance_nat : metrics.distance_cap_velo)

            setShowDistance((disciplines.length===1 && disciplines[0] === "muscu") ? false : true)

        }

        setIsNat(disciplines && disciplines.length === 1 && disciplines[0] === 'nat' ? true : false)

    }, [disciplines, metric])

    useEffect(() => {
        // Réinitialiser l'axe des abscisses
        setSummary(null)
        setCategories(null)
        setLoaded(0)
    },[xAxis])


    useEffect(() => {
        let coefDistance = isNat ? 1000 : 1
        if(datas && datas.length && xAxis) {
            let dataCategories = []
            let dataDurations = []
            let dataDistances = []
            let dataDistancesUk = []
            let dataTotalAscents = []
            let dataTotalAscentsUk = []

            for(let data of datas) {
                switch(xAxis) {
                    case TRAININGSUMMARYTYPES.WEEKLY:
                        dataCategories.push(t('app.date.week_initial')+"."+data.week_number)
                        break
                    case TRAININGSUMMARYTYPES.MONTHLY:
                        dataCategories.push(moment(data.month_number, "MM").format('MMM'))
                        break
                    case TRAININGSUMMARYTYPES.ANNUAL:
                        dataCategories.push(data.year_number)
                        break
                    default:
                        break
                }

                let dataSummary = data.summary.total
                // Durées
                dataDurations.push({name: moment(data.start_date).format('MMMM YYYY'), y: dataSummary.sec})

                // Distances
                dataDistances.push(dataSummary.distance * coefDistance)
                dataDistancesUk.push(dataSummary.distance_uk)

                // Gain d'altitude
                dataTotalAscents.push(dataSummary.total_ascent)
                dataTotalAscentsUk.push(dataSummary.total_ascent_uk)

            }
            let newSummary = {
                durations: dataDurations,
                distances: dataDistances,
                distances_uk: dataDistancesUk,
                total_ascents: dataTotalAscents,
                total_ascents_uk: dataTotalAscentsUk,
            }
            setSummary(newSummary)
            if(dataCategories.length) {
                setCategories(dataCategories)
            }
        }
    }, [datas, isNat])

    useEffect(() => {
        if (metric && categories && summary && xAxis && color && distanceUnit && totalAscentUnit) {
            let startOfDay = moment().subtract(1,'days').startOf('day')

            let distances = (metric === Metrics.imperial ? summary.distances_uk : summary.distances)
            let totalAscents = (metric === Metrics.imperial ? summary.total_ascents_uk : summary.total_ascents)
            let durations = summary.durations

            let options = {
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                chart: {
                    height: 280,
                    plotBackgroundColor:backgroundColor,
                }
                ,
                credits: {
                    enabled: false
                },
                title: {
                    text: null
                },
                legend: {
                    enabled: false
                },
                xAxis: [{
                    categories: categories,
                    crosshair: true,
                    lineWidth: 0,
                }],
                yAxis: [{ // Duration
                    opposite: true,
                    zIndex: 1,
                    gridLineWidth: 0,
                    title: {
                        text: t('app.calendar.training_summary.labels.duration')+"<br/>(h)",
                        offset: yAxisMargin,
                        align: 'low',
                        useHtml: true,
                        style: {
                            fontSize: "10px",
                            color: durationColor
                        }
                    },
                    labels: !isMobile ? {
                        x: -3,
                        y: 2,
                        formatter:function(){
                            if(this.value !== 0){
                                return startOfDay.seconds(this.value).format('H')
                            }
                        },
                        style: {
                            color: durationColor,
                            opacity: 0.4
                        }
                    }: {
                        enabled: false
                    }
                }, { // Distance
                    zIndex: 2,
                    gridLineWidth: 0,
                    title: showDistance ? {
                        text: t('app.calendar.training_summary.labels.distance')+"<br/>("+ distanceUnit+")",
                        offset: yAxisMargin,
                        align: 'low',
                        useHtml: true,
                        style: {
                            fontSize: "10px",
                            color: color
                        }
                    }: false,
                    labels: !isMobile ? {
                        x: -3,
                        y: 2,
                        formatter:function(){
                            if(this.value !==0){
                                return this.value;
                            }
                        },
                        style: {
                            color: color,
                            opacity: 0.7
                        }
                    }: {
                        enabled: false
                    }
                }, { // Altitude
                    zIndex: 0,
                    gridLineWidth: 0,
                    title: {
                        enabled: false
                    },
                    labels: {
                        enabled: false,
                    },
                }],
                tooltip: {
                    shared:true,
                    shadow: true,
                    useHTML: true,
                    borderWidth:0,
                    background:'transparent',
                    formatter: function(){
                        let titleToDisplay = ''
                        let contentToDisplay = ''
                        for (let i=0; i<this.points.length; i++) {
                            let point = this.points[i]
                            switch (point.color) {
                                //
                                case dataColor:
                                    if(showDistance) {
                                        contentToDisplay += '<span class="text"> ' + '<span style="font-weight: bold; color: ' + color + '">' + t("app.calendar.training_summary.labels.distance") + '</span> : ' + (point.y ? (point.y.toFixed(isNat ? 0 : 1) + ' ' + distanceUnit):'-') + '</span><br>'
                                    }
                                    break
                                case durationColor:
                                    if(xAxis !== TRAININGSUMMARYTYPES.ANNUAL){
                                        let monthName = point.point.name
                                        titleToDisplay = '<span class="text"> ' + '<span style="font-weight: bold;">' + monthName.charAt(0).toUpperCase() + monthName.slice(1)  + '</span><br>'
                                    }
                                    contentToDisplay += '<span class="text"> ' + '<span style="font-weight: bold; color: ' + point.color + '">' + t("app.calendar.training_summary.labels.duration") + '</span> : ' + (point.y ? convertSecondsToChrono(point.y, 'H[h]mm'):'-') + '</span><br>'
                                    break
                                case altitudeColor:
                                    if(showDistance && disciplines.length===1 && disciplines[0] !== 'nat') {
                                        contentToDisplay += '<span class="text"> '+ '<span style="font-weight: bold; color: ' + point.color + '">' + t("app.calendar.training_summary.labels.elevation_gain") + '</span> : ' + (point.y ? (Math.round(point.y) + ' ' + totalAscentUnit):'-') + '</span><br>'
                                    }
                                    break
                            }
                        }

                        return '<div class="cursor"><span class="dome"></span>'+ titleToDisplay + contentToDisplay + '</div>'
                    },
                    hideDelay:0
                },
                series: [{
                    // name: 'Distance',
                    type: 'column',
                    yAxis: 1,
                    data: distances,
                    tooltip: {
                        valueSuffix: ' '+distanceUnit
                    },
                    visible: showDistance
                }, {
                    // name: 'Durée',
                    type: 'areaspline',
                    data: durations,
                    tooltip: {
                        valueSuffix: ''
                    }
                }, {
                    // name: 'Altitude',
                    type: 'line',
                    data: totalAscents,
                    tooltip: {
                        valueSuffix: ''
                    },
                    opacity: 0
                }],
                plotOptions: {
                    series:{
                        point:{
                            events: {
                                mouseOver: function() {
                                    if(this.series.chart.xAxis[0].labelGroup.element.childNodes[this.x] !== undefined) {
                                        this.series.chart.xAxis[0].labelGroup.element.childNodes[this.x].classList.add('bold-label')
                                    }
                                },
                                mouseOut: function() {
                                    if(this.series.chart.xAxis[0].labelGroup.element.childNodes[this.x] !== undefined) {
                                        this.series.chart.xAxis[0].labelGroup.element.childNodes[this.x].classList.remove('bold-label')
                                    }
                                }
                            }
                        },
                    },
                    column: {
                        borderRadius: isMobile ? (xAxis === TRAININGSUMMARYTYPES.WEEKLY && [TRAININGSUMMARYPERIODS.LAST_6_MONTHS, TRAININGSUMMARYPERIODS.BEGINNING_YEAR].indexOf(period) !== -1 ? 2 : 5) : 10,
                        pointWidth: isMobile ? (xAxis === TRAININGSUMMARYTYPES.WEEKLY && [TRAININGSUMMARYPERIODS.LAST_6_MONTHS, TRAININGSUMMARYPERIODS.BEGINNING_YEAR].indexOf(period) !== -1 ? 5 : 15) : 20,
                        color: dataColor,
                        states: {
                            hover: {
                                color: dataHoverColor
                            }
                        }
                    },
                    areaspline: {
                        marker: {
                            enabled: false
                        },
                        color: durationColor,
                        opacity: 0.6,
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    line: {
                        marker: {
                            enabled: false
                        },
                        color: altitudeColor,
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                }
            }
            setOptionsHigh(options)
            setLoaded(loaded => loaded + 1)
        }
    }, [color, xAxis, summary, categories, metric, distanceUnit, totalAscentUnit, showDistance, period])


    return (
        <>
        {loaded && optionsHigh && categories ?
            <div className="justify-content-center d-flex mt-3">
                <div
                    style={{ width: '100%' }}
                >
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={optionsHigh}
                    />
                </div>
            </div>
            :
            <div className="">
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={80}
                    width={80}
                    className="my-5"
                />
            </div>
        }
        </>
    )
}

export default TrainingSummaryChart