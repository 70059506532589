import React, {useEffect, useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../../Tools/CustomHooks";
import {Container, Row} from "react-bootstrap";
import Loader from "react-loader-spinner";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";

import ActivitySessionStatistic from "./ActivitySessionStatistic"
import ActivitySessionMap from "./ActivitySessionMap"
import ActivitySessionIntensity  from "./ActivitySessionIntensity"
import ActivitySessionRjBalChart  from "./ActivitySessionRjBalChart"
import ActivitySessionLapsTable from "./ActivitySessionLapsTable"
import ActivitySessionPerformanceChart from "./ActivitySessionPerformanceChart"
import {LanguageContext, LoggedInContext, NavigationContext, TrainingConceptConfigContext} from "../../../Contexts";
import {getTrainingConceptByContextCode} from "../../Context/Utils";
import {TrainingConceptCodes} from "../../TrainingConcept/Utils";
import ActivitySessionSuccessIndex from "./ActivitySessionSuccessIndex";
import { reset as resetShare } from "../../../redux/features/share/shareSlice";
import { setNoMap } from "../../../redux/features/map/mapSlice";
import {ContextCodes} from "../../Context/Utils";


const ActivitySession = ({activitySessionId}) => {

    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()

    const { language } = useContext(LanguageContext)
    const trainingContextConfigValue = useContext(TrainingConceptConfigContext)
    const {is_beta_tester} = useContext(LoggedInContext)

    const [activitySession, setActivitySession] = useState(null)
    const [pathType, setPathType] = useState('primary_zones')
    const [chart, setChart] = useState('intensity')
    const [contextCode, setContextCode] = useState(null)
    const [displayCharts, setDisplayCharts] = useState(null)
    const [btnDisplayCharts, setBtnDisplayCharts] = useState(true)
    const [trainingConceptCode, setTrainingConceptCode] = useState(null)

    const {
        mapScreenshot,
        intensity,
        graphScreenshot,
        stats,
    } = useSelector(app => app.share)
    const dispatch = useDispatch()

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)


    useEffect(() => {
        getActivitySession()
    }, [])

    useEffect(() => {
        if(activitySession) {
            let cCode = null
            if(activitySession.context) {
                cCode = activitySession.context.code
            }
            else if (activitySession.discipline) {
                switch(activitySession.discipline.code) {
                    case 'trail':
                        cCode = ContextCodes.RUNNING_OUTDOOR
                        break
                    case 'vtt':
                        cCode = ContextCodes.VTT
                        break
                    default:
                        break
                }
            }
            setContextCode(cCode)
        }
    }, [activitySession])

    useEffect(() => {
        if(contextCode) {
            let trainingConcept = getTrainingConceptByContextCode(contextCode, trainingContextConfigValue)
            setTrainingConceptCode(trainingConcept ? trainingConcept.training_concept_code : TrainingConceptCodes.CRITICAL_INTENSITY)
        }
    }, [contextCode, trainingContextConfigValue])

    useEffect(() => {
        if (
            activitySession
            && ((activitySession.discipline.is_analyzable && !activitySession.statistic.is_indoor && mapScreenshot) || (activitySession.discipline.is_analyzable && activitySession.statistic.is_indoor))
            && graphScreenshot
            && intensity
            && activitySession.discipline.code
            && stats
            && language
        ) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                event: 'share',
                data: {
                    mapScreenshot,
                    graphScreenshot,
                    intensity,
                    stats,
                    discipline: activitySession.discipline.code,
                    disciplineTranslated: t(`app.sports.${activitySession.discipline.code}`),
                    language,
                },
            }))
            dispatch(resetShare())
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapScreenshot, activitySession, stats, language, intensity, graphScreenshot])

    useEffect(() => {
        if (activitySession && activitySession.discipline.is_analyzable && (activitySession.statistic.is_indoor == false)) {
            dispatch(setNoMap(false))
        } else {
            dispatch(setNoMap(true))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activitySession])

    const getActivitySession = () => {
        const headers = generateHeaders()
        axios
            .get(
                config+"api/activity_sessions/"+activitySessionId,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let aSession = response.data
                setActivitySession(aSession)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    if(!activitySession) {
        return(
            <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={50}
                    width={50}
                    className="my-5"
                />
            </div>
        )
    }

    const discipline = activitySession.discipline
    const trainingLevel = activitySession.activity.debriefing ? activitySession.activity.debriefing.training_level : null
    const rpe = activitySession.activity.debriefing ? activitySession.activity.debriefing.rpe : null

    const showStatistic = discipline.is_statisticable
    const showMap = discipline.is_analyzable && (activitySession.statistic.is_indoor == false)
    const showIntensity = discipline.is_analyzable && (!isMobile || chart =='intensity')
    const showRjBalChart = discipline.code !== 'nat' && discipline.is_analyzable && (!isMobile || chart =='rjbal')
    const showLapsTable = discipline.is_lapsable
    const showPerformanceChart = discipline.is_analyzable && discipline.code !== 'trail' // Pour l'instant, le profil de performance ne fonctionne pas pour le trail donc on ne l'affiche pas

    return (
        <Container fluid className="workoutCard pc-4 justify-content-center">
            {is_beta_tester && activitySession.statistic.success_score_value &&
                <ActivitySessionSuccessIndex successScore={activitySession.statistic.success_score_value}/>
            }
            {showStatistic &&
            <ActivitySessionStatistic
                activitySession={activitySession}
                discipline={discipline.code}
                trainingLevel={trainingLevel}
                rpe={rpe}
            />
            }
            {showMap && trainingConceptCode &&
            <ActivitySessionMap
                activitySessionId={activitySessionId}
                pathType={pathType}
                trainingConceptCode={trainingConceptCode}
            />
            }
            {btnDisplayCharts ?
                <div className="d-flex justify-content-center">
                    <div className="btn-blue-white pointer" onClick={() => {
                        setDisplayCharts(true)
                        setBtnDisplayCharts(false)
                    }}>
                        {t('app.activity_session.see_charts')}
                    </div>
                </div>
                :
                <></>
            }
            {!displayCharts ?
                <></>
                :
                <>
                    {showIntensity && trainingConceptCode &&
                    <ActivitySessionIntensity
                        activitySessionId={activitySessionId}
                        ease={Math.round(activitySession.duration /18000)}
                        pathType={pathType}
                        setPathType={setPathType}
                        discipline={discipline.code}
                        trainingConceptCode={trainingConceptCode}
                    />
                    }
                    {!showIntensity && isMobile &&
                    <div className="intensity-section">
                        <Row className="mr-1 mt-4 mb-2 justify-content-between d-flex text-left ">
                            <h2 className="seance-title2">{t('app:intensity.intensities')}</h2>
                        </Row>
                        <button className="mx-auto  home-btn-text  pcx-3 pcy-2 justify-content-center btn btn-primary"
                                onClick={()=>{setChart('intensity')}}
                        >
                            <p className="my-auto fs-9">{t('app.activity_session.see_chart')}</p>
                        </button>
                    </div>
                    }
                    {showRjBalChart &&
                    <ActivitySessionRjBalChart
                        activitySessionId={activitySessionId}
                        discipline={discipline.code}
                    />
                    }
                    {!showRjBalChart && isMobile && discipline.code !== 'nat' &&
                    <div className="intensity-section">
                        <Row className="mr-1 mt-4 mb-2 justify-content-between d-flex text-left ">
                            <h2 className="seance-title2">{t('app:rjbal.title')}</h2>
                        </Row>
                        <button className="home-btn-text  pcx-3 pcy-2 justify-content-center btn btn-primary"
                                onClick={()=>{setChart('rjbal')}}
                        >
                            <p className="my-auto fs-9">{t('app.activity_session.see_chart')}</p>
                        </button>
                    </div>
                    }
                    {showPerformanceChart && trainingConceptCode &&
                    <ActivitySessionPerformanceChart
                        activitySessionId={activitySessionId}
                        discipline={discipline.code}
                        contextCode={contextCode}
                        trainingConceptCode={trainingConceptCode}
                    />
                    }
                    <div className="d-flex justify-content-center">
                        <div className="btn-blue-white pointer" onClick={() => {
                            setDisplayCharts(false)
                            setBtnDisplayCharts(true)
                        }}>
                            {t('app.activity_session.hide_charts')}
                        </div>
                    </div>
                </>
            }
            {showLapsTable &&
            <ActivitySessionLapsTable
                activitySessionId={activitySessionId}
                activitySessionLaps={activitySession.laps}
                discipline={discipline.code}
                format="small"
            />
            }
        </Container>
    )
}

export default ActivitySession;