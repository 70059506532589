import React, {useContext} from 'react';

import '../Css/Home.css';
import {AuthContext, LanguageContext} from "../Contexts";

export default function LanguageSwitch() {
    const {language, updateLanguage} = useContext(LanguageContext)
    const {token} = useContext(AuthContext)

    const onSwitchChange = () => {
        let checkBox = document.getElementById("react-switch-new")
        updateLanguage(checkBox.checked ? "en" : "fr")
    }


    if (token) {
        return <></>
    } else {
        return (
            <div className="d-flex justify-content-start align-items-center position-absolute pc-4 input-switch">
                <p className={language === 'en' ? "out-in grey-language" : "out-in font-weight-bold"}>FR</p>
                <input className="react-switch-checkbox"
                       id={`react-switch-new`}
                       type="checkbox"
                       onChange={onSwitchChange}
                       checked={language === 'en'}
                />
                <label className="react-switch-label mcl-2 mcr-2" htmlFor={`react-switch-new`}>
                    <span className={`react-switch-button`}/>
                </label>
                <p className={language === 'en' ? "out-in font-weight-bold" : "out-in grey-language"}>EN</p>
            </div>
        )
    }
}

