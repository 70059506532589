import React, {useEffect, useState, useContext} from 'react';
import {useTranslation} from "react-i18next";
import '../../Css/Settings.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {arrow_left} from "../../img/img"
import { Tabs, Tab } from 'react-bootstrap';
import Preferences from "./Preferences";
import Subscriptions from "./Subscriptions";
import {DashboardContext, LoggedInContext, NavigationContext} from "../../Contexts";
import NewProfile from "./NewProfile";


function Settings ({openCloseSettings,isSponsorshipOpen}) {

    const {t} = useTranslation(['app']);
    const {isMobile} = useContext(NavigationContext)
    const {settingsAction} = useContext(DashboardContext)

    const {is_subscribed} = useContext(LoggedInContext)
    // const is_subscribed = 1
    const [activeKey, setActiveKey] = useState('profile')

    useEffect(() => {
        if(settingsAction) {
            switch(settingsAction) {
                case 'show_sync_provider':
                    setActiveKey('preferences')
                    break;
                case 'edit_password':
                    setActiveKey('profile')
                    break;
                case 'preferences':
                    setActiveKey('preferences')
                    break;
                case 'subscription':
                    setActiveKey('subscription')
                    break;
                default:
                    break;
            }
        }
    }, [settingsAction])


    const onClose = () => {
        setActiveKey('profile')
        openCloseSettings()
    }

    return (
        <div className={"col-xl-3 pb-5 mb-4 " + (isMobile ?  "px-0 pt-3" :"p-3")}>
            <div id="settings-card" className="dashboard-card background-white">
                <div className="d-flex justify-content-start pct-3 pcx-3">
                    <img
                        className="icon-24 mcr-2 pointer" src={arrow_left} onClick={() => onClose()}
                        alt="arrow left icon"
                    />
                    <p className="settings-title">
                        {t('app:settings.settings')}
                    </p>
                </div>
                <Tabs
                    activeKey={activeKey}
                    onSelect={(value) => setActiveKey(value)}
                    transition={false}
                    id="tab-settings"
                    className=""
                >
                    <Tab eventKey="profile" title={t('app:settings.profil.profil')}>
                        <NewProfile/>
                    </Tab>
                    <Tab eventKey="preferences" title={t('app:settings.preferences.preferences')} >
                        <Preferences/>
                    </Tab>
                    {is_subscribed &&
                    <Tab eventKey="subscription" title={t('app:settings.subscription.subscription')} >
                        <Subscriptions isSponsorshipOpen={isSponsorshipOpen}
                        />
                    </Tab>
                    }
                </Tabs>
            </div>
        </div>
    )

}
export default Settings