import React, {useEffect, useState, useContext} from "react";
import axios from "axios";
import config from "../api";
import generateHeaders from "../Functions/generateHeaders";
import { photo_default } from "../img/img";
import handleEndpointError from '../Functions/Alert';
import {NavigationContext} from "../Contexts";


const Coach = () => {
    const {isMobile} = useContext(NavigationContext)

    const [coach, setCoach] = useState({})

    const fetchCoach = async () => {
        await axios.get(
            config+"api/my_coach",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(async (res) => {
            setCoach(res.data)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    useEffect(() => {
        fetchCoach()
    }, [])

    return (
        <div className={"dashboard-card pc-3 "+(isMobile ? 'mb-5':"")}>
            <img src={coach.avatar ? coach.avatar : photo_default} className="rounded-circle coach-avatar mcb-3 border" alt=""/>
            <p className="w-100 text-center coach-text-1 mb-0">{coach.fullname}</p>
            <p className="w-100 text-center coach-text-2 mb-0">Coach</p>
        </div>
    )
}

export default Coach