import React, {useContext, useState,useEffect} from "react";
import {error, helpCircle, tic} from "../../img/img";
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";
import {NavigationContext} from "../../Contexts";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import moment from "moment";
import {MetricContext} from "../../Contexts";
import {Metrics, MetricValues} from "../../Functions/Metrics";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import Loader from "react-loader-spinner";
import {Form} from "react-bootstrap";

const WeightInput = ({openCloseEdit, getWeightEvolution}) => {

    const {t} = useTranslation(['app']);
    const {isMobile} = useContext(NavigationContext)

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)
    const [lastWeight, setLastWeight] = useState(null)
    const [weightValue, setWeightValue] = useState(null)

    const [loaded, setLoaded] = useState(false)


    useEffect(() => {
        getLastWeight()
    }, [])


    const getLastWeight = async () => {
        await axios.get(
            config + "api/my_last_weight", {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
            },
        ).then((response) => {
            setLastWeight(response.data)
            if (metric === MetricValues.international) {
                setWeightValue(response.data.value)
            } else {
                setWeightValue(response.data.uk_value)
            }
            setLoaded(true)
        }).catch(error => {
            handleEndpointError(error)
        })
    }

    const onSubmit = () => {
        if (lastWeight) {
            let lastWeightDate = moment(lastWeight.date).format("YYYY-MM-DD")
            let today = moment().format("YYYY-MM-DD")
            if (lastWeightDate === today) {
                putWeight()
            } else {
                postNewWeight()
            }
        } else {
            postNewWeight()
        }
    }

    const postNewWeight = () => {
        setLoaded(false)
        axios
            .post(
                config+"api/weights/new",
                {
                    value: weightValue,
                    date: moment().format("YYYY-MM-DD")
                },
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    }
                },
            )
            .then((response) => {

                getWeightEvolution().then(openCloseEdit())
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const putWeight = () => {
        setLoaded(false)
        axios
            .put(
                config+"api/weights/"+lastWeight.id,
                {
                    value: weightValue,
                    date: moment().format("YYYY-MM-DD")
                },
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    }
                },
            )
            .then((response) => {
                getWeightEvolution().then(openCloseEdit())
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const onChangeValue = (event) => {
        setWeightValue(event.target.value)
    }

    return (
        <>
            <p className="text-left mct-2 fs-12">{t('app:athlete.weight_summary.weight_actual')}</p>
            <div className="justify-content-center d-flex mt-3">
                <div className="w-100">
                    {loaded ?
                        <div className="d-flex justify-content-center align-items-center mcb-2">
                            <Form.Group controlId={"formInput-weight"} className={"mb-0 form-input-height d-flex justify-content-center"}>
                                <Form.Control className={"weight-input"}
                                              type={"number"}
                                              step="0.1"
                                              name={"weight"}
                                              value={weightValue}
                                              onChange={event => onChangeValue(event)}
                                              required
                                >
                                </Form.Control>
                            </Form.Group>
                            <p className="mb-0 mcl-2">{metrics.weight}</p>
                        </div>
                    :
                        <div className="pcb-5">
                            <Loader
                                type="TailSpin"
                                color="#0069d9"
                                height={80}
                                width={80}
                                className="my-5"
                            />
                        </div>
                    }
                </div>
            </div>
            <div className="d-flex justify-content-center mcy-3">
                <span className="btn-white-blue" onClick={onSubmit}>{t('athlete.saving.save')}</span>
            </div>
            <span onClick={() => openCloseEdit()} className="edit-wellness pointer w-100 pct-2">{t('athlete.external_activity.cancel')}</span>
        </>
    )
}
export default WeightInput