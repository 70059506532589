import React, {useState, useEffect, useContext} from "react";
import {useTranslation} from "react-i18next";
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import moment from "moment"
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
    x_circle,
    arrow_left,
    edit,
    editNat,
    editVelo,
    editCap,
    editMulti,
    croix,
    check, checkMulti, checkNat, checkVelo, checkCap
} from "../../../img/img";
import shareIcon from "../../../img/share.png";
import {getDisciplineIconParams} from "../Workout/Utils";
import {getVerboseDateWithClock} from "../../../Functions/DatesCalendar";
import {TrainingEventTypes} from "./Utils";
import Workout from "../Workout/Workout";
import EditDebriefing from "../Debriefing/EditDebriefing";
import ActivitySession from "../ActivitySession/ActivitySession";
import AddDebriefing from "../Debriefing/AddDebriefing";
import {NavigationContext, WeekCalendarContext} from "../../../Contexts";
import NotCompleted from "./NotCompleted";
import axios from "axios";
import config from "../../../api";
import uniqid from "uniqid";
import handleEndpointError from "../../../Functions/Alert";
import generateHeaders from "../../../Functions/generateHeaders";
import {useIsMounted} from "../../Tools/CustomHooks";
import { shouldChangeLayer, shouldTakeScreenshots, showButtonLoader as setShowButtonLoader } from "../../../redux/features/share/shareSlice";

const TrainingEventModal = ({trainingEvent, closeTrainingEventModal}) => {

    const {t} = useTranslation(['app'])

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const showButtonLoader = useSelector(app => app.share.showButtonLoader)
    const noMap = useSelector(app => app.map.noMap)
    const dispatch = useDispatch()

    const {updateTrainingEvent, updateCalendarEventToRefresh, activeTabOpen, updateActiveTabOpen} = useContext(WeekCalendarContext)

    const isMounted = useIsMounted()

    const [trainingEventNameIsEdit, setTrainingEventNameIsEdit] = useState(false)
    const [trainingEventName, setTrainingEventName] = useState(trainingEvent ? trainingEvent.name : null)
    const [activeKey, setActiveKey] = useState(null)

    useEffect(() => {
        let defaultActiveKey = null
        if(activeTabOpen) {
            defaultActiveKey = activeTabOpen
            updateActiveTabOpen(null)
        }
        else {
            defaultActiveKey = getDefaultActiveKey()
        }
        setActiveKey(defaultActiveKey)
        // setActiveKey(activeTabOpen ? activeTabOpen : getDefaultActiveKey())
    }, [])

    useEffect(() => {
        if (showButtonLoader) {
            // Start screenshot
            dispatch(shouldChangeLayer(true))
            if (noMap) {
                dispatch(shouldTakeScreenshots(true))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showButtonLoader])

    const getDefaultActiveKey = () => {
        let activeKey = "debriefing"
        if(trainingEvent == null)
            return activeKey

        switch(trainingEvent.type){
            case TrainingEventTypes.SEANCE_PLANIFIEE:
                activeKey = "workout"
                break
            case TrainingEventTypes.SEANCE_REALISEE:
                activeKey = "debriefing"
                break
            case TrainingEventTypes.COMPTE_RENDU_LIBRE:
                activeKey = "debriefing"
                break
            case TrainingEventTypes.ACTIVITE_LIBRE:
            case TrainingEventTypes.ACTIVITE_REALISEE:
                activeKey = trainingEvent.debriefing_is_complete ? 'activity_session':'debriefing'
                break;
            default:
                break;
        }
        return activeKey
    }


    if(trainingEvent == null) {
        return <></>
    }

    const updateActiveKey = (activeKey) => {
        setActiveKey(activeKey)
    }

    const handleDebriefingUpdated = (trainingEvent) => {
        let trainingEventUpdated = {...trainingEvent}
        trainingEventUpdated.debriefing_is_complete = 1
        updateTrainingEvent(trainingEventUpdated)
    }

    const changeTrainingEventName = (value) => {
        setTrainingEventName(value)
    }

    const saveTrainingEventName = () => {
        if (trainingEventName !== trainingEvent.name) {
            const headers = generateHeaders()
            let datas = {}
            if(headers){
                let url = null
                if ([TrainingEventTypes.SEANCE_PLANIFIEE, TrainingEventTypes.SEANCE_REALISEE, TrainingEventTypes.ACTIVITE_REALISEE].indexOf(trainingEvent.type) !== -1 ) {
                    datas.name = trainingEventName
                    url = config+"api/workouts/"+trainingEvent.workout_id
                } else {
                    datas.title = trainingEventName
                    url = config+"api/debriefings/"+trainingEvent.debriefing_id
                }
                if (url) {
                    axios
                        .put(
                            url,
                            datas,
                            {
                                headers: {
                                    'X-WSSE': headers
                                }
                            },
                        )
                        .then(response => {
                            if(!isMounted.current){
                                return 0;
                            }
                            setTrainingEventNameIsEdit(false)

                            // Recharger le trainingEvent
                            updateCalendarEventToRefresh(uniqid("training-event-"+trainingEvent.key))
                        })
                        .catch(error => {
                            handleEndpointError(error)
                        })
                }
            }
        }
        else {
            setTrainingEventNameIsEdit(false)
        }
    }


    // Préparer le header de la modal
    // - icône(s) discipline(s)
    // - titre : nom
    // - sous-titre: date
    const getTrainingEventHeader = () => {
        const disciplines =  trainingEvent.disciplines
        let numberOfDisciplines = trainingEvent.disciplines.length
        let nameColor = 'color-black';
        let borderColor = 'black-border';
        let editIcon = edit
        let checkIcon = check
        if(numberOfDisciplines > 1) {
            nameColor = "color-multi"
            borderColor = "multi-border"
            editIcon = editMulti
            checkIcon = checkMulti
        }
        if(numberOfDisciplines === 1) {
            switch (disciplines[0]) {
                case 'nat':
                    nameColor = "color-nat"
                    borderColor = "nat-border"
                    editIcon = editNat
                    checkIcon = checkNat
                    break;
                case 'velo':
                    nameColor = "color-velo"
                    borderColor = "velo-border"
                    editIcon = editVelo
                    checkIcon = checkVelo
                    break;
                case 'cap':
                    nameColor = "color-cap"
                    borderColor = "cap-border"
                    editIcon = editCap
                    checkIcon = checkCap
                    break;
                case 'vtt':
                    nameColor = "color-vtt"
                    borderColor = "velo-border"
                    editIcon = editVelo
                    checkIcon = checkVelo
                    break;
                case 'kayak':
                    nameColor = "color-kayak"
                    borderColor = "kayak-border"
                    break;
                case 'ski-fond':
                case 'ski-alpin':
                case 'ski-rando':
                    nameColor = "color-ski"
                    borderColor = "ski-border"
                    break;
                case 'muscu':
                case 'renfo':
                    nameColor = "color-muscu"
                    borderColor = "black-border"
                    break;
                default:
                    nameColor = "color-other"
                    borderColor = "black-border"
                    break;
            }
        }

        return(
            <Row md={9} className="mcb-2 align-items-center d-flex pct-3">
                {!isMobile &&
                <div className="position-absolute close-training-event-modal">
                    <img
                        className="icon-24 pointer"
                        src={x_circle}
                        alt="close right circle icon"
                        onClick={() => closeTrainingEventModal()}
                    />
                </div>
                }
                {isMobile &&
                <Col md={1} xs={1} className="justify-content-center align-items-center d-flex mcl-3 mcr-2 px-0 ">
                    <img
                        className="icon-24 mcr-2 pointer"
                        src={arrow_left}
                        alt="arrow left icon"
                        onClick={() => closeTrainingEventModal()}
                    />
                </Col>
                }
                {disciplines.map((discipline, index) => {
                    let {iconBgColor, disciplineIcon} = getDisciplineIconParams(trainingEvent.type !== TrainingEventTypes.SEANCE_PLANIFIEE, discipline)

                    return (
                        <Col key={"discipline-"+discipline.id}
                             className={"training-event-cube "+ iconBgColor + " align-items-center justify-content-center px-0 d-flex " + (numberOfDisciplines === index + 1 ? (!isMobile ? "mcl-3":"mx-0") : (!isMobile ? "ml-3":"mcr-2 ml-0"))}
                             md={1} xs={1}
                        >
                            <img
                                className="icon-24"
                                src={disciplineIcon}
                                alt={discipline+" set icon"}
                            />
                        </Col>
                    );
                })}
                <div className={`align-items-start d-flex  ${isMobile ? "col" : (trainingEventNameIsEdit ? "w-75 mcl-2" : "mcl-2")}`}>
                    {trainingEventNameIsEdit ?
                        <div className={"d-flex align-items-center w-100"}
                        >
                            <input type="text"
                                   className={nameColor + " seance-title " + borderColor + " pc-2 radius-8 pcr-5 " + (isMobile ? 'w-90':'w-100') }
                                   value={trainingEventName}
                                   onChange={(e) => changeTrainingEventName(e.target.value)}
                            />
                            <div className="position-relative validate-input-training-event-name">
                                <img
                                    className="icon-24 pointer"
                                    src={checkIcon}
                                    alt="validate icon"
                                    onClick={() => saveTrainingEventName()}
                                />
                            </div>

                            <img
                                className="icon-24 pointer mcl-2"
                                src={croix}
                                alt="croix close icon"
                                onClick={() => setTrainingEventNameIsEdit(false)}
                            />
                        </div>
                        :
                        <Row xs={1} className="mct-1 align-items-start d-flex">
                            <Col className={nameColor + " seance-title"}>{trainingEventName}{"\n"}</Col>
                            <Col className="dateWorkout">{getVerboseDateWithClock(trainingEvent.date)}</Col>
                        </Row>
                    }
                </div>
                {([TrainingEventTypes.ACTIVITE_LIBRE, TrainingEventTypes.ACTIVITE_REALISEE].indexOf(trainingEvent.type) === -1 || trainingEvent.debriefing_is_complete) ?
                    !trainingEventNameIsEdit &&
                        <div className={`justify-content-center align-items-center d-flex ${isMobile ? "mcr-2" : "col-md-1 col-1 mcr-2"} px-0`}>
                            <div className={"grey-on-hover pointer pcy-1 pcx-3 radius-18 "+ borderColor}
                                 onClick={() => setTrainingEventNameIsEdit(true)}
                            >
                                <img
                                    className="icon-24"
                                    src={editIcon}
                                    alt="edit icon"
                                />
                            </div>
                        </div>
                    :
                    <></>
                }
                {typeof window.ReactNativeWebView !== 'undefined'
                && activeKey && activeKey.indexOf("activity_session") !== -1
                && ((showActivitySessionTab || showActivitySession2Tab || showActivitySession3Tab) && trainingEvent.debriefing_is_complete)
                && (
                    /* This way, the share icon is displayed only if we are in native map 
                    and the show activity session tab is selected
                    and the debrief is complete (and so, the map is displayed) */
                    <div className="justify-content-center align-items-center d-flex mcr-3 p-2">
                        {!showButtonLoader ? (
                            <img
                                className="icon-24 pointer"
                                src={shareIcon}
                                alt="close right circle icon"
                                onClick={() => dispatch(setShowButtonLoader(true))}
                            />
                        ) : (  
                            <ClipLoader
                                color="#007DFF"
                                loading
                                size={24}
                            />
                        )}
                    </div>
                )}
            </Row>
        )
    }

// Affichage de l'onglet Séance ?
    const showWorkoutTab = trainingEvent.workout_id || null
// Affichage de l'onglet modifier Compte-rendu ?
    const showDebriefingTab = trainingEvent.debriefing_id || null
// Affichage de l'onglet ajouter Compte-rendu ?
    const showAddDebriefingTab =  trainingEvent.type === TrainingEventTypes.SEANCE_PLANIFIEE && moment(trainingEvent.date).isSameOrBefore(moment(), 'day')
// Affichage de l'onglet Statistiques ?
    const showActivitySessionTab = trainingEvent.activity_session_id || null
    const showActivitySession2Tab = trainingEvent.activity_session_2_id || null
    const showActivitySession3Tab = trainingEvent.activity_session_3_id || null

    const getStatisticsTitle = (trainingEvent, number) => {
        let activitySessionDisciplineIcon = null
        if(number === 1) {
            let {disciplineIcon} = getDisciplineIconParams(false, trainingEvent.activity_session_discipline)
            activitySessionDisciplineIcon = disciplineIcon
        }
        else if(number === 2) {
            let {disciplineIcon} = getDisciplineIconParams(false, trainingEvent.activity_session_2_discipline)
            activitySessionDisciplineIcon = disciplineIcon

        }
        else if (number === 3) {
            let {disciplineIcon} = getDisciplineIconParams(false, trainingEvent.activity_session_3_discipline)
            activitySessionDisciplineIcon = disciplineIcon
        }

        return (
            <>
                {t('app:app.activity_session.activity')}
                {activitySessionDisciplineIcon &&
                <img
                    className="ml-1 icon-24"
                    src={activitySessionDisciplineIcon}
                />
                }
            </>
        )
    }

// Render a modal with Training event content
    return (
        <Container fluid id="seance-card" className={ isMobile ? "col-lg-6 col-12 training-event-card mb-4" : "card-float-dashboard col-lg-6 col-12 training-event-card mb-4 min-vh-90"}>
            {getTrainingEventHeader()}
            <Row className={isMobile ? "mcb-7" : ""}>
                <Tabs
                    transition={false}
                    // defaultActiveKey={activeKey}
                    activeKey={activeKey}
                    onSelect={(k) => updateActiveKey(k)}
                    id="trainingEventTab"
                    unmountOnExit={true}
                    className="ml-2"
                >
                    {showWorkoutTab &&
                    <Tab eventKey="workout" title={t('app:app.hr_statistic.stat.session')}>
                        <div className={(isMobile ? "mobile-content-scrollable" : "")}>
                            <Workout workoutId={trainingEvent.workout_id}
                                     trainingEventType={trainingEvent.type}
                                     garminSynced={trainingEvent.garmin_training_synced}
                                     isSynchronizable={trainingEvent.garmin_training_synchronizable}
                            />
                        </div>
                    </Tab>
                    }
                    {showDebriefingTab &&
                    <Tab eventKey="debriefing" title={t('app:app.comments.debriefing')}>
                        <div className={(isMobile ? "mobile-content-scrollable" : "")}>
                            <EditDebriefing debriefingId={trainingEvent.debriefing_id}
                                            workoutId={trainingEvent.workout_id}
                                            trainingEventType={trainingEvent.type}
                                            handleDebriefingUpdated={() => handleDebriefingUpdated(trainingEvent)}
                                            updateActiveKey={(activeKey) => updateActiveKey(activeKey)}
                            />
                        </div>
                    </Tab>
                    }
                    {showAddDebriefingTab &&
                    <Tab eventKey="add_debriefing" title={t('app:app.comments.debriefing')}>
                        <div className={(isMobile ? "mobile-content-scrollable" : "")}>
                            <AddDebriefing workoutId={trainingEvent.workout_id}/>
                        </div>
                    </Tab>
                    }
                    {showActivitySessionTab &&
                    <Tab eventKey="activity_session"
                         title={showActivitySession2Tab ? getStatisticsTitle(trainingEvent, 1) : t('app:app.activity_session.activity')}>
                        {trainingEvent.debriefing_is_complete ?
                            <div className={(isMobile ? "mobile-content-scrollable" : "")}>
                                <ActivitySession activitySessionId={trainingEvent.activity_session_id}/>
                            </div>
                            :
                            <NotCompleted updateActiveKey={(value) => updateActiveKey(value)}/>
                        }
                    </Tab>
                    }
                    {showActivitySession2Tab &&
                    <Tab eventKey="activity_session_2"
                         title={getStatisticsTitle(trainingEvent, 2)}>
                        {trainingEvent.debriefing_is_complete ?
                            <div className={(isMobile ? "mobile-content-scrollable" : "")}>
                                <ActivitySession activitySessionId={trainingEvent.activity_session_2_id}/>
                            </div>
                            :
                            <NotCompleted updateActiveKey={(value) => updateActiveKey(value)}/>
                        }
                    </Tab>
                    }
                    {showActivitySession3Tab &&
                    <Tab eventKey="activity_session_3"
                         title={getStatisticsTitle(trainingEvent, 3)}>
                        {trainingEvent.debriefing_is_complete ?
                            <div className={(isMobile ? "mobile-content-scrollable" : "")}>
                                <ActivitySession activitySessionId={trainingEvent.activity_session_3_id}/>
                            </div>
                            :
                            <NotCompleted updateActiveKey={(value) => updateActiveKey(value)}/>
                        }
                    </Tab>
                    }
                </Tabs>
            </Row>
        </Container>
    )
}

export default TrainingEventModal;