import React, {useContext, useEffect, useState} from "react";
import {LanguageContext, NavigationContext, PrioritySportContext} from "../../Contexts";
import {photo_default, validate} from "../../img/img";
import {Button} from "react-bootstrap";
import moment from "moment";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import {useTranslation} from "react-i18next";
import Loader from "react-loader-spinner";
import PersonalInformations from "./PersonalInformations";
import AthleteSportsDropdown from "./AthleteSportsDropdown";
import {useIsMounted} from "../Tools/CustomHooks";
import HealthProfileDropdown from "./HealthProfileDropdown";
import WidgetHelp from "../WidgetHelp";
import {convertLocaleToLanguage} from "../../Functions/LocaleManager";

const NewProfile = () => {
    const {isMobile} = useContext(NavigationContext)
    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()
    const {updatePrioritySport, updatePreferedSports} = useContext(PrioritySportContext)
    const {updateLanguage} = useContext(LanguageContext)

    const [loaded, setLoaded] = useState(false)

    const [user, setUser] = useState(null)
    const [hideSaveButton, setHideSaveButton] = useState(true)
    const [userProfile, setUserProfile] = useState(null)
    const [errorMessage, setErrorMessage] = useState()

    const onChangeUserProfile = (field, value) => {
        const newUserProfile = {...userProfile}
        newUserProfile[field] = value
        setUserProfile(newUserProfile)
    }
    // Récuperer les informations de l'utilisateur à l'initialisation
    useEffect( () => {
        getMyProfile()
    }, []);

    // Enregistrer les informations de l'utilisateur dans le buffer
    useEffect(() => {
        if(user) {
            setUserProfile({
                name: user.lastname,
                firstname: user.firstname,
                username: user.email,
                birthdate: moment(user.birth_date).toDate(),
                city: user.city,
                country: user.country,
                language: user.locale,
                languageLabel: user.locale_label,
                phone: user.cell_phone,
                gender: user.gender,
                height: user.height,
                weight: user.weight.value,
                weightUk: user.weight.uk_value,
                weightValue: null,
                sports: user.sports
            })
        }
    }, [user])

    // Récupérer les données du profil utilisateur
    const getMyProfile = async () => {
        await axios.get(
            config+"api/my_profile",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((res) => {
            if(!isMounted.current){
                return 0;
            }
            setUser(res.data)
            setLoaded(true)

        }).catch(error => {
            handleEndpointError(error)
        })
    }


    // Mettre les données du profil utilisateur
    const handleSubmit = async (event) => {
        setLoaded(false)

        event.persist()

        let datas = {}

        if(userProfile) {
            datas.email = userProfile.username
            datas.lastname = userProfile.name
            datas.firstname = userProfile.firstname
            datas.cell_phone = userProfile.phone
            datas.birth_date = moment(userProfile.birthdate).format("YYYY-MM-DD")
            datas.city = userProfile.city
            datas.country = userProfile.country
            datas.locale = userProfile.language
            datas.height = userProfile.height
            datas.gender = userProfile.gender
            datas.weight = userProfile.weightValue
        }

        if (user.sports !== userProfile.sports) {
            let datasSports = []
            let sports = userProfile.sports
            let disciplinesUsed = []
            for (let i = 0; i < sports.length; i++) {
                if (sports[i] && !disciplinesUsed.includes(sports[i].discipline)) {
                    let sport = {"discipline": sports[i].discipline, "level": sports[i].level}
                    datasSports.push(sport)
                    disciplinesUsed.push(sports[i].discipline)
                }
            }
            datas.sports = datasSports
        }


        await axios.put(
            config + "api/my_profile",
            datas,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
        .then(response => {
            // Récupérer les données de l'utilisateur (aprés la mise à jour)
            getMyProfile()
            updateLanguage(convertLocaleToLanguage(datas.locale))

            // Mettre à jour la Préférence Sport
            let disciplineCode = datas.sports && datas.sports.length > 0 ? datas.sports[0].discipline : null
            let pSport = disciplineCode && ["cap", "nat", "velo"].indexOf(disciplineCode) !== -1 ? disciplineCode : null
            updatePrioritySport(pSport)

            if (datas.sports && datas.sports.length > 0 && datas.sports[0].discipline) {
                let userSports = []
                for (let userSport of datas.sports) {
                    userSports.push(userSport.discipline)
                }
                updatePreferedSports(userSports)
            }

        })
        .catch(error => {
            if(error.response.status === 400) {
                let message = error.response.data.errors
                setErrorMessage(message)
            }
            handleEndpointError(error)
        })

    }


    return (
        <>
        {loaded ?
        <div id="form-login" className={isMobile ? "my-auto w-100" : "w-100"}>
                <>
                    <div className="row align-items-center pcx-3">
                        <div className="col-4">
                            <img src={user.avatar ? user.avatar : photo_default} className="d-inline-block align-top settings-picture rounded-circle background-black" alt="user" />
                        </div>
                        <div className="col-8 pcy-3">
                            <p className="text-left text-settings ">{user.firstname}</p>
                            <p className="text-left text-settings mcy-2">{user.lastname ? user.lastname.toUpperCase() : ''}</p>
                            {user.city || user.country_name ?
                                <p className="text-left text-settings">{user.city} {user.city && user.country_name ? ', ': ''}{ user.country_name ? user.country_name.toUpperCase() : ''}</p>
                                :
                                ''
                            }
                        </div>
                    </div>
                    <PersonalInformations   userProfile={userProfile}
                                            onChangeUserProfile={(field,value) => onChangeUserProfile(field,value)}
                                            setHideSaveButton={setHideSaveButton}

                    />
                    <HealthProfileDropdown  userProfile={userProfile}
                                    onChangeUserProfile={(field,value) => onChangeUserProfile(field,value)}
                                    errorMessage={errorMessage}
                    />

                    <AthleteSportsDropdown userProfile={userProfile}
                                   onChangeUserProfile={(field,value) => onChangeUserProfile(field,value)}
                    />
                    <WidgetHelp/>
                    {hideSaveButton ?
                        <div className="background-white py-3">
                            {userProfile.birthdate  && userProfile.city  && userProfile.country  && userProfile.phone  && userProfile.gender  ?
                                <Button id="login-btn" type="submit"
                                        onClick={(event) => handleSubmit(event)}
                                        className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center'
                                >
                                    <img className="mcr-2 icon-24" alt="validate icon" src={validate}/>
                                    <p className="my-auto home-btn-p-text">{t('app:finalize_registration.register')}</p>
                                </Button>
                                :
                                <Button
                                    className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center'
                                    disabled>
                                    <img className="mcr-2 icon-24" alt="validate icon" src={validate}/>
                                    <p className="my-auto home-btn-p-text">{t('app:finalize_registration.register')}</p>
                                </Button>
                            }
                        </div>
                        :
                        <></>
                    }
                </>
        </div>
        :
        <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
            <Loader
                type="TailSpin"
                color="black"
                height={50}
                width={50}
                className="my-5"
            />
        </div>
        }
        </>
    )
}
export default NewProfile
