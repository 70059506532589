import handleLogout from "./HandleLogout";
import NewFloatMenuMobile from "./NewFloatMenuMobile";
import React from "react";

const NewNavbarHeaderMobile = (props) => {
    return (
        <div className="header-container">
            <NewFloatMenuMobile
                handleLogout={() => handleLogout()}
                openCloseSettings={() => props.openCloseSettings()}
                openCloseNotificationMessageList={() => props.openCloseNotificationMessageList()}
                notificationMessageListOpen={props.notificationMessageListOpen}
                chatOpen={props.chatOpen}
                settingsOpen={props.settingsOpen}
                // onClickChat={() => props.onClickChat()}
                openPreferences={() => props.openPreferences()}
                openSubscription={(field) => props.openSubscription(field)}
            />
        </div>
    )
}
export default NewNavbarHeaderMobile