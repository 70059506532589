import handleLogout from "./HandleLogout";
import React from "react";
import NewNavBtns from "./NewNavBtns";

const NewFloatMenuMobile = (props) => {
    const {settingsOpen,chatOpen,notificationMessageListOpen,openPreferences,openSubscription} = props
    return (
        <div className="new-header-mobile fixed-bottom bg-white d-flex justify-content-center align-items-center  overflow-x-scroll">
            <div className={1 ? "" :"capsule-nav-mobile"}>
                <NewNavBtns
                    handleLogout={() => handleLogout()}
                    openCloseSettings={() => props.openCloseSettings()}
                    openCloseNotificationMessageList={() => props.openCloseNotificationMessageList()}
                    // onClickChat={() => props.onClickChat()}
                    settingsOpen={settingsOpen}
                    chatOpen={chatOpen}
                    notificationMessageListOpen={notificationMessageListOpen}
                    openPreferences={() => openPreferences()}
                    openSubscription={(field) => openSubscription(field)}
                />
            </div>
        </div>
    )
}
export default NewFloatMenuMobile