export default function handleEndpointError (error) {
    if(error.response !== undefined) {
        if(error.response.status === 401) {

            if (!document.getElementById('maintenance-alert')){
                var alert = document.createElement('div')
                alert.id = "maintenance-alert"
                alert.innerHTML = "Mode hors connexion"
                alert.classList.add()
            }
            else {
                window.location.href = '/logout'
            }
        }
        else {
            console.warn('handleEndpointError response',error.response)
        }
    }
    else {
        console.warn('handleEndpointError',error)
        let url = window.location.pathname
        let redirectToComeBackSoon = url.indexOf("/public/come_back_soon") === -1 ? true : false
        if(redirectToComeBackSoon) {
            window.location.href = '/public/come_back_soon'
        }
    }
}