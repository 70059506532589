import React, {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {arrow_left, download_blue, download_red, file_plus, x_circle} from "../../../img/img";

import '../../../Css/Competition.css';

import "react-datepicker/dist/react-datepicker.css"
import {DashboardContext, NavigationContext} from "../../../Contexts";
import CustomSelect from "../../Tools/CustomSelect";
import Loader from "react-loader-spinner";
import { useDropzone } from "react-dropzone";
import useExternalActivityUpload from "../../hooks/useExternalActivityUpload";
import {Element as ScrollerElement, scroller} from "react-scroll";
import {animations} from "react-animation";


const NewExternalActivity = ({closeExternalActivityModal}) => {
    const {t} = useTranslation(['app'])

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)
    const {externalActivityAction, updateExternalActivityAction} = useContext(DashboardContext)

    const [fileNameToUpload, setFileNameToUpload] = useState(null)
    const [externalActivityStyle, setExternalActivityStyle] = useState({})
    const [uploadedFileName, sendExternalActivity] = useExternalActivityUpload()
    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length) {
            uploadFile(acceptedFiles[0])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
    })

    const [provider, setProvider] = useState({
        label: "GARMIN",
        value: 1,
        id: 1
    })

    //fake datas, no-file pour simuler pas de fichier, upload pour simuler qu'il y en a un de sauvegardé
    const [uploadStatus, setUploadStatus] = useState("no-file")
    // false ou string du contenu du message d'erreur
    const [errorMessage, setErrorMessage] = useState(false)

    const providers = [
        {
            label: "GARMIN",
            value: 1,
            id: 1
        },
        {
            label: "Polar",
            value: 2,
            id: 2
        },
        {
            label: "ZWIFT",
            value: 3,
            id: 3
        },
        {
            label: "Suunto Movescount",
            value: 4,
            id: 4
        },
        {
            label: "Suunto Cloud",
            value: 5,
            id: 5
        },
    ]

    useEffect(() => {
        switch(externalActivityAction) {
            case 'show_external_activity':
                // Scroll up
                scroller.scrollTo('externalActivityElement', {
                    duration: 500,
                    delay: 200,
                    smooth: 'easeOutQuart',
                    offset: -200
                })
                // Bounce
                setExternalActivityStyle({animation: `${animations.bounceIn}`, animationDelay: "0s"})
                const timer = setTimeout(() => updateExternalActivityAction(null), 2500);
                return () => clearTimeout(timer);

                break;
            default:
                setExternalActivityStyle({})
                break;
        }
    }, [externalActivityAction])

    const uploadFile = (file) => {
        setFileNameToUpload(file.name)
        setUploadStatus("in-progress")
        sendExternalActivity(file, provider)
            .then(() => {
                setUploadStatus("upload")
            })
            .catch((e) => {
                setErrorMessage(e)
                setUploadStatus("no-file")
            })
    }

    const addNewFile = () => {
        setErrorMessage(false)
        setUploadStatus("no-file")
        setFileNameToUpload(null)
    }

    return (
        <div id="competition-card" className={!isMobile ? "col-xl-4 p-3 mb-4 card-float-dashboard" : undefined}>
            <ScrollerElement name="externalActivityElement"
                             className="w-100 text-left"
            >
            <div style={externalActivityStyle} id="alert-card-header" className="dashboard-card background-white">
                <div className={"d-flex align-items-center pc-3 "+ (isMobile ? 'justify-content-start': 'justify-content-between')}>
                    {isMobile &&
                    <img
                        className="icon-24 mcr-2 pointer"
                        src={arrow_left}
                        alt="arrow left icon"
                        onClick={() => closeExternalActivityModal()}
                    />
                    }
                    <div className="text-left">
                        <p className="external-activity-title mb-0">
                            {t('athlete.external_activity.new')}
                        </p>
                    </div>
                    {!isMobile &&
                    <img
                        className="icon-24 pointer right"
                        src={x_circle}
                        alt="close icon"
                        onClick={() => closeExternalActivityModal()}
                    />
                    }
                </div>
                <div className="mcb-7">
                    <div className="popup-card pcx-4 pcy-3">
                        <div className="activity-CR mcb-4">
                            <div className="row">
                                <div className="col-12 mct-2">
                                    <p className="external-activity-title text-left">{t('athlete.external_activity.data_provider')}</p>
                                </div>
                                <div className="col-12">
                                    {uploadStatus === "upload" ?
                                        <div className="activity-save-display">
                                            {provider.label}
                                        </div>
                                        :
                                        <CustomSelect className="w-100 text-left external-activity-select"
                                                      name="data_provider"
                                                      options={providers}
                                                      value={provider}
                                                      onChange={(value) => setProvider(value)}
                                        />
                                    }
                                </div>
                                <div className="col-12">
                                    {uploadStatus === "upload" ?
                                        <>
                                            <div className="activity-save-display mct-4">
                                                {uploadedFileName}
                                            </div>
                                            <div className="activity-save-info mct-4">
                                                {t('athlete.external_activity.activity_save')}
                                            </div>
                                            <div className="activity-save-new mct-4" onClick={addNewFile}>
                                                {t('athlete.external_activity.new_activity')}
                                            </div>
                                        </>
                                        :
                                        errorMessage === false ?
                                            <div
                                                className={`mct-3 upload-btn pcy-3 ${uploadStatus === "no-file" ? "" : "upload-btn-file"}`}
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <img className="icon-24" alt="valid icon" src={download_blue}/>
                                                {uploadStatus === "no-file" ?
                                                    <>
                                                        <p className="upload-btn-text mct-3">{t('athlete.external_activity.select_file')}</p>
                                                        <p className="upload-btn-info mb-0">
                                                            <span>{t('athlete.external_activity.formats.1')}</span><br/>
                                                            <span>{t('athlete.external_activity.formats.2')}</span>
                                                        </p>
                                                    </>
                                                    :
                                                    <>
                                                        <p className="upload-btn-text color-nat mct-3">{fileNameToUpload}</p>
                                                        <Loader
                                                            type="ThreeDots"
                                                            color="#0069d9"
                                                            height={50}
                                                            width={50}
                                                            className="my-2"
                                                        />
                                                        <p className="upload-btn-info mct-2 mb-0">
                                                            {t('athlete.external_activity.cancel')}
                                                        </p>
                                                    </>
                                                }
                                            </div>
                                            :
                                            <>
                                                <div className={`mct-3 upload-btn upload-btn-error pcy-3 ${uploadStatus === "no-file" ? "" : "upload-btn-file"}`}>
                                                    <img className="icon-24" alt="download icon" src={download_red}/>
                                                    <>
                                                        <p className="upload-btn-text error-color mct-3">{uploadedFileName}</p>
                                                        <p className="upload-btn-info mb-0">
                                                            <span>{t('athlete.external_activity.formats.1')}</span><br/>
                                                            <span>{t('athlete.external_activity.formats.2')}</span>
                                                        </p>
                                                    </>
                                                </div>
                                                <div className="error-message-upload mct-2">
                                                    {errorMessage}
                                                </div>
                                                <div className="activity-save-new mct-4" onClick={addNewFile}>
                                                    {t('athlete.external_activity.new_activity')}
                                                </div>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </ScrollerElement>
        </div>
    )
}
export default NewExternalActivity;