/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useRef, useState } from 'react'
import Leaflet from 'leaflet';
import { Map, Polyline } from 'react-leaflet'
import { Row, Container } from 'react-bootstrap';
import L from "leaflet";
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter'
import 'leaflet/dist/leaflet.css';
import '../../../Css/map.css';
import MapMarker from './MapMarker/MapMarker';
import { useDispatch, useSelector } from 'react-redux';
import { setMapScreenshot, shouldTakeScreenshots as setShouldTakeScreenshots, shouldChangeLayer as setShouldChangeLayer } from '../../../redux/features/share/shareSlice';

Leaflet.Icon.Default.imagePath =
    '../node_modules/leaflet'

delete Leaflet.Icon.Default.prototype._getIconUrl;

Leaflet.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});



const MapGPS = ({ legendInfos, pathing, mapRef }) => {
    const ref = mapRef || useRef(null)
    const {
        shouldChangeLayer,
    } = useSelector(app => app.share)
    const dispatch = useDispatch()

    const [endMarkerCoordinates, setEndMarkerCoordinates] = useState([])
    const [screenShoter, setScreenShoter] = useState(null)
    const [currentMapLayer, setCurrentMapLayer] = useState(null)
    const baseMapLayer = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png'

    useEffect(() => {
        if (shouldChangeLayer && ref && ref.current && ref.current.leafletElement) {
            const map = ref.current.leafletElement
            const newLayerUrl = 'https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png'
            const layer = L.tileLayer(newLayerUrl).addTo(map)
            layer.on('load', () => {
                setTimeout(() => setCurrentMapLayer(newLayerUrl), 500)
                layer.off('load')
            })
        }
    }, [shouldChangeLayer])

    useEffect(() => {
        if (screenShoter && (currentMapLayer !== baseMapLayer)) {
            // Waiting for map to apply new layer, then taking screenshot
            screenShoter.takeScreen('image')
                .then(screen => {
                    dispatch(setMapScreenshot(screen))
                    dispatch(setShouldTakeScreenshots(true))
                    if (baseMapLayer && currentMapLayer && ref && ref.current && ref.current.leafletElement) {
                        const map = ref.current.leafletElement
                        L.tileLayer(baseMapLayer).addTo(map)
                        setCurrentMapLayer(baseMapLayer)
                    }
                })
                .catch(e => console.log(e))
        }
    }, [screenShoter, currentMapLayer, baseMapLayer])

    useEffect(() => {
        if (pathing && pathing.length) {
            const lastPlot = pathing[pathing.length - 1][Object.keys(pathing[pathing.length - 1])]
            const finishCoords = lastPlot[lastPlot.length - 1]
            setEndMarkerCoordinates([finishCoords.lat, finishCoords.long])
        }
    }, [pathing])

    useEffect(() => {
        if (ref && ref.current && ref.current.leafletElement) {
            const map = ref.current.leafletElement
            const smapScreenShoter = new SimpleMapScreenshoter({
                hidden: true,
            })
            setScreenShoter(smapScreenShoter)
            smapScreenShoter.addTo(map)

            L.tileLayer(baseMapLayer)
                .addTo(map)
            setCurrentMapLayer(baseMapLayer)
        }
    }, [legendInfos, ref])

    // Résolution pour mobile ?
    // const {isMobile} = useContext(NavigationContext)

    const Pathing = () => (
        <>
            {pathing.length > 0 && pathing.map((mapPlot, index) => {
                let color
                for(let zoneName in mapPlot) {
                    const pos =[]
                    if (Object.keys(legendInfos).length >0) {
                        if (legendInfos[zoneName]){
                            color = legendInfos[zoneName].color
                        }
                    }
                    let pathPoints = mapPlot[zoneName]

                    for(var i in pathPoints) {
                        pos.push([pathPoints[i].lat, pathPoints[i].long])

                    }

                    return <Polyline key={index} positions={pos} color={color}/>
                }
                return <></>
            })}
        </>
    )

    const drawMap = () => {
        const pos =[]
        pathing.length>0 && pathing.forEach((mapPlot,index) => {
            for(var zoneName in mapPlot) {
                let pathPoints = mapPlot[zoneName]

                for(var i in pathPoints) {
                    pos.push([pathPoints[i].lat, pathPoints[i].long])
                }
            }
        })

        const latLngs = pos.map(po => {
            return L.latLng(po[0], po[1]);
        });

        const bounds = L.latLngBounds(latLngs);

        let bound;
        if (Object.keys(bounds).length>0){bound = bounds}

        return (
            <Row className="map-card">
                <Map
                    attributionControl={false}
                    scrollWheelZoom={false}
                    bounds={bound}
                    style={{ width: '100%', height: '350px'}}
                    ref={ref}
                >
                    <Pathing />
                    <MapMarker />
                    {endMarkerCoordinates.length > 0 && (
                        <MapMarker
                            isEndMarker
                            defaultCoordinates={endMarkerCoordinates}
                        />
                    )}
                </Map>
            </Row>

        )
    }

    return (
        <Container fluid className="justify-content-center d-flex pc-0">{drawMap()}</Container>
    );

}

export default MapGPS
