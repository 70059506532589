import React, {useEffect, useState} from 'react';
import CustomSelect from "../Components/Tools/CustomSelect"

const TrainingProgramFormat = ({format, formats, onChangeFormat}) => {

    const [value, setValue] = useState(null)

    const onChangeValue = (value) => {
        setValue(value)
        onChangeFormat(value)
    }

    useEffect(() => {
        if(value !== format) {
            setValue(format)
        }
    }, [format])


    return (
        <CustomSelect
            className="w-100 text-left"
            name="training_program_format"
            options={formats}
            value={value}
            onChange={(value) => onChangeValue(value)}
        />
    )
}

export default TrainingProgramFormat;