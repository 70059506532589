import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {MetricContext, NavigationContext, TrainingConceptConfigContext} from "../../Contexts";
import Loader from "react-loader-spinner";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import {getCIPacingLabelWithUnit, TrainingConceptCodes} from "../TrainingConcept/Utils";
import {cap_set, nat_set, velo_set} from "../../img/img";
import {getDataContextsByDiscipline} from "../Context/Utils";

const TrainingConceptDisciplineConfig = ({discipline}) => {
    const {t} = useTranslation(['app'])
    const {metric, runningCriticalIntensityStatistic} = useContext(MetricContext)

    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    const [color, setColor] = useState()

    const {
        runningIndoor, runningOutdoor,
        cyclingIndoor, cyclingOutdoor, cyclingVirtualCycling, cyclingVTT,
        swimmingNoPool, swimmingPool25, swimmingPool33, swimmingPool50,
        updateRunningIndoor, updateRunningOutdoor,
        updateCyclingIndoor, updateCyclingOutdoor, updateCyclingVirtualCycling, updateCyclingVTT,
        updateSwimmingNoPool, updateSwimmingPool25, updateSwimmingPool33, updateSwimmingPool50
    } = useContext(TrainingConceptConfigContext)

    const [trainingConceptConfigs, setTrainingConceptConfigs] = useState(null)
    const [loaded, setLoaded] = useState(false)

    let criticalIntensityUnit = getCIPacingLabelWithUnit(discipline, t, metric, runningCriticalIntensityStatistic)
    let criticalHeartrateUnit = 'bpm'

    useEffect(() => {
        let indice = 0
        let color = 'blue'
        switch (discipline) {
            case 'nat':
                indice = 0
                color = 'blue'
                break
            case 'velo':
                indice = 1
                color = 'green'
                break
            case 'cap':
                indice = 1
                color = 'orange'
                break
            default:
                break
        }
        setColor(color)
        console.log(color)
    },[discipline])


    useEffect(() => {
        if (discipline === 'cap') {
            updateContexts(discipline)
            setLoaded(true)
        }
    }, [discipline, runningIndoor, runningOutdoor])

    useEffect(() => {
        if (discipline === 'velo') {
            updateContexts(discipline)
            setLoaded(true)
        }
    }, [discipline, cyclingIndoor, cyclingOutdoor, cyclingVirtualCycling, cyclingVTT])

    useEffect(() => {
        if(discipline === 'nat') {
            updateContexts(discipline)
            setLoaded(true)
        }
    }, [discipline, swimmingNoPool, swimmingPool25, swimmingPool33, swimmingPool50])

    const updateContexts = (discipline) => {
        switch (discipline) {
            default:
            case "cap":
                setTrainingConceptConfigs([
                    {
                        context: 'running-indoor',
                        trainingConcept: runningIndoor,
                        updateTrainingConcept: (value) => updateRunningIndoor(value),
                    },
                    {
                        context: 'running-outdoor',
                        trainingConcept: runningOutdoor,
                        updateTrainingConcept: (value) => updateRunningOutdoor(value),
                    }
                ])
                break
            case "velo":
                setTrainingConceptConfigs([
                    {
                        context: 'cycling-indoor',
                        trainingConcept: cyclingIndoor,
                        updateTrainingConcept: (value) => updateCyclingIndoor(value),
                    },
                    {
                        context: 'cycling-outdoor',
                        trainingConcept: cyclingOutdoor,
                        updateTrainingConcept: (value) => updateCyclingOutdoor(value),
                    },
                    {
                        context: 'virtual-cycling',
                        trainingConcept: cyclingVirtualCycling,
                        updateTrainingConcept: (value) => updateCyclingVirtualCycling(value),
                    },
                    {
                        context: 'vtt',
                        trainingConcept: cyclingVTT,
                        updateTrainingConcept: (value) => updateCyclingVTT(value),
                    }
                ])
                break
            case "nat":
                setTrainingConceptConfigs([
                    {
                        context: 'swimming-no-pool',
                        trainingConcept: swimmingNoPool,
                        updateTrainingConcept: (value) => updateSwimmingNoPool(value),
                    },
                    {
                        context: 'swimming-pool-25',
                        trainingConcept: swimmingPool25,
                        updateTrainingConcept: (value) => updateSwimmingPool25(value),
                    },
                    {
                        context: 'swimming-pool-33',
                        trainingConcept: swimmingPool33,
                        updateTrainingConcept: (value) => updateSwimmingPool33(value),
                    },
                    {
                        context: 'swimming-pool-50',
                        trainingConcept: swimmingPool50,
                        updateTrainingConcept: (value) => updateSwimmingPool50(value),
                    }
                ])
                break
        }
    }

    const onChangeTrainingConcept = (trainingConceptConfig, trainingConceptCode) => {
        axios.put(
            config+"api/training_concept_configs/"+trainingConceptConfig.trainingConcept.id,
            {
                training_concept_code: trainingConceptCode
            },
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        ).then(response => {
            // Mettre à jour le réglage dans le context de l'app
            trainingConceptConfig.updateTrainingConcept({
                id : trainingConceptConfig.trainingConcept.id,
                training_concept_code: trainingConceptCode
            })
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    return (
        <>
            {loaded ?
                <>
                    {trainingConceptConfigs.length > 0 && trainingConceptConfigs.map((trainingConceptConfig) => {
                        
                        let context = trainingConceptConfig.context
                        return (
                        <>
                            <div className="context-card mcb-3">
                                <p className="context-title">{t('app:app.context.' + context)}</p>
                                <div className="d-flex">
                                    <div className={"w-100 mcb-2 checkbox-container-"+color}>
                                        <input
                                            id={"critical_intensity_radio_"+context}
                                            name={"critical_intensity_radio_"+context}
                                            type="radio"
                                            value="1"
                                            checked={trainingConceptConfig.trainingConcept && trainingConceptConfig.trainingConcept.training_concept_code === TrainingConceptCodes.CRITICAL_INTENSITY}
                                            onClick={() => onChangeTrainingConcept(trainingConceptConfig, TrainingConceptCodes.CRITICAL_INTENSITY)}
                                        />
                                        <label htmlFor={"critical_intensity_radio_"+context}
                                               className={isMobile ? "text-subscription-program align-items-center" : "align-items-center text-subscription-program-desktop"}
                                        >
                                            {t('app.training_concept.critical_intensity')}<br/>
                                            {criticalIntensityUnit}
                                        </label>
                                    </div>

                                    {discipline !== 'nat' &&
                                    <div className={"w-100 mcb-2 mcl-3 checkbox-container-"+color}>
                                        <input
                                            id={"heartrate_radio_"+context}
                                            name={"heartrate_radio_"+context}
                                            type="radio"
                                            value="0"
                                            checked={trainingConceptConfig.trainingConcept && trainingConceptConfig.trainingConcept.training_concept_code === TrainingConceptCodes.CRITICAL_HEARTRATE}
                                            onClick={() => onChangeTrainingConcept(trainingConceptConfig, TrainingConceptCodes.CRITICAL_HEARTRATE)}
                                        />
                                        <label htmlFor={"heartrate_radio_"+context}
                                               className={isMobile ? "text-subscription-program align-items-center" : "align-items-center text-subscription-program-desktop"}
                                        >
                                            {t('app.training_concept.critical_heartrate')}<br/>
                                            {criticalHeartrateUnit}
                                        </label>
                                    </div>
                                    }
                                </div>
                            </div>

                        </>
                    )})}
                </>
                :
                <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                    <Loader
                        type="TailSpin"
                        color="black"
                        height={50}
                        width={50}
                        className="my-5"
                    />
                </div>
            }

        </>
    );
};

export default TrainingConceptDisciplineConfig;
