import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactTooltip from 'react-tooltip';
import Loader from "react-loader-spinner";
import InputSwitch from "../Tools/InputSwitch";
import {TrainingConceptConfigContext} from "../../Contexts";
import {ContextCodes, getTrainingConceptByContextCode} from "../Context/Utils";
import PacingTabHeader from "./PacingTabHeader";
import PacingTabBody from "./PacingTabBody";
import {TrainingConceptCodes} from "../TrainingConcept/Utils"
import PacingGraph from "./PacingGraph";
import {helpCircle} from "../../img/img";
import AddManually from "./AddManually";

const TrainingZonesPacingTab = ({dataPacingValues, discipline, context, pathType, onChangePathType, triggerLoadData}) => {

    const {t} = useTranslation(['app'])

    // const { swimmingNoPool, swimmingPool25, swimmingPool33, swimmingPool50,
    //     runningIndoor, runningOutdoor,
    //     cyclingIndoor, cyclingOutdoor, cyclingVirtualCycling, cyclingVTT
    // } = useContext(TrainingConceptConfigContext)

    const trainingContextConfigValue = useContext(TrainingConceptConfigContext)

    const [trainingConceptCode, setTrainingConceptCode] = useState(null)
    const [contextCode, setContextCode] = useState(null)
    const [selectOpt, setSelectOpt] = useState(false)

    const [nbPacingColumn, setNbPacingColumn] = useState(null)
    const [currentDataPacingValues, setCurrentDataPacingValues] = useState(null)

    const [displayGraph, setDisplayGraph] = useState(false)
    const [displayManually, setDisplayManually] = useState(false)

    const [isOpen, setIsOpen] = useState(false)
    const [messageNoIntensity, setMessageNoIntensity] = useState(null)

    // Au changement de condition : Enregistrer le code de la condition
    useEffect(() => {
        if(context) {
            setContextCode(context.code)
        }
    },[context])

    // Au changement de
    // - code de condition
    // - réglage de concept d'entraînement
    useEffect(() => {
        if(contextCode && trainingContextConfigValue) {
            let trainingConcept = getTrainingConceptByContextCode(contextCode, trainingContextConfigValue)
            let nb = 1
            switch(contextCode) {
                case ContextCodes.RUNNING_OUTDOOR:
                case ContextCodes.CYCLING_OUTDOOR:
                case ContextCodes.VIRTUAL_CYCLING:
                case ContextCodes.VTT:
                    nb = 2
                    break
                default:
                    break
            }

            setNbPacingColumn(nb)
            setTrainingConceptCode(trainingConcept ? trainingConcept.training_concept_code : TrainingConceptCodes.CRITICAL_INTENSITY)
        }
    }, [
        contextCode,
        trainingContextConfigValue
    ])

    //
    useEffect(() => {
        if(trainingConceptCode && contextCode && dataPacingValues) {
            setCurrentDataPacingValues(dataPacingValues[trainingConceptCode][contextCode])
        }
    }, [trainingConceptCode, contextCode, dataPacingValues])


    // Vérifier si les données sont valides

    useEffect(() => {
        let message = null
        if(currentDataPacingValues && currentDataPacingValues.Z1[0] === "") {
            message = t('app.training_zones.no_intensity.message')+ "."
                +" " +t('app.training_zones.no_intensity.'+discipline)+ " " + t('app.context.'+contextCode) +"."
        }
        setMessageNoIntensity(message)
    }, [currentDataPacingValues])


    // Gérer le réglage de la distribution des intensités
    const onSwitchChange = () => {
        if (selectOpt) {
            setSelectOpt(false)
            onChangePathType('primary_zones')
        } else {
            setSelectOpt(true)
            onChangePathType('secondary_zones')
        }
    }

    const onChangeDisplayManually = () => {
        setDisplayManually(!displayManually)
    }

    if(!contextCode) {
        return (
            <div className="row justify-content-center align-items-center d-flex mb-5 py-5">
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={80}
                    width={80}
                    className="my-5"
                />
            </div>
        )
    }

    const getDataTip = (discipline) => {
        return (
            <div className="text-left">
                <p>{t('app.training_zones.info.'+discipline)}</p>
                <p>{t('app.training_zones.info.widget_info_1')}</p>
                <p>{t('app.training_zones.info.widget_info_2')}</p>
                {/*<p>{t('app.training_zones.info.for_what')}</p>
                <ul className="pcl-3 mt-2">
                    <li>{t('app.training_zones.info.compute')}</li>
                    <li>{t('app.training_zones.info.improve')}</li>
                    {discipline === 'velo' &&
                    <li>{t('app.training_zones.info.wbal')}</li>
                    }
                </ul>*/}
            </div>
        )
    }

    return (
        <div className={"zs-tab-card pcy-3 mcx-2 "+ (!isOpen ? "bg-transparent":"")}>
            <div className="monitoring-title pcx-2"
                 data-tip={""}
                 data-event='click'
                 data-for="trainingZoneHelper"
            >
                {t('app.training_zones.title')}
                <img src={helpCircle}
                     className="ml-3 icon-24"
                     alt="help icon"
                />
            </div>
            <ReactTooltip key="tooltip-training-zone"
                          id="trainingZoneHelper"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip"
                          clickable={true}
                          effect="solid"
                          place="bottom"
                          getContent={() => getDataTip(discipline)}
            />
            <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                  onClick={() => setIsOpen(isOpen => !isOpen)}
            >
                <i className={isOpen ? "bi bi-chevron-up": "bi bi-chevron-down"}></i>
            </span>
            {isOpen ?
                displayManually ?
                    <AddManually
                        discipline={discipline}
                        contextCode={contextCode}
                        onChangeDisplayManually={() => onChangeDisplayManually()}
                        triggerLoadData={() => triggerLoadData()}
                    />
                    :
                    (displayGraph ?
                            <>
                                <PacingGraph trainingConceptCode={trainingConceptCode}
                                             discipline={discipline}
                                             currentDataPacingValues={currentDataPacingValues}
                                />
                                <div className="fs-10 text-left mt-3 training-zone-more-info pr-2">
                                    <p className="fs-12 mb-1" style={{color: "#00D254"}}>
                                        {t('app.training_zones.description.Z1.title')}
                                    </p>
                                    <div>
                                        <p>{t('app.training_zones.description.Z1.0')}</p>
                                        <p>{t('app.training_zones.description.Z1.1')}</p>
                                        <p>{t('app.training_zones.description.Z1.2')}</p>
                                        <p>{t('app.training_zones.description.Z1.3')}</p>
                                    </div>
                                    <p className="fs-12 mb-1" style={{color: "#FFA800"}}>
                                        {t('app.training_zones.description.Z2.title')}
                                    </p>
                                    <div>
                                        <p>{t('app.training_zones.description.Z2.0')}</p>
                                        <p>{t('app.training_zones.description.Z2.1')}</p>
                                        <p>{t('app.training_zones.description.Z2.2')}</p>
                                    </div>
                                    <p className="fs-12 mb-1" style={{color: "#F15700"}}>
                                        {t('app.training_zones.description.Z3.title')}
                                    </p>
                                    <div>
                                        <p>{t('app.training_zones.description.Z3.0')}</p>
                                        <p>{t('app.training_zones.description.Z3.1')}</p>
                                        <p>{t('app.training_zones.description.Z3.2')}</p>
                                    </div>
                                </div>
                                <span className="pointer more-information" onClick={() => setDisplayGraph(false)}>
                            {t('app:athlete.training_load.back_tab')}
                    </span>
                            </>
                            :
                            (messageNoIntensity !== null ?
                                    <>
                                        <div className="message-no-intensity pc-3 text-left">
                                            {t('app.training_zones.no_zones.0')}<br/>
                                            {t('app.training_zones.no_zones.1')}
                                            <b>{t('app.training_zones.no_zones.2_'+discipline)}</b>
                                            <p>
                                                {t('app.training_zones.no_zones.3')}
                                                <b>{t('app.training_zones.no_zones.4')}</b>
                                                {t('app.training_zones.no_zones.5')}
                                                <b>{t('app.training_zones.no_zones.6')}</b>
                                                {t('app.training_zones.no_zones.7')}
                                            </p>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="btn-white-blue" onClick={() => onChangeDisplayManually()}>
                                                {t('app.training_zones.add_manually')}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="w-100 d-flex justify-content-center mcb-2 pct-3">
                                            <InputSwitch
                                                addClassName="pb-3"
                                                labelLeft="Zone I"
                                                selectOpt={selectOpt}
                                                labelRight="Zone II"
                                                onSwitchChange={onSwitchChange}
                                            />
                                        </div>
                                        {contextCode && trainingConceptCode && nbPacingColumn &&
                                        <>
                                            <PacingTabHeader
                                                trainingConceptCode={trainingConceptCode}
                                                discipline={discipline}
                                                nbPacingColumn={nbPacingColumn}
                                            />
                                            <PacingTabBody
                                                currentDataPacingValues={currentDataPacingValues}
                                                discipline={discipline}
                                                contextCode={contextCode}
                                                pathType={pathType}
                                                nbPacingColumn={nbPacingColumn}
                                            />
                                            <div className="d-flex justify-content-center mcy-3">
                                                <div className="btn-white-blue" onClick={() => onChangeDisplayManually()}>
                                                    {t('app.actions.edit')}
                                                </div>
                                            </div>

                                            <span className="pointer more-information" onClick={() => {
                                                setDisplayGraph(true)
                                            }}>
                                                {t('app:athlete.training_load.more_information')}
                                            </span>
                                        </>
                                        }
                                    </>
                            )
                    )
                :
                <></>
            }
        </div>
    )
}

export default TrainingZonesPacingTab;