import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import Loader from 'react-loader-spinner'
import handleEndpointError from "../Functions/Alert";
import axios from "axios/index";
import config from "../api";
import generateHeaders from "../Functions/generateHeaders";

import ReactPixel from 'react-facebook-pixel';

const Purchase = ({purchaseId, purchaseAmountData}) => {

    let history = useHistory()

    const [status, setStatus] = useState(null)
    // const [errorMessage, setErrorMessage] = useState(null)
    const [action, setAction] = useState(null)

    useEffect(() => {
        if(purchaseId) {
            // Délencher la livraison
            deliverPurchase()

        }
    }, [purchaseId])

    useEffect(() => {
        switch(action) {
            case 'homepage':
                // Rediriger vers la page de confirmation de l'achat
                // redirectToConfirmPage()
                setStatus('done')
                break
            case 'check_payment':
                setStatus('to_check')
                // checkPaymentPurchase()
                break
            default:
                break
        }

    }, [action])

    useEffect(() => {
        switch(status) {
            case 'processing':
                setStatus('to_check')
                break
            case 'done':
                // Facebook
                if((window.location.host).indexOf("web.gutai.training") !== -1) {
                    let fbPixel = process.env.REACT_APP_FACEBOOK_PIXEL_KEY
                    ReactPixel.init(fbPixel, {}, {
                        autoConfig: true,
                        debug: true
                    });
                    ReactPixel.fbq('track','Purchase', {value : purchaseAmountData.amount_without_tax, currency: purchaseAmountData.currency})
                }

                // Affilae
                var aeEvent = {};
                aeEvent.key = process.env.REACT_APP_AFFILAE_TAG_KEY;
                aeEvent.Conversion = {};
                aeEvent.Conversion.id = purchaseId;
                aeEvent.Conversion.amount = purchaseAmountData.amount_without_tax;
                aeEvent.Conversion.payment = 'online';
                aeEvent.Conversion.voucher = purchaseAmountData.coupon;

                console.log('aeEvent', aeEvent);

                ('AeTracker' in window)
                    ? window.AeTracker.sendConversion(aeEvent)
                    : (window.AE = window.AE || []).push(aeEvent);


                // Rediriger vers la page de confirmation du paiement
                redirectToConfirmPage()
                break
            case 'cancelled':
                // TODO : Afficher un message
                break
            case 'payment_intent_need_authentication':
                // TODO : Rediriger vers la page de confirmation

                break
            case 'to_check':
                const timer = setTimeout(() => checkPaymentPurchase(), 1000);
                return () => clearTimeout(timer);
            default:
                break
        }
    }, [status])


    const deliverPurchase = async () => {

        await axios.post(
            config+"api/purchase/"+ purchaseId +"/deliver",
            {},
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
            .then(response => {
                setAction(response.data.redirect_action)
            })
            .catch(error => {
                console.log('deliverPurchase NOK', error.response)
                // TO DO: affichage du message d'erreur
                handleEndpointError(error)
            })

    }

    const checkPaymentPurchase = async () => {

        await axios.get(
            config+"api/purchase/"+ purchaseId +"/check_payment",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
            .then(response => {
                setStatus(response.data.status)
            })
            .catch(error => {
                handleEndpointError(error)
            })

    }

    const redirectToConfirmPage = () => {
        history.push('/purchase/'+purchaseId+'/confirmation')
    }

    return (
        <>
            <Loader
                type="TailSpin"
                color='#007DFF'
                height={80}
                width={80}
                className="pct-3 m-auto"
            />
        </>
    )
}
export default Purchase