import moment from "moment";
import 'moment/min/locales'


export function getVerboseDate(date){
    return moment(date).format('LL')
}
export function getVerboseDateWithClock(date){
    return moment(date).format('LL HH:mm')
}

export function getMonth(activeDate, t){
    let monthNum = moment(activeDate, 'DD/MM/YYYY').month()
    let month
    if (monthNum === 0) {
        month = t('app.date.january')
    }
    if (monthNum === 1) {
        month = t('app.date.february')
    }
    if (monthNum === 2) {
        month = t('app.date.march')
    }
    if (monthNum === 3) {
        month = t('app.date.april')
    }
    if (monthNum === 4) {
        month = t('app.date.may')
    }
    if (monthNum === 5) {
        month = t('app.date.june')
    }
    if (monthNum === 6) {
        month = t('app.date.july')
    }
    if (monthNum === 7) {
        month = t('app.date.august')
    }
    if (monthNum === 8) {
        month = t('app.date.september')
    }
    if (monthNum === 9) {
        month = t('app.date.october')
    }
    if (monthNum === 10) {
        month = t('app.date.november')
    }
    if (monthNum === 11) {
        month = t('app.date.december')
    }
    return month
}

export function getPrevWeek (currWeek) {
    let week = parseInt(currWeek, 10)
    return moment().week(week-1).format('WW')
}

export function getNextWeek (currWeek) {
    let week = parseInt(currWeek, 10)
    return moment().week(week+1).format('WW')
}

export function getDomNum (dow, weekPos) {
    if (dow === 0) {
        return moment().day(dow + 7 - (weekPos * 7)).date()
    } else {
        return moment().day(dow - (weekPos * 7)).date()
    }
}

export function getDomDate (dow, weekPos){
    if (dow === 0) {
        return moment().day(dow+7-(weekPos*7)).format("DD/MM/YYYY")
    } else {
        return moment().day(dow-(weekPos*7)).format("DD/MM/YYYY")
    }
}

export function getDomDateYMD (dow, weekPos){
    if (dow === 0) {
        return moment().day(dow+7-(weekPos*7)).format("YYYY-MM-DD")
    } else {
        return moment().day(dow-(weekPos*7)).format("YYYY-MM-DD")
    }
}