import React, {useEffect, useState} from 'react';
import {alert_triangle, arrow_down_right, arrow_right, arrow_up_right, trending_down, trending_up} from "../../img/img";
import {useTranslation} from "react-i18next";

const TrainingLoadBar = ({levelsString, level, loading, more}) => {
    const {t} = useTranslation(['app'])
    const [caseStyles, setCaseStyles] = useState({
        tooHigh: "",
        intense: "",
        high: "",
        ideal: "",
        low: "",
        tooLow: "",
    })

    const barCaseStyle = more ? "bar-case-more" : "bar-case"
    useEffect(() => {
        // Ne rien modifier lors du chargement
        if(!loading) {
            let newCaseStyles = {...caseStyles}
            let levelPosition = levelsString.indexOf(level)

            if(levelPosition > -1) {
                newCaseStyles.tooLow = "background-too-low"
            }
            if(levelPosition > 0) {
                newCaseStyles.low = "background-low"
            }
            if(levelPosition > 1) {
                newCaseStyles.ideal = "background-ideal"
            }
            if(levelPosition > 2) {
                newCaseStyles.high = "background-high"
            }
            if(levelPosition > 3) {
                newCaseStyles.intense = "background-intense"
            }
            if(levelPosition > 4) {
                newCaseStyles.tooHigh = "background-too-high"
            }

            setCaseStyles(newCaseStyles)
        }
    }, [loading])

    return (
        <div className="d-flex flex-column justify-content-start">
            <div className={barCaseStyle + " case-too-high " + caseStyles.tooHigh}>
                {t('app:athlete.training_load.too_high')}
            </div>

            <div className={barCaseStyle + " case-intense " + caseStyles.intense}>
                {t('app:athlete.training_load.intense')}
            </div>

            <div className={barCaseStyle + " case-high " + caseStyles.high}>
                {t('app:athlete.training_load.high')}
            </div>

            <div className={barCaseStyle + " case-ideal " + caseStyles.ideal}>
                {t('app:athlete.training_load.ideal')}
            </div>

            <div className={barCaseStyle + " case-low " + caseStyles.low}>
                {t('app:athlete.training_load.low')}
            </div>

            <div className={barCaseStyle + " case-too-low " + caseStyles.tooLow}>
                {t('app:athlete.training_load.too_low')}
            </div>
        </div>
    );
};

export default TrainingLoadBar;
