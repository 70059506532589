import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import moment from "moment";
import {
    chevron_right,
    distance,
    more,
    watch,
    logo_garmin_training_transparent,
    x_circle
} from "../../../img/img";
import {iconDiscipline, iconNoDebrief} from "../Utils";
import {round_dec, TextAbstract, time_convert} from "../../../Functions/TextMaths";
import {Metrics, MetricValues} from "../../../Functions/Metrics";
import {MetricContext} from "../../../Contexts";
import {TrainingEventTypes, getTrainingEventClassNames} from "./Utils";
import TrainingEventPanel from "./TrainingEventPanel";

/**
 * Afficher un évènement entraînement dans le calendrier
 *
 * trainingEvent : événément du calendrier
 * activeDate: date du calendrier
 * changeActiveDate : handler pour modifier la date du calendrier (WeekCalendar)
 */
const TrainingEvent = ({trainingEvent, activeDate, changeActiveDate, handleTrainingEvent, openTrainingEventModal, colorSport}) => {

    const {t} = useTranslation(['app'])

    // Indique si le panel est ouvert
    const [isPanelOpen, setIsPanelOpen] = useState(false)

    const trainingEventDate = moment(trainingEvent.date)
    const columnOpen = activeDate && activeDate.format("YYYY-MM-DD") === trainingEventDate.format("YYYY-MM-DD")

    const {color, bcolor, opacolor} = getTrainingEventClassNames(trainingEvent)

    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    let showPanelIcon = [TrainingEventTypes.SEANCE_PLANIFIEE, TrainingEventTypes.SEANCE_REALISEE, TrainingEventTypes.COMPTE_RENDU_LIBRE, TrainingEventTypes.ACTIVITE_LIBRE, TrainingEventTypes.ACTIVITE_REALISEE].indexOf(trainingEvent.type) !== -1

    let durationValue = trainingEvent.duration < 60 ? trainingEvent.duration+"'" : time_convert(trainingEvent.duration)
    let distanceValue = metric === MetricValues.imperial ? trainingEvent.distance_uk : trainingEvent.distance
    distanceValue = round_dec(distanceValue)
    let distanceUnit = trainingEvent.disciplines[0] === 'nat' && trainingEvent.disciplines.length === 1 ? metrics.distance_nat: metrics.distance_cap_velo

    return (
        <div key={trainingEvent.key} className="d-flex justify-content-center">
            {
                !columnOpen ?
                    trainingEvent.type === TrainingEventTypes.SEANCE_PLANIFIEE ?
                        <Button variant="outline-light"
                                className={"agenda-cube cube-white d-flex justify-content-center mx-auto px-0 mcb-3"}
                                onClick={() => changeActiveDate(trainingEventDate)}
                        >
                            {iconDiscipline(false, trainingEvent.disciplines)}
                        </Button> :
                        <Button
                            className={"agenda-cube " + bcolor + " d-flex justify-content-center mx-auto px-0 mcb-3"}
                            onClick={() => changeActiveDate(trainingEventDate)}
                        >
                            {!trainingEvent.debriefing_is_complete &&
                            iconNoDebrief(trainingEvent.disciplines)
                            }
                            {iconDiscipline(true, trainingEvent.disciplines)}
                        </Button>
                    :

                    <div className="div-workout-todo mcb-3 d-block"
                    >
                        <div className={trainingEvent.type === TrainingEventTypes.SEANCE_PLANIFIEE ?
                            "pc-2" :
                            "pc-2 " + opacolor}>
                            {showPanelIcon ?
                                (isPanelOpen ?
                                        <>
                                            <TrainingEventPanel trainingEvent={trainingEvent}
                                                                handleTrainingEvent={(trainingEvent) => handleTrainingEvent(trainingEvent)}
                                            />
                                            <img
                                                src={x_circle}
                                                alt="close panel icon"
                                                className="more mcr-2 mct-2 icon-24"
                                                onClick={() => setIsPanelOpen(false)}
                                            />
                                        </>:
                                        <img
                                            src={more}
                                            alt="more icon"
                                            className="more mcr-2 mct-2 icon-24"
                                            onClick={() => setIsPanelOpen(true)}
                                        />
                                )
                                :<></>
                            }
                            <div className="">
                                <div className="d-flex flex-row align-items-center">
                                    {trainingEvent.type === TrainingEventTypes.SEANCE_PLANIFIEE ?
                                        <div className="agenda-cube cube-white d-flex justify-content-center mcr-2 px-0"  >
                                            {iconDiscipline(false, trainingEvent.disciplines)}
                                        </div>
                                        :
                                        <div className={"agenda-cube "+bcolor+" d-flex justify-content-center mcr-2 px-0"}  >
                                            {!trainingEvent.debriefing_is_complete &&
                                            iconNoDebrief(trainingEvent.disciplines)
                                            }
                                            {iconDiscipline(true, trainingEvent.disciplines)}
                                        </div>
                                    }

                                    <div className={"text-workout-active text-left mb-0 "+color}>
                                        <div className={color+' pointer'}
                                             onClick={() => openTrainingEventModal(trainingEvent)}
                                        >
                                            {TextAbstract(trainingEvent.name, 19)}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center align-items-center pct-3 pcb-2 mcb-1">
                                <p className="text-workout-item mb-0">
                                    <img
                                        src={watch}
                                        alt="watch icon"
                                        className="my-auto mcr-2 icon-24"
                                    />
                                    {durationValue}
                                </p>

                                {trainingEvent.type !== TrainingEventTypes.SEANCE_PLANIFIEE &&
                                <p className="text-workout-item mb-0">
                                    <img
                                        src={distance}
                                        alt="distance icon"
                                        className="my-auto mcr-2 mcl-3 icon-24"
                                    />
                                    {distanceValue} {distanceUnit}
                                </p>
                                }
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="text-workout-link mb-2">
                                    <div className="workout-link d-flex align-items-center pointer "
                                         onClick={() => openTrainingEventModal(trainingEvent)}
                                    >
                                        {t('app:workout.panel.session')}
                                        <img
                                            src={chevron_right}
                                            alt="chevron right icon"
                                            className="icon-24"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {trainingEvent.type === TrainingEventTypes.SEANCE_PLANIFIEE && trainingEvent.garmin_training_synced &&
                            <div className={"d-flex justify-content-center align-items-center " + opacolor}>
                                <div className="garmin-synced my-2">
                                    {t('app:workout.synced_with')}
                                    <img
                                        src={logo_garmin_training_transparent}
                                        alt="logo garmin training"
                                        className="garmin-synced-img"
                                    />
                                </div>
                            </div>
                        }
                    </div>
            }
        </div>
    )
}

export default TrainingEvent;


