import React, {useState, useContext} from 'react';

import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import generateHeaders from "../../Functions/generateHeaders";
import {useIsMounted} from "../Tools/CustomHooks";
import {heart_favorites, heart_grey, plus_circle} from "../../img/img";

import WorkoutTemplateNewFavoritesList from "./WorkoutTemplateNewFavoritesList";
import {WorkoutTemplateContext} from "../../Contexts";

const WorkoutTemplateFavoritesList = ({workoutTemplateLists, workoutTemplateId}) => {
    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()

    const {onUpdateFavorite} = useContext(WorkoutTemplateContext)

    const [showNewFavoriteWidget, setShowNewFavoriteWidget] = useState(false)


    const addInFavoritesList = (listId) => {
        axios
            .put(
                config+"api/workout_template_lists/"+listId+"/add_workout_template",
                {
                    "workout_template_id": workoutTemplateId
                },
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                onUpdateFavorite(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const removeFromFavoritesList = (listId) => {
        axios
            .put(
                config+"api/workout_template_lists/"+listId+"/remove_workout_template",
                {
                    "workout_template_id": workoutTemplateId
                },
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                onUpdateFavorite(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const isInFavoriteList = (workoutTemplateList) => {
        return !!workoutTemplateList.workout_templates.find(wt => wt.id === workoutTemplateId);
    }

    if(showNewFavoriteWidget) {
        return (
            <WorkoutTemplateNewFavoritesList
                closeWidget={() => setShowNewFavoriteWidget(false)}
            />
        );
    }

    return (
        <div className="favorites-lists-relative">
            <div className="d-flex flex-column favorites-lists width-min-content pc-3 text-almost-black fs-12 text-nowrap text-gotham-book">
                {workoutTemplateLists.map(list => {
                    let inFavoritesList = isInFavoriteList(list)
                    return (
                        <div className="d-flex justify-content-start mcb-3 lh-24 pointer"
                             onClick={() => inFavoritesList ? removeFromFavoritesList(list.id) : addInFavoritesList(list.id)}
                        >
                            <img
                                className="icon-24 mcr-2"
                                src={inFavoritesList ? heart_favorites : heart_grey}
                                alt="heart icon"
                            />
                            {list.name}
                        </div>
                    )
                })}
                <div className="separator-grey mcb-3"/>
                    <div className="d-flex justify-content-start cursor-pointer lh-24"
                         onClick={() => setShowNewFavoriteWidget(true)}
                    >
                        <img
                            className="icon-24 mcr-2"
                            src={plus_circle}
                            alt="plus circle icon"
                        />
                        {t('app.workout_template.new_list.btn')}
                    </div>
            </div>
        </div>
    );
};

export default WorkoutTemplateFavoritesList;