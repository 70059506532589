import React, {useContext, useEffect, useState} from 'react';
import {calendar_black, heart_favorites, heart_favorites_empty, planned} from "../../img/img";
import {NavigationContext} from "../../Contexts";
import {useTranslation} from "react-i18next";
import {getLevelIcon, tranformDisciplines} from "./Utils";
import WorkoutTemplateDisciplineCube from "./WorkoutTemplateDisciplineCube";
import Loader from "react-loader-spinner";
import DataTable from 'react-data-table-component';
import WorkoutTemplateContent from "./WorkoutTemplateContent";
import {animateScroll} from "react-scroll";


const WorkoutTemplateTable = ({workoutTemplates, loaded, workoutTemplateLists, isFavorite, isPlanned, title, noResultMessage}) => {
    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const {t} = useTranslation(['app'])


    // const [favoritesLists, setFavoritesLists] = useState(null)
    const [workoutTemplateId, setWorkoutTemplateId] = useState(null)


    const closeWorkoutTemplateModal = () => {
        setWorkoutTemplateId(null)
    }

    const openWorkoutTemplateModal = (workoutTemplateId) => {

        setWorkoutTemplateId(workoutTemplateId)
    }

    useEffect(() => {
        if(isMobile && workoutTemplateId) {
            setTimeout(() => {
                animateScroll.scrollToTop()
            }, 200)
        }
    }, [isMobile, workoutTemplateId])



    const columns = [
        {
            name: '',
            width: '15%',
            cell: workoutTemplate => {
                let disciplines = tranformDisciplines(workoutTemplate.disciplines)
                return (
                    <div>
                        <div className="d-flex flex-wrap">
                            {disciplines.map((discipline) =>
                                <WorkoutTemplateDisciplineCube
                                key={"wt-" + workoutTemplate.id + "-" + discipline.code}
                                discipline={discipline.code}
                                />
                                )}
                        </div>
                    </div>
                )
            }
        },
        {
            name: t('app.workout_template.th.session_name'),
            sortable: true,
            width: '28%',
            selector: row => row['name'],
            cell: workoutTemplate =>
                <div>
                    <div className="d-flex  text-left cursor-pointer" 
                         onClick={() => openWorkoutTemplateModal(workoutTemplate.id)}
                    >
                        <div>{workoutTemplate.name}</div>
                        {workoutTemplate.in_top_10 &&
                            <div className="top-10-cube mcl-3">
                                TOP 10
                            </div>
                        }
                    </div>
                </div>,
        },
        {
            name: <>{t('app.workout_template.th.duration')}<br/>{t('app.workout_template.th.distance')}</>,
            sortable: true,
            width: '10%',
            selector: row => row['estimated_duration'],
            cell: workoutTemplate =>
                <div className="text-left">
                    {workoutTemplate.estimated_duration && workoutTemplate.estimated_duration+ " min"}
                    {workoutTemplate.estimated_distance && workoutTemplate.estimated_distance+ " km"}
                </div>,
        },
        {
            name: t('app.workout_template.th.level'),
            sortable: true,
            width: '9%',
            selector: row => row['level'],
            cell: workoutTemplate =>
                <div className="text-left">
                    {workoutTemplate.level &&
                        <img className="icon-24" src={getLevelIcon(workoutTemplate.level)} alt="level icon"/>
                    }
                </div>,
        },
        {
            name: t('app.workout_template.th.author'),
            sortable: true,
            width: '10%',
            selector: row => row['coach'] ? row['coach']['firstname'] + " " +row['coach']['lasttname'] : "GUTAÏ Training",
            cell: workoutTemplate =>
                <div className="text-left">
                    {workoutTemplate.coach ?
                        workoutTemplate.coach.firstname+" "+workoutTemplate.coach.lastname
                        :
                        "GUTAÏ Training"
                    }
                </div>,
        },
        {
            name: <>{t('app.workout_template.th.already')}<br/>{t('app.workout_template.th.planned')}</>,
            sortable: true,
            width: '8%',
            selector: row => isPlanned(row.id),
            cell: workoutTemplate =>
                <div>
                    {isPlanned(workoutTemplate.id) &&
                    <img className="icon-24" src={planned} alt="calendar planned icon"/>
                    }
                </div>,
        },
        {
            name: t('app.workout_template.filters.favorites'),
            sortable: true,
            width: '8%',
            selector: row => isFavorite(row.id),
            cell: workoutTemplate => isFavorite(workoutTemplate.id) ? <img className="icon-24" src={heart_favorites} alt="favourite icon" /> : null
        },
        {
            name: t('app.workout_template.th.options'),
            width: '12%',
            cell: workoutTemplate =>
                <div>
                    <div className="cursor-pointer d-flex text-left align-items-center"
                         onClick={() => openWorkoutTemplateModal(workoutTemplate.id)}
                    >
                        <i className="text-grey-normal bi bi-collection-play fs-24"></i>
                        {/*<img className="icon-24" src={calendar_black} alt="calendar icon"/>*/}
                        <span className="mcl-2 fs-12">{t('app.preview')}</span>
                    </div>
                </div>,
        },
    ];

    const columnsMobile = [
        {
            name: '',
            width: '15%',
            compact: true,
            cell: workoutTemplate => {
                let disciplines = tranformDisciplines(workoutTemplate.disciplines)
                return (
                    <div>
                        <div className="d-flex flex-wrap">
                            {disciplines.map((discipline) =>
                                <WorkoutTemplateDisciplineCube
                                    key={"wt-" + workoutTemplate.id + "-" + discipline.code}
                                    discipline={discipline.code}
                                />
                            )}
                        </div>
                    </div>
                )
            }
        },
        {
            name: <>{t('app.workout_template.th.already')}<br/>{t('app.workout_template.th.planned')}</>,
            width: '10%',
            sortable: true,
            compact: true,
            selector: row => isPlanned(row.id),
            cell: workoutTemplate =>
                <div>
                    {isPlanned(workoutTemplate.id) &&
                    <img className="icon-24" src={planned} alt="calendar planned icon"/>
                    }
                </div>,
        },
        {
            name: t('app.workout_template.th.session_name'),
            width: '50%',
            sortable: true,
            compact: true,
            selector: row => row['name'],
            cell: workoutTemplate =>
                <div>
                    <div className="d-flex  text-left cursor-pointer"
                         onClick={() => openWorkoutTemplateModal(workoutTemplate.id)}
                    >
                        <div>{workoutTemplate.name}</div>
                        {workoutTemplate.in_top_10 &&
                        <div className="top-10-cube mcl-3">
                            TOP 10
                        </div>
                        }
                    </div>
                </div>,
        },
        {
            name: t('app.workout_template.th.level'),
            width: '15%',
            sortable: true,
            compact: true,
            selector: row => row['level'],
            cell: workoutTemplate =>
                <div className="text-left">
                    {workoutTemplate.level &&
                        <img className="icon-24 mcl-2" src={getLevelIcon(workoutTemplate.level)} alt="level icon"/>
                    }
                </div>,
        },
        {
            name: t('app.workout_template.th.options'),
            width: '10%',
            compact: true,
            cell: workoutTemplate =>
                <div>
                    <div className="cursor-pointer d-flex text-left align-items-center"
                         onClick={() => openWorkoutTemplateModal(workoutTemplate.id)}
                    >
                        <i className="text-grey-normal bi bi-collection-play fs-24"></i>
                        {/*<img className="icon-24" src={calendar_black} alt="calendar icon"/>*/}
                        {!isMobile && <span className="mcl-2 fs-12">{t('app.preview')}</span>}
                    </div>
                </div>,
        },
    ];

    const paginationText = {
        rowsPerPageText: t('app.workout_template.pagination.rows_per_page_text'),
        rangeSeparatorText: t('app.workout_template.pagination.range_separator_text'),
        noRowsPerPage: false,
        selectAllRowsItem: true,
        selectAllRowsItemText: t('app.workout_template.pagination.select_all_rows_item_text')
    }

    const customStyles = {
        rows: {
            style: {
                minHeight: '88px', // override the row height
            }
        },
        cells: {
            style: {
                fontFamily: 'Gotham!important'
            }
        }
    }

    return (
        <div className={`w-100 mcb-5 ${isMobile ? "pcx-2" : "pcx-3"}`}>
            {workoutTemplateId &&
                <WorkoutTemplateContent
                    workoutTemplateId={workoutTemplateId}
                    closeWorkoutTemplateModal={closeWorkoutTemplateModal}
                    workoutTemplateLists={workoutTemplateLists}
                    isFavorite={isFavorite(workoutTemplateId)}
                />
            }
            {title &&
            <p className="seance-title2 mcb-3 text-left">
                {title}
            </p>
            }
            {loaded ?
                <DataTable
                    columns={isMobile ? columnsMobile : columns}
                    data={workoutTemplates}
                    noTableHead={!!isMobile}
                    pagination={true}
                    responsive={false}
                    paginationPerPage={25}
                    paginationComponentOptions={paginationText}
                    paginationRowsPerPageOptions={[10, 25, 50, 100, 250]}
                    fixedHeader={true}
                    // highlightOnHover={true}
                    // responsive={true}
                    className={"text-gotham"}
                    customStyles={customStyles}
                    noDataComponent={noResultMessage ? noResultMessage : null}
                />
                :
                <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                    <Loader
                        type="TailSpin"
                        color="black"
                        height={50}
                        width={50}
                        className="my-5"
                    />
                </div>
            }

        </div>
    )
}


export default WorkoutTemplateTable