import React, {useContext} from "react";
import LanguageSwitch from "../LanguageSwitch";
import {Link} from "react-router-dom";
import {gutai_login, gutai_login_desktop, gutai_logo_m} from "../../img/img";

import {NavigationContext} from "../../Contexts"

export function HeaderHome({withoutLanguageSwitch, showLogoM}) {
    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)


    return (
        <div className={(isMobile ? (withoutLanguageSwitch? "mcb-1 header-home-alone":"mcb-4") : "")+" header-home"}>
            {!withoutLanguageSwitch &&
                <LanguageSwitch />
            }
            {isMobile ?
                <div className={"d-flex align-items-center " + (withoutLanguageSwitch ? "justify-content-center" :"justify-content-start position-absolute pc-2 top-left-0")}>
                    <Link to={"/"}>
                        <img
                            src={gutai_login}
                            className="mct-1"
                            alt="Gutai logo"
                        />
                    </Link>

                </div>
                :
                <div className="w-100">
                    <Link to={"/"}>
                        <img
                            src={showLogoM ? gutai_logo_m : gutai_login_desktop}
                            className="logo-gutai-home-desktop"
                            alt="Gutai logo"
                        />
                    </Link>
                </div>
            }
        </div>
    )
}