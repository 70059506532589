import React, { useState, useContext } from 'react';
import {withTranslation} from "react-i18next";
import {Link, Redirect, withRouter} from "react-router-dom";
import axios from "axios";
import config from "../api";
import '../Css/Home.css';
import {
    Form,
    Button
} from "react-bootstrap";
import {gutai_login, tic, gutai_login_desktop, send_icon, login_icon} from "../img/img";

import LanguageSwitch from "../Components/LanguageSwitch";
import handleEndpointError from '../Functions/Alert';
import {LanguageContext, NavigationContext} from "../Contexts";

const ForgotPassword = ({t}) => {

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)
    const {language} = useContext(LanguageContext)


    const [email,setEmail] = useState("")
    const [emailOk,setEmailOk] = useState(false)
    const [send,setSend] = useState(false)
    const [resetErrors,setResetErrors] = useState(1)
    const [errorMessage, setErrorMessage] = useState()



    const checkEmail = (event) => {
        let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]/
        let inputEmail = document.getElementById("formBasicEmail")
        let ticImg = document.getElementById("ticImg")
        if (event.target === inputEmail) {
            if(inputEmail.value.match(mailformat))
            {
                ticImg.setAttribute("style", "visibility:visible!important");
                setEmailOk(true)
            }
            else
            {
                ticImg.setAttribute("style", "visibility:hidden!important");
                setEmailOk(false)
            }
        }
    }

    const handleChange = (event) => {
        switch(event.target.name) {
            case 'email':
                setEmail(event.target.value)
                break;
            default:
                break;

        }
        checkEmail(event)
        if (event.target.value === "") {
            event.target.setAttribute("style", "padding-top:0!important")
            document.getElementById("little-texte-"+event.target.id).setAttribute("style", "display:none!important")
        } else {
            document.getElementById("little-texte-"+event.target.id).setAttribute("style", "display:block!important")
            event.target.setAttribute("style", "padding-top:16px!important")
        }
    }

    const handleFocus = (event) => {
        if (event.target.value !== "") {
            event.target.setAttribute("style", "padding-top:16px!important")
            document.getElementById("little-texte-"+event.target.id).setAttribute("style", "display:block!important")
        }
    }

    const handleSubmit = (event) => {

        axios
            .post(
                config+"public/api/reset_password/request",
                {
                    username: email,
                    locale: language === "fr" ? 2 : 1
                },
            )
            .then(response => {
                if (response.status === 200) {
                   setSend(true)
                }
            })
            .catch(error => {
                if (error.response.status === 403) {
                    let message = error.response.data.message
                    setErrorMessage(message)
                }else {
                    handleEndpointError(error)
                    setResetErrors(2)
                }         
                
            })
        event.preventDefault()
    }

        
        // if (props.loggedIn === true) {
        //     return <Redirect to="/"/>
        // }
        return (
            <div className={isMobile ? "" : "d-flex justify-content-end background-velo"}>
                <LanguageSwitch />
                <div className={isMobile ? "pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-30 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                    <div className={isMobile ? "mb-auto mt-auto w-100" : "w-100"}>
                        <Link to={"/"}>
                            <img
                                src={isMobile ? gutai_login : gutai_login_desktop}
                                className={isMobile ? "logo-gutai-home" : "logo-gutai-home-desktop"}
                                alt="Gutai logo"
                            />
                        </Link>
                    </div>
                    <p className={isMobile ? "pwd-forgot-text mcb-5" : "pwd-forgot-text pwd-forgot-text-desktop mcb-5"}>{t('app:forgot_password.forgot_password')}</p>
                    <p className={isMobile ? "text-reset" : "text-reset text-reset-desktop"}>{send ? t('app:forgot_password.reinit_link') : t('app:forgot_password.instruction')}</p>
                    {!send ?
                        <div id="form-reset" className={isMobile ? "mb-auto w-100 mct-3" : "w-100"}>
                            <Form>
                                <Form.Group controlId="formBasicEmail" className="mcb-3 form-input-height">
                                    <Form.Control className="form-login-input pcl-3 py-0"
                                                  type="email"
                                                  name="email"
                                                  placeholder="Email"
                                                  value={email}
                                                  onChange={handleChange}
                                                  onFocus={handleFocus}
                                                  onBlur={handleFocus}
                                                  required
                                    >
                                    </Form.Control>
                                    <div className="test-eye ml-auto mcr-3">
                                        <img
                                            id="ticImg"
                                            src={tic}
                                            alt="tic picto"
                                            className="icon-24 invisible"
                                        />
                                    </div>
                                    <p id="little-texte-formBasicEmail" className="little-text-input mb-0 mcl-3 mct-2 text-left d-none">Email</p>

                                </Form.Group>
                            </Form>
                            <div className="flex-column">
                                {errorMessage ?
                                    <div className="error-login-text mcb-3">
                                        {errorMessage}
                                    </div>: <></>
                                }
                                {resetErrors === 2 &&
                                <div className="error-login-text mcb-3">
                                    {t('app:forgot_password.wrong_mail')}
                                </div>
                                }
                                {emailOk ?
                                    <Button id="login-btn" type="submit" className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center forgot-password-btn mct-5' onClick={handleSubmit}>
                                        <img className="mcr-2 icon-24" alt="send icon" src={send_icon} /><p className="my-auto home-btn-p-text">{t('app:forgot_password.send')}</p>
                                    </Button> :
                                    <Button className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center forgot-password-btn mct-5' disabled>
                                        <img className="mcr-2 icon-24" alt="send icon" src={send_icon} /><p className="my-auto home-btn-p-text">{t('app:forgot_password.send')}</p>
                                    </Button>
                                }
                                <Link to={"/"} className="link-btn">
                                    <button id="register-btn" className='mx-auto home-btn-white home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center forgot-password-btn mct-3'>
                                        <p className="my-auto home-btn-p-text">{t('app:forgot_password.back')}</p>
                                    </button>
                                </Link>
                            </div>
                        </div> :
                        <Link to={"/login"} className={isMobile ? "mb-auto text-decoration-none mct-3" : "text-decoration-none"}>
                            <Button className='home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center'>
                                <img className="mcr-2 icon-24" alt="log-in icon" src={login_icon} /><p className="my-auto home-btn-p-text">{t('app:login.btn')}</p>
                            </Button>
                        </Link>
                    }

                </div>
            </div>
        )
}

export default withRouter(withTranslation(['app'],{ wait: true })(ForgotPassword))