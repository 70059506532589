import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {helpCircle, rpe0, rpe910} from "../../../img/img";
import {NavigationContext} from "../../../Contexts";


const RpeDebriefing = ({disciplinesStyles, setRpe, rpe, cardId}) => {
    const {t} = useTranslation(['app'])

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const [pressed, setPressed] = useState(false)
    const [move, setMove] = useState(false)
    const [hiddenLabel, setHiddenLabel] = useState('hidden')

    let rpeValue = rpe ? rpe/10 : null;
    let label = rpeValue !== null ? (rpeValue + " : " + t('app:athlete.debriefing.rpe.'+rpeValue*10)) :  "0 : " + t('app:athlete.debriefing.rpe.0');

    let left = 0
    if (document.getElementById("progressContainer")) {
        left = rpe-(((document.getElementById("speechBubble").offsetWidth/2) / document.getElementById("progressContainer").offsetWidth)*100)+"%"
    }

    const handleEvent = (event) => {
        switch (event.type) {
            case "touchstart":
            case "mousedown":
                setPressed(true)
                break
            case "mousemove":
            case "touchmove":
                if (pressed === true) {
                    setMove(true)
                    clickProgress(event)
                }
                break
            case "mouseup":
            case "touchend":
                setPressed(false)
                break
            case "click":
                if (!move) {
                    clickProgress(event)
                }
                setMove(false)
                break
            default:
                break
        }
    }

    const clickProgress = (e) => {
        let slide = e.type === 'touchmove' || e.type === 'mousemove'
        if (hiddenLabel === 'hidden') {
            setHiddenLabel('visible')
        }

        let target = e.target, x
        if (target.id === 'progressBar'){
            target = target.parentNode
        }
        let posX = target.offsetLeft;
        let width = target.offsetWidth;
        let marginLeft
        marginLeft = document.getElementById(cardId).offsetLeft;
        if (e.type === "touchmove") {
            x = e.touches[0].clientX-marginLeft-posX;
        } else {
            if (!isMobile){
                marginLeft = marginLeft-(document.getElementById(cardId).offsetWidth/2);
            }
            x = e.clientX-marginLeft-posX;
        }


        x = parseInt(x/width*100,10);
        x = x<0?0:x;
        x = x>100?100:x;

        if (target.id === 'progressContainer') {
            if (x >= 0 && x <= 7 ) {
                setRpe(5)
            } else {
                x-=7
                let t = Math.floor(x/5)
                setRpe(10+t*5)
            }
        }
    }

    return (
        <div className="mt-3 col-12">
            <div className="d-flex justify-content-start">
                <p className="h2 rpe-title d-flex justify-content-start align-items-center">
                    RPE
                </p>
            </div>

            <p className="text-left text-p-cr mb-0">{t('app:athlete.debriefing.rpe.content.3')}</p>

            <div className="rpe-content my-4">
                <div className="rpe-section py-3 row mb-3 justify-content-center mcx-1">
                    <div className="col-2 p-0">
                        <img className="mt-2 icon-24" alt="emoji" src={rpe0}/>
                        <span className="fs-10 d-block">0,5</span>
                        <span className="fs-8 d-block">{t('athlete.debriefing.rpe.very_easy')}</span>
                    </div>
                    <div id="progressContainer" className="col-8 rpe-state p-0 " onClick={handleEvent}
                         onMouseDown={handleEvent} onMouseUp={handleEvent} onMouseMove={handleEvent}
                         onTouchStart={handleEvent} onTouchEnd={handleEvent} onTouchMove={handleEvent}>
                        <div id="progressBar" className={"state-bar " + disciplinesStyles.stateBar} style={rpe === 100 ?
                            { width: rpe + '%', height:'42px', borderRadius: '8px' } :
                            { width: rpe + '%', height:'42px' }}>
                            <div id="speechBubble" className={"speech-bubble-debrief d-flex justify-content-center align-items-center " + disciplinesStyles.speechBubble} style={{left: left, visibility: hiddenLabel}}>
                                <p className={'rpe-label ' + disciplinesStyles.rpeLabel}>{label}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-2 p-0">
                        <img className="mt-2 icon-24" alt="emoji" src={rpe910}/>
                        <span className="fs-10 d-block">10</span>
                        <span className="fs-8 d-block">{t('athlete.debriefing.rpe.very_difficult')}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RpeDebriefing;