export const AthleteEventTypes = {
    COMPETITION: 1,
    VACANCE: 2,
    STAGE: 3,
    BLESSURE: 4,
}

export const Sports = {
    TRIATHLON: 1,
    RUNNING: 2,
    TRAIL: 3,
    CYCLISME: 4
}

export const dataSports = (t) => [
    {
        "id" : 1,
        "label" : t('app.sports.triathlon'),
        "code" : "triathlon"
    },
    {
        "id" : 2,
        "label" :  t('app.sports.cap'),
        "code" : "cap"
    },
    {
        "id" : 3,
        "label" :  t('app.sports.trail'),
        "code" : "trail"
    },
    {
        "id" : 4,
        "label" :  t('app.sports.velo'),
        "code" : "velo"
    }
]


export function getAthleteEventTypeLabel(type) {
    let label
    switch (type) {
        case AthleteEventTypes.COMPETITION:
            label = 'competition'
            break;
        case AthleteEventTypes.VACANCE:
            label = 'holiday'
            break;
        case AthleteEventTypes.BLESSURE:
            label = 'injury'
            break;
        case AthleteEventTypes.STAGE:
            label = 'traineeship'
            break;
        default:
            break;
    }
    return label
}

export function getAthleteEventTypeForPut(type) {
    let label
    switch (type) {
        case AthleteEventTypes.COMPETITION:
            label = 'competitions'
            break;
        case AthleteEventTypes.VACANCE:
            label = 'holidays'
            break;
        case AthleteEventTypes.BLESSURE:
            label = 'injuries'
            break;
        case AthleteEventTypes.STAGE:
            label = 'traineeships'
            break;
        default:
            break;
    }
    return label
}


const createAthleteEventFrom = (athleteEventType, entity, athleteEventKey) => {
    let athleteEvent = {}

    athleteEvent.type = athleteEventType
    athleteEvent.id = entity.id
    athleteEvent.date = entity.start_date
    athleteEvent.name = entity.name
    athleteEvent.key = 'athlete-event-'+athleteEventKey

    if(athleteEventType === AthleteEventTypes.COMPETITION) {
        athleteEvent.sport = entity.sport
    } else {
        //athleteEvent.description = entity.description
        athleteEvent.description = "Description"
    }

    return athleteEvent
}

// Préparer événément Compétition pour le calendrier
const createAthleteEventFromCompetition = (competition, athleteEventKey) => {
// type
// name
// start_date
// end_date
// goal_label
// goal

    return createAthleteEventFrom(AthleteEventTypes.COMPETITION, competition, athleteEventKey)
}
export {createAthleteEventFromCompetition};

// Préparer événément Blessure pour le calendrier
const createAthleteEventFromInjury = (injury, athleteEventKey) => {
// type
// name
// start_date
// end_date
// resume

    return createAthleteEventFrom(AthleteEventTypes.BLESSURE, injury, athleteEventKey)
}
export {createAthleteEventFromInjury};

// Préparer événément Stage pour le calendrier
const createAthleteEventFromTraineeship = (traineeship, athleteEventKey) => {
// type
// name
// start_date
// end_date
// resume

    return createAthleteEventFrom(AthleteEventTypes.STAGE, traineeship, athleteEventKey)
}
export {createAthleteEventFromTraineeship};

// Préparer événément Compétition pour le calendrier
const createAthleteEventFromHoliday = (holiday, athleteEventKey) => {
// resume
// type
// name
// start_date
// end_date

    return createAthleteEventFrom(AthleteEventTypes.VACANCE,holiday, athleteEventKey)
}
export {createAthleteEventFromHoliday};


export function getAthleteEventClassNames (type) {
    let athleteEventClass = "agenda-cube cube-white d-flex justify-content-center px-0 mcb-3"

    if(type === AthleteEventTypes.COMPETITION) {
        athleteEventClass = "compet-case cube-white d-flex p-0 mcb-3 btn btn-outline-light b-0"
    }

    return {athleteEventClass}
}
