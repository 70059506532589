import React, {useContext, useState} from 'react';
import {Form} from "react-bootstrap";
import {handleFocus} from "../Home/InputChecking";
import {eye, eye_slash, tic_green, tic_grey} from "../../img/img";
import {NavigationContext} from "../../Contexts";
import {useTranslation} from "react-i18next";

const PasswordInput = ({password, onChangePassword, setPasswordOk, toCheck}) => {
    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    const {t} = useTranslation(['app'])

    const [inputType, setInputType] = useState("password")
    const [displayCheck, setDisplayCheck] = useState(false)
    const [passwordFormats, setPasswordFormats] = useState({
        maj: false,
        min: false,
        long: false,
        spe: false,
        num: false
    })

    const handleChange = (event) => {
        if (toCheck) {
            checkPassword(event.target.value)
        }
        onChangePassword(event.target.value)
    }

    const handleFocus = (event) => {
        if (event.type === "focus") {
            setDisplayCheck(true)
        } else if (event.type === "blur") {
            setDisplayCheck(false)
        }
    }

    const checkPassword = (value) => {
        let pwdformat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{8,}$/
        let pwdMaj = /^(?=.*[A-Z])/
        let pwdMin = /^(?=.*[a-z])/
        let pwdLong = /^.{8,}/
        let pwdSpe = /^(?=.*\W)/
        let pwdNum = /^(?=.*[0-9])/

        let formats = {}
        formats.maj = !!value.match(pwdMaj)
        formats.min = !!value.match(pwdMin)
        formats.long = !!value.match(pwdLong)
        formats.spe = !!value.match(pwdSpe)
        formats.num = !!value.match(pwdNum)

        setPasswordFormats(formats)

        setPasswordOk(!!value.match(pwdformat))

    }

    const showPassword = (state) => {
        setInputType(state ? "text" : "password")
    }

    return (
        <>
            <Form.Group controlId="formBasicPassword" className="mcb-2 form-input-height">
                <Form.Control className={password === "" ? "form-login-input pcl-3 py-0" : "form-login-input pcl-3 pb-0 pct-2"}
                              type={inputType}
                              name="password"
                              placeholder={t('app:login.password')+" *"}
                              value={password}
                              onChange={handleChange}
                              onFocus={(event) => handleFocus(event)}
                              onBlur={(event) => handleFocus(event)}
                              required
                />
                <div className="test-eye ml-auto mcr-3">
                    {inputType === "password" ?
                        <img
                            id="eyeSlashImg"
                            src={eye_slash}
                            alt="eye slash picto"
                            className="icon-24 cursor-pointer"
                            onClick={() => showPassword(true)}
                        />
                        :
                        <img
                            id="eyeImg"
                            src={eye}
                            alt="eye picto"
                            className="icon-24 cursor-pointer"
                            onClick={() => showPassword(false)}
                        />
                    }
                </div>
                {password !== "" ?
                    <p className="little-text-input-opti mb-0 mcl-3 mct-2 text-left">{t('app:login.password')+" *"}</p>
                    :
                    <></>
                }
            </Form.Group>
            {displayCheck && toCheck &&
            <div id="check-pwd" className={isMobile ? "w-100 text-left mcb-4 mcl-3" : "w-100 text-left mcb-4 mcl-3 relative-div check-pwd-desktop"}>
                <div id="checkLong" className="d-flex align-items-center mcb-1">
                    {passwordFormats.long ?
                        <img
                            id="ticGreen-checkLong"
                            src={tic_green}
                            alt="tic grey picto"
                            className="icon-12"
                        />
                        :
                        <img
                            id="ticGrey-checkLong"
                            src={tic_grey}
                            alt="tic grey picto"
                            className="icon-12"
                        />
                    }
                    <p className="validate-pwd mb-0 mcl-2">
                        {t('app:register.8_caracteres')}
                    </p>
                </div>
                <div id="checkMin" className="d-flex align-items-center mcb-1">
                    {passwordFormats.min ?
                        <img
                            id="ticGreen-checkMin"
                            src={tic_green}
                            alt="tic grey picto"
                            className="icon-12"
                        />
                        :
                        <img
                            id="ticGrey-checkMin"
                            src={tic_grey}
                            alt="tic grey picto"
                            className="icon-12"
                        />
                    }
                    <p className="validate-pwd mb-0 mcl-2">
                        1 {t('app:register.minuscule')}
                    </p>
                </div>
                <div id="checkMaj" className="d-flex align-items-center mcb-1">
                    {passwordFormats.maj ?
                        <img
                            id="ticGreen-checkMaj"
                            src={tic_green}
                            alt="tic grey picto"
                            className="icon-12"
                        />
                        :
                        <img
                            id="ticGrey-checkMaj"
                            src={tic_grey}
                            alt="tic grey picto"
                            className="icon-12"
                        />
                    }
                    <p className="validate-pwd mb-0 mcl-2">
                        1 {t('app:register.majuscule')}
                    </p>
                </div>
                <div id="checkNum" className="d-flex align-items-center mcb-1">
                    {passwordFormats.num ?
                        <img
                            id="ticGreen-checkNum"
                            src={tic_green}
                            alt="tic grey picto"
                            className="icon-12"
                        />
                        :
                        <img
                            id="ticGrey-checkNum"
                            src={tic_grey}
                            alt="tic grey picto"
                            className="icon-12"
                        />
                    }
                    <p className="validate-pwd mb-0 mcl-2">
                        1 {t('app:register.number')}
                    </p>
                </div>
                <div id="checkSpe" className="d-flex align-items-center mcb-1">
                    {passwordFormats.spe ?
                        <img
                            id="ticGreen-checkSpe"
                            src={tic_green}
                            alt="tic grey picto"
                            className="icon-12"
                        />
                        :
                        <img
                            id="ticGrey-checkSpe"
                            src={tic_grey}
                            alt="tic grey picto"
                            className="icon-12"
                        />
                    }
                    <p className="validate-pwd mb-0 mcl-2">
                        1 {t('app:register.spec_carac')} (%, #, -, ?, !)
                    </p>
                </div>
            </div>
            }
        </>
    )
}

export default PasswordInput
