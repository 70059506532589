
export function showPassword () {
    let passwordInput = document.getElementById("formBasicPassword");
    let eyeImg = document.getElementById("eyeImg");
    let eyeSlashImg = document.getElementById("eyeSlashImg");
    if (passwordInput.type === "password") {
        passwordInput.type = "text";
        eyeSlashImg.setAttribute("style", "display:none!important");
        eyeImg.setAttribute("style", "display:block!important");
    }
    else {
        passwordInput.type = "password";
        eyeSlashImg.setAttribute("style", "display:block!important");
        eyeImg.setAttribute("style", "display:none!important");
    }
}

export function handleFocus (event) {
    let x = document.getElementById("text-middle-login");
    let form = document.getElementById("form-login");
    const isMobile = window.innerWidth <= 500;

    if (isMobile && event.target.name !== "passwordChange") {
        setTimeout(function() {
            let activeElement = document.activeElement
            if (activeElement.id !== "register-btn") {
                if (x.style.display === "none") {
                    x.style.display = "block";
                    form.setAttribute("style", "margin-top:auto!important;");
                } else {
                    x.style.display = "none";
                    form.setAttribute("style", "margin-top:0px!important;");
                }
            }
        }, 100);
    }
    if (event.target.value !== "") {
        event.target.setAttribute("style", "padding-top:16px!important")
        document.getElementById("little-texte-"+event.target.id).setAttribute("style", "display:block!important")
    }
    if (event.target.name === "password" || event.target.name === "passwordChange") {
        const checkPwd = document.getElementById("check-pwd")
        if (event.type === "focus") {
            if (isMobile || event.target.name === "passwordChange") {
                checkPwd.setAttribute("style", "display:block!important")
            } else {
                let rightRelative = event.target.offsetWidth
                checkPwd.setAttribute("style", "display:block!important; position:relative; top:-72px; right:-"+rightRelative+"px; margin-bottom:0!important;")

            }
        } else if (event.type === "blur") {
            checkPwd.setAttribute("style", "display:none!important")
        }
    }
}
