import React, {useContext, useEffect, useState} from "react";
import {gutai_intensity_graph_en, gutai_intensity_graph_fr} from "../../img/img";
import {MetricContext, LanguageContext} from "../../Contexts";
import {getCIPacingUnit, TrainingConceptCodes} from "../TrainingConcept/Utils";

const PacingGraph = ({trainingConceptCode, currentDataPacingValues, discipline}) => {
    const {metric, runningCriticalIntensityStatistic} = useContext(MetricContext)
    const {language} = useContext(LanguageContext)

    let unitLabel = "bpm"

    if (trainingConceptCode === TrainingConceptCodes.CRITICAL_INTENSITY) {
        unitLabel = getCIPacingUnit(discipline, metric, runningCriticalIntensityStatistic)
    }

    const [xValues, setXValues] = useState([])

    const z1Datas = currentDataPacingValues.Z1[0]
    const z2Datas = currentDataPacingValues.Z2[0]
    const z3Datas = currentDataPacingValues.Z3[0]

    // valeurs temporaires, à remplir avec [Z1value, Z2Value, Z3Value.1, Z3Value.2]
    useEffect(() => {
        // console.log('useEffect currentDataPacingValues', currentDataPacingValues)
            let values = []
        if(currentDataPacingValues.Z1[0] !== "") {

            if(trainingConceptCode === TrainingConceptCodes.CRITICAL_INTENSITY) {
                let Z1Values = currentDataPacingValues.Z1[0].split("-")
                values.push(Z1Values[Z1Values.length-1])
                let Z2iValues = currentDataPacingValues.Z2i[0].split("-")
                values.push(Z2iValues[Z2iValues.length-1])
                let Z3Values = currentDataPacingValues.Z3[0].split("-")
                values.push(Z3Values[0])
                values.push(Z3Values[1])
            }
            else {
                let Z1iiValues = currentDataPacingValues.Z1ii[0].split("-")
                values.push(Z1iiValues[Z1iiValues.length-1])
                let Z2Values = currentDataPacingValues.Z2[0].split("-")
                values.push(Math.round((Z2Values[1]-Z2Values[0])/2) + Math.round(Z2Values[0]))
                let Z3iValues = currentDataPacingValues.Z3i[0].split("-")
                values.push(Z3iValues[0])
                values.push(Z3iValues[1])
            }


            // console.log(values)
        }

        setXValues(values)
    },[currentDataPacingValues])


    return (
        <div className="pct-3">
            {language === 'fr' &&
                <img src={gutai_intensity_graph_fr} alt="graphique gutai intensité" className="w-100"/>
            }
            {language === 'en' &&
                <img src={gutai_intensity_graph_en} alt="gutai intensity graph" className="w-100"/>
            }
            {xValues.length > 0 &&
                <div className="x-axis-row d-flex mcb-2">
                    <div className="position-absolute x-axis-unit d-flex justify-content-center align-items-center" style={{left: "6.5%"}}>
                        <span className="x-axis-tz-graph" style={{lineHeight: "11px"}}>{unitLabel}</span>
                    </div>
                    <span className="position-absolute x-axis-tz-graph" style={{left: "21%"}}>&nbsp;</span>
                    <span className="position-absolute x-axis-tz-graph" style={{left: "31%"}}>{xValues[0]}</span>
                    <span className="position-absolute x-axis-tz-graph" style={{left: "44%"}}>{xValues[1]}</span>
                    <span className="position-absolute x-axis-tz-graph" style={{left: "57%"}}>{xValues[2]}</span>
                    <span className="position-absolute x-axis-tz-graph" style={{left: "70%"}}>{xValues[3]}</span>
                </div>
            }
        </div>
    )

}

export default PacingGraph;