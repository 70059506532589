import React from 'react'
import { Marker } from 'react-leaflet'
import { useSelector } from 'react-redux'
import EndIcon from './EndIcon'
import StartIcon from './StartIcon'

const MapMarker = ({ isEndMarker = false, defaultCoordinates = null }) => {
  const markerCoordinates = useSelector(app => app.map.markerCoordinates)

  return markerCoordinates && (
    <Marker
      position={defaultCoordinates ?? [markerCoordinates.lat, markerCoordinates.long]}
      icon={isEndMarker ? EndIcon : StartIcon}
    />
  )
}

export default MapMarker
