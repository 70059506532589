import React, {useState, useContext} from 'react';

import {useTranslation} from "react-i18next";
import {Button, Form} from "react-bootstrap";
import {
    validate,
    x_circle
} from "../../img/img";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import generateHeaders from "../../Functions/generateHeaders";
import {useIsMounted} from "../Tools/CustomHooks";

import {WorkoutTemplateContext} from "../../Contexts";

const WorkoutTemplateNewFavoritesList = ({closeWidget}) => {
    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()

    const {onUpdateFavorite} = useContext(WorkoutTemplateContext)

    const [title, setTitle] = useState(null)
    const [description, setDescription] = useState(null)

    const postNewFavoritesList = () => {
        let datas = {
            name: title,
            description: description
        }
        axios
            .post(
                config + "api/workout_template_lists/new",
                datas,
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    }
                },
            )
            .then((response) => {
                if(!isMounted.current){
                    return 0;
                }
                onUpdateFavorite(true)
                // Fermer le formulaire de création de liste
                closeWidget()
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    return (
        <div className={"col-xl-8 p-3 mb-4 card-float-dashboard z-index-1"}>
            <div className="dashboard-card background-white">
                <div className={"d-flex align-items-center pc-3 justify-content-between"}>
                    <div className="d-flex align-items-center">
                        <div className="text-left mcl-3">
                            <p className={"text-caprina fs-18 mb-0"}>
                                {t('app.workout_template.new_list.title')}
                            </p>
                        </div>
                    </div>
                    <img
                        className="icon-24 pointer right"
                        src={x_circle}
                        alt="close icon"
                        onClick={() => closeWidget()}
                    />
                </div>

                <div className="popup-card pc-4 d-flex flex-column">
                    <div className="text-caprina font-weight-bold fs-16 mcb-3 text-left">
                        {t('app.workout_template.new_list.input_name')}*
                    </div>

                    <div className="w-100">
                        <Form.Group controlId="form-title">
                            <Form.Control className="form-login-input workout-template-input"
                                          type="text"
                                          name="title"
                                          placeholder={t('athlete.debriefing.comment.placeholder')}
                                          value={title}
                                          onChange={(e) => setTitle(e.target.value)}
                                          required
                            >
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="text-caprina font-weight-bold fs-16 mcb-3 text-left">
                        {t('app.workout_template.new_list.input_description')}
                    </div>

                    <div className="w-100">
                        <Form.Group controlId="form-description">
                            <Form.Control className="form-login-input workout-template-input"
                                          type="text"
                                          name="description"
                                          placeholder={t('athlete.debriefing.comment.placeholder')}
                                          value={description}
                                          onChange={(e) => setDescription(e.target.value)}
                                          required
                            >
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                        <Button className="background-black py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text"
                                onClick={() => postNewFavoritesList()}
                                disabled={!title}
                        >
                            <img className="mr-2 icon-24" alt="valid icon" src={validate} />{t('app:athlete.saving.save')}
                        </Button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default WorkoutTemplateNewFavoritesList;