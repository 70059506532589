import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const mapInitialState = {
  markerDuration: null,
  markerCoordinates: null,
  noMap: false,
};

// Used to make setMarkerDuration action async
export const setMarkerDuration = createAsyncThunk(
  'map/setMarkerDuration',
  async (markerDuration) => markerDuration
)

export const mapSlice = createSlice({
  name: 'map',
  initialState: mapInitialState,
  reducers: {
    setMarkerCoordinates: (state, action) => {
      state.markerCoordinates = action.payload
    },
    setNoMap: (state, action) => {
      state.noMap = action.payload
    },
    reset: (state) => {
      state = mapInitialState
    },
  },
  extraReducers: {
    [setMarkerDuration.fulfilled]: (state, action) => {
      state.markerDuration = action.payload
    }
  }
})

// Action creators are generated for each case reducer function
export const { setMarkerCoordinates, setNoMap, reset } = mapSlice.actions

export default mapSlice.reducer