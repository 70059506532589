import React, {useContext, useState} from "react";
import {HeaderHome} from "../Components/Home/HeaderHome";
import {Button} from "react-bootstrap";
import {NavigationContext} from "../Contexts";
import {capteurs, login_icon, maillot_ete, shopping_bag, trifonction,blue_shopping_bag} from "../img/img";
import {useTranslation} from "react-i18next";
import '../Css/Sponsorship.css';

const SponsorshipConfirmation = () => {
    const {isMobile} = useContext(NavigationContext)
    const {t} = useTranslation(['app'])

    const [shopLinks, setShopLinks] = useState({
            running: "https://gutai-training.com/categorie-produit/homme/vetements-homme/running-homme/",
            cycle: "https://gutai-training.com/categorie-produit/divers/capteurs-de-puissance/",
            triathlon: "https://gutai-training.com/categorie-produit/homme/vetements-homme/triathlon-homme/"
        }
    )


    return (
        <>
        <div className={isMobile ? "" : "d-flex justify-content-end background-sponsorship min-vh-100"}>
            <div className={"pc-4 d-flex flex-column justify-content-center "+ (isMobile ? "container" : " w-40 container-desktop")}>
                <HeaderHome withoutLanguageSwitch={1} />
                <h1 className={isMobile ? "text-center pcb-5 mct-3 sponsorship-title-mobile" :"text-center pcb-5 mct-5 sponsorship-title"}>{t("sponsorship.congratulation")}</h1>
                <p className="sponsorship-text text-center">{t("sponsorship.send_mail_text")}</p>
                <div className="mct-3">
                    <a href="/dashboard">
                        <Button className="sponsorship-btn">
                            <img className="mcr-2 icon-24" src={login_icon}/>{t("sponsorship.btn_connect_text")}
                        </Button>
                    </a>
                    <div className="row d-flex justify-content-center align-items-center mct-5 mcb-5">
                        <div className="grey-line mcr-2"></div>
                            <p className="mb-0 grey-line-text">{t('app:login.or')}</p>
                        <div className="grey-line mcl-2"></div>
                    </div>
                    <a href="/sponsorship/new" className="sponsorship-text text-center">{t("sponsorship.more_sponsor_text")}</a>
                    <h1 className={isMobile ? "text-center pcb-5 mct-3 sponsorship-title-mobile" :"text-center pcb-5 mct-5 sponsorship-title"}>{t("sponsorship.equip_yourself")}</h1>
                </div>

                <div className={isMobile ? "row d-flex flex-column mt-3" : "row d-flex mt-3"}>
                    <div className="col px-0 radius-total form-checkout-blue align-items-center mr-3 mb-3">
                        <img className="pcx-2 w-100 mct-2" src={maillot_ete}/>
                        <p className="text-subscription-confirmation">{t("confirmation_page.summer_jersey")}</p>
                        <Button id="login-btn" href={shopLinks.running}
                                className='mcx-2 btn-sponsorship home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-3'>
                            <img className="mcr-2 icon-20" alt="log-in icon" src={blue_shopping_bag}/><p
                            className="my-auto home-btn-p-text">Running</p>
                        </Button>
                    </div>
                    <div className="col px-0 radius-total form-checkout-blue mr-3 mb-3">
                        <img className="pcx-2 w-100 mct-2" src={capteurs}/>
                        <p className="text-subscription-confirmation">{t("confirmation_page.power_sensors")}</p>
                        <Button id="login-btn" href={shopLinks.cycle}
                                className='mcx-2 btn-sponsorship home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-3'>
                            <img className="mcr-2 icon-20" alt="log-in icon" src={blue_shopping_bag}/><p
                            className="my-auto home-btn-p-text">Cyclisme</p>
                        </Button>
                    </div>
                    <div className="col px-0 radius-total form-checkout-blue mr-3 mb-3">
                        <img className="pcx-2 w-100 mct-2" src={trifonction}/>
                        <p className="text-subscription-confirmation">{t("confirmation_page.trifunction")}</p>
                        <Button id="login-btn" href={shopLinks.triathlon}
                                className='mcx-2 btn-sponsorship home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-3'>
                            <img className="mcr-2 icon-20" alt="log-in icon" src={blue_shopping_bag}/><p
                            className="my-auto home-btn-p-text">Triathlon</p>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SponsorshipConfirmation