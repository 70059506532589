import React, {useContext} from "react";
import {Route, Redirect} from "react-router-dom";
import {AuthContext, LoggedInContext} from "./Contexts";


function PrivateRoute({ component: Component, ...rest }) {

    const {token} = useContext(AuthContext)
    const {username,  has_finalized_registration} = useContext(LoggedInContext)

    return (
        <Route
            {...rest}
            render={props => (
                !token  ?
                    <Redirect to={{ pathname: "/login", state: { referer: props.location } }} />
                :
                    (username ?
                            (!has_finalized_registration ? <Redirect to={{ pathname: "/finalize_registration", state: { referer: props.location } }} />
                                                         :<Component {...props} />)
                            :
                            <></>)
            )}
        />
    );
}

export default PrivateRoute;