import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";
import Loader from 'react-loader-spinner'
import {Button} from "react-bootstrap";
import {scroller, Element as ScrollerElement } from "react-scroll";
import BankCard from  "./BankCard"
import NewCartPayment from  "./NewCartPayment"

import {logo_stripe,validate} from "../img/img";


const CartPayment = ({color, purchaseCart, payForCart}) => {

    // Traduction
    const {t} = useTranslation(['app']);

    const [paymentMethod, setPaymentMethod] = useState(null)
    const [updatePaymentMethodLoading, setUpdatePaymentMethodLoading] = useState(false)
    const [readyToPurchase, setReadyToPurchase] = useState(false)
    const [submitForm, setSubmitForm] = useState(false)
    const [hideSubmitBtn, setHideSubmitBtn] = useState(false)

    useEffect(() => {
        if(submitForm && paymentMethod) {
            // Créer l'achat
            payForCart()
            setHideSubmitBtn(true)
        }
    }, [submitForm, paymentMethod])


    useEffect(() => {
        if(!updatePaymentMethodLoading) {
            // setTimeout(() => {
                scroller.scrollTo('paymentMethodElement', {
                    duration: 500,
                    delay: 2000,
                    smooth: 'easeOutQuart',
                    offset: 200
                })
            // }, 2000)
        }
    }, [updatePaymentMethodLoading])


    const updatePaymentMethod = (value) => {
        setPaymentMethod(value)
        setUpdatePaymentMethodLoading(false)
    }

    const updateReadyToPurchase = (value) => {
        setReadyToPurchase(value)
    }

    const createPurchase = () => {
        // Déclencher la création de la demande d'autorisation pour la carte bancaire
        setSubmitForm(true)
    }

    const clearBankCard = () => {
        updatePaymentMethod(false)
        updateReadyToPurchase(false)
        setSubmitForm(false)
    }

    return (
        <>
            <div className="w-100 mct-4 mcb-2 d-flex align-items-center justify-content-between">
                <h4 className="text-left label-form-subscription">{t("checkout.payment_method")}</h4>
                <img className="logo-stripe" src={logo_stripe}/>
            </div>
            {updatePaymentMethodLoading ?
                <Loader
                    type="TailSpin"
                    color={color === 'orange' ? '#FF7A00' : '#007DFF'}
                    height={50}
                    width={50}
                    className="my-5"
                />
                :
                <>
                    <ScrollerElement name="paymentMethodElement"
                                     className="w-100"
                    >
                    {paymentMethod !== false ?
                        <BankCard
                            color={color}
                            updatePaymentMethod={(value) => updatePaymentMethod(value)}
                            clearBankCard={() => clearBankCard()}
                            updateReadyToPurchase={() => updateReadyToPurchase(true)}
                        />
                        :
                        <NewCartPayment
                            color={color}
                            updateReadyToSubmit={(value) => updateReadyToPurchase(value)}
                            submitForm={submitForm}
                            updatePaymentMethod={(value) => updatePaymentMethod(value)}
                            clearBankCard={() => clearBankCard()}
                        />
                    }
                    </ScrollerElement>
                    <Button id="subscribe-btn"
                            className={(hideSubmitBtn ? "d-none":"d-flex") +' link-btn mx-auto mct-3 home-btn home-btn-text pcx-3 pcy-2 align-items-center justify-content-center mcb-2'}
                            onClick={() => createPurchase()}
                            disabled={!readyToPurchase}
                    >
                        <img className="mcr-2 icon-24" alt="valid icon" src={validate} /><p className="my-auto mx-auto home-btn-p-text">{t("checkout.subscribe_btn")}</p>
                    </Button>
                </>
            }
        </>
    )
}
export default CartPayment