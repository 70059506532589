import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import InputField from "../Components/Forms/InputField";
import '../Css/Sponsorship.css';

const SponsorForm = ({sponsor, onChangeSponsor, index, isRemovable, onRemoveSponsor}) => {
    const {t} = useTranslation(['app'])

    const [emailOk, setEmailOk] = useState(false)

    useEffect(() => {
        let newSponsor = sponsor
        newSponsor.emailOk = emailOk
        onChangeSponsor(newSponsor)
    }, [emailOk])


    const onChangeValue = (event) => {
        let newSponsor = sponsor
        let value = event.target.value
        if (event.target.name === "checkMale-"+index || event.target.name === "checkFemale-"+index) {
            if (event.target.id === "checkMale-"+index) {
                newSponsor.gender = 1
            } else {
                newSponsor.gender = 2
            }
        } else {
            newSponsor[event.target.name] = value
        }
        onChangeSponsor(newSponsor)
    }


    return (
        <div id="sponsor" className=" text-left mcb-3 sponsor-form">
            <div className="d-flex justify-content-between">
                <p className="sponsor-number mcb-4">{t('app:sponsorship.new_sponsorship.sponsor_number') + " " + (index+1)}</p>
                {isRemovable &&
                    <i className="bi bi-x-circle" onClick={() => onRemoveSponsor(index)}></i>
                }
            </div>
            <p className="label-home text-left mcb-3">{t('app:sponsorship.new_sponsorship.civility')}*</p>
            <div className="row">
                <div className="checkbox-container-login">
                    <input type="checkbox" id={"checkMale-" + index} name={"checkMale-" + index} onChange={event => onChangeValue(event)} checked={sponsor.gender === 1}/>
                    <label className="mcl-3 label-home" htmlFor={"checkMale-" + index}>{t('app:finalize_registration.male')}</label>
                </div>
                <div className="checkbox-container-login">
                    <input type="checkbox" id={"checkFemale-"+index} name={"checkFemale-"+index} onChange={event => onChangeValue(event)} checked={sponsor.gender === 2}/>
                    <label className="checkbox-sex label-home" htmlFor={"checkFemale-"+index}>{t('app:finalize_registration.female')}</label>
                </div>
            </div>
            <div>
                <InputField isRequired={true}
                            name={"firstname"}
                            type={"text"}
                            inputValue={sponsor.firstname}
                            setValue={event => onChangeValue(event)}
                            placeholder={t('app:register.firstname')+' *'}
                            withEvent={true}
                />

                <InputField isRequired={true}
                            name={"name"}
                            type={"text"}
                            inputValue={sponsor.name}
                            setValue={event => onChangeValue(event)}
                            placeholder={t('app:register.name')+' *'}
                            withEvent={true}
                />

                <InputField isRequired={true}
                            name={"email"}
                            type={"email"}
                            inputValue={sponsor.email}
                            setValue={event => onChangeValue(event)}
                            placeholder={'Email *'}
                            setEmailOk={setEmailOk}
                            withEvent={true}
                />
            </div>
        </div>
    )
}

export default SponsorForm