import React from "react";
import {useTranslation} from "react-i18next";

const InfoDebriefing = () => {
    const {t} = useTranslation(['app'])

    return (
        <div className="w-100">
            <p className={"rpe-title text-left mcb-3"}>{t('athlete.debriefing.info.title')}</p>
            <p className="info-text">
                {t('athlete.debriefing.info.text.1')}<br/>
                {t('athlete.debriefing.info.text.2')}<br/>
                {t('athlete.debriefing.info.text.3')}
            </p>
            <div className="separator-custom mcy-4"/>
        </div>
    )
}

export default InfoDebriefing