import {
    cap_set,
    duathlon_set,
    duathlon_grey,
    multi_set,
    muscu_grey,
    muscu_set,
    velo_set,
    nat_grey,
    cap_grey,
    velo_grey,
    nat_set,
    multi_grey,
    level_1, level_2, level_3
} from "../../img/img";


export const DISCIPLINES_WORKOUT_TEMPLATE = {
    velo: {
        icon_set: velo_set,
        icon_unset: velo_grey,
        color: "#00D254",
        default_theme_img_url: "https://gutai-training.com/wp-content/uploads/2021/03/Indoor-cycling-bike-man.jpg"
    },
    cap: {
        icon_set: cap_set,
        icon_unset: cap_grey,
        color: "#FFA800",
        default_theme_img_url: "https://gutai-training.com/wp-content/uploads/2021/06/pexels-run-ffwpu-2524742-scaled.jpg"
    },
    nat: {
        icon_set: nat_set,
        icon_unset: nat_grey,
        color: "#007DFF",
    },
    triathlon: {
        icon_set: multi_set,
        icon_unset: multi_grey,
        color: "#F15700",
        default_theme_img_url: "https://gutai-training.com/wp-content/uploads/2021/01/80292006_10157809155091963_2995486126975746048_o.jpg"
    },
    duathlon: {
        icon_set: duathlon_set,
        icon_unset: duathlon_grey,
        color: "#FF7A00",
        default_theme_img_url: "https://gutai-training.com/wp-content/uploads/2020/12/Cycliste-4-scaled-1.jpg"
    },
    renfo: {
        icon_set: muscu_set,
        icon_unset: muscu_grey,
        color: "#000000",
        default_theme_img_url: "https://gutai-training.com/wp-content/uploads/2021/01/Renforcement-reprise-sport-tobefit-scaled.jpg"
    }
}

export const FILTERS_WORKOUT_TEMPLATE = {
    sessions: "sessions",
    themes: "themes",
    authors: "authors",
    favorites: "favorites",
    sessions_done: "sessions_done",
}

export const AUTHOR_DEFAULT_IMAGE = "https://gutai-training.com/wp-content/uploads/2021/05/Bikepacking-couchedesoleil.jpeg"

export const getLevelIcon = (level) => {
    let levelIcon
    switch (level) {
        case 1:
            levelIcon = level_1
            break
        case 2:
            levelIcon = level_2
            break
        default:
        case 3:
            levelIcon = level_3
            break
    }
    return levelIcon
}

export const tranformDisciplines = (disciplines) => {
    let disciplinesCodes = []
    for (const discipline of disciplines) {
        disciplinesCodes.push(discipline.code)
    }
    if (disciplinesCodes.includes("cap") && disciplinesCodes.includes("nat") && disciplinesCodes.includes("velo")) {
        return [{code: "triathlon"}]
    }
    if (disciplinesCodes.includes("cap") && disciplinesCodes.includes("velo")) {
        return [{code: "duathlon"}]
    }
    return disciplines
}