import React, {useState, useEffect, useContext} from 'react';
import {useTranslation} from "react-i18next";
import Loader from 'react-loader-spinner'
import {Button} from "react-bootstrap";

import {validate, validate_blue, x_circle} from "../../img/img";
import {NavigationContext} from "../../Contexts";
import '../../Css/Settings.css';
import {animateScroll} from "react-scroll";
import generateHeaders from "../../Functions/generateHeaders";
import axios from "axios/index";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";

function CancelSubscriptionModal ({endDate,closeCancelSubscription, onCancelSubscriptionSuccess}) {

    const {t} = useTranslation(['app'])
    const {isMobile} = useContext(NavigationContext)

    const [checkedConfirmation, setCheckedConfirmation] = useState(false)
    const [isSubmitable, setIsSubmitable] = useState(false)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if(isMobile) {
            setTimeout(() => {
                animateScroll.scrollToTop()
            }, 200)
        }
    }, [isMobile])

    useEffect(() => {
        setIsSubmitable(checkedConfirmation)
    }, [checkedConfirmation])

    const subscriptionCancelAtPeriodEnd = async () => {
        await axios.post(
            config+"api/my_subscription/cancel_at_period_end",
            {},
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
            .then(response => {
                if (response.status === 200) {
                    onCancelSubscriptionSuccess()
                    // let clientSecret = response.data["setup_intent_client_secret"]
                }

            })
            .catch(error => {
                handleEndpointError(error)
                setLoading(false)
            })
    }
    const handleSubmit = () => {
        setLoading(true)
        // Enregister la demande d'annulation d'abonnement
        subscriptionCancelAtPeriodEnd()
    }

    const onChangeCheckedConfirmation = (event) => {
        let isChecked = event.target.checked
        setCheckedConfirmation(isChecked)
    }

    return (
            <div className={"settings-modal " + (isMobile ? "cancel-subscription-modal-mobile " : "cancel-subscription-modal")}
            >
                <p className="title-pref mct-3 pcx-3"
                   style={{'textAlign': 'left'}}
                >
                    {t("app:settings.subscription.cancel_subscription_confirmation.title")}
                    <img
                        className="icon-24 pointer"
                        src={x_circle}
                        alt="arrow left icon"
                        style={{position: 'absolute', right: '10px'}}
                        onClick={() => closeCancelSubscription()}
                    />
                </p>
                {loading ?
                    <Loader
                        type="TailSpin"
                        color={'#007DFF'}
                        height={50}
                        width={50}
                        className="my-5"
                    />
                    :
                    <>
                        <div className="pcx-3 pct-3">
                            <div className="fs-14 text-left">
                                <p>{t("app:settings.subscription.cancel_subscription_confirmation.message.0")} <b>{endDate}</b>.</p>
                                <p>{t("app:settings.subscription.cancel_subscription_confirmation.message.1")}</p>
                            </div>
                            <div className={"d-flex justify-content-start checkbox-container checkbox-container-subscription subscription-options-blue w-100 mt-4 mb-2"}
                                 style={{alignItems: 'start'}}
                            >
                                <input id="checkbox" type="checkbox"
                                       checked={checkedConfirmation}
                                       onChange={(event) => onChangeCheckedConfirmation(event)}
                                />
                                <label htmlFor="checkbox" className="subscription-label text-left"></label>
                                <p className="subscription-label mb-0 text-left"
                                   style={{fontSize: '9px'}}
                                >
                                    {t("app:settings.subscription.cancel_subscription_confirmation.check")}
                                </p>
                            </div>
                        </div>
                        <Button className={'modal-submit-btn d-flex link-btn mx-auto mct-3 mcb-1 home-btn home-btn-text pcx-3 pcy-2 align-items-center justify-content-center'}
                                onClick={() => handleSubmit()}
                                disabled={!isSubmitable}
                        >
                            <img className="mcr-2 icon-24" alt="valid icon" src={validate} />
                            <p className="my-auto mx-auto home-btn-p-text">{t("app:settings.subscription.cancel_subscription_confirmation.cta")}</p>
                        </Button>
                        <span onClick={() => closeCancelSubscription()} className="settings-btn mcb-3 d-block" style={{ float: "none" }} >{t("app:settings.subscription.cancel_subscription_confirmation.cta_cancel")}</span>
                    </>
                }
            </div>
    )
}

export default CancelSubscriptionModal;