export const RecurrencyTypes = {
    NONE: 0,
    DAY: 1,
    DAY_WITHOUT_WEEKEND: 5,
    WEEK: 2,
    MONTH: 3,
    YEAR: 4
}

export const dataRecurrencyTypes = (t, dayName) => {
    let results = []

    for(const [key, value] of Object.entries(RecurrencyTypes)) {
        results.push({
            "id" : value,
            "label" : t('app:athlete.athlete_availability.recurrency_type.'+ key.toLowerCase(), { dayName: dayName})
        })
    }

    return results
}