import React, {useState, useContext } from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../api";
import '../Css/Home.css';
import {
    Form,
    Button
} from "react-bootstrap";
import {Link} from "react-router-dom";
import { useParams } from "react-router";
import {gutai_login, gutai_login_desktop, send_icon, tic_green, tic_grey} from "../img/img";
import LanguageSwitch from "../Components/LanguageSwitch";
import handleEndpointError from '../Functions/Alert';
import {NavigationContext} from "../Contexts";

const ResetPassword = () => {

    const {t} = useTranslation(['app'])

    // Résolution pour mobile
    const {isMobile, isTablet} = useContext(NavigationContext)

    const [send, setSend] = useState(false)
    const [plainPassword, setPlainPassword] = useState(null)
    const [confirmationPassword, setConfirmationPassword] = useState(null)
    const [samePassword, setSamePassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const {confirmationToken} = useParams()


    const handleChange = (event) => {
        switch(event.target.name) {
            case 'plainPassword':
                if(checkPassword(event.target)) {
                    setPlainPassword(event.target.value)
                    setSamePassword(event.target.value === confirmationPassword)
                }
                break
            case 'confirmationPassword':
                if(checkPassword(event.target)) {
                    setConfirmationPassword(event.target.value)
                    setSamePassword(event.target.value === plainPassword)
                }
                break
            default:
                break
        }
    }


    const checkPassword = (inputTarget) => {
        let pwdformat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{8,}$/
        let pwdMaj = /^(?=.*[A-Z])/
        let pwdMin = /^(?=.*[a-z])/
        let pwdLong = /^.{8,}/
        let pwdSpe = /^(?=.*\W)/
        let pwdNum = /^(?=.*[0-9])/
        
        let inputTargetValue = inputTarget.value

        if(inputTargetValue.match(pwdMaj)) {
            document.getElementById("ticGrey-checkMaj").setAttribute("style", "display:none!important")
            document.getElementById("ticGreen-checkMaj").setAttribute("style", "display:block!important")
        } else if (!inputTargetValue.match(pwdMaj)) {
            document.getElementById("ticGrey-checkMaj").setAttribute("style", "display:block!important")
            document.getElementById("ticGreen-checkMaj").setAttribute("style", "display:none!important")
        }

        if(inputTargetValue.match(pwdMin)) {
            document.getElementById("ticGrey-checkMin").setAttribute("style", "display:none!important")
            document.getElementById("ticGreen-checkMin").setAttribute("style", "display:block!important")
        } else if (!inputTargetValue.match(pwdMin)) {
            document.getElementById("ticGrey-checkMin").setAttribute("style", "display:block!important")
            document.getElementById("ticGreen-checkMin").setAttribute("style", "display:none!important")
        }

        if(inputTargetValue.match(pwdLong)) {
            document.getElementById("ticGrey-checkLong").setAttribute("style", "display:none!important")
            document.getElementById("ticGreen-checkLong").setAttribute("style", "display:block!important")
        } else if (!inputTargetValue.match(pwdLong)) {
            document.getElementById("ticGrey-checkLong").setAttribute("style", "display:block!important")
            document.getElementById("ticGreen-checkLong").setAttribute("style", "display:none!important")
        }

        if(inputTargetValue.match(pwdSpe)) {
            document.getElementById("ticGrey-checkSpe").setAttribute("style", "display:none!important")
            document.getElementById("ticGreen-checkSpe").setAttribute("style", "display:block!important")
        } else if (!inputTargetValue.match(pwdSpe)) {
            document.getElementById("ticGrey-checkSpe").setAttribute("style", "display:block!important")
            document.getElementById("ticGreen-checkSpe").setAttribute("style", "display:none!important")
        }

        if(inputTargetValue.match(pwdNum)) {
            document.getElementById("ticGrey-checkNum").setAttribute("style", "display:none!important")
            document.getElementById("ticGreen-checkNum").setAttribute("style", "display:block!important")
        } else if (!inputTargetValue.match(pwdNum)) {
            document.getElementById("ticGrey-checkNum").setAttribute("style", "display:block!important")
            document.getElementById("ticGreen-checkNum").setAttribute("style", "display:none!important")
        }

        return  inputTargetValue.match(pwdformat) ? true : false
    }


    const handleSubmit = (event) => {

        let locale = document.getElementById("react-switch-new").checked ? 1 : 2

        axios
            .post(
                config+"public/api/reset_password",
                {
                    token: confirmationToken,
                    plain_password: plainPassword,
                    confirm_password: confirmationPassword,
                    locale: locale
                },
            )
            .then(response => {
                if (response.status === 200) {
                    setSend(true)
                    setSuccessMessage(t('app:reset_password.password_changed'))
                    setErrorMessage(null)
                }
            })
            .catch(error => {
                if(error.response.status === 400) {
                    let message = error.response.data.errors
                    setErrorMessage(message)
                }
                else {
                    handleEndpointError(error)
                }
            })
    }


    return (
        <div className={(isTablet || isMobile) ? "" : "d-flex justify-content-end background-velo"}>
            <LanguageSwitch />
            <div className={isMobile ? "pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-30 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                <div className={isMobile ? "mb-auto mt-auto w-100" : "w-100"}>
                    <Link to={"/"}>
                        <img
                            src={isMobile ? gutai_login : gutai_login_desktop}
                            className={isMobile ? "logo-gutai-home" : "logo-gutai-home-desktop"}
                            alt="Gutai logo"
                        />
                    </Link>
                </div>
                {errorMessage ?
                    <div className="error-login-text mcb-3">
                        {errorMessage}
                    </div>: <></>
                }
                {successMessage ?
                    <div className="success-login-text mcb-3">
                        {successMessage}
                    </div>: <></>
                }
                {!send &&
                <>
                    <p className={isMobile ? "pwd-forgot-text mcb-5" : "pwd-forgot-text pwd-forgot-text-desktop mcb-5"}>{t('app:forgot_password.new_password')}</p>
                    <div id="form-reset" className={isMobile ? "mb-2 w-100 mct-3" : "w-100"}>
                        <Form>
                            <Form.Control className="form-login-input pcl-3 py-0 mb-2"
                                          type="password"
                                          name="plainPassword"
                                          onChange={handleChange}
                                          placeholder={t('app:reset_password.new_password_input')}
                                          required
                            >
                            </Form.Control>
                            <div id="check-pwd" className="w-100 text-left mcb-4 mcl-5">
                                <div id="checkLong" className="d-flex align-items-center mcb-1">
                                    <img
                                        id="ticGrey-checkLong"
                                        src={tic_grey}
                                        alt="tic grey picto"
                                        className="icon-12"
                                    />
                                    <img
                                        id="ticGreen-checkLong"
                                        src={tic_green}
                                        alt="tic grey picto"
                                        className="icon-12 d-none"
                                    />
                                    <p className="validate-pwd mb-0 mcl-2">
                                        {t('app:register.8_caracteres')}
                                    </p>
                                </div>
                                <div id="checkMin" className="d-flex align-items-center mcb-1">
                                    <img
                                        id="ticGrey-checkMin"
                                        src={tic_grey}
                                        alt="tic grey picto"
                                        className="icon-12"
                                    />
                                    <img
                                        id="ticGreen-checkMin"
                                        src={tic_green}
                                        alt="tic grey picto"
                                        className="icon-12 d-none"
                                    />
                                    <p className="validate-pwd mb-0 mcl-2">
                                        1 {t('app:register.minuscule')}
                                    </p>
                                </div>
                                <div id="checkMaj" className="d-flex align-items-center mcb-1">
                                    <img
                                        id="ticGrey-checkMaj"
                                        src={tic_grey}
                                        alt="tic grey picto"
                                        className="icon-12"
                                    />
                                    <img
                                        id="ticGreen-checkMaj"
                                        src={tic_green}
                                        alt="tic grey picto"
                                        className="icon-12 d-none"
                                    />
                                    <p className="validate-pwd mb-0 mcl-2">
                                        1 {t('app:register.majuscule')}
                                    </p>
                                </div>
                                <div id="checkNum" className="d-flex align-items-center mcb-1">
                                    <img
                                        id="ticGrey-checkNum"
                                        src={tic_grey}
                                        alt="tic grey picto"
                                        className="icon-12"
                                    />
                                    <img
                                        id="ticGreen-checkNum"
                                        src={tic_green}
                                        alt="tic grey picto"
                                        className="icon-12 d-none"
                                    />
                                    <p className="validate-pwd mb-0 mcl-2">
                                        1 {t('app:register.number')}
                                    </p>
                                </div>
                                <div id="checkSpe" className="d-flex align-items-center mcb-1">
                                    <img
                                        id="ticGrey-checkSpe"
                                        src={tic_grey}
                                        alt="tic grey picto"
                                        className="icon-12"
                                    />
                                    <img
                                        id="ticGreen-checkSpe"
                                        src={tic_green}
                                        alt="tic grey picto"
                                        className="icon-12 d-none"
                                    />
                                    <p className="validate-pwd mb-0 mcl-2">
                                        1 {t('app:register.spec_carac')} (%, #, -, ?, !)
                                    </p>
                                </div>
                            </div>
                            <Form.Control className="form-login-input pcl-3 py-0 mb-2"
                                          type="password"
                                          name="confirmationPassword"
                                          onChange={handleChange}
                                          placeholder={t('app:reset_password.new_password_input_confirm')}
                                          required
                            >

                            </Form.Control>
                        </Form>
                        <div className="flex-column">
                            {samePassword && plainPassword && confirmationPassword &&
                            <Button id="login-btn" type="submit"
                                    className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center forgot-password-btn mct-5'
                                    onClick={handleSubmit}>
                                <img className="mcr-2 icon-24" alt="send icon" src={send_icon} />
                                <p className="my-auto home-btn-p-text">{t('app:forgot_password.send')}</p>
                            </Button>
                            }
                        </div>
                    </div>
                </>
                }

                <Link to={"/login"} className={isMobile ? "mb-auto mt-0 mt-1 text-decoration-none mct-4" : "text-decoration-none"}>
                    <Button className='mx-auto home-btn-white home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center forgot-password-btn mct-3'>
                        <p className="my-auto home-btn-p-text">{t('app:forgot_password.back')}</p>
                    </Button>
                </Link>
            </div>
        </div>
    )


}
export default ResetPassword