import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {HeaderHome} from "../../Components/Home/HeaderHome";
import {Button} from "react-bootstrap";
import {
    trifonction,
    capteurs,
    shopping_bag, maillot_ete
} from "../../../src/img/img";
import {
    LoggedInContext,
    NavigationContext,
    SplashScreenSettingContext,
    WeekCalendarContext
} from "../../Contexts";
import '../../Css/subscription.css';
import ProgramPurchaseConfirmation from "./ProgramPurchaseConfirmation";
import SubscriptionPurchaseConfirmation from "./SubscriptionPurchaseConfirmation";
import { useParams } from "react-router-dom";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import Loader from "react-loader-spinner";
import { AnimateOnChange } from 'react-animation'
import {format as formatDate} from "date-fns";

const PurchaseConfirmation = (props) => {
    // Traduction
    const {t} = useTranslation(['app']);
    const {gender} = useContext(LoggedInContext)
    const {updateDayToOpen} = useContext(WeekCalendarContext)
    const {updateShowSubscribeAutonom} = useContext(SplashScreenSettingContext)

    let {purchaseId} = useParams();

    const [loaded, setLoaded] = useState(false)
    const [programDatas, setProgramDatas] = useState(null)
    const [subscriptionDatas, setSubscriptionDatas] = useState(null)

    const [shopLinks, setShopLinks] = useState({
            running: "https://gutai-training.com/categorie-produit/homme/vetements-homme/running-homme/",
            cycle: "https://gutai-training.com/categorie-produit/divers/capteurs-de-puissance/",
            triathlon: "https://gutai-training.com/categorie-produit/homme/vetements-homme/triathlon-homme/"
        }
    )

    useEffect(() => {
        getPurchase()

        // Ne pas afficher le splashScreen "S'abonner en AUTONOM" -- dans le Dashboard | Déclencher en manuel, pour éviter de faire un call API qui permet de récupérer la valeur du réglage
        updateShowSubscribeAutonom(false)

    }, [])

    const getPurchase = () => {
        axios.get(
            config + "api/purchase/"+purchaseId,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            if (response.data.has_training_20) {
                let userProgramDatas = response.data.lines[0].user_training_program
                let startDate = new Date(userProgramDatas.start)
                let datas = {
                    name: userProgramDatas.name,
                    start_date: startDate
                }
                setProgramDatas(datas)
                updateDayToOpen(formatDate(startDate, "yyyy-MM-dd"))
            } else {

                let dataLines = response.data.lines
                let datas = {
                    date: new Date(response.data.delivered_date),
                    subscription: dataLines[0].product_name,
                    options: []
                }
                dataLines.splice(0,1)
                for(let dataLine of dataLines) {
                    datas.options.push(dataLine.product_name)
                }
                setSubscriptionDatas(datas)

            }
            setLoaded(true)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    useEffect(() => {
        if (gender === 2) {
            setShopLinks((oldLinks) => {
                let newLinks = {...oldLinks}
                newLinks.running = "https://gutai-training.com/categorie-produit/femme/vetements-femme/running-femme/"
                newLinks.triathlon = "https://gutai-training.com/categorie-produit/femme/vetements-femme/triathlon-femme/"
                return newLinks
            })
        }
    }, [])

    const {isMobile} = useContext(NavigationContext)
    return (
        <div className={isMobile ? "" : "min-vh-100 d-flex justify-content-end background-checkout-confirmation"}>
            <div className={isMobile ? "pc-4 d-flex flex-column justify-content-center align-items-center container" : "w-40 pc-4 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                <HeaderHome withoutLanguageSwitch={1}/>
                <h3 className={isMobile ? "mb-2 mt-5 subscription-title":"subscription-title" }>{t("confirmation_page.title")}</h3>
                <AnimateOnChange
                    animationIn="bounceIn"
                    animationOut="bounceOut"
                    durationOut={500}>
                    {loaded ?
                        <>
                            {programDatas &&
                            <ProgramPurchaseConfirmation programDatas={programDatas}/>
                            }
                            {subscriptionDatas &&
                            <SubscriptionPurchaseConfirmation subscriptionDatas={subscriptionDatas}/>
                            }
                            <hr/>
                            <h3 className={isMobile ? " mct-3 w-100 subscription-title" : "mct-3 w-100 subscription-title"}>{t("confirmation_page.equip_yourself")}</h3>
                            <div className={isMobile ? "row d-flex flex-column mt-3" : "row d-flex mt-3"}>
                                <div className="col px-0 radius-total form-checkout-blue align-items-center mr-3 mb-3">
                                    <img className="pcx-2 w-100 mct-2" src={maillot_ete}/>
                                    <p className="text-subscription-confirmation">{t("confirmation_page.summer_jersey")}</p>
                                    <Button id="login-btn" href={shopLinks.running}
                                            className='mcx-2 home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-3'>
                                        <img className="mcr-2 icon-24" alt="log-in icon" src={shopping_bag}/><p
                                        className="my-auto home-btn-p-text">Running</p>
                                    </Button>
                                </div>
                                <div className="col px-0 radius-total form-checkout-blue mr-3 mb-3">
                                    <img className="pcx-2 w-100 mct-2" src={capteurs}/>
                                    <p className="text-subscription-confirmation">{t("confirmation_page.power_sensors")}</p>
                                    <Button id="login-btn" href={shopLinks.cycle}
                                            className='mcx-2 home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-3'>
                                        <img className="mcr-2 icon-24" alt="log-in icon" src={shopping_bag}/><p
                                        className="my-auto home-btn-p-text">Cyclisme</p>
                                    </Button>
                                </div>
                                <div className="col px-0 radius-total form-checkout-blue mr-3 mb-3">
                                    <img className="pcx-2 w-100 mct-2" src={trifonction}/>
                                    <p className="text-subscription-confirmation">{t("confirmation_page.trifunction")}</p>
                                    <Button id="login-btn" href={shopLinks.triathlon}
                                            className='mcx-2 home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-3'>
                                        <img className="mcr-2 icon-24" alt="log-in icon" src={shopping_bag}/><p
                                        className="my-auto home-btn-p-text">Triathlon</p>
                                    </Button>
                                </div>
                            </div>
                        </>
                        :
                        <Loader
                            type="TailSpin"
                            color="#0069d9"
                            height={50}
                            width={50}
                            className="my-5"
                        />
                    }
                </AnimateOnChange>
            </div>
        </div>
    )
}
export default PurchaseConfirmation