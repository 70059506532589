export const TrainingProgramTypes = {
    PROGRESSION: 0,
    COMPETITION: 1
}

export const TrainingProgramSports = {
    TRIATHLON: 1,
    RUNNING: 2,
    TRAIL: 3,
    CYCLISME: 4,
    DUATHLON: 5,
    RAID: 6
}