import Axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import config from '../../../../api'
import { TrainingConceptConfigContext } from '../../../../Contexts'
import handleEndpointError from '../../../../Functions/Alert'
import generateHeaders from '../../../../Functions/generateHeaders'
import { arrow_left } from '../../../../img/img'
import { getTrainingConceptByContextCode } from '../../../Context/Utils'
import { TrainingConceptCodes } from '../../../TrainingConcept/Utils'
import ActivitySessionChart from './ActivitySessionChart'

const ActivitySessionChartLandscapeContainer = (props) => {
  const { activitySessionId } = useParams()
  const history = useHistory()
  const { t } = useTranslation(['app'])
  const trainingContextConfigValue = useContext(TrainingConceptConfigContext)

  const [data, setData] = useState(null)
  const [activitySession, setActivitySession] = useState(null)
  const [contextCode, setContextCode] = useState(null)
  const [trainingConceptCode, setTrainingConceptCode] = useState(null)

  useEffect(() => {
    if (activitySessionId) {
      getActivitySession()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitySessionId])

  useEffect(() => {
    if (activitySession) {
      setContextCode(activitySession.context ? activitySession.context.code : null)
    }
  }, [activitySession])

  useEffect(() => {
    if (contextCode) {
      let trainingConcept = getTrainingConceptByContextCode(contextCode, trainingContextConfigValue)
      setTrainingConceptCode(trainingConcept ? trainingConcept.training_concept_code : TrainingConceptCodes.CRITICAL_INTENSITY)
    }
  }, [contextCode, trainingContextConfigValue])

  useEffect(() => {
    if (trainingConceptCode) {
      getChartData('primary_zones', 'duration')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingConceptCode])

  const getActivitySession = () => {
      const headers = generateHeaders()
      Axios
        .get(
          `${config}api/activity_sessions/${activitySessionId}`,
          { headers: { 'X-WSSE': headers } },
        )
        .then(response => {
          setActivitySession(response.data)
        })
        .catch(error => {
          handleEndpointError(error)
        })
  }
  
  const getChartData = (pathType, xAxis) => {
    const ease = Math.round(activitySession.duration / 18000)
    Axios.get(
      `${config}api/activity_sessions/${activitySessionId}/chart`,
      {
        headers: { 'X-WSSE': generateHeaders() },
        params: {
          chart_type: pathType,
          training_concept: trainingConceptCode,
          ease: ease * ease,
          x_axis: xAxis
        }
      },
    ).then((response) => {
      setData(response.data)
    })
    .catch(error => {
      handleEndpointError(error)
    })
  }

  return activitySessionId && (
    <div className="landscape">
      {/* <Container fluid className="pr-3 pl-3 pb-3"> */}
          <div className="d-flex justify-content-start">
              <div className="d-flex align-items-center p-3 pointer" onClick={() => history.goBack()}>
                  <img
                    className="icon-24 mcr-2"
                    src={arrow_left}
                    alt="arrow left icon"
                  />
                  <h2 className="seance-title2 mb-0">
                    {t('app:intensity.intensities')}
                  </h2>
              </div>
          </div>
          <div className="d-flex justify-content-center content">
            {data ? (
              <ActivitySessionChart
                datasChart={data}
                trainingConceptCode={trainingConceptCode}
                xAxis="duration"
                discipline={activitySession.discipline.code}
                isLandscape
                activitySessionId={activitySessionId}
              />
            ) : (
              <ClipLoader
                color="#0079FF"
                loading
                size={40}
                css={css`position: absolute; top: 40%;`}
              />
            )}
          </div>
    </div>
  )
}

export default ActivitySessionChartLandscapeContainer