import React from "react";
import {
    cap_set,
    nat_set,
    velo_set,
    check_circle,
    message_white,
    smile_wellness,
    rss, calendar, balance, file_plus,
    kayak_set, muscu_set, ski_set, vtt_set
} from "../../img/img";

const DisciplineCube = ({discipline}) => {
    let bcolor = "btn-outline-nat bcolor-nat"
    let disciplineIcon
    switch (discipline) {
        default:
        case 'run':
        case 'cap':
            bcolor = "btn-outline-cap bcolor-cap"
            disciplineIcon = cap_set
            break
        case 'cycle':
        case 'velo':
            bcolor = "btn-outline-velo bcolor-velo"
            disciplineIcon = velo_set
            break
        case 'swim':
        case 'nat':
            disciplineIcon = nat_set
            break
        case 'kayak':
            bcolor = null
            disciplineIcon = kayak_set
            break
        case 'muscu':
        case 'renfo':
            bcolor = null
            disciplineIcon = muscu_set
            break
        case 'ski-fond':
        case 'ski-alpin':
        case 'ski-rando':
            bcolor = null
            disciplineIcon = ski_set
            break
        case 'vtt':
            bcolor = null
            disciplineIcon = vtt_set
            break
        case 'Debriefing':
            disciplineIcon = check_circle
            break
        case 'AthleteWellness':
            disciplineIcon = smile_wellness
            break
        case 'AthleteWeight':
            disciplineIcon = balance
            break
        case 'Activity':
            disciplineIcon = rss
            break
        case 'ActivitySessionPerformance':
            disciplineIcon = cap_set
            break
        case 'AthleteCalendar':
        case 'AthleteAvailability':
            disciplineIcon = calendar
            break
        case 'Message':
        case 'nothing':
            disciplineIcon = message_white
            break
        case 'TrainingProgram':
            disciplineIcon = file_plus
            break
    }

    return (
        <div className={"agenda-cube "+bcolor+" d-flex justify-content-center my-auto px-2"}  >
            <img
                src={disciplineIcon}
                alt={discipline+" set icon"}
                className="my-auto icon-24 "
            />
        </div>
    )
}

export default DisciplineCube