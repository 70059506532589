import React, {useEffect, useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import Loader from "react-loader-spinner";
import axios from "axios";
import config from "../api";
import generateHeaders from "../Functions/generateHeaders";
import handleEndpointError from '../Functions/Alert';
import {NavigationContext, WeekCalendarContext} from "../Contexts";
import {TrainingProgramTypes, TrainingProgramSports} from "../SearchTrainingProgram/Utils";
import {AnimateOnChange} from 'react-animation'

const UserTrainingProgram = () => {

    const {t} = useTranslation(['app']);

    const {isMobile, isTablet} = useContext(NavigationContext)
    const {updateDayToOpen} = useContext(WeekCalendarContext)

    const [isNotEmpty, setIsNotEmpty] = useState(true)
    const [userTrainingProgram, setUserTrainingProgram] = useState(false)
    const [backgroundStyle, setBackgrounStyle] = useState(null)
    const [progressBarStyle, setProgressBarStyle] = useState(null)

    const getUserTrainingPrograms = async () => {
        await axios.get(
            config+"api/my_subscription/programs",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(async (response) => {

            let finishedUtp = response.data.finished
            let currentUtp = response.data.current
            let nextUtp = response.data.next

            if(finishedUtp === null && currentUtp === null && nextUtp === null) {
                setIsNotEmpty(false)
                return 0
            }

            let utp = finishedUtp
            let isComplete = true
            let isNotStarted = false
            let cta = t("app:user_training_program.cta.next_program")
            let progressWidth = '100%'
            if(currentUtp) {
                utp = currentUtp
                isComplete = false
                progressWidth = Math.round(100 *(utp.duration_done/utp.duration_volume))+"%"
            }
            else if(nextUtp) {
                utp = nextUtp
                isComplete = false
                cta = t("app:user_training_program.cta.show_first_week")
                progressWidth = null
            }

            let type = utp.type
            let sport = utp.sport
            let title = utp.name ? utp.name : utp.sport_label
            let durationName = utp.duration_name
            let volume = utp.volume
            let sportOption = utp.with_test
            let progressLegend = null
            let startedLegend = isComplete ? t("app:user_training_program.legend.done") : null
            let background_image = utp.image ? utp.image : "https://gutai-training.com/wp-content/uploads/2021/06/background_7.png"

            if(!isComplete && utp.remaining_day_before_start)
            {
                progressLegend = t("app:user_training_program.legend.will_start")+utp.remaining_day_before_start
                isNotStarted = true
            }
            if(type === TrainingProgramTypes.COMPETITION) {
                title += " "+ utp.sport_option
                if(!isComplete && utp.remaining_day_before_end) {
                    progressLegend = null
                    startedLegend = t("app:user_training_program.legend.competition_start")+utp.remaining_day_before_end
                }
            }
            else {
                title += " "+ utp.sport_label
                sportOption = utp.sport_option + (sportOption ? (" " + sportOption) : "")
                if(!isComplete && !utp.remaining_day_before_start) {
                    progressLegend = "/"+utp.duration_volume
                    startedLegend = t("app:user_training_program.legend.week")+" "+utp.duration_done
                }
            }

            let progressBarColorClass = "bcolor-black"
            let progressColor = "color-black"
            switch(sport) {
                case TrainingProgramSports.TRIATHLON:
                case TrainingProgramSports.RAID:
                case TrainingProgramSports.DUATHLON:
                    progressBarColorClass = "bcolor-multi"
                    progressColor = "color-multi"
                    break;
                case TrainingProgramSports.RUNNING:
                case TrainingProgramSports.TRAIL:
                    progressBarColorClass = "bcolor-cap"
                    progressColor = "color-cap"
                    break;
                case TrainingProgramSports.CYCLISME:
                    progressBarColorClass = "bcolor-velo"
                    progressColor = "color-velo"
                    break;
                default:
                    break;
            }
            setProgressBarStyle({
                progressBarColorClass: progressBarColorClass,
                progressColor: progressColor
            })

            setUserTrainingProgram({
                sport: sport,
                title: title,
                durationName: durationName,
                volume: volume,
                sportOption: sportOption,
                progressWidth: progressWidth,
                progressLegend: progressLegend,
                startedLegend: startedLegend,
                isNotStarted : isNotStarted,
                isComplete: isComplete,
                startDate: utp.start_date,
                cta: cta
            })


            let styleDesktop = {
                background: `url(${background_image}) no-repeat top 10px left `+(isTablet ?"-20px":"-30px"),
                // backgroundSize: (isTablet ? "37%":"50%") +" auto"
                backgroundSize: "auto 87%"
            }
            setBackgrounStyle(styleDesktop)

        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    useEffect(() => {
        getUserTrainingPrograms()
    }, [])


    const onCtaClick = () => {
        if(userTrainingProgram.isNotStarted) {
            window.scrollTo(0,0)
            // Ouvrir le calendrier à la date de début
            updateDayToOpen(userTrainingProgram.startDate)
        }
    }


    if(null === userTrainingProgram) {
        return <></>
    }

    return (
        <>
            {isNotEmpty &&
            <div className={"dashboard-card pc-2 "+(isMobile || isTablet ? 'mb-3':"mt-3")} style={backgroundStyle}>
                <AnimateOnChange
                    animationIn="bounceIn"
                    animationOut="bounceOut"
                    durationOut={500}
                >
                {userTrainingProgram ?
                    <div className="text-center" style={{paddingLeft: '100px'}}>
                        <p className="utp-title mb-3">{userTrainingProgram.title}</p>
                        <p className="utp-text">{userTrainingProgram.durationName}</p>
                        <p className="utp-text">{userTrainingProgram.volume}</p>
                        <p className="utp-text mb-3">{userTrainingProgram.sportOption}</p>
                        <div className="utp-progress-empty m-auto">
                            {userTrainingProgram.progressWidth &&
                                <span className={progressBarStyle.progressBarColorClass + " utp-progress-started "+ (userTrainingProgram.isComplete ?'utp-complete':'')}
                                      style={{width: userTrainingProgram.progressWidth}}
                                >
                                </span>
                            }
                        </div>
                        <p className="utp-progress-legend mt-1">
                            <span className={progressBarStyle.progressColor+" utp-progress-started-legend"}>
                                {userTrainingProgram.startedLegend}
                            </span>
                            {userTrainingProgram.progressLegend}
                        </p>
                        {userTrainingProgram.isNotStarted ?
                            <p className="utp-cta cursor-pointer"
                               onClick={() => onCtaClick()}
                            >
                                {userTrainingProgram.cta}
                            </p>
                            :
                            <a href={"https://gutai-training.com/programs-running-cyclisme-triathlon/"}
                               target="_blank"
                               className="utp-cta cursor-pointer"
                            >
                                {userTrainingProgram.cta}
                            </a>
                        }
                    </div>
                    :
                    <Loader
                        type="TailSpin"
                        color="blue"
                        height={50}
                        width={50}
                        className="my-5"
                    />
                }
                </AnimateOnChange>
            </div>
            }
        </>
    )
}

export default UserTrainingProgram
