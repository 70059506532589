import React, {useEffect, useState, useContext} from 'react';
import "../../Css/TrainingZones.css"
import Loader from "react-loader-spinner";
import TrainingZonesContexts from "./TrainingZonesContexts";
import TrainingZonesDiscipline from "./TrainingZonesDiscipline";
import generateHeaders from "../../Functions/generateHeaders";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import {MetricContext, PrioritySportContext} from "../../Contexts";
import TrainingZonesPacingTab from "./TrainingZonesPacingTab";
import {getDisciplineAnalyzable} from "../Discipline/Utils";
import {ContextCodes, getContextsByDiscipline} from "../Context/Utils";
import {DataZones} from "./Utils";
import {TrainingConceptCodes} from "../TrainingConcept/Utils";
import PerformancePrediction from "./PerformancePrediction";
import BestRecords from "./BestRecords";
import EnduranceIndex from "./EnduranceIndex/EnduranceIndex";

const TrainingZones = () => {
    const {prioritySport} = useContext(PrioritySportContext)

    // métrique utilisateur
    const {metric} = useContext(MetricContext)
    const disciplines = getDisciplineAnalyzable()

    const [discipline, setDiscipline] = useState(null)
    const [context, setContext] = useState(null)
    const [pathType, setPathType] = useState('primary_zones')


    const [crititalIntensityPacingValues, setCrititalIntensityPacingValues] = useState(null)
    const [crititalHeartratePacingValues, setCrititalHeartratePacingValues] = useState(null)
    const [dataPacingValues, setDataPacingValues] = useState(null)

    // Boolean qui empeche l'affichage avant le chargement des données
    const [loaded, setLoaded] = useState(false)

    // Déclencher la récupération des données
    const [loadData, setLoadData] = useState(null)

    useEffect(() => {
        if (prioritySport) {
            setDiscipline(prioritySport)
        } else {
            setDiscipline(disciplines[0])
        }

    }, [prioritySport])

    useEffect(() => {
        triggerLoadData()
    }, [metric])

    useEffect(() => {
        if(loadData) {
            // Récupérer les allures des zones d'intensités critiques
            getTrainingZones(TrainingConceptCodes.CRITICAL_INTENSITY)
            // Récupérer les allures des zones de FC critique
            getTrainingZones(TrainingConceptCodes.CRITICAL_HEARTRATE)
            setLoadData(false)
        }
    }, [loadData])

    useEffect(() => {
        if(crititalIntensityPacingValues && crititalHeartratePacingValues) {
            let values = []
            values[TrainingConceptCodes.CRITICAL_INTENSITY] = crititalIntensityPacingValues
            values[TrainingConceptCodes.CRITICAL_HEARTRATE] = crititalHeartratePacingValues

            setDataPacingValues(values)
            onChangeLoaded(true)
        }
    }, [crititalIntensityPacingValues, crititalHeartratePacingValues])


    const getTrainingZones  = async (zoneType) => {

        await axios.get(
            config+"api/my_training_zones?zone_type="+zoneType,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        ).then(response => {

            let data = response.data

            let results = []

            let disciplines = getDisciplineAnalyzable()
            for(let d of disciplines) {
                let contextCodes =  getContextsByDiscipline(d)
                for (let contextCode of contextCodes) {
                    results[contextCode] = []
                    for (let dataZone of DataZones) {
                        let zoneCode = zoneType === TrainingConceptCodes.CRITICAL_HEARTRATE ? dataZone.hr_code : dataZone.code

                        // Passer à la zone suivante si elle n'existe pas pour le concept d'entraînement
                        if(zoneCode === null)
                            continue

                        // Passer à la zone suivante si elle ne concerne pas la discipline
                        if(dataZone.disciplines_only !== null && dataZone.disciplines_only.indexOf(d) === -1)
                            continue

                        let zoneName = dataZone.name

                        results[contextCode][zoneName] = []

                        if ([ContextCodes.SWIMMING_POOL_25, ContextCodes.SWIMMING_POOL_33, ContextCodes.SWIMMING_POOL_50, ContextCodes.SWIMMING_NO_POOL,
                            ContextCodes.RUNNING_INDOOR,
                            ContextCodes.CYCLING_INDOOR
                        ].indexOf(contextCode) !== -1) {
                            let existingIntensity = data[d].contexts[contextCode].zones[zoneType].id === null ? false : true
                            results[contextCode][zoneName][0] = existingIntensity ? data[d].contexts[contextCode].zones[zoneCode]['pacing_value'] : ''
                        }
                        else {
                            let existingIntensity = data[d].contexts[contextCode+ '-flat'].zones[zoneType].id === null ? false : true
                            results[contextCode][zoneName][0] = existingIntensity ? data[d].contexts[(contextCode + '-flat')].zones[zoneCode]['pacing_value'] : ''
                            existingIntensity = data[d].contexts[contextCode+ '-ascent'].zones[zoneType].id === null ? false : true
                            results[contextCode][zoneName][1] = existingIntensity ? data[d].contexts[(contextCode + '-ascent')].zones[zoneCode]['pacing_value'] : ''
                        }
                    }
                }
            }

            switch (zoneType){
                case TrainingConceptCodes.CRITICAL_HEARTRATE:
                    setCrititalHeartratePacingValues(results)
                    break
                case TrainingConceptCodes.CRITICAL_INTENSITY:
                default:
                    setCrititalIntensityPacingValues(results)
                    break
            }
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const triggerLoadData = () => {
        setLoadData(true)
    }

    const onChangeDiscipline = (disciplineToSet) => {
        setDiscipline(disciplineToSet)
    }

    const onChangeContext = (contextToSet) => {
        setContext(contextToSet)
    }

    const onChangeLoaded = (loadedState) => {
        setLoaded(loadedState)
    }

    const onChangePathType = (pathTypeToSet) => {
        setPathType(pathTypeToSet)
    }

    return (
        <div id="zs-card" className="w-100 pcb-5 mcb-6">
            {loaded ?
                <>
                    <TrainingZonesDiscipline discipline={discipline}
                                             onChangeDiscipline={(disciplineToSet) => onChangeDiscipline(disciplineToSet)}
                                             disciplines={disciplines}
                    />
                    <TrainingZonesContexts discipline={discipline}
                                           onChangeContext={(context) => onChangeContext(context)}
                                           context={context}
                    />
                    {dataPacingValues &&
                    <>
                        <TrainingZonesPacingTab dataPacingValues={dataPacingValues}
                                                discipline={discipline}
                                                context={context}
                                                pathType={pathType}
                                                onChangePathType={(pathType) => onChangePathType(pathType)}
                                                triggerLoadData={() => triggerLoadData()}
                        />
                        {/*{discipline !== "nat" &&
                            <EnduranceIndex discipline={discipline} context={context}/>
                        }*/}

                        <BestRecords discipline={discipline}
                                          context={context}
                        />
                        <PerformancePrediction
                                        discipline={discipline}
                                        context={context}
                        />
                    </>
                    }
                </>
                :
                <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                    <Loader
                        type="TailSpin"
                        color="black"
                        height={50}
                        width={50}
                        className="my-5"
                    />
                </div>
            }
        </div>
    )
}

export default TrainingZones;