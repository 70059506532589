import React, {useEffect, useState, useContext} from "react";
import {useIsMounted} from "../../Tools/CustomHooks";
import {useTranslation} from "react-i18next";
import {Container, Col, Row} from "react-bootstrap";
import Loader from "react-loader-spinner";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";


import WorkoutContexts from "./WorkoutContexts"
import WorkoutSection from "./WorkoutSection"
import {
    GarminTrainingContext,
    NavigationContext,
    TrainingConceptConfigContext,
    WeekCalendarContext
} from "../../../Contexts";
import {getTrainingConceptByContextCode} from "../../Context/Utils";
import {TrainingConceptCodes} from "../../TrainingConcept/Utils";
import {logo_garmin_training_transparent, watch_sync, watch_sync_blue} from "../../../img/img";
import {getWorkoutColors} from "./Utils";
import moment from "moment";
import uniqid from "uniqid";
import watch_sync_white from "../../../img/watch_sync_white.png";

const Workout = ({workoutId, trainingEventType, garminSynced, trainingEventId, isSynchronizable}) => {

    const {t} = useTranslation(['app'])

    const trainingContextConfigValue = useContext(TrainingConceptConfigContext)

    const {updateCalendarEventToRefresh} = useContext(WeekCalendarContext)

    const {garminTrainingSynced} = useContext(GarminTrainingContext)

    const {isMobile} = useContext(NavigationContext)

    const isMounted = useIsMounted()

    const [workout, setWorkout] = useState(null)
    const [runTrainingConceptCode, setRunTrainingConceptCode] = useState(null)
    const [cycleTrainingConceptCode, setCycleTrainingConceptCode] = useState(null)
    const [swimTrainingConceptCode, setSwimTrainingConceptCode] = useState(null)
    const [opacolor, setOpacolor] = useState("")
    const [checkboxcolor, setCheckboxcolor] = useState("")
    const [isGarminSynced, setIsGarminSynced] = useState(garminSynced)
    const [onSync, setOnSync] = useState(false)

    const getWorkout = () => {
        const headers = generateHeaders()
        axios
            .get(
                config+"api/workouts/"+workoutId,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let w = response.data
                setWorkout(w)
                setOpacolor(getWorkoutColors(w).opacolor)
                setCheckboxcolor(getWorkoutColors(w).checkboxcolor)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    useEffect(() => {
        if(workoutId) {
            getWorkout()
        }
    }, [workoutId])


    useEffect(() => {
        if(workout && trainingContextConfigValue) {
            if(workout.run_context) {
                let trainingConcept = getTrainingConceptByContextCode(workout.run_context.code, trainingContextConfigValue)
                setRunTrainingConceptCode(trainingConcept ? trainingConcept.training_concept_code : TrainingConceptCodes.CRITICAL_INTENSITY)
            }
            if(workout.cycle_context) {
                let trainingConcept = getTrainingConceptByContextCode(workout.cycle_context.code, trainingContextConfigValue)
                setCycleTrainingConceptCode(trainingConcept ? trainingConcept.training_concept_code : TrainingConceptCodes.CRITICAL_INTENSITY)
            }
            if(workout.swim_context) {
                let trainingConcept = getTrainingConceptByContextCode(workout.swim_context.code, trainingContextConfigValue)
                setSwimTrainingConceptCode(trainingConcept ? trainingConcept.training_concept_code : TrainingConceptCodes.CRITICAL_INTENSITY)
            }
        }
    }, [workout, trainingContextConfigValue])



    const getNutritionalStrategiesForCategory = (categoryPosition) => {
        return workout.nutritional_strategies.filter(nutritionalStrategy => nutritionalStrategy.category.position === categoryPosition)
    }
    const showNutritionalStrategies = (nutritionalStrategies) => {
        return (
            nutritionalStrategies.map(nutritionalStrategy => <div className="mcb-4 text-p-seance" key={nutritionalStrategy.id}>{nutritionalStrategy.description}</div> )
        )
    }

    const getBeforeWorkout = () => {
        let nutritionalStrategies =  getNutritionalStrategiesForCategory(1)
        if(nutritionalStrategies.length == 0) {
            return <></>
        }
        return (
            <Row className="ml-0">
                <div className="seance-title2 pb-3 text-left">{t('app:workout.before')}</div>
                <Container fluid="md" className="px-0 text-left">
                    {showNutritionalStrategies(nutritionalStrategies)}
                </Container>
            </Row>
        )
    }

    const getDuringWorkout = () => {
        let nutritionalStrategies =  getNutritionalStrategiesForCategory(2)
        if(nutritionalStrategies.length == 0) {
            return <></>
        }
        return (
            <Row className="ml-0">
                <div className="seance-title2 pb-3 text-left">{t('app:workout.during')}</div>
                <Container fluid="md" className="px-0 text-left">
                    {showNutritionalStrategies(nutritionalStrategies)}
                </Container>
            </Row>
        )
    }

    const getAfterWorkout = () => {
        let nutritionalStrategies =  getNutritionalStrategiesForCategory(3)
        if(nutritionalStrategies.length == 0) {
            return <></>
        }
        return (
            <Row className="ml-0">
                <div className="seance-title2 pb-3 text-left">{t('app:workout.after')}</div>
                <Container fluid="md" className="px-0 text-left">
                    {showNutritionalStrategies(nutritionalStrategies)}
                </Container>
            </Row>
        )
    }


    if(!workout) {
        return(
            <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                <Loader
                    type="TailSpin"
                    color="black"
                    height={50}
                    width={50}
                    className="my-5"
                />
            </div>
        )
    }

    const changeGarminSynced = () => {
        setOnSync(true)
        exportWorkoutToGarminTraining()

    }



    // Gérer l'état de la synchronisation d'une séance et déclencher l'export vers Garmin Training
    const exportWorkoutToGarminTraining = () => {
        let headers = generateHeaders()
        if(headers){
            axios
                .put(
                    config+"api/workouts/"+workoutId+'/sync_garmin_training',
                    {
                        synced: !isGarminSynced
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    if(response.data.error) {
                        console.log('exportWorkoutToGarminTraining error',response.data.error)
                    }
                    setOnSync(false)
                    setIsGarminSynced(!isGarminSynced)
                    // Recharger le calendrier
                    updateCalendarEventToRefresh(uniqid("training-event-"+trainingEventId))
                })
                .catch(error => {
                    setOnSync(false)
                    handleEndpointError(error)
                })
        }
    }

    return (
        <>
            {garminTrainingSynced && isSynchronizable &&
            <div className="background-light-grey pct-4">
                <div className={"pc-3 garmin-synced-session "+opacolor}>
                    <div className={"w-100 checkbox-container checkbox-container-debriefing conditions-options-"+checkboxcolor}>
                        {onSync ?
                            <Loader
                                type="ThreeDots"
                                color="black"
                                height={50}
                                width={50}
                                className=""
                            />
                            :
                            <div className={`d-flex flex-column ${isMobile ? "w-100 fs-11 " : ""}`}>
                                <div className="d-flex align-items-center">
                                    <div>
                                        <b>{isGarminSynced ? t('workout.synchronized.1') : t('workout.synchronize.1')}</b>
                                        <img src={logo_garmin_training_transparent} alt="logo garmin training"
                                             className="mcx-1 pcb-1 logo-garmin-synced"/>
                                        {isGarminSynced &&
                                        t('workout.synchronized.2')
                                        }
                                    </div>
                                </div>
                                {!isGarminSynced &&
                                    <>
                                        <div className="text-left">
                                            {t('workout.synchronize.2')} <span className="font-weight-bold"> {t('workout.synchronize.3')}</span>
                                        </div>
                                        <div className="d-flex justify-content-start mct-3">
                                            <div className="btn-white-blue-nohover fs-12" onClick={() => changeGarminSynced()}>
                                                <img className="icon-24 mr-3" src={watch_sync_blue} alt="watch icon"/>
                                                {t('app:popup_synchronization.btn_synchronize')}
                                            </div>
                                        </div>

                                    </>
                                }
                            </div>

                        }
                    </div>

                </div>
            </div>

            }

            <Container fluid className="workoutCard pc-4">

                {workout.disciplines.length > 0 &&
                <WorkoutContexts
                    workout={workout}
                    getWorkout={() => getWorkout()}
                />
                }
                <div className="seance-title2 pb-3 text-left">{t('app:workout.description')}</div>
                <Row className="ml-2 mr-1 mb-2 justify-content-start d-flex text-left">
                    <Container fluid="md" className="px-0">
                        <p className="mcb-4 text-p-seance">{workout.description}</p>
                    </Container>
                </Row>
                <Row>
                    {workout.sections.map((section, index) => {
                        return (
                            <WorkoutSection
                                key={section.id}
                                t={t}
                                section={section}
                                isRealized={workout.state === 2}
                                runTrainingConceptCode={runTrainingConceptCode}
                                swimTrainingConceptCode={swimTrainingConceptCode}
                                cycleTrainingConceptCode={cycleTrainingConceptCode}
                            />
                        )
                    })}
                </Row>
                {getBeforeWorkout()}
                {getDuringWorkout()}
                {getAfterWorkout()}
            </Container>
        </>
    )
}
export default Workout;