import React, {useState} from "react";
import {Button} from "react-bootstrap";
// import moment from "moment";
import moment from "moment-timezone";
import {iconDiscipline} from "../Utils";
import {x_circle} from "../../../img/img";
import AthleteAvailabilityPanel from "./AthleteAvailabilityPanel";

/**
 * Afficher une disponibilité dans le calendrier
 *
 * @param athleteAvailability : disponibilité d'un athlète
 */
const AthleteAvailability = ({athleteAvailability, editAthleteAvailability, openAthleteAvailabilityModal}) => {

    // Indique si le panel est ouvert
    const [isPanelOpen, setIsPanelOpen] = useState(false)

    let hourHeight = 16
    let athleteAvailabilityKey = athleteAvailability.id
    let athleteAvailabilityStart = moment.tz(athleteAvailability.start_date, "Europe/Paris")
    let athleteAvailabilityEnd = moment.tz(athleteAvailability.end_date, "Europe/Paris")

    let duration = moment.duration(athleteAvailabilityEnd.diff(athleteAvailabilityStart))
    let hours = duration.asHours()

    let disciplines = []
    for (let i=0; i<athleteAvailability.contexts.length; i++) {
        let disciplineCode = athleteAvailability.contexts[i].discipline.code
        if (!disciplines.includes(disciplineCode)){
            disciplines.push(athleteAvailability.contexts[i].discipline.code)
        }
    }
    let height = (hours>23.5 ? 24*hourHeight : hours*hourHeight)+"px"
    let top = (((athleteAvailabilityStart.hour() + (athleteAvailabilityStart.minutes()/60))*hourHeight) + (hourHeight/2)) +"px"

    let showMiniIcon = hours < 2 ? true : false

    const closePanel = () => {
        setIsPanelOpen(false)
    }

    const openPanel = () => {
        setIsPanelOpen(true)
    }


    const panelClassName = athleteAvailabilityStart.isoWeekday() > 3 ? "dropdown-content-right" :"dropdown-content-left";

    return (
        <>
            <Button key={athleteAvailabilityKey} variant="outline-light"
                    className={"agenda-cube cube-white d-flex justify-content-center mx-auto px-0 mcb-3"}
                    style={{ height: height, position: "absolute", top: top, padding: 0 }}
                    onClick={() => openPanel()}
            >
                {iconDiscipline(false, disciplines, showMiniIcon)}
            </Button>
            {isPanelOpen &&
            <div style={{ height: height, position: "absolute", top: top, padding: 0 }}>
                <AthleteAvailabilityPanel athleteAvailability={athleteAvailability}
                                          panelClassName={panelClassName}
                                          closePanel={() => closePanel()}
                                          openAthleteAvailabilityModal={(date) => openAthleteAvailabilityModal(date)}
                    // editAthleteAvailability={() => editAthleteAvailability()}
                />
                <img
                    src={x_circle}
                    alt="close panel icon"
                    className="mcr-2 mct-1 icon-24"
                    onClick={(e) => {
                        e.stopPropagation()
                        closePanel()
                    }}
                />
            </div>
            }
        </>

    )
}

export default AthleteAvailability;
