import moment from "moment";
import i18n from "i18next";
import fr from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import {registerLocale, setDefaultLocale} from  "react-datepicker";

export function convertLocaleToLanguage (locale) {
    let language
    switch (locale) {
        case 1:
            language = 'en'
            break;
        case 2:
            language = 'fr'
            break;
        case 3:
            language = 'es'
            break;
        case 4:
            language = 'de'
            break;
        default:
            language = 'en'
            break;
    }

    return language
}

export function registerLanguage(language)
{
    // date-fns
    registerLocale('fr', fr)
    registerLocale('es', es)
    registerLocale('de', de)
    registerLocale('en', en)

    i18n.changeLanguage(language);
    moment.locale(language);
    setDefaultLocale(language)
    console.log('registerLanguage :'+language)
}