import React, {useState, useEffect, useContext} from 'react';
import {useTranslation} from "react-i18next";
import Loader from 'react-loader-spinner'
import {Button} from "react-bootstrap";

import {logo_stripe, validate, validate_blue, x_circle} from "../../img/img";
import {loadStripe} from "@stripe/stripe-js";
import {NavigationContext, StripeContext} from "../../Contexts";
import '../../Css/Settings.css';
import NewCartPayment from  "../../Subscription/NewCartPayment"
import {animateScroll} from "react-scroll";

function BankCardModal ({setPaymentMethod, updateBankCardModal}) {

    const {t} = useTranslation(['app'])
    const {isMobile} = useContext(NavigationContext)

    const [updatePaymentMethodLoading, setUpdatePaymentMethodLoading] = useState(false)
    const [isSubmitable, setIsSubmitable] = useState(false)
    const [submitForm, setSubmitForm] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [stripePromise, setStripePromise] = useState(null)

    const stripeContextValue = {
        stripePromise
    }

    useEffect(() => {
        setStripePromise(loadStripe(""+process.env.REACT_APP_STRIPE_PUBLIC_KEY))
        // setStripePromise(loadStripe(""+process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST))
    }, [])

    useEffect(() => {
        if(isMobile) {
            setTimeout(() => {
                animateScroll.scrollToTop()
            }, 200)
        }
    }, [isMobile])

    const updateReadyToSubmit = (value) => {
        setIsSubmitable(value)
    }

    const updatePaymentMethod = (value) => {
        setPaymentMethod(value)
        setUpdatePaymentMethodLoading(false)
        if(value) {
            updateBankCardModal('close')
        }
        else {
            setHasError(true)
            // top: '30px', minHeight: '450px',left: 0, width: '100%'
        }
    }

    const handleSubmit = () => {
        // Déclencher la création de la demande d'autorisation pour la carte bancaire
        setSubmitForm(true)
    }

    const clearBankCard = () => {
        updatePaymentMethod(false)
        updateReadyToSubmit(false)
        setSubmitForm(false)
    }

    return (
        <StripeContext.Provider value={stripeContextValue}>
            <div className={"settings-modal " + (isMobile ? ("bankcard-modal-mobile "+ (hasError ? "bankcard-modal-mobile-error" :"")) : "bankcard-modal")}
                 // style={isMobile ? { top: '150px', minHeight: "450px" } : { top: '450px', minHeight: "450px" }}
            >
                <p className="title-pref mct-3 pcx-3"
                   style={{'textAlign': 'left'}}
                >
                    {t('app:settings.subscription.change_bank_card')}
                    <img
                        className="icon-24 pointer"
                        src={x_circle}
                        alt="arrow left icon"
                        style={{position: 'absolute', right: '10px'}}
                        onClick={() => updateBankCardModal('close')}
                    />
                </p>
                {updatePaymentMethodLoading ?
                    <Loader
                        type="TailSpin"
                        color={'#007DFF'}
                        height={50}
                        width={50}
                        className="my-5"
                    />
                    :
                    <>
                        <img className="logo-stripe" src={logo_stripe}/>
                        <div className="pcx-3 pct-3">
                            <NewCartPayment
                                color={'blue'}
                                updateReadyToSubmit={(value) => updateReadyToSubmit(value)}
                                submitForm={submitForm}
                                updatePaymentMethod={(value) => updatePaymentMethod(value)}
                                clearBankCard={() => clearBankCard()}
                            />
                        </div>
                        <Button className={'modal-submit-btn d-flex link-btn mx-auto mcy-3 home-btn home-btn-text pcx-3 pcy-2 align-items-center justify-content-center'}
                                onClick={() => handleSubmit()}
                                disabled={!isSubmitable}
                        >
                            <img className="mcr-2 icon-24" alt="valid icon" src={validate} />
                            <p className="my-auto mx-auto home-btn-p-text">{t("app:settings.subscription.save_bank_card")}</p>
                        </Button>
                    </>
                }
            </div>
        </StripeContext.Provider>
    )
}

export default BankCardModal;