import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import generateHeaders from "../../Functions/generateHeaders";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import {
    emojiFatigue1,
    emojiFatigue2,
    emojiHumeur1,
    emojiHumeur2,
    emojiMuscle1,
    emojiMuscle2,
    emojiSommeil1,
    emojiSommeil2,
    emojiStress1,
    emojiStress2,
    validate,
    validate2,
    helpCircle
} from "../../img/img";
import {Button} from "react-bootstrap";
import WellnessBar from "./WellnessBar";
import WellnessScore from "./WellnessScore";
import ReactTooltip from "react-tooltip";
import {NavigationContext} from "../../Contexts";
import {getMessageWellnessType} from "./Utlis";

const Wellness = ({currentDate,openCloseWellnessEvolution}) => {

    const {t} = useTranslation(['app']);
    const {isMobile} = useContext(NavigationContext)


    const [wellnessStates, setWellnessStates] = useState({
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0
    })
    const [wellness, setWellness] = useState(null)
    const [isSet, setIsSet] = useState(false)
    const [defaultValues, setDefaultValues] = useState(true)
    const [bubbleOpen, setBubbleOpen] = useState()
    const [wellnessGeneralOpen, setWellnessGeneralOpen] = useState(false)
    const [wellnessScore, setWellnessScore] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [isInit, setIsInit] = useState(false)


    useEffect(() => {
        if (isOpen && !isInit) {
            getWellness()
            setIsInit(true)
        }
    }, [isOpen])

    const getWellness = () => {
        let headers = generateHeaders()
        if (headers) {
            axios
                .get(
                    config+"api/wellnesses?min_date="+currentDate+"&max_date="+currentDate,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    let wellnesses = response.data['wellnesses']

                    if (wellnesses.length){
                        let currentWellness = wellnesses[wellnesses.length-1]
                        setWellnessStates((oldStates) => {
                            let newStates = {...oldStates}
                            newStates[0] = currentWellness['mood']*25
                            newStates[1] = currentWellness['sleep_quality']*25
                            newStates[2] = currentWellness['muscular_soreness']*25
                            newStates[3] = currentWellness['stress']*25
                            newStates[4] = currentWellness['fatigue']*25
                            return newStates
                        })
                        setWellness(currentWellness)
                        setIsSet(true)
                        getWellnessScore()
                    } else {
                        setWellnessStates((oldStates) => {
                            let newStates = {...oldStates}
                            newStates[0] = 50
                            newStates[1] = 50
                            newStates[2] = 50
                            newStates[3] = 50
                            newStates[4] = 50
                            return newStates
                        })
                    }
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    const changeWellState = (key, value) => {
        setWellnessStates((oldStates) => {
            let newStates = {...oldStates}
            newStates[key] = value
            return newStates
        })
    }

    const changeDefaultValues = () => {
        if (defaultValues) {
            setDefaultValues(false)
        }
    }

    const changeIsSet = () => {
        if (isSet) {
            setIsSet(false)
        }
    }

    const changeBubbleOpen = (key) => {
        setBubbleOpen(key)
    }

    const submitWellness = (e) => {
        let mood = wellnessStates[0]/25
        let sleepQuality = wellnessStates[1]/25
        let muscularSoreness = wellnessStates[2]/25
        let stress = wellnessStates[3]/25
        let fatigue = wellnessStates[4]/25

        let headers = generateHeaders()
        if(headers){
            if (wellness) {
                let wellnessId = wellness['id']
                axios
                    .put(
                        config+"api/wellnesses/"+wellnessId,
                        {
                            mood: mood,
                            sleep_quality: sleepQuality,
                            muscular_soreness: muscularSoreness,
                            stress: stress,
                            fatigue: fatigue
                        },
                        {
                            headers: {
                                'X-WSSE': headers
                            }
                        },
                    )
                    .then(response => {
                        setIsSet(true)
                        getWellnessScore()
                    })
                    .catch(error => {
                        handleEndpointError(error)
                    })
            } else {
                axios
                    .post(
                        config+"api/wellnesses/new",
                        {
                            date: currentDate,
                            mood: mood,
                            sleep_quality: sleepQuality,
                            muscular_soreness: muscularSoreness,
                            stress: stress,
                            fatigue: fatigue
                        },
                        {
                            headers: {
                                'X-WSSE': headers
                            }
                        },
                    )
                    .then(response => {
                        setIsSet(true)
                        setWellness(response.data)
                        getWellnessScore()
                    })
                    .catch(error => {
                        handleEndpointError(error)
                    })
            }
            e.preventDefault()
        }
    }

    // Récupération du score wellness via l'API
    const getWellnessScore = () => {
        let headers = generateHeaders()
        if (headers) {
            axios
                .get(
                    config+"api/my_wellness_score",
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    setWellnessScore(response.data)
                    setWellnessGeneralOpen(true)
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    const wellnessBars = () => {
        let bars = []
        for (let i=0; i<Object.keys(wellnessStates).length; i++) {
            let textBubble, iconLeft, iconRight, title
            switch (i) {
                case 0:
                    title = t('app:athlete.wellness.mood.title')
                    iconLeft = emojiHumeur1
                    iconRight = emojiHumeur2
                    textBubble = getMessageWellnessType('mood', wellnessStates[i], t)
                    break
                case 1:
                    title = t('app:athlete.wellness.sleep.title')
                    iconLeft = emojiSommeil1
                    iconRight = emojiSommeil2
                    textBubble = getMessageWellnessType('sleep', wellnessStates[i], t)
                    break
                case 2:
                    title = t('app:athlete.wellness.muscle.title')
                    iconLeft = emojiMuscle1
                    iconRight = emojiMuscle2
                    textBubble = getMessageWellnessType('muscular_soreness', wellnessStates[i], t)
                    break
                case 3:
                    title = t('app:athlete.wellness.stress.title')
                    iconLeft = emojiStress1
                    iconRight = emojiStress2
                    textBubble = getMessageWellnessType('stress', wellnessStates[i], t)
                    break
                case 4:
                    title = t('app:athlete.wellness.fatigue.title')
                    iconLeft = emojiFatigue1
                    iconRight = emojiFatigue2
                    textBubble = getMessageWellnessType('fatigue', wellnessStates[i], t)
                    break
                default:
                    break
            }

            bars.push(
                <WellnessBar key={"wellness-bar-"+i}
                             iconLeft={iconLeft}
                             iconRight={iconRight}
                             wellState={wellnessStates[i]}
                             changeWellState={(i, valueState) => changeWellState(i, valueState)}
                             keyState={i}
                             changeDefaultValues={changeDefaultValues}
                             changeIsSet={changeIsSet}
                             bubbleOpen={bubbleOpen}
                             changeBubbleOpen={changeBubbleOpen}
                             textBubble={textBubble}
                             title={title}
                />
            )
        }

        return bars
    }

    const getDataTip = () => {
        return (
            <div className="fs-12 text-left">
                <p>{t('app:athlete.wellness.wellness_info.0')}</p>
                <p>{t('app:athlete.wellness.wellness_info.1')}</p>

            </div>
        )
    }

    return (
        <>
            <div className="dashboard-card pc-3 mcb-4">
                <div className="d-flex justify-content-start"
                >
                    <p className="monitoring-title mb-0">
                        Wellness
                        <img
                            src={helpCircle}
                            className="ml-3 icon-24 "
                            alt="help icon"
                            data-for="wellnessHelper"
                            data-tip={""}
                            data-event='click'

                        />
                    </p>
                </div>
                <ReactTooltip key="tooltip-wellness"
                              id="wellnessHelper"
                              type="light"
                              multiline={true}
                              textColor="#000000"
                              backgroundColor="#F5F5F5"
                              className={isMobile ? "tooltip mcl-5" : "tooltip"}
                              effect="solid"
                              place="bottom"
                              getContent={() => getDataTip()}
                />
                <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                      onClick={() => setIsOpen(isOpen => !isOpen)}
                >
                <i className={isOpen ? "bi bi-chevron-up": "bi bi-chevron-down"} />
            </span>
                {isOpen &&
                    (wellnessGeneralOpen && wellnessScore && wellnessScore.valid ?
                        <>
                            <WellnessScore wellnessScore={wellnessScore}/>
                            <div className="d-flex justify-content-center mcy-3">
                                <span className="btn-white-blue"
                                      onClick={() => setWellnessGeneralOpen(false)}>{t('app:athlete.wellness.edit_wellness')}</span>
                            </div>
                            <span className="edit-wellness pointer w-100 pct-2"
                                  onClick={() => openCloseWellnessEvolution()}>{t('app:athlete.wellness.see_wellness_evolution')}</span>
                        </>
                        :
                        <>
                            <div className="wellness-content container my-3">
                                {wellnessBars()}
                            </div>
                            <div className="wellness-btn d-flex justify-content-center">
                                {isSet ?
                                    <div
                                        className='wellness-btn-validate py-2 px-3 btn btn-primary d-flex align-items-center justify-content-center'>
                                        <img className="mr-2 icon-24" alt="valid icon"
                                             src={validate2}/>{t('app:athlete.saving.saved')}
                                    </div> :
                                    <Button
                                        className={defaultValues ? 'background-grey wellness-btn-text py-2 px-3 d-flex align-items-center justify-content-center' : 'background-black wellness-btn-text py-2 px-3 d-flex align-items-center justify-content-center'}
                                        onClick={submitWellness} disabled={defaultValues}>
                                        <img className="mr-2 icon-24" alt="valid icon"
                                             src={validate}/>{t('app:athlete.saving.save')}
                                    </Button>
                                }
                            </div>
                        </>
                    )
                }
            </div>

        </>
    )

}

export default Wellness
// export default withRouter(withTranslation(['app', 'athlete'],{wait: true})(Wellness))