
export const MetricValues = {
    international: 1,
    imperial: 2
}

export const MetricUnits = {
    international:  {
        temperature : '°C',
        weight : 'kg',
        altitude : 'm',
        distance_nat : 'm',
        speed_nat : 'm/s',
        allure_nat : 'min/100m',
        distance_cap : 'm',
        distance_cap_velo : 'km',
        distance_other : 'km',
        speed_cap_velo : 'km/h',
        allure_cap_velo : 'min/km',
        power_velo : 'W',
        cadence_velo : 'rpm',
        cadence_cap : 'ppm',
        heartrate: 'bpm'
    },
    imperial: {
        temperature : '°F',
        weight : 'lbs',
        altitude : 'ft',
        distance_nat : 'yds',
        speed_nat : 'yds/min',
        allure_nat : 'min/100y',
        distance_cap_velo : 'mi',
        distance_other : 'mi',
        speed_cap_velo : 'mph',
        allure_cap_velo : 'min/mi',
        power_velo : 'W',
        cadence_velo : 'rpm',
        cadence_cap : 'ppm',
        heartrate: 'bpm'
    }
}

export const Metrics = (metric) => {
    let metrics = {}

    switch (metric) {
        case MetricValues.imperial:
            metrics = MetricUnits.imperial
            break
        case MetricValues.international:
        default:
            metrics = MetricUnits.international
            break
    }
    return metrics
}
