import React, {useContext, useEffect, useState,} from 'react';
import {Navbar} from "react-bootstrap";
import { useHistory,Link} from "react-router-dom";
import {search} from "../../img/img";
import logo from "../../img/logo_gutai_black.png";
import {NavigationContext, PrioritySportContext, WorkoutTemplateContext} from "../../Contexts";
import {useTranslation} from "react-i18next";
import {DISCIPLINES_WORKOUT_TEMPLATE, FILTERS_WORKOUT_TEMPLATE} from "./Utils";
import WorkoutTemplateThemes from "./WorkoutTemplateThemes";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import {useIsMounted} from "../Tools/CustomHooks";
import generateHeaders from "../../Functions/generateHeaders";
import WorkoutTemplateTheme from "./WorkoutTemplateTheme";
import WorkoutTemplateTable from "./WorkoutTemplateTable";
import WorkoutTemplateAuthors from "./WorkoutTemplateAuthors";
import WorkoutTemplateAuthor from "./WorkoutTemplateAuthor";
import {animateScroll} from "react-scroll";

const WorkoutTemplateSearch = ({}) => {
    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()
    const history = useHistory()

    const {preferedSports} = useContext(PrioritySportContext)

    const [disciplineCode, setDisciplineCode] = useState(null)
    const [filterActive, setFilterActive] = useState(FILTERS_WORKOUT_TEMPLATE.themes)
    const [onSearch, setOnSearch] = useState(true)
    const [keyWord, setKeyWord] = useState(null)
    const [workoutTemplates, setWorkoutTemplates] = useState([])
    const [themes, setThemes] = useState([])
    const [authors, setAuthors] = useState([])
    const [workoutTemplateLists, setWorkoutTemplateLists] = useState([])
    const [plannedWorkoutTemplates, setPlannedWorkoutTemplates] = useState([])
    const [favoriteWorkoutTemplates, setFavoriteWorkoutTemplates] = useState([])
    // Pour gérer la mise à jour des séances favorites
    const [refreshFavorite, setRefreshFavorite] = useState(true)
    const WorkoutTemplateContextValue = {
        refreshFavorite,
        onUpdateFavorite: (value) => {
            setRefreshFavorite(value)
        },
    }



    const [loadedWorkoutTemplates, setLoadedWorkoutTemplates] = useState(false)
    const [loadedThemes, setLoadedThemes] = useState(false)
    const [loadedAuthors, setLoadedAuthors] = useState(false)
    const [loadedPlannedWorkoutTemplates, setLoadedPlannedWorkoutTemplates] = useState(false)
    const [loadedFavoriteWorkoutTemplates, setLoadedFavoriteWorkoutTemplates] = useState(false)
    const [isInit, setIsInit] = useState(false)

    // Pour ouverture du thème
    const [themeId, setThemeId] = useState(null)
    // Pour ouverture du Coach
    const [authorId, setAuthorId] = useState(null)



    const [showSessionsWidget,setShowSessionsWidget] = useState(false)
    const [showThemesWidget,setShowThemesWidget] = useState(true)
    const [showAuthorsWidget,setShowAuthorsWidget] = useState(true)
    const [showFavoriteSessionsWidget,setShowFavoriteSessionsWidget] = useState(false)
    const [showPlannedSessionsWidget,setShowPlannedSessionsWidget] = useState(false)



    useEffect(() => {
        // Récupérer les modèles de séances planifiés
        getPlannedWorkoutTemplates()
    }, [])


    useEffect(() => {
        if(isMobile) {
            setTimeout(() => {
                animateScroll.scrollToTop()
            }, 200)
        }
    }, [isMobile])

    useEffect(() => {
        if(WorkoutTemplateContextValue.refreshFavorite) {
            // Récupérer les modèles de séances favoris
            getFavoriteWorkoutTemplates()
            // Récupérer les playlists de modèles de séance
            getWorkoutTemplateLists()

            WorkoutTemplateContextValue.onUpdateFavorite(false)
        }

    }, [WorkoutTemplateContextValue.refreshFavorite])


    useEffect(() => {
        setShowSessionsWidget(!filterActive || filterActive === FILTERS_WORKOUT_TEMPLATE.sessions)
        setShowThemesWidget(!filterActive || filterActive === FILTERS_WORKOUT_TEMPLATE.themes)
        setShowAuthorsWidget(!filterActive || filterActive === FILTERS_WORKOUT_TEMPLATE.authors)
        setShowFavoriteSessionsWidget(filterActive === FILTERS_WORKOUT_TEMPLATE.favorites)
        setShowPlannedSessionsWidget(filterActive === FILTERS_WORKOUT_TEMPLATE.sessions_done)

    }, [filterActive])

    // Sélectionner les filtres par défault (sport en fonction du réglage utilisateur)
    useEffect(() => {
        if (preferedSports && preferedSports.length>0) {
            setDisciplineCode(preferedSports[0])
        } else {
            setDisciplineCode('cap')
        }
        setIsInit(true)
    }, [preferedSports])

    // En fonction des filtres de recherche
    useEffect(() => {
        if (isInit && onSearch) {
            // Récupérer la liste des thèmes
            getThemes()
            // Récupérer la liste des auteurs
            getAuthors()
            // Récuperer la liste des séances
            getWorkoutTemplates()

            setOnSearch(false)
        }
    }, [disciplineCode, onSearch])


    const getWorkoutTemplates = () => {
        setLoadedWorkoutTemplates(false)
        axios
            .get(
                config+"api/workout_templates",
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
                    params: {
                        keyword: keyWord,
                        discipline_code: disciplineCode
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let datas = response.data.workoutTemplates
                setWorkoutTemplates(datas)
                setLoadedWorkoutTemplates(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const getThemes = () => {
        setLoadedThemes(false)
        axios
            .get(
                config+"api/workout_template_themes",
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
                    params: {
                        keyword: keyWord,
                        discipline_code: disciplineCode
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let datas = response.data
                setThemes(datas.workoutTemplateThemes)
                setLoadedThemes(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const getAuthors = () => {
        setLoadedAuthors(false)
        axios
            .get(
                config+"api/coachs",
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
                    params: {
                        keyword: keyWord,
                        discipline_code: disciplineCode
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let datas = response.data.coachs
                setAuthors(datas)
                setLoadedAuthors(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }



    const getFavoriteWorkoutTemplates = () => {
        setLoadedFavoriteWorkoutTemplates(false)
        axios
            .get(
                config+"api/list_favorite_workout_templates",
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let datas = response.data
                setFavoriteWorkoutTemplates(datas)
                setLoadedFavoriteWorkoutTemplates(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const getPlannedWorkoutTemplates = () => {
        setLoadedPlannedWorkoutTemplates(false)
        axios
            .get(
                config+"api/list_planned_workout_templates",
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let datas = response.data
                setPlannedWorkoutTemplates(datas)
                setLoadedPlannedWorkoutTemplates(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const getWorkoutTemplateLists = () => {
        axios
            .get(
                config+"api/workout_template_lists",
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let datas = response.data.workout_template_lists
                setWorkoutTemplateLists(datas)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const changeDiscipline = (disciplineName) => {
        let newDiscipline = disciplineName !== disciplineCode ? disciplineName : null;
        setDisciplineCode(newDiscipline)
        setOnSearch(true)
    }

    const changeFilterActive = (filterName) => {
        let newFilter = filterName !== filterActive ? filterName : null;
        setFilterActive(newFilter)
    }

    const searchAction = () => {
        setOnSearch(!onSearch)
    }

    const onKeyDownInput = (e) => {
        // Detection de la touche entrée pour lancer la recherche
        if (e.key === 'Enter' && keyWord !== "") {
            searchAction()
        }
    }

    const isFavorite = (workoutTemplateId) => {
        return undefined !== favoriteWorkoutTemplates.find(workoutTemplate => workoutTemplate.id === workoutTemplateId) ? true : false
    }
    const isPlanned = (workoutTemplateId) => {
        return undefined !== plannedWorkoutTemplates.find(workoutTemplate => workoutTemplate.id === workoutTemplateId) ? true : false
    }

    const redirectHome = () => {
        let url = "/"
        history.push(url)
    }

    return (
        <WorkoutTemplateContext.Provider value={WorkoutTemplateContextValue}>
            <div>
                <div className="new-header pcx-0 mcb-5">
                    <Navbar>
                        <Navbar.Brand className="mx-auto abs-center-x" as={Link} to="/">
                            <img
                                src={logo}
                                className="d-inline-block align-top navbar-logo"
                                alt="Gutai logo"
                            />
                        </Navbar.Brand>
                        <div className="ml-auto mct-3 margin-link pointer justify-content-center align-items-center d-flex flex-column"
                             onClick={() => redirectHome()}
                        >
                            <span className="bi bi-house"></span>
                            <p className="pct-1 gotham-12">{t('app.workout_template.header_title')}</p>
                        </div>
                    </Navbar>
                </div>
                <div className={`${themeId||authorId ? "" : "pcx-3"} w-100 pcb-3 d-flex align-items-center flex-column`}>
                    {themeId ?
                        <WorkoutTemplateTheme themeId={themeId}
                                              setThemeId={setThemeId}
                                              isFavorite={(workoutTemplateId) => isFavorite(workoutTemplateId)}
                                              isPlanned={(workoutTemplateId) => isPlanned(workoutTemplateId)}
                        />
                        :
                        authorId ?
                            <WorkoutTemplateAuthor authorId={authorId}
                                                   setAuthorId={setAuthorId}
                                                   isFavorite={(workoutTemplateId) => isFavorite(workoutTemplateId)}
                                                   isPlanned={(workoutTemplateId) => isPlanned(workoutTemplateId)}
                            />
                            :
                            <>
                                <div className={`${isMobile ? "w-100" : "w-25"} mcb-3 d-flex`}>
                                    <div className="relative-div d-flex search-icon-container">
                                        <img className="icon-24" src={search} alt="search icon"/>
                                    </div>
                                    <input type="text"
                                           className={"search-bar-notification "+ (isMobile ? "w-75":"w-100")}
                                           onChange={(event) => setKeyWord(event.target.value)}
                                           onKeyDown={(event) => onKeyDownInput(event)}
                                    />
                                    <div className="relative-div d-flex search-link-container">
                                        <span className="search-link pcx-2 cursor-pointer"
                                              onClick={() => searchAction()}>
                                            {onSearch ? t('app.workout_template.cancel') : t('app.workout_template.search')}
                                        </span>
                                    </div>
                                </div>
                                <div className={`${isMobile ? "w-100" : "w-25"} d-flex justify-content-between mct-2 mcb-4`}>
                                    {Object.keys(DISCIPLINES_WORKOUT_TEMPLATE).map(function(key, index) {
                                        let discipline = DISCIPLINES_WORKOUT_TEMPLATE[key]
                                        let active = disciplineCode === key
                                        return (
                                            <div key={'discipline-'+key} className="d-flex flex-column">
                                                <div className="search-discipline-cube cursor-pointer"
                                                     style={{backgroundColor: `${active ? discipline.color : "#F5F5F5"}`}}
                                                     onClick={() => changeDiscipline(key)}
                                                >
                                                    <img className="icon-24" src={active ? discipline.icon_set : discipline.icon_unset} alt="discipline icon"/>
                                                </div>
                                                <span className={`mct-2 search-discipline-name ${active ? "color-black" : ""}`}>
                                        {t('app.disciplines.short_name.'+key)}
                                    </span>
                                            </div>

                                        )
                                    })}
                                </div>
                                <div className={`w-100 d-flex justify-content-center mct-2 mcb-4 ${isMobile ? "scrollable-filters" : ""}`}>
                                    {Object.keys(FILTERS_WORKOUT_TEMPLATE).map(function(key, index) {
                                        let filter = FILTERS_WORKOUT_TEMPLATE[key]
                                        let active = filterActive === key
                                        return (
                                            <div key={'filter-'+key}
                                                 className={`${active ? "search-filter-btn-active" : "search-filter-btn"} ${index !== 0 && "mcl-3"} d-flex justify-content-center align-items-center cursor-pointer`}
                                                 onClick={() => changeFilterActive(filter)}
                                            >
                                                {t('app.workout_template.filters.'+filter)}
                                            </div>

                                        )
                                    })}
                                </div>
                                {showSessionsWidget &&
                                    <WorkoutTemplateTable workoutTemplates={workoutTemplates}
                                                          loaded={loadedWorkoutTemplates}
                                                          workoutTemplateLists={workoutTemplateLists}
                                                          isFavorite={(workoutTemplateId) => isFavorite(workoutTemplateId)}
                                                          isPlanned={(workoutTemplateId) => isPlanned(workoutTemplateId)}
                                                          title={t('app.workout_template.filters.sessions')}
                                                          noResultMessage={t('app.workout_template.no_workout_template')}
                                    />
                                }
                                {showThemesWidget &&
                                    <WorkoutTemplateThemes themes={themes}
                                                           loaded={loadedThemes}
                                                           setThemeId={setThemeId}
                                    />
                                }
                                {showAuthorsWidget &&
                                    <WorkoutTemplateAuthors authors={authors}
                                                            loaded={loadedAuthors}
                                                            setAuthorId={setAuthorId}
                                    />
                                }
                                {showFavoriteSessionsWidget &&
                                    <WorkoutTemplateTable workoutTemplates={favoriteWorkoutTemplates}
                                                          loaded={loadedFavoriteWorkoutTemplates}
                                                          isFavorite={workoutTemplateId => true}
                                                          isPlanned={(workoutTemplateId) => isPlanned(workoutTemplateId)}
                                                          title={t('app.workout_template.filters.favorites')}
                                    />
                                }
                                {showPlannedSessionsWidget &&
                                    <WorkoutTemplateTable workoutTemplates={plannedWorkoutTemplates}
                                                          loaded={loadedPlannedWorkoutTemplates}
                                                          isFavorite={(workoutTemplateId) => isFavorite(workoutTemplateId)}
                                                          isPlanned={workoutTemplateId => true}
                                                          title={t('app.workout_template.filters.sessions_done')}
                                    />
                                }
                            </>
                    }


                </div>
            </div>
        </WorkoutTemplateContext.Provider>
    );
};

export default WorkoutTemplateSearch;