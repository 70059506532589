import React, {useState, useContext} from 'react';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {Form,Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {
    validate_blue,
    login_icon,
} from "../../img/img";
import axios from "axios";
import config from "../../api";
import handleEndpointError from '../../Functions/Alert';
import '../../Css/Home.css';

import {AuthContext, NavigationContext} from "../../Contexts";
import InputField from "./InputField";
import PasswordInput from "./PasswordInput";

const Login = ({switchToRegister, onSuccess}) => {

    let history = useHistory()

    const {t} = useTranslation(['app'])
    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    // Mettre à jour l'authentification

    const {updateToken} = useContext(AuthContext)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [stayLogin, setStayLogin] = useState(false)
    const [loginErrors, setLoginErrors] = useState(1)
    const [emailOk, setEmailOk] = useState(false)

    const handleCheck = () => {
        if (stayLogin) {
            setStayLogin(false)
        } else {
            setStayLogin(true)
        }
    }

    const onChangeEmail = (value) => {
        setEmail(value)
    }

    const onChangePassword = (value) => {
        setPassword(value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        axios
            .post(
                config+"public/api/login",
                {
                    username: email,
                    password: password
                },
            )
            .then(response => {
                if (response.status === 200) {
                    let token = response.data["secret"]
                    if (stayLogin){
                        localStorage.setItem("token", token)
                        localStorage.setItem("username", email)

                    } else {
                        sessionStorage.setItem("token", token)
                        sessionStorage.setItem("username", email)
                    }
                    updateToken(token)
                    onSuccess(email)
                }
            })
            .catch(error => {

                if(error.response === undefined || error.response.status !== 400) {
                    // redirection vers page de maintenance
                    history.push("/public/come_back_soon")
                }

                setLoginErrors(2)
                handleEndpointError(error)
            })

    }

    return (
        <>
            <div id="form-login" className={isMobile ? "mb-auto w-100" : "w-100"}>
                <Form>
                    <InputField
                        name={"email"}
                        isRequired={true}
                        type={"email"}
                        placeholder={"Email *"}
                        inputValue={email}
                        setValue={(value) => onChangeEmail(value)}
                        setEmailOk={setEmailOk}
                    />
                    <p id="error-text-input" className="mcb-2 text-left error-text-input d-none">{t('app:login.mail_format')}</p>

                    <PasswordInput password={password}
                                   onChangePassword={(value) => onChangePassword(value)}
                                   toCheck={false}
                    />
                    <div className="w-100 text-right mcb-4">
                        <Link to={"/reset_password"} className="forgot-password">
                            {t('app:forgot_password.forgot_password')+" ?"}
                        </Link>
                    </div>

                    <div className="checkbox-container-login checkbox-login mcb-4">
                        <input type="checkbox" id="check1" name="check1" onChange={handleCheck}/>
                        <label htmlFor="check1">{t('app:login.stay_loggedin')}</label>
                    </div>

                </Form>
                { loginErrors === 2 &&
                <div className="error-login-text mcb-3">
                    {t('app:login.error_input')}
                </div>
                }
                <div className="flex-column">
                    {emailOk && password !== "" ?
                        <Button id="login-btn" type="submit" className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5' onClick={handleSubmit}>
                            <img className="mcr-2 icon-24" alt="log-in icon" src={login_icon} /><p className="my-auto home-btn-p-text">{t('app:login.btn')}</p>
                        </Button> :
                        <Button className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5' disabled>
                            <img className="mcr-2 icon-24" alt="log-in icon" src={login_icon} /><p className="my-auto home-btn-p-text">{t('app:login.btn')}</p>
                        </Button>
                    }
                    <div className="row d-flex justify-content-center align-items-center mct-5">
                        <div className="grey-line mcr-2"></div>
                        <p className="mb-0 grey-line-text">{t('app:login.or')}</p>
                        <div className="grey-line mcl-2"></div>
                    </div>
                    <p className="grey-line-text mcb-2 mct-2">{t('app:login.no_account')}</p>
                    <Button id="register-btn"
                            className='link-btn mx-auto home-btn-white home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcb-2'
                            onClick={() => switchToRegister()}
                    >
                        <img className="mcr-2 icon-24" alt="valid icon" src={validate_blue} /><p className="my-auto home-btn-p-text">{t('app:register.btn')}</p>
                    </Button>
                </div>
            </div>
        </>
    )
}

export default Login