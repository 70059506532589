import React, {useContext,useState} from "react";
import {useTranslation} from "react-i18next";
import {NavigationContext} from "../Contexts";

const CartOption = ({productOption, currency, checked, disabled, onChange, color}) => {

    // Traduction
    const {t} = useTranslation(['app']);
    const {isMobile} = useContext(NavigationContext)

    const [isChecked, setIsChecked] = useState(checked ? true : false)

    const udpateIsChecked = (isChecked) => {
        setIsChecked(isChecked)
        onChange(productOption, isChecked)
    }

    const onCheckboxClick = (checked) => {
        udpateIsChecked(checked)
    }

    const priceRecurring = currency +  (productOption.product_price.recurring ? ("/" + t('checkout.price.recurring.'+ productOption.product_price.recurring)) : "")

    return (
        <>
            <div className={"d-flex align-items-center checkbox-container checkbox-container-subscription checkbox-container-subscription-wrapper subscription-options-"+color+" w-100"}>
                <input id={"checkbox_"+productOption.id}
                       type="checkbox"
                       checked={isChecked}
                       disabled={disabled}
                       onClick={(event) => onCheckboxClick(event.target.checked)}
                />
                <label htmlFor={"checkbox_"+productOption.id}
                       className={(isMobile ?"subscription-label-mobile d-flex align-items-center" :"subscription-label d-flex align-items-center") + " w-75"}
                >
                    {productOption.name}
                </label>
                <span class="price-label w-25 text-right">
                    <span class="price-label-amount">{productOption.product_price.amount}</span>
                    {priceRecurring}
                </span>
            </div>
            {productOption.description &&
            <p className="text-subscription fs-12 text-left pcl-6">
                {productOption.description}
            </p>
            }
        </>
    )
}

export default CartOption

