import React from "react";
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {cap_set, nat_set, tic, tic_green, tic_orange, velo_set} from "../../../img/img";

const DisciplineContexts = (props) => {
    const {t, workout, contexts, currentCheck, handleCheck, discipline} = props

    let bcolor, disciplineIcon
    let color = null

    switch (discipline) {
        case 'cap':
            bcolor = "btn-outline-cap bcolor-cap"
            disciplineIcon = cap_set
            color = 'orange'
            break
        case 'velo':
            bcolor = "btn-outline-velo bcolor-velo"
            disciplineIcon = velo_set
            color = 'green'
            break
        case 'nat':
        default:
            bcolor = "btn-outline-nat bcolor-nat"
            disciplineIcon = nat_set
            color = 'blue'
            break
    }
    if(!currentCheck | currentCheck[discipline] === null) {
        return null
    }

    return (
            <div className="w-100 mct-3 justify-content-start mx-0">
                <div className="mcb-3 row">
                    <div className="mcb-3 col-2  col-md-1">
                        <div className={"agenda-cube "+bcolor+" d-flex justify-content-center my-auto px-0"}  >
                            <img
                                src={disciplineIcon}
                                alt={discipline+" set icon"}
                                className="my-auto icon-24"
                            />
                        </div>
                    </div>
                    {}
                    { workout.state === 1 ?
                        <div className="col-10 d-flex flex-column align-items-start pcl-3 mcb-3">
                            <span className="conditions-desc text-left">{t('app:workout.status.to_realised')}<span className="font-weight-bold">{currentCheck[discipline].fullname}</span>.</span>
                            <span className="conditions-desc text-left font-weight-bold">{t('app:workout.warning')}</span>
                        </div>
                        :
                        <div className="col-9 d-flex align-items-center justify-content-start pcl-3 mcb-3">
                            <span className="conditions-desc text-left">{t('app:workout.status.realised')}<span className="font-weight-bold">{currentCheck[discipline].fullname}</span>.</span>
                        </div>
                    }
                    {workout.state === 1 && contexts.map((item) =>
                        <div className="col-md-3 col-6" key={item.id}>
                            <div className={"checkbox-container-"+color+" w-100 mcb-2"}>
                                <input
                                    id={"context_"+discipline+"_"+item.id}
                                    type="radio"
                                    name={discipline}
                                    value={item.fullname}
                                    checked={item.id === currentCheck[discipline].id}
                                    onChange={event => handleCheck(event, item)}
                                />
                                <label className="fs-11" htmlFor={"context_"+discipline+"_"+item.id}>{item.fullname}</label>
                            </div>
                        </div>
                    )}
                </div>
            </div>


    )
}

export default withRouter(withTranslation(['app'],{ wait: true })(DisciplineContexts));
