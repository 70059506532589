import React, {useContext} from 'react';
import logo from "../img/logo_gutai_black.png";
import {LoggedInContext} from "../Contexts";


export default function HeaderMobile (props) {
    const {firstname} = useContext(LoggedInContext)
    return (
        <div className="header-container">
            <div className="logo-gutai mt-1">

                <img
                    src={logo}
                    className="logo-gutai-mobile"
                    alt="Gutai logo"
                />
            </div>
            {firstname &&
            <div className="d-flex justify-content-start ml-4 mt-3">
                <p className="shortname-header">
                    Hello {firstname} !
                </p>
            </div>
            }
        </div>
    )
}