import React, {useState, useEffect} from 'react';
import CustomSelect from "../Tools/CustomSelect"

const SelectRecurrencyType = ({recurrencyTypes, defaultRecurrencyType, onChange}) => {

    const [value, setValue] = useState(defaultRecurrencyType)
    const [defaultValue, setDefaultValue] = useState(defaultRecurrencyType)

    // Sélectionner la valeur par défaut lors de la mise à jour de la liste
    useEffect(() => {
        if(recurrencyTypes && recurrencyTypes.length > 0) {
            let inRecurrencyTypes = false
            if(defaultValue) {
                for(let c of recurrencyTypes) {
                    if(c.id === defaultValue.id) {
                        inRecurrencyTypes = true
                        break
                    }
                }
            }

            if(!inRecurrencyTypes) {
                let recurrencyTypeValue = recurrencyTypes[0]
                setDefaultValue(recurrencyTypeValue)
                onChangeValue(recurrencyTypeValue)
            }
        }
    }, [recurrencyTypes])

    const onChangeValue = (value) => {
        setValue(value)
        onChange(value)
    }

    if(recurrencyTypes === null || recurrencyTypes.length === 0 || !defaultValue)
        return (<></>)

    return (
        <CustomSelect
            className="w-100 text-left"
            name="recurrency_type"
            options={recurrencyTypes}
            defaultValue={defaultValue}
            value={value}
            onChange={(value) => onChangeValue(value)}
        />
    )
}

export default SelectRecurrencyType;