import React, {useEffect, useState, useContext} from "react";
import {MetricContext, NavigationContext} from "../../Contexts";
import {useTranslation} from "react-i18next";
import InputSwitch from "../Tools/InputSwitch";
import {Metrics} from "../../Functions/Metrics";
import generateHeaders from "../../Functions/generateHeaders";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";


const AddManually = ({discipline, contextCode, onChangeDisplayManually, triggerLoadData}) => {
    const {t} = useTranslation(['app'])

    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    const [hrIsReferal, setHrIsReferal] = useState(false)
    const [values, setValues] = useState({
        1: null,
        2: null,
        3: null
    })
    const [isSubmitable, setIsSubmitable] = useState(false)
    const [isAscent, setIsAscent] = useState(false)
    const [metricLabel, setMetricLabel] = useState("")
    const [metricUnit, setMetricUnit] = useState("")
    const [colorClasses, setColorClasses] = useState({
        borderColor: "",
        labelColor: ""
    })

    const [hasElevation, setHasElevation] = useState(true)


    useEffect(() => {
        getMetricValues()
    }, [hrIsReferal, discipline]);

    useEffect(() => {
        getColors()
    }, [discipline]);

    useEffect(() => {
       setHasElevation(discipline === 'nat' || contextCode.indexOf("indoor") !== -1 ? false : true)
    }, [discipline, contextCode]);

    useEffect(() => {
        if (values[1] && values[1] !== "0" && values[2] && values[2] !== "0") {
            setIsSubmitable(true)
        } else {
            setIsSubmitable(false)
        }
    }, [values]);

    const submitCompute = () => {
        let zoneType = hrIsReferal ? "critical_heartrate" : "critical_intensity"
        let contextCodeWithElevation
        if (!hasElevation) {
            contextCodeWithElevation = contextCode
        } else {
            let extension = isAscent ? "-ascent" : "-flat"
            contextCodeWithElevation = contextCode+extension
        }
        let datas = {
            discipline_code : discipline,
            context_code: contextCodeWithElevation,
            zone_type: zoneType,
            value_1: values[1],
            value_2: values[2],
            value_3: values[3]
        }
        let headers = generateHeaders()
        if (headers) {
            axios
                .put(
                    config+"api/my_training_zones/compute",
                    datas,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    // Déclencher récupération des valeurs des zones d'intensités
                    triggerLoadData()

                    // Fermer ce formulaire
                    onChangeDisplayManually()
                })

                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    const onSwitchChangeReferal = () => {
        setHrIsReferal(!hrIsReferal)
    }

    const onSwitchChangeElevation = () => {
        setIsAscent(!isAscent)
    }


    const getColors = () => {
        let borderColor = discipline+"-border"
        let labelColor = "bcolor-"+discipline
        setColorClasses({borderColor: borderColor, labelColor: labelColor})
    }


    const getMetricValues = () => {
        let unit, label
        if (hrIsReferal) {
            label = t('stats.labels.bpm')
            unit = metrics.heartrate
        } else {
            switch (discipline) {
                default:
                case "nat":
                    label = t('rjbal.time')
                    unit = "min"
                    break
                case "cap":
                    label = t('stats.labels.speed')
                    unit = metrics.speed_cap_velo
                    break
                case "velo":
                    label = t('stats.labels.power')
                    unit = metrics.power_velo
                    break
            }
        }
        setMetricLabel(label)
        setMetricUnit(unit)
    }

    const onChangeValues = (value, num) => {
        setValues((oldValues) => {
            let newValues = {...oldValues}
            newValues[num] = value
            return newValues
        })
    }

    let rowValues = []
    let rowUnit = null
    switch(discipline) {
        case 'nat':
            rowValues = [400,800,1500]
            rowUnit="m"
            break
        case 'velo':
            rowValues = [5,20,60]
            rowUnit="min"
            break
        case 'cap':
            rowValues = [5,20,45]
            rowUnit="min"
            break
        default:
            break;
    }

    return (
        <div>
            <div className="message-no-intensity pc-3 text-left">
                {t('app.training_zones.add_manually_description_'+discipline)}
            </div>
            {discipline === "nat" ?
                <></>
                :
                <div className="d-flex flex-column align-items-center justify-content-center">
                    {discipline === "velo" ?
                        <InputSwitch
                            addClassName="pb-3 switch-add-manually"
                            labelLeft={t('stats.labels.power')}
                            selectOpt={hrIsReferal}
                            labelRight={t('stats.labels.bpm')}
                            onSwitchChange={onSwitchChangeReferal}
                        />
                        :
                        <InputSwitch
                            addClassName="pb-3 switch-add-manually"
                            labelLeft={t('stats.labels.speed')}
                            selectOpt={hrIsReferal}
                            labelRight={t('stats.labels.bpm')}
                            onSwitchChange={onSwitchChangeReferal}
                        />
                    }
                    {hasElevation &&
                    <InputSwitch
                        addClassName="pb-3 switch-add-manually"
                        labelLeft={t('app.elevation.flat')}
                        selectOpt={isAscent}
                        labelRight={t('app.elevation.ascent')}
                        onSwitchChange={onSwitchChangeElevation}
                    />
                    }
                </div>
            }
            <div className="pcx-3">
                <div className="row mcb-2 justify-content-center">
                    <div className="col-9 offset-3 d-flex flex-column align-items-center gotham-12">
                        <p className="m-0">{metricLabel} { hasElevation ? (isAscent ? t('app.elevation.ascent') : t('app.elevation.flat')) : null}</p>
                        <p className="m-0">({metricUnit})</p>
                    </div>
                </div>
                {rowValues.length && rowUnit && rowValues.map((rowValue, index) =>
                    <div className="row mcb-2 justify-content-center">
                        <div className="col-3 pcx-0">
                            <div className={"duration-training-zones-manually "+colorClasses.labelColor}>
                                {rowValue+rowUnit}
                            </div>
                        </div>
                        <div className="col-9">
                            <input type="text"
                                   className={"input-training-zones-manually w-100 "+colorClasses.borderColor}
                                   placeholder={"0 "+metricUnit}
                                   onChange={(e) => onChangeValues(e.target.value, index+1)}
                            />
                        </div>
                    </div>
                )}

            </div>
            <div className="d-flex justify-content-center mcy-3">
                {isSubmitable ?
                    <div className="btn-white-blue" onClick={submitCompute}>
                        {t('athlete.saving.save')}
                    </div>
                    :
                    <div className="btn-white-blue-disabled">
                        {t('athlete.saving.save')}
                    </div>
                }

            </div>
            <div className="cancel-manually pointer" onClick={onChangeDisplayManually}>
                {t('athlete.external_activity.cancel')}
            </div>
        </div>
    )

}

export default AddManually;