import React, {useEffect, useState} from "react";
import {
    arrow_left_circle, arrow_right_circle,
    cap_grey,
    cap_set, multi_set,
    muscu_grey, muscu_set,
    nat_grey,
    nat_set,
    velo_grey,
    velo_set
} from "../../img/img";

const TrainingSummaryDiscipline = ({disciplines, onChangeDiscipline, disciplinesList, onChangeOneDiscipline}) => {


    const initStyles = {
        cap: {
            icon: cap_grey,
            bcolor: "discipline-cube-grey"
        },
        velo: {
            icon: velo_grey,
            bcolor: "discipline-cube-grey"
        },
        nat: {
            icon: nat_grey,
            bcolor: "discipline-cube-grey"
        },
        muscu: {
            icon: muscu_grey,
            bcolor: "discipline-cube-grey"
        },
    }

    const [disciplineStyle, setDisciplineStyle] = useState(initStyles)

    useEffect(() => {
        setDisciplineStyle(() => {
            let newStyles = initStyles
            for (let i=0; i<disciplines.length; i++) {
                switch (disciplines[i]) {
                    default:
                    case 'cap':
                        newStyles.cap.bcolor = "btn-outline-cap bcolor-cap"
                        newStyles.cap.icon = cap_set
                        break
                    case 'velo':
                        newStyles.velo.bcolor = "btn-outline-velo bcolor-velo"
                        newStyles.velo.icon = velo_set
                        break
                    case 'nat':
                        newStyles.nat.bcolor = "btn-outline-nat bcolor-nat"
                        newStyles.nat.icon = nat_set
                        break
                    case 'muscu':
                        newStyles.muscu.bcolor = "btn-outline-muscu bcolor-muscu"
                        newStyles.muscu.icon = muscu_set
                        break
                }
            }
            return newStyles
        })
    }, [disciplines])

    return (
        <div className="d-flex flex-column align-items-center pc-3">
            <div className="d-flex justify-content-center">
                {disciplines.length===1 &&
                    <div className="h-40 d-flex align-items-center">
                        <img src={arrow_left_circle} alt="arrow-left icon" className="grey-arrow icon-24 pointer mcr-3" onClick={() => onChangeOneDiscipline('prev')}/>
                    </div>
                }
                {disciplinesList.map((discipline, index) => {
                        return (
                            <div key={"btn-"+discipline}
                                className={`pointer nav-case-discipline${(index===disciplinesList.length-1 && disciplines.length>1) ? "": " mcr-3"} ` + disciplineStyle[discipline].bcolor}
                                onClick={() => onChangeDiscipline(discipline)}
                            >
                                <img src={disciplineStyle[discipline].icon} alt={discipline+" icon"} className="icon-24"/>
                            </div>
                        )
                    })
                }
                {disciplines.length===1 &&
                    <div className="h-40 d-flex align-items-center">
                        <img src={arrow_right_circle} alt="arrow-right icon" className="grey-arrow icon-24 pointer" onClick={() => onChangeOneDiscipline('next')}/>
                    </div>
                }
            </div>

        </div>
    )
}

export default TrainingSummaryDiscipline