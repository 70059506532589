import React from 'react';

import axios from "axios";
import config from "../../api";

import {logo_polar} from "../../img/img"

import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from '../../Functions/Alert';


function PreferencePolar({isPolarSynced, updateIsPolarSynced}) {

    const onChangePolarSynced = () => {
        if(isPolarSynced === true) {
            // Désynchroniser
            updateIsPolarSynced(false)
            unsyncPolar()
        }
        else {
            // Synchroniser
            updateIsPolarSynced(true)
            syncPolar()
        }
    }

    // Synchroniser le compte Polar
    const syncPolar = async () => {
        await axios.get(
            config+"api/my_profile/sync_polar_url",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            // Rediriger l'utilisateur vers la page de synchronisation
            window.location.href=response.data
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    // Désynchroniser le compte Polar
    const unsyncPolar = async () => {
        await axios.get(
            config+"api/my_profile/unsync_polar",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            updateIsPolarSynced(!response.data.unsynced)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }


    return (
        <>
            <div className="w-100 d-inline-flex align-items-center justify-content-between show-input-settings mcb-3">
                <img
                    src={logo_polar}
                    className="settings-provider-logo pcy-2"
                    alt="Polar logo"
                />
                <span className="toggle-switch">
                    <input type="checkbox" checked={isPolarSynced} onChange={()=>{}}/>
                    <span className="switch" onClick={() => onChangePolarSynced()} />
                </span>
            </div>
        </>
    )
}

export default PreferencePolar;