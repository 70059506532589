import ReactTooltip from "react-tooltip";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavigationContext} from "../../Contexts";
import AthleteSports from "./AthleteSports";

const AthleteSportsDropdown = ({userProfile, onChangeUserProfile}) => {
    const {t} = useTranslation(['app'])
    const {isMobile} = useContext(NavigationContext)
    const [isDisciplineOpen, setIsDisciplineOpen] = useState(false)
    return (
        <div className={"zs-tab-card pcy-3 mcb-3 pcx-3 "}>
            <div className={isMobile ?"monitoring-title2 fs-14":"monitoring-title2"}
                 data-tip={""}
                 data-for="trainingZoneHelper"
            >
                {t('settings.profil.discipline')}
            </div>
            <ReactTooltip key="tooltip-training-zone"
                          id="trainingZoneHelper"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip"
                          effect="solid"
                          place="bottom"
            />
            <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                  onClick={() => setIsDisciplineOpen(isDisciplineOpen => !isDisciplineOpen)}
            >
                <i className={isDisciplineOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
            </span>
            {isDisciplineOpen ?
                <>
                    <div className="grey-line-2 mcy-3 pcx-3"></div>
                    <div className="row mct-5">
                        <div className="col-5 pr-0">
                            <p className="w-100 text-left label-home mcb-1 fs-14">{t('finalize_registration.sport')} :
                                *</p>
                        </div>
                        <div className="col-5 pr-0">
                            <p className="w-100 text-left label-home mcb-1 fs-14">{t('finalize_registration.level.label')} :
                                *</p>
                        </div>
                    </div>

                    <>
                        <AthleteSports userProfile={userProfile}
                                       onChangeUserProfile={(field,value) => onChangeUserProfile(field,value)} />
                    </>
                </>
                :
                <></>
            }

        </div>
    )
}
export default AthleteSportsDropdown