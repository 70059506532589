import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import {useIsMounted} from "../Tools/CustomHooks";
import {
    arrow_left,
    calendar,
    duration_icon,
    heart_favorites,
    heart_grey,
    star_full_1,
    star_full_2,
    star_full_3,
    star_full_4,
    star_full_5,
    star_1,
    star_2,
    star_3,
    star_4,
    star_5,
    target,
    user,
    x_circle
} from "../../img/img";
import Loader from "react-loader-spinner";
import WorkoutTemplateDisciplineCube from "./WorkoutTemplateDisciplineCube";
import {time_convert} from "../../Functions/TextMaths";
import {getLevelIcon, tranformDisciplines} from "./Utils";
import WorkoutTemplateExercise from "./WorkoutTemplateExercise";
import WorkoutTemplatePlan from "./WorkoutTemplatePlan";
import WorkoutTemplateFavoritesList from "./WorkoutTemplateFavoritesList";
import {NavigationContext} from "../../Contexts";

const WorkoutTemplateContent = ({workoutTemplateId, closeWorkoutTemplateModal, isFavorite, workoutTemplateLists}) => {
    const {t} = useTranslation(['app'])

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const isMounted = useIsMounted()

    const [workoutTemplate, setWorkoutTemplate] = useState(null)
    const [showFavoriteWidget, setShowFavoriteWidget] = useState(false)
    const [showPlanWidget, setShowPlanWidget] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [openCloseRating, setOpenCloseRating] = useState(null)

    const [reviewScoreValues, setReviewScoreValues] = useState(null)
    const [reviewScoreStarValue, setReviewScoreStarValue] = useState(null)
    const [reviewScoreStarIcon, setReviewScoreStarIcon] = useState(null)

    useEffect(() => {
        getWorkoutTemplate()
    }, [])

    useEffect(() => {
        if(workoutTemplate && workoutTemplate.review_score_values.count.total > 0) {
            setReviewScoreValues(workoutTemplate.review_score_values)

            let starValue = Math.round(workoutTemplate.review_score_values.avg)
            setReviewScoreStarValue(starValue)

            let starIcon = star_1
            switch(starValue) {
                case 1:
                    starIcon = star_full_1
                    break
                case 2:
                    starIcon = star_full_2
                    break
                case 3:
                    starIcon = star_full_3
                    break
                case 4:
                    starIcon = star_full_4
                    break
                case 5:
                    starIcon = star_full_5
                    break
            }
            setReviewScoreStarIcon(starIcon)
        }
    }, [workoutTemplate])

    const getWorkoutTemplate = () => {
        axios
            .get(
                config+"api/workout_templates/"+workoutTemplateId,
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let datas = response.data
                setWorkoutTemplate(datas)
                setLoaded(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }


    const getTitleColor = () => {
        const disciplines = workoutTemplate.disciplines
        let numberOfDisciplines = workoutTemplate.disciplines.length
        let nameColor = 'color-black';
        if (numberOfDisciplines > 1) {
            nameColor = "color-multi"
        }
        if (numberOfDisciplines === 1) {
            switch (disciplines[0].code) {
                case 'nat':
                    nameColor = "color-nat"
                    break;
                case 'velo':
                    nameColor = "color-velo"
                    break;
                case 'cap':
                    nameColor = "color-cap"
                    break;
                case 'duathlon':
                case 'triathlon':
                    nameColor = "color-multi"
                    break;
                case 'muscu':
                case 'renfo':
                    nameColor = "color-muscu"
                    break;
                default:
                    nameColor = "color-other"
                    break;
            }
        }
        return nameColor
    }

    const contextDiv = (context) => {
        return (
            <div className="d-flex align-items-center text-gotham fs-14 mcb-3">
                <div>
                    <WorkoutTemplateDisciplineCube discipline={context.discipline.code}/>
                </div>
                <div className={"text-left"}>{t('app.workout_template.context')} <span className="font-weight-bold">{context.fullname}</span></div>
            </div>
        )
    }

    const sectionDiv = (section) => {
        return (
            <>
                <div className="text-caprina font-weight-bold fs-16 mcb-3 text-left">
                    {section.repetitions}x {section.name}
                </div>
                {section.exercises.map(exercise =>
                    <WorkoutTemplateExercise key={exercise.id} exercise={exercise}/>
                )}
            </>

        )
    }

    return (
        <div className={`${isMobile ? "card-float-dashboard-mobile w-100" : "col-6 card-float-dashboard p-3"} mb-4 z-index-1`}>
            {loaded ?
                <>
                    <div className="dashboard-card background-white">
                        <div className={"d-flex align-items-center pc-3 justify-content-between"}>
                            {isMobile &&
                            <img
                                className="icon-24 pointer right mcr-2"
                                src={arrow_left}
                                alt="arrow left icon"
                                onClick={() => closeWorkoutTemplateModal()}
                            />
                            }
                            <div className="d-flex align-items-center w-100">
                                {tranformDisciplines(workoutTemplate.disciplines).map((discipline) =>
                                    <WorkoutTemplateDisciplineCube
                                        key={"wt-"+workoutTemplate.id+"-"+discipline.code}
                                        discipline={discipline.code}
                                    />
                                )}
                                <div className="text-left mcl-3">
                                    <p className={"text-caprina fs-18 mb-0 "+getTitleColor()}>
                                        {workoutTemplate.name}
                                    </p>
                                </div>
                                {workoutTemplate.in_top_10 &&
                                <div className="top-10-cube mcl-3">
                                    TOP 10
                                </div>
                                }
                            </div>
                            {reviewScoreValues &&
                                <div className="d-flex align-items-center pointer"
                                     onClick={() => setOpenCloseRating(true)}
                                >
                                    <img className="icon-24 mcr-1" src={reviewScoreStarIcon}/>
                                    <p className="text-gotham my-auto" style={{ width: '50px', marginRight: '10px', fontSize: '12px'}}>
                                        <span className="fw-bold">
                                            {reviewScoreValues.avg}
                                        </span>
                                        {" (" + reviewScoreValues.count.total +")"}
                                    </p>
                                </div>
                            }
                            {!isMobile &&
                                <img
                                    className="icon-24 pointer right"
                                    src={x_circle}
                                    alt="close icon"
                                    onClick={() => closeWorkoutTemplateModal()}
                                />
                            }
                        </div>
                        {openCloseRating &&
                            <div className="mb-4 mct-4 card-float-rating z-index-1">
                                <div className="header-rating background-white pc-3 d-flex align-items-center justify-content-between">
                                    <p className="text-caprina fs-16 mb-0 mcr-5">{t('app.workout_template.workout_rating.title')}</p>
                                    <img
                                        className="icon-24 pointer right"
                                        src={x_circle}
                                        alt="close icon"
                                        onClick={() => setOpenCloseRating(false)}
                                    />
                                </div>
                                <div className="popup-card dashboard-card background-white  pcy-3">
                                    <div className="mcx-4 d-flex align-items-center justify-content-center rating-card">
                                        <img className="icon-24 mcr-3" src={reviewScoreStarValue > 0 ? star_full_1 : star_1}/>
                                        <img className="icon-24 mcr-3" src={reviewScoreStarValue > 1 ? star_full_2 : star_2}/>
                                        <img className="icon-24 mcr-3" src={reviewScoreStarValue > 2 ? star_full_3 : star_3}/>
                                        <img className="icon-24 mcr-3" src={reviewScoreStarValue > 3 ? star_full_4 : star_4}/>
                                        <img className="icon-24" src={reviewScoreStarValue > 4 ? star_full_5 : star_5}/>
                                    </div>
                                    <p className="gotham-12 pct-2">{reviewScoreValues.avg + " (" + reviewScoreValues.count.total + " " + t('app.workout_template.workout_rating.ratings')+ ")"}</p>
                                    <div className="">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="col-4 text-left gotham-12">{t('app.workout_template.workout_rating.rating_5')}</span>
                                            <div className="col-6 progress-bar-rating p-0 ">
                                                <div className="state-bar-green " style={
                                                    { width: (100*reviewScoreValues.count.value_5/reviewScoreValues.count.total) + '%', height:'10px', borderRadius: '8px' }
                                                }>
                                                </div>
                                            </div>
                                            <span className="text-left gotham-12 col-2">{reviewScoreValues.count.value_5}</span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="col-4 text-left gotham-12">{t('app.workout_template.workout_rating.rating_4')}</span>
                                            <div className="col-6 progress-bar-rating p-0 ">
                                                <div className="state-bar-light-green " style={
                                                    { width: (100*reviewScoreValues.count.value_4/reviewScoreValues.count.total) + '%', height:'10px', borderRadius: '8px' }
                                                }>
                                                </div>
                                            </div>
                                            <span className="text-left gotham-12 col-2">{reviewScoreValues.count.value_4}</span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="col-4 text-left gotham-12">{t('app.workout_template.workout_rating.rating_3')}</span>
                                            <div className="col-6 progress-bar-rating p-0 ">
                                                <div className="state-bar-yellow " style={
                                                    { width: (100*reviewScoreValues.count.value_3/reviewScoreValues.count.total) + '%', height:'10px', borderRadius: '8px' }
                                                }>
                                                </div>
                                            </div>
                                            <span className="text-left gotham-12 col-2">{reviewScoreValues.count.value_3}</span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="col-4 text-left gotham-12">{t('app.workout_template.workout_rating.rating_2')}</span>
                                            <div className="col-6 progress-bar-rating p-0 ">
                                                <div className="state-bar-orange " style={
                                                    { width: (100*reviewScoreValues.count.value_2/reviewScoreValues.count.total) + '%', height:'10px', borderRadius: '8px' }
                                                }>
                                                </div>
                                            </div>
                                            <span className="text-left gotham-12 col-2">{reviewScoreValues.count.value_2}</span>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="col-4 text-left gotham-12">{t('app.workout_template.workout_rating.rating_1')}</span>
                                            <div className="col-6 progress-bar-rating p-0">
                                                <div className="state-bar-bold-orange " style={
                                                    { width: (100*reviewScoreValues.count.value_1/reviewScoreValues.count.total) + '%', height:'10px', borderRadius: '8px' }
                                                }>
                                                </div>
                                            </div>
                                            <span className="text-left gotham-12 col-2">{reviewScoreValues.count.value_1}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="popup-card pc-4 d-flex flex-column">
                            {workoutTemplate.description &&
                            <div className="text-gotham fs-14 text-left mcb-4">
                                {workoutTemplate.description}
                            </div>
                            }
                            {workoutTemplate.category &&
                            <div className="text-gotham fs-14 text-left mcb-3">
                                <div className="d-flex">
                                    <img
                                        className="icon-24 mcr-3"
                                        src={target}
                                        alt="target icon"
                                    />
                                    <div>
                                        <span className="font-weight-bold text-caprina lh-24">{workoutTemplate.category.name}</span><br/>
                                        <span className="fs-12">{workoutTemplate.category.goal}</span>
                                    </div>
                                </div>
                            </div>
                            }
                            {workoutTemplate.estimated_duration &&
                            <div className="text-gotham fs-14 text-left mcb-3">
                                <div className="d-flex align-items-center lh-24">
                                    <img
                                        className="icon-24 mcr-3"
                                        src={duration_icon}
                                        alt="duration icon"
                                    />
                                    {time_convert(workoutTemplate.estimated_duration)}
                                </div>
                            </div>
                            }
                            {workoutTemplate.level &&
                            <div className="text-gotham fs-14 text-left mcb-3">
                                <div className="d-flex align-items-center lh-24">
                                    <img
                                        className="icon-24 mcr-3"
                                        src={getLevelIcon(workoutTemplate.level)}
                                        alt="level icon"
                                    />
                                    {t('app.workout_template.level.'+workoutTemplate.level)}
                                </div>
                            </div>
                            }

                            <div className="text-gotham fs-14 text-left mcb-3">
                                <div className="d-flex align-items-center lh-24">
                                    <img
                                        className="icon-24 mcr-3"
                                        src={user}
                                        alt="user icon"
                                    />
                                    {workoutTemplate.coach ?
                                        workoutTemplate.coach.fullname
                                        :
                                        "GUTAÏ Training"
                                    }
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="btn-blue-white mcr-3"
                                     onClick={() => setShowPlanWidget(!showPlanWidget)}
                                >
                                    <img
                                        className="icon-24 mcr-2"
                                        src={calendar}
                                        alt="calendar icon"
                                    />
                                    {t('app.plan')}
                                </div>
                                <div className={`${isFavorite ? "is-favourite-btn" : "search-filter-btn"} cursor-pointer`}
                                     onClick={() => setShowFavoriteWidget(!showFavoriteWidget)}
                                >
                                    <img
                                        className="icon-24 mcr-2 cursor-pointer"
                                        src={isFavorite ? heart_favorites : heart_grey}
                                        alt="heart icon"
                                    />
                                    {t('app.workout_template.filters.favorites')}
                                </div>
                                {showPlanWidget &&
                                    <WorkoutTemplatePlan
                                        workoutTemplateId={workoutTemplateId}
                                        closeWorkoutTemplateModal={closeWorkoutTemplateModal}
                                        closeWidget={() => setShowPlanWidget(false)}
                                    />
                                }
                                {showFavoriteWidget &&
                                <WorkoutTemplateFavoritesList
                                    workoutTemplateId={workoutTemplateId}
                                    workoutTemplateLists={workoutTemplateLists}
                                />
                                }
                            </div>
                            <div className="separator-grey mcy-4"/>
                            <div className="text-caprina font-weight-bold fs-16 mcb-3 text-left">
                                {t('app.context.title')}s
                            </div>
                            {workoutTemplate.run_context &&
                            contextDiv(workoutTemplate.run_context)
                            }
                            {workoutTemplate.cycle_context &&
                            contextDiv(workoutTemplate.cycle_context)
                            }
                            {workoutTemplate.swim_context &&
                            contextDiv(workoutTemplate.swim_context)
                            }
                            <div className="separator-grey mcb-4 mct-2"/>
                            {workoutTemplate.sections.map(section =>
                                sectionDiv(section)
                            )}
                        </div>

                    </div>
                </>

                :
                <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                    <Loader
                        type="TailSpin"
                        color="black"
                        height={50}
                        width={50}
                        className="my-5"
                    />
                </div>
            }

        </div>
    );
};

export default WorkoutTemplateContent;