import React, {useContext} from "react";
import {NavigationContext, SplashScreenSettingContext} from "../../Contexts";
import {check, gutai_login_desktop} from "../../img/img";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import {useHistory} from "react-router";

const SplashScreenAutonom = ({setShowPopup}) => {
    const {isMobile} = useContext(NavigationContext)
    const {t} = useTranslation(['app'])
    let history = useHistory()

    const {
        updateShowSubscribeAutonom,
        splashScreenSettingSubscribeAutonom,
        updateSplashScreenSettingSubscribeAutonom
    } = useContext(SplashScreenSettingContext)

    const editShowSubscribeAutonom = async () => {
        let datas = {}
        datas.state = "ignore"

        await axios.put(
            config + "api/splash_screen_settings/"+splashScreenSettingSubscribeAutonom.id,
            datas,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
            .then(response => {
                let newSplashScreenSettingSubscribeAutonom = {...splashScreenSettingSubscribeAutonom}
                newSplashScreenSettingSubscribeAutonom.state = "ignore"
                updateShowSubscribeAutonom(false)
                updateSplashScreenSettingSubscribeAutonom(newSplashScreenSettingSubscribeAutonom)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const onIgnore = () => {
        setShowPopup(false)
        editShowSubscribeAutonom()
    }

    const onSubscribe = () => {
        history.push("/public/subscription/autonom")
    }

    return (
        <div className="background-light-blue">
            <div className={isMobile ? "" : "d-flex justify-content-end background-splashscreen-autonom"}>
                <div className={isMobile ? "pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-30 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                    <div>
                        <img
                            src={gutai_login_desktop}
                            className={isMobile ? "logo-gutai-home-mobile" : "logo-gutai-home"}
                            alt="Gutai logo"
                        />
                    </div>
                    <h3 id="text-middle-login" className={isMobile ? "splashscreen-title my-3" : "splashscreen-title text-middle-login-desktop"}>
                        {t('app:splashscreen_autonom.title1')}<br/>{t('app:splashscreen_autonom.title2')}<br/><span className="text-blue">{t('app:splashscreen_autonom.price')}</span>
                    </h3>
                    <p className="gotham-16">{t('app:splashscreen_autonom.subtitle')}</p>
                    <div className="splashscreen-autonom-content pcx-2 pct-3 mcb-5">
                        <p className="gotham-12"><img className="icon-24 mcr-1" src={check}/>{t('app:splashscreen_autonom.text1')}</p>
                        <p className="gotham-12"><img className="icon-24 mcr-1" src={check}/>{t('app:splashscreen_autonom.text2')}</p>
                        <p className="gotham-12"><img className="icon-24 mcr-1" src={check}/>{t('app:splashscreen_autonom.text3')}</p>
                    </div>
                    <Button className='mx-auto home-btn home-btn-text pcx-4 pcy-2 d-flex align-items-center justify-content-center m-0'
                    >
                        <p className="my-auto home-btn-p-text" onClick={() => onSubscribe()}>{t('app:splashscreen_autonom.btn_subscribe')}</p>
                    </Button>
                    <span className="text-grey-normal mct-2 pointer" onClick={() => onIgnore()}>{t('app:splashscreen_autonom.ignore')}</span>
                </div>
            </div>
        </div>
    )
}
export default SplashScreenAutonom