import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import {LoggedInContext, TalkJSContext, WeekCalendarContext} from "../../../Contexts";


const CommentDebriefing = ({comment, onChangeComment, haveComment, conversationId}) => {
    const {t} = useTranslation(['app'])

    const {coach_id, is_coaching_smart} = useContext(LoggedInContext)
    const {toggleChat, updateConversationId} = useContext(TalkJSContext)
    const {updateTrainingEventOpen} = useContext(WeekCalendarContext)

    const openConversation = () => {
        // Fermer la popup
        updateTrainingEventOpen(false)
        // Ouvrir le chat
        toggleChat()
        setTimeout(() => {
            // Sélectionner la conversation adéquate
            updateConversationId(conversationId)
        }, 700)
    }

    return (
        <>
            <div className="col-12">
                <p className="rpe-title text-left">{t('app:athlete.debriefing.comment.title')}</p>
            </div>
            {!haveComment ?
                <div className="col-12">
                    {coach_id !== null && !is_coaching_smart &&
                    <p className="text-left text-p-cr mb-0">{t('app:athlete.debriefing.rpe.content.5')}</p>
                    }
                    <Form.Group controlId="form-comment">
                        <Form.Control className="mcy-2 form-login-input pc-3 background-white"
                                      as="textarea"
                                      rows={3}
                                      name="comment"
                                      placeholder={t('app:athlete.debriefing.comment.placeholder')}
                                      value={comment}
                                      onChange={event => onChangeComment(event.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                </div>
                :
                <div className="col-12">
                    <div className="comment-div">
                        <span className="text-white">{comment}</span>
                    </div>
                    {conversationId &&
                    <span className="show-conversation pointer w-100" onClick={() => openConversation()}>{t('app:athlete.debriefing.comment.show_conversation')}</span>
                    }
                </div>
            }
        </>
    )
}

export default CommentDebriefing;