import React, {useContext} from 'react';
import {NavigationContext} from "../../Contexts";
import {useTranslation} from "react-i18next";
import Loader from "react-loader-spinner";
import {AUTHOR_DEFAULT_IMAGE} from "./Utils";


const WorkoutTemplateAuthors = ({authors, loaded, setAuthorId}) => {
    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const {t} = useTranslation(['app'])

    return (
        <div className={`w-100 mcb-5 ${isMobile ? "pcx-2" : "pcx-3"}`}>
            <p className="seance-title2 mcb-3 text-left">
                {t('app.workout_template.filters.authors')}
            </p>
            {loaded ?
                <div className={`w-100 d-flex justify-content-start ${isMobile ? "content-scrollable" : "flex-wrap"}`}>
                    {authors.length>0 ?
                        authors.map((author, index) => {
                            if(author.count === 0
                                // TODO: à supprimer
                                || [14241, 1094].includes(author.id) === false
                            ) {
                                return null
                            }

                            let imgUrl = author.photo_medium ? author.photo_medium : AUTHOR_DEFAULT_IMAGE
                            let authorTitle = author.firstname + ' ' + author.lastname
                            return (
                                <div key={"auhtor"+index} className="d-flex justify-content-start">
                                    <div className="search-card text-left cursor-pointer"
                                         onClick={() => setAuthorId(author.id)}
                                    >
                                        <div className="mcb-2 search-card-img"
                                             style={{backgroundImage: "url("+imgUrl+")"}}
                                        />
                                        <p className="text-caprina font-weight-bold fs-15 mcb-2">
                                            {authorTitle}
                                        </p>
                                        <p className="text-gotham fs-13">
                                            {author.count+" "+t('app.workout_template.count.'+(author.count === 1 ? 'session':'sessions'))}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="d-flex justify-content-center m-auto">
                                {t('app.workout_template.no_author')}
                        </div>
                    }
                </div>
                :
                <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                    <Loader
                        type="TailSpin"
                        color="black"
                        height={50}
                        width={50}
                        className="my-5"
                    />
                </div>
            }
        </div>
    );
};


export default WorkoutTemplateAuthors;