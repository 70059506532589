import {useTranslation} from "react-i18next";
import React, {useContext, useState} from "react";
import {WeekCalendarContext} from "../../../Contexts";
import generateHeaders from "../../../Functions/generateHeaders";
import axios from "axios";
import config from "../../../api";
import uniqid from "uniqid";
import handleEndpointError from "../../../Functions/Alert";
import Loader from "react-loader-spinner";
import {edit, trash, copy} from "../../../img/img";
import {useIsMounted} from "../../Tools/CustomHooks";

const AthleteAvailabilityPanel = ({athleteAvailability, panelClassName, editAthleteAvailability,openAthleteAvailabilityModal, closePanel}) => {
    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()

    const {updateCalendarEventToRefresh, updateAthleteAvailabilityEventHandled} = useContext(WeekCalendarContext)

    // Indique si la partie de confirmation de suppression est ouverte
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    let isEditable = athleteAvailability
    let isDuplicatable = false
    let isDeletable = true

    // Supprimer une séance
    const deleteAthleteAvailability = () => {
        let headers = generateHeaders()
        if(headers){
            axios
                .delete(
                    config+"api/athlete_availabilities/"+athleteAvailability.id,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    if(!isMounted.current){
                        return 0;
                    }
                    // Cacher le loader
                    setDeleteLoading(false)
                    // Fermer la popup
                    setDeleteOpen(false)

                    if(response.data.error) {
                        console.log('deleteAthleteAvailability error', response.data.error)
                    }
                    // Recharger le calendrier
                    updateCalendarEventToRefresh(uniqid("athlete-availability-"+athleteAvailability.id))
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Action lors de la suppression qui peut être confirmée ou non
    const onConfirmDelete = (confirmed) => {
        if(confirmed) {
            setDeleteLoading(true)
            deleteAthleteAvailability()
        }
        else (
            setDeleteOpen(false)
        )
    }

    const getDeleteLoader = () => {
        return(
            <Loader
                type="TailSpin"
                color="#0069d9"
                height={50}
                width={50}
                className="my-3"
            />
        )
    }

    // Afficher la confirmation de suppression
    const confirmDelete = () => {
        return (
            <div className="w-100 text-center delete-red height-suppress-workout">
                <img
                    src={trash}
                    alt="trash icon"
                    className="trash mx-auto icon-24 d-block"
                />
                {t('app:workout.panel.delete')} ?
                <div className="d-flex justify-content-center mt-3">
                    <div className="btn-confirmSuppress btn-panel d-flex align-items-center justify-content-center"
                         onClick={() => onConfirmDelete(true)}>
                        <span className="text-btn-panel-yes">
                            {t('app:menu.yes')}
                        </span>
                    </div>
                    <div className="btn-cancelSuppress btn-panel d-flex align-items-center justify-content-center ml-3"
                         onClick={() => onConfirmDelete(false)}>
                        <span className="text-btn-panel-no">
                            {t('app:menu.no')}
                        </span>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <div className={"dropdown-content pointer fs-12 "+ panelClassName}>
            <div className="d-flex flex-column">
                {!deleteOpen &&
                <>
                    {isEditable &&
                    <p className="w-100 d-flex align-items-center pl-0 move-training-availability"
                       onClick={() => {openAthleteAvailabilityModal(athleteAvailability.start_date)
                                        updateAthleteAvailabilityEventHandled(athleteAvailability)
                                        closePanel()
                       }}
                    >
                        <img
                            src={edit}
                            alt="edit icon"
                            className="mx-3 icon-24"
                        />
                        {t('app:workout.panel.edit')}
                    </p>
                    }
                    {isDuplicatable &&
                    <p className="w-100 d-flex align-items-center pl-0 move-training-availability"
                       onClick={() => {}}
                    >
                        <img
                            src={copy}
                            alt="copy icon"
                            className="mx-3 icon-24"
                        />
                        {t('app:workout.panel.duplicate')}
                    </p>
                    }
                    {isDeletable &&
                    <p className="w-100 text-left delete-red d-flex align-items-center pl-0"
                       onClick={() => setDeleteOpen(true)}
                    >
                        <img
                            src={trash}
                            alt="trash icon"
                            className="mx-3 icon-24"
                        />
                        {t('app:workout.panel.delete')}
                    </p>
                    }

                </>
                }

                {deleteOpen ?
                    deleteLoading ? getDeleteLoader() : confirmDelete()
                    : <></>
                }
            </div>
        </div>

    )
}

export default AthleteAvailabilityPanel