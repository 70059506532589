import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";
import DisciplineContexts from "./DisciplineContexts";
import {useIsMounted} from "../../Tools/CustomHooks";

const WorkoutContexts = ({workout, getWorkout}) => {

    const isMounted = useIsMounted()

    const [contextsRun, setContextsRun] = useState([])
    const [contextsSwim, setContextsSwim] = useState([])
    const [contextsCycle, setContextsCycle] = useState([])
    const [currentCheck, setCurrentCheck] = useState({
        cap: workout.run_context,
        nat: workout.swim_context,
        velo: workout.cycle_context
    })
    const [haveContext, setHaveContext] = useState(false)

    const findAllContexts = (discipline) => {
        let headers = generateHeaders()
        if (headers) {
            axios
                .get(
                    config+"api/contexts?only_without_parent=1&discipline_id="+discipline.id,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    if(!isMounted.current){
                        return 0;
                    }
                    switch (discipline.code) {
                        case 'cap':
                            for (let i=0; i < response.data.contexts.length; i++) {
                                setContextsRun(oldArray =>[...oldArray, response.data.contexts[i]])
                                setHaveContext(true)
                            }
                            break
                        case 'nat':
                            for (let i=0; i < response.data.contexts.length; i++) {
                                setContextsSwim(oldArray =>[...oldArray, response.data.contexts[i]])
                                setHaveContext(true)
                            }
                            break
                        case 'velo':
                            for (let i=0; i < response.data.contexts.length; i++) {
                                setContextsCycle(oldArray =>[...oldArray, response.data.contexts[i]])
                                setHaveContext(true)
                            }
                            break
                    }
                })

                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    const changeContexts = (checkToSave) => {
        let run_context, swim_context, cycle_context
        if (checkToSave.cap) {
            run_context = checkToSave.cap.id
        }
        if (checkToSave.nat) {
            swim_context = checkToSave.nat.id
        }
        if (checkToSave.velo) {
            cycle_context = checkToSave.velo.id
        }

        let headers = generateHeaders()
        if (headers) {
            axios
                .put(
                    config+"api/workouts/"+workout.id,
                    {
                        runContext : run_context,
                        swimContext : swim_context,
                        cycleContext : cycle_context,
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    if(!isMounted.current){
                        return 0;
                    }
                    setTimeout(getWorkout,300)
                })

                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    useEffect(() => {
        for (let i=0; i<workout.disciplines.length; i++) {
            let discipline = workout.disciplines[i]
            findAllContexts(discipline)
        }
    }, [])

    const handleCheck = (event, context) => {
        const targetName = event.target.name
        setCurrentCheck((prevCurrentCheck) => {
            const newCurrentCheck = {...prevCurrentCheck}
            newCurrentCheck[targetName] = context
            changeContexts(newCurrentCheck)
            return newCurrentCheck
        })
    }

    if (haveContext) {
        return (
            <Container fluid="md" className="px-0">
                {haveContext &&
                    <div className="seance-title2 text-left">Conditions</div>
                }
                {contextsRun.length > 0 &&
                    <DisciplineContexts key="run-contexts"
                                        discipline={'cap'}
                                        workout={workout}
                                        contexts={contextsRun}
                                        currentCheck={currentCheck}
                                        handleCheck={(event, context) => handleCheck(event, context)}
                    />
                }
                {contextsSwim.length > 0 &&
                    <DisciplineContexts key="swim-contexts"
                                        discipline={'nat'}
                                        workout={workout}
                                        contexts={contextsSwim}
                                        currentCheck={currentCheck}
                                        handleCheck={(event, context) => handleCheck(event, context)}
                    />
                }
                {contextsCycle.length > 0 &&
                    <DisciplineContexts key="cycle-contexts"
                                        discipline={'velo'}
                                        workout={workout}
                                        contexts={contextsCycle}
                                        currentCheck={currentCheck}
                                        handleCheck={(event, context) => handleCheck(event, context)}
                    />
                }
            </Container>
        )
    } else {
        return null
    }
}

export default WorkoutContexts;
