import React, {useEffect, useContext} from 'react';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import '../Css/Home.css';
import {HeaderHome} from "../Components/Home/HeaderHome";

import {AuthContext, LanguageContext, NavigationContext} from "../Contexts";

import Login from "../Components/Forms/Login";
import {updateLanguageFromSearch} from "../Functions/Utils";

const LoginPage = (props) => {
    //Gestion de la langue selon l'URL
    const {updateLanguage} = useContext(LanguageContext)
    useEffect(() =>  {
        updateLanguageFromSearch(updateLanguage, props.location.search)
    }, [])

    let history = useHistory()

    const {t} = useTranslation(['app'])
    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    // Mettre à jour l'authentification
    const {token} = useContext(AuthContext)

    const referer = props.location.state && props.location.state.referer ? (props.location.state.referer.pathname+props.location.state.referer.search) : '/dashboard';

    const onSuccess = () => {
        history.push(referer)
    }

    useEffect(() => {
        if(token) {
            onSuccess()
        }
    }, [token])

    const switchToRegister = () => {
        history.push("/registration")
    }

    return (
        <div className={isMobile ? "" : "d-flex justify-content-end background-nat"}>
            <div className={isMobile ? "pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-30 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                <HeaderHome />
                <p id="text-middle-login" className={isMobile ? "text-middle-login my-auto" : "text-middle-login text-middle-login-desktop"}>{t('app:login.head_message')}</p>
                <Login
                    onSuccess={() => onSuccess()}
                    switchToRegister={() => switchToRegister()}
                />
            </div>
        </div>
    )
}

export default LoginPage