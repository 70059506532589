import {DISCIPLINES_WORKOUT_TEMPLATE} from "./Utils";
import React from "react";

const WorkoutTemplateDisciplineCube = ({discipline, noMargin = false}) => (
    <div className={`search-discipline-cube ${noMargin ? "" : "mcr-2"}`}
         style={{backgroundColor: DISCIPLINES_WORKOUT_TEMPLATE[discipline].color}}
    >
        <img className="icon-24" src={DISCIPLINES_WORKOUT_TEMPLATE[discipline].icon_set} alt="discipline icon"/>
    </div>
)

export default WorkoutTemplateDisciplineCube