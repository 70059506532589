import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams, useHistory} from "react-router";
import {useIsMounted} from "../../Tools/CustomHooks";
import {Container} from "react-bootstrap";
import Loader from "react-loader-spinner";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";
import {arrow_left} from "../../../img/img";

import ActivitySessionLapsTable from "./ActivitySessionLapsTable"

const ActivitySessionLaps = () => {

    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()

    const {activitySessionId} = useParams()
    const history = useHistory()

    const [activitySessionLaps, setActivitySessionLaps] = useState(null)
    const [discipline, setDiscipline] = useState(null)

    const getActivitySessionLaps = () => {
        const headers = generateHeaders()
        axios
            .get(
                config+"api/activity_sessions/"+activitySessionId,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let laps = response.data.laps
                setActivitySessionLaps(laps)
                setDiscipline(response.data.discipline.code)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    useEffect(() => {
        getActivitySessionLaps()
    }, [])



    if(!activitySessionLaps) {
        return(
            <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={50}
                    width={50}
                    className="my-5"
                />
            </div>
        )
    }


    return (
        <Container fluid className="landscape lapsCard justify-content-center lapsMobile">
            <div className="d-flex justify-content-start">
                <div className="d-flex align-items-center p-3 pointer" onClick={() => history.goBack()}>
                    <img
                        className="icon-24 mcr-2"
                        src={arrow_left}
                        alt="arrow left icon"
                    />
                    <h2 className="seance-title2 mb-0">{t('app:intervals_intensity')}</h2>
                </div>
            </div>
            <ActivitySessionLapsTable
                activitySessionId={activitySessionId}
                activitySessionLaps={activitySessionLaps}
                discipline={discipline}
                format="full"
            />
        </Container>
    )
}

export default ActivitySessionLaps;