import md5 from "md5";
import uniqid from "uniqid";
import base64 from "base-64";
import sha1 from "js-sha1";

export default function generateHeaders(){
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')
    const username = localStorage.getItem('username') || sessionStorage.getItem('username')

    if (token) {
        let nonceBase = md5(uniqid("nonce")).substr(0, 16);
        let nonce = base64.encode(nonceBase);

        let timestamp = Math.floor(Date.now() / 1000);

        let digestBase = sha1(nonceBase + timestamp + token, false);
        let digest = base64.encode(digestBase);

        return 'UsernameToken Username="' + username +
            '", PasswordDigest="' + digest +
            '", Nonce="' + nonce +
            '", Created="' + timestamp + '"'
    }
}