import L from 'leaflet';

const EndIcon = new L.Icon({
    iconUrl: require('../../../../img/marker-end.png'),
    iconRetinaUrl: require('../../../../img/marker-end.png'),
    iconSize: new L.Point(20, 20),
    className: 'end-marker-icon',
});

export default EndIcon
