import React, {useContext, useEffect, useState} from 'react';
import {CriticalIntensityStatistics, getCIPacingLabelWithUnit} from "../TrainingConcept/Utils";
import Loader from "react-loader-spinner";
import {useTranslation} from "react-i18next";
import {MetricContext, NavigationContext} from "../../Contexts";
import moment from "moment";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";

const CriticalIntensityStatistic = ({discipline, updateRunningCIStatistic}) => {
    const {t} = useTranslation(['app'])
    const {metric, runningCriticalIntensityStatistic} = useContext(MetricContext)

    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    const [loaded, setLoaded] = useState(false)
    const [isPace, setIsPace] = useState(true)

    let criticalIntensityPace = getCIPacingLabelWithUnit(discipline, t, metric, "pace")
    let criticalIntensitySpeed = getCIPacingLabelWithUnit(discipline, t, metric, "speed")

    useEffect(() => {
        switch (runningCriticalIntensityStatistic) {
            default:
            case CriticalIntensityStatistics.PACE:
                setIsPace(true)
                break
            case CriticalIntensityStatistics.SPEED:
                setIsPace(false)
                break
        }
        setLoaded(true)
    },[runningCriticalIntensityStatistic])

    // Mettre les données du profil utilisateur à jour
    const handleChange = async (runningCriticalIntensity) => {
        setLoaded(false)
        let datas = {}
        datas.show_running_critical_intensity = runningCriticalIntensity

        await axios.put(
            config + "api/my_profile",
            datas,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
            .then(response => {
                updateRunningCIStatistic(runningCriticalIntensity)
                setLoaded(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    return (
        <>
            {loaded ?
            <>
                <div className="training-concept-config">
                    <p className="title-pref text-left">
                        {t('app:settings.preferences.intensity_display')}
                    </p>
                </div>
                <>
                    <div className="context-card mcb-3">
                        <div className="d-flex">
                            <div className="checkbox-container-orange w-100 mcb-2">
                                <input
                                    id={"running_critical_intensity_radio"}
                                    name={"running_critical_intensity_radio"}
                                    type="radio"
                                    value="1"
                                    checked={isPace}
                                    onClick={() => {
                                        handleChange(CriticalIntensityStatistics.PACE)
                                    }}
                                />
                                <label htmlFor={"running_critical_intensity_radio"}
                                       className={isMobile ? "text-subscription-program align-items-center" : "align-items-center text-subscription-program-desktop"}
                                >
                                    {criticalIntensityPace}
                                </label>
                            </div>

                            <div className="checkbox-container-orange w-100 mcb-2 mcl-3">
                                <input
                                    id={"running_heartrate_radio"}
                                    name={"running_heartrate_radio"}
                                    type="radio"
                                    value="0"
                                    checked={!isPace}
                                    onClick={() => {
                                        handleChange(CriticalIntensityStatistics.SPEED)
                                    }}
                                />
                                <label htmlFor={"running_heartrate_radio"}
                                       className={isMobile ? "text-subscription-program align-items-center" : "align-items-center text-subscription-program-desktop"}
                                >
                                    {criticalIntensitySpeed}
                                </label>
                            </div>
                        </div>
                    </div>
                </>
            </>
            :
            <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                <Loader
                    type="TailSpin"
                    color="black"
                    height={50}
                    width={50}
                    className="my-5"
                />
            </div>
        }
        </>
    );
};

export default CriticalIntensityStatistic;
