import React, {useContext} from "react";
import LanguageSwitch from "../Components/LanguageSwitch";
import {
    send_icon,
    blue_arrow_left, gutai_login, gutai_login_desktop
} from "../img/img";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {NavigationContext} from "../Contexts";


const UserDisabled = () => {

    const {t} = useTranslation(['app'])
    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    return(
        <div className={isMobile ? "" : "d-flex justify-content-end background-error404"}>
            <LanguageSwitch />
            <div className={isMobile ? "pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-30 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                <Link to={"/"}>
                    <img
                        src={isMobile ? gutai_login : gutai_login_desktop}
                        className={isMobile ? "mt-5" : "logo-gutai-home-desktop"}
                        alt="Gutai logo"
                    />
                </Link>
                <p id="text-middle-login" className={isMobile ? "text-middle-login my-auto" : "text-middle-login text-middle-login-desktop"}>{t('app.user_disabled.title')}</p>
                <p>{t('app.user_disabled.description')}</p>
                <div id="form-login" className={isMobile ? "mb-auto w-100" : "w-100"}>
                    <div className="flex-column">
                            <a className='btn btn-primary mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center m-0'
                               href="mailto:info@gutai-training.com"
                            >
                                <img className="mcr-2 icon-24" alt="log-in icon" src={send_icon} /><p className="my-auto home-btn-p-text">{t('app.user_disabled.send_message')}</p>
                            </a>
                        <div className={"row d-flex justify-content-center align-items-center " + (isMobile ? "mct-5":"mct-1")}>
                            <div className="grey-line mcr-2"></div>
                            <p className="mb-0 grey-line-text">{t('app:login.or')}</p>
                            <div className="grey-line mcl-2"></div>
                        </div>
                        <p className="grey-line-text mcb-2 mct-2">{t('app.user_disabled.want_to_go_back')}</p>
                        <Link to={"/logout"} className="link-btn">
                            <button id="register-btn" className='mx-auto home-btn-white home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcb-2'>
                                <img className="mcr-2 icon-24" alt="valid icon" src={blue_arrow_left} /><p className="my-auto home-btn-p-text">{t('app.user_disabled.previous_page_btn')}</p>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserDisabled