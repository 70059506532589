import React, {useContext} from "react";

import {useTranslation} from "react-i18next";
import {
    star_1,
    star_2,
    star_3,
    star_4,
    star_5,
    star_full_1,
    star_full_2,
    star_full_3,
    star_full_4, star_full_5
} from "../../../img/img";
import {NavigationContext} from "../../../Contexts";


const ReviewScoreDebriefing = ({reviewScore, changeReviewScore}) => {
    const {t} = useTranslation(['app'])

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const starsEmpty = {
        1: star_1,
        2: star_2,
        3: star_3,
        4: star_4,
        5: star_5,
    }


    const starsFull = {
        1: star_full_1,
        2: star_full_2,
        3: star_full_3,
        4: star_full_4,
        5: star_full_5,
    }

    return (
        <div className="col-12">
            <p className={"rpe-title text-left mcb-3"}>{t('athlete.debriefing.review.score.title')}</p>
            <p className="text-left text-p-cr mcb-4">
                {t('athlete.debriefing.review.score.content.1')}<br/>
                {t('athlete.debriefing.review.score.content.2')}<br/>
            </p>
            <div className="w-100 d-flex justify-content-center">
                <div className="col-2 d-flex align-items-center justify-content-end">
                    <p className={`mb-0 text-right ${isMobile && "fs-10"}`}>
                        <span className="font-weight-bold">1 {t('athlete.debriefing.review.score.star')}</span> <br/>
                        {t('athlete.debriefing.review.score.mediocre')}
                    </p>

                </div>
                <div className="review-score-container pcy-3 d-flex justify-content-center align-items-center col-8">
                    {Object.keys(starsEmpty).map((star, index) =>
                        <img key={"star-"+index} onClick={() => changeReviewScore(star)} className={`${index === 0 ? "" : isMobile ? "mcl-2" : "mcl-3"} icon-24 pointer`} alt="star icon" src={(reviewScore >= star) ? starsFull[star] : starsEmpty[star]} />
                    )}
                </div>
                <div className="col-2 d-flex align-items-center justify-content-start">
                    <p className={`mb-0 text-left ${isMobile && "fs-10"}`}>
                        <span className="font-weight-bold">5 {t('athlete.debriefing.review.score.stars')}</span> <br/>
                        {t('athlete.debriefing.review.score.excellent')}
                    </p>
                </div>
            </div>

            <div className="simple-separator mcy-4"/>
        </div>
    )
}

export default ReviewScoreDebriefing