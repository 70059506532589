import {
    bell,
    message,
    settings,
    heart_favorites_empty,
    search_black,
    photo_default,
    preferences_icon,
    subscription_icon, user_plus, logout, settings_icon_blue, bell_blue, circle_notif
} from "../img/img";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {LoggedInContext, NavigationContext, TalkJSContext} from "../Contexts";
import handleLogout from "./HandleLogout";
import {Link,useHistory} from "react-router-dom";

const NewNavBtns = (props) => {

    const {notificationMessageListOpen,openPreferences,openSubscription} = props

    const {t} = useTranslation(['app']);
    const {isMobile} = useContext(NavigationContext)
    const {avatar,coach_id,has_unread_notifications,username, is_premium, user_id} = useContext(LoggedInContext)
    const {toggleChat,hasNewMessage} = useContext(TalkJSContext)

    const history = useHistory()

    const [openCloseSettings, setOpenCloseSettings] = useState(null)
    const [notificationAlreadyOpened, setNotificationAlreadyOpened] = useState(false)

    const showSearchBtn = ["schfraxx@gmail.com", "l.lepoutre@gutai-training.com"].includes(username) || (is_premium && [763, 1030, 1082, 3774].includes(user_id) === false)


    const displaySettings = () => {
       if(openCloseSettings === true) {
           setOpenCloseSettings(false)
       }
       else {
           setOpenCloseSettings(true)
       }
    }

    const openWorkoutTemplate = () => {
        history.push("/search_workout_template")
    }


    return (
        <div className="ml-auto d-flex navbtn-container">
            {showSearchBtn &&
            <div className={isMobile ? "pcr-3" : "" +( "margin-link pointer icon-40 d-flex flex-column justify-content-center align-items-center")}
                 onClick={() => openWorkoutTemplate()}
            >
                <img
                    src={search_black}
                    className="d-inline-block align-top icon-24"
                    alt="search icon"
                />
                <p className="text-gotham fs-8 pct-1">{t("app.nav_header_home.search_icon")}</p>
            </div>
            }
                {/*<div className="margin-link pointer icon-40 d-flex flex-column justify-content-center align-items-center"
                >
                    <img
                        src={heart_favorites_empty}
                        className="d-inline-block align-top icon-24"
                        alt="favorite icon"
                    />
                    <p className="text-gotham fs-8 pct-1">{t("app.nav_header_home.favorite_icon")}</p>
                </div>*/}
            <div className={isMobile ? "pcr-3" : "" +( "margin-link pointer icon-40 d-flex flex-column justify-content-center align-items-center")}
                 onClick={() => {
                     setNotificationAlreadyOpened(true)
                     props.openCloseNotificationMessageList()
                 }}
            >
                <img
                    src={notificationMessageListOpen ? bell_blue : bell}
                    className="d-inline-block align-top icon-24"
                    alt="bell icon"
                />
                {has_unread_notifications === true && !notificationAlreadyOpened &&
                <div className={isMobile ? "relative-div circle-notif-mobile" :"relative-div circle-notif"}>
                    <img
                        src={circle_notif}
                        className="icon-10"
                        alt="circle notif icon"
                    />
                </div>
                }
                <p className="text-gotham fs-8 pct-1">{t("app.nav_header_home.notif_icon")}</p>
            </div>
            {coach_id !== 0 &&
            <div className={isMobile ? "pcr-3" : "" +( "margin-link pointer icon-40 d-flex flex-column justify-content-center align-items-center")}
                 onClick={() => toggleChat()}
            >
                <img
                    src={message}
                    className="d-inline-block align-top icon-24"
                    alt="message icon"
                />
                {hasNewMessage &&
                <div className={isMobile ? "relative-div circle-notif-mobile" :"relative-div circle-notif"}>
                    <img
                        src={circle_notif}
                        className="icon-10"
                        alt="circle notif icon"
                    />
                </div>
                }
                <p className="text-gotham fs-8 pct-1">{t("app.nav_header_home.message_icon")}</p>
            </div>
            }
            <div className="margin-link pointer icon-40 justify-content-center align-items-center d-flex flex-column"
                 onClick={ () => displaySettings()}
            >
                <img
                    src={openCloseSettings ? settings_icon_blue : settings}
                    className="d-inline-block align-top icon-24"
                    alt="settings icon"
                />
                <p className="text-gotham fs-8 pct-1">{t("app.nav_header_home.settings_icon")}</p>
            </div>
            {openCloseSettings ?
                <div className={isMobile ? "d-flex flex-column settings-container-mobile" : "settings-container-desktop d-flex flex-column"}
                >
                    <div className="d-flex align-items-center pointer"
                         onClick={() => { props.openCloseSettings()
                         setOpenCloseSettings(false)
                         }}
                    >
                            <img
                                src={avatar ? avatar : photo_default}
                                className="ml-0 mcr-3 margin-link pointer d-flex align-items-center icon-24 rounded-circle"
                                alt="user"
                            />
                        <span className="gotham-12 d-flex align-items-center">{t('app.nav_header_home.settings_content.profil')}</span>
                    </div>
                    <div className="d-flex justify-content-start align-items-center pointer"
                         onClick={() => {openPreferences()
                         setOpenCloseSettings(false)
                         }}
                    >
                        <img
                            src={preferences_icon}
                            className="ml-0 mcr-3 margin-link d-flex align-items-center icon-24"
                            alt="preferences"
                        />
                        <span className="gotham-12 d-flex align-items-center">{t('app.nav_header_home.settings_content.preferences')}</span>
                    </div>
                    <div className="d-flex justify-content-start align-items-center pointer"
                         onClick={() => {openSubscription('subscription')
                         setOpenCloseSettings(false)
                         }}
                    >
                        <img
                            src={subscription_icon}
                            className="ml-0 mcr-3 margin-link d-flex align-items-center icon-24"
                            alt="subscription"
                        />
                        <span className="gotham-12 d-flex align-items-center">{t('app.nav_header_home.settings_content.subscription')}</span>
                    </div>
                    <div className="d-flex justify-content-start align-items-center pointer"
                         onClick={() => {openSubscription('sponsorship')
                         setOpenCloseSettings(false)
                         }}
                    >
                        <img
                            src={user_plus}
                            className="ml-0 mcr-3 margin-link d-flex align-items-center icon-24"
                            alt="sponsorship"
                        />
                        <span className="gotham-12 d-flex align-items-center">{t('app.nav_header_home.settings_content.sponsorship')}</span>
                    </div>
                    <Link className="d-flex justify-content-start align-items-center pointer"
                          to="/logout"
                          onClick={() => handleLogout()}
                          style={{lineHeight: 'normal'}}
                    >
                        <img
                            src={logout}
                            className="ml-0 mcr-3 margin-link d-flex align-items-center icon-24"
                            alt="logout"
                        />
                        <span className="gotham-12 d-flex align-items-center">{t('app.nav_header_home.settings_content.disconnection')}</span>
                    </Link>
                </div>
            :
                <></>
            }
        </div>
    )
}
export default NewNavBtns
