import React, {useContext} from "react";
import LanguageSwitch from "../Components/LanguageSwitch";
import {
    shopping_bag,
    file_text,
    gutai_login, gutai_login_desktop
} from "../img/img";
import {Link, withRouter} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {NavigationContext} from "../Contexts";

const ComeBackSoon = ({t}) => {

    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    return(
        <div className={isMobile ? "" : "d-flex justify-content-end background-coming-soon"}>
            <LanguageSwitch />
            <div className={isMobile ? "pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-30 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                <Link to={"/"}>
                    <img
                        src={isMobile ? gutai_login : gutai_login_desktop}
                        className={isMobile ? "mt-5" : "logo-gutai-home-desktop"}
                        alt="Gutai logo"
                    />
                </Link>
                <p id="text-middle-login" className={isMobile ? "text-middle-login my-auto" : "text-middle-login text-middle-login-desktop"}>{t('app.maintenance.title')}</p>
                <p>{t('app.maintenance.description')}</p>
                <p className="text-grey">{t('app.maintenance.second_description')}</p>
                <div id="form-login" className={isMobile ? "mb-auto w-100" : "w-100"}>
                    <div className="flex-column">
                        <Button className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5'onClick={(e) => {
                            e.preventDefault();
                            window.location.href='https://gutai-training.com/categorie-produit/homme/vetements-homme/';
                        }}>
                            <img className="mcr-2 icon-24" alt="log-in icon" src={shopping_bag} /><p className="my-auto home-btn-p-text">{t('app.maintenance.shop_btn')}</p>
                        </Button>
                        <div className="row d-flex justify-content-center align-items-center mct-5">
                            <div className="grey-line mcr-2"></div>
                            <p className="mb-0 grey-line-text">{t('app:login.or')}</p>
                            <div className="grey-line mcl-2"></div>
                        </div>
                        <p className="grey-line-text mcb-2 mct-2">{t('app.maintenance.blog_text')}</p>
                        <a href="https://gutai-training.com/categorie-actualites/" className="link-btn">
                            <button id="register-btn" className='mx-auto home-btn-white home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcb-2'>
                                <img className="mcr-2 icon-24" alt="valid icon" src={file_text} /><p className="my-auto home-btn-p-text">{t('app.maintenance.blog_btn')}</p>
                            </button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default withRouter(withTranslation(['app'],{ wait: true })(ComeBackSoon))