import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../../Tools/CustomHooks";
import {Button} from "react-bootstrap";
import Loader from "react-loader-spinner";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";
import DisciplineDebriefing from "./DisciplineDebriefing";
import TypeDebriefing from "./TypeDebriefing";
import DistanceDurationDebriefing from "./DistanceDurationDebriefing";
import RpeDebriefing from "./RpeDebriefing";
import ConditionsDebriefing from "./ConditionsDebriefing";
import {validate, validate2} from "../../../img/img";
import {TrainingEventTypes} from "../TrainingEvent/Utils";
import WorkoutDebriefing from "./WorkoutDebriefing";
import {MetricContext, WeekCalendarContext} from "../../../Contexts";
import {MetricValues} from "../../../Functions/Metrics";
import CommentDebriefing from "./CommentDebriefing";
import {convertMilesToKilometers} from "../Workout/Utils";
import uniqid from "uniqid";
import DescriptionDebriefing from "./DescriptionDebriefing";
import InfoDebriefing from "./InfoDebriefing";
import ReviewScoreDebriefing from "./ReviewScoreDebriefing";
import ReviewDebriefing from "./ReviewDebriefing";

// Onglet Debriefing - composant
//
// - workout (cas 4 et 5 uniquement)
// - discipline (cas 3 uniquement)
// - type
// - distance (cas 1, 2 et 3 uniquement)
// (pour la nat 'm' ou 'yds' (en fonction de la metric) sinon 'km' ou 'mile')
// - duration (cas 1, 2 et 3 uniquement)
// - rpe
// - sickness
// - fever
// - hurt


// Pour l'ajout d'un compte-rendu à une séance planifiée --> Composant AddDebriefing
//   -> En cas de succès, il faudra rendre le cas 2 : mettre à jour TrainingEvent dans le calendrier et mettre à jour la TrainingEventModal
// Pour l'ajout d'un compte-rendu libre  --> Composant NewDebriefing
//   -> En cas de succès, il faudra rendre le cas 2 : mettre à jour TrainingEvent dans le calendrier


/**
 * Afficher un formulaire de modification de Compte-rendu
 *
 * @param debriefingId
 * @param trainingEventType
 * @param workoutId
 */
const EditDebriefing = ({debriefingId, workoutId, trainingEventType, updateActiveKey, handleDebriefingUpdated}) => {
    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()

    const {updateCalendarEventToRefresh} = useContext(WeekCalendarContext)

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)

    const [debriefing, setDebriefing] = useState(null)
    const [type, setType] = useState(3)
    const [rpe, setRpe] = useState(0)
    const [hour, setHour] = useState(0)
    const [min, setMin] = useState(0)
    const [distance, setDistance] = useState(0)
    const [review, setReview] = useState({
        score: 0,
        title: null,
        description: null
    })
    const [discipline, setDiscipline] = useState(null)
    const [description, setDescription] = useState("")
    const [comment, setComment] = useState({
        id: null,
        content: '',
        talkjs_conversation_id: null
    })
    const [haveComment, setHaveComment] = useState(false)
    const [haveDescription, setHaveDescription] = useState(false)
    const [workout, setWorkout] = useState(workoutId)
    const [conditions, setConditions] = useState({
        sickness: false,
        hurt: false,
        fever: false
    })
    const [hurtDetails, setHurtDetails] = useState('')
    const [disciplinesStyles, setDisciplinesStyles] = useState({
        rpeLabel: "rpe-label-other",
        speechBubble: "speech-bubble-other",
        stateBar: "state-bar-other",
        rpeBtn: "rpe-btn-other",
        color: 'blue'
    })
    const [isModify, setIsModify] = useState(false)
    const [isSet, setIsSet] = useState(false)

    const [errorMessage, setErrorMessage] = useState()


    // Récuperer les données du compte-rendu pour initialiser le formulaire de modification
    const getDebriefing = async () => {
        const headers = generateHeaders()
        await axios
            .get(
                config+"api/debriefings/"+debriefingId,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let d = response.data
                setDebriefing(d)
                setType(d.type)
                setRpe(d.rpe*10)
                setHour(Math.floor(d.duration / 60))
                setMin(d.duration % 60)
                setDistance(metric === MetricValues.imperial ? d.distance_uk : d.distance)
                setReview({
                         score: d.review_score ? d.review_score : null,
                         title: d.review_title,
                         description: d.review_description,
                })

                const newConditions = {}
                newConditions.sickness = d.sickness
                newConditions.hurt = d.hurt
                newConditions.fever = d.fever

                setConditions(newConditions)
                if (d.hurt_details) {
                    setHurtDetails(d.hurt_details)
                }
                let defaultDiscipline = d.disciplines.length ? {
                    id: d.disciplines[0].id,
                    label: d.disciplines[0].name,
                    code: d.disciplines[0].code
                }: null
                setDiscipline(defaultDiscipline)

                if (d.comments.length > 0) {
                    setComment(d.comments[0])
                    setHaveComment(true)
                }
                if(d.description && d.description.length > 0) {
                    setDescription(d.description)
                    if(!haveDescription){
                        setHaveDescription(true)
                    }
                    else {
                        setHaveDescription(false)
                    }

                }
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    useEffect(() => {
        getDebriefing()
    }, [])


    useEffect(() => {
        if (discipline) {
            setDisciplinesStyles((oldStyles) => {
                let newStyles = {...oldStyles}
                switch (discipline.id) {
                    case 5:
                        newStyles.rpeLabel = "rpe-label-nat"
                        newStyles.speechBubble = "speech-bubble-nat"
                        newStyles.stateBar = "state-bar-nat"
                        newStyles.rpeBtn = "rpe-btn-nat"

                        newStyles.color = 'blue'
                        break
                    case 6:
                        newStyles.rpeLabel = "rpe-label-velo"
                        newStyles.speechBubble = "speech-bubble-velo"
                        newStyles.stateBar = "state-bar-velo"
                        newStyles.rpeBtn = "rpe-btn-velo"

                        newStyles.color = 'green'
                        break
                    case 7:
                        newStyles.rpeLabel = "rpe-label-cap"
                        newStyles.speechBubble = "speech-bubble-cap"
                        newStyles.stateBar = "state-bar-cap"
                        newStyles.rpeBtn = "rpe-btn-cap"

                        newStyles.color = 'orange'
                        break
                    default:
                        newStyles.rpeLabel = "rpe-label-other"
                        newStyles.speechBubble = "speech-bubble-other"
                        newStyles.stateBar = "state-bar-other"
                        newStyles.rpeBtn = "rpe-btn-other"

                        newStyles.color = 'blue'
                        break
                }
                return newStyles
            })
        }
    }, [discipline])


    const onChangeDiscipline = (value) => {
        setDiscipline(value)
        setIsModify(true)
        setIsSet(false)
    }

    const onChangeRpe = (value) => {
        setRpe(value)
        setIsModify(true)
        setIsSet(false)
    }

    const onChangeType = (value) => {
        setType(value)
        setIsModify(true)
        setIsSet(false)
    }

    const onChangeConditions = (value) => {
        setConditions(value)
        setIsModify(true)
        setIsSet(false)
    }

    const onChangeDuration = (durationType, value) => {
        if (durationType === 'hour') {
            setHour(value.id)
        } else {
            setMin(value.id)
        }
        setIsModify(true)
        setIsSet(false)
    }

    const onChangeDistance = (value) => {
        setDistance(value)
        setIsModify(true)
        setIsSet(false)
    }

    const onChangeWorkout = (value) => {
        setWorkout(value.id)
        setIsModify(true)
        setIsSet(false)
    }

    const onChangeComment = (value) => {
        setComment((oldComment) => {
            let newComment = {...oldComment}
            newComment.content = value
            return newComment
        })

        setIsModify(true)
        setIsSet(false)
    }

    const onChangeDescription = (value) => {
        setDescription(value)
    }

    const onChangeReviewScore = (value) => {
        setReview((oldReview) => {
            let newReview = {...oldReview}
            newReview.score = value
            return newReview
        })
        setIsModify(true)
        setIsSet(false)
    }

    const onChangeReviewTitle = (value) => {
        setReview((oldReview) => {
            let newReview = {...oldReview}
            newReview.title = value
            return newReview
        })
        setIsModify(true)
        setIsSet(false)
    }

    const onChangeReviewDescription = (value) => {
        setReview((oldReview) => {
            let newReview = {...oldReview}
            newReview.description = value
            return newReview
        })
        setIsModify(true)
        setIsSet(false)
    }

    const putDebriefing = () => {
        let headers = generateHeaders()

        let datas = {}
        let rpeValue = rpe/10 === 0 ? 0.5 : rpe/10

        datas.rpe = debriefing.rpe !== rpeValue ? rpeValue : null
        datas.sickness = debriefing.sickness !== conditions.sickness ? conditions.sickness : null
        datas.fever = debriefing.fever !== conditions.fever ? conditions.fever : null
        datas.hurt = debriefing.hurt !== conditions.hurt ? conditions.hurt : null
        datas.hurt_details = hurtDetails !== '' && hurtDetails !== debriefing.hurt_details && conditions.hurt ? hurtDetails : null
        datas.description =  description
        datas.type = +debriefing.type !== +type ? +type : null
        datas.review_score = (debriefing.review_score !== review.score && review.score !== 0) ? review.score : null
        datas.review_title = debriefing.review_title !== review.title ? review.title : null
        datas.review_description = debriefing.review_description !== review.description ? review.description : null

        datas.distance = debriefing.distance !== distance ? +distance : null
        let durationMin = +hour*60 + +min
        datas.duration = +debriefing.duration !== +durationMin ? +durationMin : null

        if (trainingEventType === TrainingEventTypes.COMPTE_RENDU_LIBRE) {
            datas.discipline_ids = [discipline.id]
        } else {
            if (workout !== workoutId) {
                datas.workout_id =  workout
            }
        }

        if(datas.distance !== null && metric === MetricValues.imperial) {
            datas.distance = convertMilesToKilometers(datas.distance)
        }

        if(headers){
            axios
                .put(
                    config+"api/debriefings/"+debriefingId,
                    datas,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    if(!isMounted.current){
                        return 0;
                    }
                    setIsSet(true)

                    // Marquer que le compte-rendu est complet
                    handleDebriefingUpdated()

                    // Afficher l'onglet ActivitySession
                    if(trainingEventType === TrainingEventTypes.ACTIVITE_LIBRE || trainingEventType === TrainingEventTypes.ACTIVITE_REALISEE){
                        updateActiveKey('activity_session')
                    }
                    // Recharger le calendrier
                    updateCalendarEventToRefresh(uniqid("debriefing-"+debriefingId))

                    // Créer le commentaire (le cas échéant)
                    if (comment.content !== '') {
                        addCommentToDebriefing(debriefingId)
                    }

                })
                .catch(error => {
                    handleEndpointError(error)
                    if(error.response !== undefined) {
                        if (error.response.status === 400) {
                            setErrorMessage(error.response.data.errors)
                        }
                    }
                })
        }
    }

    if(!debriefing) {
        return(
            <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                <Loader
                    type="TailSpin"
                    color="black"
                    height={50}
                    width={50}
                    className="my-5"
                />
            </div>
        )
    }

    // Enregister compte-rendu
    const addCommentToDebriefing = (debriefId) => {
        const headers = generateHeaders()
        let datas = {
            content: comment.content
        }


        axios
            .post(
                config+"api/debriefings/"+debriefId+"/comments/new",
                datas,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then((response) => {
                if(!isMounted.current){
                    return 0;
                }
                setHaveComment(true)
            })
            .catch(error => {
                handleEndpointError(error)
                if(error.response !== undefined) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.data.errors)
                    }
                }
            })
    }

    return (
        <div id="rpe-card" className="rpe-card pcx-3 pcy-3">
            <InfoDebriefing />
            {trainingEventType !== TrainingEventTypes.COMPTE_RENDU_LIBRE && discipline &&
                <WorkoutDebriefing
                        debriefingId={debriefingId}
                        workoutId={workout}
                        onChangeWorkout={(value) => onChangeWorkout(value)}
                        discipline={discipline}
                />
            }
            <div className="activity-CR mcb-4">
                <div className="row">
                    <div className="col-12">
                        <p className={"rpe-title text-left mcb-3"}>{t('app.layout.activity')}</p>
                    </div>

                    {trainingEventType === TrainingEventTypes.COMPTE_RENDU_LIBRE &&
                    <DisciplineDebriefing   onChangeDiscipline={(value) => onChangeDiscipline(value)}
                                            discipline={discipline}
                    />
                    }

                    {/* Champs requis */}
                    <TypeDebriefing type={type}
                                    onChangeType={(value) => onChangeType(value)}
                                    color={disciplinesStyles.color}
                    />

                    <DistanceDurationDebriefing onChangeDuration={(durationType, value) => onChangeDuration(durationType, value)}
                                                    onChangeDistance={(value) => onChangeDistance(value)}
                                                    distance={distance}
                                                    hour={hour}
                                                    min={min}
                                                    discipline={discipline}
                    />

                    {/* Champs requis */}
                    <RpeDebriefing disciplinesStyles={disciplinesStyles}
                                   setRpe={(value) => onChangeRpe(value)}
                                   rpe={rpe}
                                   cardId={'seance-card'}
                    />
                    <ConditionsDebriefing setConditions={(value) => onChangeConditions(value)}
                                          conditions={conditions}
                                          color={disciplinesStyles.color}
                                          hurtDetails={hurtDetails}
                                          setHurtDetails={setHurtDetails}
                    />

                    <CommentDebriefing comment={comment.content}
                                       conversationId={comment.talkjs_conversation_id}
                                       onChangeComment={(value) => onChangeComment(value)}
                                       haveComment={haveComment}
                    />
                    <DescriptionDebriefing description={description}
                                           onChangeDescription={(value) => onChangeDescription(value)}
                                           color={disciplinesStyles.color}
                                           haveDescription={haveDescription}
                    />
                    {trainingEventType !== TrainingEventTypes.COMPTE_RENDU_LIBRE &&
                        <>
                            <ReviewScoreDebriefing reviewScore={review.score} changeReviewScore={(value) => onChangeReviewScore(value)}/>
                            <ReviewDebriefing
                                reviewTitle={review.title}
                                changeReviewTitle={(value) => onChangeReviewTitle(value)}
                                reviewDescription={review.description}
                                changeReviewDescription={(value) => onChangeReviewDescription(value)}
                            />
                        </>
                    }
                    <div className="w-100 mct-3 d-flex justify-content-center">
                        {isSet ?
                            <div id={disciplinesStyles.rpeBtn} className='rpe-btn-validate py-2 px-3 d-flex align-items-center justify-content-center'>
                                <img className="mr-2 icon-24" alt="valid icon" src={validate2} /> {t('app:athlete.saving.saved')}
                            </div> :
                            <>
                                {errorMessage &&
                                <div className="error-login-text mcb-3">
                                    {errorMessage}
                                </div>
                                }
                                <Button className={
                                    !isModify ?
                                        'background-grey py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text'
                                        :
                                        'background-black py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text'
                                }
                                        onClick={() => {
                                            putDebriefing()
                                        }}
                                        disabled={!isModify} >
                                    <img className="mr-2 icon-24" alt="valid icon" src={validate} />{t('app:athlete.saving.save')}
                                </Button>
                            </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EditDebriefing;