import React, {useContext} from 'react';
import {NavigationContext} from "../../Contexts";
import {useTranslation} from "react-i18next";
import WorkoutTemplateDisciplineCube from "./WorkoutTemplateDisciplineCube";
import Loader from "react-loader-spinner";
import {DISCIPLINES_WORKOUT_TEMPLATE} from "./Utils";


const WorkoutTemplateThemes = ({themes, loaded, setThemeId}) => {
    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const {t} = useTranslation(['app'])

    return (
        <div className={`w-100 mcb-5 ${isMobile ? "pcx-2" : "pcx-3"}`}>
            <p className="seance-title2 mcb-3 text-left">
                {t('app.workout_template.filters.themes')}
            </p>
            {loaded ?
                <div className={`w-100 d-flex justify-content-start ${isMobile ? "content-scrollable" : "flex-wrap"}`}>
                    {themes.length>0 ?
                        themes.map((theme, index) => {
                            if(theme.count === 0) {
                                return null
                            }

                            let themeImg = theme.image_url ? theme.image_url : DISCIPLINES_WORKOUT_TEMPLATE[theme.discipline.code].default_theme_img_url

                            return (
                                <div key={"theme" + index} className="d-flex justify-content-start">
                                    <div className="search-card text-left cursor-pointer"
                                         onClick={() => setThemeId(theme.id)}
                                    >
                                        <div className="mcb-2 search-card-img"
                                             style={{backgroundImage: "url(" + themeImg + ")"}}
                                        >
                                            <div className="mcl-2 mct-2">
                                                <WorkoutTemplateDisciplineCube discipline={theme.discipline.code}/>
                                            </div>
                                        </div>
                                        <p className="text-caprina font-weight-bold fs-15 mcb-2">
                                            {theme.name}
                                        </p>
                                        <p className="text-gotham fs-13">
                                            {theme.count + " " + t('app.workout_template.count.' + (theme.count === 1 ? 'session' : 'sessions'))}
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div className="d-flex justify-content-center m-auto">
                                {t('app.workout_template.no_theme')}
                        </div>
                    }
                </div>
                :
                <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                    <Loader
                        type="TailSpin"
                        color="black"
                        height={50}
                        width={50}
                        className="my-5"
                    />
                </div>
            }
        </div>
    );
};


export default WorkoutTemplateThemes;