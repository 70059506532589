export const TRAININGSUMMARYTYPES = {
    WEEKLY: "weekly",
    MONTHLY: "monthly",
    ANNUAL: "annual"
}

export const  TRAININGSUMMARYPERIODS = {
    "BEGINNING_YEAR": 1,
    "ENTIRE_YEAR": 2,
    "LAST_6_MONTHS": 3,
    "LAST_3_MONTHS": 4,
    "CUSTOM_PERIOD": 5
}