import React, {useState, useEffect, useRef, useContext} from 'react';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../Tools/CustomHooks";
import {Button} from "react-bootstrap";
import {
    validate,
    x_circle
} from "../../img/img";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import generateHeaders from "../../Functions/generateHeaders";
import {NavigationContext} from "../../Contexts";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {format, formatISO, getISOWeek, getISOWeekYear} from "date-fns"
import Loader from "react-loader-spinner";

const WorkoutTemplatePlan = ({workoutTemplateId, closeWorkoutTemplateModal, closeWidget}) => {
    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()
    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)
    let history = useHistory()

    const [date, setDate] = useState(new Date())

    const [workoutId, setWorkoutId] = useState(null)

    const [loading, setLoading] = useState(false)

    const [title, setTitle] = useState(null)
    const [message1, setMessage1] = useState(null)
    const [message2, setMessage2] = useState(null)
    const [ctaTitle, setCtaTitle] = useState(null)

    const pickerRef = useRef(null)

    useEffect(() => {
        if (isMobile && pickerRef.current !== null) {
            pickerRef.current.input.readOnly = true;
        }
    }, [isMobile, pickerRef]);


    const planWorkoutTemplate = () => {
        setLoading(true)

        let datas = {
            date: formatISO(date)
        }
        axios
            .post(
                config + "api/calendar/plan_workout_template/"+workoutTemplateId,
                datas,
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    }
                },
            )
            .then((response) => {
                if(!isMounted.current){
                    return 0;
                }

                let status = response.data.status
                setTitle(t('app.workout_template.plan.'+status+'.title'))
                setMessage1(t('app.workout_template.plan.'+status+'.message_1'))
                setMessage2(t('app.workout_template.plan.'+status+'.message_2'))
                setCtaTitle(t('app.workout_template.plan.'+status+'.cta_title'))
                setWorkoutId(response.data.workout_id)

                setLoading(false)

            })
            .catch(error => {
                handleEndpointError(error)
            })
    }


    const isLoaded = () => {
        return title ? true : false
    }

    const onChangeDate = (value) => {
        setDate(value)
    }

    const onCtaClick = () => {
        if(workoutId) {
            let weekNumber = getISOWeek(date)
            let yearNumber = getISOWeekYear(date)
            // Rediriger vers le calendrier
            history.push('/dashboard?week='+weekNumber+"&year="+yearNumber)
        }
        else {
            // TODO: Afficher la section en "Savoir plus" ou rediriger vers la page adéquat
        }
    }

    return (
        <div className={"col-xl-8 p-3 mb-4 card-float-dashboard z-index-1"}>
            <div className="dashboard-card background-white">
                <div className={"d-flex align-items-center pc-3 justify-content-between"}>
                    <div className="d-flex align-items-center">
                        <div className="text-left mcl-3">
                            <p className={"text-caprina fs-18 mb-0"}>
                                {t('app.workout_template.plan.header')}
                            </p>
                        </div>
                    </div>
                    <img
                        className="icon-24 pointer right"
                        src={x_circle}
                        alt="close icon"
                        onClick={() => closeWidget()}
                    />
                </div>

                <div className="popup-card pc-4 d-flex flex-column">
                    <div>
                        {loading ?
                            <Loader
                                type="TailSpin"
                                color="black"
                                height={50}
                                width={50}
                                className="my-5"
                            />
                            :
                            isLoaded() ?
                                <div>
                                    <div className="text-caprina font-weight-bold mcb-3" style={{lineHeight:"42px", fontSize:"42px"}}>
                                        {workoutId ?
                                            <i className="bi bi-shield-fill-check text-blue"></i>
                                            :
                                            <i className="bi bi-shield-fill-exclamation text-red"></i>
                                        }
                                    </div>
                                    <div className="text-caprina font-weight-bold fs-16 pcb-5">{title}</div>
                                    <div className="fs-13 pcb-1">{message1}</div>
                                    <div className="fs-13 pcb-3">{message2}</div>
                                    <div className="d-flex justify-content-center mcy-3">
                                        <div className="btn-white-blue"
                                             onClick={() => closeWorkoutTemplateModal()}
                                        >
                                            {t('app.workout_template.plan.find_next')}
                                        </div>
                                    </div>

                                    <span className="pointer more-information"
                                          onClick={() => onCtaClick()}
                                    >
                                        {ctaTitle}
                                    </span>
                                </div>
                                :
                                <div class="mcb-5">
                                    <span className="label-input-athlete-event mcb-2">Date</span>
                                    {/* Date */}
                                    <DatePicker
                                        wrapperClassName="w-100"
                                        className="custom-input w-100"
                                        selected={date}
                                        ref={pickerRef}
                                        onChange={(date) => onChangeDate(date)}
                                        showTimeSelect
                                        timeFormat="p"
                                        timeIntervals={30}
                                        dateFormat="Pp"
                                    />
                                </div>
                        }
                    </div>
                    {isLoaded () === false &&
                        <div className="w-100 d-flex justify-content-center">
                            <Button className="py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text"
                                    onClick={() => planWorkoutTemplate()}
                                    disabled={!date}
                            >
                                <img className="mr-2 icon-24" alt="valid icon" src={validate} />{t('app.confirm')}
                            </Button>
                        </div>
                    }

                </div>
            </div>
        </div>
    );
};

export default WorkoutTemplatePlan;