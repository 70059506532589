export const TrainingEventTypes = {
    SEANCE_PLANIFIEE: 1,
    // CAS 1 | Séance planifiée : workout avec debriefing à null
    // [ onglet Workout : OUI avec EDIT | activé
    //
    //   onglet Debriefing : OUI avec NEW si planned_date <= now
    //   -> POST : workout et disciplines cachés dans le formulaire (déduits du workout).
    //
    //   onglet ActivitySession : NON
    // ]

    SEANCE_REALISEE: 2,
    // CAS 2 | Séance Réalisée : workout avec debriefing et first_activity_session_id à null
    // [ onglet Workout : OUI
    //
    //   onglet Debriefing : OUI avec EDIT | activé
    //   -> PUT :   Possibilité de modifier le workout.
    //              Discipline caché dans le formulaire (déduits du workout).
    //
    //   onglet ActivitySession : NON
    // ]

    COMPTE_RENDU_LIBRE: 3,
    // CAS 3 | CR libre : debriefing avec workout à null et first_activity_session_id à null
    // [ onglet Workout : NON
    //
    //   onglet Debriefing : OUI avec EDIT | activé
    //   -> PUT :  workout caché et à null quoiqu'il arrive.
    //
    //   onglet ActivitySession : NON
    // ]

    ACTIVITE_LIBRE: 4,
    // CAS 4 | Activité libre : debriefing avec first_activity_session_id et workout à null
    // [ onglet Workout : NON
    //
    //   onglet Debriefing : OUI avec EDIT
    //   -> PUT :   Possibilité de modifier le workout.
    //              Discipline caché dans le formulaire (ne pas modifier lors du PUT parce que déduit de activity)
    //
    //   onglet ActivitySession : OUI  | activate
    // ]

    ACTIVITE_REALISEE: 5,
    // CAS 5 | Activité réalisée : debriefing avec first_activity_session_id et workout
    // [ onglet Workout : OUI
    //
    //   onglet Debriefing : OUI avec EDIT
    //   -> PUT :   Possibilité de modifier le workout.
    //              Discipline caché dans le formulaire (ne pas modifier lors du PUT parce que déduit de activity)
    //
    //   onglet ActivitySession : OUI  | activate
    // ]
}


export function getTrainingEventClassNames (trainingEvent) {

    let color, bcolor, opacolor
    let disciplines = trainingEvent.disciplines

    if (disciplines.length > 1) {
        color = "color-multi"
        bcolor = "btn-outline-multi bcolor-multi"
        opacolor = "color-opa-multi"
    } else {
        switch (disciplines[0]) {
            case 'velo' :
                color = "color-velo"
                bcolor = "btn-outline-velo bcolor-velo"
                opacolor = "color-opa-velo"
                break
            case 'cap' :
            case 'trail':
                color = "color-cap"
                bcolor = "btn-outline-cap bcolor-cap"
                opacolor = "color-opa-cap"
                break
            case 'nat' :
                color = "color-nat"
                bcolor = "btn-outline-nat bcolor-nat"
                opacolor = "color-opa-nat"
                break
            case 'kayak' :
                color = "color-kayak"
                bcolor = "btn-outline-kayak bcolor-kayak"
                opacolor = "color-opa-kayak"
                break
            case 'ski-fond':
            case 'ski-alpin':
            case 'ski-rando':
                color = "color-ski"
                bcolor = "btn-outline-ski bcolor-ski"
                opacolor = "color-opa-ski"
                break
            case 'muscu' :
            case 'renfo':
                color = "color-muscu"
                bcolor = "btn-outline-muscu bcolor-muscu"
                opacolor = "color-opa-muscu"
                break
            case 'vtt' :
                color = "color-vtt"
                bcolor = "btn-outline-vtt bcolor-vtt"
                opacolor = "color-opa-vtt"
                break
            default:
                color = "color-other"
                bcolor = "btn-outline-other bcolor-other"
                opacolor = "color-opa-other"
                break
        }
    }

    return {
        color: color,
        bcolor: bcolor,
        opacolor: opacolor
    }
}


const createTrainingEventFromWorkout = (workout, debriefing, trainingEventKey) => {
    let trainingEvent = {}

    // let debriefing = workout.debriefing || null

    // CAS 1 | Séance planifiée : workout avec debriefing à null
    if (!debriefing) {
        trainingEvent.type = TrainingEventTypes.SEANCE_PLANIFIEE
    }
    else {
        return createTrainingEventFromDebriefing(debriefing, trainingEventKey)
    }

    trainingEvent.workout_id = workout.id
    trainingEvent.garmin_training_synchronizable = workout.garmin_training_synchronizable
    trainingEvent.garmin_training_synced = workout.garmin_training_synced
    trainingEvent.debriefing_id =  null
    trainingEvent.debriefing_is_complete = null
    trainingEvent.activity_session_id =  null
    trainingEvent.activity_session_2_id =  null
    trainingEvent.activity_session_3_id =  null
    trainingEvent.activity_session_discipline =  null
    trainingEvent.activity_session_2_discipline =  null
    trainingEvent.activity_session_3_discipline =  null

    trainingEvent.disciplines = workout.disciplines.map(discipline => discipline.code)
    trainingEvent.name =  workout.name
    trainingEvent.duration =  workout.duration
    trainingEvent.distance = null
    trainingEvent.distance_uk = null
    trainingEvent.date = workout.planned_date
    trainingEvent.key = 'training-event-'+trainingEventKey

    return trainingEvent
}
export {createTrainingEventFromWorkout};

const createTrainingEventFromDebriefing = (debriefing, trainingEventKey) => {
    let trainingEvent = {}

    let workout_id = debriefing.workout_id || null

    if (!debriefing.first_activity_session_id) {
        // CAS 2 | Séance Réalisée : workout avec debriefing et first_activity_session_id à null
        // CAS 3 | CR libre : debriefing avec workout à null et first_activity_session_id à null
        trainingEvent.type =  workout_id ? TrainingEventTypes.SEANCE_REALISEE : TrainingEventTypes.COMPTE_RENDU_LIBRE
    }
    else {
        // CAS 4 | Activité libre : debriefing avec first_activity_session_id et workout à null
        // CAS 5 | Activité réalisée : debriefing avec first_activity_session_id et workout
        trainingEvent.type = workout_id ? TrainingEventTypes.ACTIVITE_REALISEE : TrainingEventTypes.ACTIVITE_LIBRE
    }

    trainingEvent.debriefing_id = debriefing.id
    trainingEvent.workout_id = workout_id
    trainingEvent.garmin_training_synchronizable = null
    trainingEvent.garmin_training_synced = null
    trainingEvent.debriefing_is_complete = debriefing.is_complete || null
    trainingEvent.activity_session_id = debriefing.first_activity_session_id || null
    let activitySessions = debriefing.activity_sessions
    trainingEvent.activity_session_2_id = activitySessions.length > 1 ? activitySessions[1].id : null
    trainingEvent.activity_session_3_id = activitySessions.length > 2 ? activitySessions[2].id : null
    trainingEvent.activity_session_discipline = trainingEvent.activity_session_id ? activitySessions[0].discipline : null
    trainingEvent.activity_session_2_discipline = trainingEvent.activity_session_2_id ? activitySessions[1].discipline : null
    trainingEvent.activity_session_3_discipline = trainingEvent.activity_session_3_id ? activitySessions[2].discipline : null

    trainingEvent.disciplines = debriefing.disciplines.map(discipline => discipline.code)
    trainingEvent.name = debriefing.name
    trainingEvent.duration = debriefing.duration
    trainingEvent.distance = debriefing.distance
    trainingEvent.distance_uk = debriefing.distance_uk
    trainingEvent.date = debriefing.date
    trainingEvent.key = 'training-event-'+trainingEventKey

    if(trainingEvent.activity_session_id && trainingEvent.activity_session_discipline === null) {
        return null
    }
    return trainingEvent
}
export {createTrainingEventFromDebriefing};