import React, { useReducer } from "react"


export const AuthContext = React.createContext({
    token: null,
    updateToken: () => {}
})

export const StripeContext = React.createContext({
    stripePromise: null
})

// Utilisateur connecté
export const LoggedInContext = React.createContext({
    maintenance: false,
    enabled: true,
    loggedIn : null,
    avatar: null,
    user_id: null,
    coach_id: null,
    username: null,
    firstname: null,
    is_premium: null,
    is_training_20: null,
    has_user_training_program: null,
    is_subscribed: null,
    is_coaching_smart: null,
    is_chat_limited: null,
    is_coaching_pro: null,
    firebase_token: null,
    talkjs_user_id: null,
    remaining_coach_message_number: null,
    has_finalized_registration: null,
    gender: null,
    has_unread_notifications: null,
    is_beta_tester: null
})

// Sports utilisateur
export const PrioritySportContext = React.createContext({
    prioritySport: null,
    updatePrioritySport: () => {},
    preferedSports: [],
    updatePreferedSports: () => {}
})

export const SplashScreenSettingContext = React.createContext({
    showSyncProvider: null,
    updateShowSyncProvider: () => {},
    splashScreenSettingSyncProvider: null,
    updateSplashScreenSettingSyncProvider: () => {},
    showSubscribeAutonom: null,
    updateShowSubscribeAutonom: () => {},
    splashScreenSettingSubscribeAutonom: null,
    updateSplashScreenSettingSubscribeAutonom: () => {},
    showPlanWorkoutTemplate: null,
    updateShowPlanWorkoutTemplate: () => {},
    splashScreenSettingPlanWorkoutTemplate: null,
    updateSplashScreenSettingPlanWorkoutTemplate: () => {},
})

// Langue utilisateur
export const LanguageContext = React.createContext({
    language: null,
    updateLanguage: () => {}
})

// Métrique utilisateur
export const MetricContext = React.createContext({
    metric: null,
    updateMetric: () => {},
    runningCriticalIntensityStatistic: null,
    updateRunningCriticalIntensityStatistic: () => {}
})

// TimeZoneSelect utilisateur
export const TimeZoneContext = React.createContext({
    timeZone: null,
    updateTimeZone: () => {}
})

// Navigateur
export const NavigationContext = React.createContext({
    isSafari: null,
    isMobile: null,
    isTablet: null,
    width: null,
})

export const DashboardContext = React.createContext({
    settingsAction: null,
    updateSettingsAction: () => {},
    externalActivityAction: null,
    updateExternalActivityAction: () => {},
    wellnessEvolutionAction: null,
    updateWellnessEvolutionAction: () => {},
    weightEvolutionAction: null,
    updateWeightEvolutionAction: () => {}
})

// Etat de la synchronization Garmin
export const GarminTrainingContext = React.createContext({
    garminTrainingSynced: null,
    updateGarminTrainingSynced: () => {}
})

// Talkjs
export const TalkJSContext = React.createContext({
    chatOpen: null,
    conversationId: null,
    hasNewMessage: null,
    toggleChat: () => {},
    updateConversationId: () => {},
    updateHasNewMessage: () => {}
})

// Calendrier
export const WeekCalendarContext = React.createContext({
    trainingEvent: null,
    updateTrainingEvent: () => {},
    // Etat de la modal du Training Event
    trainingEventOpen: null,
    updateTrainingEventOpen: () => {},
    // Faut-il recharger la semaine en cours
    calendarEventToRefresh: null,
    updateCalendarEventToRefresh : () => {},
    // Il faut ouvrir le calendar à cette semaine si spécifié
    dayToOpen: null,
    updateDayToOpen : () => {},
    // Forcer l'ouverture de l'onglet du Training Event
    activeTabOpen: null,
    updateActiveTabOpen: () => {},
    // Semaine active
    activeWeek: null,
    updateActiveWeek: () => {},
    // Evenement d'un athlete
    athleteEventHandled: null,
    updateAthleteEventHandled: () => {},
    // Disponibilité athlète
    athleteAvailabilityEventHandled: null,
    updateAthleteAvailabilityEventHandled: () => {}
})


// Réglages concepts d'entraînement
export const TrainingConceptConfigContext = React.createContext({
    swimmingNoPool: null,
    swimmingPool25: null,
    swimmingPool33: null,
    swimmingPool50: null,
    runningIndoor: null,
    runningOutdoor: null,
    cyclingIndoor: null,
    cyclingOutdoor: null,
    cyclingVirtualCycling: null,
    cyclingVTT: null,
    updateSwimmingNoPool: () => {},
    updateSwimmingPool25: () => {},
    updateSwimmingPool33: () => {},
    updateSwimmingPool50: () => {},
    updateRunningIndoor: () => {},
    updateRunningOutdoor: () => {},
    updateCyclingIndoor: () => {},
    updateCyclingOutdoor: () => {},
    updateCyclingVirtualCycling: () => {},
    updateCyclingVTT: () => {}
})


export const WorkoutTemplateContext = React.createContext({
    refreshFavorite: null,
    onUpdateFavorite: () => {}
})
