import React, {useEffect, useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import Loader from "react-loader-spinner";
import axios from "axios";
import config from "../../api";
import {useHistory} from "react-router";
// import moment from "moment";
import 'moment/min/locales'
import moment from "moment-timezone";
import {
    // logo_garmin_training_transparent, move,
    // stage_img, trash, vacance_img,
    // watch_sync, x_circle
    arrow_left_circle,
    arrow_right_circle,
    blessure_img, calendar,
    download,
    plus_circle, stage_img, vacance_img
} from "../../img/img";
import '../../Css/Agenda.css';

import generateHeaders from "../../Functions/generateHeaders";
import {getOtherMonth, getOtherWeek, getWeeksOfMonth} from "./Utils";
import TrainingEvent from "./TrainingEvent/TrainingEvent";
import {TrainingEventTypes, createTrainingEventFromWorkout, createTrainingEventFromDebriefing} from "./TrainingEvent/Utils";
import AthleteEvent from "./AthleteEvent/AthleteEvent";
import {
    createAthleteEventFromCompetition,
    createAthleteEventFromInjury,
    createAthleteEventFromTraineeship,
    createAthleteEventFromHoliday, AthleteEventTypes
} from "./AthleteEvent/Utils";
import WeekSummary from "./WeekSummary"

import {NavigationContext, WeekCalendarContext, LoggedInContext} from "../../Contexts"
import handleEndpointError from "../../Functions/Alert";
import InputSwitch from "../Tools/InputSwitch";
import AthleteAvailability from "./AthleteAvailability/AthleteAvailability";
import EditCompetition from "./AthleteEvent/Competition/EditCompetition";
import NewCompetition from "./AthleteEvent/Competition/NewCompetition";

const WeekCalendar = ({ activeDateProp,
                          currentDate,
                          activeWeekNValue,
                          openDebriefingModal,
                          openCompetitionModal,
                          openInjuryModal,
                          openHolidayModal,
                          openTraineeshipModal,
                          openTrainingEventModal,
                          openAthleteAvailabilityModal,
                          // editAthleteAvailability,
                          typeCalendar,
                          openCloseTrainingSummary
                      }) => {

    const {t} = useTranslation(['app']);

    const history = useHistory()

    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    const {username, is_premium, user_id} = useContext(LoggedInContext)

    const {calendarEventToRefresh, activeWeek, updateActiveWeek} = useContext(WeekCalendarContext)

    // Jour actif, date de la colonne du calendrier ouverte
    const [activeDate, setActiveDate] = useState(moment(activeDateProp))

    const [weeksOfMonth, setWeeksOfMonth] = useState(getWeeksOfMonth(moment(activeDateProp)))

    // Ensemble des évènements d'entraînement de la semaine pour un athlète
    const [trainingEvents, setTrainingEvents] = useState([])

    // Ensemble des disponibilités de la semaine pour un athlète
    const [athleteAvailabilities, setAthleteAvailabilities] = useState([])

    // Ensemble des évènements hors entraînement (compétition, vacances, blessure, stage)
    // de la semaine pour un athlète
    const [athleteEvents, setAthleteEvents] = useState([])

    // Boolean qui empeche l'affichage avant le chargement des données
    const [loaded, setLoaded] = useState(false)

    // Training event à manipuler
    const [trainingEventHandled, setTrainingEventHandled] = useState(null)

    // Type de calendrier à visualiser
    const TYPE = {
        calendar: 1,
        availabilities: 2
    }
    const [typeDisplay, setTypeDisplay] = useState(TYPE.calendar)

    // Panel d'ajout d'un évènement
    const [addEventPanel, setAddEventPanel] = useState({isOpen : false, day : null})

    // activeWeekNValue = activeWeek.format('W')

    // Temporaire : affichage du bouton pour planifier une séance à la date du jour
    const showPlanBtn = ["schfraxx@gmail.com", "l.lepoutre@gutai-training.com"].includes(username) || (is_premium && [763, 1030, 1082, 3774].includes(user_id) === false)


    const onTypeChange = () => {
        switch (typeDisplay) {
            case (TYPE.availabilities):
                setTypeDisplay(TYPE.calendar)
                break
            default:
            case (TYPE.calendar):
                setTypeDisplay(TYPE.availabilities)
                break
        }
    }

    const getTrainingEvents = async () => {
        const startWeek = activeWeek.isoWeekday(1).format("YYYY-MM-DD")
        const endWeek = activeWeek.isoWeekday(7).format("YYYY-MM-DD")
        const headers = generateHeaders()
        axios
            .get(
                config+"api/calendar?type=all&min_date="+startWeek+"&max_date="+endWeek,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then(response => {

                // Créer les événements AthleteEvent
                let listAthleteEvents = []
                let athleteEventKey = 1;

                // Compétitions
                const dataCompetitions = response.data.competitions
                for (let i=0; i<dataCompetitions.length; i++) {
                    let competition = dataCompetitions[i]
                    if (moment(competition.start_date) >= activeWeek.startOf('isoWeek')) {
                        let athleteEvent = createAthleteEventFromCompetition(competition, athleteEventKey)
                        listAthleteEvents.push(athleteEvent)
                        athleteEventKey++
                    }
                }
                // Blessures
                const dataInjuries = response.data.injuries
                for (let i=0; i<dataInjuries.length; i++) {
                    let injury = dataInjuries[i]
                    if (moment(injury.start_date) >= activeWeek.startOf('isoWeek')) {
                        // TODO Si la date de début est supérieure ou égale au lundi de la semaine
                        let athleteEvent = createAthleteEventFromInjury(injury, athleteEventKey)
                        listAthleteEvents.push(athleteEvent)
                        athleteEventKey++
                    }
                }
                // Vacances
                const dataHolidays = response.data.holidays
                for (let i=0; i<dataHolidays.length; i++) {
                    let holiday = dataHolidays[i]
                    if (moment(holiday.start_date) >= activeWeek.startOf('isoWeek')) {
                        let athleteEvent = createAthleteEventFromHoliday(holiday, athleteEventKey)
                        listAthleteEvents.push(athleteEvent)
                        athleteEventKey++
                    }
                }
                // Stages
                const dataTraineeships = response.data.traineeships
                for (let i=0; i<dataTraineeships.length; i++) {
                    let traineeship = dataTraineeships[i]
                    if (moment(traineeship.start_date) >= activeWeek.startOf('isoWeek')) {
                        let athleteEvent = createAthleteEventFromTraineeship(traineeship, athleteEventKey)
                        listAthleteEvents.push(athleteEvent)
                        athleteEventKey++
                    }
                }

                setAthleteEvents(listAthleteEvents)


                let listTrainingEvents = []
                let trainingEventKey = 1;

                // Créer les événéments TrainingEvent [1]
                const dataWorkouts = response.data.workouts
                for (let i=0; i<dataWorkouts.length; i++) {
                    let workout = dataWorkouts[i]
                    let debriefings = workout.debriefings
                    let nbDebriefing = debriefings.length
                    if(nbDebriefing === 0) {
                        let trainingEvent = createTrainingEventFromWorkout(workout, null, trainingEventKey)
                        listTrainingEvents.push(trainingEvent)
                        trainingEventKey++
                    }
                }

                // Créer les événéments TrainingEvent [2,3,4,5]
                const dataDebriefings = response.data.debriefings
                for (let i=0; i<dataDebriefings.length; i++) {
                    let debriefing = dataDebriefings[i]

                    if(debriefing.workout_id === null && debriefing.first_activity_session_id === null && debriefing.duration === 0) {
                        continue;
                    }
                    // if (!debriefing.workout_id) {

                    let trainingEvent = createTrainingEventFromDebriefing(debriefing, trainingEventKey)
                    if(trainingEvent) {
                        listTrainingEvents.push(trainingEvent)
                        trainingEventKey++
                    }
                    // }
                }
                setTrainingEvents(listTrainingEvents)

                let listAthleteAvailabilities = []
                // let athleteAvailabilitiesKey = 1;

                // Créer les disponibilités
                const dataAvailabilities = response.data.athlete_availabilities
                for (let i=0; i<dataAvailabilities.length; i++) {
                    // let availability = {athleteAvailabilitiesKey: dataAvailabilities[i]}
                    let availability = dataAvailabilities[i]
                    availability.key = 'availability-'+availability.id
                    listAthleteAvailabilities.push(availability)
                    // athleteAvailabilitiesKey++
                }

                setAthleteAvailabilities(listAthleteAvailabilities)
            })
            .catch()
    }

    useEffect(() => {
        window.addEventListener('click', checkClick)

        return () => {
            window.removeEventListener('click', checkClick)
        }
    }, [])

    useEffect(() => {
        if(typeCalendar && typeDisplay !== typeCalendar) {
            onTypeChange(typeCalendar)
        }
    }, [typeCalendar])

    useEffect(() => {
        if(activeDateProp && moment(activeDateProp).format('YYYY-MM-DD') !== activeDate.format('YYYY-MM-DD')) {
            setActiveDate(moment(activeDateProp))
        }
    }, [activeDateProp])


    const checkClick = (event) => {
        let targetClasses = event.target.classList
        if (!targetClasses.contains('change-week') && !targetClasses.contains('move-training-event') && !targetClasses.contains('week-of-month')) {
            setTrainingEventHandled(null)
        }
    }


    // Change le mois actif selon la props prevOrNext
    // prevOrNext : string ['prev', 'next']
    const changeMonth = async (prevOrNext) => {
        updateActiveWeek((oldWeek) => getOtherMonth({...oldWeek}, prevOrNext, false))
    }


    useEffect(() => {
        setLoaded(false)
        if(activeWeek) {
            getTrainingEvents()
            setWeeksOfMonth(getWeeksOfMonth(activeWeek))
        }
    }, [activeWeek, calendarEventToRefresh])

    useEffect(() => {
        setLoaded(true)
        scrollHorizontal(activeDate.isoWeekday())
    }, [trainingEvents])

    const onPlanSession = () => {
        history.push("/search_workout_template")
    }

    // Retourne une colonne de calendrier selon le jour de la semaine
    // dayOfWeek : number [1|2|3|4|5|6|7], numéro iso du jour de la semaine
    const dayColumn = (dayOfWeek) => {
        let dayToCheckYMD = activeWeek.isoWeekday(dayOfWeek).format('YYYY-MM-DD')
        let dayToCheck = moment(dayToCheckYMD)
        let activeDateYMD = activeDate ? activeDate.format('YYYY-MM-DD') : "1901-01-01"
        let currentDateYMD = currentDate.format('YYYY-MM-DD')
        let isToday = currentDateYMD === activeDateYMD && dayToCheckYMD === currentDateYMD && currentDateYMD === moment().format('YYYY-MM-DD')

        let style
        if (dayToCheckYMD === activeDateYMD) {
            style = "flex-column day-col-open py-3"
        } else {
            style = "flex-column day-col-close justify-content-center px-1 py-3"
        }
        return (
            <div key={dayToCheckYMD} className={style}>
                {dayToCheckYMD === activeDateYMD ?
                    <p className="agenda-dow pt-2">{t('app.date.day.' + dayToCheck.isoWeekday())}</p> :
                    <p className="agenda-dow pt-2">{t('app.date.short_day.' + dayToCheck.isoWeekday())}</p>
                }
                {isToday ?
                    <div className="agenda-today-rectangle mx-auto mcb-4 pointer" onClick={() => changeActiveDate(dayToCheck)}>
                        <p className="agenda-today-text">{t('app.date.today')}</p>
                    </div> :
                    dayToCheckYMD === currentDateYMD ?
                        <Button variant="outline-light"
                                className="close-width agenda-cube cube-white agenda-today-case d-flex justify-content-center mx-auto px-0 mcb-4"
                                onClick={() => changeActiveDate(dayToCheck)}
                        >
                            <p className="agenda-dom-number agenda-today-case-text w-100">
                                {dayToCheck.format("D")}
                            </p>
                        </Button>
                        :
                        dayToCheckYMD === activeDateYMD ?
                            <div className="agenda-day-rectangle mx-auto mcb-4 pointer" onClick={() => changeActiveDate(dayToCheck)}>
                                <p className="agenda-day-text">
                                    {dayToCheck.format("DD MMMM")}
                                </p>
                            </div> :
                            <Button variant="outline-light"
                                    className="close-width agenda-cube cube-white d-flex justify-content-center mx-auto px-0 mcb-4"
                                    onClick={() => changeActiveDate(dayToCheck)}
                            >
                                <p className="agenda-dom-number w-100">
                                    {dayToCheck.format("D")}
                                </p>
                            </Button>
                }

                {athleteEvents.map((athleteEvent) => {
                    let athleteEventDate = moment(athleteEvent.date)
                    let openAthleteEventModal = athleteEvent.type === AthleteEventTypes.COMPETITION ? openCompetitionModal : null
                    if (athleteEventDate.isoWeekday() === dayOfWeek) {
                        return <AthleteEvent key={athleteEvent.key}
                                             athleteEvent={athleteEvent}
                                             activeDate={activeDate}
                                             changeActiveDate={(date) => changeActiveDate(date)}
                                             openAthleteEventModal={(date) => openAthleteEventModal(date)}
                        />
                    }
                })
                }
                {trainingEvents.map((trainingEvent) => {
                    let trainingEventDate = moment(trainingEvent.date)
                    if (trainingEventDate.isoWeekday() === dayOfWeek) {
                        return <TrainingEvent key={trainingEvent.key}
                                              trainingEvent={trainingEvent}
                                              activeDate={activeDate}
                                              changeActiveDate={(date) => changeActiveDate(date)}
                                              openTrainingEventModal={(trainingEvent) => openTrainingEventModal(trainingEvent)}
                                              handleTrainingEvent={(trainingEvent) => handleTrainingEvent(trainingEvent)}
                        />
                    }
                })
                }
                {!trainingEventHandled &&
                (addEventPanel.isOpen && addEventPanel.day === dayToCheckYMD ? panelAdd(dayToCheck, (dayToCheck <= currentDate ? true : false)) :
                        <div className="d-flex justify-content-center">
                            <div className="agenda-cube-empty d-flex align-items-center justify-content-center w-100 pointer"
                                 onClick={() => {
                                     (dayToCheckYMD !== activeDateYMD && changeActiveDate(dayToCheck))
                                     openPanelAdd(dayToCheckYMD)
                                 }
                                 }
                            >
                                <img src={plus_circle} alt="plus icon" className="plus-icon-empty my-auto" />
                            </div>
                        </div>
                )
                }
                {trainingEventHandled &&
                <img src={download} alt="download icon" className="plus-icon icon-24 move-training-event" onClick={() => moveTrainingEvent(dayToCheck)} />
                }
                {isToday && showPlanBtn &&
                    <div className="btn-blue-white agenda-plan-today-session mct-3 mcx-2"
                         onClick={() => onPlanSession()}
                    >
                        {t('app.plan_session')}
                    </div>
                }
            </div>
        )
    }

    const closePanelAdd = () => {
        setAddEventPanel({isOpen: false, day: null})
    }

    const openPanelAdd = (day) => {
        setAddEventPanel({isOpen: true, day: day})
    }

    const panelAdd = (dayToCheck, showAddDebriefing) => {
        return (
            <>
                <div className="panel-add pointer fs-12">
                    <>
                        {showAddDebriefing &&
                        <p className="move-training-event"
                           onClick={() => {
                               openDebriefingModal(dayToCheck)
                               closePanelAdd()
                           }}>
                            <img
                                src={plus_circle}
                                alt="plus icon"
                                className="mcr-1 icon-24"
                            />
                            {t('app:athlete.add_event.debriefing')}
                        </p>
                        }
                        <p className=""
                           onClick={() => {
                               openCompetitionModal(dayToCheck)
                               closePanelAdd()
                           }}>
                            <img
                                src={plus_circle}
                                alt="plus icon"
                                className="mcr-1 icon-24"
                            />
                            {t('app:athlete.add_event.competition')}
                        </p>
                        <p className=""
                           onClick={() => {
                               openInjuryModal(dayToCheck)
                               closePanelAdd()
                           }}
                        >
                            <img
                                src={blessure_img}
                                alt="plus icon"
                                className="mcr-1 icon-24"
                            />
                            {t('app:athlete.add_event.injury')}
                        </p>
                        <p className=""
                           onClick={() => {
                               openHolidayModal(dayToCheck)
                               closePanelAdd()
                           }}
                        >
                            <img
                                src={vacance_img}
                                alt="holiday icon"
                                className="mcr-1 icon-24"
                            />
                            {t('app:athlete.add_event.holidays')}
                        </p>
                        <p className=""
                           onClick={() => {
                               openTraineeshipModal(dayToCheck)
                               closePanelAdd()
                           }}
                        >
                            <img
                                src={stage_img}
                                alt="traineeship icon"
                                className="mcr-1 icon-24"
                            />
                            {t('app:athlete.add_event.traineeship')}
                        </p>
                    </>
                </div>

            </>
        )
    }

    const dayColumnAvailabilities = (dayOfWeek) => {
        let dayToCheckYMD = activeWeek.isoWeekday(dayOfWeek).format('YYYY-MM-DD')
        let dayToCheck = moment(dayToCheckYMD)
        let currentDateYMD = currentDate.format('YYYY-MM-DD')

        // Liste des heures à afficher à gauche du tableau
        let rows = []

        // Liste de divs pointillés
        let linesHours = []

        for (let i=0; i<=24; i+=1) {
            let time = i%2 === 0 ? i>=10 ? i+'h' : '0'+i+'h': '-'
            rows.push(
                <div key={'hour-'+i}
                     className="d-flex justify-content-center align-items-center text-time-availabilities tr-availabilities">
                    {time}
                </div>
            )

            linesHours.push(
                <div key={'line-hour-'+i}
                     className="position-absolute text-time-availabilities tr-availabilities d-flex align-items-center" style={{top: i*16+"px"}}>
                    <div className="line-hour"></div>
                </div>
            )
        }

        return (
            <div key={"availabilities"+dayToCheckYMD} className="flex-column day-col-close justify-content-center px-1 py-3">
                <p className="agenda-dow pt-2">{t('app.date.short_day.' + dayToCheck.isoWeekday())}</p>

                {dayToCheckYMD === currentDateYMD ?
                    <Button variant="outline-light"
                            className="close-width agenda-cube cube-white agenda-today-case d-flex justify-content-center mx-auto px-0 mcb-4"
                            onClick={() => changeActiveDate(dayToCheck)}
                    >
                        <p className="agenda-dom-number agenda-today-case-text w-100">
                            {dayToCheck.format("D")}
                        </p>
                    </Button>
                    :
                    <Button variant="outline-light"
                            className="close-width agenda-cube cube-white d-flex justify-content-center mx-auto px-0 mcb-4"
                    >
                        <p className="agenda-dom-number w-100">
                            {dayToCheck.format("D")}
                        </p>
                    </Button>
                }

                <div className="d-flex justify-content-center">
                    <div className="agenda-cube-empty d-flex align-items-center justify-content-center w-100 pointer"
                         onClick={() => openAthleteAvailabilityModal(dayToCheck)}
                    >
                        <img src={plus_circle} alt="plus icon" className="plus-icon-empty my-auto" />
                    </div>
                </div>

                <div className="mct-3 position-relative d-flex justify-content-center" style={{height: "384px"}}>
                    {dayOfWeek === 1 &&
                    <div className="times-availabilities">
                        {rows}
                    </div>
                    }

                    {linesHours}

                    {athleteAvailabilities.map((athleteAvailability) => {
                        // let athleteAvailability = athleteAvailabilityObj.athleteAvailabilitiesKey
                        let athleteAvailabilityDate = moment.tz(athleteAvailability.start_date, "Europe/Paris")
                        if (athleteAvailabilityDate.isoWeekday() === dayOfWeek) {
                            return (
                                <AthleteAvailability key={athleteAvailability.key}
                                                     athleteAvailability={athleteAvailability}
                                                     openAthleteAvailabilityModal={() => openAthleteAvailabilityModal(dayToCheck)}
                                    // editAthleteAvailability={() => editAthleteAvailability(athleteAvailability)}
                                />
                            )
                        }
                    })}
                </div>
            </div>
        )
    }

    // Retourne un tableau avec une column de calendrier pour chaque jour de la semaine (de 1 à 7)
    const getDayColumns = (type) => {
        let columns = []
        if (type === TYPE.calendar) {
            for (let i=1; i<=7; i++) {
                columns.push(dayColumn(i))
            }
        } else {
            for (let i=1; i<=7; i++) {
                columns.push(dayColumnAvailabilities(i))
            }
        }

        return columns
    }

    // Change la date du jour séléctionné pour une date donnée
    // dayToCheck : moment object, nouveau jour actif
    const changeActiveDate = (dayToCheck) => {
        let activeDateYMD = activeDate ? activeDate.format('YYYY-MM-DD') : "1901-01-01"
        let dayToCheckYMD = dayToCheck.format('YYYY-MM-DD')
        if (activeDate && activeDate.format('YYYY-MM-DD') && dayToCheckYMD === activeDateYMD) {
            setActiveDate(null)
        } else {
            setActiveDate(dayToCheck)
            scrollHorizontal(dayToCheck.isoWeekday())
        }
        setAddEventPanel({isOpen : false, day : null})
    }

    // Fonction qui va centrer le scroll horizontal du calendrier en mobile selon le jour de la semaine
    // dayOfWeek : number [1|2|3|4|5|6|7], numéro iso du jour de la semaine
    const scrollHorizontal = (dayOfWeek) => {
        let wrapper = document.getElementById("scrolling-container-2")
        if(wrapper) {
            let colWith = 50, colActiveWidth = 200
            let left = (dayOfWeek-1)*colWith
            let middleWrapper = wrapper.offsetWidth/2
            let goal = left + (colActiveWidth/2) - middleWrapper

            if (isMobile && document.getElementsByClassName("day-col-open").length === 0) {
                let div = document.createElement("div")
                div.style.width = "150px"
                div.id = "empty-150"
                if (document.getElementById("wrapper")) {
                    document.getElementById("wrapper").appendChild(div)
                    setTimeout(function() {
                        const emptyDiv = document.getElementById("empty-150")
                        emptyDiv && emptyDiv.remove()
                    }, 700);
                }
            }

            wrapper.scrollTo({
                left: goal,
                behavior: 'smooth'
            })
        }
    }

    // Déclencher la mise à jour de la date de l'évènement déplacé dans le calendrier
    const moveTrainingEvent = (date) => {
        let newDate = date.format("YYYY-MM-DDTHH:mm:ssZ")
        let debriefingId, workoutId
        switch (trainingEventHandled.type) {
            case TrainingEventTypes.SEANCE_PLANIFIEE:
                workoutId = trainingEventHandled.workout_id
                break
            case TrainingEventTypes.COMPTE_RENDU_LIBRE:
            case TrainingEventTypes.SEANCE_REALISEE:
                debriefingId = trainingEventHandled.debriefing_id
                break
            default:
                break
        }
        if (workoutId) {
            updateWorkoutDate(workoutId, newDate)
        } else if (debriefingId) {
            updateDebriefingDate(debriefingId, newDate)
        }

    }

    // Enregistrer mise à jour de la date d'une séance planifiée (aprés déplacement dans le calendrier)
    const updateWorkoutDate = (workoutId, date) => {
        let headers = generateHeaders()
        if (headers) {
            axios
                .put(
                    config+"api/workouts/"+workoutId,
                    {
                        plannedDate : date,
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    // Cacher les emplacements de déplacement d'évènement
                    setTrainingEventHandled(null)
                    // Recharger les évènements dans le calendrier
                    getTrainingEvents()
                })

                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Enregistrer mise à jour de la date d'un compte-rendu (aprés déplacement dans le calendrier)
    const updateDebriefingDate = (debriefingId, date) => {
        let headers = generateHeaders()
        if (headers) {
            axios
                .put(
                    config+"api/debriefings/"+debriefingId,
                    {
                        date : date,
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    // Cacher les emplacements de déplacement d'évènement
                    setTrainingEventHandled(null)
                    // Recharger les évènements dans le calendrier
                    getTrainingEvents()
                })

                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Mémoriser l'évènement que l'on veut déplacer dans le calendrier
    const handleTrainingEvent = (trainingEvent) => {
        if (trainingEvent) {
            setTrainingEventHandled(trainingEvent)
        } else {
            setTrainingEventHandled(null)
        }

    }

    const weekNav = (weekAndYear) => {
        const weekString = weekAndYear.week.toString()
        const activeWeekNumber = activeWeek.format('W')
        const activeWeekYear = activeWeek.isoWeekYear()
        const currentYear = moment().isoWeekYear()
        return (
            <div
                key={'week-'+weekString+'-'+currentYear}
                className={"pcb-2 cursor-pointer d-flex align-items-center justify-content-center " + (weekString === activeWeekNumber ? "week-of-month-active-container":"")}
                onClick={() => {
                    if (weekString === activeWeekNumber && activeWeekYear === currentYear) {
                     // Ne rien faire
                    } else {
                        updateActiveWeek(moment(weekAndYear.year, 'GGGG').isoWeek(weekAndYear.week))
                    }
                }}
            >
                <span className={`week-of-month ${weekString === activeWeekNumber ? 'week-of-month-active' : ''}`}>
                    {isMobile && weekString !== activeWeekNumber ? t('app.date.week_initial') : t('app.date.week')} {weekAndYear.week < 10 ? '0'+weekString : weekString}
                </span>
            </div>
        )
    }

    const paddingForHoursLegend = isMobile && typeDisplay === TYPE.availabilities ? "pcl-7" :""

    if(activeWeek === null) {
        return <></>
    }

    return (
        <div className="agenda-card">
            <div className="d-flex pc-3 bg-white week-calendar-header calendar-header-radius">
                <div className={`d-flex align-items-center justify-content-start nav-prev-next ${!isMobile ? "ml-auto" : ""}`}>
                    <span onClick={() => changeMonth('prev')} className="change-week">
                        <img src={arrow_left_circle}
                             className="grey-arrow pointer icon-24 change-week"
                             alt="arrow left circle icon"
                        />
                    </span>
                    <span
                        className="agenda-headers-mobile ucfirst ml-2">{isMobile ? getOtherMonth(activeWeek, 'prev', false).format('MMM') : getOtherMonth(activeWeek, 'prev', true)}</span>
                </div>
                <div className="mx-auto d-flex flex-column align-items-center justify-content-center month-center">
                    <span className="agenda-headers-mobile ucfirst mb-0">
                        {activeWeek.format('MMMM YYYY')}
                    </span>
                </div>
                <div className={`d-flex align-items-center justify-content-end nav-prev-next ${!isMobile ? "mr-auto" : ""}`}>
                    <span
                        className="agenda-headers-mobile ucfirst mr-2">{isMobile ? getOtherMonth(activeWeek, 'next', false).format('MMM') : getOtherMonth(activeWeek, 'next', true)}</span>
                    <span onClick={() => changeMonth('next')} className="change-week">
                        <img src={arrow_right_circle}
                             className="grey-arrow pointer icon-24 change-week"
                             alt="arrow right circle icon"
                        />
                    </span>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-white week-calendar-header">
                <div className={`d-flex mct-3 justify-content-between pcx-3 ${isMobile ? 'w-100' : 'week-navigator'}`}>
                    {weeksOfMonth.map((weekAndYear) =>
                        weekNav(weekAndYear)
                    )}
                </div>
            </div>

            <div id="scrolling-container-2"
                 className={(isMobile ? "agenda-content scrolling-wrapper-flexbox" : "agenda-content scrolling-wrapper-flexbox") + " " + paddingForHoursLegend}>
                {loaded ?
                    <div id="wrapper"
                         className={isMobile ? "d-flex justify-content-center" : "d-flex justify-content-center w-100"}>
                        {getDayColumns(typeDisplay)}
                    </div>
                    :
                    <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                        <Loader
                            type="TailSpin"
                            color="black"
                            height={50}
                            width={50}
                            className="my-5"
                        />
                    </div>
                }
            </div>

            <div className="d-flex justify-content-center pcy-3">
                <InputSwitch
                    labelLeft={t('app.date.calendar')}
                    labelRight={t('app.date.availabilities')}
                    onSwitchChange={onTypeChange}
                    selectOpt={TYPE.availabilities === typeDisplay}
                />
            </div>

            {typeDisplay === TYPE.calendar &&
                <WeekSummary activeWeek={activeWeek}
                             openCloseTrainingSummary={()=>openCloseTrainingSummary()}
                />
            }


        </div>
    )
}
export default WeekCalendar;