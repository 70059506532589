import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form} from "react-bootstrap";
import {validate} from "../../../../img/img";
import {NavigationContext, WeekCalendarContext} from "../../../../Contexts";
import '../../../../Css/Competition.css';
import axios from "axios";
import config from "../../../../api";
import generateHeaders from "../../../../Functions/generateHeaders";
import handleEndpointError from "../../../../Functions/Alert";

// import SelectSportEvent from "../../SelectSportEvent";
import SelectSport from "../../SelectSport";
import SelectSportCompetition from "../../SelectSportCompetition";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {format, formatISO} from "date-fns"

import {dataSports} from "../Utils"

/**
 * Afficher un formulaire de création de compétition
 *
 * @param date
 * @param closeCompetitionModal
 */
const NewCompetition = ({date, closeCompetitionModal}) => {

    const {t} = useTranslation(['app'])
    const sports = dataSports(t)


    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const [sportEvent, setSportEvent] = useState(null)
    const [name, setName] = useState(null)
    const [sport, setSport] = useState(null)
    const [sportCompetition, setSportCompetition] = useState(null)
    const [goal, setGoal] = useState(null)
    const [startDate, setStartDate] = useState( date.toDate())
    const [comment, setComment] = useState(null)

    const [requiredFields, setRequiredFields] = useState({
        name: false,
        sport: false,
        sportCompetition: false,
        date: true,
        goal: false
    })
    const [errorMessage, setErrorMessage] = useState()

    const [sportEvents, setSportEvents] = useState(null)
    const [sportCompetitions, setSportCompetitions] = useState(null)
    const [disciplinesSportCompetitions, setDisciplinesSportCompetitions] = useState(null)

    const [ready, setReady] = useState(false)

    useEffect(() => {
        // getSportEvents()
        getSportCompetitions()
    }, []);


    useEffect(() => {
        if (sportEvents) {
            onChangeSportEvent(sportEvents[0])
        }
    }, [sportEvents]);


    useEffect(() => {
        if (sportEvent) {
            onChangeSport()
        }
    }, [sportEvent]);


    // useEffect(() => {
    //     if(sport == null) {
    //         onChangeSport(sports[0])
    //     }
    // }, [sports]);

    useEffect(() => {
        if (sport && disciplinesSportCompetitions) {
            setSportCompetitions(disciplinesSportCompetitions[sport.code])
        }
    }, [sport, disciplinesSportCompetitions]);

    // useEffect(() => {
    //     if(sportCompetitions) {
    //         onChangeSportCompetition(sportCompetitions[0])
    //     }
    // }, [sportCompetitions]);


    // Vérifier les champs obligatoires
    useEffect(() => {
        let isReady = true
        for (let requiredField of Object.values(requiredFields)) {
            if (requiredField == false) {
                isReady = false
                break
            }
        }
        setReady(isReady)
    }, [requiredFields])

    const updateRequiredFields = (key, action) => {
        let value = action == 'add' ? true : false;
        if (requiredFields[key] !== value) {
            let newRequiredFields = {...requiredFields}
            newRequiredFields[key] = value
            setRequiredFields(newRequiredFields)
        }
    }


    const onChangeName = (value) => {
        setName(value)
        updateRequiredFields('name', value ? 'add' : 'remove')
    }

    const onChangeComment = (value) => {
        setComment(value)
    }

    const onChangeSportEvent = (value) => {
        setSportEvent(value)
    }

    const onChangeSport = (value) => {
        setSport(value)
        updateRequiredFields('sport', value ? 'add' : 'remove')
    }

    const onChangeSportCompetition = (value) => {
        setSportCompetition(value)
        updateRequiredFields('sportCompetition', value ? 'add' : 'remove')
    }

    const onChangeStartDate = (value) => {
        setStartDate(value)
        updateRequiredFields('date', value ? 'add' : 'remove')
    }
    const onChangeGoal = (value) => {
        setGoal(value)
        if (!requiredFields.goal) {
            updateRequiredFields('goal', value ? 'add' : 'remove')
        }
    }

    const getSportEvents = async () => {
        await axios.get(
            config + "api/sport_events?date_future_only=1",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            let dataSportEvents = response.data.sport_events

            let options = []

            for (let dataSportEvent of dataSportEvents) {
                let option = {
                    id: dataSportEvent.id,
                    label: dataSportEvent.name,
                    sport_competition_id: dataSportEvent.sport_competition.id,
                    sport_id: dataSportEvent.sport,
                }

                options.push(option)
            }
            setSportEvents(options);
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }


    const getSportCompetitions = () => {
        axios.get(
            config + "api/sport_competitions?type=1",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            let options = {
                triathlon: [],
                cap: [],
                trail: [],
                velo: [],
            }

            for (let f of response.data.sport_competitions) {
                let option = {
                    id: f.id,
                    label: f.name,
                    sport: f.sport
                }
                switch (f.sport) {
                    case 1:
                        options.triathlon.push(option)
                        break
                    case 2:
                        options.cap.push(option)
                        break
                    case 3:
                        options.trail.push(option)
                        break
                    case 4:
                        options.velo.push(option)
                        break
                    default:
                        break;
                }
            }
            setDisciplinesSportCompetitions(options)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    // Enregister la competition
    const saveCompetition = () => {
        const headers = generateHeaders()

        let sportEventId = sportEvent ? sportEvent.id : null
        let sportId = sport ? sport.id : null
        let sportCompetitionId = sportCompetition ? sportCompetition.id : null

        let dateISO = formatISO(startDate)
        let datas = {
            name: name,
            sport_event_id: sportEventId,
            sport: sportId,
            sport_competition_id: sportCompetitionId,
            start_date: dateISO,
            end_date: dateISO,
            goal: goal,
            description: comment
        }

            axios
                .post(
                    config + "api/competitions/new",
                    datas,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then((response) => {
                    // Fermer le formulaire de création de compte-rendu
                    closeCompetitionModal(format(startDate, 'yyyy-MM-dd'))
                })
                .catch(error => {
                    handleEndpointError(error)
                    if (error.response !== undefined) {
                        if (error.response.status === 400) {
                            setErrorMessage(error.response.data.errors)
                        }
                    }
                })

    }

    return (
        <div className="mcb-7">
            <div id="rpe-card" className="rpe-card pcx-3 pcy-3">
                <div className="activity-CR mcb-4">
                    <div className="row">
                        {/* Evenement sportif */}
                        <div className="col-3 mcy-2 d-flex align-items-center">
                            <span className="label-input-competition">{t('app:athlete.competition.sport_event')}</span>
                        </div>
                        <div className="col-9 mcy-2">
                            {/*<SelectSportEvent sportEvents={sportEvents}*/}
                            {/*defaultSportEvent={sportEvent}*/}
                            {/*onChange={(value) => onChangeSportEvent(value)}*/}
                            {/*/>*/}
                            {sportEvent === null &&
                            <input type="text"
                                   className="w-100 form-input-text pcx-3 pcy-2 background-white"
                                   name="name"
                                   placeholder={t('app:athlete.competition.name')}
                                   value={name}
                                   onChange={event => onChangeName(event.target.value)}
                            />
                            }
                        </div>
                        {/* Sport select avec Tools/CustomSelect*/}
                        <div className="col-3 mcy-2 d-flex align-items-center">
                            <span className="label-input-competition" onClick={() => {
                            }}>{t('app:athlete.competition.sport')}</span>
                        </div>
                        <div className="col-9 mcy-2">
                            {sportEvent === null ?
                                <>
                                    <SelectSport sports={sports}
                                                 defaultSport={sport}
                                                 onChange={(value) => onChangeSport(value)}
                                    />
                                </>
                                : sport &&
                                <input type="text"
                                       className="w-100 form-input-text pcx-3 pcy-2 background-white"
                                       name="sport"
                                       value={sport.label}
                                       disabled={1}
                                />
                            }
                        </div>

                        {/* SportCompetition select avec Tools/CustomSelect */}
                        <div className="col-3 mcy-2 d-flex align-items-center">
                            <span className="label-input-competition" onClick={() => {
                            }}>{t('app:athlete.competition.sport_competition')}</span>
                        </div>
                        <div className="col-9 mcy-2">
                            {sportCompetitions && sportEvent === null ?
                                <SelectSportCompetition sportCompetitions={sportCompetitions}
                                                        defaultSportCompetition={sportCompetition}
                                                        onChange={(value) => onChangeSportCompetition(value)}
                                />
                                :
                                sportCompetition &&
                                <input type="text"
                                       className="w-100 form-input-text pcx-3 pcy-2 background-white"
                                       name="sport_competition"
                                       value={sportCompetition.label}
                                       disabled={1}
                                />
                            }
                        </div>
                        {/* Date */}
                        <div className="col-3 mcy-2 d-flex align-items-center">
                            <span className="label-input-competition">Date</span>
                        </div>
                        <div className="react-datepicker-lefted col-9 mcy-2">
                            <DatePicker
                                wrapperClassName="w-100"
                                className="custom-input w-100"
                                dateFormat="P"
                                selected={startDate}
                                onChange={(date) => onChangeStartDate(date)}
                            />
                        </div>
                        {/* Priorité :  3 boutons radios */}
                        <div className="col-3 mcy-2 d-flex align-items-start">
                            <span className="label-input-competition">{t('app:athlete.competition.goal.label')}</span>
                        </div>
                        <div className="col-9 mcy-2">
                            <div className="checkbox-goal w-100 mcb-2">
                                <input
                                    id={"major_radio"}
                                    type="radio"
                                    name="major"
                                    value={1}
                                    checked={goal === 1}
                                    onChange={() => onChangeGoal(1)}
                                />
                                <label htmlFor={"major_radio"}
                                       className="gotham-15-black">{t('app:athlete.competition.goal.1')}</label>
                            </div>
                            <div className="checkbox-goal w-100 mcb-2">
                                <input
                                    id={"minor_radio"}
                                    type="radio"
                                    name="minor"
                                    value={2}
                                    checked={goal === 2}
                                    onChange={() => onChangeGoal(2)}
                                />
                                <label htmlFor={"minor_radio"}
                                       className="gotham-15-black">{t('app:athlete.competition.goal.2')}</label>
                            </div>
                            <div className="checkbox-goal w-100 mcb-2">
                                <input
                                    id={"training_radio"}
                                    type="radio"
                                    name="training"
                                    value={3}
                                    checked={goal === 3}
                                    onChange={() => onChangeGoal(3)}
                                />
                                <label htmlFor={"training_radio"}
                                       className="gotham-15-black">{t('app:athlete.competition.goal.3')}</label>
                            </div>
                        </div>

                        {/* Commentaire pour une competition */}
                        <div className="col-12 mct-2">
                            <p className="rpe-title text-left">{t('app:athlete.competition.comment')}</p>
                        </div>
                        <div className="col-12">
                            <Form.Group controlId="form-comment">
                                <Form.Control className="mcy-2 form-login-input pc-3 background-white"
                                              as="textarea"
                                              rows={2}
                                              name="comment"
                                              placeholder={t('app:athlete.debriefing.comment.placeholder')}
                                              value={comment}
                                              onChange={event => onChangeComment(event.target.value)}
                                >
                                </Form.Control>
                            </Form.Group>
                        </div>

                        <div className="w-100 mct-5 d-flex justify-content-center">
                            {errorMessage &&
                            <div className="error-login-text mcb-3">
                                {errorMessage}
                            </div>
                            }
                            <Button
                                className="background-black py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text"
                                onClick={() => saveCompetition()}
                                disabled={!ready}
                            >
                                <img className="mr-2 icon-24" alt="valid icon"
                                     src={validate}/>{t('app:athlete.saving.save')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewCompetition;