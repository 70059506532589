import React, {useContext} from "react";
import {NavigationContext} from "../Contexts";
import {useTranslation} from "react-i18next";


const ExternalActivity = ({openExternalActivityModal}) => {
    const {t} = useTranslation(['app']);

    const {isMobile, isTablet} = useContext(NavigationContext)
    return (
        <div className={"dashboard-card pc-3 "+(isMobile || isTablet ? 'mcb-4':"")}>
            <p className="w-100 text-center week-calendar-header mb-0">
                {t('app:athlete.external_activity.title') }
                </p>
            <span className="pointer more-information" onClick={() => openExternalActivityModal()}>
                    {t('athlete.external_activity.cta')}
            </span>
        </div>
    )
}

export default ExternalActivity