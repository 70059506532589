import React, {useState, useEffect, useContext} from 'react';
import {useHistory} from "react-router";
import Wellness from "../Components/Wellness/Wellness";
import Settings from "../Components/Settings/Settings";
import Chat from "../Components/Conversation/Chat";
import NotificationMessageList from "../Components/Notification/NotificationMessageList";
import axios from "axios/index";
import generateHeaders from "../Functions/generateHeaders";
import config from "../api";
import moment from 'moment';
import queryString from 'query-string';
import WeekCalendar from "../Components/AthleteCalendar/WeekCalendar";
import TrainingEventModal from "../Components/AthleteCalendar/TrainingEvent/TrainingEventModal";
import {
    LoggedInContext,
    NavigationContext,
    TalkJSContext,
    WeekCalendarContext,
    DashboardContext, SplashScreenSettingContext
} from "../Contexts";
import NewDebriefing from "../Components/AthleteCalendar/Debriefing/NewDebriefing";
import Coach from "../Components/Coach";
import UserTrainingProgram from "../Components/UserTrainingProgram";
import TrainingLoad from "../Components/TrainingLoad/TrainingLoad";
import CompetitionModal from "../Components/AthleteCalendar/AthleteEvent/Competition/CompetitionModal";
import TrainingZones from "../Components/TrainingZones/TrainingZones";
import NewInjury from "../Components/AthleteCalendar/AthleteEvent/Injury/NewInjury";
import NewHoliday from "../Components/AthleteCalendar/AthleteEvent/Holiday/NewHoliday";
import NewTraineeship from "../Components/AthleteCalendar/AthleteEvent/Traineeship/NewTraineeship";

import Talk from "talkjs";
import NewExternalActivity from "../Components/AthleteCalendar/AthleteEvent/NewExternalActivity";
import ExternalActivity from "../Components/ExternalActivity";
import TrainingSummary from "../Components/TrainingSummary/TrainingSummary";
import WidgetHelp from "../Components/WidgetHelp";
import AthleteAvailabilityModal from "../Components/AthleteCalendar/AthleteAvailability/AthleteAvailabilityModal";
import WellnessEvolution from "../Components/Wellness/WellnessEvolution";
import Weight from "../Components/Weight/Weight";
import WeightEvolution from "../Components/Weight/WeightEvolution";
import SplashScreenSyncProvider from "../Components/SplashScreens/SplashScreenSyncProvider";
import SplashScreenWorkoutTemplate from "../Components/SplashScreens/SplashScreenWorkoutTemplate";
import SplashScreenAutonom from "../Components/SplashScreens/SplashScreenAutonom";
import HeaderMobile from "../Components/HeaderMobile";
import NewNavbarHeaderMobile from "../Components/NewNavbarHeaderMobile";
import NewFloatMenuMobile from "../Components/NewFloatMenuMobile";
import NewNavbarHeader from "../Components/NewNavbarHeader";

export default function Dashboard(props) {

    const {handleLogout} = props
    const {talkjs_conversation_id, edit_password, week, year} = queryString.parse(props.location.search)

    let history = useHistory()

    // Statut d'authentification utilisateur
    const {maintenance, enabled, coach_id, talkjs_user_id, has_user_training_program} = useContext(LoggedInContext)
    // Informations utilisateur TalkJS
    // RÃ©solution pour mobile
    const {isMobile, isTablet} = useContext(NavigationContext)

    const {showSyncProvider, showSubscribeAutonom, showPlanWorkoutTemplate} = useContext(SplashScreenSettingContext)

    let tmpNow = moment()

    const [settingsOpen, setSettingsOpen] = useState(false)
    const [isSponsorshipOpen, setIsSponsorshipOpen] = useState(null)
    const [notificationMessageListOpen, setNotificationMessageListOpen] = useState(false)
    const [now, setNow] = useState(tmpNow)
    const [currentDate, setCurrentDate] = useState(now.format('YYYY-MM-DD'))
    const [activeDate, setActiveDate] = useState(now.format('YYYY-MM-DD'))

    const [newDebriefOpen, setNewDebriefOpen] = useState(false)
    const [competitionModalOpen, setCompetitionModalOpen] = useState(false)
    const [newInjuryOpen, setNewInjuryOpen] = useState(false)
    const [newHolidayOpen, setNewHolidayOpen] = useState(false)
    const [newExternalActivityOpen, setNewExternalActivityOpen] = useState(false)
    const [newTraineeshipOpen, setNewTraineeshipOpen] = useState(false)
    const [moreStatisticsOpen, setTrainingSummaryOpen] = useState(false)
    const [wellnessEvolutionOpen, setWellnessEvolutionOpen] = useState(false)
    const [weightEvolutionOpen, setWeightEvolutionOpen] = useState(false)
    const [newElementDate, setNewElementDate] = useState(null)
    // const [dateDebrief, setDateDebrief] = useState(null)
    const [typeCalendar, setTypeCalendar] = useState(1)

    // Gérer invitation à synchroniser le matériel (montre, capteur...)
    const [showPopupSyncProvider, setShowPopupSyncProvider] = useState(false)
    const [showPopupSubscribeAutonom, setShowPopupSubscribeAutonom] = useState(false)
    const [showPopupPlanWorkoutTemplate, setShowPopupPlanWorkoutTemplate] = useState(false)

    const [athleteAvailabilityModalOpen, setAthleteAvailabilityModalOpen] = useState(false)

    // Forcer le rafraichissement du calendrier
    const {trainingEvent, 
        updateTrainingEvent, 
        trainingEventOpen,
        updateTrainingEventOpen,
        dayToOpen,
        updateDayToOpen, 
        updateActiveWeek, 
        updateAthleteEventHandled,
        updateAthleteAvailabilityEventHandled} = useContext(WeekCalendarContext)


    // Affichage du widget Coach
    const showCoach = coach_id ? true : false
    const showUserTrainingProgram = has_user_training_program ? true : false


    // Etat du widget Chat
    const [chatOpen, setChatOpen] = useState(false)
    const [showSplashScreenAutonom, setShowSplashScreenAutonom] = useState(false)
    const [conversationId, setConversationId] = useState(null)
    const [hasNewMessage, setHasNewMessage] = useState(null)
    const TalkJSContextValue = {
        chatOpen,
        conversationId,
        hasNewMessage,
        toggleChat: () => {
            if (chatOpen) {
                setChatOpen(false)
            } else {
                manageMenuItems('chat')
                // setChatOpen(true)
            }
        },
        updateConversationId: setConversationId,
        updateHasNewMessage: setHasNewMessage
    }

    // Action Ã  effectuer dans le widget Settings & widget activité externe
    const [settingsAction, setSettingsAction] = useState(null)
    const [externalActivityAction,setExternalActivityAction] = useState(null)
    const [wellnessEvolutionAction,setWellnessEvolutionAction] = useState(null)
    const [weightEvolutionAction,setWeightEvolutionAction] = useState(null)
    const DashboardContextValue = {
        settingsAction,
        updateSettingsAction: setSettingsAction,
        externalActivityAction,
        updateExternalActivityAction: setExternalActivityAction,
        wellnessEvolutionAction,
        updateWellnessEvolutionAction: setWellnessEvolutionAction,
        weightEvolutionAction,
        updateWeightEvolutionAction: setWeightEvolutionAction
    }

    const initTalk = () => {
        // Promise can be `then`ed multiple times
        Talk.ready
            .then(() => {
                let talkJsAppId = process.env.REACT_APP_TALKJS_API_KEY
                // let talkJsAppId = process.env.REACT_APP_TALKJS_TEST_API_KEY
                const me = new Talk.User(talkjs_user_id);

                if (!window.talkSession) {
                    window.talkSession = new Talk.Session({
                        appId: talkJsAppId,
                        me: me
                    });
                }

                window.talkSession.unreads.on("change", function(conversationIds) {
                    let amountOfUnreads = conversationIds.length;
                    TalkJSContextValue.updateHasNewMessage(amountOfUnreads > 0 ? true : false)
                })
            })
            .catch(e => console.error(e));
    }

    useEffect(() => {
        if(maintenance === true) {
            history.push('/public/come_back_soon')
        }
    },[maintenance])


    // Redirection vers page "Utilisateur dÃ©sactivÃ©" si l'utilisateur n'est pas 'enabled'
    useEffect(() => {
        if(maintenance === false && enabled === false) {
            history.push('/user_disabled')
        }
    },[enabled])

    useEffect(() => {
        // Initialisation du chat
        initTalk()

        // Ouverture automatique d'un Ã©vÃ¨nement du calendrier - le cas Ã©chÃ©ant
        if(!trainingEvent) {
            setTimeout(()=> {
                updateTrainingEventOpen(false)
            }, 300)
        }
        else {
            updateActiveDateFromTrainingEvent(trainingEvent)
        }

    },[])

    useEffect(() => {
        if(talkjs_conversation_id) {
            // console.log('talkjs_conversation_id', talkjs_conversation_id)
            // Fermer la popup
            updateTrainingEventOpen(false)
            // Ouvrir le chat
            TalkJSContextValue.toggleChat()
            setTimeout(() => {
                // SÃ©lectionner la conversation adÃ©quate
                TalkJSContextValue.updateConversationId(talkjs_conversation_id)
            }, 700)
        }
    }, [talkjs_conversation_id])

    let dInterval = null
    useEffect(() => {

        let isMounted = true;
        // setSettingsOpen(!isMobile && !isTablet)

        // Workaround : Async function to update the state without warning
        const checkDate = async () => {
            await axios.get(
                config + "public/api/timestamp",
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    }
                },
            ).then(data => {
                if (isMounted) {
                    // Update current date every hours
                    dInterval = setInterval(function () {
                        let newDate = moment()
                        if (newDate.format('YYYY-MM-DD') !== currentDate) {
                            setNow(newDate)
                            setCurrentDate(newDate)
                        }
                    }, 3600000)
                }
            })
        }

        checkDate()

        return () => {
            isMounted = false
            clearInterval(dInterval)
        };


    }, [currentDate, now])

    useEffect(() => {
        if (week && year) {
            updateDayToOpen(moment().year(year).week(week))
        }
    }, [week, year])

    useEffect(() => {
        if (dayToOpen) {
            updateActiveDate(dayToOpen)
            // Vider la mémoire tampon du jour à ouvrir aprés l'ouverture
            // nécessaire pour pouvoir re-déclencher l'ouverture du même jour suite à la navigation dans le calendrier
            setTimeout(() => {
                updateDayToOpen(null)
                // updateActiveDate(null)
            }, 700)
        }
    }, [dayToOpen])

    useEffect(() => {
        if(!settingsOpen && edit_password == 1) {
            setSettingsAction("edit_password")
            openCloseSettings()
        }
    }, [])

    useEffect(() => {
        if(activeDate) {
            updateActiveWeek(moment(activeDate))
        }
    }, [activeDate])

    useEffect(() => {
        if(showSyncProvider) {
            const timer = setTimeout(() => {
                setShowPopupSyncProvider(true)
            }, 3000);

            return () => clearTimeout(timer);
        }
    },[showSyncProvider])


    useEffect(() => {
        if(showPlanWorkoutTemplate) {
            const timer = setTimeout(() => {
                setShowPopupPlanWorkoutTemplate(true)
            }, 5000);

            return () => clearTimeout(timer);
        }
    },[showPlanWorkoutTemplate])

    useEffect(() => {
        if(showSubscribeAutonom) {
            const timer = setTimeout(() => {
                setShowPopupSubscribeAutonom(true)
            }, 10000);

            return () => clearTimeout(timer);
        }
    },[showSubscribeAutonom])

    function openCloseSettings() {
        if (settingsOpen) {
            setSettingsOpen(false)
        } else {
            manageMenuItems('settings')
        }
    }
    function openCloseNotificationMessageList() {
        if (notificationMessageListOpen) {
            setNotificationMessageListOpen(false)
        } else {
            manageMenuItems('notificationMessageList')
        }
    }

    const manageMenuItems = (itemName) => {
        switch(itemName) {
            case 'chat':
                // chat = true
                setChatOpen(true)
                setNotificationMessageListOpen(false)
                setSettingsOpen(false)
                break
            case 'notificationMessageList':
                setChatOpen(false)
                setNotificationMessageListOpen(true)
                setSettingsOpen(false)
                break
            case 'settings':
                setChatOpen(false)
                setNotificationMessageListOpen(false)
                setSettingsOpen(true)
                break
            default:
                break
        }
    }

    const openDebriefingModal = (date) => {
        setNewElementDate(date)
        setNewDebriefOpen(true)
    }

    const closeDebriefingModal = () => {
        setNewDebriefOpen(false)
    }

    const openCompetitionModal = (date) => {
        setNewElementDate(date)
        setCompetitionModalOpen(true)
    }

    const closeCompetitionModal = (dateYMD) => {
        if(dateYMD) {
            updateActiveDate(dateYMD)
        }
        setCompetitionModalOpen(false)
        updateAthleteEventHandled(null)
    }

    const openInjuryModal = (date) => {
        setNewElementDate(date)
        setNewInjuryOpen(true)
    }

    const closeInjuryModal = (dateYMD) => {
        if(dateYMD) {
            updateActiveDate(dateYMD)
        }
        setNewInjuryOpen(false)
    }

    const openHolidayModal = (date) => {
        setNewElementDate(date)
        setNewHolidayOpen(true)
    }

    const openExternalActivityModal = () => {
        setNewExternalActivityOpen(true)
        if(!isMobile && externalActivityAction === null) {
            setExternalActivityAction('show_external_activity')
        }
    }

    const closeExternalActivityModal = (dateYMD) => {
        if(dateYMD) {
            updateActiveDate(dateYMD)
        }
        setNewExternalActivityOpen(false)
    }

    const closeHolidayModal = (dateYMD) => {
        if(dateYMD) {
            updateActiveDate(dateYMD)
        }
        setNewHolidayOpen(false)
    }

    const openCloseTrainingSummary = () => {
        setTrainingSummaryOpen(!moreStatisticsOpen)
    }

    const openCloseWellnessEvolution = () => {
        if (wellnessEvolutionOpen === false) {
            setWellnessEvolutionOpen(true)
            setWellnessEvolutionAction('show_wellness_evolution')
        }
        else {
            setWellnessEvolutionOpen(false)
        }
    }
    const openCloseWeightEvolution = () => {
        if(weightEvolutionOpen === false) {
            setWeightEvolutionOpen(true)
            setWeightEvolutionAction('show_weight_evolution')
        }
        else {
            setWeightEvolutionOpen(false)
        }
    }

    const openTraineeshipModal = (date) => {
        setNewElementDate(date)
        setNewTraineeshipOpen(true)
    }

    const closeTraineeshipModal = (dateYMD) => {
        if(dateYMD) {
            updateActiveDate(dateYMD)
        }
        setNewTraineeshipOpen(false)
    }

    const updateActiveDate = (dateYMD) => {
        // let date = moment(dateYMD).format('YYYY-MM-DD')
        setActiveDate(dateYMD)
    }

    const openAthleteAvailabilityModal = (date) => {
        setNewElementDate(date)
        setAthleteAvailabilityModalOpen(true)
    }
    const closeAthleteAvailabilityModal = (dateYMD) => {
        if(dateYMD) {
            updateActiveDate(dateYMD)
        }
        setAthleteAvailabilityModalOpen(false)
        setTypeCalendar(2)//Afficher le calendrier en mode DisponibilitÃ©
        updateAthleteAvailabilityEventHandled(null)
    }

    const updateActiveDateFromTrainingEvent = (trainingEvent) => {
        let date = moment(trainingEvent.date).format('YYYY-MM-DD')
        setActiveDate(date)
        updateActiveWeek(moment(date))
    }
    const openTrainingEventModal = (trainingEvent) => {
        updateActiveDateFromTrainingEvent(trainingEvent)
        updateTrainingEvent(trainingEvent)
        updateTrainingEventOpen(true)
    }
    const closeTrainingEventModal = () => {
        updateTrainingEventOpen(false)
    }


    const showSyncWidget = () => {
        setSettingsAction('show_sync_provider')
        if(!settingsOpen) {
            openCloseSettings()
        }
    }

    const openPreferences =() => {
        setSettingsAction('preferences')
        if(!settingsOpen) {
            openCloseSettings()
        }
    }

    const openSubscription =(field) => {
        if(field === 'subscription') {
            setSettingsAction('subscription')
            setIsSponsorshipOpen(false)
            if(!settingsOpen) {
                openCloseSettings()
            }
        }
        else if(field === 'sponsorship') {
            setSettingsAction('subscription')
            setIsSponsorshipOpen(true)
            if(!settingsOpen) {
                openCloseSettings()
            }
        }
    }


    const changeShowPopupSyncProvider = (value) => {
        setShowPopupSyncProvider(value)
    }

    const changeShowPopupSubscribeAutonom = (value) => {
        setShowPopupSubscribeAutonom(value)
    }

    const changeShowPopupPlanWorkoutTemplate = (value) => {
        setShowPopupPlanWorkoutTemplate(value)
    }


    if (showPopupSyncProvider && showSyncProvider) {
        return (
            <DashboardContext.Provider value={DashboardContextValue}>
                <TalkJSContext.Provider value={TalkJSContextValue}>
                        <SplashScreenSyncProvider
                            showSyncWidget={() => showSyncWidget()}
                            setShowPopup={(value) => changeShowPopupSyncProvider(value)}
                        />
                </TalkJSContext.Provider>
            </DashboardContext.Provider>
        )
    }
    if (showPopupPlanWorkoutTemplate && showPlanWorkoutTemplate) {
        return (
            <DashboardContext.Provider value={DashboardContextValue}>
                <SplashScreenWorkoutTemplate
                    setShowPopup={(value) => changeShowPopupPlanWorkoutTemplate(value)}
                />
            </DashboardContext.Provider>
        )
    }
    if (showPopupSubscribeAutonom && showSubscribeAutonom) {
        return (
            <DashboardContext.Provider value={DashboardContextValue}>
                    <SplashScreenAutonom
                        setShowPopup={(value) => changeShowPopupSubscribeAutonom(value)}
                    />
            </DashboardContext.Provider>
        )
    }


    if (isMobile) {
        if (settingsOpen ||
            notificationMessageListOpen ||
            newDebriefOpen ||
            newExternalActivityOpen ||
            competitionModalOpen ||
            newInjuryOpen ||
            newHolidayOpen ||
            newTraineeshipOpen ||
            trainingEventOpen ||
            athleteAvailabilityModalOpen
        ) {
            return (
                <DashboardContext.Provider value={DashboardContextValue}>
                    <TalkJSContext.Provider value={TalkJSContextValue}>
                        <>
                            <NewFloatMenuMobile
                                handleLogout={handleLogout}
                                openCloseSettings={() => openCloseSettings()}
                                openCloseNotificationMessageList={() => openCloseNotificationMessageList()}
                                notificationMessageListOpen={notificationMessageListOpen}
                                chatOpen={chatOpen}
                                settingsOpen={settingsOpen}
                                openPreferences={() => openPreferences()}
                                openSubscription={(field) => openSubscription(field)}
                            />
                            {chatOpen ?
                                <Chat/>
                                : settingsOpen ?
                                <Settings
                                    openCloseSettings={() => openCloseSettings()}
                                    isSponsorshipOpen={isSponsorshipOpen}
                                />
                                : notificationMessageListOpen ?
                                    <NotificationMessageList
                                        openCloseNotificationMessageList={() => openCloseNotificationMessageList()}
                                    />
                                    : newExternalActivityOpen ?
                                        <NewExternalActivity
                                            closeExternalActivityModal={(dateYMD) => closeExternalActivityModal(dateYMD)}
                                        />
                                        : newDebriefOpen ?
                                            <NewDebriefing
                                                // date={dateDebrief}
                                                date={newElementDate}
                                                closeDebriefingModal={() => closeDebriefingModal()}
                                            />
                                            : competitionModalOpen ?
                                                <CompetitionModal
                                                    date={newElementDate}
                                                    closeCompetitionModal={(dateYMD) => closeCompetitionModal(dateYMD)}
                                                />
                                                : newInjuryOpen ?
                                                    <NewInjury
                                                        date={newElementDate}
                                                        closeInjuryModal={(dateYMD) => closeInjuryModal(dateYMD)}
                                                    />
                                                    : newHolidayOpen ?
                                                        <NewHoliday
                                                            date={newElementDate}
                                                            closeHolidayModal={(dateYMD) => closeHolidayModal(dateYMD)}
                                                        />
                                                        : newTraineeshipOpen ?
                                                            <NewTraineeship
                                                                date={newElementDate}
                                                                closeTraineeshipModal={(dateYMD) => closeTraineeshipModal(dateYMD)}
                                                            />
                                                            : athleteAvailabilityModalOpen ?
                                                                <AthleteAvailabilityModal
                                                                    date={newElementDate}
                                                                    closeAthleteAvailabilityModal={(dateYMD) => closeAthleteAvailabilityModal(dateYMD)}
                                                                />
                                                                : trainingEventOpen && trainingEvent ?
                                                                    <TrainingEventModal
                                                                        trainingEvent={trainingEvent}
                                                                        closeTrainingEventModal={() => closeTrainingEventModal()}
                                                                    />
                                                                    : <></>
                            }
                        </>
                    </TalkJSContext.Provider>
                </DashboardContext.Provider>
            )
        } else {
            return (
                <DashboardContext.Provider value={DashboardContextValue}>
                    <TalkJSContext.Provider value={TalkJSContextValue}>
                        <div>
                            <HeaderMobile />
                            <NewNavbarHeaderMobile
                                handleLogout={handleLogout}
                                openCloseSettings={() => openCloseSettings()}
                                openCloseNotificationMessageList={() => openCloseNotificationMessageList()}
                                notificationMessageListOpen={notificationMessageListOpen}
                                chatOpen={chatOpen}
                                settingsOpen={settingsOpen}
                                openPreferences={() => openPreferences()}
                                openSubscription={(field) => openSubscription(field)}
                            />
                            <div className="container-fluid px-2">
                                {chatOpen &&
                                <Chat/>
                                }
                                <div className="row">
                                    {moreStatisticsOpen ?
                                        <div id="more-statistics" className="col-12 p-3">
                                            <TrainingSummary openCloseTrainingSummary={openCloseTrainingSummary}
                                                             activeDayProp={activeDate}
                                            />
                                        </div>
                                        : wellnessEvolutionOpen ?
                                            <div id="more-statistics" className="col-12 p-3">
                                                <WellnessEvolution openCloseWellnessEvolution={openCloseWellnessEvolution}
                                                                   activeDayProp={activeDate}
                                                />
                                            </div>
                                        :
                                        weightEvolutionOpen ?
                                            <div id="more-statistics" className="col-12 p-3">
                                                <WeightEvolution openCloseWeightEvolution={openCloseWeightEvolution}
                                                                 activeDayProp={activeDate}
                                                />
                                            </div>
                                            :
                                        <div id="agenda-card" className="col-12 p-3">
                                            <WeekCalendar
                                                activeDateProp={activeDate}
                                                currentDate={moment(currentDate)}
                                                openDebriefingModal={(date) => openDebriefingModal(date)}
                                                openCompetitionModal={(date) => openCompetitionModal(date)}
                                                openInjuryModal={(date) => openInjuryModal(date)}
                                                openHolidayModal={(date) => openHolidayModal(date)}
                                                openTraineeshipModal={(date) => openTraineeshipModal(date)}
                                                openAthleteAvailabilityModal={(date) => openAthleteAvailabilityModal(date)}
                                                openTrainingEventModal={(trainingEvent) => openTrainingEventModal(trainingEvent)}
                                                typeCalendar={typeCalendar}
                                                openCloseTrainingSummary={openCloseTrainingSummary}
                                            />
                                        </div>
                                    }
                                    <div id="wellness-card" className="col-12 pc-3 mb-4">
                                        {showUserTrainingProgram &&
                                        <UserTrainingProgram />
                                        }
                                        <ExternalActivity
                                            openExternalActivityModal={() => openExternalActivityModal()}
                                        />
                                        <TrainingLoad key="training-load"
                                                      currentDate={currentDate}/>
                                        <Wellness key="wellness"
                                                  currentDate={currentDate}
                                                  openCloseWellnessEvolution={openCloseWellnessEvolution}
                                        />
                                        <Weight key="weight"
                                                openCloseWeightEvolution={openCloseWeightEvolution}
                                                currentDate={currentDate}
                                                activeDayProp={activeDate}
                                        />
                                        <TrainingZones />

                                        {showCoach &&
                                        <Coach />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TalkJSContext.Provider>
                </DashboardContext.Provider>
            )
        }
    }
    else {
        return (
            <DashboardContext.Provider value={DashboardContextValue}>
                <TalkJSContext.Provider value={TalkJSContextValue}>
                    <div>
                        <NewNavbarHeader
                            handleLogout={handleLogout}
                            openCloseSettings={() => openCloseSettings()}
                            openCloseNotificationMessageList={() => openCloseNotificationMessageList()}
                            settingsOpen={settingsOpen}
                            chatOpen={chatOpen}
                            notificationMessageListOpen={notificationMessageListOpen}
                            openPreferences={() => openPreferences()}
                            openSubscription={(field) => openSubscription(field)}
                        />
                        <div className="container-fluid">
                            {chatOpen &&
                            <Chat/>
                            }
                            <div className="row">
                                {isTablet && settingsOpen &&
                                <Settings
                                    openCloseSettings={() => openCloseSettings()}
                                    isSponsorshipOpen={isSponsorshipOpen}
                                />
                                }
                                {isTablet && notificationMessageListOpen &&
                                <NotificationMessageList
                                    openCloseNotificationMessageList={() => openCloseNotificationMessageList()}
                                />
                                }
                                {!isTablet && !isMobile &&
                                <div id="wellness-card" className="col-xl-3 pc-3 mb-4">
                                    <Wellness key="wellness"
                                              currentDate={currentDate}
                                              openCloseWellnessEvolution={openCloseWellnessEvolution}
                                    />
                                    <TrainingLoad key="training_load"
                                                  currentDate={currentDate}/>
                                    <Weight openCloseWeightEvolution={openCloseWeightEvolution}
                                            activeDayProp={activeDate}
                                    />
                                </div>
                                }
                                <div id="agenda-card" className="col-xl-6 col-12 p-3 mb-4">
                                    {moreStatisticsOpen ?
                                        <TrainingSummary openCloseTrainingSummary={openCloseTrainingSummary}/>
                                        : wellnessEvolutionOpen ?
                                        <div id="more-statistics" className="col-12 p-3">
                                        <WellnessEvolution openCloseWellnessEvolution={openCloseWellnessEvolution}
                                                           activeDayProp={activeDate}
                                        />
                                        </div>
                                        :
                                        weightEvolutionOpen ?
                                            <div id="more-statistics" className="col-12 p-3">
                                                <WeightEvolution openCloseWeightEvolution={openCloseWeightEvolution}
                                                                 activeDayProp={activeDate}
                                                />
                                            </div>
                                            :
                                        <WeekCalendar
                                            activeDateProp={activeDate}
                                            currentDate={moment(currentDate)}
                                            openDebriefingModal={(date) => openDebriefingModal(date)}
                                            openCompetitionModal={(date) => openCompetitionModal(date)}
                                            openInjuryModal={(date) => openInjuryModal(date)}
                                            openHolidayModal={(date) => openHolidayModal(date)}
                                            openTraineeshipModal={(date) => openTraineeshipModal(date)}
                                            openAthleteAvailabilityModal={(date) => openAthleteAvailabilityModal(date)}
                                            openTrainingEventModal={(trainingEvent) => openTrainingEventModal(trainingEvent)}
                                            typeCalendar={typeCalendar}
                                            openCloseTrainingSummary={openCloseTrainingSummary}
                                        />
                                    }
                                    {!isTablet && !isMobile &&
                                    <div className="row pt-3">
                                        <div className="col-6">
                                            {showCoach &&
                                            <Coach />
                                            }
                                            {showUserTrainingProgram &&
                                            <UserTrainingProgram />
                                            }
                                        </div>
                                        <div className="col-6">
                                            <ExternalActivity
                                                openExternalActivityModal={() => openExternalActivityModal()}
                                            />
                                        </div>
                                    </div>
                                    }
                                </div>


                                {isTablet &&
                                <>
                                    <div id="wellness-card" className="col-6 pc-3 mb-4">
                                        <Wellness key="wellness"
                                                  currentDate={currentDate}
                                                  openCloseWellnessEvolution={openCloseWellnessEvolution}
                                        />
                                        <TrainingLoad key="training_load"
                                                      currentDate={currentDate}/>
                                        <Weight openCloseWeightEvolution={openCloseWeightEvolution}
                                                activeDayProp={activeDate}
                                        />
                                    </div>
                                    <div className="col-6 pc-3 mb-4">
                                        {showUserTrainingProgram &&
                                        <UserTrainingProgram />
                                        }
                                        <ExternalActivity
                                            openExternalActivityModal={() => openExternalActivityModal()}
                                        />
                                        <TrainingZones />
                                        {showCoach &&
                                        <Coach />
                                        }
                                    </div>
                                </>
                                }
                                {!isTablet && !isMobile && settingsOpen &&
                                <Settings
                                    openCloseSettings={() => openCloseSettings()}
                                    isSponsorshipOpen={isSponsorshipOpen}
                                />
                                }
                                {!isTablet && !isMobile && notificationMessageListOpen &&
                                <NotificationMessageList
                                    openCloseNotificationMessageList={() => openCloseNotificationMessageList()}
                                />
                                }
                                {!isTablet && !isMobile && !settingsOpen && !notificationMessageListOpen &&
                                <div className="col-xl-3 pc-3 mb-4">
                                    <TrainingZones />
                                </div>
                                }

                                {newExternalActivityOpen &&
                                <NewExternalActivity
                                    closeExternalActivityModal={(dateYMD) => closeExternalActivityModal(dateYMD)}
                                />
                                }
                                {newDebriefOpen &&
                                <NewDebriefing
                                    // date={dateDebrief}
                                    date={newElementDate}
                                    closeDebriefingModal={() => closeDebriefingModal()}
                                />
                                }
                                {competitionModalOpen &&
                                <CompetitionModal
                                    date={newElementDate}
                                    closeCompetitionModal={(dateYMD) => closeCompetitionModal(dateYMD)}
                                />
                                }
                                {newInjuryOpen &&
                                <NewInjury
                                    date={newElementDate}
                                    closeInjuryModal={(dateYMD) => closeInjuryModal(dateYMD)}
                                />
                                }
                                {newHolidayOpen &&
                                <NewHoliday
                                    date={newElementDate}
                                    closeHolidayModal={(dateYMD) => closeHolidayModal(dateYMD)}
                                />
                                }
                                {newTraineeshipOpen &&
                                <NewTraineeship
                                    date={newElementDate}
                                    closeTraineeshipModal={(dateYMD) => closeTraineeshipModal(dateYMD)}
                                />
                                }
                                {athleteAvailabilityModalOpen &&
                                <AthleteAvailabilityModal
                                    date={newElementDate}
                                    closeAthleteAvailabilityModal={(dateYMD) => closeAthleteAvailabilityModal(dateYMD)}
                                />
                                }
                                {trainingEventOpen &&
                                <TrainingEventModal
                                    trainingEvent={trainingEvent}
                                    closeTrainingEventModal={() => closeTrainingEventModal()}
                                />
                                }
                            </div>
                        </div>
                        <WidgetHelp isSettingsHelper={false}

                        />
                    </div>
                </TalkJSContext.Provider>
            </DashboardContext.Provider>
        )
    }
}

