import React, {Suspense, useEffect, useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import {StripeContext} from "../Contexts";
import {Elements} from "@stripe/react-stripe-js";
import Loader from "react-loader-spinner";
import CardForm from "./CardForm"

import handleEndpointError from "../Functions/Alert";
import config from "../api";
import axios from "axios/index";
import generateHeaders from "../Functions/generateHeaders";

const NewCartPayment = ({color, updateReadyToSubmit, updatePaymentMethod, submitForm, clearBankCard}) => {

    // Traduction
    const {t} = useTranslation(['app']);

    const {stripePromise} = useContext(StripeContext)

    const [checkedTerms, setCheckedTerms] = useState(false)
    const [validCardFormValidate, setValidCardForm] = useState(false)
    const [setupIntentClientSecret, setSetupIntentClientSecret] = useState(null)

    useEffect(() => {
        // Créer le setup intent
        createSetupIntent()
    }, [])

    useEffect(() => {
        // le formulaire à l'air correct donc la demande est d'enregistrement est prête à être envoyée à Stripe
        updateReadyToSubmit(checkedTerms && validCardFormValidate)
    }, [checkedTerms, validCardFormValidate])

    const reloadSetupIntent = () => {
        // Changer de carte bancaire
        createSetupIntent()
        clearBankCard()
    }

    const handleChangeCheckedTerms = (event) => {
        let isChecked = event.target.checked
        setCheckedTerms(isChecked)
    }

    const updateValidCardForm = (value) => {
        setValidCardForm(value)
    }

    const createSetupIntent = async () => {
        await axios.post(
            config+"api/stripe_customer/create_setup_intent",
            {},
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
            )
            .then(response => {
                if (response.status === 200) {
                    let clientSecret = response.data["setup_intent_client_secret"]
                    setSetupIntentClientSecret(clientSecret)
                }

            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const savePaymentMethod = async (stripePaymentMethod) => {
        await axios.post(
            config+"api/stripe_customer/update_payment_method",
            {
                stripe_payment_method: stripePaymentMethod
            },
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
            .then(response => {
                if (response.status === 200) {
                    let paymentMethod = response.data.card
                    updatePaymentMethod(paymentMethod  ? paymentMethod : false)
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    console.log(error.response.data)
                    // TODO: Afficher l'erreur
                }
                handleEndpointError(error)
            })

    }

    function getLoader () {
        return (
            <Loader
                type="TailSpin"
                color="#0069d9"
                height={50}
                width={50}
                className="my-5"
            />
        )
    }

    return (
        <Suspense fallback={getLoader()}>
            <Elements stripe={stripePromise}>
                {setupIntentClientSecret ?
                    <>
                        <CardForm
                            setupIntentClientSecret={setupIntentClientSecret}
                            updateValidCardForm={(value) => updateValidCardForm(value)}
                            savePaymentMethod={(value) => savePaymentMethod(value)}
                            reloadSetupIntent={() => reloadSetupIntent()}
                            submitForm={submitForm}
                        />
                        <div className={"d-flex justify-content-start checkbox-container checkbox-container-subscription subscription-options-blue w-100 mb-2"}
                             style={{alignItems: 'start'}}
                        >
                            <input id="checkbox" type="checkbox"
                                   checked={checkedTerms}
                                   onChange={(event) => handleChangeCheckedTerms(event)}
                            />
                            <label htmlFor="checkbox" className="subscription-label text-left"></label>
                            <p className="subscription-label text-left"
                               style={{fontSize: '9px'}}
                            >
                                {t("checkout.legal_conditions")}
                            </p>
                        </div>
                    </>
                    :
                    getLoader()
                }
            </Elements>
        </Suspense>
    )
}
export default NewCartPayment