import React, {useState, useEffect, useContext} from 'react';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {Form,Button} from "react-bootstrap";
import '../Css/Home.css';
import {AuthContext, LanguageContext, NavigationContext} from "../Contexts";

import {updateLanguageFromSearch} from "../Functions/Utils";
import {HeaderHome} from "../Components/Home/HeaderHome";
import InputField from "../Components/Forms/InputField";
import {login_icon} from "../img/img";
import queryString from 'query-string';

const MagicLoginPage = (props) => {
    //Gestion de la langue selon l'URL
    const {updateLanguage} = useContext(LanguageContext)
    useEffect(() =>  {
        updateLanguageFromSearch(updateLanguage, props.location.search)
    }, [])

    let history = useHistory()

    const {username, token} = queryString.parse(props.location.search)


    const {t} = useTranslation(['app'])
    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    // Mettre à jour l'authentification
    const {updateToken} = useContext(AuthContext)

    const [email, setEmail] = useState(username)
    const [submit, setSubmit] = useState(username ? 1 : 0)


    useEffect(() => {
        if(submit) {
            sessionStorage.setItem("token", token)
            sessionStorage.setItem("username", email)
            updateToken(token)
            history.push("/dashboard")
        }
    }, [submit])


    const onChangeEmail = (value) => {
        setEmail(value)
    }


    return (
        <div className={isMobile ? "" : "d-flex justify-content-end background-nat"}>
            <div className={isMobile ? "pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-40 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                <HeaderHome />
                <div id="form-login" className={isMobile ? "mb-auto w-100" : "w-100"}>
                    <Form>
                        <InputField
                            name={"email"}
                            isRequired={true}
                            type={"email"}
                            placeholder={"Email *"}
                            inputValue={email}
                            setValue={(value) => onChangeEmail(value)}
                            setEmailOk={()=>{}}
                        />
                    </Form>
                </div>
                <div className="flex-column">
                    <Button id="login-btn" type="submit" className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5' onClick={() => setSubmit(1)}>
                        <img className="mcr-2 icon-24" alt="log-in icon" src={login_icon} /><p className="my-auto home-btn-p-text">{t('app:login.btn')}</p>
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default MagicLoginPage