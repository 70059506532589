import React, {useContext} from "react";
import {NavigationContext, SplashScreenSettingContext} from "../../Contexts";
import {duathlon_set, multi_set, velo_set, level_3, heart_favorites, gutai_login_desktop} from "../../img/img";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import {useHistory} from "react-router";

const SplashScreenWorkoutTemplate = ({setShowPopup}) => {
    const {isMobile} = useContext(NavigationContext)
    const {t} = useTranslation(['app'])

    let history = useHistory()

    const {
        updateShowPlanWorkoutTemplate,
        splashScreenSettingPlanWorkoutTemplate,
        updateSplashScreenSettingPlanWorkoutTemplate
    } = useContext(SplashScreenSettingContext)

    const editShowPlanWorkoutTemplate = async () => {
        let datas = {}
        datas.state = "ignore"

        await axios.put(
            config + "api/splash_screen_settings/"+splashScreenSettingPlanWorkoutTemplate.id,
            datas,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
            .then(response => {
                let newSplashScreenSettingPlanWorkoutTemplate = {...splashScreenSettingPlanWorkoutTemplate}
                newSplashScreenSettingPlanWorkoutTemplate.state = "ignore"
                updateShowPlanWorkoutTemplate(false)
                updateSplashScreenSettingPlanWorkoutTemplate(newSplashScreenSettingPlanWorkoutTemplate)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const onIgnore = () => {
        setShowPopup(false)
        editShowPlanWorkoutTemplate()
    }

    const onExplore = () => {
        history.push("/search_workout_template")
    }

    return (
        <div className="background-light-blue">
            <div className={isMobile ? "" : "d-flex justify-content-end background-workout-template"}>
                <div className={isMobile ? "pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-30 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                    <div>
                        <img
                            src={gutai_login_desktop}
                            className={isMobile ? "logo-gutai-home-mobile" : "logo-gutai-home"}
                            alt="Gutai logo"
                        />
                    </div>
                    <h3 id="text-middle-login" className={isMobile ? "splashscreen-title my-3" : "splashscreen-title fs-36 text-middle-login-desktop"}>
                        {t('app:splashscreen_workout_template.title1')}<br/>{t('app:splashscreen_workout_template.title2')}
                    </h3>
                    <p className="gotham-16">{t('app:splashscreen_workout_template.subtitle')}</p>
                    <div className="splashscreen-workout-template-content pcx-2 pcy-3 mcb-5">
                        <div className="d-flex align-items-center">
                            <div className="nav-case-discipline bcolor-multi">
                                <img className="icon-24" src={multi_set}/>
                            </div>
                            <p className="splashscreen-workout-template-text gotham-12 my-auto pcl-3">Hit Z3i 1h06min</p>
                            <img className="mcl-4 icon-24" src={level_3}/>
                            <img className="mcl-4 icon-24" src={heart_favorites}/>
                        </div>
                        <hr/>
                        <div className="d-flex align-items-center">
                            <div className="nav-case-discipline bcolor-velo">
                                <img className="icon-24" src={velo_set}/>
                            </div>
                            <p className="splashscreen-workout-template-text gotham-12 my-auto pcl-3">Hit Z3i 1h06min</p>
                            <img className="mcl-4 icon-24" src={level_3}/>
                            <img className="mcl-4 icon-24" src={heart_favorites}/>
                        </div>
                        <hr/>
                        <div className="d-flex align-items-center">
                            <div className="nav-case-discipline bcolor-duathlon">
                                <img className="icon-24" src={duathlon_set}/>
                            </div>
                            <p className="splashscreen-workout-template-text gotham-12 my-auto pcl-3">Enchainement vélo 1h30min + CAP 20min</p>
                            <img className="mcl-4 icon-24" src={level_3}/>
                            <img className="mcl-4 icon-24" src={heart_favorites}/>
                        </div>
                    </div>
                    <Button className='mx-auto home-btn home-btn-text pcx-4 pcy-2 d-flex align-items-center justify-content-center m-0'>
                        <p className="my-auto home-btn-p-text" onClick={() => onExplore()}>{t('app:splashscreen_workout_template.btn_subscribe')}</p>
                    </Button>
                    <span className="text-grey-normal mct-2 pointer" onClick={() => onIgnore()}>{t('app:splashscreen_workout_template.ignore')}</span>
                </div>
            </div>
        </div>
    )
}
export default SplashScreenWorkoutTemplate