import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

const DescriptionDebriefing = ({description, onChangeDescription, color,haveDescription}) => {
    const {t} = useTranslation(['app'])

    const [addDescriptionValue, setAddDescriptionValue] = useState( haveDescription)

    useEffect(() => {
        setAddDescriptionValue(haveDescription)
    }, [haveDescription])

    useEffect(() => {
        if(!addDescriptionValue) {
            onChangeDescription('')
        }
    }, [addDescriptionValue])

    const handleCheckChange = () => {
        setAddDescriptionValue(!addDescriptionValue)
    }

    return (
        <>
            <div className={"col-12 mcy-3 d-flex align-items-center checkbox-container checkbox-container-debriefing conditions-options-"+color}>
                <input type="checkbox" id="addDescription" name="addDescription" checked={addDescriptionValue} onChange={() => handleCheckChange()}/>
                <label htmlFor="addDescription">{t('app:athlete.debriefing.description.label')}</label>
            </div>
            {addDescriptionValue  ?
                <div className="col-12">
                    <Form.Group controlId="form-comment">
                        <Form.Control className="mcy-2 form-login-input pc-3 background-white"
                                      style={{height: '100px'}}
                                      as="textarea"
                                      rows={3}
                                      name="description"
                                      placeholder={t('app:athlete.debriefing.comment.placeholder')}
                                      value={description}
                                      onChange={event => onChangeDescription(event.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>
                </div>
                :
                <></>
            }
        </>
    )
}
export default DescriptionDebriefing;