import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import moment from 'moment';
import {arrow_left, arrow_left_circle, arrow_right_circle, x_circle} from "../../img/img";
import '../../Css/TrainingSummary.css';
import '../../Css/Agenda.css';
import {MetricContext, NavigationContext, PrioritySportContext, WeekCalendarContext} from "../../Contexts";
import {getDisciplinesSummaryzable} from "../Discipline/Utils";
import {Metrics, MetricValues} from "../../Functions/Metrics";
import generateHeaders from "../../Functions/generateHeaders";
import axios from "axios/index";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import {convertSecondsToChrono} from "../AthleteCalendar/Utils";
import TrainingSummaryDiscipline from "./TrainingSummaryDiscipline";
import TrainingSummaryChart from "./TrainingSummaryChart";
import {TRAININGSUMMARYTYPES, TRAININGSUMMARYPERIODS} from "./Utils"
import DatePicker from "react-datepicker";
import Moment from "react-moment";


const TrainingSummary = ({openCloseTrainingSummary, activeDayProp}) => {
    const {t} = useTranslation(['app'])
    const disciplinesList = getDisciplinesSummaryzable()

    const {activeWeek} = useContext(WeekCalendarContext)

    const chronoFormat = 'H[h]mm'
    const dateFormat = "YYYY-MM-DD"
    const initEndDate = activeWeek ? activeWeek.isoWeekday(7) : moment().isoWeekday(7)

    // const PERIODS = {
    //     "BEGINNING_YEAR": 1,
    //     "ENTIRE_YEAR": 2,
    //     "LAST_6_MONTHS": 3,
    //     "LAST_3_MONTHS": 4,
    //     "CUSTOM_PERIOD": 5
    // }

    // métrique utilisateur
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    const {preferedSports} = useContext(PrioritySportContext)

    const [disciplines, setDisciplines] = useState([])
    const [type, setType] = useState(TRAININGSUMMARYTYPES.WEEKLY)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(initEndDate)

    const [trainingSummaryDatas, setTrainingSummaryDatas] = useState([])
    const [statistics, setStatistics] = useState(null)
    const [statisticValues, setStatisticValues] = useState(null)
    const [chartLegend, setChartLegend] = useState(null)
    const [period, setPeriod] = useState(TRAININGSUMMARYPERIODS.LAST_3_MONTHS)

    const [disciplineStyle, setDisciplineStyle] = useState(null)

    const [isMuscu, setIsMuscu] = useState(false)
    const [isNat, setIsNat] = useState(false)


    useEffect(() => {
        if (preferedSports && preferedSports.length>0) {
            if (preferedSports.includes('triathlon')) {
                setDisciplines(["cap", "velo", "nat"])
            } else {
                setDisciplines(preferedSports)
            }
        } else {
            setDisciplines([disciplinesList[0]])
        }

    }, [preferedSports])
    useEffect(() => {
        if (disciplines) {
            if (disciplines.length > 1) {
                setDisciplineStyle("multi")
            } else {
                setDisciplineStyle(disciplines[0])
            }
        }

        setIsMuscu(disciplines && disciplines.length === 1 && disciplines[0] === 'muscu' ? true : false)
        setIsNat(disciplines && disciplines.length === 1 && disciplines[0] === 'nat' ? true : false)

    }, [disciplines])


    /**
     * L'intervalles des dates à considérer
     * est géré à partir de la date de fin.
     * ----> WARNING : Ne jamais modifier la date de début autre part que dans ce useEffect
     */
    useEffect(() => {
        if (period && endDate && period !== TRAININGSUMMARYPERIODS.CUSTOM_PERIOD && period !== TRAININGSUMMARYPERIODS.ENTIRE_YEAR) {
            let newStartDate = moment(endDate)
            switch (period) {
                case TRAININGSUMMARYPERIODS.BEGINNING_YEAR:
                    newStartDate.startOf('year')
                    break
                case TRAININGSUMMARYPERIODS.LAST_3_MONTHS:
                    // 3 derniers mois à partir de la date de fin
                    // newStartDate.subtract(3, 'months').add(1, 'days').date(1)
                    newStartDate.subtract(3, 'months').add(1, 'days').date(1)
                    break
                case TRAININGSUMMARYPERIODS.LAST_6_MONTHS:
                    // 3 derniers mois à partir de la date de fin
                    newStartDate.subtract(6, 'months').add(1, 'days').date(1)
                    break
                default:
                    break
            }

            if (type === TRAININGSUMMARYTYPES.WEEKLY){
                newStartDate.isoWeekday(1)
            }

            setStartDate(newStartDate)
        }
    }, [period, type])

    useEffect(() => {
        // Récupérer les données Volume & Kilométrage
        if (disciplines && startDate && type) {
            getTrainingSummary()
        }

    }, [disciplines, startDate])

    useEffect(() => {
        // Récupérer les données Volume & Kilométrage
        if (endDate && period === TRAININGSUMMARYPERIODS.CUSTOM_PERIOD) {
            getTrainingSummary()
        }

    }, [endDate])

    useEffect(() => {
        if (metric && statistics) {
            let distanceValue = statistics.distance ? (Math.round(metric === MetricValues.imperial ? statistics.distance_uk : statistics.distance) + (isNat ? metrics.distance_nat : metrics.distance_cap_velo)) : '-'
            let totalAscentValue = isNat === false && statistics.total_ascent ? (Math.round(metric === MetricValues.imperial ? statistics.total_ascent_uk : statistics.total_ascent) + metrics.altitude) : '-'
            let durationValue = statistics.sec ? convertSecondsToChrono(statistics.sec, chronoFormat) : '-'
            let countValue = statistics.count ? statistics.count : '-'

            setStatisticValues({
                distance: distanceValue,
                duration: durationValue,
                count: countValue,
                total_ascent: totalAscentValue
            })
        }
    }, [metric, statistics])

    useEffect(() => {
        let legend = null
        if (startDate && endDate && type) {
            let year = endDate.format('YYYY')
            let startYear = startDate.format('YYYY')
            switch (type) {
                case TRAININGSUMMARYTYPES.WEEKLY:
                case TRAININGSUMMARYTYPES.MONTHLY:
                    legend = (startYear !== year ? (startYear + ' - ') : '') + year
                    break
                case TRAININGSUMMARYTYPES.ANNUAL:
                default:
                    break
            }
        }
        setChartLegend(legend)
    }, [startDate, endDate, type])


    const getTrainingSummary = async () => {
        await axios.get(
            config + "api/calendar/training_summary",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                },
                params: {
                    discipline_codes: disciplines,
                    start_date: startDate.format(dateFormat),
                    end_date: endDate.format(dateFormat),
                    type: type

                }
            },
        ).then((response) => {
            let total = {
                "sec": 0,
                "count": 0,
                "distance": 0,
                "distance_uk": 0,
                "total_ascent": 0,
                "total_ascent_uk": 0
            }

            let datas = response.data

            for (let trainingSummaryData of datas) {
                total["sec"] += trainingSummaryData.summary.total.sec
                total["count"] += trainingSummaryData.summary.total.count
                total["distance"] += trainingSummaryData.summary.total.distance
                total["distance_uk"] += trainingSummaryData.summary.total.distance_uk
                total["total_ascent"] += trainingSummaryData.summary.total.total_ascent
                total["total_ascent_uk"] += trainingSummaryData.summary.total.total_ascent_uk
            }

            if(disciplines.length === 1 && disciplines[0] === 'nat') {
                total["distance"] = total["distance"] * 1000
            }

            setStatistics(total)

            setTrainingSummaryDatas(datas)

        }).catch(error => {
            handleEndpointError(error)
        })
    }

    const handleType = (type, newEndDate, newStartDate) => {
        switch (type) {
            case TRAININGSUMMARYTYPES.WEEKLY:
                // On se place sur la fin de la semaine
                if (newEndDate.isoWeekday() !== 7) {
                    newEndDate.isoWeekday(7)
                }
                if (newStartDate.isoWeekday() !== 1) {
                    newStartDate.isoWeekday(1)
                }
                break
            case TRAININGSUMMARYTYPES.MONTHLY:
                // On se place sur la fin du mois
                newEndDate.endOf('month')
                newStartDate.startOf('month')
                break
            case TRAININGSUMMARYTYPES.ANNUAL:
                // On se place sur la fin de l'année
                newEndDate.endOf('year')
                newStartDate = newEndDate.clone().startOf('year')
                break
            default:
                break;
        }
        return {newEndDate: newEndDate, newStartDate: newStartDate}
    }

    const onChangePeriod = (action) => {
        let newEndDate = moment(endDate)
        let newStartDate = moment(startDate)
        let key = 'weeks'
        switch (type) {
            case TRAININGSUMMARYTYPES.WEEKLY:
                key = 'weeks'
                break
            case TRAININGSUMMARYTYPES.MONTHLY:
                key = 'months'
                break
            case TRAININGSUMMARYTYPES.ANNUAL:
                key = 'years'
                break
            default:
                break;
        }
        if (action == 'previous') {
            newEndDate.subtract(1, key)
            newStartDate.subtract(1, key)
        } else if (action == 'next') {
            newEndDate.add(1, key)
            newStartDate.add(1, key)
        }

        let newDates = handleType(type, newEndDate, newStartDate)
        newEndDate = newDates.newEndDate
        newStartDate = newDates.newStartDate

        setEndDate(newEndDate)
        setStartDate(newStartDate)
    }

    const onChangeDiscipline = (disciplineToSet) => {
        if (disciplines.includes(disciplineToSet)) {
            if (disciplines.length > 1) {
                setDisciplines((oldDisciplines) => [...oldDisciplines].filter((d) => d !== disciplineToSet))
            }
        } else {
            setDisciplines((oldDisciplines) => {
                let newDisciplines = [...oldDisciplines]
                newDisciplines.push(disciplineToSet)
                return newDisciplines
            })
        }
    }

    const onChangeOneDiscipline = (prevOrNext) => {
        let disciplineIndex = disciplinesList.indexOf(disciplines[0])
        if (prevOrNext === 'prev') {
            if (disciplineIndex === 0) {
                setDisciplines([disciplinesList[disciplinesList.length - 1]])
            } else {
                setDisciplines([disciplinesList[disciplineIndex - 1]])
            }
        } else {
            if (disciplineIndex === disciplinesList.length - 1) {
                setDisciplines([disciplinesList[0]])
            } else {
                setDisciplines([disciplinesList[disciplineIndex + 1]])
            }
        }

    }

    const onChangeType = (typeToSet) => {
        if (typeToSet === TRAININGSUMMARYTYPES.ANNUAL){
            setPeriod(TRAININGSUMMARYPERIODS.ENTIRE_YEAR)
            let newDates = handleType(typeToSet, moment(endDate), moment(startDate))
            setEndDate(newDates.newEndDate)
            setStartDate(newDates.newStartDate)
        }
        if (type === TRAININGSUMMARYTYPES.ANNUAL) {
            setEndDate(initEndDate)
            setPeriod(TRAININGSUMMARYPERIODS.LAST_3_MONTHS)
        }
        setType(typeToSet)
    }

    const displayValue = (label, value) => {
        return (
            <div className="col d-flex flex-column justify-content-center mcb-3">
                <div className="more-stats-label mcb-1">
                    {label}
                </div>
                <div className={"more-stats-value color-" + disciplineStyle}>
                    {statisticValues[value]}
                </div>
            </div>
        )
    }

    const handleCheckChange = (value) => {
        setPeriod(value)
    }

    return (
        <div className="more-stats-card">
            <div className="d-flex pc-3 bg-white more-stats-header">
                {isMobile &&
                    <img src={arrow_left} alt="arrow left" className="icon-24 mcr-2 pointer"
                         onClick={openCloseTrainingSummary}/>
                }
                <p className="more-stats-header-text mb-0">
                    {t('app.calendar.training_summary.header')}
                </p>
                {!isMobile &&
                    <div className="position-absolute close-training-event-modal">
                        <img
                            className="icon-24 pointer"
                            src={x_circle}
                            alt="close right circle icon"
                            onClick={openCloseTrainingSummary}
                        />
                    </div>
                }
            </div>
            <div className={"more-stats-container mcy-3"}>
                <div id="more-stats-discipline">
                    <p className={"more-stats-header-text mb-0"}>{t('app.calendar.training_summary.select_discipline')}</p>
                    <TrainingSummaryDiscipline disciplines={disciplines}
                                               onChangeDiscipline={(disciplineToSet) => onChangeDiscipline(disciplineToSet)}
                                               disciplinesList={disciplinesList}
                                               onChangeOneDiscipline={(prevOrNext) => onChangeOneDiscipline(prevOrNext)}
                    />
                </div>
                <div className="d-flex justify-content-center w-100 mct-2 pointer">
                    <div className={`${isMobile ? "w-100 mcx-2" : "w-50"} more-stats-select-type row`}>
                        <div
                            className={(type === TRAININGSUMMARYTYPES.WEEKLY ? "more-stats-type-" + disciplineStyle : "more-stats-type") + " col"}
                            onClick={() => onChangeType(TRAININGSUMMARYTYPES.WEEKLY)}
                        >
                            {t('app.calendar.training_summary.weekly')}
                        </div>
                        <div
                            className={(type === TRAININGSUMMARYTYPES.MONTHLY ? "more-stats-type-" + disciplineStyle : "more-stats-type") + " col"}
                            onClick={() => onChangeType(TRAININGSUMMARYTYPES.MONTHLY)}
                        >
                            {t('app.calendar.training_summary.monthly')}
                        </div>
                        <div
                            className={(type === TRAININGSUMMARYTYPES.ANNUAL ? "more-stats-type-" + disciplineStyle : "more-stats-type") + " col"}
                            onClick={() => onChangeType(TRAININGSUMMARYTYPES.ANNUAL)}
                        >
                            {t('app.calendar.training_summary.annual')}
                        </div>
                    </div>
                </div>
                <TrainingSummaryChart
                    disciplines={disciplines}
                    datas={trainingSummaryDatas}
                    xAxis={type}
                    period={period}
                />
            </div>
            <div className={"more-stats-summary bg-pale-" + disciplineStyle}>
                <div className="d-flex align-items-center mct-2 mcb-3">
                    {period !== TRAININGSUMMARYPERIODS.CUSTOM_PERIOD &&
                    <>
                        <div className={`d-flex align-items-center justify-content-start`}>
                                <span onClick={() => onChangePeriod('previous')} className="change-week">
                                <img src={arrow_left_circle}
                                     className="grey-arrow pointer icon-24 change-week"
                                     alt="arrow left circle icon"
                                />
                                </span>
                        </div>

                        <div className="mx-auto d-flex align-items-center justify-content-center ">
                                <span className="more-stats-little-text">
                            {t('app.calendar.training_summary.for_period')} :&nbsp;
                                    {isMobile && <br/>}
                                    {startDate && startDate.format('DD/MM/YYYY')} - {endDate && endDate.format('DD/MM/YYYY')}
                                </span>
                        </div>

                        <div className={`d-flex align-items-center justify-content-end`}>
                                <span onClick={() => onChangePeriod('next')} className="change-week">
                                <img src={arrow_right_circle}
                                     className="grey-arrow pointer icon-24 change-week"
                                     alt="arrow right circle icon"
                                />
                                </span>
                        </div>
                    </>
                    }
                </div>
                <div className="more-stats-title">
                    {t('app.calendar.training_summary.average_' + type)}
                </div>
                {period !== TRAININGSUMMARYPERIODS.CUSTOM_PERIOD ?
                    <div
                        className={`checkbox-section mx-2 pt-3 row mb-3 ${isMobile ? "justify-content-start flex-column pcl-5" : "justify-content-center"}`}>
                        {type !== TRAININGSUMMARYTYPES.ANNUAL &&
                        <>
                            <div
                                className={"checkbox-container training-summary-checkbox-container duration-options-" + disciplineStyle + " mb-2"}>
                                <input type="checkbox" id="beginning-year" name="beginning-year"
                                       checked={period === TRAININGSUMMARYPERIODS.BEGINNING_YEAR}
                                       onChange={() => handleCheckChange(TRAININGSUMMARYPERIODS.BEGINNING_YEAR)}/>
                                <label
                                    htmlFor="beginning-year">{t('app.calendar.training_summary.durations.since_beginning_year')}</label>
                            </div>
                            <div
                                className={"checkbox-container training-summary-checkbox-container duration-options-" + disciplineStyle + " mb-2"}>
                                <input type="checkbox" id="6-months" name="6-months"
                                       checked={period === TRAININGSUMMARYPERIODS.LAST_6_MONTHS}
                                       onChange={() => handleCheckChange(TRAININGSUMMARYPERIODS.LAST_6_MONTHS)}/>
                                <label
                                    htmlFor="6-months">{t('app.calendar.training_summary.durations.6_months')}</label>
                            </div>
                            <div
                                className={"checkbox-container training-summary-checkbox-container duration-options-" + disciplineStyle + " mb-2"}>
                                <input type="checkbox" id="3-months" name="3-months"
                                       checked={period === TRAININGSUMMARYPERIODS.LAST_3_MONTHS}
                                       onChange={() => handleCheckChange(TRAININGSUMMARYPERIODS.LAST_3_MONTHS)}/>
                                <label
                                    htmlFor="3-months">{t('app.calendar.training_summary.durations.3_months')}</label>
                            </div>
                        </>
                        }

                        <div
                            className={"checkbox-container training-summary-checkbox-container duration-options-" + disciplineStyle + " mb-2"}>
                            <input type="checkbox" id="custom-period" name="custom-period"
                                   checked={period === TRAININGSUMMARYPERIODS.CUSTOM_PERIOD}
                                   onChange={() => handleCheckChange(TRAININGSUMMARYPERIODS.CUSTOM_PERIOD)}/>
                            <label
                                htmlFor="custom-period">{t('app.calendar.training_summary.durations.define_period')}</label>
                        </div>
                    </div>
                    :
                    (isMobile ?
                        <div className={"d-flex flex-column justify-content-center align-items-center mcy-3"}>
                            <div
                                className={"d-flex align-items-center justify-content-center w-100 mcb-3"}>
                                <div className="ml-auto">{t('app.calendar.training_summary.period')}</div>
                                <img className={'pointer icon-24 ml-auto'} src={x_circle} alt="x circle icon"
                                     onClick={() => handleCheckChange(TRAININGSUMMARYPERIODS.LAST_3_MONTHS)}
                                />
                            </div>
                            <div className={"d-flex align-items-center justify-content-between w-100 mcb-3 pcx-3"}>
                                    <span
                                        className={"mcr-3 width-min-content more-stats-little-text"}>{t('app.calendar.training_summary.from')}</span>
                                <DatePicker
                                    className="custom-input"
                                    dateFormat="P"
                                    selected={startDate.toDate()}
                                    onChange={(date) => setStartDate(moment(date))}
                                    showYearDropdown
                                    showMonthDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={80}
                                    maxDate={new Date()}
                                />
                            </div>
                            <div className={"d-flex align-items-center justify-content-between w-100 pcx-3"}>
                                    <span
                                        className={"mcr-3 width-min-content more-stats-little-text"}>{t('app.calendar.training_summary.period_to')}</span>
                                <DatePicker
                                    className="custom-input"
                                    dateFormat="P"
                                    selected={endDate.toDate()}
                                    onChange={(date) => setEndDate(moment(date))}
                                    showYearDropdown
                                    showMonthDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={80}
                                    maxDate={new Date()}
                                />
                            </div>
                        </div>
                        :
                        <div className={"d-flex justify-content-center align-items-center mcy-3"}>
                                <span
                                    className={"mcr-3 more-stats-little-text"}>{t('app.calendar.training_summary.period_from')}</span>
                            <DatePicker
                                className="custom-input"
                                dateFormat="P"
                                selected={startDate.toDate()}
                                onChange={(date) => setStartDate(moment(date))}
                                showYearDropdown
                                showMonthDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={80}
                                maxDate={new Date()}
                            />
                            <span
                                className={"mcx-3 more-stats-little-text"}>{t('app.calendar.training_summary.period_to')}</span>
                            <DatePicker
                                className="custom-input"
                                dateFormat="P"
                                selected={endDate.toDate()}
                                onChange={(date) => setEndDate(moment(date))}
                                showYearDropdown
                                showMonthDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={80}
                                maxDate={new Date()}
                            />
                            <img className={'pointer icon-24 mcl-3'} src={x_circle} alt="x circle icon"
                                 onClick={() => handleCheckChange(type===TRAININGSUMMARYTYPES.ANNUAL ? TRAININGSUMMARYPERIODS.ENTIRE_YEAR : TRAININGSUMMARYPERIODS.LAST_3_MONTHS)}/>
                        </div>)

                }
                <div className="row">
                    {disciplines && disciplines.length > 0 && statisticValues &&
                    <>
                        {isMuscu === false && displayValue(t('app.activity_session.length'), "distance")}
                        {displayValue(t('app.activity_session_statistic.duration'), "duration")}
                        {displayValue(t('app.calendar.training_summary.activities'), "count")}
                        {isMuscu === false && displayValue(t('app.calendar.training_summary.elevation_gain'), "total_ascent")}
                    </>
                    }
                </div>
            </div>
        </div>
    );
};

export default TrainingSummary;