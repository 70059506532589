import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import {error, tic} from "../../img/img";

const InputField = ({isRequired, name, type, inputValue, setValue, page, isDate, placeholder, isHalf, setEmailOk, withEvent}) => {
    const {t} = useTranslation(["app"])
    const [displayError, setDisplayError] = useState(false)
    const [isValidate, setIsValidate] = useState(false)
    const [firstFocus, setFirstFocus] = useState(false)

    const checkEmail = () => {
        let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]/

        if(inputValue && inputValue.match(mailformat))
        {
            setIsValidate(true)
            setStyleInput(generateStyle(false))
            setDisplayError(false)
            setEmailOk(true)
        }
        else
        {
            setIsValidate(false)
            setDisplayError(true)
            setStyleInput(generateStyle(true))
            setEmailOk(false)
        }

    }

    const generateStyle = (error) => {
        let style = "mcy-2 form-login-input pcl-3 grey-border radius-8"
        if (page === "settings") {
            style+=" background-white"
        }
        if (error) {
            style+=" border-red form-error-input"
        }
        if (inputValue !== "") {
            style+=" pct-3"
        } else {
            style+=" py-0"
        }
        return style
    }
    const [styleInput, setStyleInput] = useState(generateStyle(false))

    const handleFocus = (event) => {
        if (name === "email") {
            checkEmail()
        } else {
            setIsValidate(inputValue !== "")
            if (event.type === "blur") {
                changeInput()
            } else {
                if (!firstFocus) {
                    setFirstFocus(true)
                }
                setStyleInput(generateStyle(false))
                if (displayError) {
                    setDisplayError(false)
                }
            }
        }
    }

    const changeInput = () => {
        if (inputValue === "") {
            if (!displayError && isRequired) {
                setDisplayError(true)
                setIsValidate(false)
            }
            setStyleInput(generateStyle(true))
        } else {
            if (displayError) {
                setDisplayError(false)
                setIsValidate(true)
            }
            if(inputValue && inputValue.length === 1){
                setIsValidate(true)
            }
            setStyleInput(generateStyle(false))
        }
    }

    useEffect(() => {
        if (firstFocus) {
            if (name === "email") {
                checkEmail()
            } else {
                changeInput()
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputValue])

    const onChangeValue = (event) => {
        if (withEvent) {
            setValue(event)
        } else {
            setValue(event.target.value)
        }
        if (name === "email") {
            checkEmail()
        } else {
            changeInput()
        }
    }

    return (
        <Form.Group controlId={"formInput-"+name} className={page === "settings" ? isHalf===1 ? "mcl-2 mcb-4" : isHalf===2 ? "mcr-2 mcb-4" : "mcx-2 mcb-4" : "mcb-4 form-input-height"}>
            <Form.Control className={styleInput}
                          type={type}
                          name={name}
                          placeholder={placeholder}
                          value={inputValue}
                          onChange={event => onChangeValue(event)}
                          onFocus={handleFocus}
                          onBlur={handleFocus}
                          required
            >
            </Form.Control>
            {inputValue !== "" && <p id="little-texte-formBasicEmail" className="little-text-input-opti mb-0 mcl-3 mct-2 text-left">{placeholder}</p>}

            {displayError && !isDate &&
            <div className="relative-div error-div-relative ml-auto mcr-3">
                <div className="position-relative d-flex justify-content-center">
                    <img
                        id="errorImg"
                        src={error}
                        alt="error icon"
                        className="icon-24"
                    />
                </div>
            </div>
            }

            {isValidate && !isDate &&
                <div className="relative-div error-div-relative ml-auto mcr-3">
                    <div className="position-relative d-flex justify-content-center" style={{right: "9px"}}>
                        <img
                            id={"ticImg-form-" + name}
                            src={tic}
                            alt="tic picto"
                            className="icon-24"
                        />
                    </div>
                </div>
            }
            {name !== "email" && displayError &&
            <p id={"error-text-"+name} className={"mb-0 mcl-1 text-left error-text-input"}>{t('app:finalize_registration.required_field')}</p>
            }

        </Form.Group>
    )
}

export default InputField