import React, {useContext} from "react";
import {withTranslation} from "react-i18next";
import {Link, withRouter} from "react-router-dom";
import {Col, Row} from "react-bootstrap";
import {MetricContext} from "../../../Contexts";
import {Metrics, MetricValues} from "../../../Functions/Metrics";
import uniqid from "uniqid";

import {
    distance_icon, power_icon, heart_icon, cadence_velo_icon, total_ascent_icon,
    allure_icon, vitesse_icon, watch
    } from "../../../img/img"
import {convertSecondsToChrono} from "../Utils";

const ActivitySessionLapsTable = ({t, activitySessionId, activitySessionLaps, discipline, format}) => {

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    const isMetricUk = metric === MetricValues.imperial

    // Format du tableau
    const isFullFormat = format === 'full'
    // Affichage pour la natation
    let forSwimming = discipline === 'nat'
    // Colonnes avec 2 valeurs
    let extendedKeys = []

    // Nombre de ligne maximum dans le format réduit
    const lapNumberMax = !isFullFormat ? 6 : 1000

    // Liste des colonnes par défaut
    var keyLaps = [
        'number',
        'distance',
        'duration',
        'pace',
        'cadence',
        'speed',
        'heartrate',
        'power',
        'ascent'
    ]
    // Configuration des colonnes à afficher en fonction de
    // - discipline
    // - format
    switch(discipline){
        case 'velo':
            if(!isFullFormat) {
                keyLaps = [
                    'number',
                    'distance',
                    'duration',
                    'cadence',
                    'speed',
                    'heartrate',
                    'power',
                ]
            }else {
                keyLaps = [
                    'number',
                    'distance',
                    'duration',
                    'speed',
                    'heartrate',
                    'hr_drift',
                    'cadence',
                    'power',
                    'ascent'
                ]
            }
            break
        case 'cap':
            if(!isFullFormat) {
                keyLaps = [
                    'number',
                    'distance',
                    'duration',
                    'pace',
                    'speed',
                    'heartrate',
                    'ascent'
                ]
            }
            else  {
                keyLaps = [
                    'number',
                    'distance',
                    'duration',
                    'pace',
                    'speed',
                    'heartrate',
                    'hr_drift',
                    'cadence',
                    'power',
                    'ascent'
                ]
            }
            break;
        case 'nat':
            keyLaps = [
                'number',
                'distance',
                'duration',
                'pace',
                'cadence',
            ]
            break;
        default:
            break;
    }

    // Configuration de l'affichage du header de la colonne du tableau
    var dataCols = {}
    for(let key of keyLaps) {
        switch (key) {
            case 'distance':
                dataCols[key] = {
                    icon: distance_icon,
                    name: t('app.activity_session.length'),
                    unit: forSwimming ? metrics.distance_nat : metrics.distance_cap_velo
                }
                break
            case 'duration':
                dataCols[key] = {
                    icon: watch,
                    name: t('app.activity_session.timescale'),
                    unit: "min"
                }
                break
            case 'pace':
                dataCols[key] = {
                    icon: allure_icon,
                    name: t('app.activity_session.pace'),
                    unit: forSwimming ? metrics.allure_nat : metrics.allure_cap_velo,
                    extendedValues: forSwimming || isFullFormat
                }
                extendedKeys.push(key)
                break
            case 'speed':
                dataCols[key] = {
                    icon: vitesse_icon,
                    name: t('app.activity_session.speed'),
                    unit: metrics.speed_cap_velo,
                    extendedValues: isFullFormat
                }
                extendedKeys.push(key)
                break
            case 'cadence':
                dataCols[key] = {
                    icon: cadence_velo_icon,
                    name: t('app.activity_session.rhythm'),
                    unit: metrics.cadence_velo,
                    extendedValues: forSwimming || isFullFormat
                }
                extendedKeys.push(key)
                break
            case 'heartrate':
                dataCols[key] = {
                    icon: heart_icon,
                    name: "HR",
                    unit: metrics.heartrate,
                    extendedValues: isFullFormat
                }
                extendedKeys.push(key)
                break
            case 'hr_drift':
                dataCols[key] = {
                    icon: heart_icon,
                    name: "HR analysis",
                    unit: '%',
                    extendedValues: isFullFormat
                }
                extendedKeys.push(key)
                break
            case 'power':
                dataCols[key] = {
                    icon: power_icon,
                    name: t('app.activity_session.power'),
                    unit: metrics.power_velo,
                    extendedValues: isFullFormat
                }
                extendedKeys.push(key)
                break
            case 'ascent':
                dataCols[key] = {
                    icon: total_ascent_icon,
                    name: t('app.activity_session.drop'),
                    unit: metrics.altitude
                }
                break
            default:
                break
        }
    }

    // Préparation des valeurs pour chaque lignes du tableau
    var dataLaps = []
    for(let activitySessionLap of activitySessionLaps)
    {
        let number = activitySessionLap.number
        let distance = activitySessionLap.distance ? (isMetricUk ? activitySessionLap.distance_uk : (forSwimming ? activitySessionLap.distance  : Math.round((activitySessionLap.distance/1000)*100)/100)) : null
        let duration = activitySessionLap.duration
        let durationFormat = duration ? (duration >= 3600 ? 'H:mm:ss': 'mm:ss') : null
        let avg_pace = activitySessionLap.avg_pace ? (isMetricUk ? activitySessionLap.avg_pace_uk : activitySessionLap.avg_pace_string) : null
        let max_pace = activitySessionLap.max_pace ? (isMetricUk ? activitySessionLap.max_pace_uk : activitySessionLap.max_pace_string) : null
        let avg_speed = activitySessionLap.avg_speed ? (isMetricUk ? activitySessionLap.avg_speed_uk : activitySessionLap.avg_speed) : null
        let max_speed = activitySessionLap.max_speed ? (isMetricUk ? activitySessionLap.max_speed_uk : activitySessionLap.max_speed) : null
        let avg_cadence = activitySessionLap.avg_cadence
        let max_cadence = activitySessionLap.max_cadence
        let avg_heartrate = activitySessionLap.avg_h_r
        let max_heartrate = activitySessionLap.max_h_r
        let avg_power = activitySessionLap.avg_power
        let max_power = activitySessionLap.max_power
        let ascent = activitySessionLap.total_ascent || activitySessionLap.total_descent ?
            (isMetricUk ? (activitySessionLap.total_ascent_uk - activitySessionLap.total_descent_uk)
                        : (activitySessionLap.total_ascent - activitySessionLap.total_descent))
            : null
        let hr_drift =  activitySessionLap.hr_drift ? Math.round(activitySessionLap.hr_drift*1000)/10 : null
        let hr_rise =  activitySessionLap.hr_rise ? Math.round(activitySessionLap.hr_rise*1000)/10 : null

        let dataLap = []
        dataLap['number'] = number
        for(let key of keyLaps) {
            switch(key) {
                case 'distance':
                    dataLap['distance'] = distance ? distance : '-'
                    break
                case 'duration':
                    dataLap['duration'] = duration ? convertSecondsToChrono(duration, durationFormat) : '-'
                    break
                case 'pace':
                    dataLap['pace'] = {
                        'avg': (avg_pace ? avg_pace : '-'),
                        'max': (max_pace ? max_pace : '-')
                    }
                    break
                case 'speed':
                    dataLap['speed'] = {
                        'avg': (avg_speed ? avg_speed.toFixed(1)  : '-'),
                        'max': (max_speed ? max_speed.toFixed(1)  : '-')
                    }
                    break
                case 'cadence':
                    dataLap['cadence'] = {
                        'avg': (avg_cadence ? avg_cadence : '-'),
                        'max': (max_cadence ? max_cadence : '-')
                    }
                    break
                case 'heartrate':
                    dataLap['heartrate'] = {
                        'avg': (avg_heartrate ? avg_heartrate  : '-'),
                        'max': (max_heartrate ? max_heartrate : '-')
                    }
                    break
                case 'power':
                    dataLap['power'] = {
                        'avg': (avg_power ? avg_power  : '-'),
                        'max': (max_power ? max_power  : '-')
                    }
                    break
                case 'hr_drift':
                    dataLap['hr_drift'] = {
                        'avg': (hr_rise ? hr_rise  : '-'),
                        'max': (hr_drift ? hr_drift  : '-')
                    }
                    break
                case 'ascent':
                    dataLap['ascent'] = ascent ? ascent.toFixed(1) : '-'
                    break
                default:
                    break
            }

        }
        dataLaps.push(dataLap)
    }

    // Afficher l'entête du tableau (colonnes avec le nom et les unités)
    const getTableHeader = () => {
        let dataColsKeys = Object.keys(dataCols)

        return Object.values(dataCols).map((dataCol, index) => {
            if(dataCol.extendedValues) {
                let subtitle =  t('app.activity_session.avg')+ " " + t('app.activity_session.max')
                // TODO : Détécter hrDrift
                let isHrDrift = dataColsKeys[index] === 'hr_drift'
                let headerStyle = isHrDrift ? {minWidth:100} : null
                subtitle = isHrDrift ? "HR.Rise HR.Drift" : subtitle
                let subtitleClass = isHrDrift ? "fs-9" :""
                return(
                    <th key={activitySessionId+"-"+index} scope="row">
                        <figure className="header-lap-extended" style={headerStyle}>
                            <img className="icon-24" src={dataCol.icon} alt=""/>
                            <figcaption className="mt-2">
                                <div className="statsDescHead">{dataCol.name} {"\n"}</div>
                                <div className="texthead">{dataCol.unit}</div>
                                <div className={"textheadlaps mt-1 "+subtitleClass}>{subtitle}</div>
                            </figcaption>
                        </figure>
                    </th>
                )
            }
            return (
                <th key={activitySessionId+"-"+index} scope="col">
                    <figure>
                        <img className="icon-24" src={dataCol.icon} alt=""/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{dataCol.name} {"\n"}</div>
                            <div className="texthead">{dataCol.unit}</div>
                            {isFullFormat &&
                            <div className="textheadlaps">&nbsp;</div>
                            }
                        </figcaption>
                    </figure>
                </th>
            )
        })
    }


    const getLapElement = (keyLap, dataLap) => {
        if(keyLap === 'number') return null

        var lapElement = dataLap[keyLap]
        if(extendedKeys.indexOf(keyLap) !== -1) {
            if(forSwimming || isFullFormat) {
                return (
                    <td key={uniqid(keyLap)} className="lapsDesc">
                        <div className="d-flex justify-content-between">
                            <span>{lapElement.avg}</span>
                            <span>{lapElement.max}</span>
                        </div>
                    </td>
                )
            }
            lapElement = lapElement.avg
        }
        return (<td key={uniqid(keyLap)}  className="lapsDesc">{lapElement}</td>)
    }

    const linkTo = {
        pathname :"/activity_sessions/"+activitySessionId+"/laps",
        activitySessionId: activitySessionId
    };

    return (
        <>
            {activitySessionLaps.length > 0 &&
            <div className="intensity-section">
                {!isFullFormat &&
                <div className="row mct-4 mr-1 mb-2 justify-content-start d-flex text-left ">
                    <h2 className="seance-title2">{t('app:intervals_intensity')}</h2>
                </div>
                }
                <Row className="overflow-x-scroll justify-content-start d-flex stats-card mb-5">
                    <table className="table  mb-0 header-fixed-tab">
                        <thead>
                            <tr>
                                {getTableHeader()}
                            </tr>
                        </thead>
                        <tbody>
                        {dataLaps.map((dataLap, index) => {
                            if (dataLap.number > lapNumberMax) return null
                            return (
                                <tr key={dataLap.number} data-position={dataLap.number}>
                                    { keyLaps.map((keyLap, index) => getLapElement(keyLap, dataLap))}
                                </tr>
                        )})}
                        </tbody>
                    </table>
                    {!isFullFormat &&
                        <Col className="my-3 statsDesc">
                            <Link to={linkTo}>
                                {t('app.activity_session.see_laps')}
                            </Link>
                        </Col>
                    }
                </Row>
            </div>
            }
        </>
    )
}

export default withRouter(withTranslation(['app'],{ wait: true })(ActivitySessionLapsTable));