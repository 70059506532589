import React, {useContext, useEffect} from "react";

import {MetricContext} from "../../Contexts";
import {getCIPacingLabel, getCIPacingUnit, TrainingConceptCodes} from "../TrainingConcept/Utils";
import {useTranslation} from "react-i18next";


// Générer l'entête du tableau récapitulatif
const PacingTabHeader = ({trainingConceptCode, discipline, nbPacingColumn}) => {
    const {t} = useTranslation(['app'])

    const {metric} = useContext(MetricContext)


    let name = t('app.training_concept.critical_heartrate')
    let unitLabel = "bpm"

    if (trainingConceptCode === TrainingConceptCodes.CRITICAL_INTENSITY) {
        name = getCIPacingLabel(discipline, t)
        unitLabel = getCIPacingUnit(discipline, metric)
    }

    useEffect(() => {
    }, [trainingConceptCode, discipline, nbPacingColumn])

    // Génerer l'entête de la colonne de l'allure/puissance/vitesse
    const PacingColumn = ({name, unitLabel, elevation}) => {
        return (
            <span className={ (elevation ? 'col-3 p-0' : 'col-5') + " fs-10" }>
                <span className="font-weight-bold">{name}</span>
                <br/>
                {unitLabel}
                <br/>
                {elevation ? '('+elevation+')': <>&nbsp;</>}
            </span>
        )
    }


    if(!trainingConceptCode && !nbPacingColumn) {
        return <></>
    }

    return (
        <div className="container pcx-2">
            <div className="row pcx-3 mcb-3">
                <span className="col-3 p-0 fs-10 font-weight-bold">{t('app:athlete.training_zone.intensity_zones')}</span>
                <span className={(nbPacingColumn === 2 ? 'col-3':'col-4')+" fs-10 font-weight-bold"}>
                        <span dangerouslySetInnerHTML={{__html: t('app:athlete.training_zone.rpe_indicative')}}>
                        </span>
                    </span>
                {nbPacingColumn === 1 &&
                <PacingColumn key={"column-only"}
                    name={name}
                    unitLabel={unitLabel}
                    elevation={false}
                />
                }
                {nbPacingColumn === 2 &&
                <>
                    <PacingColumn key={"column-flat"}
                        name={name}
                        unitLabel={unitLabel}
                        elevation={t('app:athlete.training_zone.flat')}
                    />
                    <PacingColumn key={"column-ascent"}
                        name={name}
                        unitLabel={unitLabel}
                        elevation={t('app:athlete.training_zone.ascent')}
                    />
                </>
                }
            </div>
        </div>
    )
}

export default PacingTabHeader