import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {NavigationContext, SplashScreenSettingContext} from "../../Contexts";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import {Button} from "react-bootstrap";
import watch_sync_white from "../../img/watch_sync_white.png";
import {gutai_login_desktop} from "../../img/img";


const SplashScreenSyncProvider = ({showSyncWidget, setShowPopup}) => {
    const {t} = useTranslation(['app'])
    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    const {
        updateShowSyncProvider,
        splashScreenSettingSyncProvider,
        updateSplashScreenSettingSyncProvider
    } = useContext(SplashScreenSettingContext)


    const onSynchronize = () => {
        setShowPopup(false)
        showSyncWidget()
    }

    const onClose = () => {
        editShowProviderSetting()
    }

    const editShowProviderSetting = async () => {
        let datas = {}
        datas.state = "ignore"

        await axios.put(
            config + "api/splash_screen_settings/"+splashScreenSettingSyncProvider.id,
            datas,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        )
            .then(response => {
                let newSplashScreenSettingSyncProvider = {...splashScreenSettingSyncProvider}
                newSplashScreenSettingSyncProvider.state = "ignore"
                updateShowSyncProvider(false)
                updateSplashScreenSettingSyncProvider(newSplashScreenSettingSyncProvider)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    return (
        <div className={isMobile ? "" : "d-flex justify-content-end background-onboarding"}>
            <div className={isMobile ? "pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-30 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                <div>
                    <img
                        src={gutai_login_desktop}
                        className={isMobile ? "logo-gutai-home-mobile" : "logo-gutai-home"}
                        alt="Gutai logo"
                    />
                </div>
                <p className="onboarding-title">
                    {t('settings.preferences.synchronization')}
                </p>
                <p className="onboarding-desc">
                    {t('onboarding_screen.step.synchro.0')}
                </p>

                <p className="onboarding-desc">
                    {t('app:popup_synchronization.info')}
                </p>

                <Button className="home-btn home-btn-text justify-content-center"
                        onClick={() => onSynchronize()}
                >
                    <img className="icon-20 mr-3" src={watch_sync_white} alt="watch icon white"/>
                    {t('app:popup_synchronization.btn_synchronize')}
                </Button>

                <p className="onboarding-ignore mct-5 pointer" onClick={() => onClose()}>{t('onboarding_screen.ignore')}</p>
            </div>
        </div>
    );
};


export default SplashScreenSyncProvider