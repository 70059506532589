import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import axios from "axios";
import config from "../../api";

import {logo_zwift} from "../../img/img"

import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from '../../Functions/Alert';


function PreferenceZwift({isZwiftSynced, zwiftStatus, updateIsZwiftSynced, updateZwiftModal, zwiftCredentials}) {
    const {t} = useTranslation(['app'])

    const getZwiftStatusMessage = (zwiftStatus) => {
        let value = null
        switch (zwiftStatus) {
            case  1:
                value = t('app:settings.preferences.zwift.status.sync_in_progress')
                break
            case  3:
                value = t('app:settings.preferences.zwift.status.refused')
                break
            default:
                break
        }
        return value
    }

    const [zwiftStatusMessage, setZwiftStatusMessage] = useState(null)

    const onChangeZwiftSynced = () => {

        if(isZwiftSynced == true) {
            // Désynchroniser
            unsyncZwift()
        }
        else {
            // Ouvrir modal
            updateZwiftModal('open')
        }
    }

    useEffect(() => {
        if(zwiftCredentials) {
            syncZwift(zwiftCredentials)
        }
    }, [zwiftCredentials]);

    useEffect(() => {
        setZwiftStatusMessage(getZwiftStatusMessage(zwiftStatus))
    }, [zwiftStatus]);

    // Synchroniser le compte Zwift
    const syncZwift = async (zwiftCredentials) => {
        await axios.put(
            config+"api/my_zwift/authenticate",
            zwiftCredentials,
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            }
        ).then(response => {
            setZwiftStatusMessage(getZwiftStatusMessage(1))
            updateZwiftModal('close')
        })
        .catch(error => {
            handleEndpointError(error)
        })
    }

    // Désynchroniser le compte Zwift
    const unsyncZwift = async () => {
        await axios.put(
            config+"api/my_zwift/unregister",
            {},
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            updateIsZwiftSynced(!response.data.unsynced)
        })
        .catch(error => {
            handleEndpointError(error)
        })
    }


    return (
        <>
            <div className="w-100 d-inline-flex align-items-center justify-content-between show-input-settings mcb-3 ">
                <img
                    src={logo_zwift}
                    className="settings-provider-logo pcy-2"
                    alt="Zwift logo"
                />
                <span className="fs-8"> {zwiftStatusMessage}</span>
                <span className="toggle-switch">
                    <input type="checkbox" checked={isZwiftSynced} onChange={()=>{}}/>
                    <span className="switch" onClick={() => onChangeZwiftSynced()} />
                </span>
            </div>
        </>
    )
}

export default PreferenceZwift;