import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import {helpCircle,watch_white} from "../../img/img";
import moment from "moment"
import axios from "axios/index";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";

const PerformancePrediction = ({discipline}) => {
    const {t} = useTranslation(['app'])

    const [color, setColor] = useState()
    const [colorCode, setColorCode] = useState()
    const [isOpen, setIsOpen] = useState(false)

    const [years, setYears] = useState([])
    const [performanceGoalDistances, setPerformanceGoalDistances] = useState([])

    const [distanceChecked, setDistanceChecked] = useState(null)
    const [yearChecked, setYearChecked] = useState(null)

    const [prediction, setPrediction] = useState('--:--:--')
    const [performance1, setPerformance1] = useState(null)
    const [performance2, setPerformance2] = useState(null)
    const [performance3, setPerformance3] = useState(null)


    const getDataTip = () => {
        return (
            <div className="text-left">
                <p>{t('app.performance_prediction.description')}</p>
            </div>
        )
    }

    useEffect(() => {
        // Liste des années
        let currentYear = moment().year();
        setYears([currentYear-2, currentYear-1, currentYear])

        onChangeYearChecked(currentYear)
    }, [])

    useEffect(() => {
        let color = 'blue'
        let colorCode = ""
        let distances = []
        switch (discipline) {
            case 'nat':
                color = 'blue'
                colorCode = "#007DFF"
                distances = [
                    1500, 1800, 3900
                ]
                break
            case 'cap':
                color = 'orange'
                colorCode = "#FFA800"
                distances = [
                    10000, 21100, 42000
                ]
                break
            default:
                break
        }
        setColor(color)
        setColorCode(colorCode)
        setPerformanceGoalDistances(distances)
        onChangeDistanceChecked(distances.length ? distances[0] : null)
    },[discipline])


    useEffect(() => {
        if(isOpen) {

            const getPerformancPrediction = async () => {

                await axios.get(
                    config+"api/athlete_performance/prediction",
                    {
                        headers: {
                            'X-WSSE': generateHeaders()
                        },
                        params: {
                            distance: distanceChecked,
                            discipline_id: discipline === 'nat' ? 5:7,
                            year: yearChecked
                        }
                    }
                ).then(response => {

                    let data = response.data

                    let predictionChrono = data.prediction
                    if(predictionChrono === null) {
                        predictionChrono = '--:--:--'
                    }
                    setPrediction(predictionChrono)

                    let perf1 = {
                        'distance': '-',
                        'chrono': '--:--:--'
                    };
                    let perf2 = {
                        'distance': '-',
                        'chrono': '--:--:--'
                    };
                    let perf3 = {
                        'distance': '-',
                        'chrono': '--:--:--'
                    };
                    if(data.reference !== null) {
                        let values = Object.entries(data.reference)
                        perf1.distance = values[0][0].split(" ").join("")
                        perf1.chrono = values[0][1]
                        perf2.distance = values[1][0].split(" ").join("")
                        perf2.chrono = values[1][1]
                        perf3.distance = values[2][0].split(" ").join("")
                        perf3.chrono = values[2][1]
                    }
                    setPerformance1(perf1)
                    setPerformance2(perf2)
                    setPerformance3(perf3)

                }).catch(error => {
                    handleEndpointError(error)
                })
            }

            getPerformancPrediction()

        }

    }, [distanceChecked, yearChecked, isOpen])



    const onChangeDistanceChecked = (value) => {
        setDistanceChecked(value)
    }
    const onChangeYearChecked = (value) => {
        setYearChecked(value)
    }

    return (
        <>
        {discipline !== "velo" ?
            <div className={"zs-tab-card pcy-3 mcx-2 mct-3 " + (!isOpen ? "bg-transparent" : "")}>
                <div className="monitoring-title pcx-2"
                     data-tip={""}
                     data-for="timerPredictionHelper"
                >
                    {t('app.performance_prediction.title')}
                    <img
                         src={helpCircle} className="ml-2 icon-24"
                         alt="help icon"

                    />
                </div>
                <ReactTooltip key="tooltip-timer-prediction"
                              id="timerPredictionHelper"
                              type="light"
                              multiline={true}
                              textColor="#000000"
                              backgroundColor="#F5F5F5"
                              className="tooltip"
                              effect="solid"
                              place="bottom"
                              getContent={() => getDataTip()}
                />
                <span className="cursor-pointer  pcx-2 d-flex justify-content-end dropdown-arrow"
                      onClick={() => setIsOpen(isOpen => !isOpen)}
                >
                    <i className={isOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
                </span>
                {isOpen ?
                    <>
                        <div className="row align-items-center pc-3 pcb-2">
                            {performanceGoalDistances.length > 0 && discipline && performanceGoalDistances.map((performanceGoalDistance, index) => {
                                let performanceGoalDistanceName = discipline == 'cap' ? ((performanceGoalDistance/1000) + 'km') : (performanceGoalDistance+ 'm')
                                let labelStyle = performanceGoalDistance === distanceChecked ? "font-weight-bold" : ""
                                return (
                                    <div className="col-4 pcx-1" key={discipline+ "-distance-"+performanceGoalDistance}>
                                        <div className={"checkbox-container-"+color+" w-100 mcy-1 mcl-2"}>
                                            <input
                                                id={"distance_"+discipline+"_"+performanceGoalDistance}
                                                type="radio"
                                                name={performanceGoalDistanceName}
                                                value={performanceGoalDistance}
                                                checked={performanceGoalDistance === distanceChecked}
                                                onChange={() => onChangeDistanceChecked(performanceGoalDistance)}
                                            />
                                            <label htmlFor={"distance_"+discipline+"_"+performanceGoalDistance} className={labelStyle + " fs-12"}>{performanceGoalDistanceName}</label>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        <div className="row mcy-2 p-0 ppr-tab-row" style={{borderColor: colorCode}}>
                            <div className="col-3 row-label"
                                 style={{borderColor: colorCode, background: colorCode}}>
                                <img className="icon-18" src={watch_white}/>
                            </div>
                            <span style={{color:colorCode}} className="col-9 gotham-12 fs-16">{prediction}</span>
                        </div>
                        <h1 className="monitoring-title pcx-2 pct-3 fs-12">
                            {t('app.performance_prediction.reference')}
                        </h1>
                        <div className="row align-items-center pcx-3 pcy-2">
                            {years.length > 0 && years.map((year) => {
                                let labelStyle = year === yearChecked ? "font-weight-bold" : ""
                                return (
                                    <div className="col-4 pcx-1" key={"year-"+year}>
                                        <div className={"checkbox-container-"+color+" w-100 mcy-1 mcl-2"}>
                                            <input
                                                id={"year_"+year}
                                                type="radio"
                                                name={year}
                                                value={year}
                                                checked={year === yearChecked}
                                                onChange={() => onChangeYearChecked(year)}
                                            />
                                            <label htmlFor={"year_"+year} className={labelStyle + " fs-12"}>{year}</label>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                        {performance1 &&
                        <div className="row mcy-2 p-0 ppr-tab-row gotham-12" style={{borderColor: colorCode}}>
                            <div className="col-3 row-label"
                                 style={{borderColor: colorCode, background: colorCode}}>
                                <span className="font-weight-normal gotham-12 mcr-3 mcl-3 text-white">{performance1.distance}</span>
                            </div>
                            <span className="col-9">{performance1.chrono}</span>
                        </div>
                        }
                        {performance2 &&
                        <div className="row mcy-2 p-0 ppr-tab-row gotham-12" style={{borderColor: colorCode}}>
                            <div className="col-3 row-label"
                                 style={{borderColor: colorCode, background: colorCode}}>
                                <span className="font-weight-normal gotham-12 mcr-3 mcl-3 text-white">{performance2.distance}</span>
                            </div>
                            <span className="col-9">{performance2.chrono}</span>
                        </div>
                        }
                        {performance3 &&
                        <div className="row mcy-2 p-0 ppr-tab-row  gotham-12" style={{borderColor: colorCode}}>
                            <div className="col-3 row-label"
                                 style={{borderColor: colorCode, background: colorCode}}>
                                <span className="font-weight-normal mcr-3 mcl-3 text-white">{performance3.distance}</span>
                            </div>
                            <span className="col-9">{performance3.chrono}</span>
                        </div>
                        }
                    </>
                    :
                    <></>
                }
            </div>
        :
        <></>
    }
    </>
    )
}

export default PerformancePrediction