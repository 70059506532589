import { useEffect } from 'react';
import { Workbox } from 'workbox-window';

const CacheBuster = ({ children }) => {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      const wb = new Workbox('/workbox-strategies.js');

      wb.register();
    }
  }, [])

  return children;
};

export default CacheBuster;
