import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Form,Col} from "react-bootstrap";
import {eye, eye_slash, validate, validate_blue, x_circle} from "../../img/img";

import '../../Css/Settings.css';

function PreferenceZwiftModal({setZwiftCredentials, updateZwiftModal}) {

    const {t} = useTranslation(['app'])

    const [zwiftLogin, setZwiftLogin] = useState(null)
    const [zwiftPassword, setZwiftPassword] = useState(null)
    const [hidePassword, setHidePassword] = useState(true)
    const [errorMessage, setErrorMessage] = useState(false)

    const handleChange = (event) => {
        let value = event.target.value
        switch(event.target.name) {
            case 'zwiftLogin':
                setZwiftLogin(value)
                // Vérifier format de l'email
                let mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-]/
                setErrorMessage(!value.match(mailformat))
                break
            case 'zwiftPassword':
                setZwiftPassword(value)
                break
            default:
                break;
        }
    }

    const handleSubmit = () => {
        setZwiftCredentials({
            zwift_username: zwiftLogin,
            zwift_password: zwiftPassword
        })
    }

    return (
        <div className="settings-modal">
            <p className="title-pref mct-3 pcx-3">
                {t('app:settings.preferences.zwift.authentication')}
                <img
                    className="icon-24 pointer"
                    src={x_circle}
                    alt="arrow left icon"
                    style={{position: 'absolute', right: '10px'}}
                    onClick={() => updateZwiftModal('close')}
                />
            </p>
            <Form className="pcx-3 pct-3">
                <Form.Group className="mcb-3 form-input-height">
                    <Form.Control className="form-login-input pcl-3 py-0"
                                  type="text"
                                  name="zwiftLogin"
                                  placeholder="Login ZWIFT *"
                                  value={zwiftLogin}
                                  onChange={(e) => handleChange(e)}
                                  required
                    >
                    </Form.Control>
                </Form.Group>
                {errorMessage &&
                <p id="error-text-input" className="mcb-2 text-left error-text-input">{t('app:login.mail_format')}</p>
                }

                <Form.Group className="mcb-2 form-input-height">
                    <Form.Control className="form-login-input pcl-3 py-0"
                                  type={hidePassword ?"password":"text"}
                                  name="zwiftPassword"
                                  placeholder={t('app:login.password')+" ZWIFT *"}
                                  value={zwiftPassword}
                                  onChange={(e) => handleChange(e)}
                                  required
                    />
                    <div className="test-eye ml-auto mcr-3">
                        {hidePassword ?
                            <img
                                id="eyeSlashImg"
                                style={{cursor: 'pointer'}}
                                src={eye_slash}
                                alt="eye slash picto"
                                className="icon-24"
                                onClick={() => setHidePassword(false)}
                            />
                        :
                            <img
                                id="eyeImg"
                                style={{cursor: 'pointer'}}
                                src={eye}
                                alt="eye picto"
                                className="icon-24"
                                onClick={() => setHidePassword(true)}
                            />
                        }
                    </div>
                </Form.Group>
            </Form>
            <div className="flex-column">
                {zwiftLogin && zwiftPassword && !errorMessage ?
                    <button className='btn btn-primary mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5'
                            onClick={(e) => handleSubmit(e)}
                    >
                        <img className="mcr-2 icon-24" alt="log-in icon" src={validate} />
                        <p className="my-auto home-btn-p-text">{t('app:settings.preferences.zwift.save')}</p>
                    </button>
                    :
                    <button className='mx-auto home-btn-white home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5' disabled>
                        <img className="mcr-2 icon-24" alt="log-in icon" src={validate_blue} />
                        <p className="my-auto home-btn-p-text">{t('app:settings.preferences.zwift.save')}</p>
                    </button>
                }
            </div>
        </div>
    )
}

export default PreferenceZwiftModal;