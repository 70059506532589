import React, {useEffect, useState, useContext} from "react";
import {DataZones} from "./Utils";
import {NavigationContext} from "../../Contexts";


// Générer le corps de tableau : lignes avec Nom des zones, RPE et Allure(s)
const PacingTabBody = ({currentDataPacingValues, discipline, contextCode, pathType, nbPacingColumn}) => {

    const {isMobile} = useContext(NavigationContext)

    const [tabContent, setTabContent] = useState([])
    const [pacingValueStyle, setPacingValueStyle] = useState(null)


    const zoneNameStyle = 'col-3'

    useEffect(() => {
        if(contextCode && pathType) {
            let lines = []

            // Récupérer les informations adéquate pour les zones d'intensités, en fonction :
            // -  discipline et type de Zone (I ou II)
            for (let dataZone of DataZones) {
                if ((dataZone.path_type_only == null || dataZone.path_type_only === pathType) &&
                    (dataZone.disciplines_only === null || dataZone.disciplines_only.indexOf(discipline) !== -1))
                {
                    lines.push(dataZone)
                }
            }
            let fontStyleClass = isMobile ? 'fs-12' : (nbPacingColumn === 2 ? 'fs-9' : 'fs-10')
            let style = (nbPacingColumn === 2 ? 'col-3 p-0': 'col-5') + ' ' + fontStyleClass
            setPacingValueStyle(style)

            setTabContent(lines)

        }
    },[contextCode, pathType, nbPacingColumn])




    return (
        <div className="container pcx-2">
            {pacingValueStyle && nbPacingColumn && tabContent.length && tabContent.map((item, index) => {

                if(currentDataPacingValues[item.name]) {
                    return (
                        <div key={contextCode+'-'+index}
                             className="row mcy-2 p-0 tz-tab-row"
                             style={{borderColor: item.color}}
                        >
                            <div className={zoneNameStyle + " row-label"}
                                 style={{borderColor: item.color, background: item.color}}
                            >
                                <span className="fs-10 row-name">{item.name}</span>
                            </div>
                            <span className={(nbPacingColumn == 2 ? 'col-3':'col-4') + " " + (isMobile ? 'fs-12': 'fs-10')}>{item.rpe}</span>
                            <span className={pacingValueStyle+ ""}>{currentDataPacingValues[item.name][0]}</span>
                            {nbPacingColumn == 2 &&
                            <span className={pacingValueStyle+ ""}>{currentDataPacingValues[item.name][1]}</span>
                            }
                            {/*<span className={pacingValueStyle+ " pcr-0"}>{currentDataPacingValues[item.name][1]}</span>*/}
                            {/*<span className={pacingValueStyle+ " pcr-0"}>{currentDataPacingValues[item.name][0]}</span>*/}
                        </div>

                    )
                }
                return <React.Fragment key={contextCode+'-'+index}></React.Fragment>
            })}
        </div>
    )

}

export default PacingTabBody;