import React from 'react';
import {Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from "../img/logo_gutai_black.png";
import handleLogout from "./HandleLogout"
import NewNavBtns from "./NewNavBtns";
const NewNavbarHeader = (props) => {
    const {settingsOpen,chatOpen,notificationMessageListOpen,openPreferences,openSubscription} = props

    return (
        <div className="new-header pcx-0 mcb-2">
            <Navbar>
                <Navbar.Brand className="mx-auto abs-center-x" as={Link} to="/">
                    <img
                        src={logo}
                        className="d-inline-block align-top navbar-logo"
                        alt="Gutai logo"
                    />
                </Navbar.Brand>
                <NewNavBtns
                    handleLogout={() => handleLogout()}
                    openCloseSettings={() => props.openCloseSettings()}
                    openCloseNotificationMessageList={() => props.openCloseNotificationMessageList()}
                    // onClickChat={() => props.onClickChat()}
                    settingsOpen={settingsOpen}
                    chatOpen={chatOpen}
                    notificationMessageListOpen={notificationMessageListOpen}
                    openPreferences={() => openPreferences()}
                    openSubscription={(field) => openSubscription(field)}
                />
            </Navbar>
        </div>
    )
}
export default NewNavbarHeader
