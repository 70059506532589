import {DataZones} from "../../TrainingZones/Utils";
import React, {useContext, useEffect, useState} from "react";
import {NavigationContext} from "../../../Contexts";
import {useTranslation} from "react-i18next";

const ActivitySessionIntensityTab = ({displayTab,setDisplayTab,pathType,discipline}) => {

    const {isMobile} = useContext(NavigationContext)
    const {t} = useTranslation(['app'])

    const [tabContent, setTabContent] = useState([])

    const zoneNameStyle = 'col-3'

    useEffect(() => {
        if(pathType) {
            let lines = []

            // Récupérer les informations adéquate pour les zones d'intensités, en fonction :
            // -  discipline et type de Zone (I ou II)
            for (let dataZone of DataZones) {
                if ((dataZone.path_type_only == null || dataZone.path_type_only === pathType) &&
                    (dataZone.disciplines_only === null || dataZone.disciplines_only.indexOf(discipline) !== -1))
                {
                    lines.push(dataZone)
                }
            }
            setTabContent(lines)

        }
    },[pathType])

    return (
        <>
            {displayTab ?
                <>
                    <div className="container pcx-2 pcy-0">
                        <h1 className="seance-title2 fs-16 text-left">{t('app:athlete.training_zone.title_intensity_zones')}</h1>
                        <div className="row pcx-3 mcb-3">
                            <span
                                className="col-3 p-0 fs-10 font-weight-bold">{t('app:athlete.training_zone.intensity_zones')}
                            </span>
                            <span className={"col-4 fs-10 text-center font-weight-bold"}>
                                <span dangerouslySetInnerHTML={{__html: t('app:athlete.training_zone.rpe_indicative')}}>
                                </span>
                            </span>
                            <span className={"col-5 fs-10 text-center font-weight-bold"}>
                                <span>{t('app:athlete.training_zone.time_to_support_effort')}</span>
                            </span>
                        </div>
                    </div>
                    <div className="container pcx-2 mcb-2">
                        {tabContent.map((item, index) => {

                            if (item.name) {
                                return (
                                    <div key={index}
                                         className="row mcy-2 p-0 tz-tab-row"
                                         style={{borderColor: item.color}}
                                    >
                                        <div className={zoneNameStyle + " row-label"}
                                             style={{borderColor: item.color, background: item.color}}
                                        >
                                            <span className="fs-10 row-name">{item.name}</span>
                                        </div>
                                        <span className={"col-4 text-center fs-12"}>{item.rpe}</span>
                                        <span className="col-5 fs-12">{t('app:athlete.training_zone.effort_labels.'+ item.code)}</span>
                                    </div>

                                )
                            }
                            return <React.Fragment key={index}></React.Fragment>
                        })}
                        <span className="pointer more-information" onClick={() => setDisplayTab(false)}>
                            {t('app:athlete.training_zone.btn_i_understood')}
                        </span>
                    </div>
                </>
                :
                <></>
            }
        </>
    )
}

export default ActivitySessionIntensityTab