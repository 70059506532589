import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Container, Row, Col} from 'react-bootstrap';

import {secondesToMinutes, secondsToMinPoints} from "../../../Functions/TextMaths";
import {
    duration_icon, distance_icon,
    power_icon, allure_icon, cadence_cap_icon, cadence_velo_icon, heart_icon,
    rpe0, rpe12, rpe34, rpe56, rpe78, rpe910, relief
} from "../../../img/img";

import {formatPacingData, formatCadenceData, convertMetersToYards, convertKilometersToMiles} from "./Utils";
import {Metrics, MetricValues} from "../../../Functions/Metrics";
import {MetricContext, NavigationContext} from "../../../Contexts";
import {getCIPacingUnit, TrainingConceptCodes} from "../../TrainingConcept/Utils";
import Loader from "react-loader-spinner";

const WorkoutExercise = ({exo, discipline, disciplineIcon, iconBgColor, trainingConceptCode}) => {

    const {t} = useTranslation(['app'])

    const {isMobile} = useContext(NavigationContext)

    // // métrique utilisateur
    const {metric, runningCriticalIntensityStatistic} = useContext(MetricContext)
    const metrics = Metrics(metric)

    const distanceUnit = ['m','km']
    const durationUnit = ['sec', 'min']
    const unitCode = exo.unit.code

    let exoValue = exo.value
    let exoUnit = unitCode
    let exoUnitType, exoValueIcon

    const isHeartrateTrainingConcept = trainingConceptCode == TrainingConceptCodes.CRITICAL_HEARTRATE ? true : false

    if(distanceUnit.indexOf(unitCode) !== -1) {
        exoUnitType = 'distance'
        exoValueIcon = distance_icon
        if(discipline == 'nat') {
            exoUnit = metrics.distance_nat
            exoValue = exoUnit != unitCode ? (exoValue*1000) : exoValue
            exoValue = metric === MetricValues.imperial ? Math.floor(convertMetersToYards(exoValue)) : exoValue
        }
        else {
            exoUnit = metrics.distance_cap_velo
            exoValue = exoUnit != unitCode ? (exoValue/1000) : exoValue
            exoValue = metric === MetricValues.imperial ? convertKilometersToMiles(exoValue).toFixed(1) : exoValue
        }
    }
    else if(durationUnit.indexOf(unitCode) !== -1) {
        exoUnitType = 'duration'
        exoValueIcon = duration_icon
        if(unitCode == 'sec' && exoValue >= 60) {
            exoValue = secondsToMinPoints(exoValue)
            exoUnit = 'min'
        }
        else if(unitCode == 'min') {
            exoValue = exoValue >= 60 ? exoValue : secondsToMinPoints(exoValue*60)
        }
    }

    let pacingValues, pacingUnit, pacingIcon
    let cadenceUnit, showCadence, showHr, cadenceValues, cadenceIcon
    switch(discipline){
        case 'nat':
            showCadence = true
            showHr = false
            pacingIcon = allure_icon
            pacingUnit = metrics.allure_nat
            cadenceUnit = metrics.cadence_velo
            cadenceIcon = cadence_velo_icon
            break
        case 'velo':
            showCadence = true
            showHr = false
            pacingIcon = isHeartrateTrainingConcept ? heart_icon : power_icon
            pacingUnit = isHeartrateTrainingConcept ? metrics.heartrate : metrics.power_velo
            cadenceUnit = metrics.cadence_velo
            cadenceIcon = cadence_velo_icon
            break
        case 'cap':
        default:
            pacingIcon = isHeartrateTrainingConcept ? heart_icon : allure_icon
            pacingUnit = isHeartrateTrainingConcept ? metrics.heartrate : getCIPacingUnit('cap', metric, runningCriticalIntensityStatistic)
            cadenceUnit = metrics.cadence_cap
            cadenceIcon = cadence_cap_icon
            showCadence = true
            showHr = false
            // if (metric === 2) {
            //     // cadenceValue = exo.hr_pacing_data ? exo.hr_pacing_data.max_value1 : '-'
            // } else {
            //     // cadenceValue = exo.hr_pacing_data ? exo.hr_pacing_data.max_value1 : '-'
            // }

            break
    }
    cadenceValues = formatCadenceData(exo.cadence_min, exo.cadence_max)

    const suffixes = {
        flat: t('app:app.elevation.flat').toLowerCase(),
        ascent: t('app:app.elevation.ascent').toLowerCase()
    }

    let pacingData = isHeartrateTrainingConcept ? exo.hr_pacing_data : exo.pacing_data
    pacingData.runningCriticalIntensityStatistic = runningCriticalIntensityStatistic
    pacingValues = formatPacingData(pacingData, suffixes, false, metric)

    let rpeIcon
    switch (exo.rpe_min) {
        case 1:
            rpeIcon = rpe12
            break
        case 3:
            rpeIcon = rpe34
            break
        case 5:
            rpeIcon = rpe56
            break
        case 7:
            rpeIcon = rpe78
            break
        default:
            rpeIcon = exo.rpe === null ? rpe0 : rpe910
            break;
    }

    function showPacingValues(string) {
        return {__html: (string ? string : '-')};
    }

    let zoneName = exo.swim_type !== null ? exo.swim_type.name : (exo.recup ? t('app:workout.exercise.recovery') : null)
    if(null === zoneName && exo.zone !== null) {
        zoneName = metric === MetricValues.international ? exo.zone.name : (exo.hr_zone !== null ? exo.hr_zone.name : null)
    }

    return (
        <>
            <div id="scrolling-container-relief" key={exo.id}
                 className="scrolling-wrapper-flexbox min-w-100">
                    <div id="wrapper"
                         className="d-flex justify-content-center">
                        {/*{getDayColumns(typeDisplay)}*/}

                                <div className={`col-exercise ${isMobile ? "width-50 mx-1" : "width-80"} px-0`}>
                                        <div className={"training-event-cube justify-content-center px-0 d-flex  " + iconBgColor}>
                                            <img
                                                className="icon-24"
                                                src={disciplineIcon}
                                                alt={discipline.code + " unset icon"}
                                            />
                                        </div>
                                        <div className="mt-3">
                                            <figcaption className="nage">
                                                {zoneName}
                                            </figcaption>
                                        </div>
                                </div>
                                <div className={`col-exercise ${isMobile ? "width-50 mx-1" : "width-80"} px-0`}>
                                    <figure>
                                        <img className="icon-24" src={exoValueIcon} alt={exoUnitType}/>
                                        <figcaption className="mt-2">
                                            <div className="exoDesc">{exoValue ? exoValue : '-'}</div>{"\n"}
                                            {exoUnit}
                                        </figcaption>
                                    </figure>
                                </div>
                                <div className={`col-exercise ${isMobile ? "width-100 mx-1" : "width-120"} px-0`}>
                                    <figure>
                                        <img className="icon-24" src={pacingIcon} alt="pacingValue"/>
                                        <figcaption className="mt-2">
                                            <div className="exoDesc2" dangerouslySetInnerHTML={showPacingValues(pacingValues)} />{"\n"}
                                            {pacingUnit}
                                        </figcaption>
                                    </figure>
                                </div>
                                {showCadence &&
                                <div className={`col-exercise ${isMobile ? "width-50 mx-1" : "width-80"} px-0`}>
                                    <figure>
                                        <img className="icon-24" src={cadenceIcon} alt="cadence"/>
                                        <figcaption className="mt-2">
                                            <div className="exoDesc">{cadenceValues ? cadenceValues : '-'}{"\n"}</div>
                                            {cadenceUnit}
                                        </figcaption>
                                    </figure>
                                </div>
                                }
                                {showHr &&
                                <div className={`col-exercise ${isMobile ? "width-50 mx-1" : "width-80"} px-0`}>
                                    <figure>
                                        <img className="icon-24" src={heart_icon} alt="heart"/>
                                        <figcaption className="mt-2">
                                            <div className="exoDesc">{exo.hr_min}{"\n"}</div>
                                            bpm
                                        </figcaption>
                                    </figure>
                                </div>
                                }
                                <div className={`col-exercise ${isMobile ? "width-50 mx-1" : "width-80"} px-0`}>
                                    <figure>
                                        <img className="icon-24" src={rpeIcon} alt="rpe"/>
                                        <figcaption className="mt-2">
                                            <div className="exoDesc">{exo.rpe_min} - {exo.rpe_max} {"\n"}</div>
                                            RPE
                                        </figcaption>
                                    </figure>
                                </div>
                                {(exo.trail_type !== null) &&
                                <div className={`col-exercise width-120 mx-1 px-0`}>
                                    <figure>
                                        <img className="icon-24" src={relief} alt="relief"/>
                                        <figcaption className="mt-2">
                                            {exo.trail_type.name}
                                        </figcaption>
                                    </figure>
                                </div>
                                }
                            </div>
            </div>
            {(exo.observations !== null) ?
                <div className="d-flex align-items-center text-seance mx-3 ">
                    {exo.observations}
                </div>
                :
                <></>
            }
        </>
    )
}

export default WorkoutExercise;