export const WEIGHTTYPES = {
    DAILY: "daily",
    WEEKLY: "weekly",
    MONTHLY: "monthly"
}

export const WEIGHTPERIODS = {
    "BEGINNING_YEAR": 1,
    "ENTIRE_YEAR": 2,
    "LAST_6_MONTHS": 3,
    "LAST_3_MONTHS": 4,
    "CUSTOM_PERIOD": 5
}