import React, {useContext, useState, useEffect} from 'react';
import {Redirect} from "react-router-dom";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import '../Css/Home.css';
import {HeaderHome} from "../Components/Home/HeaderHome";
import EditProfile from "../Components/Settings/EditProfile";

import {LoggedInContext, NavigationContext} from "../Contexts";
import Loader from "react-loader-spinner";


function FinalizeRegistration (props) {

    const {getUserLoggedData} = props
    let history = useHistory()

    const {t} = useTranslation(['app'])

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const {has_finalized_registration, maintenance} = useContext(LoggedInContext)
    const [loaded,setLoaded] = useState(true)

    const referer = props.location.state ? props.location.state.referer.pathname : '/dashboard';
    const onSuccess = () => {
        getUserLoggedData()
        setLoaded(false)
    }


    useEffect(() => {
        if(maintenance === true) {
            history.push('/public/come_back_soon')
        }
    },[maintenance])

    if(has_finalized_registration) {
        return (
            <Redirect to={referer} />
        )
    }

    return (
        <>
            {loaded  ?
                <div className={isMobile ? "padding-top-mobile" : "d-flex justify-content-end background-cap"}>
                    <div
                        className={isMobile ? "pc-4 min-vh-100 d-flex flex-column justify-content-center align-items-center container" : "w-30 pc-4 min-vh-100 d-flex flex-column justify-content-center align-items-center container-desktop"}>
                        <HeaderHome withoutLanguageSwitch={1}/>
                        <p id="text-middle-login"
                           className={isMobile ? "text-middle-login my-auto pcb-5" : "text-middle-login mcy-5"}>{t('app:finalize_registration.message')}</p>
                        <p className="label-home mcb-5">{t('app:finalize_registration.desc')}</p>
                        <EditProfile page={'registration'} onSuccess={() => onSuccess()}/>
                    </div>
                </div>
                :
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={50}
                    width={50}
                    className="my-5"
                />
            }
            </>
    )
}

export default FinalizeRegistration