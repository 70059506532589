import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Container, Form} from "react-bootstrap";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";
import {arrow_left, validate, validate2} from "../../../img/img";
import {MetricContext, WeekCalendarContext} from "../../../Contexts";
import TypeDebriefing from "./TypeDebriefing";
import DistanceDurationDebriefing from "./DistanceDurationDebriefing";
import RpeDebriefing from "./RpeDebriefing";
import ConditionsDebriefing from "./ConditionsDebriefing";
import '../../../Css/Debriefing.css';
import CommentDebriefing from "./CommentDebriefing";
import {convertMilesToKilometers} from "../Workout/Utils";
import {MetricValues} from "../../../Functions/Metrics";
import DescriptionDebriefing from "./DescriptionDebriefing";
import InfoDebriefing from "./InfoDebriefing";
import ReviewScoreDebriefing from "./ReviewScoreDebriefing";
import ReviewDebriefing from "./ReviewDebriefing";


/**
 * Afficher un formulaire de création de Compte-rendu
 *
 * @param workoutId
 */
const AddDebriefing = ({workoutId}) => {
    const {t} = useTranslation(['app'])

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)

    const {updateTrainingEventOpen, updateCalendarEventToRefresh} = useContext(WeekCalendarContext)

    const [workout, setWorkout] = useState(null)
    const [type, setType] = useState(3)
    const [hour, setHour] = useState(0)
    const [min, setMin] = useState(0)
    const [rpe, setRpe] = useState(0)
    const [distance, setDistance] = useState(0)
    const [review, setReview] = useState({
        score: 0,
        title: null,
        description: null
    })
    const [discipline, setDiscipline] = useState(null)
    const [comment, setComment] = useState('')
    const [description, setDescription] = useState('')
    const [conditions, setConditions] = useState({
        sickness: false,
        hurt: false,
        fever: false
    })
    const [hurtDetails, setHurtDetails] = useState('')
    const [disciplinesStyles, setDisciplinesStyles] = useState({
        rpeLabel: "rpe-label-other",
        speechBubble: "speech-bubble-other",
        stateBar: "state-bar-other",
        rpeBtn: "rpe-btn-other",
        color: 'blue'
    })
    const [requiredFields, setRequiredFields] = useState({
        duration: false,
        distance: false,
        rpe: false,
    })
    const [ready, setReady] = useState(false)

    useEffect(() => {
        if (requiredFields.duration && requiredFields.distance  && requiredFields.rpe) {
            setReady(true)
        }
    }, [requiredFields])

    useEffect(() => {
        getWorkout()
    }, [])

    const [errorMessage, setErrorMessage] = useState()

    useEffect(() => {
        if (discipline) {
            setDisciplinesStyles((oldStyles) => {
                let newStyles = {...oldStyles}
                switch (discipline.id) {
                    case 5:
                        newStyles.rpeLabel = "rpe-label-nat"
                        newStyles.speechBubble = "speech-bubble-nat"
                        newStyles.stateBar = "state-bar-nat"
                        newStyles.rpeBtn = "rpe-btn-nat"

                        newStyles.color = 'blue'
                        break
                    case 6:
                        newStyles.rpeLabel = "rpe-label-velo"
                        newStyles.speechBubble = "speech-bubble-velo"
                        newStyles.stateBar = "state-bar-velo"
                        newStyles.rpeBtn = "rpe-btn-velo"

                        newStyles.color = 'green'
                        break
                    case 7:
                        newStyles.rpeLabel = "rpe-label-cap"
                        newStyles.speechBubble = "speech-bubble-cap"
                        newStyles.stateBar = "state-bar-cap"
                        newStyles.rpeBtn = "rpe-btn-cap"

                        newStyles.color = 'orange'
                        break
                    default:
                        newStyles.rpeLabel = "rpe-label-other"
                        newStyles.speechBubble = "speech-bubble-other"
                        newStyles.stateBar = "state-bar-other"
                        newStyles.rpeBtn = "rpe-btn-other"

                        newStyles.color = 'blue'
                        break
                }
                return newStyles
            })
        }
    }, [discipline])

    const onChangeDistance = (value) => {
        setDistance(value)
        if (!requiredFields.distance) {
            setRequiredFields((oldRequiredFields) => {
                let newRequiredFields = {...oldRequiredFields}
                newRequiredFields.distance = true
                return newRequiredFields
            })
        }
    }

    const onChangeDuration = (durationType, value) => {
        if (durationType === 'hour') {
            setHour(value.id)
        } else {
            setMin(value.id)
        }

        if (!requiredFields.duration) {
            setRequiredFields((oldRequiredFields) => {
                let newRequiredFields = {...oldRequiredFields}
                newRequiredFields.duration = true
                return newRequiredFields
            })
        }
    }

    const onChangeRpe = (value) => {
        setRpe(value)
        if (!requiredFields.rpe) {
            setRequiredFields((oldRequiredFields) => {
                let newRequiredFields = {...oldRequiredFields}
                newRequiredFields.rpe = true
                return newRequiredFields
            })
        }
    }

    const onChangeComment = (value) => {
        setComment(value)
    }
    const onChangeDescription = (value) => {
        setDescription(value)
    }

    const onChangeType = (value) => {
        setType(value)
    }

    const onChangeReviewScore = (value) => {
        setReview((oldReview) => {
            let newReview = {...oldReview}
            newReview.score = value
            return newReview
        })
    }

    const onChangeReviewTitle = (value) => {
        setReview((oldReview) => {
            let newReview = {...oldReview}
            newReview.title = value
            return newReview
        })
    }

    const onChangeReviewDescription = (value) => {
        setReview((oldReview) => {
            let newReview = {...oldReview}
            newReview.description = value
            return newReview
        })
    }


    // Récupération du workout via l'API
    const getWorkout = () => {
        let headers = generateHeaders()
        if (headers) {
            axios
                .get(
                    config+"api/workouts/"+workoutId,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    let disciplines = response.data.disciplines
                    if(disciplines && disciplines.length === 1) {
                        let disciplineObj = {
                            id: disciplines[0].id,
                            label: disciplines[0].name,
                            code: disciplines[0].code,
                        }
                        setDiscipline(disciplineObj)
                    }

                    setWorkout(response.data)
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    const postDebriefing = () => {
        const headers = generateHeaders()
        let datas = {}

        let durationMin = +hour*60 + +min

        let disciplinesIds = []
        for (let i=0; i<workout.disciplines.length; i++) {
            disciplinesIds.push(workout.disciplines[i].id)
        }


        datas.rpe = rpe/10
        datas.sickness = conditions.sickness
        datas.fever = conditions.fever
        datas.hurt = conditions.hurt
        datas.hurt_details = conditions.hurt && hurtDetails !== '' ? hurtDetails : null
        datas.description = description
        datas.date = workout.planned_date
        datas.discipline_ids = disciplinesIds
        datas.duration = durationMin
        datas.distance = +distance
        datas.type = type
        datas.workout_id = workout.id
        datas.review_score = review.score !== 0 ? review.score : null
        datas.review_title = review.title
        datas.review_description = review.description

        if(metric === MetricValues.imperial) {
            datas.distance = convertMilesToKilometers(datas.distance)
        }

        axios
            .post(
                config+"api/debriefings/new",
                datas,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then((response) => {
                updateTrainingEventOpen(false)

                // Déclencher le rechargement le calendrier
                updateCalendarEventToRefresh("debriefing-" + response.data.id)

                if (comment !== '') {
                    postComment(response.data.id)
                }
            })
            .catch(error => {
                handleEndpointError(error)
                if(error.response !== undefined) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.data.errors)
                    }
                }
            })
    }

    // Enregister compte-rendu
    const postComment = (debriefId) => {
        const headers = generateHeaders()
        let datas = {}

        datas.content = comment

        axios
            .post(
                config+"api/debriefings/"+debriefId+"/comments/new",
                datas,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then((response) => {
            })
            .catch(error => {
                handleEndpointError(error)
                if(error.response !== undefined) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.data.errors)
                    }
                }
            })
    }

    return (
        <div id="rpe-card" className="rpe-card pcx-3 pcy-3">
            <InfoDebriefing />
            <div className="activity-CR mcb-4">
                <div className="row">
                    <div className="col-12">
                        <p className={"rpe-title text-left mcb-3"}>{t('app.layout.activity')}</p>
                    </div>

                    {/* Champs requis */}
                    <TypeDebriefing type={type}
                                    onChangeType={(value) => onChangeType(value)}
                                    color={disciplinesStyles.color}
                    />

                    {/* Champs requis */}
                    <DistanceDurationDebriefing onChangeDuration={(durationType, value) => onChangeDuration(durationType, value)}
                                                onChangeDistance={(value) => onChangeDistance(value)}
                                                distance={distance}
                                                hour={hour}
                                                min={min}
                                                discipline={workout && workout.disciplines[0].id}
                    />

                    {/* Champs requis */}
                    <RpeDebriefing disciplinesStyles={disciplinesStyles}
                                   setRpe={(value) => onChangeRpe(value)}
                                   rpe={rpe}
                                   cardId={'seance-card'}
                    />

                    <ConditionsDebriefing setConditions={setConditions}
                                          conditions={conditions}
                                          color={disciplinesStyles.color}
                                          hurtDetails={hurtDetails}
                                          setHurtDetails={setHurtDetails}
                                          
                    />

                    <CommentDebriefing comment={comment}
                                       onChangeComment={(value) => onChangeComment(value)}
                                       haveComment={false}
                    />
                    <DescriptionDebriefing description={description}
                                           onChangeDescription={(value) => onChangeDescription(value)}
                                           color={disciplinesStyles.color}
                    />
                    <>
                        <ReviewScoreDebriefing reviewScore={review.score} changeReviewScore={(value) => onChangeReviewScore(value)}/>
                        <ReviewDebriefing
                            reviewTitle={review.title}
                            changeReviewTitle={(value) => onChangeReviewTitle(value)}
                            reviewDescription={review.description}
                            changeReviewDescription={(value) => onChangeReviewDescription(value)}
                        />
                    </>

                    <div className="w-100 mct-3 d-flex justify-content-center">
                        {errorMessage &&
                        <div className="error-login-text mcb-3">
                            {errorMessage}
                        </div>
                        }
                        <Button className="background-black py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text"
                                onClick={() => postDebriefing()}
                                disabled={!ready}
                        >
                            <img className="mr-2 icon-24" alt="valid icon" src={validate} />{t('app:athlete.saving.save')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default AddDebriefing;