import React, {useEffect, useState, useContext} from 'react';
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../Components/Tools/CustomHooks";
import Loader from 'react-loader-spinner'
import '../Css/Settings.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import axios from "axios";
import config from "../api";
import generateHeaders from "../Functions/generateHeaders";
import handleEndpointError from '../Functions/Alert';
import {NavigationContext} from "../Contexts";

function BankCard ({color, updatePaymentMethod, clearBankCard, updateReadyToPurchase}) {

    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()

    const {isMobile} = useContext(NavigationContext)

    const [card, setCard] = useState(null)

    useEffect( () => {
        fetchAccountPayment()
    }, []);

    useEffect(() => {
        if(card !== null) {
            updatePaymentMethod(card)
            updateReadyToPurchase()
        }
    }, [card])

    const fetchAccountPayment = async () => {
        await axios.get(
            config+"api/my_profile/customer_account",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((res) => {
            if(!isMounted.current){
                return 0;
            }
            let dataCustomerAccount = res.data.stripe_customer
            setCard(dataCustomerAccount && dataCustomerAccount.card ? dataCustomerAccount.card : false)
        }).catch(error => {
            handleEndpointError(error)
        })
    }

    return(
        <>
            {card ?
                <>
                    <div className="card-info card-checkout w-100 pcy-1">
                        <div className="d-flex justify-content-between  my-3 pcx-3 fs-13"><span className="align-self-center">{t('app:settings.subscription.type')}</span><span
                            className="align-self-center text-blue subscription-item-text text-uppercase fs-10">{card.brand}</span>
                        </div>
                        <div className="d-flex justify-content-between my-3 pcx-3 fs-13"><span className="align-self-center">{t('app:settings.subscription.card_number')}</span><span
                            className={"align-self-center text-blue subscription-item-text "+ (isMobile ? "fs-8":"fs-10")}>XXXX XXXX XXXX {card.last4}</span>
                        </div>
                        <div className="d-flex justify-content-between  my-3 pcx-3 fs-13"><span className="align-self-center">{t('app:settings.subscription.card_expiration')}</span><span
                            className="align-self-center text-blue subscription-item-text fs-10">{card.expire}</span>
                        </div>
                    </div>
                    <span className="subscription-clear-payment-method"
                          onClick={() => clearBankCard()}
                    >
                        {t('app:settings.subscription.change_payment_method')}
                    </span>
                </>
                :
                <Loader
                    type="TailSpin"
                    color={color === 'orange' ? '#FF7A00' : '#007DFF'}
                    height={50}
                    width={50}
                    className="pct-3 pcr-3"
                />
            }
        </>
    )
}
export default BankCard