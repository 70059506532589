export const getMessageWellnessType = (wellnessType, score, t) => {
    let message
    switch (wellnessType) {
        case 'mood':
            switch (score) {
                case 0:
                    message = t('app:athlete.wellness.mood.upset')
                    break
                case 25:
                    message = t('app:athlete.wellness.mood.gloomy')
                    break
                case 50:
                    message = t('app:athlete.wellness.mood.normal')
                    break
                case 75:
                    message = t('app:athlete.wellness.mood.good')
                    break
                case 100:
                    message = t('app:athlete.wellness.mood.excellent')
                    break
                default:
                    break
            }
            break
        case 'sleep_quality':
        case 'sleep':
            switch (score) {
                case 0:
                    message = t('app:athlete.wellness.sleep.bad')
                    break
                case 25:
                    message = t('app:athlete.wellness.sleep.not_restorative')
                    break
                case 50:
                    message = t('app:athlete.wellness.sleep.normal')
                    break
                case 75:
                    message = t('app:athlete.wellness.sleep.well')
                    break
                case 100:
                    message = t('app:athlete.wellness.sleep.restorative')
                    break
                default:
                    break
            }
            break
        case 'muscular_soreness':
            switch (score) {
                case 0:
                    message = t('app:athlete.wellness.muscle.painfull')
                    break
                case 25:
                    message = t('app:athlete.wellness.muscle.sore')
                    break
                case 50:
                    message = t('app:athlete.wellness.muscle.normal')
                    break
                case 75:
                    message = t('app:athlete.wellness.muscle.good')
                    break
                case 100:
                    message = t('app:athlete.wellness.muscle.excellent')
                    break
                default:
                    break
            }
            break
        case 'stress':
            switch (score) {
                case 0:
                    message = t('app:athlete.wellness.stress.very')
                    break
                case 25:
                    message = t('app:athlete.wellness.stress.bit')
                    break
                case 50:
                    message = t('app:athlete.wellness.stress.none')
                    break
                case 75:
                    message = t('app:athlete.wellness.stress.rather_relax')
                    break
                case 100:
                    message = t('app:athlete.wellness.stress.relax')
                    break
                default:
                    break
            }
            break
        case 'fatigue':
            switch (score) {
                case 0:
                    message = t('app:athlete.wellness.fatigue.very_tired')
                    break
                case 25:
                    message = t('app:athlete.wellness.fatigue.tired')
                    break
                case 50:
                    message = t('app:athlete.wellness.fatigue.none')
                    break
                case 75:
                    message = t('app:athlete.wellness.fatigue.good')
                    break
                case 100:
                    message = t('app:athlete.wellness.fatigue.great')
                    break
                default:
                    break
            }
            break
    }
    return message
}