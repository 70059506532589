import React, {useState, useEffect} from 'react';
import CustomSelect from "../Tools/CustomSelect"

const SelectSport = ({sports, defaultSport, onChange}) => {

    const [value, setValue] = useState(defaultSport)
    const [defaultValue, setDefaultValue] = useState(defaultSport)

    // Sélectionner la valeur par défaut lors de la mise à jour de la liste
    useEffect(() => {
        if(sports && sports.length > 0) {
            let inSports = false
            if(defaultValue) {
                for(let c of sports) {
                    if(c.id === defaultValue.id) {
                        inSports = true
                        break
                    }
                }
            }

            if(!inSports) {
                let sportValue = sports[0]
                setDefaultValue(sportValue)
                onChangeValue(sportValue)
            }
        }
    }, [sports])

    const onChangeValue = (value) => {
        setValue(value)
        onChange(value)
    }

    if(sports === null || sports.length === 0 || !defaultValue)
        return (<>Aucun sport</>)

    return (
        <CustomSelect
            className="w-100 text-left"
            name="sport"
            options={sports}
            defaultValue={defaultValue}
            value={value}
            onChange={(value) => onChangeValue(value)}
        />
    )
}

export default SelectSport;