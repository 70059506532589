import React from 'react';
import {useTranslation} from "react-i18next";
import {edit_white, pulse_square} from "../../../img/img";
import {Button} from "react-bootstrap";

const NotCompleted = ({updateActiveKey}) => {
    const {t} = useTranslation(["app"])
    return (
        <div className="rpe-card pcx-3 pcy-3 d-flex flex-column justify-content-center">
            <div className="w-100 d-flex justify-content-center mcb-5">
                <img className="icon-40" src={pulse_square} alt="pulse square icon"/>
            </div>
            <span className="debrief-not-completed-text-bold">{t('app:no_stat_available')}</span>
            <span className="debrief-not-completed-text mcy-5">
                {t('app:fill_debrief.1')}<br/>
                {t('app:fill_debrief.2')} <b>{t('app:fill_debrief.3')}</b> {t('app:fill_debrief.4')}
            </span>
            <Button
                className='mcy-3 mcb-3 mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center'
                onClick={() => updateActiveKey('debriefing')}
            >
                <img className="mcr-2 icon-24" alt="edit icon" src={edit_white}/>
                <p className="my-auto home-btn-p-text">{t('app.comments.debriefing')}</p>
            </Button>
        </div>
    );
};

export default NotCompleted;
