import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {HeaderHome} from "../../src/Components/Home/HeaderHome";
import {AuthContext, LanguageContext, NavigationContext} from "../Contexts";
import '../Css/subscription.css';
import Checkout from "./Checkout";
import Cart from "./Cart";
import {updateLanguageFromSearch} from "../Functions/Utils";

const SubscriptionAutonom = (props) => {

    // Traduction
    const {t} = useTranslation(['app']);
    const {isMobile} = useContext(NavigationContext)
    const {token} = useContext(AuthContext)
    const {language, updateLanguage} = useContext(LanguageContext)

    const [totalAmount, setTotalAmount] = useState("10€/mois")


    useEffect(() =>  {
        // Mettre à jour la langue en prenant en compte l'url lorsque l'utilisateur n'est pas connecté
        if(token === null) {
            updateLanguageFromSearch(updateLanguage, props.location.search)
        }
    }, [token])

    const updateTotalAmount = (amount) => {
        setTotalAmount(amount)
    }

    const product = {
        'title': 'Abonnement AUTONOM',
        'code': 'autonom',
        'name' : 'AUTONOM',
        'price': 6,
        'currency': '€',
        'recurring': 'month',
        'options': [
            {
                'name': 'Banque de séance',
                'code': 'workout_bank',
                'price': 4,
                'recurring': 'month',
                'cart_line' : 'Ajouter la banque de séances',
                'description' : 'Tu as accès à une bibliothèque de séances, afin de gérer tes entraînements. Elabore ta propre stratégie d’entraînement. Définis tes objectifs de manière autonome.'
            }
        ]
    }
    const defaultOptions = [
        // 'workout_bank'
    ]

    return (
        <div className={isMobile ? "" : "d-flex justify-content-end background-autonom"}>
            <div className={"pc-4 mcb-5 d-flex flex-column justify-content-center align-items-center "+ (isMobile ? "container" : " w-40 container-desktop")}>
                <HeaderHome />
                <Cart
                    product={product}
                    defaultOptions={defaultOptions}
                    color="blue"
                    onUpdate={(amount) => updateTotalAmount(amount)}
                />
                {/*<Checkout*/}
                    {/*type="subscription"*/}
                    {/*color="blue"*/}
                    {/*totalAmount={totalAmount}*/}
                {/*/>*/}
            </div>
        </div>
    )
}
export default SubscriptionAutonom