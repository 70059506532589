import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactTooltip from "react-tooltip";
import InputField from "../Forms/InputField";
import DatePicker from "react-datepicker";
import CustomSelect from "../Tools/CustomSelect";
import {NavigationContext, DashboardContext, LanguageContext} from "../../Contexts";
import ChangePassword from "./ChangePassword";
import iso3311a2 from "iso-3166-1-alpha-2";
import {scroller, Element as ScrollerElement } from "react-scroll";
import {animations} from "react-animation";

const PersonalInformations = ({userProfile,onChangeUserProfile,setHideSaveButton}) => {
    const {t} = useTranslation(['app'])
    const {isMobile} = useContext(NavigationContext)
    const {settingsAction,updateSettingsAction} = useContext(DashboardContext)
    const {language} = useContext(LanguageContext)


    const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(true)
    const [editPassword, setEditPassword] = useState(false)
    const [name, setName] = useState(null)
    const [username, setUsername] = useState(null)
    const [firstName, setFirstName] = useState(null)
    const [birthdate, setBirthdate] = useState(null)
    const [city, setCity] = useState(null)
    const [country, setCountry] = useState(null)
    const [countries, setCountries] = useState(null)
    const [phone, setPhone] = useState(null)
    const [currentLanguage, setCurrentLanguage] = useState(null)

    const languagesList = [
        {
            id:1,
            label:"English"
        },
        {
            id:2,
            label:"Français"
        },
        {
            id:3,
            label:"Español"
        },
        {
            id:4,
            label:"Deutsch"
        }
    ]


    useEffect(() => {
        generateCountries()
    }, [])

    //Initialisation des states avec les données de l'utilisateur
    useEffect(() => {
        if(userProfile) {
            setName(userProfile.name)
            setFirstName(userProfile.firstname)
            setUsername(userProfile.username)
            setBirthdate(userProfile.birthdate)
            setCity(userProfile.city)
            setPhone(userProfile.phone)
        }
    }, [userProfile])

    useEffect(() => {
        if (countries !== null && userProfile) {
            let countryCode = userProfile.country
            let countryOption = null
            for (let c of countries) {
                if (c.id === countryCode) {
                    countryOption = c
                    break
                }
            }
            setCountry(countryOption)
        }
    }, [countries, userProfile])

    useEffect(() => {
        if(userProfile) {
            let label = {id: userProfile.language, label: userProfile.languageLabel}
            setCurrentLanguage(label)
        }
    }, [])

    useEffect(() => {
        switch(settingsAction) {
            case 'edit_password':
                // Scroll down
                scroller.scrollTo('changePasswordElement', {
                    duration: 500,
                    delay: 500,
                    smooth: 'easeOutQuart'
                })
                // Bounce
                setEditPassword(true)
                const timer = setTimeout(() => updateSettingsAction(null), 2500);
                return () => clearTimeout(timer);
                break;
        }
    }, [settingsAction])

    const handleEditPassword = (value) => {
        // Afficher/Cacher le formulaire de modification de mot de passe
        setEditPassword(value)
        setHideSaveButton(!value)
    }

    const generateCountries = () => {
        const countries = iso3311a2.getData()
        let countriesValues = []
        Object.entries(countries).map(([id, nom]) => {
            countriesValues.push({
                id: id,
                label: nom
            })
        })
        setCountries(countriesValues)
    }

    const onChangeCountry = (value) => {
        onChangeUserProfile('country', value.id)
    }

    const onChangeLanguage = (value) => {
        onChangeUserProfile('language', value.id)
        setCurrentLanguage(value)
    }

    const onChangeUsername = (value) => {
        onChangeUserProfile('username', value)
    }

    const onChangeName = (value) => {
        onChangeUserProfile('name', value)
    }
    const onChangeFirstName = (value) => {
        onChangeUserProfile('firstname', value)
    }
    const onChangeBirthdate = (value) => {
        onChangeUserProfile('birthdate', value)
    }
    const onChangeCity = (value) => {
        onChangeUserProfile('city', value)
    }
    const onChangePhone = (value) => {
        onChangeUserProfile('phone', value)
    }

    return (
        <div className={"zs-tab-card pcy-3 mcb-3 pcx-3"}>
            <div className={isMobile ?"monitoring-title2 fs-14":"monitoring-title2"}
                 data-tip={""}
                 data-for="trainingZoneHelper"
            >
                {t('app:settings.profil.personal_info')}
            </div>
            <ReactTooltip key="tooltip-training-zone"
                          id="trainingZoneHelper"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip"
                          effect="solid"
                          place="bottom"
            />
            <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                  onClick={() => setIsPersonalInfoOpen(isPersonalInfoOpen => !isPersonalInfoOpen)}
            >
                <i className={isPersonalInfoOpen ? "bi bi-chevron-up mcl-2" : "bi bi-chevron-down mcl-2"}></i>
            </span>
            {isPersonalInfoOpen ?
                <>
                    <div className="grey-line-2 mcy-3 pcx-3"></div>
                    <div>
                        <InputField isRequired={true} name={"firstname"} type={"text"} inputValue={name}
                                    setValue={(value) => onChangeName(value)}
                                    placeholder={t('app:register.firstname') + ' *'}/>

                        <InputField isRequired={true} name={"name"} type={"text"} inputValue={firstName}
                                    setValue={(value) => onChangeFirstName(value)} placeholder={t('app:register.name') + ' *'}/>

                        <InputField isRequired={true} name={"email"} type={"email"} inputValue={username}
                                    setValue={(value) => onChangeUsername(value)} placeholder={'Email *'}
                                    setEmailOk={() => {}}/>
                    </div>
                    <ScrollerElement name="changePasswordElement">
                    {editPassword ?
                            <ChangePassword handleEditPassword={(value) => handleEditPassword(value)} />
                        :
                        <div className="">
                            <div className="w-100 d-flex flex-column justify-content-center pcx-3 h-56 form-login-input grey-border radius-8">
                                <span className="password-text text-left pcb-3">{t('app:settings.profil.password')}</span>
                                <p className="text-left text-settings">**********</p>
                            </div>
                            <button onClick={() => handleEditPassword(true)} className="settings-btn d-flex justify-content-end mcb-2" >{t('app.profile.update_password.update_my_password')}</button>
                        </div>
                    }
                    </ScrollerElement>
                    <DatePicker
                        wrapperClassName={"w-100 h-56 "}
                        className={"custom-input form-login-input grey-border radius-8 w-100 mcb-5 h-56 pcl-3 " + (birthdate && " pct-3")}
                        dateFormat="P"
                        placeholderText={t('app:finalize_registration.birthdate') + ' *'}
                        selected={birthdate}
                        onChange={(value) => onChangeBirthdate(value)}
                        maxDate={new Date()}
                        showYearDropdown
                        showMonthDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={80}
                    />
                    {birthdate &&
                    <p className={"little-text-input-opti mcl-3 mct-2 text-left "}>{t('app:finalize_registration.birthdate') + ' *'}</p>}
                    <InputField isRequired={true} name={"city"} type={"text"} inputValue={city}
                                setValue={(value) => onChangeCity(value)}
                                placeholder={t('app:finalize_registration.city') + ' *'}/>

                    <div className={"w-100 mcb-4 "}>
                        <CustomSelect
                            className={"w-100 text-left edit-profil-country"}
                            name="country"
                            options={countries}
                            defaultValue={country}
                            value={country}
                            onChange={(value) => onChangeCountry(value)}
                        />
                        <p className="little-text-input-opti mb-0 mcl-3 mct-2 text-left">{t('app:finalize_registration.country') + ' *'}</p>
                    </div>

                    <InputField isRequired={true} name={"phone"} type={"tel"} inputValue={phone}
                                setValue={(value) => onChangePhone(value)}
                                placeholder={t('app:finalize_registration.phone') + ' *'}/>
                    <div className={"w-100 mcb-4 "}>
                        <CustomSelect
                            className={"w-100 text-left edit-profil-country"}
                            name="language"
                            options={languagesList}
                            defaultValue={currentLanguage}
                            value={currentLanguage}
                            onChange={(value) => onChangeLanguage(value)}
                        />
                    </div>
                </>
                :
                <></>
            }
        </div>
    )
}
export default PersonalInformations