import React, {useContext, useEffect, useState} from "react";
import CustomSelect from "../Tools/CustomSelect";
import {useTranslation} from "react-i18next";
import {MetricContext} from "../../Contexts";
import {Metrics, MetricValues} from "../../Functions/Metrics";

const HealthProfile = ({onChangeUserProfile,userProfile,errorMessage}) => {
    const {t} = useTranslation(['app'])

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    const [gender, setGender] = useState(null)
    const [height, setHeight] = useState(null)
    const [heights, setHeights] = useState(null)
    const [weight, setWeight] = useState( null)
    const [weightUk, setWeightUk] = useState(null)
    const [weights, setWeights] = useState(null)
    const [weightValue, setWeightValue] = useState( null)

    useEffect(() => {
        generateHeights(140, 220)
    }, [])

    useEffect(() => {
        metric === MetricValues.imperial ? generateWeights(80, 350) : generateWeights(35, 150)
    }, [metric])

    //Initialisation des states avec les données de l'utilisateur
    useEffect(() => {
        if(userProfile) {
            setGender(userProfile.gender)
            setWeight(userProfile.weight)
            setWeightUk(userProfile.weightUk)
        }
    }, [userProfile])

    useEffect(() => {
        if (heights !== null && userProfile) {
            let heightValue = userProfile.height
            let heightOption = null
            for (let h of heights) {
                if (h.id === heightValue) {
                    heightOption = h
                    break
                }
            }
            setHeight(heightOption)
        }
    }, [heights,userProfile])


    useEffect(() => {
        if (weights && weightUk && weight && metric) {

            let wValue = metric === MetricValues.imperial ? weightUk : weight
            let weightOption = null
            for (let w of weights) {
                if (w.id === parseInt(wValue)) {
                    weightOption = w
                    break
                }
            }
            setWeightValue(weightOption)
        }
    }, [weights,weight,weightUk, metric])


    const transform = (value) => {
        let val = value / 100
        val = val.toString()
        if (val.length === 3) {
            val = val + "0"
        }
        return val
    }

    const changeGender = (event) => {
        if (event.target.id === "checkMale") {
            onChangeUserProfile('gender', 1)
        } else {
            onChangeUserProfile('gender', 2)
        }
    }

    const generateHeights = (minHeight, maxHeight) => {
        let heights = []
        let heightValues = []

        for (let i = minHeight; i <= maxHeight; i++) {
            heights.push(i)
        }
        heights.map((item, i) => {
            heightValues.push({
                id: item,
                label: transform(item) + 'm'
            })
        })
        setHeights(heightValues)
    }
    const onChangeHeight = (value) => {
        onChangeUserProfile('height', value.id)
    }
    const generateWeights = (minWeight, maxWeight) => {
        let weights = []
        let weightValues = []
        for (let i = minWeight; i <= maxWeight; i++) {
            weights.push(i)
        }
        weights.map((item, i) => {
            weightValues.push({
                id: item,
                label: item + metrics.weight
            })
        })
        setWeights(weightValues)
    }
    const onChangeWeightValue = (value) => {
        setWeightValue(value)
        onChangeUserProfile('weightValue', value.id)
    }

    return (
                <>
                    <p className="label-home text-left mcb-4">{t('app:finalize_registration.sex') + " *"}</p>

                    <div className="row">
                        <div className="checkbox-container-login">
                            <input type="checkbox" id="checkMale" name="checkMale" onChange={(value) => changeGender(value)}
                                   checked={gender === 1}/>
                            <label className="mcl-5 label-home"
                                   htmlFor="checkMale">{t('app:finalize_registration.male')}</label>
                        </div>
                        <div className="checkbox-container-login">
                            <input type="checkbox" id="checkFemale" name="checkFemale" onChange={(value,event) => changeGender(value,event)}
                                   checked={gender === 2}/>
                            <label className="checkbox-sex label-home"
                                   htmlFor="checkFemale">{t('app:finalize_registration.female')}</label>
                        </div>
                    </div>

                    <div className="row mct-5 w-100">
                        <div className="col-6">
                            <p className="w-100 text-left label-home mcb-2">{t('app:finalize_registration.height')} :</p>
                            <CustomSelect
                                className={"w-100 text-left edit-profil-country "}
                                name="height"
                                options={heights}
                                defaultValue={height}
                                value={height}
                                onChange={(value) => onChangeHeight(value)}
                            />
                        </div>
                        <div className="col-6">
                            <p className="w-100 text-left label-home mcb-2">{t('app:finalize_registration.weight')} :</p>
                            <CustomSelect
                                className={"w-100 text-left edit-profil-country "}
                                name="weight"
                                options={weights}
                                defaultValue={weightValue}
                                value={weightValue}
                                onChange={(value) => onChangeWeightValue(value)}

                            />
                            {errorMessage ?
                                <div className="error-login-text mcb-3 mct-5">
                                    {errorMessage}
                                </div> : <></>
                            }
                        </div>
                    </div>
                </>
    )
}
export default  HealthProfile