import { createSlice } from '@reduxjs/toolkit'

const initialShareState = {
  shouldTakeScreenshots: false,
  shouldChangeLayer: false,
  mapScreenshot: null,
  intensity: null,
  graphScreenshot: null,
  stats: null,
  discipline: null,
  showButtonLoader: false,
};

export const shareSlice = createSlice({
  name: 'share',
  initialState: initialShareState,
  reducers: {
    shouldTakeScreenshots: (state, action) => {
      state.shouldTakeScreenshots = action.payload
    },
    setStats: (state, action) => {
      state.stats = action.payload
    },
    setDiscipline: (state, action) => {
      state.discipline = action.payload
    },
    showButtonLoader: (state, action) => {
      state.showButtonLoader = action.payload
    },
    shouldChangeLayer: (state, action) => {
      state.shouldChangeLayer = action.payload
    },
    setMapScreenshot: (state, action) => {
      state.mapScreenshot = action.payload
    },
    setIntensity: (state, action) => {
      state.intensity = action.payload
    },
    setGraphScreenshot: (state, action) => {
      state.graphScreenshot = action.payload
    },
    reset: (state) => {
      state.shouldTakeScreenshots = false
      state.mapScreenshot = null
      state.intensity = null
      state.graphScreenshot = null
      state.showButtonLoader = false
      state.shouldChangeLayer = false
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  shouldTakeScreenshots,
  setStats,
  setDiscipline,
  showButtonLoader,
  shouldChangeLayer,
  setMapScreenshot,
  setGraphScreenshot,
  setIntensity,
  reset,
} = shareSlice.actions

export default shareSlice.reducer