import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Container, Form} from "react-bootstrap";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";
import {arrow_left, validate, x_circle} from "../../../img/img";
import {MetricContext, NavigationContext, WeekCalendarContext} from "../../../Contexts";
import {getVerboseDateWithClock} from "../../../Functions/DatesCalendar";
import DisciplineDebriefing from "./DisciplineDebriefing";
import TypeDebriefing from "./TypeDebriefing";
import DistanceDurationDebriefing from "./DistanceDurationDebriefing";
import RpeDebriefing from "./RpeDebriefing";
import ConditionsDebriefing from "./ConditionsDebriefing";
import '../../../Css/Debriefing.css';
import CommentDebriefing from "./CommentDebriefing";
import {convertMilesToKilometers} from "../Workout/Utils";
import {MetricValues} from "../../../Functions/Metrics";
import DescriptionDebriefing from "./DescriptionDebriefing";

import {format, formatISO, subHours, subMinutes} from "date-fns"
import InfoDebriefing from "./InfoDebriefing";

/**
 * Afficher un formulaire de création de Compte-rendu
 *
 * @param date
 */
const NewDebriefing = ({date, closeDebriefingModal}) => {
    const {t} = useTranslation(['app'])
    // openCloseDebrief,
    const {updateCalendarEventToRefresh} = useContext(WeekCalendarContext)

    const {metric} = useContext(MetricContext)

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const [title, setTitle] = useState(t('app:workout.debriefing.free'))
    const [type, setType] = useState(3)
    const [hour, setHour] = useState(0)
    const [min, setMin] = useState(0)
    const [rpe, setRpe] = useState(0)
    const [distance, setDistance] = useState(0)
    const [discipline, setDiscipline] = useState(null)
    const [comment, setComment] = useState("")
    const [description, setDescription] = useState("")
    const [conditions, setConditions] = useState({
        sickness: false,
        hurt: false,
        fever: false
    })
    const [hurtDetails, setHurtDetails] = useState('')
    const [disciplinesStyles, setDisciplinesStyles] = useState({
        rpeLabel: "rpe-label-other",
        speechBubble: "speech-bubble-other",
        stateBar: "state-bar-other",
        rpeBtn: "rpe-btn-other",
        color: 'blue'
    })
    const [requiredFields, setRequiredFields] = useState({
        duration: false,
        distance: false,
        rpe: false,
    })
    const [ready, setReady] = useState(false)
    const [startDate, setStartDate] = useState(null)

    useEffect(() => {

        var currentDate = date.toDate()
        let sYear = parseInt(format(currentDate,'y'))
        let sMonth = parseInt(format(currentDate,'M'))
        let sDay = parseInt(format(currentDate,'d'))
        var hours = (new Date).getHours()
        var minutes = (new Date).getMinutes()

        // let sHour = hours-hour
        // let sMinute = minutes-min
        let newDate = new Date(sYear, sMonth-1, sDay, hours, minutes)
        if(hour) {
            newDate = subHours(newDate, hour)
        }
        if(min) {
            newDate = subMinutes(newDate, min)
        }
        console.log('setStartDate', [sYear, sMonth, sDay, hours, minutes, newDate, hour, minutes])
        setStartDate(newDate)

    }, [date, hour, min])
    // Vérifier les champs obligatoires
    useEffect(() => {
        if (requiredFields.duration && requiredFields.distance  && requiredFields.rpe) {
            setReady(true)
        }
    }, [requiredFields])

    const [errorMessage, setErrorMessage] = useState()

    useEffect(() => {
        if (discipline) {
            setDisciplinesStyles((oldStyles) => {
                let newStyles = {...oldStyles}
                switch (discipline.id) {
                    case 5:
                        newStyles.rpeLabel = "rpe-label-nat"
                        newStyles.speechBubble = "speech-bubble-nat"
                        newStyles.stateBar = "state-bar-nat"
                        newStyles.rpeBtn = "rpe-btn-nat"

                        newStyles.color = 'blue'
                        break
                    case 6:
                        newStyles.rpeLabel = "rpe-label-velo"
                        newStyles.speechBubble = "speech-bubble-velo"
                        newStyles.stateBar = "state-bar-velo"
                        newStyles.rpeBtn = "rpe-btn-velo"

                        newStyles.color = 'green'
                        break
                    case 7:
                        newStyles.rpeLabel = "rpe-label-cap"
                        newStyles.speechBubble = "speech-bubble-cap"
                        newStyles.stateBar = "state-bar-cap"
                        newStyles.rpeBtn = "rpe-btn-cap"

                        newStyles.color = 'orange'
                        break
                    default:
                        newStyles.rpeLabel = "rpe-label-other"
                        newStyles.speechBubble = "speech-bubble-other"
                        newStyles.stateBar = "state-bar-other"
                        newStyles.rpeBtn = "rpe-btn-other"

                        newStyles.color = 'blue'
                        break
                }
                return newStyles
            })
        }
    }, [discipline])


    const onChangeTitle = (value) => {
        setTitle(value)
    }

    const onChangeType = (value) => {
        setType(value)
    }

    const onChangeDiscipline = (value) => {
        setDiscipline(value)
        if (!requiredFields.distance && (value.code === "renfo" || value.code === "muscu")) {
            setRequiredFields((oldRequiredFields) => {
                let newRequiredFields = {...oldRequiredFields}
                newRequiredFields.distance = true
                return newRequiredFields
            })
        }
    }

    const onChangeDistance = (value) => {
        setDistance(value)
        if (!requiredFields.distance) {
            setRequiredFields((oldRequiredFields) => {
                let newRequiredFields = {...oldRequiredFields}
                newRequiredFields.distance = true
                return newRequiredFields
            })
        }
    }

    const onChangeDuration = (durationType, value) => {
        if (durationType === 'hour') {
            setHour(value.id)
        } else {
            setMin(value.id)
        }

        if (!requiredFields.duration) {
            setRequiredFields((oldRequiredFields) => {
                let newRequiredFields = {...oldRequiredFields}
                newRequiredFields.duration = true
                return newRequiredFields
            })
        }
    }

    const onChangeRpe = (value) => {
        setRpe(value)
        if (!requiredFields.rpe) {
            setRequiredFields((oldRequiredFields) => {
                let newRequiredFields = {...oldRequiredFields}
                newRequiredFields.rpe = true
                return newRequiredFields
            })
        }
    }

    const onChangeComment = (value) => {
        setComment(value)
    }
    const onChangeDescription = (value) => {
        setDescription(value)
    }

    // Enregister compte-rendu
    const postDebriefing = () => {
        const headers = generateHeaders()
        let datas = {}

        let durationMin = +hour*60 + +min

        datas.title = title ? title : t('app:workout.debriefing.free')
        datas.rpe = rpe/10
        datas.sickness = conditions.sickness
        datas.fever = conditions.fever
        datas.hurt = conditions.hurt
        datas.hurt_details = conditions.hurt && hurtDetails !== '' ? hurtDetails : null
        datas.description = description
        // datas.date = startDate.format("YYYY-MM-DD[T]HH:mm:ssZ")
        datas.date = formatISO(startDate)
        datas.discipline_ids = [discipline.id]
        datas.duration = durationMin
        datas.distance = (discipline.code === "renfo" || discipline.code === "muscu") ? 0 : +distance
        datas.type = type


        if(metric === MetricValues.imperial) {
            datas.distance = convertMilesToKilometers(datas.distance)
        }

        axios
            .post(
                config+"api/debriefings/new",
                datas,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then((response) => {
                // Déclencher le rechargement le calendrier
                updateCalendarEventToRefresh("debriefing-" + response.data.id)

                // Fermer le formulaire de création de compte-rendu
                closeDebriefingModal()
                // openCloseDebrief()

                // Créer un commentaire
                if (comment !== '') {
                    postComment(response.data.id)
                }

            })
            .catch(error => {
                handleEndpointError(error)
                if(error.response !== undefined) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.data.errors)
                    }
                }
            })
    }

    // Enregister compte-rendu
    const postComment = (debriefId) => {
        const headers = generateHeaders()
        let datas = {}

        datas.content = comment

        axios
            .post(
                config+"api/debriefings/"+debriefId+"/comments/new",
                datas,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then((response) => {
            })
            .catch(error => {
                handleEndpointError(error)
                if(error.response !== undefined) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.data.errors)
                    }
                }
            })
    }

    return (
        <div id="free-debrief-card" className={isMobile ? "col-12 mb-4 px-0" : "col-xl-4 p-3 mb-4 card-float-dashboard"}>
            <div id="alert-card-header" className="dashboard-card background-white">
                <div className={"d-flex pct-3 pcx-3 "+ (isMobile ? 'justify-content-start': 'justify-content-between')}>
                    {isMobile &&
                    <img
                        className="icon-24 mcr-2 mt-2 pointer"
                        src={arrow_left}
                        alt="arrow left icon"
                        onClick={closeDebriefingModal}
                    />
                    }
                    <div>
                        <input type="text"
                               className={"color-black seance-title black-border pc-2 radius-8 w-100" }
                               value={title}
                               onChange={(e) => onChangeTitle(e.target.value)}
                        />
                        <p className="gotham-12 mcy-2 color-grey-light text-left">
                            {startDate ? getVerboseDateWithClock(startDate) : ''}
                        </p>
                    </div>
                    {!isMobile &&
                    <img
                        className="icon-24 pointer right"
                        src={x_circle}
                        alt="close icon"
                        onClick={closeDebriefingModal}
                    />
                    }
                </div>
                <div className="mcb-7">
                    <div id="rpe-card" className="rpe-card pcx-3 pcy-3">
                        <InfoDebriefing />
                        <div className="activity-CR mcb-4">
                            <div className="row">
                                <div className="col-12">
                                    <p className={"rpe-title text-left mcb-3"}>{t('app.layout.activity')}</p>
                                </div>

                                <DisciplineDebriefing onChangeDiscipline={(value) => onChangeDiscipline(value)} />


                                {/* Champs requis */}
                                <TypeDebriefing type={type}
                                                onChangeType={(value) => onChangeType(value)}
                                                color={disciplinesStyles.color}

                                />

                                {/* Champs requis */}
                                <DistanceDurationDebriefing onChangeDuration={(durationType, value) => onChangeDuration(durationType, value)}
                                                            onChangeDistance={(value) => onChangeDistance(value)}
                                                            distance={distance}
                                                            hour={hour}
                                                            min={min}
                                                            discipline={discipline}
                                />



                                {/* Champs requis */}
                                <RpeDebriefing disciplinesStyles={disciplinesStyles}
                                               setRpe={(value) => onChangeRpe(value)}
                                               rpe={rpe}
                                               cardId={'free-debrief-card'}
                                />

                                <ConditionsDebriefing setConditions={setConditions}
                                                      conditions={conditions}
                                                      color={disciplinesStyles.color}
                                                      hurtDetails={hurtDetails}
                                                      setHurtDetails={setHurtDetails}
                                />

                                <CommentDebriefing comment={comment}
                                                   onChangeComment={(value) => onChangeComment(value)}
                                                   haveComment={false}
                                />
                                <DescriptionDebriefing description={description}
                                                       onChangeDescription={(value) => onChangeDescription(value)}
                                                       color={disciplinesStyles.color}
                                />

                                <div className="w-100 mct-3 d-flex justify-content-center">
                                    {errorMessage &&
                                    <div className="error-login-text mcb-3">
                                        {errorMessage}
                                    </div>
                                    }
                                    <Button className="background-black py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text"
                                            onClick={() => postDebriefing()}
                                            disabled={!ready}
                                    >
                                        <img className="mr-2 icon-24" alt="valid icon" src={validate} />{t('app:athlete.saving.save')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NewDebriefing;