import L from 'leaflet';

const StartIcon = new L.Icon({
    iconUrl: require('../../../../img/marker-start.png'),
    iconRetinaUrl: require('../../../../img/marker-start.png'),
    iconSize: new L.Point(20, 20),
    className: 'start-marker-icon',
});

export default StartIcon
