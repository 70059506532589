import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import InputSwitch from "../Tools/InputSwitch";
import {cup_1, cup_2, cup_3, cup_4, cup_5, cup_6, cup_7, cup_8} from "../../img/img";
import CustomSelect from "../Tools/CustomSelect";
import {getCycleValues, getRunningValues, getSwimValues} from "./Utils";
import {MetricContext, TrainingConceptConfigContext, WeekCalendarContext} from "../../Contexts";
import {TrainingConceptCodes, CriticalIntensityStatistics} from "../TrainingConcept/Utils";
import {Metrics, MetricValues} from "../../Functions/Metrics";
import generateHeaders from "../../Functions/generateHeaders";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import {useIsMounted} from "../Tools/CustomHooks";
import {getTrainingConceptByContextCode} from "../Context/Utils";
import {round_dec} from "../../Functions/TextMaths";
import moment from "moment";
import {format as formatDate} from "date-fns";
import {createTrainingEventFromDebriefing} from "../AthleteCalendar/TrainingEvent/Utils";

const BestRecords = ({discipline, context}) => {

    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()

    const {metric, runningCriticalIntensityStatistic} = useContext(MetricContext)
    const metrics = Metrics(metric)

    const trainingContextConfigValue = useContext(TrainingConceptConfigContext)
    const {updateDayToOpen, updateTrainingEvent, updateTrainingEventOpen, updateActiveTabOpen} = useContext(WeekCalendarContext)

    const [dimensionValue, setDimensionValue] = useState(null)
    const [dimensionOptions, setDimensionOptions] = useState([])
    // Définis si les valeurs seront affichées en fonction du temps ou de la distance sélectionnée
    const [distanceIsReferal, setDistanceIsReferal] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [moreIsOpen, setMoreIsOpen] = useState(false)
    const [metricValues, setMetricValues] = useState(null)

    const [datas, setDatas] = useState(null)


    useEffect(() => {
        if (isOpen) {
            if(context && trainingContextConfigValue) {
                getMetricValues()
            }
        }
    }, [context, trainingContextConfigValue, isOpen])


    useEffect(() => {
        if (dimensionValue && metricValues) {
            getTopDatas()
        }
    }, [dimensionValue, metricValues])

    useEffect(() => {
        let param = distanceIsReferal ? 'distance' : 'duration'
        let values
        switch (discipline) {
            default:
            case "velo":
                values = getCycleValues(param)
                setDimensionOptions(values)
                break
            case "cap":
                values = getRunningValues(param)
                setDimensionOptions(values)
                break
            case "nat":
                values = getSwimValues()
                setDimensionOptions(values)
                break
        }
    }, [discipline, distanceIsReferal])

    useEffect(() => {
        if (dimensionOptions && dimensionOptions.length > 0) {
            setDimensionValue(dimensionOptions[0])
        }
    }, [dimensionOptions])

    const getTopDatas = async () => {
        let param = distanceIsReferal ? 'distance' : 'duration'
        const headers = generateHeaders()
        let type = metricValues.type
        if(type === 'pace' && discipline == 'cap') {
            type = 'speed'
        }
        await axios
            .get(
                config+"api/athlete_performance/best_records",
                {
                    headers: {
                        'X-WSSE': headers
                    },
                    params: {
                        discipline_code: discipline,
                        context_code: context.code,
                        type: type,
                        dimension_type: param,
                        dimension_value: dimensionValue.value
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let bestRecords = response.data
                setDatas(bestRecords)
            })
            .catch(error => {
                handleEndpointError(error)
            })
        //call api avec params discipline et time ou distance
    }


    const openTrainingEventWithActivitySession = async (debriefingId, activitySessionId) => {
        const headers = generateHeaders()
        await axios
            .get(
                config+"api/debriefings/"+debriefingId,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let debriefing = response.data
                let trainingEvent = createTrainingEventFromDebriefing(debriefing, "debriefing-event-"+debriefingId)

                updateDayToOpen(formatDate(new Date(trainingEvent.date), "yyyy-MM-dd"))
                updateTrainingEvent(trainingEvent)
                updateTrainingEventOpen(true)


                // Onglet à ouvrir
                let activeTab = 'debriefing'
                if (trainingEvent.debriefing_is_complete) {
                    switch (activitySessionId) {
                        case trainingEvent.activity_session_id:
                            activeTab = 'activity_session'
                            break
                        case trainingEvent.activity_session_1_id:
                            activeTab = 'activity_session_1'
                            break
                        case trainingEvent.activity_session_2_id:
                            activeTab = 'activity_session_2'
                            break
                        default:
                            break
                    }
                }
                updateActiveTabOpen(activeTab)

            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const getMetricValues = () => {
        let unit, label, typeValue
        let trainingConcept = getTrainingConceptByContextCode(context.code, trainingContextConfigValue)
        let trainingConceptCode = trainingConcept ? trainingConcept.training_concept_code : TrainingConceptCodes.CRITICAL_INTENSITY
        let dIsReferal = true
        switch (trainingConceptCode) {
            case TrainingConceptCodes.CRITICAL_HEARTRATE:
                label = t('app.training_concept.critical_heartrate')
                unit = metrics.heartrate
                typeValue="heartrate"
                dIsReferal = false
                break
            default:
            case TrainingConceptCodes.CRITICAL_INTENSITY:
                switch (discipline){
                    case 'nat':
                        label = t('app.training_concept.ci_pacing.pace')
                        unit = metrics.allure_nat
                        typeValue="pace"
                        break
                    case 'velo':
                        label = t('app.training_concept.ci_pacing.power')
                        unit = metrics.power_velo
                        typeValue="power"
                        dIsReferal = false
                        break
                    case 'cap':
                    default:
                        switch (runningCriticalIntensityStatistic) {
                            case CriticalIntensityStatistics.PACE:
                                label = t('app.training_concept.ci_pacing.pace')
                                unit = metrics.allure_cap_velo
                                typeValue="pace"
                                break
                            case CriticalIntensityStatistics.SPEED:
                            default:
                                label = t('app.training_concept.ci_pacing.speed')
                                unit = metrics.speed_cap_velo
                                typeValue="speed"
                                break
                        }
                        break
                }
                break
        }
        setDistanceIsReferal(dIsReferal)
        setMetricValues({unit: unit, label: label, type: typeValue})
    }

    // Gérer le réglage de la distribution des intensités
    const onSwitchChange = () => {
        if (distanceIsReferal) {
            setDistanceIsReferal(false)
        } else {
            setDistanceIsReferal(true)
        }
    }

    const onChangeDimension = (value) => {
        setDimensionValue(value)
    }

    const onChangeSeeMore = () => {
        setMoreIsOpen(!moreIsOpen)
    }

    const onBestRecordClick = (debriefingId, activitySessionId) => {
        openTrainingEventWithActivitySession(debriefingId, activitySessionId)
    }

    const renderCupDiv = (position) => {
        let cupIcon
        let placement = "3-more"
        switch (position) {
            default:
            case 1 :
                placement = "1"
                cupIcon = cup_1
                break
            case 2 :
                placement = "2"
                cupIcon = cup_2
                break
            case 3 :
                placement = "3"
                cupIcon = cup_3
                break
            case 4 :
                cupIcon = cup_4
                break
            case 5 :
                cupIcon = cup_5
                break
            case 6 :
                cupIcon = cup_6
                break
            case 7 :
                cupIcon = cup_7
                break
            case 8 :
                cupIcon = cup_8
                break
        }

        let bestRecord = datas[position-1]
        let valueToDisplay = metric === MetricValues.imperial? bestRecord.value_uk : bestRecord.value
        switch (metricValues.type) {
            case 'speed':
                valueToDisplay = round_dec(valueToDisplay)
                break
            case 'power':
            case 'heartrate':
                valueToDisplay = Math.round(valueToDisplay)
                break
            case 'pace':
                if(discipline == 'cap') {
                    valueToDisplay = metric === MetricValues.imperial? bestRecord.pacing_value_uk : bestRecord.pacing_value
                }
            default:
                break
        }

        return (

            <div className="mct-2 d-flex align-items-center justify-content-center pcx-3 w-100">
                <div className={"cup-container cup-"+placement+"-border w-75 mcr-2"}>
                    <div className={"container-cup-icon container-cup-icon-"+placement}>
                        <img className="icon-24" src={cupIcon} alt={"cup icon "+position}/>
                    </div>
                    <div className="cup-content w-100">
                        { valueToDisplay }
                    </div>
                </div>
                <div className="cup-activity pointer w-25"
                     onClick={() => onBestRecordClick(bestRecord.debriefing_id, bestRecord.activity_session_id)}
                >
                    {t('app.best_performances.see_activity')} {bestRecord && moment(bestRecord.date).format("DD/MM/YYYY")}
                </div>
            </div>
        )
    }

    return (
        <div className={"zs-tab-card pcy-3 mcx-2 mct-3 " + (!isOpen ? "bg-transparent" : "")}>
            <div className="monitoring-title pcx-2">
                {t('app.best_performances.title')}
            </div>
            <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                  onClick={() => setIsOpen(isOpen => !isOpen)}
            >
                <i className={isOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
            </span>
            {isOpen && metricValues ?
                <>
                    <div className="w-100 d-flex justify-content-center mcb-2 pct-3">
                        {discipline !== 'nat' && metricValues.type !== 'heartrate' &&
                            <InputSwitch
                                addClassName="pb-3"
                                labelLeft={t('rjbal.time')}
                                selectOpt={distanceIsReferal}
                                labelRight={t('rjbal.distance')}
                                onSwitchChange={onSwitchChange}
                            />
                        }
                        {discipline === 'nat' &&
                            <p className="font-weight-bold fs-12">
                                { t('rjbal.distance') }
                            </p>
                        }
                        {metricValues.type === 'heartrate' &&
                            <p className="font-weight-bold fs-12">
                                {t('rjbal.time')}
                            </p>
                        }

                    </div>
                    <div className="d-flex justify-content-center w-100">
                        <CustomSelect
                            className="w-50 text-center best-record-dimension-value"
                            name="distanceSelect"
                            options={dimensionOptions}
                            defaultValue={dimensionValue}
                            value={dimensionValue}
                            onChange={(value) => onChangeDimension(value)}
                        />
                    </div>

                    <div className="d-flex flex-column mct-3">
                        <span className="gotham-12">
                            {metricValues.label}
                        </span>
                        <span className="gotham-12 font-weight-normal">
                            {metricValues.unit}
                        </span>
                    </div>
                    {datas &&
                        <>
                            {datas.length > 0 && renderCupDiv(1)}
                            {datas.length > 1 && renderCupDiv(2)}
                            {datas.length > 2 && renderCupDiv(3)}
                            {moreIsOpen ?
                                <>
                                    {datas.length > 3 && renderCupDiv(4)}
                                    {datas.length > 4 && renderCupDiv(5)}
                                    {datas.length > 5 && renderCupDiv(6)}
                                    {datas.length > 6 && renderCupDiv(7)}
                                    {datas.length > 7 && renderCupDiv(8)}
                                </>
                                :
                                <></>
                            }
                        </>
                    }


                    {datas && datas.length > 2 &&
                        <div className="pointer more-information mct-3" onClick={onChangeSeeMore}>
                            {moreIsOpen ?
                                t('app.best_performances.see_less')
                                :
                                t('app.best_performances.see_more')
                            }
                        </div>
                    }
                    {!datas || datas.length === 0 &&
                    <div className="d-flex justify-content-center gotham-12 font-weight-normal mct-3">
                        {t('app.best_performances.no_data')}
                    </div>
                    }
                </>

                :
                <></>
            }
        </div>
    )
}

export default BestRecords