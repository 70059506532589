import React from 'react';
import {format as formatDate} from "date-fns";
import {useTranslation} from "react-i18next";
import {validate} from "../../img/img";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

const SubscriptionPurchaseConfirmation = ({subscriptionDatas}) => {
    const {t} = useTranslation(["app"])
    let dateNumber = subscriptionDatas.date.getDate()
    return (
        <>
            <div className="purchase-confirmation-text mct-4">
                <p>{t('confirmation_page.subscription.0')}<br/>
                    - {t('confirmation_page.subscription.1')} <span className="font-weight-bold">{subscriptionDatas.subscription}</span><br/>
                    - {t('confirmation_page.subscription.2')} <span className="font-weight-bold">{t('confirmation_page.subscription.3')}</span> {t('confirmation_page.subscription.4')}<br/>

                {subscriptionDatas.options.length > 0 && subscriptionDatas.options.map((option, index) =>
                    <React.Fragment key={"option-"+index}>
                    - {t('confirmation_page.subscription.5')} <span className="font-weight-bold">{option}</span><br/>
                    </React.Fragment>
                )}
                </p>
                <p>{t('confirmation_page.subscription.6')} {dateNumber} {t('confirmation_page.subscription.7')}</p>
            </div>
            <Link to={"/dashboard"} className="link-btn">
                <Button className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5'
                >
                    <img className="mcr-2 icon-24" alt="valid icon" src={validate}/>
                    <p className="my-auto home-btn-p-text">{t('confirmation_page.start')}</p>
                </Button>
            </Link>
        </>
    );
};

export default SubscriptionPurchaseConfirmation;
