import React, {useState, useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import Loader from 'react-loader-spinner'

import {tic, tic_orange, check_circle_blue, check_circle_orange, error} from "../img/img";
import handleEndpointError from "../Functions/Alert";
import axios from "axios/index";
import config from "../api";
import {LanguageContext} from "../Contexts";


const CartCoupon = ({cart, couponTmp, color, updateCart}) => {

    // Traduction
    const {t} = useTranslation(['app']);

    const {language} = useContext(LanguageContext)

    const [coupon, setCoupon] = useState(couponTmp ? couponTmp : "")
    const [couponMessage, setCouponMessage] = useState(null)
    const [couponError, setCouponError] = useState(null)
    const [showSubmitCouponBtn, setShowSubmitCouponBtn] = useState(true)

    useEffect(() => {
        // Prise en compte du coupon indiqué dans l'url d'appel
        if(couponTmp) {
            updateCartCoupon()
        }
    }, [])

    const getIconCheck = () => {
        let iconCheck = null
        switch(color) {
            case 'blue':
                iconCheck = tic
                break
            case 'orange':
                iconCheck = tic_orange
                break
            default:
                break
        }
        return (
            <div className="test-eye ml-auto mcr-3">
                <img
                    id="ticImg"
                    src={iconCheck}
                    alt="tic picto"
                    className="icon-24"
                />
            </div>
        )
    }
    const getIconCoupon = () => {
        let iconCoupon = null
        switch(color) {
            case 'blue':
                iconCoupon = check_circle_blue
                break
            case 'orange':
                iconCoupon = check_circle_orange
                break
            default:
                break
        }
        return (
            <div>
                <img
                    id="couponSuccess"
                    src={iconCoupon}
                    alt="promo code"
                    className="icon-24"
                />
            </div>
        )
    }

    const onChangeCoupon = (value) => {
        setCoupon(value)
        setCouponMessage(null)
        setCouponError(null)
    }

    const onSubmitCoupon = () => {
        setShowSubmitCouponBtn(false)
        // Mettre à jour le panier d'achat avec le coupon
        updateCartCoupon()
    }

    const clearCoupon = () => {
        onChangeCoupon(null)
        setTimeout(() => {
            updateCartCoupon()
        }, 300)
    }

    const updateCartCoupon = async () => {
        await axios
            .put(
                config+"public/api/cart/"+cart.id +"/update_coupon",
                {
                    coupon: coupon,
                    locale: language
                },
            )
            .then(response => {
                // Mettre à jour le message d'information
                setCouponMessage(response.data.confirmation)
                // Mettre à jour le message d'erreur
                setCouponError(response.data.error)

                // Récupérer les données du panier d'achat
                    // Déclencher la mise à jour du tarif
                updateCart()

                setShowSubmitCouponBtn(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    return (
        <>
            <h4 className="text-left w-100 label-form-subscription mcb-3 mct-5">{t('app:settings.subscription.status.code_promo')}</h4>
            <div className="card-checkout d-flex px-3 w-100 mcb-3 form-input-height">
                <Form.Group className="w-100 form-input-height">
                    <Form.Control className="form-login-input pcx-1 form-checkout-coupon"
                                  type="text"
                                  name="promo-code"
                                  placeholder=""
                                  value={coupon}
                                  onChange={(event) => onChangeCoupon(event.target.value)}
                    >
                    </Form.Control>
                    {couponMessage &&
                    getIconCheck()
                    }
                    {couponError &&
                    <div className="test-eye ml-auto cursor-pointer"
                         onClick={() => onChangeCoupon("")}
                    >
                        <img
                            id="errorImg"
                            src={error}
                            alt="error picto"
                            className="icon-24"
                        />
                    </div>
                    }
                </Form.Group>

                {couponMessage === null && couponError === null ?
                    showSubmitCouponBtn ?
                        <button className={"my-auto d-flex align-items-center btn-promo-code btn-promo-code-"+color}
                                style={{background:"#FFFFFF",fontWeight:"bold"}}
                                onClick={() => onSubmitCoupon()}
                        >
                            {getIconCoupon()}
                            <span className="mcl-1">{t('app:cart.coupon.use')}</span>
                        </button>
                        :
                        <Loader
                            type="ThreeDots"
                            color={color === 'orange' ? '#FF7A00' : '#007DFF'}
                            height={26}
                            width={26}
                            className="pct-3 pcr-3"
                        />
                    :
                    <></>
                }
            </div>
            { couponMessage  &&
                <>
                    <p className={"text-promo-code w-100 text-left mb-1"}>{couponMessage}</p>
                    <span className="subscription-clear-coupon mt-0"
                        onClick={() => clearCoupon()}
                        >
                        {t('app:cart.coupon.clear')}
                    </span>
                </>
            }
            { couponError  &&
            <p className="text-promo-code-error w-100 text-left">{couponError}</p>
            }
        </>
    )
}
export default CartCoupon