import React, {useContext, useEffect} from 'react';
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import { Container } from "react-bootstrap";
import '../Css/Home.css';
import {HeaderHome} from "../Components/Home/HeaderHome";
import {LanguageContext, NavigationContext} from "../Contexts";
import Register from "../Components/Forms/Register";

import {updateLanguageFromSearch} from "../Functions/Utils";


const Registration = (props) => {
    //Gestion de la langue selon l'URL
    const {updateLanguage} = useContext(LanguageContext)
    useEffect(() =>  {
        updateLanguageFromSearch(updateLanguage, props.location.search)
    }, [])

    const history = useHistory()

    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    const {t} = useTranslation(['app'])

    const onSuccess = () => {
        history.push("/finalize_registration")
    }

    const switchToLogin = () => {
        history.push("/login")
    }

    return (
        <Container className={isMobile ? "pc-4 vh-100 d-flex flex-column align-items-center padding-top-mobile" : "w-25 pc-4 vh-100 d-flex flex-column justify-content-center align-items-center"}>
            <HeaderHome />
            <p id="text-middle-login" className={isMobile ? "text-middle-login my-auto" : "text-middle-login text-middle-login-desktop"}>{t('app:register.welcome')}</p>
            <div id="form-login" className={isMobile ? "w-100" : "w-100 height-form-register"}>
                <Register
                    onSuccess={() => onSuccess()}
                    switchToLogin={() => switchToLogin()}
                />
            </div>
        </Container>
    )
}

export default Registration
