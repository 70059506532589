import React, {useContext} from 'react';
import {
    allure_icon,
    cadence_cap_icon,
    cadence_velo_icon,
    distance_icon,
    duration_icon,
    power_icon,
    rpe0,
    rpe12,
    rpe34,
    rpe56,
    rpe78,
    rpe910,
    total_ascent_icon
} from "../../img/img";
import WorkoutTemplateDisciplineCube from "./WorkoutTemplateDisciplineCube";
import {MetricContext, NavigationContext} from "../../Contexts";
import {Metrics, MetricValues} from "../../Functions/Metrics";
import {secondsToMinPoints} from "../../Functions/TextMaths";
import {convertKilometersToMiles, convertMetersToYards} from "../AthleteCalendar/Workout/Utils";

const WorkoutTemplateExercise = ({exercise}) => {
    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    // métrique utilisateur
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    let disciplineCode = exercise.discipline.code
    let iconCol2, iconCol3, unitCol2, unitCol3, rpeIcon
    switch (disciplineCode) {
        default:
        case 'cap':
            iconCol2 = allure_icon
            iconCol3 = cadence_cap_icon
            unitCol2 = metrics.allure_cap_velo
            unitCol3 = metrics.cadence_cap
            break
        case 'velo':
            iconCol2 = power_icon
            iconCol3 = cadence_velo_icon
            unitCol2 = metrics.power_velo
            unitCol3 = metrics.cadence_velo
            break
        case 'nat':
            iconCol2 = allure_icon
            iconCol3 = cadence_velo_icon
            unitCol2 = metrics.allure_nat
            unitCol3 = metrics.cadence_velo
            break
    }
    if(exercise.rpe_max === null) {
        rpeIcon = rpe0
    }
    else if(exercise.rpe_max < 3) {
        rpeIcon = rpe12
    }
    else if(exercise.rpe_max < 5) {
        rpeIcon = rpe34
    }
    else if(exercise.rpe_max < 7) {
        rpeIcon = rpe56
    }
    else if(exercise.rpe_max < 9) {
        rpeIcon = rpe78
    }
    else if(exercise.rpe_max <= 10) {
        rpeIcon = rpe910
    }

    let zoneName = exercise.zone ? exercise.zone.name : "-"
    // if (zoneName.length > 4) {
    //     zoneName = zoneName.substr(0, 4)+'.'
    // }

    const distanceUnit = ['m','km']
    const durationUnit = ['sec', 'min']
    const unitCode = exercise.unit.code
    let exoValue = exercise.value
    let exoUnit = unitCode
    let exoUnitType, exoValueIcon

    if(distanceUnit.indexOf(unitCode) !== -1) {
        exoUnitType = 'distance'
        exoValueIcon = distance_icon
        if(disciplineCode == 'nat') {
            exoUnit = metrics.distance_nat
            exoValue = exoUnit != unitCode ? (exoValue*1000) : exoValue
            exoValue = metric === MetricValues.imperial ? Math.floor(convertMetersToYards(exoValue)) : exoValue
        }
        else {
            exoUnit = metrics.distance_cap_velo
            exoValue = exoUnit != unitCode ? (exoValue/1000) : exoValue
            exoValue = metric === MetricValues.imperial ? convertKilometersToMiles(exoValue).toFixed(1) : exoValue
        }
    }
    else if(durationUnit.indexOf(unitCode) !== -1) {
        exoUnitType = 'duration'
        exoValueIcon = duration_icon
        if(unitCode == 'sec' && exoValue >= 60) {
            exoValue = secondsToMinPoints(exoValue)
            exoUnit = 'min'
        }
        else if(unitCode == 'min') {
            exoValue = exoValue >= 60 ? exoValue : secondsToMinPoints(exoValue*60)
        }
    }

    return (
        <div className={`d-flex align-items-center text-gotham fs-14 mcb-3 ${isMobile ? "row" : ""}`}>
            <div className={`d-flex flex-column align-items-center ${isMobile ? "col" : "mcr-3"}`} style={isMobile ? {} : {minWidth: '68px'}}>
                <WorkoutTemplateDisciplineCube discipline={exercise.discipline.code} noMargin={true}/>
                <div className="text-gotham-book fs-12">{zoneName}</div>
            </div>

            <div className={`d-flex flex-column align-items-center ${isMobile ? "col" : "mcx-3"}`} style={isMobile ? {} : {minWidth: '58px'}}>
                <img
                    className="icon-24"
                    src={exoValueIcon}
                    alt="icon"
                />
                <div className="text-gotham-book font-weight-bold fs-12 mct-1">{exoValue ? exoValue : '-'}</div>
                <div className="text-gotham-book fs-12">{exoUnit}</div>
            </div>
            <div className={`d-flex flex-column align-items-center ${isMobile ? "col" : "mcx-3"}`} style={isMobile ? {} : {minWidth: '58px'}}>
                <img
                    className="icon-24"
                    src={iconCol2}
                    alt="icon"
                />
                <div className="text-gotham-book font-weight-bold fs-12 mct-1">-</div>
                <div className="text-gotham-book fs-12">
                    {unitCol2}
                </div>
            </div>
            <div className={`d-flex flex-column align-items-center ${isMobile ? "col" : "mcx-5"}`}>
                <img
                    className="icon-24"
                    src={iconCol3}
                    alt="icon"
                />
                <div className="text-gotham-book font-weight-bold fs-12 mct-1">-</div>
                <div className="text-gotham-book fs-12">
                    {unitCol3}
                </div>
            </div>
            <div className={`d-flex flex-column align-items-center ${isMobile ? "col" : "mcx-5"}`} style={isMobile ? {} : {minWidth: '48px'}}>
                <img
                    className="icon-24"
                    src={rpeIcon}
                    alt="icon"
                />
                <div className="text-gotham-book font-weight-bold fs-12 mct-1">
                    {exercise.rpe_min ?
                        exercise.rpe_min+" - "+exercise.rpe_max
                        :
                        0
                    }
                </div>
                <div className="text-gotham-book fs-12">
                    RPE
                </div>
            </div>
            {exercise.trail_type !== null &&
                <div className={`d-flex flex-column align-items-center w-100 ${isMobile ? "col" : ""}`}>
                    <img
                        className="icon-24"
                        src={total_ascent_icon}
                        alt="icon"
                    />
                    <div className="text-gotham-book fs-12">
                        {exercise.trail_type.name}
                    </div>
                </div>
            }
            {disciplineCode === 'nat' &&
            <div className={`d-flex flex-column align-items-center w-100 ${isMobile ? "col" : ""}`}>
                <div className="text-gotham-book font-weight-bold fs-12">
                    {exercise.swim_type ? exercise.swim_type.name : ' '}
                </div>
            </div>
            }

        </div>
    )
};

export default WorkoutTemplateExercise;