import React, {Suspense, useEffect, useRef, useState} from 'react';
import Conditional from 'react-simple-conditional';
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import PWAPrompt from "react-ios-pwa-prompt";
import {Button, Col} from "react-bootstrap";
import Loader from "react-loader-spinner";
import PrivateRoute from "./PrivateRoute"

import axios from "axios";
import config from "./api";
import generateHeaders from "./Functions/generateHeaders";
import handleEndpointError from './Functions/Alert';
import CacheBuster from "./CacheBuster";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import './Css/App.css';
import "./Css/Seance.css"
import "./Css/Debriefing.css"
import "./Css/laps.css"

import Home from "./Pages/Home";
import LoginPage from "./Pages/LoginPage";
import MagicLoginPage from "./Pages/MagicLoginPage";
import UserDisabled from "./Pages/UserDisabled";
import Registration from "./Pages/Registration";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetingPassword";
import FinalizeRegistration from "./Pages/FinalizeRegistration";
import Dashboard from "./Pages/Dashboard";
import PageNotFound from "./Pages/PageNotFound";
import ComeBackSoon from "./Pages/ComeBackSoon";
import SubscriptionCoaching from "./Subscription/SubscriptionCoaching"
import SubscriptionMembership from "./Subscription/SubscriptionMembership"
import SubscriptionAutonom from "./Subscription/SubscriptionAutonom"
import SubscriptionProgram from "./Subscription/SubscriptionProgram";
import Logout from "./Pages/Logout";
import ActivitySessionLaps from "./Components/AthleteCalendar/ActivitySession/ActivitySessionLaps";

import {convertLocaleToLanguage, registerLanguage} from "./Functions/LocaleManager";
import {
    AuthContext,
    LanguageContext,
    MetricContext,
    LoggedInContext,
    NavigationContext,
    GarminTrainingContext,
    WeekCalendarContext,
    TrainingConceptConfigContext,
    SportsContext, PrioritySportContext, SplashScreenSettingContext
} from "./Contexts"
import {messaging} from './init-fcm';
import {ContextCodes} from "./Components/Context/Utils";
import PurchaseConfirmation from "./Subscription/PurchaseConfirmation/PurchaseConfirmation";
import TagManager from "react-gtm-module";
import NewSponsorship from "./Sponsorship/NewSponsorship";
import SponsorshipConfirmation from "./Sponsorship/SponsorshipConfirmation";
import ActivitySessionChartLandscapeContainer from './Components/AthleteCalendar/ActivitySession/Charts/ActivitySessionChartLandscapeContainer';
import Onboarding from "./Pages/Onboarding";
import WorkoutTemplateSearch from "./Components/WorkoutTemplate/WorkoutTemplateSearch";


const packageJson = require('../package.json');
const appVersion = packageJson.version



function App(props) {

    // Etat de la connexion
    const [token, setToken] = useState(localStorage.getItem("token") || sessionStorage.getItem("token"))
    const authContextValue = {
        token,
        updateToken: setToken
    }

    // Réglage utilisateur de la langue
    const [language, setLanguage] = useState(null)
    const languageContextValue = {
        language,
        updateLanguage: setLanguage
    }

    // Réglage utilisateur de la métrique
    const [metric, setMetric] = useState(null)
    const [runningCriticalIntensityStatistic, setRunningCriticalIntensityStatistic] = useState(null)
    const metricContextValue = {
        metric,
        updateMetric: setMetric,
        runningCriticalIntensityStatistic,
        updateRunningCriticalIntensityStatistic: setRunningCriticalIntensityStatistic
    }


    // Sports associés à l'utilisateur
    const [prioritySport, setPrioritySport] = useState(null)
    const [preferedSports, setPreferedSports] = useState([])
    const prioritySportContextValue = {
        prioritySport,
        updatePrioritySport: setPrioritySport,
        preferedSports,
        updatePreferedSports: setPreferedSports,
    }

    // Données de l'utilisateur connecté
    const [loggedInContextValue, setLoggedInContextValue] = useState({})

    // Etat de la synchronisation Provider
    const [showSyncProvider, setShowSyncProvider] = useState(false)
    const [splashScreenSettingSyncProvider, setSplashScreenSettingSyncProvider] = useState( null)

    const [showSubscribeAutonom, setShowSubscribeAutonom] = useState(false)
    const [splashScreenSettingSubscribeAutonom, setSplashScreenSettingSubscribeAutonom] = useState( null)

    const [showPlanWorkoutTemplate, setShowPlanWorkoutTemplate] = useState(false)
    const [splashScreenSettingPlanWorkoutTemplate, setSplashScreenSettingPlanWorkoutTemplate] = useState( null)
    
    const splashScreenSettingContextValue = {
        showSyncProvider,
        updateShowSyncProvider: (value) => {
            setShowSyncProvider(value)
        },
        splashScreenSettingSyncProvider,
        updateSplashScreenSettingSyncProvider: (value) => {
            setSplashScreenSettingSyncProvider(value)
        },
        showSubscribeAutonom,
        updateShowSubscribeAutonom: (value) => {
            setShowSubscribeAutonom(value)
        },
        splashScreenSettingSubscribeAutonom,
        updateSplashScreenSettingSubscribeAutonom: (value) => {
            setSplashScreenSettingSubscribeAutonom(value)
        },
        showPlanWorkoutTemplate,
        updateShowPlanWorkoutTemplate: (value) => {
            setShowPlanWorkoutTemplate(value)
        },
        splashScreenSettingPlanWorkoutTemplate,
        updateSplashScreenSettingPlanWorkoutTemplate: (value) => {
            setSplashScreenSettingPlanWorkoutTemplate(value)
        },
    }
    

    // Etat de la synchronisation Garmin Training
    const [garminTrainingSynced, setGarminTrainingSynced] = useState(null)
    const garminTrainingContextValue = {
        garminTrainingSynced,
        updateGarminTrainingSynced: setGarminTrainingSynced
    }


    // Réglages des concepts d'entraînements
    const [swimmingNoPool, setSwimmingNoPool]= useState(null)
    const [swimmingPool25, setSwimmingPool25]= useState(null)
    const [swimmingPool33, setSwimmingPool33]= useState(null)
    const [swimmingPool50, setSwimmingPool50]= useState(null)
    const [runningIndoor, setRunningIndoor]= useState(null)
    const [runningOutdoor, setRunningOutdoor]= useState(null)
    const [cyclingIndoor, setCyclingIndoor]= useState(null)
    const [cyclingOutdoor, setCyclingOutdoor]= useState(null)
    const [cyclingVirtualCycling, setCyclingVirtualCycling]= useState(null)
    const [cyclingVTT, setCyclingVTT]= useState(null)
    const trainingConceptConfigContextValue = {
        swimmingNoPool,
        swimmingPool25,
        swimmingPool33,
        swimmingPool50,
        runningIndoor,
        runningOutdoor,
        cyclingIndoor,
        cyclingOutdoor,
        cyclingVirtualCycling,
        cyclingVTT,
        updateSwimmingNoPool: setSwimmingNoPool,
        updateSwimmingPool25: setSwimmingPool25,
        updateSwimmingPool33: setSwimmingPool33,
        updateSwimmingPool50: setSwimmingPool50,
        updateRunningIndoor: setRunningIndoor,
        updateRunningOutdoor: setRunningOutdoor,
        updateCyclingIndoor: setCyclingIndoor,
        updateCyclingOutdoor: setCyclingOutdoor,
        updateCyclingVirtualCycling: setCyclingVirtualCycling,
        updateCyclingVTT: setCyclingVTT
    }

    // Etat du calendrier
    const [trainingEvent, setTrainingEvent] = useState(null)
    const [trainingEventOpen, setTrainingEventOpen] = useState(true)
    const [activeTabOpen, setActiveTabOpen] = useState(null)
    const [calendarEventToRefresh, setCalendarEventToRefresh] = useState(null)
    const [dayToOpen, setDayToOpen] = useState(null)
    const [activeWeek, setActiveWeek] = useState(null)
    const [athleteEventHandled, setAthleteEventHandled] = useState(null)
    const [athleteAvailabilityEventHandled, setAthleteAvailabilityEventHandled] = useState(null)
    const weekCalendarContextValue = {
        trainingEvent,
        updateTrainingEvent: setTrainingEvent,
        trainingEventOpen,
        updateTrainingEventOpen: setTrainingEventOpen,
        activeTabOpen,
        updateActiveTabOpen: setActiveTabOpen,
        calendarEventToRefresh,
        updateCalendarEventToRefresh: setCalendarEventToRefresh,
        dayToOpen,
        updateDayToOpen : setDayToOpen,
        activeWeek,
        updateActiveWeek : setActiveWeek,
        athleteEventHandled,
        updateAthleteEventHandled: setAthleteEventHandled,
        athleteAvailabilityEventHandled,
        updateAthleteAvailabilityEventHandled: setAthleteAvailabilityEventHandled
    }



    const getIsMobile = (width) => {return width <= 500}
    const getIsTablet = (width) => {return width > 500 && width <= 1024}

    // Navigateur
    const [isSafari, setIsSafari] = useState(false)
    const [width, setWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(getIsMobile(window.innerWidth))
    const [isTablet, setIsTablet] = useState(getIsTablet(window.innerWidth))
    const navigationContextValue = {
        isSafari,
        isMobile,
        isTablet,
        width
    }


    // Gestion du redimensionnement de la fenêtre du navigateur
    function handleWindowSizeChange() {
        let dataWidth = window.innerWidth

        setWidth(dataWidth)
        setIsMobile(getIsMobile(dataWidth))
        setIsTablet(getIsTablet(dataWidth))
    }
    useEffect(() => {
        if((window.location.host).indexOf("web.gutai.training") !== -1) {
            //Google tag manager
            const tagManagerArgs = {
                gtmId: process.env.REACT_APP_GOOGLE_TAG_KEY
            }
            TagManager.initialize(tagManagerArgs)
        }

        window.addEventListener('resize', handleWindowSizeChange)

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        };
    },[])

    function getLoader () {
        return (
            <Loader
                type="TailSpin"
                color="#0069d9"
                height={50}
                width={50}
                className="my-5"
            />
        )
    }

    const submitFirebaseToken = (firebaseToken) => {
        let headers = generateHeaders()
        if(headers){
            axios
                .put(
                    config+"api/my_profile/firebase_token",
                    {
                        firebase_token: firebaseToken
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    console.log('firebaseToken updated')
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Enregistrer le token sur le bon utilisateur
    useEffect(() => {
        const initFCM = async () => {
            try {
                if (!messaging.unsupported && token) {
                    await messaging.requestPermission();
                    const FCMtoken = await messaging.getToken();
                    submitFirebaseToken(FCMtoken);
                }
            } catch (error) {
                console.log("Unable to get permission to notify.", error);
            }
        };

        initFCM();
    }, [token]);


    const installPrompt = useRef(null);
    const [loadingData, setLoadingData] = useState(false)

    const [installButton, setInstallButton] = useState(false)
    const installApp = async () =>{
        if(!installPrompt) return false;
        installPrompt.prompt();
        let outcome = await this.installPrompt.userChoice;
        if(outcome.outcome==='accepted'){
            console.log("App Installed")
        }
        else{
            console.log("App not installed");
        }
        // Remove the event reference
        installPrompt.current.value=null;
        // Hide the button
        setInstallButton(false)
    }


    // Récuperer des informations sur l'utilisateur connecté
    // - langue
    // - métrique
    // - autres informations de l'utilisateur
    const getUserLoggedData = async () => {
        await axios.get(
            config+"api/my_profile",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((res) => {
            let user = res.data
            // Langue
            languageContextValue.updateLanguage(convertLocaleToLanguage(user.locale))
            // Métrique
            metricContextValue.updateMetric(user.metric)

            let showRunningCriticalIntensity = user.show_running_critical_intensity ? user.show_running_critical_intensity : 'pace'
            metricContextValue.updateRunningCriticalIntensityStatistic(showRunningCriticalIntensity)

            // Donnée utilisateurs
            setLoggedInContextValue({
                enabled: true,
                avatar: user.avatar,
                user_id: user.id,
                username: user.email,
                coach_id: user.coach_id,
                firstname: user.firstname,
                is_subscribed: (!user.is_aito || user.is_subscribed ? true : false),
                is_premium: user.is_premium,
                is_training_20: user.is_training_20,
                has_user_training_program: user.has_user_training_program,
                is_coaching_smart: user.is_coaching_smart,
                is_chat_limited: user.is_chat_limited,
                is_coaching_pro: user.is_coaching_pro,
                talkjs_user_id: user.talkjs_user_id,
                remaining_coach_message_number: user.remaining_coach_message_number,
                firebase_token: user.firebase_token,
                has_finalized_registration: user.has_finalized_registration,
                gender: user.gender,
                has_unread_notifications : user.has_unread_notifications,
                is_beta_tester : user.is_beta_tester
            })

            let splashScreenSettings = user.splash_screen_settings
            let ishowSyncProvider = false
            let ishowSubscribeAutonom = false
            let ishowPlanWorkoutTemplate = false
            for(let splashScreenSetting of splashScreenSettings) {
                switch (splashScreenSetting.type) {
                    case "sync_provider":
                        splashScreenSettingContextValue.updateSplashScreenSettingSyncProvider(splashScreenSetting)
                        if(splashScreenSetting.state === 'show') {
                            ishowSyncProvider = true
                        }
                        break
                    case "subscribe_autonom":
                        splashScreenSettingContextValue.updateSplashScreenSettingSubscribeAutonom(splashScreenSetting)
                        if(splashScreenSetting.state === 'show') {
                            ishowSubscribeAutonom = true
                            ishowSubscribeAutonom = false // TODO : à effacer pour activer la fonctionnalité
                        }
                        break
                    case "plan_workout_template":
                        splashScreenSettingContextValue.updateSplashScreenSettingPlanWorkoutTemplate(splashScreenSetting)
                        if(splashScreenSetting.state === 'show') {
                            ishowPlanWorkoutTemplate = true
                            ishowPlanWorkoutTemplate = false// TODO : à effacer pour activer la fonctionnalité
                        }
                        break
                }
            }
            splashScreenSettingContextValue.updateShowSyncProvider(ishowSyncProvider)
            splashScreenSettingContextValue.updateShowSubscribeAutonom(ishowSubscribeAutonom)
            splashScreenSettingContextValue.updateShowPlanWorkoutTemplate(ishowPlanWorkoutTemplate)

            // Préférence Sport
            let disciplineCode = user.sports && user.sports.length > 0 && user.sports[0].discipline ? user.sports[0].discipline.code : null
            let pSport = disciplineCode && ["cap", "nat", "velo"].indexOf(disciplineCode) !== -1 ? disciplineCode : null
            prioritySportContextValue.updatePrioritySport(pSport)

            if (user.sports && user.sports.length > 0 && user.sports[0].discipline) {
                let userSports = []
                for (let userSport of user.sports) {
                    userSports.push(userSport.discipline.code)
                }
                prioritySportContextValue.updatePreferedSports(userSports)
            }

            garminTrainingContextValue.updateGarminTrainingSynced(user.is_garmin_training_synced)


            if(enableLoader()) {
                setLoadingData(false)
            }
        })
            .catch(error => {
                if(error.response == undefined || error.response.status === 503) {
                    setLoggedInContextValue({
                        maintenance: true
                    })
                }
                else {
                    setLoggedInContextValue({
                        maintenance: false,
                        enabled: false
                    })
                }
                handleEndpointError(error)
                setLoadingData(false)
            })
    }


    // Récupérer les réglages des concepts d'entraînements
    const getTrainingConceptConfigs = async () => {
        await axios.get(
            config + "api/training_concept_configs",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            const trainingConceptConfigs = response.data.training_concept_configs
            for(let trainingConceptConfig of trainingConceptConfigs) {

                let trainingConceptConfigValue =  {
                    id : trainingConceptConfig.id,
                    training_concept_code: trainingConceptConfig.training_concept.code
                }

                switch(trainingConceptConfig.context.code) {
                    case ContextCodes.SWIMMING_NO_POOL:
                        trainingConceptConfigContextValue.updateSwimmingNoPool(trainingConceptConfigValue)
                        break
                    case ContextCodes.SWIMMING_POOL_25:
                        trainingConceptConfigContextValue.updateSwimmingPool25(trainingConceptConfigValue)
                        break
                    case ContextCodes.SWIMMING_POOL_33:
                        trainingConceptConfigContextValue.updateSwimmingPool33(trainingConceptConfigValue)
                        break
                    case ContextCodes.SWIMMING_POOL_50:
                        trainingConceptConfigContextValue.updateSwimmingPool50(trainingConceptConfigValue)
                        break
                    case ContextCodes.RUNNING_INDOOR:
                        trainingConceptConfigContextValue.updateRunningIndoor(trainingConceptConfigValue)
                        break
                    case ContextCodes.RUNNING_OUTDOOR:
                        trainingConceptConfigContextValue.updateRunningOutdoor(trainingConceptConfigValue)
                        break
                    case ContextCodes.CYCLING_INDOOR:
                        trainingConceptConfigContextValue.updateCyclingIndoor(trainingConceptConfigValue)
                        break
                    case ContextCodes.CYCLING_OUTDOOR:
                        trainingConceptConfigContextValue.updateCyclingOutdoor(trainingConceptConfigValue)
                        break
                    case ContextCodes.VIRTUAL_CYCLING:
                        trainingConceptConfigContextValue.updateCyclingVirtualCycling(trainingConceptConfigValue)
                        break
                    case ContextCodes.VTT:
                        trainingConceptConfigContextValue.updateCyclingVTT(trainingConceptConfigValue)
                        break
                }
            }
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    useEffect(() => {
        if (navigator.appCodeName === "Safari") {
            setIsSafari(true)
        }
    },[]);


    // Répercuter le changement de la langue utilisateur
    useEffect(() => {
        if(language) {
            registerLanguage(language)
        }
    }, [language]);



    useEffect(() => {
        if (token) {
            if(enableLoader()) {
                setLoadingData(true)
            }
            getUserLoggedData()
            getTrainingConceptConfigs()
        }
        else {
            const lang = ["fr", "fr-FR"].indexOf(navigator.language) !== -1 ? "fr" : "en"
            languageContextValue.updateLanguage(lang)
        }
    },[token]);

    const enableLoader = () => {
        let url = window.location.pathname
        let enable = url.indexOf("/public/subscription") === -1 ? true : false
        return  enable
    }

    return (
        <CacheBuster>
            <div className="App">
                {loadingData ? getLoader()
                    :
                    <Suspense fallback={getLoader()}>
                        <Conditional condition={installButton}>
                            <Button variant="primary" onClick={installApp}>Install As Application</Button>
                        </Conditional>
                        <Col className="app-version color-grey-light"> v{appVersion}</Col>
                        <NavigationContext.Provider value={navigationContextValue}>
                            <AuthContext.Provider value={authContextValue}>
                                <LanguageContext.Provider value={languageContextValue}>
                                    <BrowserRouter>
                                        <Switch>
                                            <Route exact path="/" component={Home}/>
                                            <Route path="/home" component={Home}/>
                                            <Route path="/login" render={props => (
                                                <LoginPage {...props}
                                                />
                                            )}
                                            />
                                            <Route path="/onboarding" render={props => (
                                                <Onboarding {...props}
                                                />
                                            )}
                                            />
                                            <Route path="/magic_login" render={props => (
                                                <MagicLoginPage {...props}
                                                />
                                            )}
                                            />
                                            <Route exact path="/user_disabled" component={UserDisabled}/>
                                            <Route path="/logout" render={props => (
                                                <Logout {...props}
                                                />
                                            )}
                                            />
                                            <Route path="/registration" render={props => (
                                                <Registration {...props}/>
                                            )}
                                            />
                                            <Route path="/reset_password" render={props => (
                                                <ForgotPassword {...props}/>
                                            )}
                                            />
                                            <Route path="/resetting_password/:confirmationToken" render={props => (
                                                <ResetPassword {...props}/>
                                            )}
                                            />
                                            <Route path="/public/come_back_soon" render={props => (
                                                <ComeBackSoon {...props}/>
                                            )}
                                            />
                                            <LoggedInContext.Provider value={loggedInContextValue}>
                                                <SplashScreenSettingContext.Provider value={splashScreenSettingContextValue}>
                                                    <PrioritySportContext.Provider value={prioritySportContextValue}>
                                                        <MetricContext.Provider value={metricContextValue}>
                                                            <WeekCalendarContext.Provider value={weekCalendarContextValue}>
                                                                <GarminTrainingContext.Provider value={garminTrainingContextValue}>
                                                                    <TrainingConceptConfigContext.Provider value={trainingConceptConfigContextValue}>
                                                                        <Switch>
                                                                            <Route path="/public/subscription/autonom" render={props =>(
                                                                                <SubscriptionAutonom {...props}/>
                                                                            )}
                                                                            />
                                                                            <Route path="/purchase/:purchaseId/confirmation" render={props =>(
                                                                                <PurchaseConfirmation {...props}/>
                                                                            )}
                                                                            />
                                                                            <Route path="/public/subscription/program/theme/:themeValue" render={props =>(
                                                                                <SubscriptionProgram {...props}/>
                                                                            )}
                                                                            />
                                                                            <Route path="/public/subscription/coaching/:productCodeValue" render={props =>(
                                                                                <SubscriptionCoaching {...props}/>
                                                                            )}
                                                                            />
                                                                            <Route path="/public/subscription/membership/:coachId" render={props =>(
                                                                                <SubscriptionMembership {...props}/>
                                                                            )}
                                                                            />
                                                                            <Route exact path="/finalize_registration" render={props => (
                                                                                <FinalizeRegistration getUserLoggedData={()=>getUserLoggedData()} {...props}/>
                                                                            )}
                                                                            />
                                                                            <PrivateRoute path="/sponsorship/confirmation"
                                                                                          component={SponsorshipConfirmation}
                                                                            />
                                                                            <PrivateRoute path="/sponsorship/new"
                                                                                          component={NewSponsorship}
                                                                            />
                                                                            <PrivateRoute path="/dashboard"
                                                                                          component={Dashboard}
                                                                            />
                                                                            <PrivateRoute path="/search_workout_template"
                                                                                          component={WorkoutTemplateSearch}
                                                                            />
                                                                            <PrivateRoute path="/activity_sessions/:activitySessionId/laps"
                                                                                          component={ActivitySessionLaps}
                                                                            />
                                                                            <PrivateRoute path="/activity_sessions/:activitySessionId/intensity_chart"
                                                                                          component={ActivitySessionChartLandscapeContainer}
                                                                            />
                                                                            <Route component={PageNotFound} />
                                                                        </Switch>
                                                                    </TrainingConceptConfigContext.Provider>
                                                                </GarminTrainingContext.Provider>
                                                            </WeekCalendarContext.Provider>
                                                        </MetricContext.Provider>
                                                    </PrioritySportContext.Provider>
                                                </SplashScreenSettingContext.Provider>
                                            </LoggedInContext.Provider>
                                        </Switch>
                                    </BrowserRouter>
                                </LanguageContext.Provider>
                            </AuthContext.Provider>
                        </NavigationContext.Provider>
                        {isSafari && <PWAPrompt />}
                    </Suspense>
                }
            </div>
        </CacheBuster>
    );
}

export default App;