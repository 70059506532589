export function TextAbstract(text, length) {
    if (text == null) {
        return "";
    }
    if (text.length <= length) {
        return text;
    }
    text = text.substring(0, length);
    return text + "...";
}

export function time_convert(num) {
    const hours = Math.floor(num / 60);
    let minutes = num % 60;
    if (minutes<10){
        minutes="0"+minutes
    }
    return `${hours}h${minutes}`;
}

export function pad(num) {
    return ("0"+num).slice(-2);
}
export function hhmmss(secs) {
  var minutes = Math.floor(secs / 60);
  secs = secs%60;
  var hours = Math.floor(minutes/60)
  minutes = minutes%60;
  return `${hours}h${pad(minutes)}`;
  // return pad(hours)+":"+pad(minutes)+":"+pad(secs); for old browsers
}

export function round_dec (num) {
    return Math.round(num*10)/10
}

export function secondesToMinutes(num) {
    return Math.floor(num / 60)
}
export function secondesToHHmm(num) {
    return time_convert(Math.ceil(num/60))
}

export function calculPercent(total, part) {
    return Math.floor((part/total)*100)
}

export function secondsToMinPoints(timescale) {
    let minutes = Math.floor(timescale / 60)
    let seconds = timescale - minutes * 60
    return (new Array(2 + 1).join('0') + minutes).slice(-2) + ':' + (new Array(2 + 1).join('0') + seconds).slice(-2)
}

export const associatePercent = (stateNumber) => {
    let str = stateNumber.toString()
    if (str.length>1) {
        str = str.substring(0, 1)
        stateNumber = parseInt(str, 10)
        if (stateNumber === 0) {
            stateNumber = 1
        }
    }
    if (stateNumber === 0) {
        return 0
    }
    if (stateNumber === 1) {
        return 10
    }
    if (stateNumber === 2) {
        return 20
    }
    if (stateNumber === 3) {
        return 30
    }
    if (stateNumber === 4) {
        return 40
    }
    if (stateNumber === 5) {
        return 50
    }
    if (stateNumber === 6) {
        return 60
    }
    if (stateNumber === 7) {
        return 70
    }
    if (stateNumber === 8) {
        return 80
    }
    if (stateNumber === 9) {
        return 90
    }
    if (stateNumber === 10) {
        return 100
    }
}

export const associateNumber = (statePercent) => {
    if (statePercent === 0) {
        return 0
    }
    if (statePercent === 10) {
        return 1
    }
    if (statePercent === 20) {
        return 2
    }
    if (statePercent === 30) {
        return 3
    }
    if (statePercent === 40) {
        return 4
    }
    if (statePercent === 50) {
        return 5
    }
    if (statePercent === 60) {
        return 6
    }
    if (statePercent === 70) {
        return 7
    }
    if (statePercent === 80) {
        return 8
    }
    if (statePercent === 90) {
        return 9
    }
    if (statePercent === 100) {
        return 10
    }
}