import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form} from "react-bootstrap";
import {arrow_left, validate, x_circle} from "../../../../img/img";
import {NavigationContext, WeekCalendarContext} from "../../../../Contexts";
import {getVerboseDate} from "../../../../Functions/DatesCalendar";
import '../../../../Css/Competition.css';
import axios from "axios";
import config from "../../../../api";
import generateHeaders from "../../../../Functions/generateHeaders";
import handleEndpointError from "../../../../Functions/Alert";

// import SelectSportEvent from "../../SelectSportEvent";
import SelectSport from "../../SelectSport";
import SelectSportCompetition from "../../SelectSportCompetition";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {format, formatISO} from "date-fns"

import {dataSports} from "../Utils"
import EditCompetition from "./EditCompetition";
import NewCompetition from "./NewCompetition";

/**
 * Afficher un formulaire de création de compétition
 *
 * @param date
 * @param closeCompetitionModal
 */
const CompetitionModal = ({date, closeCompetitionModal}) => {

    const {t} = useTranslation(['app'])

    const {athleteEventHandled} = useContext(WeekCalendarContext)

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    return (
        <div id="competition-card" className={!isMobile ? "col-xl-4 p-3 mb-4 card-float-dashboard" : undefined}>
            <div id="alert-card-header" className="dashboard-card background-white">
                <div className={"d-flex pct-3 pcx-3 "+ (isMobile ? 'justify-content-start': 'justify-content-between')}>
                    {isMobile &&
                    <img
                        className="icon-24 mcr-2 pointer"
                        src={arrow_left}
                        alt="arrow left icon"
                        onClick={() => closeCompetitionModal()}
                    />
                    }
                    <div className="text-left">
                        <p className="settings-title mb-0">
                            {athleteEventHandled ? t('app:athlete.competition.edit_competition') : t('app:athlete.competition.add_competition')}
                        </p>
                        <p className="gotham-12 color-grey-light">
                            {getVerboseDate(date)}
                        </p>
                    </div>
                    {!isMobile &&
                    <img
                        className="icon-24 pointer right"
                        src={x_circle}
                        alt="close icon"
                        onClick={() => closeCompetitionModal()}
                    />
                    }
                </div>
                {athleteEventHandled ?
                    <EditCompetition closeCompetitionModal={closeCompetitionModal}/>
                    :
                    <NewCompetition date={date} closeCompetitionModal={closeCompetitionModal}/>
                }
            </div>
        </div>
    )
}
export default CompetitionModal;