export const ContextCodes = {
    SWIMMING_POOL_25: 'swimming-pool-25',
    SWIMMING_POOL_33: 'swimming-pool-33',
    SWIMMING_POOL_50: 'swimming-pool-50',
    SWIMMING_NO_POOL: 'swimming-no-pool',
    RUNNING_INDOOR: 'running-indoor',
    RUNNING_OUTDOOR: 'running-outdoor',
    CYCLING_INDOOR: 'cycling-indoor',
    CYCLING_OUTDOOR: 'cycling-outdoor',
    VIRTUAL_CYCLING: 'virtual-cycling',
    VTT: 'vtt'
}

export const getContextNameByCode  = (t, code) => {
    return t('app.context_short.'+code)
}

export const getDataContextsByDiscipline = (t, disciplineCode) => {
    let datas = []
    let contextsCodes = getContextsByDiscipline(disciplineCode)
    for (const contextCode of contextsCodes) {
        datas.push({
            code: contextCode,
            name: getContextNameByCode(t, contextCode)
        })
    }
    return datas
}

export const getDisciplineByContext = (contextCode) => {
    let discipline
    switch(contextCode) {
        case ContextCodes.SWIMMING_POOL_25:
        case ContextCodes.SWIMMING_POOL_33:
        case ContextCodes.SWIMMING_POOL_50:
        case ContextCodes.SWIMMING_NO_POOL:
            discipline = 'nat'
            break
        case ContextCodes.RUNNING_INDOOR:
        case ContextCodes.RUNNING_OUTDOOR:
            discipline = 'cap'
            break
        case ContextCodes.CYCLING_INDOOR:
        case ContextCodes.CYCLING_OUTDOOR:
        case ContextCodes.VIRTUAL_CYCLING:
        case ContextCodes.VTT:
            discipline = 'velo'
            break
        default:
            break
    }

    return discipline
}

export const getContextsByDiscipline = (disciplineCode) => {
    let datas = []
    switch(disciplineCode) {
        case 'nat':
            datas = [
                ContextCodes.SWIMMING_POOL_25,
                ContextCodes.SWIMMING_POOL_33,
                ContextCodes.SWIMMING_POOL_50,
                ContextCodes.SWIMMING_NO_POOL
            ]
            break
        case 'velo':
            datas = [
                ContextCodes.CYCLING_INDOOR,
                ContextCodes.CYCLING_OUTDOOR,
                ContextCodes.VIRTUAL_CYCLING,
                ContextCodes.VTT
            ]
            break
        case 'cap':
            datas = [
                ContextCodes.RUNNING_INDOOR,
                ContextCodes.RUNNING_OUTDOOR
            ]
            break
        default:
            break
    }

    return datas
}

export const getTrainingConceptByContextCode = (contextCode, trainingContextConfigValue) => {
    let trainingConcept = null
    switch(contextCode) {
        case ContextCodes.SWIMMING_POOL_25:
            trainingConcept = trainingContextConfigValue.swimmingPool25
            break
        case ContextCodes.SWIMMING_POOL_33:
            trainingConcept = trainingContextConfigValue.swimmingPool33
            break
        case ContextCodes.SWIMMING_POOL_50:
            trainingConcept = trainingContextConfigValue.swimmingPool50
            break
        case ContextCodes.SWIMMING_NO_POOL:
            trainingConcept = trainingContextConfigValue.swimmingNoPool
            break
        case ContextCodes.RUNNING_INDOOR:
            trainingConcept = trainingContextConfigValue.runningIndoor
            break
        case ContextCodes.RUNNING_OUTDOOR:
            trainingConcept = trainingContextConfigValue.runningOutdoor
            break
        case ContextCodes.CYCLING_INDOOR:
            trainingConcept = trainingContextConfigValue.cyclingIndoor
            break
        case ContextCodes.CYCLING_OUTDOOR:
            trainingConcept = trainingContextConfigValue.cyclingOutdoor
            break
        case ContextCodes.VIRTUAL_CYCLING:
            trainingConcept = trainingContextConfigValue.cyclingVirtualCycling
            break
        case ContextCodes.VTT:
            trainingConcept = trainingContextConfigValue.cyclingVTT
            break
        default:
            break
    }

    return trainingConcept
}