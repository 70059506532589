import React, {useState, useEffect, useContext} from 'react';
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../Tools/CustomHooks";
import ReactTooltip from 'react-tooltip';
import {scroller, Element as ScrollerElement } from "react-scroll";
import {animations} from 'react-animation'
import axios from "axios";
import config from "../../api";
import '../../Css/Settings.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from '../../Functions/Alert';
import {MetricValues} from "../../Functions/Metrics";

import {
    MetricContext,
    GarminTrainingContext,
    DashboardContext,
    NavigationContext,
    SplashScreenSettingContext,
} from "../../Contexts"


import PreferenceGarminConnect from "./PreferenceGarminConnect"
import PreferenceGarminTraining from "./PreferenceGarminTraining"
import PreferencePolar from "./PreferencePolar"
import PreferenceSuunto from "./PreferenceSuunto"
import PreferenceSuuntoCloud from "./PreferenceSuuntoCloud"
import PreferenceWahoo from "./PreferenceWahoo"
import PreferenceZwift from "./PreferenceZwift"
import PreferenceZwiftModal from "./PreferenceZwiftModal";

import TimeZoneSelect from "./TimeZoneSelect";
import TrainingConceptManager from "./TrainingConceptManager";


function Preferences() {

    const {t} = useTranslation(['app'])
    const {isMobile} = useContext(NavigationContext)

    const isMounted = useIsMounted()

    const {metric, updateMetric, updateRunningCriticalIntensityStatistic} = useContext(MetricContext)
    const {updateGarminTrainingSynced} = useContext(GarminTrainingContext)
    const {settingsAction, updateSettingsAction} = useContext(DashboardContext)
    const {updateShowSyncProvider, updateSplashScreenSettingSyncProvider} = useContext(SplashScreenSettingContext)


    // Réglage - Autorisation de notification
    const [isNotificationToggled, setIsNotificationToggled] = useState(false)
    const [isNotificationOpen, setIsNotificationOpen] = useState(false)

    // pour gestion de l'animation sur le paneau de synchronisation
    const [synchronizationPanelStyle, setSynchronizationPanelStyle] = useState({})
    const [isSynchronizationOpen, setIsSynchronizationOpen] = useState(false)
    const [isMetricOpen, setIsMetricOpen] = useState(false)
    const [isTimeZoneOpen, setIsTimeZoneOpen] = useState(false)
    const [isTrainingConceptOpen, setIsTrainingConceptOpen] = useState(false)

    const [isGarminConnectSynced, setIsGarminConnectSynced] = useState(false)
    const [isGarminTrainingSynced, setIsGarminTrainingSynced] = useState(false)
    const [isPolarSynced, setIsPolarSynced] = useState(false)
    const [isSuuntoSynced, setIsSuuntoSynced] = useState(false)
    const [isSuuntoCloudSynced, setIsSuuntoCloudSynced] = useState(false)
    const [isWahooSynced, setIsWahooSynced] = useState(false)

    // Etat d'ouverture de la popup ZWIFT
    const [zwiftOpen, setZwiftOpen] = useState(null)
    // Etat intermédiaire pour stocker les informations à utiliser pour la requête de connexion à ZWIFT
    const [zwiftCredentials, setZwiftCredentials] = useState(null)
    const [isZwiftSynced, setIsZwiftSynced] = useState(false)
    const [zwiftStatus, setZwiftStatus] = useState(null)
    const [timeZone, setTimeZone] = useState('')


    // Récupérer les informations utiles de l'utilisateur
    useEffect( () => {
        getMyProfile('init')
    }, []);

    useEffect(() => {
        switch(settingsAction) {
            case 'show_sync_provider':
                setIsSynchronizationOpen(true)
                // Scroll down
                scroller.scrollTo('synchronizationPanelElement', {
                    duration: 500,
                    delay: 500,
                    smooth: 'easeOutQuart',
                    offset: 200
                })
                // Bounce
                setSynchronizationPanelStyle({animation: `${animations.bounceIn}`, animationDelay: "0s"})
                const timer = setTimeout(() => updateSettingsAction(null), 2500);
                return () => clearTimeout(timer);

                break;
            default:
                setSynchronizationPanelStyle({})
                break;
        }
    }, [settingsAction])


    useEffect(() => {
        setTimeout(()=> {
            getMyProfile('sync_provider')
        }, 1000)
    },[isGarminConnectSynced, isGarminTrainingSynced, isPolarSynced, isZwiftSynced, isSuuntoSynced, isSuuntoCloudSynced, isWahooSynced])


    const getMyProfile = async (action = 'init') => {
        await axios.get(
            config+"api/my_profile",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((res) => {
            if(!isMounted.current){
                return 0;
            }
            let data = res.data
            switch(action) {
                case 'init':
                    updateGarminTrainingSynced(data.is_garmin_training_synced)

                    setIsNotificationToggled(data.notify_me)

                    setIsGarminConnectSynced(data.is_garmin_connect_synced)
                    setIsGarminTrainingSynced(data.is_garmin_training_synced)
                    setIsPolarSynced(data.is_polar_synced)
                    setIsSuuntoSynced(data.is_suunto_synced)
                    setIsSuuntoCloudSynced(data.is_suunto_cloud_synced)
                    setIsWahooSynced(data.is_wahoo_synced)
                    setIsZwiftSynced(data.is_zwift_synced)
                    setZwiftStatus(data.zwift_status)
                    setTimeZone(data.timezone)
                    break
                case 'sync_provider':
                    let splashScreenSettings = data.splash_screen_settings
                    let ishowSyncProvider = false
                    for(let splashScreenSetting of splashScreenSettings) {
                        if(splashScreenSetting.type == 'sync_provider') {
                            updateSplashScreenSettingSyncProvider(splashScreenSetting)
                            if(splashScreenSetting.state == 'show') {
                                ishowSyncProvider = true
                            }
                        }
                    }
                    updateShowSyncProvider(ishowSyncProvider)
                    break
                default:
                    break
            }

        }).catch(error => {
            handleEndpointError(error)
        })
    }

    // Gérer le formulaire de changement du réglage "Notification"
    const onToggleNotification = () => {
        if(isNotificationToggled === false) {
            submitNotifyMe(true)
        }
        else {
            submitNotifyMe(false)
        }
    }

    // Enregistrer la valeur du réglage "notification autorisée"
    const submitNotifyMe = (notify) => {
        let headers = generateHeaders()
        if(headers){
            axios
                .put(
                    config+"api/my_profile",
                    {
                        notify_me: notify
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                ).then(response => {
                    setIsNotificationToggled(notify);
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Gérer le formulaire de changement du réglage "Métrique"
    const handleCheck = (event) => {
        const targetValue = event.target.value
        let metricValue = null;
        switch(targetValue){
            case 'international':
                metricValue = MetricValues.international
                break
            case 'imperial':
                metricValue = MetricValues.imperial
                break
            default:
                break
        }
        submitMetric(metricValue)
    }

    // Enregistrer la valeur du réglage "Métrique"
    const submitMetric = async (metricValue) => {
        let headers = generateHeaders()
        if(headers){
            await axios
                .put(
                    config+"api/my_profile",
                    {
                        metric: metricValue
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                ).then(response => {
                // Utilisation du contexte pour mettre à jour la valeur du réglage Métrique
                updateMetric(metricValue)
            })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Enregistrer la valeur du réglage "Affichage de l'intensité crititique pour la course à pied"
    const submitRunningCIStatistic = (runningCIStatisticValue) => {
        updateRunningCriticalIntensityStatistic(runningCIStatisticValue)
    }

    const onChangeTimeZone = (value) => {
        setTimeZone(value)
    }
    const updateIsGarminConnectSynced = (value) => {
        setIsGarminConnectSynced(value)
    }
    const updateIsGarminTrainingSynced = (value) => {
        setIsGarminTrainingSynced(value)
        updateGarminTrainingSynced(value)
    }
    const updateIsPolarSynced = (value) => {
        setIsPolarSynced(value)
    }
    const updateIsSuuntoSynced = (value) => {
        setIsSuuntoSynced(value)
    }
    const updateIsSuuntoCloudSynced = (value) => {
        setIsSuuntoCloudSynced(value)
    }

    const updateIsWahooSynced = (value) => {
        setIsWahooSynced(value)
    }

    const updateIsZwiftSynced = (value) => {
        setIsZwiftSynced(value)
    }

    const updateZwiftModal = (action) => {
        switch(action) {
            case 'open' :
                setZwiftOpen(true)
                setZwiftCredentials(null)
                break
            case 'close':
                setZwiftOpen(false)
                setZwiftCredentials(null)
                break
            default:
                break
        }
    }

    return (
        <>
            {zwiftOpen &&
            <PreferenceZwiftModal
                setZwiftCredentials={(zwiftCredentials) => setZwiftCredentials(zwiftCredentials)}
                updateZwiftModal={(action) => updateZwiftModal(action)}
            />
            }
            <div className={(zwiftOpen ? "overlay-content pct-3 m-0": "pct-3 pcb-3" )}>
            <div className={"zs-tab-card pcy-3 mcb-3 pcx-3"}>
                <div className={isMobile ?"monitoring-title2 fs-14":"monitoring-title2"}
                     data-tip={""}
                     data-for="notificationsHelper"
                >
                    {t('app:settings.preferences.personal_info')}

                </div>
                <ReactTooltip key="tooltip-notifications"
                              id="notificationsHelper"
                              type="light"
                              multiline={true}
                              textColor="#000000"
                              backgroundColor="#F5F5F5"
                              className="tooltip"
                              effect="solid"
                              place="bottom"
                />
                <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                      onClick={() => setIsNotificationOpen(isNotificationOpen => !isNotificationOpen)}
                >
                <i className={isNotificationOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
            </span>
                {isNotificationOpen &&
                    <>
                    <div className="grey-line-2 mcy-3 pcx-3"></div>
                    <div className="col-12 pcy-2">
                        <div className="w-100 d-inline-flex align-items-center justify-content-between show-input-settings mcb-3">
                            <div className="show-input-settingsNotif" style={{height:'56px',lineHeight:'10px'}}>
                                <p className="w-100" style={{paddingTop:'8px'}}>{t('app:settings.preferences.notifications')}</p>
                                <p className="w-100" style={{fontSize:'12px',color:'#B5B5B5'}}>{t('app:settings.preferences.NotifOn')}</p>
                            </div>
                            <span className="toggle-switch">
                            <input type="checkbox" checked={isNotificationToggled} onChange={() => onToggleNotification()} />
                            <span className="switch" />
                        </span>
                        </div>
                    </div>
                    </>
                }
            </div>
                <div className={"zs-tab-card pcy-3 mcb-3 pcx-3"}>
                    <div className={isMobile ?"monitoring-title2 fs-14":"monitoring-title2"}
                         data-tip={""}
                         data-for="synchronizationHelper"
                    >
                            {t('app:settings.preferences.synchronization')}
                        {/*{isSynchronizationOpen &&
                        <img
                            data-tip=""
                            data-for="metricHelper"
                            data-event='click'
                            src={helpCircle}
                            className="ml-3 icon-24"
                            alt="help icon"
                        />
                        }*/}
                    </div>
                    <ReactTooltip key="tooltip-synchronization"
                                  id="synchronizationHelper"
                                  type="light"
                                  multiline={true}
                                  textColor="#000000"
                                  backgroundColor="#F5F5F5"
                                  className="tooltip"
                                  effect="solid"
                                  place="bottom"
                    />
                    <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                          onClick={() => setIsSynchronizationOpen(isSynchronizationOpen => !isSynchronizationOpen)}
                    >
                <i className={isSynchronizationOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
            </span>
                    <ScrollerElement name="synchronizationPanelElement"
                                     className="w-100 text-left"
                    >
                    {isSynchronizationOpen &&
                        <>
                        <div className="grey-line-2 mcy-3 pcx-3"></div>
                            <div className="col-12 pcy-2"
                                 style={synchronizationPanelStyle}
                            >
                                <PreferenceGarminConnect isGarminConnectSynced={isGarminConnectSynced}
                                                         updateIsGarminConnectSynced={(value) => updateIsGarminConnectSynced(value)}
                                />
                                <PreferenceGarminTraining isGarminTrainingSynced={isGarminTrainingSynced}
                                                          updateIsGarminTrainingSynced={(value) => updateIsGarminTrainingSynced(value)}
                                />
                                <PreferencePolar isPolarSynced={isPolarSynced}
                                                 updateIsPolarSynced={(value) => updateIsPolarSynced(value)}
                                />
                                <PreferenceSuunto isSuuntoSynced={isSuuntoSynced}
                                                  updateIsSuuntoSynced={(value) => updateIsSuuntoSynced(value)}
                                />
                                <PreferenceSuuntoCloud isSuuntoCloudSynced={isSuuntoCloudSynced}
                                                       updateIsSuuntoCloudSynced={(value) => updateIsSuuntoCloudSynced(value)}
                                />
                                <PreferenceZwift isZwiftSynced={isZwiftSynced}
                                                 zwiftStatus={zwiftStatus}
                                                 updateIsZwiftSynced={(value) => updateIsZwiftSynced(value)}
                                                 updateZwiftModal={(action) => updateZwiftModal(action)}
                                                 zwiftCredentials={zwiftCredentials}
                                />
                                <PreferenceWahoo isWahooSynced={isWahooSynced}
                                                 updateIsWahooSynced={(value) => updateIsWahooSynced(value)}
                                />
                            </div>
                        </>
                    }
                    </ScrollerElement>
                </div>
                <div className={"zs-tab-card pcy-3 mcb-3 pcx-3 "}>
                    <div className={isMobile ?"monitoring-title2 fs-14":"monitoring-title2"}
                         data-tip={""}
                         data-for="metricContentHelper"
                    >
                        {t('app:app.profile.preferences.metric')}
                        {/*{isMetricOpen &&
                        <img
                            data-tip="International: °C, kg, m, W, km/h<br />
                        Imperial (UK): °F, lbs, ft, yds, W, mph"
                            data-for="metricHelper"
                            data-event='click'
                            src={helpCircle}
                            className="ml-3 icon-24"
                            alt="help icon"
                        />
                        }*/}
                    </div>
                    <ReactTooltip key="tooltip-metric"
                                  id="metricContentHelper"
                                  type="light"
                                  multiline={true}
                                  textColor="#000000"
                                  backgroundColor="#F5F5F5"
                                  className="tooltip"
                                  effect="solid"
                                  place="bottom"
                    />
                    <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                          onClick={() => setIsMetricOpen(isMetricOpen => !isMetricOpen)}
                    >
                <i className={isMetricOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
            </span>
                    {isMetricOpen &&
                        <>
                            <div className="grey-line-2 mcy-3 pcx-3"></div>
                        {/*<ReactTooltip key="tooltip-metric"
                                      id="metricHelper"
                                      type="light"
                                      multiline={true}
                                      textColor="#000000"
                                      backgroundColor="#F5F5F5"
                                      className="tooltip"
                                      effect="solid"
                                      place="bottom"
                        />*/}
                        <div className="d-flex align-content-center col-12 pcy-2">
                            <div className="checkbox-container mb-2" style={{width: '100%'}}>
                                <input
                                    id="metric_si"
                                    type="radio"
                                    name="metric"
                                    value="international"
                                    checked={metric === MetricValues.international}
                                    onChange={event => handleCheck(event)}
                                />
                                <label htmlFor="metric_si">{t('app:app.metric.si')}</label>
                            </div>
                            <div className="checkbox-container mb-2 col-7">
                                <input
                                    id="metric_uk"
                                    type="radio"
                                    name="metric"
                                    value="imperial"
                                    checked={metric === MetricValues.imperial}
                                    onChange={event => handleCheck(event)}
                                />
                                <label htmlFor="metric_uk">{t('app:app.metric.uk')}</label>
                            </div>
                        </div>
                        </>
                    }
                </div>
                <div className={"zs-tab-card pcy-3 mcb-3 pcx-3 "}>
                    <div className={isMobile ?"monitoring-title2 fs-14":"monitoring-title2"}
                         data-tip={""}
                         data-for="timeZoneHelper"
                    >
                        {t('app:app.profile.preferences.time_zone')}
                        {/*{isTimeZoneOpen &&
                        <img
                            data-tip=""
                            data-for="metricHelper"
                            data-event='click'
                            src={helpCircle}
                            className="ml-3 icon-24"
                            alt="help icon"
                        />
                        }*/}
                    </div>
                    <ReactTooltip key="tooltip-time-zone"
                                  id="timeZoneHelper"
                                  type="light"
                                  multiline={true}
                                  textColor="#000000"
                                  backgroundColor="#F5F5F5"
                                  className="tooltip"
                                  effect="solid"
                                  place="bottom"
                    />
                    <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                          onClick={() => setIsTimeZoneOpen(isTimeZoneOpen => !isTimeZoneOpen)}
                    >
                <i className={isTimeZoneOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
            </span>
                    {isTimeZoneOpen &&
                        <>
                        <div className="grey-line-2 mcy-3 pcx-3"></div>
                        <div className="col-12 pcy-2">
                            <TimeZoneSelect
                                timeZone={timeZone}
                                onChangeTimeZone={(value) => onChangeTimeZone(value)}
                            />
                        </div>
                        </>
                    }
                </div>
                <div className={"zs-tab-card pcy-3 mcb-3 pcx-3 "}>
                    <div className={isMobile ?"monitoring-title2 fs-14":"monitoring-title2"}
                         data-tip={""}
                         data-for="trainingConceptHelper"
                    >
                        {t('app:settings.preferences.training_concept')}
                        {/*{isTrainingConceptOpen &&
                        <img
                            data-tip=""
                            data-for="metricHelper"
                            data-event='click'
                            src={helpCircle}
                            className="ml-3 icon-24"
                            alt="help icon"
                        />
                        }*/}
                    </div>
                    <ReactTooltip key="tooltip-training-concept"
                                  id="trainingConceptHelper"
                                  type="light"
                                  multiline={true}
                                  textColor="#000000"
                                  backgroundColor="#F5F5F5"
                                  className="tooltip"
                                  effect="solid"
                                  place="bottom"
                    />
                    <span className="cursor-pointer pcx-2 d-flex justify-content-end dropdown-arrow"
                          onClick={() => setIsTrainingConceptOpen(isTrainingConceptOpen => !isTrainingConceptOpen)}
                    >
                <i className={isTrainingConceptOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"}></i>
            </span>
                    {isTrainingConceptOpen &&
                        <>
                        <div className="grey-line-2 mcy-3 pcx-3"></div>
                        <TrainingConceptManager
                            submitRunningCIStatistic={(value) => submitRunningCIStatistic(value)}
                        />
                        </>
                    }
                </div>
            </div>
        </>
    )

}
export default Preferences
