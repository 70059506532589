import React, {useEffect, useState, useContext} from 'react';
import {useTranslation} from "react-i18next";
import '../../Css/Agenda.css';
import {
    velo_unset,
    vtt_unset,
    cap_unset,
    nat_unset,
    other_unset,
} from "../../img/img";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import {round_dec} from "../../Functions/TextMaths"
import handleEndpointError from '../../Functions/Alert';
import {Metrics, MetricValues} from "../../Functions/Metrics";
import {convertSecondsToChrono} from "./Utils"
import {MetricContext} from "../../Contexts";

/**
 * Récapitulatif hebdomadaire
 * - set met à jour en fonction de la date 'activeWeek'
 *
 * @param activeWeek : date de la semaine
 */
const WeekSummary = ({activeWeek, openCloseTrainingSummary}) => {
    const {t} = useTranslation(['app'])

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)

    const metrics = Metrics(metric)

    // Date de la semaine du calendrier
    const weekNumber = activeWeek.format('W')
    const yearNumber = activeWeek.format('GGGG')

    // Données du récapitulatif hebdomadaire
    const [weekSummaryDatas, setWeekSummaryDatas] = useState(null)

    // Valeurs par défaut des données du récapitulatif hebdomadaire (pour une dimension : discipline, autre ou total)
    const initDisciplineData = (disciplineIcon, textColorClass, bgColorClass) => {
        return {
            planned: {
                duration: 0
            },
            realized: {
                duration: 0,
                distance: 0,
                distance_uk: 0,
            },
            percent: 0,
            distanceUnit: metrics.distance_other,
            icon: disciplineIcon,
            textColorClass: textColorClass,
            bgColorClass: bgColorClass
        }
    }

    // Récupérer les données du récapitulatif hebdomadaire
    const getWeekSummary = async () => {
        await axios.get(
            config+"api/calendar/week_summary",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                },
                params: {
                    week_number: weekNumber,
                    year_number: yearNumber
                }
            },
        ).then((res) => {
            let datas = {
                nat: initDisciplineData(nat_unset, "color-nat", "bcolor-nat"),
                velo: initDisciplineData(velo_unset, "color-velo", "bcolor-velo"),
                vtt: initDisciplineData(vtt_unset, "color-vtt", "bcolor-vtt"),
                cap: initDisciplineData(cap_unset, "color-cap", "bcolor-cap"),
                other: initDisciplineData(other_unset, "color-other", "bcolor-other"),
                total : initDisciplineData(null, "color-black", "bcolor-black")
            }
            // Données des exercices planifiés (Nat, Vélo, Course à pied et Total)
            let trainingEventPlannedDatas = res.data.exerciseDatas
            datas.nat.planned.duration = trainingEventPlannedDatas.nat.sec
            datas.velo.planned.duration = trainingEventPlannedDatas.velo.sec
            datas.vtt.planned.duration = 0
            datas.cap.planned.duration = trainingEventPlannedDatas.cap.sec
            // datas.total.planned.duration = trainingEventPlannedDatas.total.sec
            datas.total.planned.duration = trainingEventPlannedDatas.velo.sec + trainingEventPlannedDatas.nat.sec + trainingEventPlannedDatas.cap.sec

            // Données des activités réalisées
            let trainingEventRealizedDatas = res.data.activitySessionDatas
            for (let disciplineCode in trainingEventRealizedDatas) {
                if(['nat', 'velo', 'vtt', 'cap', 'total'].indexOf(disciplineCode) != -1) {
                    let stackedDisciplineCode = disciplineCode
                    // let stackedDisciplineCode = disciplineCode !== 'vtt' ? disciplineCode : 'velo'
                    datas[stackedDisciplineCode].realized.duration = trainingEventRealizedDatas[disciplineCode].sec.value
                    datas[stackedDisciplineCode].realized.distance = trainingEventRealizedDatas[disciplineCode].distance.value
                    datas[stackedDisciplineCode].realized.distance_uk = trainingEventRealizedDatas[disciplineCode].distance_uk.value

                    // Calcul du pourcentage de réalisation par rapport à la planification (limité à 100%)
                    datas[stackedDisciplineCode].percent = datas[stackedDisciplineCode].realized.duration ?
                        (datas[stackedDisciplineCode].planned.duration ? (100 * datas[stackedDisciplineCode].realized.duration/datas[stackedDisciplineCode].planned.duration) : 100)
                        :0
                    if(datas[stackedDisciplineCode].percent > 100) {
                        datas[stackedDisciplineCode].percent = 100
                    }
                    if(disciplineCode=='nat') {
                        datas[stackedDisciplineCode].distanceUnit = metrics.distance_nat
                        datas[stackedDisciplineCode].realized.distance *= 1000
                    }
                }
                // Les autres disciplines sont regroupées dans 'other'
                else {
                    datas['other'].realized.duration += trainingEventRealizedDatas[disciplineCode].sec.value
                    datas['other'].realized.distance += trainingEventRealizedDatas[disciplineCode].distance.value
                    datas['other'].realized.distance_uk += trainingEventRealizedDatas[disciplineCode].distance_uk.value
                    // Calcul du pourcentage de réalisation par rapport à la planification (limité à 100%)
                    datas['other'].percent = datas['other'].realized.duration ?
                        (datas['other'].planned.duration ? (100 * datas['other'].realized.duration / datas['other'].planned.duration) : 100)
                        :0
                    if(datas['other'].percent > 100) {
                        datas['other'].percent = 100
                    }
                }
            }

            setWeekSummaryDatas(datas)

        }).catch(error => {
            handleEndpointError(error)
        })
    }

    // Mettre à jour les données du récapitulatif hebdomadaire
    // - lorsque la semaine du calendrier change
    // - lorsque la metric change
    useEffect(() => {
        getWeekSummary()
    }, [activeWeek, metric])


    // Afficher le widget de récapitualif hebdomadaire pour une discipline en particulier
    const SummaryForDiscipline = ({code}) => {
        let chronoFormat = 'H[h]mm'
        // Données du récapitulatif hebdomadaire pour la discipline
        let weekSummaryData = weekSummaryDatas[code]
        if(weekSummaryData.planned.duration || weekSummaryData.realized.duration)
        {
            return (
                <div className={`col-xs-1 my-3 d-flex justify-content-center flex-column${code === "total" ? "" : " mcr-4"}`}>
                    <div className={(weekSummaryData.icon == null ? "footer-total ":"") + "mb-2 d-flex justify-content-center align-items-center"}>
                        {weekSummaryData.icon !== null ?
                            <img src={weekSummaryData.icon} alt="icon" className="my-auto icon-footer" />
                            :
                            <p className="footer-total-text mb-0">{t('app.total')}</p>
                        }
                    </div>
                    <div className="empty-total align-self-center">
                        <div className={"done-total "+weekSummaryData.bgColorClass} style={{width: (weekSummaryData.percent+ '%'), borderRadius: '8px'}}/>
                    </div>
                    <p className={"text-done-total mb-1 mct-2 " +weekSummaryData.textColorClass}>{convertSecondsToChrono(weekSummaryData.realized.duration, chronoFormat)}
                        /<span className="planned-duration">{convertSecondsToChrono(weekSummaryData.planned.duration, chronoFormat)}</span>
                    </p>
                    <p className={"text-done-total my-0 " +weekSummaryData.textColorClass}>{round_dec(metric == MetricValues.international ? weekSummaryData.realized.distance : weekSummaryData.realized.distance_uk) + "" + weekSummaryData.distanceUnit}</p>
                </div>
            )
        }
        // Ne rien afficher s'il n'y aucune séance/activité réalisée pour cette discipline
        return <></>
    }

    return (weekSummaryDatas !== null ?
        <div className="pcy-3 bg-white calendar-footer-radius">
            <div className="agenda-footer justify-content-center">
                <div className="week-calendar-header fs-15">
                    {t('app.calendar.training_summary.header')}
                </div>
                <div className="d-flex justify-content-center w-100">
                    <SummaryForDiscipline
                        code={'nat'}
                    />
                    <SummaryForDiscipline
                        code={'velo'}
                    />
                    <SummaryForDiscipline
                        code={'vtt'}
                    />
                    <SummaryForDiscipline
                        code={'cap'}
                    />
                    <SummaryForDiscipline
                        code={'other'}
                    />
                    <SummaryForDiscipline
                        code={'total'}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-center mct-2">
                <div className="btn-white-blue fs-12" onClick={openCloseTrainingSummary}>
                    {t('app.calendar.more_stats')}
                </div>
            </div>
        </div>
        :
        <></>
    )
}
export default WeekSummary