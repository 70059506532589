import React, {useContext, useEffect, useState} from 'react';
import {NavigationContext} from "../../Contexts";
import {useTranslation} from "react-i18next";
import WorkoutTemplateDisciplineCube from "./WorkoutTemplateDisciplineCube";
import Loader from "react-loader-spinner";
import {DISCIPLINES_WORKOUT_TEMPLATE} from "./Utils";
import {arrow_left, search} from "../../img/img";
import moment from "moment";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";
import {useIsMounted} from "../Tools/CustomHooks";
import WorkoutTemplateTable from "./WorkoutTemplateTable";


const WorkoutTemplateTheme = ({themeId, setThemeId, workoutTemplateLists, isFavorite, isPlanned}) => {
    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()

    const [theme, setTheme] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [workoutTemplates, setWorkoutTemplates] = useState([])
    const [filteredWorkoutTemplates, setFilteredWorkoutTemplates] = useState([])
    const [keyWord, setKeyWord] = useState(null)

    useEffect(() => {
        getTheme()
    }, [])

    useEffect(() => {
        if (workoutTemplates.length>0){
            if (keyWord === ""){
                setFilteredWorkoutTemplates(workoutTemplates)
            } else {
                let newFilteredWorkoutTemplates = workoutTemplates.filter(workoutTemplate =>
                    workoutTemplate.name ? workoutTemplate.name.toLowerCase().includes(keyWord.toLowerCase()) : false
                )
                setFilteredWorkoutTemplates(newFilteredWorkoutTemplates)
            }
        }
    }, [keyWord])

    const getTheme = () => {
        axios
            .get(
                config+"api/workout_template_themes/"+themeId,
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    },
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let datas = response.data
                setTheme(datas.data)
                setWorkoutTemplates(datas.workoutTemplates)
                setFilteredWorkoutTemplates(datas.workoutTemplates)
                setLoaded(true)
            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    const themeHeaderDesktop = () => (
        <div className="w-100 d-flex flex-column workout-template-theme-header-container">
            <div className="w-100 text-left text-gotham fs-13 mcb-4 cursor-pointer" onClick={() => setThemeId(null)}>
                <img src={arrow_left} className="icon-24 mcr-3" alt="arrow left icon"/> {t('athlete.training_load.back_tab')}
            </div>
            <div className="w-100 d-flex">
                <div className="workout-template-theme-header-img"
                     style={{backgroundImage: "linear-gradient(0deg, rgba(5, 18, 29, 0.1), rgba(5, 18, 29, 0.1)), url("+getUrlImg()+")"}}
                />
                <div className="mcl-5 d-flex flex-column col justify-content-center">
                    <div className="text-grey-normal fs-15 text-gotham text-uppercase d-flex align-items-center mcb-3">
                        <WorkoutTemplateDisciplineCube discipline={theme.discipline.code}/> {t('app.disciplines.name.'+theme.discipline.code)}
                    </div>
                    <div className="d-flex align-items-center">
                        <p className="workout-template-theme-header-title mcb-3">
                            {theme.name}
                        </p>
                    </div>
                    <div className="text-grey-normal fs-15 text-gotham mcb-4 text-left">
                        {theme.description ? theme.description : '-' }
                    </div>
                    <div className="text-grey-normal fs-15 text-gotham text-left">
                        {moment(theme.date).format("YYYY")+
                        " • "+theme.count + " " + t('app.workout_template.count.' + (theme.count === 1 ? 'session' : 'sessions'))
                            // +" • "+(theme.like_counter ? theme.like_counter+" "+t('app.likes') : "0 "+t('app.like'))
                        }
                    </div>
                </div>
            </div>
        </div>
    )

    const themeHeaderMobile = () => (
        <div className="w-100 d-flex flex-column workout-template-theme-header-container-mobile pc-4">
            <div className="w-100 text-left text-gotham fs-16 mcb-4 cursor-pointer font-weight-bold" onClick={() => setThemeId(null)}>
                <img src={arrow_left} className="icon-24 mcr-3" alt="arrow left icon"/> {t('app.workout_template.back_to_themes')}
            </div>
            <div className="workout-template-theme-header-img pc-2"
                 style={{backgroundImage: "linear-gradient(0deg, rgba(5, 18, 29, 0.1), rgba(5, 18, 29, 0.1)), url("+getUrlImg()+")"}}
            >
                <WorkoutTemplateDisciplineCube discipline={theme.discipline.code}/>
            </div>
            <div className="mct-4 d-flex flex-column col justify-content-center">
                <div className="d-flex align-items-center">
                    <p className="fs-16 workout-template-theme-header-title mcb-3">
                        {theme.name}
                    </p>
                </div>
                <div className="text-grey-normal fs-12 text-gotham mcb-4 text-left">
                    {theme.description ? theme.description : '-' }
                </div>
                <div className="text-grey-normal fs-12 text-gotham text-left">
                    {moment(theme.date).format("YYYY")+
                    " • "+theme.count + " " + t('app.workout_template.count.' + (theme.count === 1 ? 'session' : 'sessions'))
                        // +" • "+(theme.like_counter ? theme.like_counter+" "+t('app.likes') : "0 "+t('app.like'))
                    }
                </div>
            </div>
        </div>
    )

    const getUrlImg = () => (theme && theme.image_url) ? theme.image_url : DISCIPLINES_WORKOUT_TEMPLATE[theme.discipline.code].default_theme_img_url
    return (
        <div className="w-100">
            {loaded ?
                <>
                    {isMobile ? themeHeaderMobile() : themeHeaderDesktop()}
                    <div className="w-100 d-flex justify-content-center">
                        <div className={`${isMobile ? "w-75" : "w-25"} mcb-5 d-flex`}>
                            <div className="relative-div d-flex search-icon-container">
                                <img className="icon-24" src={search} alt="search icon"/>
                            </div>
                            <input type="text"
                                   className="search-bar-notification w-100"
                                   onChange={(event) => setKeyWord(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="pcx-4">
                        <WorkoutTemplateTable
                            workoutTemplates={filteredWorkoutTemplates}
                            workoutTemplateLists={workoutTemplateLists}
                            isFavorite={(workoutTemplateId) => isFavorite(workoutTemplateId)}
                            isPlanned={(workoutTemplateId) => isPlanned(workoutTemplateId)}
                            loaded={loaded}
                        />
                    </div>
                </>
                :
                <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                    <Loader
                        type="TailSpin"
                        color="black"
                        height={50}
                        width={50}
                        className="my-5"
                    />
                </div>
            }
        </div>
    );
};


export default WorkoutTemplateTheme