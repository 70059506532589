import React, {useEffect, useState, useContext, useRef} from 'react'
import {useTranslation} from "react-i18next";
import Highcharts, { chart } from 'highcharts'
import HC_exporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import ClipLoader from "react-spinners/ClipLoader";
import { useScreenshot } from 'use-react-screenshot'
import '../../../../Css/Intensites.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {Metrics} from "../../../../Functions/Metrics";

import { MetricContext, NavigationContext } from "../../../../Contexts"
import {secondsToChronoForChart} from "../../Utils";
import { useDispatch, useSelector } from 'react-redux';
import { setGraphScreenshot } from '../../../../redux/features/share/shareSlice';
import { Link } from 'react-router-dom';
import { reset as resetMap, setMarkerDuration } from '../../../../redux/features/map/mapSlice';

// Init the highcharts export module
HC_exporting(Highcharts)

function ActivitySessionChart ({
    datasChart,
    discipline,
    xAxis,
    trainingConceptCode,
    isLandscape,
    activitySessionId,
}) {

    const {t} = useTranslation(['app'])

    // métrique utilisateur
    const {metric} = useContext(MetricContext)
    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const metrics = Metrics(metric)

    const speedColor = '#0079ff';
    const altitudeColor = '#4B6E6E';
    const htColor = '#F4184D';
    const powerColor = '#FF0000';
    const cmColor = '#000000';
    const temperatureColor = '#49D5D4';

    const [speedsData, setSpeedsData] = useState([])
    const [heartratesData, setHeartratesData] = useState([])
    const [altitudesData, setAltitudesData] = useState([])
    const [powersData, setPowersData] = useState([])
    const [temperaturesData, setTemperaturesData] = useState([])
    const [cadencesData, setCadencesData] = useState([])

    const [speedOn, setSpeedOn] = useState(false)
    const [altitudeOn, setAltitudeOn] = useState(false)
    const [cadenceOn, setCadenceOn] = useState(false)
    const [powerOn, setPowerOn] = useState(false)
    const [bpmOn, setBpmOn] = useState(false)
    const [temperaturesOn, setTemperaturesOn] = useState(false)

    const [datasEnabled, setDatasEnabled] = useState({
        speed: false,
        allure: false,
        altitude: false,
        cadence: false,
        power: false,
        bpm: false,
        temperatures: false,
    })

    const [loaded, setLoaded] = useState(false)
    const [isChartHovered, setIsChartHovered] = useState(false)
    const [hasChartAlreadyBeenHovered, setHasChartAlreadyBeenHovered] = useState(false)
    const chartRef = useRef(null)
    const [optionsHigh, setOptionsHigh] = useState(null)
    const [chartInitialized, setChartInitialized] = useState(false)

    const [image, takeScreenshot] = useScreenshot()
    const getImage = (ref) => takeScreenshot(ref)
    const shouldTakeScreenshots = useSelector(app => app.share.shouldTakeScreenshots)
    const dispatch = useDispatch()

    const [mainCharacteristic, setMainCharacteristic] = useState(null)
    const [secondaryCharacteristic, setSecondaryCharacteristic] = useState(null)

    useEffect(() => setCharacteristics(), [])

    useEffect(() => {
        if (mainCharacteristic && secondaryCharacteristic) {
            setDatasEnabled(prevData => ({
                ...prevData,
                [mainCharacteristic]: true,
                [secondaryCharacteristic]: true,
            }))
        }
    }, [mainCharacteristic, secondaryCharacteristic])

    useEffect(() => {
        if (shouldTakeScreenshots && chartInitialized) {
            const chartSvg = chartRef.current.chart.getSVG().replace(/&quot;/g,'\'')
            dispatch(setGraphScreenshot(`data:image/svg+xml;utf8,${chartSvg}`))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldTakeScreenshots, chartInitialized])

    const setCharacteristics = () => {
        const { powers, cadences, temperatures, altitudes, heartrates } = datasChart
        setMainCharacteristic(discipline === 'nat' ? 'speed' : trainingConceptCode === 'critical_heartrate' ? 'bpm' : ['cap', 'trail'].includes(discipline) ? 'speed' : 'power')
        if (trainingConceptCode === 'critical_heartrate') {
            setSecondaryCharacteristic(['cap', 'trail'].includes(discipline) ? 'speed' : (powers.length ? 'power' : altitudes.length ? 'altitude' : temperatures.length ? 'temperatures' : cadences.length ? 'cadence' : null))
        } else if (trainingConceptCode === 'critical_intensity') {
            setSecondaryCharacteristic(heartrates.length ? 'bpm' : altitudes.length ? 'altitude' : temperatures.length ? 'temperatures' : cadences.length ? 'cadence' : null)
        }
    }

    const setDatas = () => {
        // let isIndoor = datasChart.isIndoor
        const { speeds, altitudes, temperatures, powers, heartrates, cadences, plotBands: chartPlotBands } = datasChart
        let mainCharacteristicData = []
        for (let i = 0; i < chartPlotBands.length; i++) {
            // Building mainCharacteristic colorized data
            const mainCharacteristicFormatted = mainCharacteristic === 'bpm' ? 'heartrates' : mainCharacteristic !== 'temperatures' ? `${mainCharacteristic}s` : 'temperatures'
            const mainCharacteristicDataFiltered = datasChart[mainCharacteristicFormatted].filter(data => data.x < chartPlotBands[i].to && data.x >= chartPlotBands[i].from)
            if (mainCharacteristicDataFiltered.length) {
                const mainCharacteristicDataFilteredWithColor = mainCharacteristicDataFiltered.map(mcdf => {
                    mcdf.color = chartPlotBands[i].color
                    return mcdf
                })
                mainCharacteristicData = [...mainCharacteristicData, ...mainCharacteristicDataFilteredWithColor]
            }
        }

        let speedOk = false, bpmOk = false, altitudeOk = false, cadenceOk = false, powerOk = false, temperaturesOk = false
        for (let i = 0; i < speeds.length; i++) {
            if (heartrates.length > 0 && heartrates[i] && heartrates[i].y !== null && heartrates[i].y !== 0 && !bpmOk) {
                setBpmOn(true)
                bpmOk = true
            }
            if (altitudes.length > 0 && altitudes[i] && altitudes[i].y !== null && altitudes[i].y !== 0 && !altitudeOk) {
                setAltitudeOn(true)
                altitudeOk = true
            }
            if (cadences.length > 0 && cadences[i] && cadences[i].y !== null && cadences[i].y !== 0 && !cadenceOk) {
                setCadenceOn(true)
                cadenceOk = true
            }
            if (speeds.length > 0 && speeds[i] && speeds[i].y !== null && speeds[i].y !== 0 && !speedOk) {
                setSpeedOn(true)
                speedOk = true
            }
            if (powers.length > 0 && powers[i] && powers[i].y !== null && powers[i].y !== 0 && !powerOk) {
                setPowerOn(true)
                powerOk = true
            }
            if (temperatures.length > 0 && temperatures[i] && temperatures[i].y !== null && temperatures[i].y !== 0 && !temperaturesOk) {
                setTemperaturesOn(true)
                temperaturesOk = true
            }
        }
        if (!bpmOk && secondaryCharacteristic === 'bpm') {
            if (altitudeOk) {
                setSecondaryCharacteristic('altitude')
            } else if (temperaturesOk) {
                setSecondaryCharacteristic('temperature')
            } else if (cadenceOk) {
                setSecondaryCharacteristic('cadence')
            }
        } 

        if (discipline === 'nat') {
            setAltitudeOn(false)
        }

        discipline !== 'nat' && setPowersData(mainCharacteristic === 'power' && mainCharacteristicData.length ? mainCharacteristicData : powers)
        discipline !== 'nat' && setHeartratesData(mainCharacteristic === 'bpm' && mainCharacteristicData.length ? mainCharacteristicData : heartrates)
        discipline !== 'nat' && setAltitudesData(mainCharacteristic === 'altitude' && mainCharacteristicData.length ? mainCharacteristicData : altitudes)
        discipline !== 'nat' && setTemperaturesData(mainCharacteristic === 'temperatures' && mainCharacteristicData.length ? mainCharacteristicData : temperatures)
        setSpeedsData(mainCharacteristic === 'speed' && mainCharacteristicData.length ? mainCharacteristicData : speeds)
        setCadencesData(mainCharacteristic === 'cadence' && mainCharacteristicData.length ? mainCharacteristicData : cadences)

        setLoaded(true)
    }

    useEffect( () => {
        if (mainCharacteristic) {
            setLoaded(false)
            setDatas()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasChart, mainCharacteristic]);

    useEffect(() => {
        if (loaded && xAxis) {
            let speedUnit = metrics.speed_cap_velo;
            let paceUnit = metrics.allure_nat;
            let hrUnit = 'bpm';
            let powerUnit = 'W';
            let cadenceUnit = ['velo', 'vtt'].includes(discipline) ? "rpm" : "ppm";
            let temperatureUnit = metrics.temperature;
            let yAxisMargin = 5;
    
            let labels = {
                formatter: function () {
                    if (xAxis === 'duration') {
                        return secondsToChronoForChart(this.value)
                    }
                    let value = this.value
                    if(this.axis.max - this.axis.min > 1000) {
                        value = (value/1000).toFixed(1) + ' k'
                    }
                    return value
                }
            }
    
            let options = {
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }    
                    }
                },
                chart: {
                    type: 'area',
                    zoomType: 'x',
                    resetZoomButton: {
                        position: {
                            y: -10
                        }
                    },
                    backgroundColor: '#F5F5F5',
                },
                credits: {
                    enabled: false
                },
                title: {
                    text : null
                },
                legend: {
                    enabled : false
                },
                xAxis: {
                    title: {
                        text: xAxis === 'duration' ? 'min' : 'm',
                        offset: yAxisMargin + 2,
                        align: 'low',
                    },
                    labels : labels,
                    crosshair: {
                        width:2,
                        color: '#dddddd'
                    },
                    showFirstLabel: false,
                },
                tooltip: {
                    shared:true,
                    crosshairs: true,
                    shadow: true,
                    useHTML: true,
                    borderWidth:0,
                    background:'transparent',
                    positioner: function () {
                        return { x: 80, y: 120 };
                    },
                    formatter: function(){
                        // Used to put the marker on the map
                        // Encapsulating the call into a promise to continue execution meanwhile
                        const setDuration = () => new Promise(() => {
                            if (xAxis === 'duration' && !isLandscape) {
                                dispatch(setMarkerDuration(this.x))
                            }
                        })
                        setDuration()
                        // Used to put the marker on the map
    
                        let toDisplay = '<div class="cursor"><span class="dome"></span>'
                        const added = []
                        for (let i=0; i<this.points.length; i++) {
                            let point = this.points[i]
                            if (point.key) {
                                const altitudeObject = altitudesData.find(d => d.x === point.key)
                                const cadenceObject = cadencesData.find(d => d.x === point.key)
                                const powerObject = powersData.find(d => d.x === point.key)
                                const bpmObject = heartratesData.find(d => d.x === point.key)
                                const temperatureObject = temperaturesData.find(d => d.x === point.key)
                                const speedObject = speedsData.find(d => d.x === point.key)
                                if (altitudeOn && altitudeObject && !added.includes('altitude')) {
                                    toDisplay+='<span class="text"> '+t("app:stats.labels.altitude")+' : '+ Math.round(altitudeObject.y*100)/100 +' m </span><br>'
                                    added.push('altitude')
                                }
                                if (cadenceOn && cadenceObject && !added.includes('cadence')) {
                                    toDisplay+='<span class="text"> '+t("app:stats.labels.cadence")+' : '+ Math.round(cadenceObject.y) +' '+cadenceUnit+' </span><br>'
                                    added.push('cadence')
                                }
                                if (powerOn && powerObject && !added.includes('power')) {
                                    toDisplay+='<span class="text"> '+t("app:stats.labels.power")+' : '+ Math.round(powerObject.y) +' '+ powerUnit + '</span><br>'
                                    added.push('power')
                                }
                                if (bpmOn && bpmObject && !added.includes('bpm')) {
                                    toDisplay+='<span class="text"> '+t("app:stats.labels.bpm")+' : '+ Math.round(bpmObject.y) +' '+ hrUnit + '</span><br>'
                                    added.push('bpm')
                                }
                                if (temperaturesOn && temperatureObject && !added.includes('temperatures')) {
                                    toDisplay+='<span class="text"> '+t("app:stats.labels.temperature")+' : '+ temperatureObject.y +' '+ temperatureUnit + '</span><br>'
                                    added.push('temperatures')
                                }
                                if (speedOn && speedObject && !added.includes('speed')) {
                                    if (discipline === 'nat') {
                                        toDisplay+='<span class="text"> '+t("app:stats.labels.speed")+' : '+ speedObject.pacing +' '+ paceUnit + '</span><br>'
                                    } else {
                                        toDisplay+='<span class="text"> '+t("app:stats.labels.speed")+' : '+ speedObject.y +' '+ speedUnit + '</span><br>'
                                    }
                                    added.push('speed')
                                }
                            }
                        }
                        toDisplay+='<span class="text text-chrono"> '+ (xAxis === 'duration' ? secondsToChronoForChart(this.x) : (Math.round(this.x) + 'm')) +'</span></div>'
    
                        return toDisplay
                    },
    
                    hideDelay:0
                },
                plotOptions: {
                    series: {
                        lineWidth: 0,
                        cursor: 'pointer',
                        turboThreshold: 200000,
                        states: {
                            hover: {
                                enabled: false
                            }
                        },
                        marker: {
                            enabled: false,
                            radius: 1
                        }
                    },
                    area: {
                        marker: {
                            enabled: false,
                            symbol: 'circle',
                            radius: 2,
                            states: {
                                hover: {
                                    enabled: false
                                }
                            }
                        },
                        lineWidth: 0,
                        threshold: null,
                    }
                }
            }
    
    
            let disciplineOptions
            const yAxisLabel = {
                x: -3,
                formatter:function(){
                    if(this.value !==0){
                        return this.value;
                    }
                }
            }
            const yAxisNatLabel = {
                x: -2,
                formatter:function(){
                    if(this.value !==0){
                        return secondsToChronoForChart(this.value);
                    }
                }
            }
            // Adaptative yAxis
            const yAxis = [
                {
                    gridLineWidth: 0,
                    title: {
                        text: mainCharacteristic === 'speed' && discipline === 'nat' ? paceUnit : mainCharacteristic === 'speed' && discipline !== 'nat' ? speedUnit : mainCharacteristic === 'power' ? powerUnit : mainCharacteristic === 'bpm' ?  hrUnit : '',
                        offset: yAxisMargin,
                        align: 'low',
                    },
                    labels: discipline === 'nat' ? yAxisNatLabel : yAxisLabel,
                    showFirstLabel: false,
                    min: 0,
                }, {
                    gridLineWidth: 0,
                    title: {
                        text: datasEnabled[secondaryCharacteristic] ? (secondaryCharacteristic === 'bpm' ? hrUnit : secondaryCharacteristic === 'speed' ? speedUnit : secondaryCharacteristic === 'power' ? powerUnit : secondaryCharacteristic === 'cadence' ? cadenceUnit : secondaryCharacteristic === 'temperatures' ? temperatureUnit : 'm') : datasEnabled.altitude ? 'm' : (datasEnabled.power && mainCharacteristic !== 'power') ? powerUnit : (datasEnabled.speed && mainCharacteristic !== 'speed') ? speedUnit : datasEnabled.temperatures ? temperatureUnit : datasEnabled.cadence ? cadenceUnit : '',
                        offset: yAxisMargin,
                        align: 'low',
                    },
                    labels: yAxisLabel,
                    showFirstLabel: false,
                    min: 0,
                    opposite: true,
                }
            ]
            disciplineOptions = {
                yAxis,
                series: [
                speedOn && {
                    name: discipline !== 'nat' ? t('app:stats.labels.speed') : t('app:stats.labels.pace'),
                    data: datasEnabled.speed || mainCharacteristic === 'speed' ? speedsData : [],
                    type: mainCharacteristic === 'speed' ? 'column' : 'line',
                    yAxis: mainCharacteristic === 'speed' ? 0 : 1,
                    color: speedColor,
                    lineWidth: mainCharacteristic === 'speed' ? 5 : 1,
                    visible: speedOn,
                    zIndex: mainCharacteristic === 'speed' ? 1 : 3,
                },
                bpmOn && {
                    name: t('app:stats.labels.bpm'),
                    data: datasEnabled.bpm ? heartratesData : [],
                    type: mainCharacteristic === 'bpm' ? 'column' : 'line',
                    yAxis: mainCharacteristic === 'bpm' ? 0 : 1,
                    lineWidth: mainCharacteristic === 'bpm' ? 5 : 1,
                    color: htColor,
                    visible: bpmOn,
                    zIndex: mainCharacteristic === 'bpm' ? 1 : 4,
                },
                altitudeOn && {
                    name: t('app:stats.labels.altitude'),
                    data: datasEnabled.altitude ? altitudesData : [],
                    yAxis: 1,
                    type: 'line',
                    color: altitudeColor,
                    lineWidth: 1,
                    visible: altitudeOn,
                    zIndex: 5,
                },
                powerOn && {
                    name: t('app:stats.labels.power'),
                    data: datasEnabled.power ? powersData : [],
                    type: mainCharacteristic === 'power' ? 'column' : 'line',
                    yAxis: mainCharacteristic === 'power' ? 0 : 1,
                    color: powerColor,
                    lineWidth: mainCharacteristic === 'power' ? 5 : 1,
                    visible: powerOn,
                    zIndex: mainCharacteristic === 'power' ? 1 : 2,
                },
                temperaturesOn && {
                    name: t('app:stats.labels.temperature'),
                    data: datasEnabled.temperatures ? temperaturesData : [],
                    type: 'line',
                    color: temperatureColor,
                    yAxis: 1,
                    lineWidth: 1,
                    visible: temperaturesOn,
                    zIndex: 6,
                },
                cadenceOn && {
                    name: t('app:stats.labels.cadence'),
                    data: datasEnabled.cadence ? cadencesData : [],
                    type: 'line',
                    yAxis: 1,
                    lineWidth:1,
                    color: cmColor,
                    visible: cadenceOn,
                    zIndex: 7,
                }]
            }

            // Removing useless data
            disciplineOptions.series = disciplineOptions.series.filter(d => d !== false)

            if (!isLandscape) {
                options.chart.height = 280
            }
    
            setOptionsHigh({...options, ...disciplineOptions})
            setChartInitialized(true)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLandscape, loaded, xAxis, datasEnabled, speedsData, powersData, cadencesData, temperaturesData, heartratesData, altitudesData])
    
    useEffect(() => {
        // hasChartBeenHovered is used to prevent map.reset() to be fired when pages load
        hasChartAlreadyBeenHovered && !isChartHovered && !isLandscape && dispatch(resetMap())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChartHovered, hasChartAlreadyBeenHovered])

    useEffect(() => {
        const setChartSize = (isMobile, isFirstCall) => {
            let { innerWidth: width, innerHeight: height } = window
            if ((width > height && !isFirstCall) || (!isMobile && !isFirstCall && width < height)) {
                // Reversing values
                [width, height] = [height, width]
            }
            if (isMobile) {
                // 20px for the beta bar and 120px for the header + graph buttons
                chartRef.current.chart.setSize((height - 30), (width - 120), false)
            } else {
                chartRef.current.chart.setSize(width, 280, false)
            }
        }
        if (chartInitialized && isLandscape && chartRef && chartRef.current && chartRef.current.chart) {
            // phones
            // First load
            setChartSize(isMobile, true)

            window.addEventListener('orientationchange', () => {
                // User rotates device
                setChartSize(isMobile, false)
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLandscape, chartInitialized])

    const changeGraph = async (btnName) => {
        if (datasEnabled[btnName]) {
            setDatasEnabled((prevDatasEnabled) => {
                let newDatasEnabled = {...prevDatasEnabled}
                newDatasEnabled[btnName] = false
                return newDatasEnabled
            })
        } else {
            setDatasEnabled((prevDatasEnabled) => {
                let newDatasEnabled = {...prevDatasEnabled}
                newDatasEnabled[btnName] = true
                return newDatasEnabled
            })
        }
    }

    const rowBtns = (style) => {
        return (
            <div className={style + " row pcx-4"}>
                {speedOn &&
                    <button
                        name="vitesse-btn"
                        style={{background: speedColor}}
                        className={datasEnabled.speed ? "mc-2 button-stats vitesse-btn" : "mc-2 button-stats-empty"}
                        onClick={() => changeGraph('speed')}
                        disabled={mainCharacteristic === 'speed'}
                    >
                            {t('app:stats.labels.speed')}
                    </button>
                }
                {altitudeOn &&
                    <button
                        name="alt-btn"
                        style={{background: altitudeColor}} 
                        className={datasEnabled.altitude ? "mc-2 button-stats alt-btn" : "mc-2 button-stats-empty"}
                        onClick={() => changeGraph('altitude')}
                        disabled={mainCharacteristic === 'altitude'}
                    >
                            {t('app:stats.labels.altitude')}
                    </button>
                }
                {powerOn &&
                    <button
                        name="puissance-btn"
                        style={{background: powerColor}}
                        className={datasEnabled.power ? "mc-2 button-stats puissance-btn" : "mc-2 button-stats-empty"}
                        onClick={() => changeGraph('power')}
                        disabled={mainCharacteristic === 'power'}
                    >
                            {t('app:stats.labels.power')}
                    </button>
                }
                {bpmOn &&
                    <button
                        name="bpm-btn"
                        style={{background: htColor}}
                        className={datasEnabled.bpm ? "mc-2 button-stats bpm-btn" : "mc-2 button-stats-empty"}
                        onClick={() => changeGraph('bpm')}
                        disabled={mainCharacteristic === 'bpm'}
                    >
                            {t('app:stats.labels.bpm')}
                    </button>
                }
                {cadenceOn &&
                    <button
                        name="cadence-btn"
                        style={{background: cmColor}}
                        className={datasEnabled.cadence ? "mc-2 button-stats cadence-btn" : "mc-2 button-stats-empty"}
                        onClick={() => changeGraph('cadence')}
                        disabled={mainCharacteristic === 'cadence'}
                    >
                            {t('app:stats.labels.cadence')}
                    </button>
                }
                {temperaturesOn &&
                    <button
                        name="temperatures-btn"
                        style={{background: temperatureColor}}
                        className={datasEnabled.temperatures ? "mc-2 button-stats temperatures-btn" : "mc-2 button-stats-empty"}
                        onClick={() => changeGraph('temperatures')}
                        disabled={mainCharacteristic === 'temperatures'}
                    >
                            {t('app:stats.labels.temperature')}
                    </button>
                }
            </div>
        )
    }

    return (
        <div className="intensity-section">
            {loaded && xAxis && optionsHigh ?
                <div className="row justify-content-center d-flex stats-card">
                    <div
                        style={{height: '280px', width: '100%'}}
                        onMouseEnter={() => {
                            setHasChartAlreadyBeenHovered(true)
                            setIsChartHovered(true)
                        }}
                        onMouseLeave={() => setIsChartHovered(false)}
                        className="graph-container"
                    >
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={optionsHigh}
                            ref={chartRef}
                        />
                    </div>
                    
                    {isMobile && !isLandscape ? (
                        <div className="statsDesc my-2" style={{ margin: 'auto' }}>
                            <Link to={{ pathname: `/activity_sessions/${activitySessionId}/intensity_chart`, activitySessionId }}>Voir le détail</Link>
                        </div>
                    ) : (!isMobile || isLandscape) && (
                        rowBtns("mcy-2 justify-content-center")
                    )}
                </div> :
                <div className="row justify-content-center align-items-center d-flex mb-5 py-5" >
                    <ClipLoader
                        color="#0069d9"
                        size={60}
                        loading
                        className="my-5"
                    />
                </div>
            }
        </div>
    )
}

export default ActivitySessionChart