import React, {useEffect, useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../../Tools/CustomHooks";
import Loader from "react-loader-spinner";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";

import {Line} from "react-chartjs-2"
import {Metrics} from "../../../Functions/Metrics";
import {MetricContext} from "../../../Contexts";
import {TrainingConceptCodes} from "../../TrainingConcept/Utils";
import {helpCircle} from "../../../img/img";
import ReactTooltip from "react-tooltip";
import {secondsToChronoForChart} from "../Utils";

const ActivitySessionPerformanceChart = ({activitySessionId, discipline, contextCode, trainingConceptCode}) => {
    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()

    const [chartData, setChartData] = useState({})
    const [datas1to1, setDatas1to1] = useState({})
    const [oldDatas1to1, setOldDatas1to1] = useState({})
    const [times1to1, setTimes1to1] = useState([])
    const [datas1to3, setDatas1to3] = useState({})
    const [oldDatas1to3, setOldDatas1to3] = useState({})
    const [times1to3, setTimes1to3] = useState([])
    const [datas3to6, setDatas3to6] = useState({})
    const [oldDatas3to6, setOldDatas3to6] = useState({})
    const [times3to6, setTimes3to6] = useState([])
    const [datasOver6, setDatasOver6] = useState({})
    const [oldDatasOver6, setOldDatasOver6] = useState({})
    const [timesOver6, setTimesOver6] = useState([])
    const [btnText, setBtnText] = useState('')

    const [contextParam, setContextParam] = useState(null)
    const [metricUnit, setMetricUnit] = useState(null)

    const [loaded, setLoaded] = useState(false)
    const [loadedGraph, setLoadedGraph] = useState(true)

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    const [xAxis, setXAxis] = useState('duration')

    const [maxXValue, setMaxXValue] = useState(null)


    useEffect(() => {
        generateContextOpt()
    }, [])

    useEffect(() => {
        if(discipline && trainingConceptCode) {
            let metricUnitValue
            if(trainingConceptCode === TrainingConceptCodes.CRITICAL_HEARTRATE) {
                metricUnitValue = metrics.heartrate
            }
            else {
                switch(discipline) {
                    case 'nat':
                        metricUnitValue = metrics.allure_nat
                        break;
                    case 'velo':
                    case 'vtt':
                        metricUnitValue = metrics.power_velo
                        break;
                    case 'cap':
                    default:
                        metricUnitValue = metrics.speed_cap_velo
                        break;
                }
            }
            setMetricUnit(metricUnitValue)

            setXAxis(discipline === 'nat' ? 'distance': 'duration')
        }
    }, [discipline, trainingConceptCode]);


    useEffect(() => {
        if(contextParam && trainingConceptCode) {
            fetchDataByPercent()
        }
    }, [contextParam, trainingConceptCode]);



    const generateContextOpt = () => {
        let contextParamValue
        switch(contextCode) {
            case 'swimming-pool-25':
                contextParamValue = 'pool'
                setBtnText(t('app.athlete_perf.swimming_pool_25'))
                break
            case 'swimming-pool-33':
                contextParamValue = 'pool'
                setBtnText(t('app.athlete_perf.swimming_pool_33'))
                break
            case 'swimming-pool-50':
                contextParamValue = 'pool'
                setBtnText(t('app.athlete_perf.swimming_pool_50'))
                break
            case 'running-indoor':
                contextParamValue = 'indoor'
                setBtnText(t('app.athlete_perf.treadmill'))
                break
            case 'cycling-indoor':
                contextParamValue = 'indoor'
                setBtnText(t('app.athlete_perf.hometrainer'))
                break
            case 'swimming-no-pool':
                contextParamValue = 'no_pool'
                setBtnText(t('app.athlete_perf.open_water'))
                break
            case 'running-outdoor':
            case 'cycling-outdoor':
            case 'vtt':
                contextParamValue = 'minus1_to_1per100'
                break
            default:
                contextParamValue = 'all'
                break
        }
        setContextParam(contextParamValue)
    }

    const fetchDataByPercent = async () => {
        await axios.get(
            config+"api/activity_sessions/"+activitySessionId+"/performance_profile",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                },
                params: {
                    context_param: contextParam,
                    training_concept: trainingConceptCode,
                    x_axis: xAxis
                }
            },
        ).then(async (res) => {
            if(!isMounted.current){
                return 0;
            }

            let datas
            if (res.data[contextParam]) {
                datas = res.data[contextParam]
            } else {
                datas = res.data[0]
            }

            let speeds = []
            let oldSpeeds = []
            let times = []

            for (let i = 0; i < datas['activity_session_performances'].length; i++) {
                let currentPerf = datas['activity_session_performances'][i]
                let oldPerf = datas['old_performance_profile_performances'][i]
                times.push(xAxis === 'duration' ?  currentPerf.x : currentPerf.x)
                speeds.push({x: currentPerf.x, y: currentPerf.y.toFixed(1)})
                if (oldPerf) {oldSpeeds.push({x: oldPerf.x, y: oldPerf.y.toFixed(1)})}
            }

            setMaxXValue(times[datas['activity_session_performances'].length-1])

            let dataSets =  {
                label: 'Session',
                data: speeds,
                pointRadius: 2,
                fill: false,
                borderColor: '#000000',
                borderWidth: 2,
                yAxisID: 'axe-1'
            }

            let oldDataSets =  {
                label: "Reference",
                data: oldSpeeds,
                pointRadius: 2,
                fill: false,
                borderColor: '#FFA800',
                borderWidth: 2,
                yAxisID: 'axe-1'
            }

            if (contextParam === 'minus1_to_1per100') {
                setDatas1to1(dataSets)
                setOldDatas1to1(oldDataSets)
                setTimes1to1(times)
            } else if (contextParam === '1_to_3per100') {
                setDatas1to3(dataSets)
                setOldDatas1to3(oldDataSets)
                setTimes1to3(times)
            } else if (contextParam === '3_to_6per100') {
                setDatas3to6(dataSets)
                setOldDatas3to6(oldDataSets)
                setTimes3to6(times)
            } else if (contextParam === 'over_6per100') {
                setDatasOver6(dataSets)
                setOldDatasOver6(oldDataSets)
                setTimesOver6(times)
            }

            await setChartData({
                labels: times,
                datasets: [dataSets, oldDataSets],
            })
            setLoaded(true)
            setLoadedGraph(true)

        })
            .catch(error => {
                handleEndpointError(error)
            })
    }


    const changeGraph = async (event) => {
        let btn = event.target
        let activeBtn = document.getElementsByClassName("button-perf")[0]
        setLoadedGraph(false)

        if (!btn.classList.contains(btn.id)) {
            activeBtn.classList.remove(activeBtn.id)
            activeBtn.classList.remove("button-perf")
            activeBtn.classList.add("button-perf-empty")

            btn.classList.add(btn.id)
            btn.classList.add("button-perf")
            btn.classList.remove("button-perf-empty")

            if (btn.id === "1to1-btn") {
                if (Object.keys(datas1to1).length>0) {
                    setChartData({labels: times1to1,
                        datasets: [datas1to1, oldDatas1to1]})
                    setLoadedGraph(true)
                } else {
                    setContextParam('minus1_to_1per100')
                }
            } else if (btn.id === "1to3-btn") {
                if (Object.keys(datas1to3).length>0) {
                    setChartData({labels: times1to3,
                        datasets: [datas1to3, oldDatas1to3]})
                    setLoadedGraph(true)
                } else {
                    setContextParam('1_to_3per100')
                }
            } else if (btn.id === "3to6-btn") {
                if (Object.keys(datas3to6).length>0) {
                    setChartData({labels: times3to6,
                        datasets: [datas3to6, oldDatas3to6]})
                    setLoadedGraph(true)
                } else {
                    setContextParam('3_to_6per100')
                }
            } else if (btn.id === "over6-btn") {
                if (Object.keys(datasOver6).length>0) {
                    setChartData({labels: timesOver6,
                        datasets: [datasOver6, oldDatasOver6]})
                    setLoadedGraph(true)
                } else {
                    setContextParam('over_6per100')
                }
            }
        }
    }

    const getDataTip = () => {
        return (
            <div className="fs-12 text-left">
                <p>{t('app.activity_session.perf_profil_info')}</p>
            </div>
        )
    }

    return (
        <div className="intensity-section">
            <div className="row mct-4 mr-1 mb-2 justify-content-start d-flex text-left "
                 data-tip={""}
                 data-for="perf-profil-helper"
                 data-event='click'
            >
                <h2 className="seance-title2">{t('app.activity_session.perf_profil')}</h2>
                <img src={helpCircle} className="ml-3 icon-24"
                     alt="help icon"
                />
            </div>
            <ReactTooltip key="tooltip-perf-profil"
                          id="perf-profil-helper"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip"
                          effect="solid"
                          place="bottom"
                          getContent={() => getDataTip()}
            />
            {loaded ?
                <div className="row justify-content-center d-flex stats-card mb-5">
                    {['running-outdoor','cycling-outdoor', 'vtt'].indexOf(contextCode) !== -1 ?
                        <div className="row mcy-3 pcx-4">
                            <button id="1to1-btn" name="1to1-btn" className="mc-2 button-perf 1to1-btn" onClick={changeGraph}>{t('app.athlete_perf.minus1_to_1per100')}</button>

                            <button  id="1to3-btn" name="1to3-btn" className="mc-2 button-perf-empty" onClick={changeGraph}>{t('app.athlete_perf.1_to_3per100')}</button>

                            <button  id="3to6-btn" name="3to6-btn" className="mc-2 button-perf-empty" onClick={changeGraph}>{t('app.athlete_perf.3_to_6per100')}</button>

                            <button  id="over6-btn" name="over6-btn" className="mc-2 button-perf-empty" onClick={changeGraph}>{t('app.athlete_perf.over_6per100')}</button>
                        </div>
                        : btnText !== '' &&
                        <button id="perf-btn" name="perf-btn" className="mc-2 button-perf">{btnText}</button>
                    }

                    {loadedGraph ? <div style={{height: '300px', width: '100%'}}>
                            <Line redraw={true} data={chartData} options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                hover: {
                                    mode: 'index'
                                },
                                tooltips: {
                                    mode: 'index',
                                    callbacks: {
                                        title: function(tooltipItem, data) {
                                            let value = tooltipItem[0].label
                                            return discipline === 'nat' ? (value+'m') : secondsToChronoForChart(value)
                                        },
                                        label: function(tooltipItem, data) {
                                            let label = data.datasets[tooltipItem.datasetIndex].label || '';

                                            if (label) {
                                                label += ': ';
                                            }
                                            let value = tooltipItem.yLabel
                                            label += (discipline === 'nat' ? secondsToChronoForChart(value) : value) + ' '+metricUnit;
                                            return label;
                                        }
                                    }
                                },
                                legend: {
                                    display: true,
                                    position: 'bottom',
                                    labels: {
                                        fontFamily: "Gotham",
                                        fontStyle: 'bold',
                                        fontSize: 12,
                                    }
                                },
                                scales: {
                                    yAxes: [
                                        {
                                            scaleLabel: {
                                                display: true,
                                                labelString: metricUnit,
                                                fontFamily: "Gotham",
                                                fontStyle: 'bold',
                                                fontSize: 10
                                            },
                                            gridLines: {
                                                color: "rgba(0, 0, 0, 0)",
                                            },
                                            id: 'axe-1',
                                            position: 'left',
                                            type: 'linear',
                                            ticks: {
                                                fontSize: 10,
                                                callback: function(value, index, values) {
                                                    return discipline === 'nat' ? secondsToChronoForChart(value) : value
                                                }
                                            }
                                        },
                                    ],
                                    xAxes: [{
                                        // type: 'linear',
                                        ticks: {
                                            autoSkip: true,
                                            maxTicksLimit: 4,
                                            maxRotation: 0,
                                            minRotation: 0,
                                            callback: function(value, index, values) {
                                                return discipline === 'nat' ? value + 'm' : secondsToChronoForChart(value)
                                            },
                                            min: 0,
                                            max: maxXValue,
                                        }
                                    }],
                                },
                            }}/>
                        </div> :
                        <div className="col-12">
                            <Loader
                                type="TailSpin"
                                color="#0069d9"
                                height={80}
                                width={80}
                                className="my-5"
                            />
                        </div>

                    }
                </div> :
                <div className="row justify-content-center align-items-center d-flex stats-card mb-5 py-5" >
                    <Loader
                        type="TailSpin"
                        color="#0069d9"
                        height={80}
                        width={80}
                        className="my-5"
                    />
                </div>
            }

        </div>
    )
}

export default ActivitySessionPerformanceChart;