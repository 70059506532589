import React, {useState, useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {logo_garmin_training_transparent, watch_sync, move, trash} from "../../../img/img";
import {TrainingEventTypes} from "./Utils";
import axios from "axios/index";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";
import Loader from "react-loader-spinner";
import moment from "moment/moment";
import {GarminTrainingContext, LoggedInContext, WeekCalendarContext} from "../../../Contexts";
import uniqid from "uniqid";

const TrainingEventPanel = ({trainingEvent, handleTrainingEvent}) => {

    const {t} = useTranslation(['app'])

    const {updateCalendarEventToRefresh} = useContext(WeekCalendarContext)

    const {is_subscribed} = useContext(LoggedInContext)

    // Indique si la partie de confirmation de suppression est ouverte
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)

    // Indique si le choix du type de synchronisation est ouvert
    const [syncOpen, setSyncOpen] = useState(false)
    const [syncLoading, setSyncLoading] = useState(false)

    // Etat de synchronization Garmin Training
    const {garminTrainingSynced} = useContext(GarminTrainingContext)

    // Supprimer une séance
    const deleteWorkout = (workoutId) => {
        let headers = generateHeaders()
        if(headers){
            axios
                .delete(
                    config+"api/workouts/"+workoutId,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    // Cacher le loader
                    setDeleteLoading(false)
                    // Fermer la popup
                    setDeleteOpen(false)

                    if(response.data.error) {
                        console.log('deleteWorkout error',response.data.error)
                    }
                    // Recharger le calendrier
                    updateCalendarEventToRefresh(uniqid("training-event-"+trainingEvent.id))
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Supprimer un compte rendu
    const deleteDebriefing = (debriefingId) => {
        let headers = generateHeaders()
        if(headers){
            axios
                .delete(
                    config+"api/debriefings/"+debriefingId,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    // Cacher le loader
                    setDeleteLoading(false)
                    // Fermer la popup
                    setDeleteOpen(false)

                    if(response.data.error) {
                        console.log('deleteDebriefing error',response.data.error)
                    }
                    // Recharger le calendrier
                    updateCalendarEventToRefresh(uniqid("training-event-"+trainingEvent.id))
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Action lors de la suppression qui peut être confirmée ou non
    const onConfirmDelete = (confirmed) => {
        if(confirmed) {
            setDeleteLoading(true)
            switch(trainingEvent.type) {
                case TrainingEventTypes.SEANCE_PLANIFIEE:
                    // Supprimer la séance
                    let workoutId = trainingEvent.workout_id
                    deleteWorkout(workoutId)

                    break
                case TrainingEventTypes.SEANCE_REALISEE:
                case TrainingEventTypes.COMPTE_RENDU_LIBRE:
                case TrainingEventTypes.ACTIVITE_LIBRE:
                case TrainingEventTypes.ACTIVITE_REALISEE:
                    // Supprimer le compte-rendu
                    let debriefingId = trainingEvent.debriefing_id
                    deleteDebriefing(debriefingId)

                    break
                default:
                    setDeleteLoading(false)
                    setDeleteOpen(false)
                    break
            }
        }
        else (
            setDeleteOpen(false)
        )
    }



    // Afficher bouton "Désynchroniser/Synchroniser Garmin Training"
    const isSynchronizable = trainingEvent.garmin_training_synchronizable
    const syncState =  !trainingEvent.garmin_training_synced

    // Afficher bouton "Déplacer"
    let isMoveable = [TrainingEventTypes.SEANCE_PLANIFIEE, TrainingEventTypes.SEANCE_REALISEE, TrainingEventTypes.COMPTE_RENDU_LIBRE].indexOf(trainingEvent.type) !== -1

    // Afficher bouton "Supprimer"
    let isDeletable = [TrainingEventTypes.SEANCE_PLANIFIEE, TrainingEventTypes.SEANCE_REALISEE, TrainingEventTypes.COMPTE_RENDU_LIBRE, TrainingEventTypes.ACTIVITE_LIBRE, TrainingEventTypes.ACTIVITE_REALISEE].indexOf(trainingEvent.type) !== -1

    if (!is_subscribed && [TrainingEventTypes.SEANCE_PLANIFIEE, TrainingEventTypes.SEANCE_REALISEE, TrainingEventTypes.ACTIVITE_REALISEE].indexOf(trainingEvent.type) !== -1) {
        isMoveable = false
        isDeletable = false
    }

    const trainingEventDate = moment(trainingEvent.date)

    // Gérer l'état de la synchronisation d'une séance et déclencher l'export vers Garmin Training
    const exportWorkoutToGarminTraining = (synced) => {
        let headers = generateHeaders()
        if(headers){
            axios
                .put(
                    config+"api/workouts/"+trainingEvent.workout_id+'/sync_garmin_training',
                    {
                        synced: synced
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    setSyncLoading(false)
                    if(response.data.error) {
                        console.log('exportWorkoutToGarminTraining error',response.data.error)
                    }
                    // Recharger le calendrier
                    updateCalendarEventToRefresh(uniqid("training-event-"+trainingEvent.id))
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Gérer l'état de la synchronisation d'une séance et déclencher l'export vers Garmin Training
    const exportWeekCalendarWorkoutsToGarminTraining = (synced) => {

        let headers = generateHeaders()
        if(headers){
            axios
                .put(
                    config+"api/calendar/sync_garmin_training",
                    {
                        week_number: trainingEventDate.format("W"),
                        year_number: trainingEventDate.format("YYYY"),
                        synced: synced
                    },
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    setSyncLoading(false)
                    if(response.data.error) {
                        console.log('exportWeekCalendarWorkoutsToGarminTraining error',response.data.error)
                    }
                    else {
                        // Recharger le calendrier
                        updateCalendarEventToRefresh(uniqid("training-event-"+trainingEvent.id))
                    }
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Action lors du choix du synchronisation Garmin Training
    const onSynchronize = (syncState, action) => {
        setSyncLoading(true)
        switch (action) {
            case "all_week":
                exportWeekCalendarWorkoutsToGarminTraining(syncState)
                break
            case "this_session":
                exportWorkoutToGarminTraining(syncState)
                break
            default:
                break
        }

    }

    const getDeleteLoader = () => {
        return(
            <Loader
                type="TailSpin"
                color="#0069d9"
                height={80}
                width={80}
                className="my-5"
            />
        )
    }

    // Afficher la confirmation de suppression
    const confirmDelete = () => {
        return (
            <div className="w-100 text-center delete-red height-suppress-workout">
                <img
                    src={trash}
                    alt="trash icon"
                    className="trash mx-auto icon-24 d-block"
                />
                {t('app:workout.panel.delete')} ?
                <div className="d-flex justify-content-center mt-3">
                    <div className="btn-confirmSuppress btn-panel d-flex align-items-center justify-content-center"
                         onClick={() => onConfirmDelete(true)}>
                        <span className="text-btn-panel-yes">
                            {t('app:menu.yes')}
                        </span>
                    </div>
                    <div className="btn-cancelSuppress btn-panel d-flex align-items-center justify-content-center ml-3"
                         onClick={() => onConfirmDelete(false)}>
                        <span className="text-btn-panel-no">
                            {t('app:menu.no')}
                        </span>
                    </div>
                </div>

            </div>
        )
    }

    // Afficher la demande de confirmation de synchronisation Garmin Training
    const handleSynchronizeGarminTraining = (syncState) => {

        return (
            <div className="w-100 text-center height-suppress-workout d-flex flex-column align-items-center justify-content-center">
                <img
                    src={watch_sync}
                    alt="watch sync icon"
                    className="mx-auto icon-24 mcy-2"
                />
                <span>{t(syncState ? 'app:workout.panel.sync' : 'app:workout.panel.unsync')}</span>
                <span>
                    <img
                        style={{height: '14px'}}
                        src={logo_garmin_training_transparent}
                        alt="logo garmin"
                    />
                </span>

                <div className="d-flex justify-content-center align-items-center btn-panel-sync btn-sync-week mcy-2"
                     onClick={() => onSynchronize(syncState, 'all_week')}
                >
                    {t('app:workout.panel.all_week')}
                </div>
                <div className="d-flex justify-content-center align-items-center btn-panel-sync btn-sync-one mcb-3"
                     onClick={() => onSynchronize(syncState, 'this_session')}
                >
                    {t('app:workout.panel.this_seance')}
                </div>

            </div>
        )
    }

    const getSyncLoader = () => {
        return(
            <Loader
                type="TailSpin"
                color="#0069d9"
                height={80}
                width={80}
                className="my-5"
            />
        )
    }

    return (
        <div className="dropdown-content dropdown-content-right pointer fs-12">
            <div className="d-flex flex-column">
                {!deleteOpen && !syncOpen &&
                <>
                    {garminTrainingSynced && isSynchronizable &&
                    <div className="w-100 d-flex align-items-center" onClick={() => setSyncOpen(true)}>
                        <div className="align-items-center">
                            <img
                                src={watch_sync}
                                alt="watch icon"
                                className="mx-3 icon-24"
                            />
                        </div>
                        <div style={{textAlign: 'left'}}>
                            {t(syncState ? 'app:workout.panel.sync' : 'app:workout.panel.unsync')}<br/>
                            <img
                                style={{height: '14px'}}
                                src={logo_garmin_training_transparent}
                                alt="logo garmin"
                            />
                        </div>
                    </div>
                    }
                    {isMoveable &&
                    <p className="w-100 d-flex align-items-center pl-0 move-training-event"
                       onClick={() => handleTrainingEvent(trainingEvent)}>
                        <img
                            src={move}
                            alt="move icon"
                            className="mx-3 icon-24 move-training-event"
                        />
                        {t('app:workout.panel.move')}
                    </p>
                    }
                    {isDeletable &&
                    <p className="w-100 text-left delete-red d-flex align-items-center pl-0" onClick={() => setDeleteOpen(true)}>
                        <img
                            src={trash}
                            alt="trash icon"
                            className="mx-3 icon-24"
                        />
                        {t('app:workout.panel.delete')}
                    </p>
                    }
                </>
                }

                {deleteOpen ?
                    deleteLoading ? getDeleteLoader() : confirmDelete()
                    : <></>
                }

                {syncOpen ?
                    syncLoading ? getSyncLoader() : handleSynchronizeGarminTraining(syncState)
                    : <></>
                }
            </div>
        </div>

    )
}

export default TrainingEventPanel;