import React, {useEffect, useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {Button, Form} from "react-bootstrap";
import {helpCircle, plus_circle_grey, trash_grey, validate} from "../../img/img";
import generateHeaders from "../../Functions/generateHeaders";
import axios from "axios";

import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import {Metrics, MetricValues} from "../../Functions/Metrics";
import moment from "moment";
import InputField from "../Forms/InputField";
import iso3311a2 from "iso-3166-1-alpha-2";
import {AuthContext, LoggedInContext, MetricContext, PrioritySportContext} from "../../Contexts";
import CustomSelect from "../Tools/CustomSelect";
import DatePicker from "react-datepicker";
import ReactTooltip from "react-tooltip";


const EditProfile = ({user, setIsEdit, getMyProfile, isMobile, page, userSports, onSuccess}) => {

    const {t} = useTranslation(['app'])
    const transform = (value) => {
        let val = value / 100
        val = val.toString()
        if (val.length === 3) {
            val = val + "0"
        }
        return val
    }

    const {updatePrioritySport, updatePreferedSports} = useContext(PrioritySportContext)

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    const [name, setName] = useState(user ? user.lastname : "")
    const [username, setUsername] = useState(user ? user.email : "")
    const [emailOk, setEmailOk] = useState(user ? user.email : "")
    const [firstName, setFirstName] = useState(user ? user.firstname : "")
    const [birthdate, setBirthdate] = useState(user ? moment(user.birth_date).toDate() : "")
    const [city, setCity] = useState(user ? user.city : "")
    const [country, setCountry] = useState(null)
    const [countries, setCountries] = useState(null)
    const [phone, setPhone] = useState(user ? user.cell_phone : "")
    const [gender, setGender] = useState(user ? user.gender : "")
    const [height, setHeight] = useState(null)
    const [heights, setHeights] = useState(null)
    const [weights, setWeights] = useState(null)
    const userWeigth = user ? user.weight : null
    const weight = userWeigth ? userWeigth.value : null
    const weightUk = userWeigth ? userWeigth.uk_value : null
    const [weightValue, setWeightValue] = useState(null)
    const [sports, setSports] = useState(user ? userSports : [])
    const [sportCounter, setSportCounter] = useState(user && user.sports.length > 0 ? user.sports.slice(-1)[0].id + 1 : 0)
    const [sportsAvailables, setSportsAvailables] = useState(user ?
        []
        :
        ["cap"])

    const disciplines = ["cap", "velo", "nat", "triathlon"]
    const [errorMessage, setErrorMessage] = useState()
    const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(false)
    const [isHealthProfileOpen, setIsHealthProfileOpen] = useState(false)
    const [isDisciplineOpen, setIsDisciplineOpen] = useState(false)

    useEffect(() => {
        setIsPersonalInfoOpen(true)
        setIsHealthProfileOpen(true)
        setIsDisciplineOpen(true)
    }, [])

    useEffect(() => {
        if (sportsAvailables.length > 0) {
            addSport()
        }
        generateHeights(140, 220)
        generateCountries()
    }, [])

    useEffect(() => {
        if (countries !== null) {
            let countryCode = user ? user.country : "FR"
            let countryOption = null
            for (let country of countries) {
                if (country.id === countryCode) {
                    countryOption = country
                    break
                }
            }
            setCountry(countryOption)

        }
    }, [countries])

    useEffect(() => {
        if (heights !== null) {
            let heightValue = user ? user.height : null
            let heightOption = null
            for (let height of heights) {
                if (height.id === heightValue) {
                    heightOption = height
                    break
                }
            }
            setHeight(heightOption)

        }
    }, [heights])

    useEffect(() => {
        metric === MetricValues.imperial ? generateWeights(80, 350) : generateWeights(35, 150)
    }, [metric])

    useEffect(() => {
        if (weights !== null) {
            let weightValue = user ? (metric === MetricValues.imperial ? weightUk : weight) : null
            let weightOption = null
            for (let weight of weights) {
                if (weight.id === parseInt(weightValue)) {
                    weightOption = weight
                    break
                }
            }
            setWeightValue(weightOption)

        }
    }, [weights])

    useEffect(() => {
        if (sports) {
            let sportsNotAvailables = []
            for (let i = 0; i < sports.length; i++) {
                if (sports[i]) {
                    sportsNotAvailables.push(sports[i].discipline)
                }

            }
            let newSportsAvailables = []
            for (let i = 0; i < disciplines.length; i++) {
                if (!sportsNotAvailables.includes(disciplines[i])) {
                    newSportsAvailables.push(disciplines[i])
                }
            }
            setSportsAvailables(newSportsAvailables)
        }
    }, [sports])


    const changeGender = (event) => {
        if (event.target.id === "checkMale") {
            setGender(1)
        } else {
            setGender(2)
        }
    }

    const generateCountries = () => {
        const countries = iso3311a2.getData()
        let countriesValues = []

        Object.entries(countries).map(([id, nom]) => {
            countriesValues.push({
                id: id,
                label: nom
            })
        })
        setCountries(countriesValues)
    }

    const onChangeCountries = (value) => {
        setCountry(value)
    }

    const generateHeights = (minHeight, maxHeight) => {
        let heights = []
        let heightValues = []

        for (let i = minHeight; i <= maxHeight; i++) {
            heights.push(i)
        }
        heights.map((item, i) => {
            heightValues.push({
                id: item,
                label: transform(item) + 'm'
            })
        })
        setHeights(heightValues)
    }

    const onChangeHeight = (value) => {
        setHeight(value)
    }

    const generateWeights = (minWeight, maxWeight) => {
        let weights = []
        let weightValues = []
        for (let i = minWeight; i <= maxWeight; i++) {
            weights.push(i)
        }
        weights.map((item, i) => {
            weightValues.push({
                id: item,
                label: item + metrics.weight
            })
        })
        setWeights(weightValues)
    }

    const onChangeWeight = (value) => {
        setWeightValue(value, metric === MetricValues.imperial ? weightUk : weight)
    }

    const generateSportsDiv = () => {
        let sportsDivs = []
        let sportsDivsValues = []
        let counter = 0
        if (sports) {
            for (let i = 0; i < sports.length; i++) {
                if (sports[i]) {
                    counter++
                    sportsDivs.push(<SportDiv key={sports[i].id} sport={sports[i]} counter={counter}/>)
                }
            }
        }
        sportsDivs.map((item, i) => {
            sportsDivsValues.push({
                id: i,
                label: item
            })
        })
        return sportsDivs
    }

    const SportDiv = ({sport, counter}) => {
        if (sport) {
            let notFirst = counter > 1
            return (
                <div key={"sport-" + sport.id} className="row">
                    <div className="col-5">
                        <Form.Group id={"formBasicSport-" + sport.id}>
                            <Form.Control id={"sport-" + sport.id} as="select"
                                          className={page === "settings" ? "background-white form-login-input form-login-input-profile pcl-2 py-0 mcr-3" : "form-login-input pcl-3 py-0 mcr-3"}
                                          name="sport"
                                          onChange={(event) => {
                                              handleChangeSport(event, sport)
                                          }}
                                          required
                            >
                                <option value={sport.discipline}
                                        defaultValue={true}>{t('app:finalize_registration.sports.' + sport.discipline)}</option>
                                {sportsAvailables.map((sportAvailable) =>
                                    <option key={"sport-" + sport.id + '_' + sportAvailable}
                                            value={sportAvailable}>{t('app:finalize_registration.sports.' + sportAvailable)}</option>
                                )

                                }
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div className="col-5">
                        <Form.Group id={"formBasicLevel-" + sport.id}>
                            <Form.Control id={"level-" + sport.id} as="select"
                                          className={page === "settings" ? "background-white form-login-input form-login-input-profile pcl-2 py-0 mcr-3" : "form-login-input pcl-3 py-0 mcr-3"}
                                          name="level"
                                          onChange={(event) => {
                                              handleChangeSport(event, sport)
                                          }}
                                          defaultValue={sport.level}
                                          required
                            >
                                <option value="beginner">{t('app:finalize_registration.level.beginner')}</option>
                                <option value="intermediate">{t('app:finalize_registration.level.intermediate')}</option>
                                <option value="advanced">{t('app:finalize_registration.level.advanced')}</option>
                                <option value="athlete">{t('app:finalize_registration.level.athlete')}</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                    {notFirst &&
                    <div className="m-auto pb-3">
                        <div className="w-40 h-40 d-flex justify-content-center align-items-center">
                            <img id={"trash-" + sport.id} src={trash_grey} alt="trash icon" className="icon-24 pointer"
                                 onClick={() => {
                                     deleteSport(sport)
                                 }
                                 }/>
                        </div>
                    </div>
                    }

                </div>
            )
        } else {
            return null
        }
    }

    const addSport = () => {
        let newSport = {
            "discipline": sportsAvailables[0],
            "level": "beginner",
            "id": sportCounter
        }
        setSports(oldSports => {
            let newSports = [...oldSports]
            newSports[sportCounter] = newSport
            return newSports
        })
        setSportCounter(sportCounter => sportCounter + 1)
    }

    const deleteSport = (sport) => {
        setSports((oldSports) => {
            let newSports = []
            for (let i = 0; i < [...oldSports].length; i++) {
                if ([...oldSports][i]) {
                    if ([...oldSports][i].id !== parseFloat(sport.id)) {
                        newSports[i] = [...oldSports][i]
                    }
                }
            }
            return newSports
        })
    }

    const handleChangeSport = (event, sport) => {
        let sel = event.target
        let sportNum = sport.id
        let opt1
        let opt2

        if (sel.name === "sport") {
            for (let i = 0, len = sel.options.length; i < len; i++) {
                opt1 = sel.options[i]
                if (opt1.selected === true) {
                    break
                }
            }
            let levelSelect = document.getElementById("level-" + sportNum)
            for (let i = 0, len = levelSelect.options.length; i < len; i++) {
                opt2 = levelSelect.options[i];
                if (opt2.selected === true) {
                    break;
                }
            }
        } else if (sel.name === "level") {
            for (let i = 0, len = sel.options.length; i < len; i++) {
                opt2 = sel.options[i]
                if (opt2.selected === true) {
                    break
                }
            }
            let sportSelect = document.getElementById("sport-" + sportNum)
            for (let i = 0, len = sportSelect.options.length; i < len; i++) {
                opt1 = sportSelect.options[i];
                if (opt1.selected === true) {
                    break;
                }
            }
        }

        let objSport = {
            "discipline": opt1.value,
            "level": opt2.value,
            "id": parseFloat(sportNum)
        }

        setSports((oldSports) => {
            let newSports = [...oldSports]
            newSports[objSport.id] = objSport
            return newSports
        })
    }

    const handleSubmit = (event) => {
        let datas = {}

        datas.cell_phone = phone
        datas.birth_date = moment(birthdate).format("YYYY-MM-DD")
        datas.height = height ? height.id : null
        datas.gender = gender
        datas.city = city
        datas.country = country ? country.id : null
        datas.weight = weightValue ? weightValue.id : null

        if (sports !== userSports) {
            let datasSports = []
            for (let i = 0; i < sports.length; i++) {
                if (sports[i]) {
                    let sport = {"discipline": sports[i].discipline, "level": sports[i].level}
                    datasSports.push(sport)
                }
            }
            datas.sports = datasSports
        }

        if (page === "settings") {
            datas.email = username
            datas.lastname = name
            datas.firstname = firstName
        } else {
            datas.has_finalized_registration = true
        }

        axios
            .put(
                config + "api/my_profile",
                datas,
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    }
                }
            )
            .then(response => {
                if (page === "settings") {
                    setIsEdit(false)
                    getMyProfile()
                } else {
                    onSuccess()
                }

                // Préférence Sport
                let disciplineCode = datas.sports && datas.sports.length > 0 ? datas.sports[0].discipline : null
                let pSport = disciplineCode && ["cap", "nat", "velo"].indexOf(disciplineCode) !== -1 ? disciplineCode : null
                updatePrioritySport(pSport)

                if (datas.sports && datas.sports.length > 0 && datas.sports[0].discipline) {
                    let userSports = []
                    for (let userSport of datas.sports) {
                        userSports.push(userSport.discipline)
                    }

                    updatePreferedSports(userSports)
                }

            })
            .catch(error => {
                handleEndpointError(error)
            })

        event.preventDefault()
    }

    return (
        <div id="form-login" className={isMobile ? "my-auto w-100" : "w-100"}>
            {page === "settings" &&
                <>
            <div>
                <InputField isRequired={true} name={"firstname"} type={"text"} inputValue={firstName}
                            setValue={setFirstName} page={page}
                            placeholder={t('app:register.firstname') + ' *'}/>

                <InputField isRequired={true} name={"name"} type={"text"} inputValue={name}
                            setValue={setName} page={page} placeholder={t('app:register.name') + ' *'}/>

                <InputField isRequired={true} name={"email"} type={"email"} inputValue={username}
                            setValue={setUsername} page={page} placeholder={'Email *'}
                            setEmailOk={setEmailOk}/>
            </div>
                <DatePicker
                wrapperClassName={"w-100 h-56 " + (page === "settings" ? "pcx-2" : "")}
                className={"custom-input border-0 w-100 mcb-2 h-56 pcl-3 " + (page === "settings" ? "" : "form-login-input") + (birthdate && " pct-3")}
                dateFormat="P"
                placeholderText={t('app:finalize_registration.birthdate') + ' *'}
                selected={birthdate}
                onChange={(date) => setBirthdate(date)}
                maxDate={new Date()}
                showYearDropdown
                showMonthDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={80}
                />
            {birthdate &&
                <p className={"little-text-input-opti mb-0 mcl-3 mct-2 text-left " + (page === "settings" ? "pcl-2" : "")}>{t('app:finalize_registration.birthdate') + ' *'}</p>}
            {/* <InputField isRequired={true} name={"birthdate"} type={"date"} inputValue={birthdate}
                            setValue={setBirthdate} page={page} isDate={true} placeholder={t('app:finalize_registration.birthdate')+' *'}/>*/}

                <InputField isRequired={true} name={"city"} type={"text"} inputValue={city}
                setValue={setCity} page={page}
                placeholder={t('app:finalize_registration.city') + ' *'}/>

                <div className={page === "settings" ? "row mcl-2 mcr-2 mcb-3" : ""}>
                <CustomSelect
                className={"w-100 text-left edit-profil-country " + (page === "settings" ? "" : "edit-profil-register")}
                name="country"
                options={countries}
                defaultValue={country}
                value={country}
                onChange={(value) => onChangeCountries(value)}
                />
                <p className="little-text-input-opti mb-0 mcl-3 mct-2 text-left">{t('app:finalize_registration.country') + ' *'}</p>
                </div>

                <InputField isRequired={true} name={"phone"} type={"tel"} inputValue={phone}
                setValue={setPhone} page={page}
                placeholder={t('app:finalize_registration.phone') + ' *'}/>
                </>
            }
            <div>
                {page !== "settings" &&
                <>
                    <DatePicker
                        wrapperClassName={"w-100 h-56 " + (page === "settings" ? "pcx-2" : "")}
                        className={"custom-input border-0 w-100 mcb-2 h-56 pcl-3 " + (page === "settings" ? "" : "form-login-input") + (birthdate && " pct-3")}
                        dateFormat="P"
                        placeholderText={t('app:finalize_registration.birthdate') + ' *'}
                        selected={birthdate}
                        onChange={(date) => setBirthdate(date)}
                        maxDate={new Date()}
                        showYearDropdown
                        showMonthDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={80}
                    />
                    {birthdate &&
                    <p className={"little-text-input-opti mb-0 mcl-3 mct-2 text-left " + (page === "settings" ? "pcl-2" : "")}>{t('app:finalize_registration.birthdate') + ' *'}</p>}
                    {/* <InputField isRequired={true} name={"birthdate"} type={"date"} inputValue={birthdate}
                            setValue={setBirthdate} page={page} isDate={true} placeholder={t('app:finalize_registration.birthdate')+' *'}/>*/}

                    <InputField isRequired={true} name={"city"} type={"text"} inputValue={city}
                                setValue={setCity} page={page}
                                placeholder={t('app:finalize_registration.city') + ' *'}/>

                    <div className={page === "settings" ? "row mcl-2 mcr-2 mcb-3" : ""}>
                        <CustomSelect
                            className={"w-100 text-left edit-profil-country " + (page === "settings" ? "" : "edit-profil-register")}
                            name="country"
                            options={countries}
                            defaultValue={country}
                            value={country}
                            onChange={(value) => onChangeCountries(value)}
                        />
                        <p className="little-text-input-opti mb-0 mcl-3 mct-2 text-left">{t('app:finalize_registration.country') + ' *'}</p>
                    </div>

                    <InputField isRequired={true} name={"phone"} type={"tel"} inputValue={phone}
                                setValue={setPhone} page={page}
                                placeholder={t('app:finalize_registration.phone') + ' *'}/>
                </>
                }
                <div className="grey-line-2 mcy-5 mcx-2"></div>

                <p className="label-home text-left mcb-4">{t('app:finalize_registration.sex') + " *"}</p>

                <div className="row">
                    <div className="checkbox-container-login">
                        <input type="checkbox" id="checkMale" name="checkMale" onChange={changeGender}
                               checked={gender === 1}/>
                        <label className="mcl-5 label-home"
                               htmlFor="checkMale">{t('app:finalize_registration.male')}</label>
                    </div>
                    <div className="checkbox-container-login">
                        <input type="checkbox" id="checkFemale" name="checkFemale" onChange={changeGender}
                               checked={gender === 2}/>
                        <label className="checkbox-sex label-home"
                               htmlFor="checkFemale">{t('app:finalize_registration.female')}</label>
                    </div>
                </div>

                <div className="row mct-5">
                    <div className="col-6">
                        <p className="w-100 text-left label-home mcb-2">{t('app:finalize_registration.height')} :</p>
                        <CustomSelect
                            className={"w-100 text-left edit-profil-country " + (page === "settings" ? "" : "edit-profil-register")}
                            name="height"
                            options={heights}
                            defaultValue={height}
                            value={height}
                            onChange={(value) => onChangeHeight(value)}

                        />
                    </div>
                    <div className="col-6">
                        {/*<Form.Group id="formBasicWeight" className={page === "settings" ? "mcr-2 mcb-3 form-input-height" : "mcb-3 form-input-height"}>
                            <Form.Label className="w-100 text-left label-home mcb-2">{t('app:finalize_registration.weight')+' *'} :</Form.Label>
                            <Form.Control as="select" className={page === "settings" ? "background-white form-login-input pcl-3 py-0 mcr-3" : "form-login-input pcl-3 py-0 mcr-3"}
                                          name="weight"
                                          onChange={event => setWeightValue(event.target.value)}
                                          required
                            >
                                <option value="" defaultValue disabled>--- {metrics.weigth}</option>
                                {metric === MetricValues.imperial ? generateWeights(80, 350) : generateWeights(35, 150)}
                            </Form.Control>
                        </Form.Group>*/}
                        <p className="w-100 text-left label-home mcb-2">{t('app:finalize_registration.weight')} :</p>
                        <CustomSelect
                            className={"w-100 text-left edit-profil-country " + (page === "settings" ? "" : "edit-profil-register")}
                            name="weight"
                            options={weights}
                            defaultValue={weightValue}
                            value={weightValue}
                            onChange={(value) => onChangeWeight(value)}

                        />
                        {errorMessage ?
                            <div className="error-login-text mcb-3 mct-5">
                                {errorMessage}
                            </div> : <></>
                        }
                    </div>
                </div>
                <div className="row mct-5">
                    <div className="col-5">
                        <p className="w-100 text-left label-home mcb-3">{t('app:finalize_registration.sport')} :
                            *</p>
                    </div>
                    <div className="col-5">
                        <p className="w-100 text-left label-home mcb-3">{t('app:finalize_registration.level.label')} :
                            *</p>
                    </div>
                </div>

                {generateSportsDiv()}

                {sportsAvailables.length > 0 &&
                <p className="label-home d-flex align-items-center pointer" onClick={addSport}>
                    <img src={plus_circle_grey} alt="plus icon"
                         className="icon-24"/> {t('app:finalize_registration.add_sport')}
                </p>
                }
                <div className="grey-line-2 mcy-5"></div>

                <p className="text-reset text-left"></p>
                {page !== "settings" &&
                <p className="text-reset text-left mcb-5">{t('app:finalize_registration.can_edit')}</p>}


                {birthdate !== "" && city !== "" && country !== "" && phone !== "" && gender !== "" && weightValue ?
                    <Button id="login-btn mcb-3" type="submit" onClick={(event) => {
                        handleSubmit(event)
                    }}
                            className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center'>
                        <img className="mcr-2 icon-24" alt="validate icon" src={validate}/>
                        <p className="my-auto home-btn-p-text">{t('app:finalize_registration.register')}</p>
                    </Button>
                    :
                    <Button
                        className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcb-3'
                        disabled>
                        <img className="mcr-2 icon-24" alt="validate icon" src={validate}/>
                        <p className="my-auto home-btn-p-text">{t('app:finalize_registration.register')}</p>
                    </Button>}
            </div>
        </div>
    )
}
export default EditProfile