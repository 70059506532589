import React, {useContext} from 'react';
import axios from "axios";
import config from "../../api";

import {logo_garmin_connect} from "../../img/img"

import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from '../../Functions/Alert';
import {NavigationContext} from "../../Contexts";


function PreferenceGarminConnect({isGarminConnectSynced, updateIsGarminConnectSynced}) {

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    const onChangeGarminConnectSynced = () => {
        if(isGarminConnectSynced === true) {
            // Désynchroniser
            updateIsGarminConnectSynced(false)
            unsyncGarminConnect()
        }
        else {
            // Synchroniser
            updateIsGarminConnectSynced(true)
            syncGarminConnect()
        }
    }

    // Synchroniser le compte Garmin Connect
    const syncGarminConnect = async () => {
        await axios.get(
            config+"api/my_profile/sync_garmin_connect_url",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            // Rediriger l'utilisateur vers la page de synchronisation
            window.location.href=response.data
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    // Désynchroniser le compte Garmin Connect
    const unsyncGarminConnect = async () => {
        await axios.get(
            config+"api/my_profile/unsync_garmin_connect",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            updateIsGarminConnectSynced(!response.data.unsynced)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    let widthLogo = isMobile ? '160px': '90px'

    return (
        <>
            <div className="w-100 d-inline-flex align-items-center justify-content-between show-input-settings mcb-3">
                <img
                    src={logo_garmin_connect}
                    className="settings-provider-logo pcy-2 pcy-1 align-self-center"
                    style={{width: widthLogo }}
                    alt="Garmin connect logo"
                />
                <span className="toggle-switch">
                    <input type="checkbox" checked={isGarminConnectSynced}/>
                    <span className="switch" onClick={() => onChangeGarminConnectSynced()} />
                </span>
            </div>
        </>
    )
}

export default PreferenceGarminConnect;