import React, {useContext, useEffect, useState, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../../Tools/CustomHooks";
import {Button} from "react-bootstrap";
import axios from "axios";
import config from "../../../api";
import generateHeaders from "../../../Functions/generateHeaders";
import handleEndpointError from "../../../Functions/Alert";
import {arrow_left, validate, x_circle} from "../../../img/img";
import Loader from "react-loader-spinner";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {format, formatISO, isAfter, isBefore, setSeconds} from "date-fns"
import moment from "moment"

import {NavigationContext, WeekCalendarContext} from "../../../Contexts";

import AthleteAvailibilityContexts from "./AthleteAvailibilityContexts";
import {dataRecurrencyTypes, RecurrencyTypes} from "./Utils";
import SelectRecurrencyType from "../SelectRecurrencyType"



/**
 * Afficher un formulaire de création de Compte-rendu
 *
 * @param date
 */
const NewAthleteAvailability = ({date, closeAthleteAvailabilityModal}) => {
    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()

    const {updateCalendarEventToRefresh} = useContext(WeekCalendarContext)

    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)

    // Liste des disciplines
    const disciplineCodes = ["nat","velo","cap"]
    const [disciplines, setDisciplines] = useState(null)
    // Liste des contextes pour Natation
    const [swimmingContexts, setSwimmingContexts] = useState(null)
    // Liste des contextes pour Vélo
    const [cyclingContexts, setCyclingContexts] = useState(null)
    // Liste des contextes pour Course à pied
    const [runningContexts, setRunningContexts] = useState(null)

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [highlightDates, setHighlightDates] = useState([])
    const [allDay, setAllDay] = useState(false)
    const [contexts, setContexts] = useState([])
    const [isRecurrent, setIsRecurrent] = useState(false)
    const [dayName, setDayName] = useState(null)
    const [recurrencyTypes, setRecurrencyTypes] = useState(null)
    const [recurrencyType, setRecurrencyType] = useState(0)
    const [recurrencyLimitDate, setRecurrencyLimitDate] = useState(null)

    const [requiredFields, setRequiredFields] = useState({
        date: false,
        contexts: false,
        recurrency_params: true
    })

    const [errorMessage, setErrorMessage] = useState()
    const [ready, setReady] = useState(false)

    const pickerRef = useRef(null)

    useEffect(() => {
        // Liste des disciplines
        axios.get(
            config+"api/disciplines",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            let options = []

            for(let d of response.data.disciplines) {
                if(disciplineCodes.indexOf(d.code) != -1) {
                    let option = {
                        'id': d.id,
                        'label': d.name,
                        'code' : d.code
                    }
                    options.push(option)
                }
            }
            setDisciplines(options)
        })
        .catch(error => {
            handleEndpointError(error)
        })
    },[])

    useEffect(() => {
        if (isMobile && pickerRef.current !== null) {
            pickerRef.current.input.readOnly = true;
        }
    }, [isMobile, pickerRef]);

    useEffect(() => {
        onChangeStartDate(date.set('hour', 8).toDate())
        // onChangeEndDate(date.set('hour', 9).toDate())
    }, [date])


    useEffect(() => {
        if(disciplines) {
            for(let discipline of disciplines) {
                setContextsByDiscipline(discipline)
            }
        }
    }, [disciplines])


    useEffect(() => {
        if(allDay) {
            let year = parseInt(format(startDate,'y'))
            let month = parseInt(format(startDate,'M'))
            let day = parseInt(format(startDate,'d'))
            setStartDate(new Date(year, month-1, day, 0, 0))
            setEndDate(new Date(year, month-1, day, 23, 59))
        }
    }, [allDay])

    useEffect(() => {
        let isR = recurrencyType && recurrencyType.id ? true : false
        setIsRecurrent(isR)
        if(isR && recurrencyLimitDate === null) {
            let year = parseInt(format(startDate,'y'))
            let month = parseInt(format(startDate,'M'))
            let day = parseInt(format(startDate,'d'))
            let newDate = new Date(year, month-1, day+1)
            setRecurrencyLimitDate(newDate)
        }
    }, [recurrencyType, recurrencyLimitDate])


    useEffect(() => {
        if(dayName) {
            let datas = dataRecurrencyTypes(t, dayName)
            setRecurrencyTypes(datas)
        }
    }, [dayName])


    useEffect(() => {
        let options = []
        let refDate = startDate ? startDate.getDate() : null
        if(refDate && recurrencyType && recurrencyType.id === RecurrencyTypes.WEEK) {
            let numberOfValues = 12
            let ecart = 7
            options =[
                generateHighlightDates (refDate, numberOfValues, ecart)
            ]
        }
        setHighlightDates(options)
    }, [startDate, recurrencyType])

    // Vérifier les champs obligatoires
    useEffect(() => {
        let isReady = true
        for(let requiredField of Object.values(requiredFields)) {
            if(requiredField == false){
                isReady = false
                break
            }
        }
        setReady(isReady)
    }, [requiredFields])

    // Enregister disponibilité
    const postAthleteAvailability = async () => {
        const headers = generateHeaders()

        let startDateISO = formatISO(startDate)
        let endDateISO = formatISO(endDate)
        let recurrencyLimitDateYMD = recurrencyLimitDate ? format(recurrencyLimitDate, 'yyyy-MM-dd') : null

        let datas = {
            start_date: startDateISO,
            end_date: endDateISO,
            all_day: allDay,
            context_ids: contexts,
            is_recurrent: isRecurrent,
            recurrency_type: recurrencyType.id,
            recurrency_limit_date: recurrencyLimitDateYMD
        }
        if(datas.is_recurrent === false) {
            delete datas.recurrency_type
            delete datas.recurrency_limite_date
        }
        await axios
            .post(
                config+"api/athlete_availabilities/new",
                datas,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then((response) => {
                if(!isMounted.current){
                    return 0;
                }

                // Déclencher le rechargement le calendrier des disponibilité
                updateCalendarEventToRefresh("athlete-availibility-" + response.data.id)

                // Fermer le formulaire de création
                let dateYMD = format(startDate, 'yyyy-MM-dd')
                closeAthleteAvailabilityModal(dateYMD)

            })
            .catch(error => {
                handleEndpointError(error)
                if(error.response !== undefined) {
                    if (error.response.status === 400) {
                        setErrorMessage(error.response.data.errors)
                    }
                }
            })
    }


    const setContextsByDiscipline = async (discipline) => {
        await axios.get(
            config+"api/contexts?discipline_id="+discipline.id+"&only_without_parent=1",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            let options = []
            for(let c of response.data.contexts) {
                let option = {
                    'id': c.id,
                    'label': c.fullname,
                    'code' : c.code
                }
                options.push(option)
            }
            switch(discipline.code) {
                case 'nat':
                    setSwimmingContexts(options)
                    break
                case 'velo':
                    setCyclingContexts(options)
                    break
                case 'cap':
                    setRunningContexts(options)
                    break
                default:
                    break;
            }
        })
        .catch(error => {
            handleEndpointError(error)
        })

    }

    const updateRequiredFields = (action, key) => {
        let value = action == 'add' ? true : false;
        if(requiredFields[key] !== value) {
            let newRequiredFields = {...requiredFields}
            newRequiredFields[key] = value
            setRequiredFields(newRequiredFields)
        }
    }


    const onChangeStartDate = (value) => {
        setStartDate(value)
        setDayName(moment(format(value, 'yyyy-MM-dd')).format('dddd'))

        value = setSeconds(value,1)
        let sYear = parseInt(format(value,'y'))
        let sMonth = parseInt(format(value,'M'))
        let sDay = parseInt(format(value,'d'))

        let eHour = endDate ? parseInt(format(endDate,'H')) : 0
        let eMinute = endDate ? parseInt(format(endDate,'m')) : 0
        let newDate = new Date(sYear, sMonth-1, sDay, eHour, eMinute)
        if(isAfter(value, newDate)) {
            let sHour = parseInt(format(value,'H'))
            let sMinute = parseInt(format(value,'m'))
            let newHour = sHour+2
            let newMinute = sMinute
            if(sHour >= 22) {
                newHour = 23
                newMinute = 30
            }
            newDate = new Date(sYear, sMonth-1, sDay, newHour, newMinute)
        }
        setEndDate(newDate)

        if(recurrencyLimitDate && isAfter(value, recurrencyLimitDate)) {
            onChangeRecurrencyLimitDate(null)
        }

        updateRequiredFields('add', 'date')
    }

    const onChangeEndDate = (value) => {
        setEndDate(value)
        if(isBefore(value, startDate)) {
            updateRequiredFields('remove', 'date')
            onChangeStartDate(value)
        }
        else {
            updateRequiredFields('add', 'date')
        }
    }

    const onChangeAllDay = (value) => {
        setAllDay(value)
    }

    const onChangeContexts = (value) => {
        setContexts(value)
        if(value === null || value.length === 0) {
            updateRequiredFields('remove', 'contexts')
        }
        else {
            updateRequiredFields('add', 'contexts')
        }
    }

    const onChangeRecurrencyType = (value) => {
        setRecurrencyType(value)
        if(value === 0) {
            onChangeRecurrencyLimitDate(null)
        }
    }

    const onChangeRecurrencyLimitDate = (value) => {
        setRecurrencyLimitDate(value)
        if(value) {
            updateRequiredFields('add', 'recurrency_params')
        }
        else if(recurrencyType) {
            updateRequiredFields('remove', 'recurrency_params')
        }
    }



    const generateHighlightDates = (refDate, numberOfValues, ecart) => {
        return {
            "react-datepicker__day--highlighted": new Array(numberOfValues).fill().map((_, i) => {
                const d = new Date()
                d.setDate(refDate + i*ecart)
                return d
            })
        }
    }


    return (
                <div className="mcb-7">
                    <div className="modal-card pcx-4 pcy-3">
                        <div className="mcb-4">
                            {disciplines && swimmingContexts && cyclingContexts && runningContexts ?
                                <>
                                    <div className="row mcb-3">
                                        <div className="col-5">
                                            <span className="label-input-athlete-event mcb-2">Date</span>
                                            {/* Date */}
                                            <DatePicker
                                                wrapperClassName="w-100"
                                                className="custom-input w-100"
                                                dateFormat="P"
                                                selected={startDate}
                                                ref={pickerRef}
                                                onChange={(date) => onChangeStartDate(date)}
                                            />
                                        </div>
                                        {allDay === false &&
                                            <>
                                                <div className="col-3 pr-0">
                                                    {/* Heure début */}
                                                    <span className="label-input-athlete-event mcb-2">{t('app:athlete.athlete_availability.start_date')}</span>
                                                    <DatePicker
                                                        wrapperClassName="w-100"
                                                        className="custom-input w-100"
                                                        dateFormat="p"
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        selected={startDate}
                                                        timeCaption="Horaire"
                                                        onChange={(date) => onChangeStartDate(date)}
                                                    />
                                                </div>
                                                <div className="col-3 pr-0">
                                                    {/* Heure fin */}
                                                    <span className="label-input-athlete-event  mcb-2">{t('app:athlete.athlete_availability.end_date')}</span>
                                                    <DatePicker
                                                        wrapperClassName="w-100"
                                                        className="custom-input w-100"
                                                        dateFormat="p"
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        selected={endDate}
                                                        timeCaption="Horaire"
                                                        onChange={(date) => onChangeEndDate(date)}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="row mcb-3">
                                        <div className="col-12">
                                            <div className={"checkbox-container checkbox-container-debriefing conditions-options-blue mb-2 w-100"}>
                                                <input type="checkbox" id="all_day" name="all_day" checked={allDay} onChange={(e) => onChangeAllDay(e.target.checked)}/>
                                                <label htmlFor="all_day">{t('app:athlete.athlete_availability.all_day')}</label>
                                            </div>
                                            <div className="d-flex justify-content-start align-items-center">
                                                <span className="label-input-athlete-event mcr-3" style={{ width: '120px' }}>{t('app:athlete.athlete_availability.recurrency')}</span>
                                                {recurrencyTypes !== null &&
                                                <SelectRecurrencyType recurrencyTypes={recurrencyTypes}
                                                                      defaultRecurrencyType={recurrencyType}
                                                                      onChange={(value) => onChangeRecurrencyType(value)}
                                                />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {recurrencyType && recurrencyType.id > 0 &&
                                    <div className="row mcb-3">
                                        <div className="col-8">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <span className="label-input-athlete-event mcr-3" style={{ width: '150px' }}>{t('app:athlete.athlete_availability.recurrency_limit_date')}</span>
                                                    <DatePicker
                                                        wrapperClassName="w-100"
                                                        className="custom-input w-100"
                                                        dateFormat="P"
                                                        selected={recurrencyLimitDate}
                                                        highlightDates={highlightDates}
                                                        filterDate={(date) => {return date > startDate}}
                                                        onChange={(date) => onChangeRecurrencyLimitDate(date)}
                                                    />
                                                </div>
                                        </div>
                                    </div>
                                    }
                                    <hr/>
                                    <AthleteAvailibilityContexts
                                        selectedValues={contexts}
                                        disciplines={disciplines}
                                        swimmingContexts={swimmingContexts}
                                        cyclingContexts={cyclingContexts}
                                        runningContexts={runningContexts}
                                        onChange={(selectedContexts) => onChangeContexts(selectedContexts)}
                                        setReady={(value) => setReady(value)}
                                    />
                                </>
                                :
                                <Loader
                                    type="TailSpin"
                                    color="blue"
                                    height={50}
                                    width={50}
                                    className="my-1"
                                />
                            }
                            <div className="w-100 mct-5 d-flex justify-content-center">
                                {errorMessage &&
                                <div className="error-login-text mcb-3">
                                    {errorMessage}
                                </div>
                                }
                                <Button className="background-black py-2 px-3 d-flex align-items-center justify-content-center rpe-btn-text"
                                        onClick={() => postAthleteAvailability()}
                                        disabled={!ready}
                                >
                                    <img className="mr-2 icon-24" alt="valid icon" src={validate} />{t('app:athlete.saving.save')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
    )
}
export default NewAthleteAvailability;