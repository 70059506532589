import React, {useState, useEffect} from "react";
import {useTranslation} from "react-i18next";

import AthleteAvailibilityContext from "./AthleteAvailibilityContext"
import {plus_circle_grey} from "../../../img/img";


const AthleteAvailibilityContexts = ({  selectedValues,
                                        disciplines,
                                        swimmingContexts,
                                        cyclingContexts,
                                        runningContexts,
                                        onChange,
                                        setReady
}) => {
    const {t} = useTranslation(['app'])


    const [selectedContexts, setSelectedContexts] = useState(selectedValues)
    const [newContext, setNewContext] = useState(selectedValues? false : true)


    const updateSelectedContexts = (selectedContexts, index, value) => {
        let updatedSelectedContexts = [...selectedContexts]
        let founded = updatedSelectedContexts.indexOf(value)
        if(founded === -1) {
            if(index === 'new') {
                updatedSelectedContexts.push(value)
                setNewContext(false)
            }
            else {
                updatedSelectedContexts[index] = value
                setReady(true)
            }
            setSelectedContexts(updatedSelectedContexts)
        }
        else {
            if(index !== 'new' && index !== selectedContexts.length -1 && index !== founded){
                updatedSelectedContexts.splice(index,1)
                setSelectedContexts(updatedSelectedContexts)

            }
            else if(index === 'new') {
                setReady(true)
            }
        }
    }

    const deleteSelectedContexts = (selectedContexts, index) => {
        if(index === 'new') {
            setNewContext(false)
        }
        else {
            let updatedSelectedContexts = [...selectedContexts]
            updatedSelectedContexts.splice(index,1)
            setSelectedContexts(updatedSelectedContexts)
            setReady(true)
        }
    }

    useEffect(() => {
        onChange(selectedContexts)
    },[selectedContexts])

    return (
        <>
            <div className="row">
                <div className="col-6">
                    <p className="d-flex justify-content-start align-items-center">
                        {t('app:athlete.athlete_availability.disciplines')}
                    </p>
                </div>
                <div className="col-6">
                    <p className="d-flex justify-content-start align-items-center">
                        {t('app:athlete.athlete_availability.contexts')}
                    </p>
                </div>
            </div>
            {selectedContexts && selectedContexts.map((selectedContext, index) => {
                return (<AthleteAvailibilityContext
                    key={"athlete-availability-context-"+index}
                    selectedValue={selectedContext}
                    disciplines={disciplines}
                    swimmingContexts={swimmingContexts}
                    cyclingContexts={cyclingContexts}
                    runningContexts={runningContexts}
                    onDelete={() => deleteSelectedContexts(selectedContexts, index)}
                    onChange={(value) => updateSelectedContexts(selectedContexts, index, value)}
                />)
            })}
            {newContext &&
                <AthleteAvailibilityContext
                    key="new-athlete-availability-context"
                    disciplines={disciplines}
                    swimmingContexts={swimmingContexts}
                    cyclingContexts={cyclingContexts}
                    runningContexts={runningContexts}
                    onDelete={() => deleteSelectedContexts(selectedContexts, 'new')}
                    onChange={(value) => updateSelectedContexts(selectedContexts, 'new', value)}
                />
            }
            <div className="text-left link-add-element"
                 onClick={() => setNewContext(true)}
            >
                <img
                    className="icon-24 pointer right"
                    src={plus_circle_grey}
                    alt="plus icon"
                />
                Ajouter une discipline
            </div>
        </>
    )
}

export default AthleteAvailibilityContexts;