import React from 'react';
import {format as formatDate} from "date-fns";
import {useTranslation} from "react-i18next";
import {validate} from "../../img/img";
import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";

const ProgramPurchaseConfirmation = ({programDatas}) => {
    const {t} = useTranslation(["app"])
    let dateFormatted = formatDate(programDatas.start_date, 'dd/MM/yyyy')
    let dateNumber = programDatas.start_date.getDate()
    return (
        <>
            <div className="purchase-confirmation-text mct-4">
                <p>{t('confirmation_page.program.0')}<br/>
                    - {t('confirmation_page.program.1')} <span className="font-weight-bold">{programDatas.name}</span>,<br/>
                    - {t('confirmation_page.program.2')} <span className="font-weight-bold">{t('confirmation_page.program.3')}</span> {t('confirmation_page.program.4')}</p>

                <p>{t('confirmation_page.program.5')} {dateFormatted}, {t('confirmation_page.program.6')}<br/>
                    {t('confirmation_page.program.7')} <span className="font-weight-bold">{t('confirmation_page.program.8')}</span> {t('confirmation_page.program.9')}</p>

                <p>{t('confirmation_page.program.10')} {dateNumber} {t('confirmation_page.program.11')}</p>
            </div>
            <Link to={"/dashboard"} className="link-btn">
                <Button className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5'
                >
                    <img className="mcr-2 icon-24" alt="valid icon" src={validate}/>
                    <p className="my-auto home-btn-p-text">{t('confirmation_page.start')}</p>
                </Button>
            </Link>
        </>
    );
};

export default ProgramPurchaseConfirmation;
