import {Metrics} from "../../Functions/Metrics";

export const TrainingConceptCodes = {
    CRITICAL_INTENSITY: 'critical_intensity',
    CRITICAL_HEARTRATE: 'critical_heartrate'
}

export const CriticalIntensityStatistics = {
    SPEED : 'speed',
    PACE : 'pace'
}

export const getTrainingConceptsByDiscipline = (disciplineCode) => {
    let datas = []
    switch(disciplineCode) {
        case 'nat':
            datas = [
                TrainingConceptCodes.CRITICAL_INTENSITY
            ]
            break
        case 'velo':
            datas = [
                TrainingConceptCodes.CRITICAL_INTENSITY,
                TrainingConceptCodes.CRITICAL_HEARTRATE
            ]
            break
        case 'cap':
            datas = [
                TrainingConceptCodes.CRITICAL_INTENSITY,
                TrainingConceptCodes.CRITICAL_HEARTRATE
            ]
            break
        default:
            break
    }

    return datas
}

export const getCIPacingUnit = (discipline, metric, ciStatistic) => {
    const metrics = Metrics(metric)
    let unit
    switch(discipline) {
        case 'nat':
            unit = metrics.allure_nat
            break
        case 'velo':
            unit = metrics.power_velo
            break
        case 'cap':
            unit = ciStatistic === CriticalIntensityStatistics.SPEED ? metrics.speed_cap_velo : metrics.allure_cap_velo
            break
        default:
            break
    }
    return unit
}

export const getCIPacingLabel = (discipline, t, ciStatistic)  => {
    let metricLabel
    switch(discipline) {
        case 'nat':
            metricLabel = 'pace'
            break
        case 'cap':
            metricLabel = ciStatistic ? ciStatistic : 'pace'
            break
        case 'velo':
            metricLabel = 'power'
            break
        default:
            break
    }
    return t('app.training_concept.ci_pacing.'+metricLabel)
}

export const getCIPacingLabelWithUnit = (discipline, t, metric, ciStatistic)  => {
    let pacingLabel = getCIPacingLabel(discipline, t, ciStatistic)

    return pacingLabel + ' ('+ getCIPacingUnit(discipline, metric, ciStatistic) +')'
}