import moment from "moment";
import { extendMoment } from 'moment-range';

import {
    pause,
    cap_set,
    cap_unset,
    multi_set,
    multi_unset,
    nat_set,
    nat_unset, no_debrief,
    no_debrief_white, other_set, other_unset,
    velo_set,
    velo_unset,
    kayak_set, kayak_unset, muscu_set, muscu_unset, ski_set, ski_unset, vtt_set, vtt_unset,
    // competition_img, competition_img_active,
    competition_cap_img, competition_cap_img_active,
    competition_nat_img, competition_nat_img_active,
    competition_velo_img, competition_velo_img_active,
    competition_trail_img, competition_trail_img_active,
    competition_triathlon_img, competition_triathlon_img_active,
    blessure_img, vacance_img, stage_img
} from "../../img/img";
import React from "react";
import {AthleteEventTypes, Sports} from "./AthleteEvent/Utils";

// Renvoies la semaine suivante ou la semaine précédente selon la props prevOrNext, formaté si format = true
//
// currentWeek : moment object, semaine actuelle
// prevOrNext : string ['prev'|'next']
// format : boolean, si true alors renvoies la date au format 'WW'
export function getOtherWeek (currentWeek, prevOrNext, format) {
    let otherWeek = moment(currentWeek)
    switch (prevOrNext) {
        case 'prev':
            otherWeek = otherWeek.subtract(1, 'w')
            break
        case 'next':
            otherWeek = otherWeek.add(1, 'w')
            break
        default:
            break
    }
    if (format) {
        otherWeek = otherWeek.format('WW')
    }
    return otherWeek
}

// Renvoies la semaine suivante ou la semaine précédente selon la props prevOrNext, formaté si format = true
//
// currentWeek : moment object, semaine actuelle
// prevOrNext : string ['prev'|'next']
// format : boolean, si true alors renvoies la date au format 'MMMM'
export function getOtherMonth (currentWeek, prevOrNext, format) {
    let otherWeek = moment(currentWeek)
    switch (prevOrNext) {
        case 'prev':
            let lastWeekPrevMonth = otherWeek.startOf('month').subtract(1, 'week')
            otherWeek = lastWeekPrevMonth
            break
        case 'next':
            otherWeek = otherWeek.add(1, 'month').startOf('month')
            break
        default:
            break
    }
    if (format) {
        otherWeek = otherWeek.format('MMMM')
    }
    return otherWeek
}

export function getWeeksOfMonth (currentWeek) {
    let weeksOfMonth = []
    let weeksAndYear = []
    let firstDayOfMonth = moment(currentWeek).startOf('month')
    let lastDayOfMonth = moment(currentWeek).endOf('month')

    let monthRange = extendMoment(moment).range(firstDayOfMonth, lastDayOfMonth)

    for (let mday of monthRange.by('days')) {
        if (weeksOfMonth.indexOf(mday.isoWeek()) === -1) {
            weeksOfMonth.push(mday.isoWeek());

            let objWeekYear = {
                week: mday.isoWeek(),
                year: mday.isoWeekYear()
            }
            weeksAndYear.push(objWeekYear);
        }
    }

    return weeksAndYear
}

export function convertSecondsToChrono(seconds, format = 'H:mm:ss')
{
    let prefix = ''
    if(seconds >= 24 * 3600) {
        format = format.replace('H', '')
        prefix = Math.round(seconds/3600)
    }
    return prefix + moment().startOf('day').seconds(seconds).format(format)
}

export function secondsToChronoForChart (value) {
    let chronoFormat = value < 3600 ? 'mm:ss' : 'H:mm:ss'

    return convertSecondsToChrono(value, chronoFormat)
}

export const getCompetitionIcon = (sport, isActive) => {
    let icon
    switch(sport) {
        case Sports.TRIATHLON:
            icon = isActive ? competition_triathlon_img_active : competition_triathlon_img
            break
        case Sports.RUNNING:
            icon = isActive ? competition_cap_img_active : competition_cap_img
            break
        case Sports.TRAIL:
            icon = isActive ? competition_trail_img_active : competition_trail_img
            break
        case Sports.CYCLISME:
            icon = isActive ? competition_velo_img_active : competition_velo_img
            break
        default:
            icon = isActive ? competition_nat_img_active: competition_nat_img
            break
    }

    return icon
}

export const iconAthleteEvent = (type, isActive, sport) => {
    let icon
    let iconClass = 'my-auto icon-24'

    switch(type) {
        case AthleteEventTypes.COMPETITION:

            icon = getCompetitionIcon(sport, isActive)
            iconClass = 'compet-img'
            break;
        case AthleteEventTypes.STAGE:
            icon = stage_img
            break;
        case AthleteEventTypes.VACANCE:
            icon = vacance_img
            break;
        case AthleteEventTypes.BLESSURE:
            icon = blessure_img
            break;
    }

    return (
        <img
            src={icon}
            alt={"athlete_event icon"}
            className={iconClass}
        />
    )
}

export const iconDiscipline = (set, disciplines, mini = false) => {
    let icon
    if(disciplines === null || disciplines.length === 0) {
        icon = pause
    }
    else if (disciplines.length > 1) {
        icon = set ? multi_set : multi_unset
    } else {
        switch (disciplines[0]) {
            case 'velo':
                icon = set ? velo_set : velo_unset
                break
            case 'cap':
            case 'trail':
                icon = set ? cap_set : cap_unset
                break
            case 'nat':
                icon = set ? nat_set : nat_unset
                break
            case 'kayak':
                icon = set ? kayak_set : kayak_unset
                break;
            case 'muscu':
            case 'renfo':
                icon = set ? muscu_set : muscu_unset
                break;
            case 'ski-fond':
            case 'ski-alpin':
            case 'ski-rando':
                icon = set ? ski_set : ski_unset
                break;
            case 'vtt':
                icon = set ? vtt_set : vtt_unset
                break;
            default:
                icon = set ? other_set : other_unset
                break
        }
    }

    return (
        <img
            src={icon}
            alt={disciplines[0]+" icon"}
            className={"my-auto " + (mini ? "icon-12":"icon-24") }
        />
    )
}

export const iconNoDebrief = (disciplines) => {
    return (
        <img
            src={disciplines.length <= 1 && disciplines[0] !== 'velo' && disciplines[0] !== 'cap' &&
            disciplines[0] !== 'nat' ? no_debrief_white : no_debrief}
            alt={disciplines.length <= 1 && disciplines[0] !== 'velo' && disciplines[0] !== 'cap' &&
            disciplines[0] !== 'nat' ? "no debriefing white icon" : "no debriefing icon"}
            className="no-debrief"
        />
    )
}
