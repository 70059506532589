import React, {useEffect, useState, useContext} from 'react';
import {useTranslation} from "react-i18next";
import {useIsMounted} from "../Tools/CustomHooks";
import '../../Css/Settings.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from '../../Functions/Alert';
import Sponsorship from "./Sponsorship";
import BankCardModal from "./BankCardModal";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import {LoggedInContext} from "../../Contexts";
import {getVerboseDate} from "../../Functions/DatesCalendar";
import moment from "moment/moment";

function Subscriptions({isSponsorshipOpen}) {

    const {t} = useTranslation(['app'])
    const isMounted = useIsMounted()

    const {is_training_20, coach_id} = useContext(LoggedInContext)

    const [subscriptionDetails, setSubscriptionDetails] = useState([])
    const [accountPayment, setAccountPayment] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [couponMessage, setCouponMessage] = useState(null)
    const [subscriptionTotal, setSubscriptionTotal] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [isCancellable, setIsCancellable] = useState(false)
    const [cancelAt, setCancelAt] = useState(null)

    // Etat d'ouverture de la popup carte bancaire
    const [cancelSubscriptionOpen, setCancelSubscriptionOpen] = useState(null)

    // Etat d'ouverture de la popup carte bancaire
    const [bankCardOpen, setBankCardOpen] = useState(null)

    const showSponsorship = 1

    useEffect( () => {
        const getSubscriptionsDetails = async () => {
            await axios.get(
                config+"api/my_profile/subscription_details",
                {
                    headers: {
                        'X-WSSE': generateHeaders()
                    }
                },
            ).then((res) => {
                if(!isMounted.current){
                    return 0;
                }
                let dataSubscription = res.data.subscription_items
                setSubscriptionDetails(dataSubscription)
                if(dataSubscription && dataSubscription.length > 0){
                    let amount = (res.data.last_invoice.total/100).toFixed(1)
                    let totalcurrency = dataSubscription[0].currency_symbol
                    let totalRecurring = dataSubscription[0].recurring_label

                    setSubscriptionTotal({
                        amount: amount,
                        currency_symbol: totalcurrency,
                        recurring_label: totalRecurring
                    })
                }
                if(res.data.coupon) {
                    setCouponMessage(res.data.coupon.message)
                }
            }).catch(error => {
                handleEndpointError(error)
            })
        }
        getSubscriptionsDetails()
    }, []);


    useEffect( () => {
        getAccountPayment()
    }, []);

    const getAccountPayment = async () => {
        await axios.get(
            config+"api/my_profile/customer_account",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((res) => {
            if(!isMounted.current){
                return 0;
            }
            let dataCustomerAccount = res.data.stripe_customer
            setAccountPayment(dataCustomerAccount)
            setPaymentMethod(dataCustomerAccount ? dataCustomerAccount.card : null)

            let currentSubscription = dataCustomerAccount ? dataCustomerAccount.current_subscription : null
            if(currentSubscription) {
                // L'abonnement est-il annulé ?
                if(currentSubscription.cancel_at_period_end) {
                    setCancelAt(getVerboseDate(currentSubscription.cancel_at))
                    setIsCancellable(false)
                    setEndDate(null)
                }
                // L'abonnement est-il annulable ?
                else if(currentSubscription.items.length) {
                    // Calculer la date de fin du mois
                    let now = moment()
                    let endOfMonthDate = moment(currentSubscription.created)
                    endOfMonthDate.set('year', now.format('YYYY'))
                    endOfMonthDate.set('month', now.month())
                    if(endOfMonthDate <= now) {
                        endOfMonthDate.add(1, 'months')
                    }
                    setEndDate(endOfMonthDate.format('LL'))
                    for(let subscriptionItem of currentSubscription.items)
                    {
                        if(subscriptionItem.product.type === "subscription") {
                            setIsCancellable(true)
                            break
                        }
                    }

                }

            }

        }).catch(error => {
            handleEndpointError(error)
        })
    }

    const textAccountPayment = () => {
        let text = t('app:settings.subscription.status.no_subscribed')
        let textColorClass=""

        if(accountPayment.current_subscription) {
            switch (accountPayment.status) {
                case 0:
                    text = t('app:settings.subscription.status.no_subscribed')
                    break
                case 1:
                    text = t('app:settings.subscription.status.subscribed')
                    break
                case 2:
                    text = t('app:settings.subscription.status.refused_payment')
                    textColorClass= 'text-red';
                    break
                case 3:
                    text = t('app:settings.subscription.status.trial_end')
                    break
                case 4:
                    text = t('app:settings.subscription.status.desactivated')
                    textColorClass= 'text-red';
                    break
                case 5:
                    text = t('app:settings.subscription.status.wait_cancel')
                    break
                case 6:
                    text = t('app:settings.subscription.status.handled')
                    break
                case 7:
                    text = t('app:settings.subscription.status.incomplete')
                    textColorClass= 'text-red';
                    break
                default :
                    text = t('app:settings.subscription.status.no_payment_statut')
                    break
            }
        }

        return (
            <span className={textColorClass}>{text}</span>
        )
    }

    const updateBankCardModal = (action) => {
        switch(action) {
            case 'open' :
                setBankCardOpen(true)
                break
            case 'close':
                setBankCardOpen(false)
                break
            default:
                break
        }
    }

    const newPaymentMethod = () => {
        updateBankCardModal('open')
    }

    const updateCancelSubscriptionModal = (action) => {
        switch(action) {
            case 'open' :
                setCancelSubscriptionOpen(true)
                break
            case 'close':
                setCancelSubscriptionOpen(false)
                break
            default:
                break
        }
    }

    const openCancelSubscription = () => {
        updateCancelSubscriptionModal('open')
    }
    const closeCancelSubscription = () => {
        updateCancelSubscriptionModal('close')
    }

    const onCancelSubscriptionSuccess = () => {
        getAccountPayment()
        closeCancelSubscription()
    }

    return(
        <>
            {cancelSubscriptionOpen &&
            <CancelSubscriptionModal
                endDate={endDate}
                closeCancelSubscription={() => closeCancelSubscription()}
                onCancelSubscriptionSuccess={() => onCancelSubscriptionSuccess()}
            />
            }
            {bankCardOpen &&
            <BankCardModal
                setPaymentMethod={(value) => setPaymentMethod(value)}
                updateBankCardModal={(action) => updateBankCardModal(action)}
            />
            }
            <div className={(bankCardOpen || cancelSubscriptionOpen ?"overlay-content ":"")+"d-flex flex-column text-left px-3 py-4 col-12 offset-md-2 col-md-8 offset-lg-0 col-lg-12"}>
                <p className="title-pref">{t('app:settings.subscription.subscription')}</p>
                {subscriptionDetails && subscriptionDetails.map((item, index) =>
                    <div key={"sub-"+index} className="d-flex justify-content-between show-input-settings  pcx-3 pcy-5 mcb-3 ">
                        <span className="align-self-center align-middle fs-13">{item.quantity > 1 ?(<span
                            className="subscription-item-text font-weight-bold pcr-2">{item.quantity}x</span>)
                            : ""}{item.name}
                        </span>
                        <span className="align-self-center text-blue subscription-item-text">{item.amount}{item.currency_symbol}/{item.recurring_label}</span>
                    </div>
                )}
                {couponMessage &&
                    <div className="d-flex justify-content-between pcx-3 show-input-settings mcb-2 bg-light text-primary">
                        <span className="align-self-center fs-13">{couponMessage}</span>
                    </div>
                }

                {subscriptionDetails && subscriptionTotal ?
                    <>
                        <div className="d-flex justify-content-between pcx-3 show-input-settings mcb-2 bg-primary text-white">
                            <span className="align-self-center align-middle fs-13">{t('app:settings.subscription.total')}</span>
                            <span className="align-self-center subscription-item-text">{subscriptionTotal.amount}{subscriptionTotal.currency_symbol}/{subscriptionTotal.recurring_label}</span>
                        </div>

                        {isCancellable &&
                        <button className="mct-1 pt-0 settings-btn d-flex justify-content-end"
                                onClick={() => openCancelSubscription()}
                        >
                            {t("app:settings.subscription.cancel_subscription")}
                        </button>
                        }
                        {cancelAt &&
                        <span className="color-grey-light fs-10 text-center">Abonnement valable jusqu'au {cancelAt}</span>
                        }
                    </>
                    :
                    <div className="card-info p-3">
                        {t('app:settings.subscription.not_subscribe')}
                    </div>
                }
                <div className="row">
                    <div className="col-12">
                        <hr/>
                    </div>
                </div>
                <p className="title-pref">{t('app:settings.subscription.payment_method')}</p>
                {paymentMethod ?
                    <>
                        <div className="card-info">
                            <div className="d-flex justify-content-between  my-3 pcx-3 fs-13"><span className="align-self-center">{t('app:settings.subscription.type')}</span><span
                                className="align-self-center text-blue subscription-item-text text-uppercase fs-10">{accountPayment.card.brand}</span>
                            </div>
                            <div className="d-flex justify-content-between my-3 pcx-3 fs-13"><span className="align-self-center">{t('app:settings.subscription.card_number')}</span><span
                                className="align-self-center text-blue subscription-item-text fs-9">XXXX XXXX XXXX {accountPayment.card.last4}</span>
                            </div>
                            <div className="d-flex justify-content-between  my-3 pcx-3 fs-13"><span className="align-self-center">{t('app:settings.subscription.card_expiration')}</span><span
                                className="align-self-center text-blue subscription-item-text fs-10">{accountPayment.card.expire}</span>
                            </div>
                        </div>
                        <button className="settings-btn d-flex justify-content-end"
                                onClick={() => newPaymentMethod()}
                        >
                            {t("app:settings.subscription.change_bank_card")}
                        </button>
                    </>
                    :
                    <div className="card-info p-3 fs-13">
                        {t('app:settings.subscription.no_payment_method')}
                    </div>
                }
                <p className="title-pref mt-2">{t('app:settings.subscription.payment_statut')}</p>
                
                {accountPayment &&
                    <div className="d-flex justify-content-between show-input-settings pcx-3 pcy-3 mcb-3 ">
                        {textAccountPayment()}
                    </div>
                }
                {showSponsorship && (is_training_20 || coach_id) ?
                    <Sponsorship isSponsorshipOpen={isSponsorshipOpen}
                    />
                    :
                    <></>
                }
            </div>
        </>
    )
}
export default Subscriptions