import React, {useEffect, useState} from "react";
import {arrow_left_circle, arrow_right_circle, cap_set, nat_set, velo_set} from "../../img/img";

const TrainingConceptDiscipline = ({discipline, onChangeDiscipline, disciplines}) => {
    const [disciplineStyle, setDisciplineStyle] = useState({})

    useEffect(() => {
        setDisciplineStyle((oldStyles) => {
            let newStyles = {...oldStyles}
            switch (discipline) {
                default:
                case 'run':
                    newStyles.bcolor = "btn-outline-cap bcolor-cap"
                    newStyles.disciplineIcon = cap_set
                    break
                case 'velo':
                    newStyles.bcolor = "btn-outline-velo bcolor-velo"
                    newStyles.disciplineIcon = velo_set
                    break
                case 'nat':
                    newStyles.bcolor = "btn-outline-nat bcolor-nat"
                    newStyles.disciplineIcon = nat_set
                    break
            }
            return newStyles
        })
    }, [discipline])


    const prevDiscipline = () => {
        for (let i=0; i < disciplines.length; i++) {
            if ((disciplines[i] === discipline)) {
                if (i !== 0) {
                    onChangeDiscipline(disciplines[i-1])
                } else {
                    onChangeDiscipline(disciplines[disciplines.length-1])
                }
            }
        }
    }

    const nextDiscipline = () => {
        for (let i=0; i < disciplines.length; i++) {
            if ((disciplines[i] === discipline)) {
                if (i !== disciplines.length-1) {
                    onChangeDiscipline(disciplines[i+1])
                } else {
                    onChangeDiscipline(disciplines[0])
                }

            }
        }
    }

    return (
        <div className="training-concept-discipline d-flex flex-column align-items-center">
            <div className="w-100 d-flex justify-content-between align-items-center">
                <img src={arrow_left_circle} alt="arrow-left icon" className="grey-arrow icon-24 pointer" onClick={() => {prevDiscipline()}} />

                <div className="d-flex justify-content-center">
                    <span className={discipline && discipline === "cap" ? "navpoint cap-navpoint" : "navpoint empty-navpoint"}/>
                    <span className={discipline && discipline === "nat" ? "navpoint nat-navpoint mcx-2" : "navpoint empty-navpoint mcx-2"}/>
                    <span className={discipline && discipline === "velo" ? "navpoint velo-navpoint" : "navpoint empty-navpoint"}/>
                </div>

                <img src={arrow_right_circle} alt="arrow-right icon" className="grey-arrow icon-24 pointer" onClick={() => {nextDiscipline()}} />
            </div>
            <div className={"nav-case-discipline mct-2" + disciplineStyle.bcolor}>
                <img src={disciplineStyle.disciplineIcon} alt={discipline + " set icon"} className="icon-24"/>
            </div>
        </div>
    )
}

export default TrainingConceptDiscipline