import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavigationContext, DashboardContext, WeekCalendarContext} from "../../Contexts";
import {arrow_left, arrow_left_circle, arrow_right_circle, x_circle} from "../../img/img";
import {Element as ScrollerElement, scroller} from "react-scroll";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import moment from 'moment';

import {MetricContext} from "../../Contexts";
import {Metrics, MetricValues} from "../../Functions/Metrics";
import {WEIGHTTYPES} from "./Utils";
import Loader from 'react-loader-spinner'
import axios from "axios";
import config from "../../api";
import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from "../../Functions/Alert";

const WeightEvolution = ({openCloseWeightEvolution}) => {
    const {t} = useTranslation(['app']);
    const {isMobile} = useContext(NavigationContext)
    const {weightEvolutionAction,updateWeightEvolutionAction} = useContext(DashboardContext)

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    const activeWeekNValue = moment().format('W')
    let newWeeklyValue = 'S.'+activeWeekNValue
    const activeDay = moment().format('DD/MM')
    const activeMonth = moment().format('MMM')

    const [loaded, setLoaded] = useState(false)


    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(moment())

    const [type, setType] = useState(WEIGHTTYPES.DAILY)

    const [categories, setCategories] = useState([])
    const [weightDatas, setWeightDatas] = useState()
    const [optionsHigh, setOptionsHigh] = useState(null)

    useEffect(() => {
        if(isMobile){
            switch(weightEvolutionAction) {
                case 'show_weight_evolution':
                    // Scroll up
                    scroller.scrollTo('weightEvolutionElement', {
                        duration: 500,
                        delay: 200,
                        smooth: 'easeOutQuart',
                        offset: -400
                    })
                    const timer = setTimeout(() => updateWeightEvolutionAction(null), 2500);
                    return () => clearTimeout(timer);
                default:
                    break;
            }
        }

    }, [weightEvolutionAction])

    useEffect(() => {
        if (startDate && type) {
            getWeightEvolution()
        }
    }, [startDate])

    useEffect(() => {
        if (endDate && type) {
            let newStartDate = moment(endDate)
            switch (type) {
                case WEIGHTTYPES.WEEKLY:
                    newStartDate.subtract(3, 'months').add(1, 'days').date(1)
                    newStartDate.isoWeekday(1)
                    break
                case WEIGHTTYPES.MONTHLY:
                    newStartDate.subtract(6, 'months').add(1, 'days').date(1)
                    break
                default:
                    newStartDate.subtract(6, "days")
                    break
            }
            setStartDate(newStartDate)
        }
    }, [type])

    useEffect(() => {
        if (weightDatas) {
            let options = {
                title: {
                    text: null
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                exporting: {
                    buttons: {
                        contextButton: {
                            enabled: false
                        }
                    }
                },
                chart: {
                    height: 280,
                    spacingLeft:isMobile ? 10 : 32,
                    spacingRight:isMobile ? 10 : 64,
                    plotBackgroundColor: '#F1F8FF',
                    borderRadius: 8,
                    marginRight: isMobile ? 36 : null,
                    marginLeft: isMobile ? 36 : null
                },
                xAxis: {
                    categories: categories,
                    gridLineWidth: 0,
                    labels:{
                        formatter:function(){
                            if(this.value === activeMonth || this.value === activeDay || this.value === newWeeklyValue){
                                return '<span style="color: #007DFF">'+this.value+'</span>'
                            }
                            else {
                                return '<span style="color: #000000">'+this.value+'</span>'
                            }
                        },
                        style:{
                            fontSize:'10px',
                            fontFamily:'Gotham'
                        }
                    }
                },
                yAxis: {
                    gridLineWidth: 0,
                    title: {
                        enabled: !isMobile,
                        text:t('app:athlete.weight_summary.title')+'<br>'+ '('+metrics.weight+')',
                        align: 'high',
                        rotation: isMobile ? 270:0,
                    },
                    labels: {
                        enabled: !isMobile
                    }
                },
                plotOptions: {
                    series: {
                        labels:{
                            style:{
                                fontFamily:'Caprina'
                            },
                        },
                        dataLabels: {
                            formatter:function(){
                                if(this.x === activeMonth || this.x === activeDay || this.x === newWeeklyValue){
                                    return '<span style="color: #007DFF">'+this.y+'</span>'
                                }
                                else {
                                    return '<span style="color: #A1A6AA">'+this.y+'</span>'
                                }
                            },
                        },
                        marker: {
                            fillColor: '#A1A6AA',

                        }
                    },
                    line: {
                        dataLabels: {
                            enabled: true,
                            style:{
                                fontFamily:'Caprina',
                                color:'#A1A6AA'
                            },
                        },
                        enableMouseTracking: false,
                    }
                },
                series: [{
                    data: metric === MetricValues.international ? weightDatas.values : weightDatas.uk_values,
                    color: '#CCD4DA',
                }],
            }
            setOptionsHigh(options)
            setLoaded(true)
        }

    }, [weightDatas, categories])

    const getWeightEvolution = async () => {
        await axios.get(
            config + "api/my_weight_evolution",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                },
                params: {
                    start_date: moment(startDate).format("YYYY-MM-DD"),
                    end_date: moment(endDate).format("YYYY-MM-DD"),
                    type: type
                }
            },
        ).then((response) => {
            let datas = response.data
            let dataCategories = []
            let dataValues = []
            let dataUkValues = []
            for(let data of datas) {
                switch(type) {
                    case WEIGHTTYPES.DAILY:
                        dataCategories.push(moment(data.date).format('DD/MM'))
                        break
                    case WEIGHTTYPES.WEEKLY:
                        dataCategories.push(t('app.date.week_initial')+"."+data.week_number)
                        break
                    case WEIGHTTYPES.MONTHLY:
                        dataCategories.push(moment(data.month_number, "MM").format('MMM'))
                        break
                    default:
                        break
                }
                let weight = data.weight
                dataValues.push(weight ? weight.value : null)
                dataUkValues.push(weight ? weight.uk_value : null)
            }
            setCategories(dataCategories)

            let newWeightDatas = {
                values: dataValues,
                uk_values: dataUkValues,
            }

            setWeightDatas(newWeightDatas)

        }).catch(error => {
            handleEndpointError(error)
        })
    }

    const onChangeType = (typeToSet) => {
        setLoaded(false)
        let newType = null
        if (typeToSet === WEIGHTTYPES.DAILY){
            newType = WEIGHTTYPES.DAILY
        }
        else if (typeToSet === WEIGHTTYPES.WEEKLY) {
            newType = WEIGHTTYPES.WEEKLY
        }
        else if (typeToSet === WEIGHTTYPES.MONTHLY) {
            newType = WEIGHTTYPES.MONTHLY
        }
        setType(newType)
    }

    const handleType = (type, newEndDate, newStartDate) => {
        switch (type) {
            case WEIGHTTYPES.WEEKLY:
                // On se place sur la fin de la semaine
                if (newEndDate.isoWeekday() !== 7) {
                    newEndDate.isoWeekday(7)
                }
                if (newStartDate.isoWeekday() !== 1) {
                    newStartDate.isoWeekday(1)
                }
                break
            case WEIGHTTYPES.MONTHLY:
                // On se place sur la fin du mois
                newEndDate.endOf('month')
                newStartDate.startOf('month')
                break
            default:
                break;
        }
        return {newEndDate: newEndDate, newStartDate: newStartDate}
    }

    const onChangePeriod = (action) => {
        setLoaded(false)
        let newEndDate = moment(endDate)
        let newStartDate = moment(startDate)
        let key = 'weeks'
        let step = 1
        switch (type) {
            case WEIGHTTYPES.DAILY:
                key = 'weeks'
                break
            case WEIGHTTYPES.WEEKLY:
                step = 6
                key = 'weeks'
                break
            case WEIGHTTYPES.MONTHLY:
                step = 6
                key = 'months'
                break;
            default:
                break;
        }

        if (action == 'previous') {
            newEndDate.subtract(step, key)
            newStartDate.subtract(step, key)
        } else if (action == 'next') {
            newEndDate.add(step, key)
            newStartDate.add(step, key)
        }

        let newDates = handleType(type, newEndDate, newStartDate)
        newEndDate = newDates.newEndDate
        newStartDate = newDates.newStartDate

        setEndDate(newEndDate)
        setStartDate(newStartDate)
    }

    return (
        <div className="more-stats-card">
            <ScrollerElement name="weightEvolutionElement"
                             className="w-100 text-left"
            >
                <div className="d-flex pc-3 bg-white more-stats-header">
                    {isMobile &&
                    <img src={arrow_left} alt="arrow left" className="icon-24 mcr-2 pointer"
                         onClick={() => openCloseWeightEvolution(false)}/>
                    }
                    <p className="more-stats-header-text mb-0">
                        {t('app:athlete.weight_summary.weight_evolution.title')}
                    </p>
                    {!isMobile &&
                    <div className="position-absolute close-training-event-modal">
                        <img
                            className="icon-24 pointer"
                            src={x_circle}
                            alt="close right circle icon"
                            onClick={() => openCloseWeightEvolution(false)}
                        />
                    </div>
                    }
                </div>
                <div className={"more-stats-container mcy-3"}>
                    <div className="d-flex justify-content-center w-100 mct-2 pointer">
                        <div className={`${isMobile ? "w-100 mcx-2" : "w-50"} more-stats-select-type row`}>
                            <div className={(type === WEIGHTTYPES.DAILY ? "more-stats-type-nat" : "more-stats-type") + " col"}
                                 onClick={() => onChangeType(WEIGHTTYPES.DAILY)}
                            >
                                {t('athlete.weight_summary.weight_evolution.daily')}
                            </div>

                            <div className={(type === WEIGHTTYPES.WEEKLY ? "more-stats-type-nat" : "more-stats-type") + " col"}
                                 onClick={() => onChangeType(WEIGHTTYPES.WEEKLY)}
                            >
                                {t('athlete.weight_summary.weight_evolution.weekly')}
                            </div>

                            <div className={(type === WEIGHTTYPES.MONTHLY ? "more-stats-type-nat" : "more-stats-type") + " col"}
                                 onClick={() => onChangeType(WEIGHTTYPES.MONTHLY)}
                            >
                                {t('athlete.weight_summary.weight_evolution.mensual')}
                            </div>
                        </div>
                    </div>

                    {loaded && optionsHigh && categories ?
                        <div className="justify-content-center d-flex mt-3">
                            <div className="w-100">
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={optionsHigh}
                                />
                                <div className={`d-flex justify-content-between w-100 mcb-4 ${isMobile ? "pcx-2" : "pcx-4"} arrows-relatives`}>
                                        <span onClick={() => onChangePeriod('previous')} className="change-week">
                                            <img src={arrow_left_circle}
                                                 className="grey-arrow pointer icon-24 change-week"
                                                 alt="arrow left circle icon"
                                            />
                                        </span>
                                    <span onClick={() => onChangePeriod('next')} className="change-week">
                                            <img src={arrow_right_circle}
                                                 className="grey-arrow pointer icon-24 change-week"
                                                 alt="arrow right circle icon"
                                            />
                                        </span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="pcb-5">
                            <Loader
                                type="TailSpin"
                                color="#0069d9"
                                height={80}
                                width={80}
                                className="my-5"
                            />
                        </div>
                    }
                </div>
            </ScrollerElement>
        </div>
    )
}
export default WeightEvolution