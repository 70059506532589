import React, {useEffect, useState} from "react";
import {getContextsByDiscipline, getDataContextsByDiscipline} from "../Context/Utils";
import {useTranslation} from "react-i18next";

const TrainingZonesContexts = ({discipline, onChangeContext, context}) => {
    const [color, setColor] = useState()
    const [contexts, setContexts] = useState([])

    const {t} = useTranslation(['app'])

    // Mettre à jour les conditions lors du changement de discipline
    useEffect(() => {
        let indice = 0
        let color = 'blue'
        switch (discipline) {
            case 'nat':
                indice = 0
                color = 'blue'
                break
            case 'velo':
                indice = 1
                color = 'green'
                break
            case 'cap':
                indice = 1
                color = 'orange'
                break
            default:
                break
        }
        setColor(color)
        let newContexts = getDataContextsByDiscipline(t, discipline)
        onChangeContext(newContexts[indice])
        setContexts(newContexts)
    },[discipline])


    return (
        <div className="container pcx-2">
            <div className="monitoring-title pcx-2 pct-3">{t('app.context.title')}</div>
            <div className="zs-contexts row align-items-center pc-3">
                {contexts.length > 0 && context && contexts.map((item) => {
                    let labelStyle = item.code === context.code ? "font-weight-bold" : ""
                    return (
                        <div className="col-6 pcx-1" key={item.code}>
                            <div className={"checkbox-container-"+color+" w-100 mcy-1 mcl-2"}>
                                <input
                                    id={"context_"+discipline+"_"+item.code}
                                    type="radio"
                                    name={item.name}
                                    value={item.code}
                                    checked={item.code === context.code}
                                    onChange={() => onChangeContext(item)}
                                />
                                <label htmlFor={"context_"+discipline+"_"+item.code} className={labelStyle + " fs-12"}>{item.name}</label>
                            </div>
                        </div>
                    )
                })
                }
            </div>
        </div>
    )
}

export default TrainingZonesContexts