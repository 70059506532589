import React, {useEffect, useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import Loader from 'react-loader-spinner'
import {AnimateOnChange, easings} from 'react-animation'
import {StripeContext, LoggedInContext} from "../Contexts";
import {loadStripe} from "@stripe/stripe-js";
import handleEndpointError from "../Functions/Alert";
import axios from "axios/index";
import config from "../api";
import CartCoupon from "./CartCoupon"
import CartAuthentication from "./CartAuthentication"
import CartPayment from "./CartPayment"
import Purchase from "./Purchase"


// type : ‘subscription’ ou ‘training_program’
const Checkout = ({cart, updateCart, purchaseCart, purchaseId, purchaseAmountData, type, color}) => {

    // Traduction
    const {t} = useTranslation(['app']);

    const {user_id, username} = useContext(LoggedInContext)

    // Prise en compte du coupon indiqué dans l'url d'appel
    const [couponTmp, setCouponTmp] = useState(cart.coupon)
    const [userAuthenticated, setUserAuthenticated] = useState(null)
    const [totalPrice, setTotalPrice] = useState(null)
    const [showPayment, setShowPayment] = useState(null)
    const [showLoader, setShowLoader] = useState(null)
    const [stripePromise, setStripePromise] = useState(null)

    const stripeContextValue = {
        stripePromise
    }

    useEffect(() => {
        setStripePromise(loadStripe(""+process.env.REACT_APP_STRIPE_PUBLIC_KEY))
        // setStripePromise(loadStripe(""+process.env.REACT_APP_STRIPE_PUBLIC_KEY_TEST))
    }, [])

    useEffect(() => {
        if(cart.id) {
            let recurring = cart.recurring ? ("/"+ t('checkout.price.recurring.'+ cart.recurring)) : null
            setTotalPrice(cart.confirmation_price + cart.currency_symbol + recurring)
        }
    }, [cart])


    useEffect(() => {
        if(userAuthenticated && cart.id && user_id && username && userAuthenticated === username) {
            checkoutCart()
        }
    }, [cart, userAuthenticated, user_id, username])

    useEffect(() => {
        if(purchaseId) {
            setShowLoader(false)
        }
    }, [purchaseId])

    const checkoutCart = async () => {
        await axios.post(
            config+"public/api/cart/"+ cart.id +"/checkout",
            {
                athlete_id: user_id
            },
        )
        .then(response => {
            // Afficher le formulaire de paiement
            setShowPayment(true)
        })
        .catch(error => {
            handleEndpointError(error)
        })
    }


    const payForCart = () => {
        setShowLoader(true)
        purchaseCart()
    }

    const updateUserAuthenticated = (value) => {
        setUserAuthenticated(value)
    }


    const getTitleForType = (type) => {
        let title = null
        switch (type) {
            case 'subscription':
                title = t('subscription.title')
                break
            case 'training_program':
                title = t('training_program.title')
                break
            default:
                break
        }

        return title
    }

    // Titre de la page
    const title = getTitleForType(type)
    //
    // const style = {
    //     animation: `pop-in ${easings.easeOutExpo} 500ms forwards`
    // }
    return (
        <StripeContext.Provider value={stripeContextValue}>
            <div className={"mct-5 mx-auto w-100 d-flex flex-column align-items-center form-checkout form-checkout-"+color}>
                <h3 className="pt-2 subscription-title">{title}</h3>
                <p className={"checkout-total-amount checkout-" + color}>
                    <AnimateOnChange
                        animationIn="bounceIn"
                        animationOut="bounceOut"
                        durationOut={500}
                    >
                    {totalPrice}
                    </AnimateOnChange>
                </p>
                {showLoader ?
                    <>
                        <Loader
                            type="TailSpin"
                            color={color === 'orange' ? '#FF7A00' : '#007DFF'}
                            height={80}
                            width={80}
                            className="pct-3 m-auto"
                        />
                    </>
                    :
                    (purchaseId ?
                    <>
                        <Purchase
                            purchaseId={purchaseId}
                            purchaseAmountData={purchaseAmountData}
                        />
                    </>
                    :
                    <>
                        {cart.id &&
                        <CartCoupon
                            cart={cart}
                            couponTmp={couponTmp}
                            color={color}
                            updateCart={() => updateCart()}
                        />
                        }
                        {userAuthenticated === null &&
                            <CartAuthentication
                                cart={cart}
                                color={color}
                                updateUserAuthenticated={(email) => updateUserAuthenticated(email)}
                            />
                        }
                        {userAuthenticated !== null ?
                            (showPayment === true ?
                                <CartPayment
                                    payForCart={()=>payForCart()}
                                />
                                :
                                <Loader
                                    type="TailSpin"
                                    color='#007DFF'
                                    height={50}
                                    width={50}
                                    className="my-5"
                                />
                            )
                            :
                            <></>
                        }

                    </>
                    )
                }
            </div>
        </StripeContext.Provider>
    )
}
export default Checkout