import React from 'react';
import axios from "axios";
import config from "../../api";

import {logo_suunto} from "../../img/img"

import generateHeaders from "../../Functions/generateHeaders";
import handleEndpointError from '../../Functions/Alert';


function PreferenceSuunto({isSuuntoSynced, updateIsSuuntoSynced}) {

    const onChangeSuuntoSynced = () => {
        if(isSuuntoSynced === true) {
            // Désynchroniser
            updateIsSuuntoSynced(false)
            unsyncSuunto()
        }
        else {
            // Synchroniser
            updateIsSuuntoSynced(true)
            syncSuunto()
        }
    }

    // Synchroniser le compte Suunto
    const syncSuunto = async () => {
        await axios.get(
            config+"api/my_profile/sync_suunto_url",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            // Rediriger l'utilisateur vers la page de synchronisation
            window.location.href=response.data
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    // Désynchroniser le compte Suunto
    const unsyncSuunto = async () => {
        await axios.get(
            config+"api/my_profile/unsync_suunto",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then(response => {
            updateIsSuuntoSynced(!response.data.unsynced)
        })
            .catch(error => {
                handleEndpointError(error)
            })
    }


    return (
        <>
            <div className="w-100 d-inline-flex align-items-center justify-content-between show-input-settings mcb-3">
                <img
                    src={logo_suunto}
                    className="settings-provider-logo pcy-2"
                    alt="Suunto logo"
                />
                <span className="toggle-switch">
                    <input type="checkbox" checked={isSuuntoSynced} onChange={()=>{}}/>
                    <span className="switch"  onClick={() => onChangeSuuntoSynced()}/>
                </span>
            </div>
        </>
    )
}

export default PreferenceSuunto;