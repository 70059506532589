import React, {useEffect, useState, useContext} from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import {Container, Col, Row} from "react-bootstrap";
import { useDispatch } from "react-redux";

import clock from "../../../img/clock.png";
import chevrons_rightvelo from "../../../img/chevrons-rightvelo.png";
import heart from "../../../img/heart.png";
import heartvelo from "../../../img/heartvelo.png";
import Gain_altitude from "../../../img/Gain_altitude.png";
import sun from "../../../img/sun.png";
import natLong from "../../../img/natLong.png";
import wind from "../../../img/wind.png";
import home from "../../../img/home.png";
import square from "../../../img/square.png";
import cadenceCap from "../../../img/cadence-cap.png"
import cadenceVelo from "../../../img/cadence-velo.png"
import humidity_picto from "../../../img/Humidity.png"
import vitesse_picto from "../../../img/Vitesse.png"
import allure_picto from "../../../img/Allure.png"
import ground_contact from "../../../img/ground_contact.png"
import vertical_oscillation from "../../../img/vertical_oscillation.png"
import balance_velo from "../../../img/balance_left_right.png"
import {rpe0, rpe12, rpe34, rpe56, rpe78, rpe910} from "../../../img/img";

import '../../../Css/stats.css';


import Loader from "react-loader-spinner";
import {Metrics, MetricValues} from "../../../Functions/Metrics";
import {MetricContext, NavigationContext} from "../../../Contexts";
import {battery_charging} from "../../../img/img";
import {secondsToChronoForChart} from "../Utils";
import ReactTooltip from "react-tooltip";
import { setStats } from "../../../redux/features/share/shareSlice";


const ActivitySessionStatistic = ({t, activitySession, discipline, trainingLevel, rpe}) => {

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)
    const {isMobile} = useContext(NavigationContext)

    let activitySessionStatistic = activitySession.statistic

    const [datas, setDatas] = useState(null)
    const [rpeIcon, setRpeIcon] = useState(rpe0)

    const dispatch = useDispatch()


    useEffect(() => {

        var d = {
            distance_unit: metrics.distance_cap_velo,
            pace_unit: metrics.allure_cap_velo,
            ascent_unit: metrics.altitude,
            speed_unit : metrics.speed_cap_velo,
            temperature_unit : metrics.temperature,
            power_unit : metrics.power_velo,
            vertical_oscillation_unit: 'cm',
            ground_contact_unit: 'ms',
            balance_unit: '%',
            hrAvg: null,
            paceAvg: null,
            speedAvg: null,
            powerAvg: null,
            ppm: null,
            rpm: null,
            totalAscent: null,
            poolLength: null,
            temperature: null,
            windSpeed: null,
            humidity: null,
            verticalOscillation: null,
            balance: null,
            groundContact: null
        }

        // d.chrono = activitySessionStatistic.duration_chrono
        d.chrono = secondsToChronoForChart(activitySession.duration)
        d.distance = metric == MetricValues.international ? (activitySessionStatistic.distance/ 1000).toFixed(1) : activitySessionStatistic.distance_uk

        switch(discipline) {
            case 'cap':
            case 'trail':
                d.hrAvg = Math.round(activitySessionStatistic.h_r_avg)
                d.totalAscent = Math.round(metric == MetricValues.international ? activitySessionStatistic.total_ascent : activitySessionStatistic.total_ascent_uk)
                d.speedAvg = activitySessionStatistic.speed_avg ? (metric == MetricValues.international ?(activitySessionStatistic.speed_avg).toFixed(1) : (activitySessionStatistic.speed_avg_uk).toFixed(1)): null
                d.temperature = activitySessionStatistic.temperature ? (metric == MetricValues.international ? (activitySessionStatistic.temperature).toFixed(1) : (activitySessionStatistic.temperature_uk).toFixed(1)):null
                d.windSpeed = activitySessionStatistic.wind_speed ? (activitySessionStatistic.wind_speed).toFixed(1) : null
                d.paceAvg = metric == MetricValues.international ? activitySessionStatistic.pace_avg_string : activitySessionStatistic.pace_avg_uk
                d.ppm = Math.round(activitySessionStatistic.cadence_avg * 2)
                d.humidity = Math.round(activitySessionStatistic.humidity)
                d.groundContact = activitySessionStatistic.ground_contact ? Math.round(activitySessionStatistic.ground_contact) : null
                d.verticalOscillation = activitySessionStatistic.vertical_oscillation ? Math.round(activitySessionStatistic.vertical_oscillation) : null
                break;
            case 'velo':
            case 'vtt':
                d.hrAvg = Math.round(activitySessionStatistic.h_r_avg)
                d.totalAscent = Math.round(metric == MetricValues.international ? activitySessionStatistic.total_ascent : activitySessionStatistic.total_ascent_uk)
                d.speedAvg = activitySessionStatistic.speed_avg ? (metric == MetricValues.international ?(activitySessionStatistic.speed_avg).toFixed(1) : (activitySessionStatistic.speed_avg_uk).toFixed(1)): null
                d.temperature = activitySessionStatistic.temperature ? (metric == MetricValues.international ? (activitySessionStatistic.temperature).toFixed(1) : (activitySessionStatistic.temperature_uk).toFixed(1)):null
                d.windSpeed = activitySessionStatistic.wind_speed ? (activitySessionStatistic.wind_speed).toFixed(1) : null
                d.powerAvg = Math.round(activitySessionStatistic.power_avg)
                d.rpm = Math.round(activitySessionStatistic.cadence_avg)
                d.balance = activitySessionStatistic.balance_left && activitySessionStatistic.balance_right ?
                    (Math.round(100*activitySessionStatistic.balance_left) + " - " + Math.round(100*activitySessionStatistic.balance_right)) : null
                break
            case 'nat':
                d.distance = Math.trunc(metric == MetricValues.international ? activitySessionStatistic.distance : activitySessionStatistic.distance_uk)
                d.distance_unit = metrics.distance_nat
                d.pace_unit = metrics.allure_nat
                d.pool_unit = metrics.distance_nat
                d.rpm = Math.round(activitySessionStatistic.cadence_avg)
                d.poolLength = metric == MetricValues.international ? activitySessionStatistic.pool_length : activitySessionStatistic.pool_length_uk
                d.paceAvg = metric == MetricValues.international ? activitySessionStatistic.pace_avg_string : activitySessionStatistic.pace_avg_uk
                break
            default:
                break;
        }

        setDatas(d)

        // The following two lines are used to set share context data
        dispatch(setStats({
            ...d,
            poolLength: discipline === 'nat' && activitySessionStatistic.is_indoor === true ? d.poolLength : activitySessionStatistic.condition_string
        }))
    }, [metric])

    useEffect(() => {
        let icon
        if(rpe === null) {
            icon = rpe0
        }
        else if(rpe < 3) {
            icon = rpe12
        }
        else if(rpe < 5) {
            icon = rpe34
        }
        else if(rpe < 7) {
            icon = rpe56
        }
        else if(rpe < 9) {
            icon = rpe78
        }
        else if(rpe <= 10) {
            icon = rpe910
        }
        setRpeIcon(icon)
    },[rpe])

    if(!datas) {
        return(
            <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5" >
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={50}
                    width={50}
                    className="my-5"
                />
            </div>
        )
    }

    const getDataTip = (field) => {
        let label = t('app.activity_session_statistic.'+ field)
        return (
            <div className="fs-12 text-left">
                <p className="mb-0">{label}</p>
            </div>
        )
    }

    return (
        <>
            <Row xs={5} className="mb-2">
                {discipline == 'nat' && activitySessionStatistic.is_indoor == false ?
                    <Col className="mb-2 justify-content-center px-0  d-flex md-auto">
                        <figure>
                            <img src={home} alt="" className="icon-24"/>
                            <figcaption className="mt-2">
                                <div className="statsDescHead">{activitySessionStatistic.condition_string}</div>
                                <div className="texthead">{"\n"}</div>
                            </figcaption>
                        </figure>
                    </Col>
                    :
                    <div className="notDisplayed"/>
                }
                {discipline == 'nat' && activitySessionStatistic.is_indoor == true && datas.poolLength ?
                    <Col className="mb-2 justify-content-center px-0  d-flex md-auto"  data-for="square" data-tip={""}  data-event='click'>
                        <figure>
                            <img src={square} alt="" className="icon-24" id="square"/>
                            <figcaption className="mt-2">
                                <div className="statsDescHead">{t('app.activity_session_statistic.pool_length')}</div>
                                <div className="texthead">{"\n"}{datas.poolLength} {datas.pool_unit}</div>
                            </figcaption>
                        </figure>
                    </Col>
                    :
                    <div className="notDisplayed"/>
                }

                <Col className="mb-2 justify-content-center px-0  d-flex md-auto"  data-for="clock" data-tip={""}  data-event='click'>
                    <figure>
                        <img src={clock} alt="clock" className="icon-24" id="clock"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.chrono}</div>{"\n"}
                            <div className="hidden">m</div></figcaption>
                    </figure>
                </Col>

                <Col className="mb-2 justify-content-center px-0  d-flex md-auto" data-for="nat-long" data-tip={""}  data-event='click'>
                    <figure>
                        <img src={natLong} alt="" className="icon-24" id="nat-long"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.distance}</div>
                            <div className="texthead">{"\n"}{datas.distance_unit}</div>
                        </figcaption>
                    </figure>
                </Col>
                {datas.paceAvg !== null &&
                <Col className="mb-2 justify-content-center px-0  d-flex md-auto" data-for="look" data-tip={""}  data-event='click'>
                    <figure>
                        <img src={allure_picto} alt="clock" className="icon-24"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.paceAvg}</div>
                            <div className="texthead">{"\n"}{datas.pace_unit}</div>
                        </figcaption>
                    </figure>
                </Col>
                }
                {datas.powerAvg !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto" data-for="power" data-tip={""} data-event='click'>
                    <figure>
                        <img src={chevrons_rightvelo} alt="" className="icon-24" id="power"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.powerAvg}</div>
                            <div className="texthead">{"\n"}{datas.power_unit}</div>
                        </figcaption>
                    </figure>
                </Col>
                }
                {datas.speedAvg !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto" data-for="speed" data-tip={""} data-event='click'>
                    <figure>
                        <img src={vitesse_picto} alt="" className="icon-24" id="speed"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.speedAvg}</div>
                            <div className="texthead">{"\n"}{datas.speed_unit}</div>
                        </figcaption>
                    </figure>
                </Col>
                }
                {datas.hrAvg !== null &&
                <Col className="mb-2 justify-content-center px-0  d-flex md-auto" data-for="heart" data-tip={""} data-event='click'>
                    <figure>
                        <img src={heart} alt="clock" className="icon-24" id="heart"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.hrAvg}</div>
                            <div className="texthead">{"\n"}bpm</div>
                        </figcaption>
                    </figure>
                </Col>
                }

                {datas.ppm !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto" data-for="cadence-cap" data-tip={""} data-event='click'>
                    <figure>
                        <img src={cadenceCap} alt="" className="icon-24" id="cadence-cap"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.ppm}</div>
                            <div className="texthead">{"\n"}ppm</div>
                        </figcaption>
                    </figure>
                </Col>
                }
                {datas.rpm !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto" data-for="cadence-velo" data-tip={""} data-event='click'>
                    <figure>
                        <img src={discipline == 'nat' ? heartvelo : cadenceVelo} alt="" className="icon-24" id="cadence-velo"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.rpm}</div>
                            <div className="texthead">{"\n"}rpm</div>
                        </figcaption>
                    </figure>
                </Col>
                }
                {datas.balance !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto" data-for="balance-velo" data-tip={""}>
                    <figure>
                        <img src={balance_velo} alt="" className="icon-24" id="balance-velo"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.balance}</div>
                            <div className="texthead">{"\n"}{datas.balance_unit}</div>
                        </figcaption>
                    </figure>
                </Col>
                }
                {datas.totalAscent !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto" data-for="gain-altitude" data-tip={""} data-event='click'>
                    <figure>
                        <img src={Gain_altitude} alt="" className="icon-24" id="gain-altitude"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">+{datas.totalAscent}</div>
                            <div className="texthead">{"\n"}{datas.ascent_unit}</div>
                        </figcaption>
                    </figure>
                </Col>
                }
                {datas.verticalOscillation !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto" data-for="vertical-oscillation" data-tip={""} data-event='click'>
                    <figure>
                        <img src={vertical_oscillation} alt="" className="icon-24" id="vertical-oscillation"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.verticalOscillation}</div>
                            <div className="texthead">{"\n"}{datas.vertical_oscillation_unit}</div>
                        </figcaption>
                    </figure>
                </Col>
                }
                {datas.groundContact !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto" data-for="ground-contact" data-tip={""} data-event='click'>
                    <figure>
                        <img src={ground_contact} alt="" className="icon-24" id="ground-contact"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.groundContact}</div>
                            <div className="texthead">{"\n"}{datas.ground_contact_unit}</div>
                        </figcaption>
                    </figure>
                </Col>
                }

                {datas.windSpeed !== null &&
                <Col className="mb-2 justify-content-center px-0  d-flex md-auto" data-for="wind" data-tip={""} data-event='click'>
                    <figure>
                        <img src={wind} alt="" className="icon-24" id="wind"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.windSpeed}</div>
                            <div className="texthead">{"\n"}km/h</div>
                        </figcaption>
                    </figure>
                </Col>
                }
                {datas.humidity !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto" data-for="humidity" data-tip={""} data-event='click'>
                    <figure>
                        <img src={humidity_picto} alt="humidity" className="icon-24" id="humidity"/>
                        <figcaption className="mt-2">
                            <div className="statsDescHead">{datas.humidity}</div>
                            <div className="texthead">{"\n"}%</div>
                        </figcaption>
                    </figure>
                </Col>
                }
                {datas.temperature !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto" data-for="sun" data-tip={""} data-event='click'>
                    <figure>
                        <img src={sun} alt="clock" className="icon-24" id="sun"/>
                        <figcaption className="mt-2"><div className="statsDescHead">{datas.temperature}</div><div className="texthead">{"\n"}{datas.temperature_unit}</div></figcaption>
                    </figure>
                </Col>
                }
                {trainingLevel !== null &&
                <Col className=" justify-content-center px-0  d-flex md-auto"  data-for="battery-charging" data-tip={""} data-event='click'>
                    <figure>
                        <img src={battery_charging} alt="battery charging" className="icon-24" id="battery-charging"/>
                        <figcaption className="mt-2"><div className="statsDescHead">{trainingLevel}</div><div className="texthead">{"\n"}U</div></figcaption>
                    </figure>
                </Col>
                }
                {rpe &&
                <Col className=" justify-content-center px-0 d-flex md-auto" data-for="rpe" data-tip={""} data-event='click'>
                    <figure>
                        <img src={rpeIcon} alt="rpe" className="icon-24" id="rpe"/>
                        <figcaption className="mt-2"><div className="statsDescHead">{rpe}</div><div className="texthead">{"\n"}RPE</div></figcaption>
                    </figure>
                </Col>
                }
            </Row>
            <ReactTooltip key="tooltip-square"
                          id="square"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('pool_length')}
            />
            <ReactTooltip key="tooltip-look"
                          id="look"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('pace')}
            />
            <ReactTooltip key="tooltip-rpe"
                          id="rpe"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('rpe')}
            />
            <ReactTooltip key="tooltip-battery-charging"
                          id="battery-charging"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('training_level')}
            />
            <ReactTooltip key="tooltip-sun"
                          id="sun"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('temperature')}
            />
            <ReactTooltip key="tooltip-humidity"
                          id="humidity"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('humidity')}
            />
            <ReactTooltip key="tooltip-wind"
                          id="wind"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('wind_speed')}
            />

            <ReactTooltip key="tooltip-ground-contact"
                          id="ground-contact"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('ground_contact')}
            />
            <ReactTooltip key="tooltip-vertical-oscillation"
                          id="vertical-oscillation"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('vertical_oscillation')}
            />

            <ReactTooltip key="tooltip-gain-altitude"
                          id="gain-altitude"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('total_ascent')}
            />

            <ReactTooltip key="tooltip-balance-velo"
                          id="balance-velo"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('balance')}
            />
            <ReactTooltip key="tooltip-cadence-velo"
                          id="cadence-velo"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('cadence')}
            />
            <ReactTooltip key="tooltip-cadence-cap"
                          id="cadence-cap"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('cadence')}
            />
            <ReactTooltip key="tooltip-heart"
                          id="heart"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('heartrate')}
            />
            <ReactTooltip key="tooltip-speed"
                          id="speed"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('speed')}
            />
            <ReactTooltip key="tooltip-power"
                          id="power"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('power')}
            />
            <ReactTooltip key="tooltip-clock"
                          id="clock"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={() => getDataTip('duration')}
            />
            <ReactTooltip key="tooltip-nat-long"
                          id="nat-long"
                          type="light"
                          multiline={true}
                          textColor="#000000"
                          backgroundColor="#F5F5F5"
                          className="tooltip activity-session-stat-tooltip"
                          effect="solid"
                          place="bottom"
                          clickable={true}
                          getContent={(field) => getDataTip('distance')}
            />
        </>
    )
}

export default withRouter(withTranslation(['app'],{ wait: true })(ActivitySessionStatistic));