import React, {useContext, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Form} from "react-bootstrap";
import InputField from "./InputField";
import PasswordInput from "./PasswordInput";
import {validate, validate_blue} from "../../img/img";
import axios from "axios";
import config from "../../api";
import handleEndpointError from "../../Functions/Alert";
import {AuthContext, LanguageContext} from "../../Contexts";
import Loader from "react-loader-spinner";

const Register = ({onSuccess, switchToLogin}) => {
    const {t} = useTranslation(['app'])

    const {language} = useContext(LanguageContext)

    // Mettre à jour l'authentification (pour l'inscription)
    const {updateToken} = useContext(AuthContext)

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [name, setName] = useState("")
    const [firstname, setFirstname] = useState("")
    const [emailOk, setEmailOk] = useState(false)
    const [emailError, setEmailError] = useState(null)
    const [passwordOk, setPasswordOk] = useState(true)
    const [checkboxData, setCheckboxData] = useState(false)
    const [loaded, setLoaded] = useState(true)

    const checkDatas = (event) => {
        let checkbox = event.target
        if (checkbox.checked) {
            setCheckboxData(true)
        } else {
            setCheckboxData(false)
        }
    }

    const onChangeEmail = (value) => {
        setEmail(value)
    }

    const onChangeFirstname = (value) => {
        setFirstname(value)
    }

    const onChangeName = (value) => {
        setName(value)
    }

    const onChangePassword = (value) => {
        setPassword(value)
    }

    const onSubmit = async () => {
        // event.preventDefault()

        await axios.post(
            config+"public/api/register",
            {
                username: email,
                plain_password : password,
                firstname : firstname,
                lastname :name,
                locale: language === "fr" ? 2 : 1
            },
        )
            .then(response => {
                if (response.status === 200) {
                    let token = response.data["secret"]

                    localStorage.setItem("token", token)
                    localStorage.setItem("username", email)
                    updateToken(token)

                    onSuccess(email)
                    setLoaded(true)
                }

            })
            .catch(error => {
                console.log(error.response)
                if (error.response.status === 400) {
                    setEmailError(error.response.data["errors"].email)
                }
                handleEndpointError(error)
                setLoaded(true)
            })

    }

    return (
        <>
            {loaded ?
                <>
                    <Form>
                        <InputField
                            type={"text"}
                            name={"firstname"}
                            isRequired={true}
                            placeholder={t('app:register.firstname')+" *"}
                            inputValue={firstname}
                            setValue={(value) => onChangeFirstname(value)}
                        />
                        <InputField
                            type={"text"}
                            name={"name"}
                            isRequired={true}
                            placeholder={t('app:register.name')+" *"}
                            inputValue={name}
                            setValue={(value) => onChangeName(value)}
                        />
                        <InputField
                            name={"email"}
                            isRequired={true}
                            type={"email"}
                            placeholder={"Email *"}
                            inputValue={email}
                            setValue={(value) => onChangeEmail(value)}
                            setEmailOk={setEmailOk}
                        />
                        {emailError &&
                        <p className="mb-0 mcl-1 text-left error-text-input">{emailError}</p>
                        }
                        <PasswordInput password={password}
                                       onChangePassword={(value) => onChangePassword(value)}
                                       setPasswordOk={setPasswordOk}
                                       toCheck={true}
                        />

                        <p className="required_info mct-4 text-left">{t('app:register.required_info')}</p>
                        <label className="container-checkbox-simple">
                            <input type="checkbox" onChange={checkDatas}/>
                            <span className="checkmark-checkbox"></span>
                            <p className="accept_datas mct-3 text-left">{t('app:register.accept_datas')}</p>
                        </label>

                    </Form>
                    <div id="block-btn" className="flex-column">
                        {emailOk && passwordOk && name !== "" && firstname !== "" && checkboxData ?
                            <Button id="login-btn"
                                // type="submit"
                                    className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5'
                                    onClick={(event) => {
                                        setLoaded(false)
                                        onSubmit(event)
                                    }}
                            >
                                <img className="mcr-2 icon-24" alt="valid icon" src={validate}/>
                                <p className="my-auto home-btn-p-text">{t('app:register.btn')}</p>
                            </Button>
                            :
                            <Button id="login-btn"
                                    className='mx-auto home-btn home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcy-5'
                                    disabled
                            >
                                <img className="mcr-2 icon-24" alt="valid icon" src={validate}/>
                                <p className="my-auto home-btn-p-text">{t('app:register.btn')}</p>
                            </Button>
                        }

                        <div className="row d-flex justify-content-center align-items-center mcy-2">
                            <div className="grey-line mcr-2"></div>
                            <p className="mb-0 grey-line-text">{t('app:login.or')}</p>
                            <div className="grey-line mcl-2"></div>
                        </div>
                        <p className="grey-line-text mcb-2 mct-2">{t('app:register.have_account')}</p>
                        <Button id="register-btn"
                                className='link-btn mx-auto home-btn-white home-btn-text pcx-3 pcy-2 d-flex align-items-center justify-content-center mcb-2'
                                onClick={() => switchToLogin()}
                        >
                            <img className="mcr-2 icon-24" alt="valid icon" src={validate_blue} /><p className="my-auto home-btn-p-text">{t('app:login.btn')}</p>
                        </Button>
                    </div>
                </>
                :
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={50}
                    width={50}
                    className="my-5"
                />
            }

        </>
    )
}

export default Register
