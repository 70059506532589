import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {arrow_left,x_circle} from "../../../img/img";
import "react-datepicker/dist/react-datepicker.css"
import {NavigationContext, WeekCalendarContext} from "../../../Contexts";
import EditAthleteAvailability from "./EditAthleteAvailability";
import NewAthleteAvailability from "./NewAthleteAvailability";

const AthleteAvailabilityModal = ({date, closeAthleteAvailabilityModal}) => {
    const {t} = useTranslation(['app'])

    const {athleteAvailabilityEventHandled} = useContext(WeekCalendarContext)

    // Résolution pour mobile
    const {isMobile} = useContext(NavigationContext)


    return (
        <div className={!isMobile ? "col-xl-4 p-3 mb-4 card-float-dashboard" : undefined}>
            <div id="alert-card-header" className="dashboard-card background-white">
                <div className={"d-flex pct-3 pcx-3 "+ (isMobile ? 'justify-content-start': 'justify-content-between')}>
                    {isMobile &&
                    <img
                        className="icon-24 mcr-2 pointer"
                        src={arrow_left}
                        alt="arrow left icon"
                        onClick={() => closeAthleteAvailabilityModal()}
                    />
                    }
                    <div>
                        <p className="settings-title">
                            {athleteAvailabilityEventHandled ? t('app:athlete.athlete_availability.edit_athlete_availability'): t('app:athlete.athlete_availability.add_athlete_availability')}
                        </p>
                    </div>
                    {!isMobile &&
                    <img
                        className="icon-24 pointer right"
                        src={x_circle}
                        alt="close icon"
                        onClick={() => closeAthleteAvailabilityModal()}
                    />
                    }
                </div>
                {athleteAvailabilityEventHandled ?
                    <EditAthleteAvailability date={date} closeAthleteAvailabilityModal={closeAthleteAvailabilityModal}/>
                    :
                    <NewAthleteAvailability date={date} closeAthleteAvailabilityModal={closeAthleteAvailabilityModal}/>
                }
            </div>
        </div>
    )
}
export default AthleteAvailabilityModal;