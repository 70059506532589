import React, {useEffect, useState} from 'react';
import CustomSelect from "../Components/Tools/CustomSelect"

const TrainingProgramPhase = ({phase, phases, onChangePhase}) => {

    const [value, setValue] = useState(null)

    const onChangeValue = (value) => {
        setValue(value)
        onChangePhase(value)
    }

    useEffect(() => {
        if(value !== phase) {
            setValue(phase)
        }
    }, [phase])


    return (
        <CustomSelect
            className="w-100 text-left"
            name="training_program_phase"
            options={phases}
            value={value}
            onChange={(value) => onChangeValue(value)}
        />
    )
}

export default TrainingProgramPhase;