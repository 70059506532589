import React, {useContext} from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import moment from "moment";
import DisciplineCube from "./../Tools/DisciplineCube";
import generateHeaders from "../../Functions/generateHeaders";
import axios from "axios";
import config from "../../api";
import {createTrainingEventFromDebriefing} from "../AthleteCalendar/TrainingEvent/Utils";
import handleEndpointError from "../../Functions/Alert";
import {NavigationContext, WeekCalendarContext} from "../../Contexts";
import {format as formatDate} from "date-fns";
import {useIsMounted} from "../Tools/CustomHooks";

function NotificationMessage({t,notification,openCloseNotificationMessageList}) {
    const {isMobile} = useContext(NavigationContext)
    const {updateDayToOpen, updateTrainingEvent, updateTrainingEventOpen,updateActiveTabOpen} = useContext(WeekCalendarContext)
    const isMounted = useIsMounted()

    const isDiscipline = notification.disciplines.length>0 && (notification.disciplines[0].code === 'cap' || notification.disciplines[0].code === 'velo' || notification.disciplines[0].code === 'nat')
    const debriefingId = notification.debriefing_id
    const activitySessionId = notification.activity_session_id
    const date = moment(notification.date).format('DD/MM/YYYY '+t('app:at')+' HH[h]mm')

    let borderColor = "default-border"
    let titleColor = "color-nat"
    if (isDiscipline) {
        let discipline = notification.disciplines[0].code
        switch (discipline) {
            case 'velo':
                borderColor = "velo-border"
                titleColor = "color-velo"
                break
            case 'cap':
            default:
                borderColor = "cap-border"
                titleColor = "color-cap"
                break
        }
    }

    function showNotificationContent(string) {
        return {__html: string};
    }

    const openTrainingEventWithActivitySession = async (debriefingId, activitySessionId) => {
        const headers = generateHeaders()
        await axios
            .get(
                config+"api/debriefings/"+debriefingId,
                {
                    headers: {
                        'X-WSSE': headers
                    }
                },
            )
            .then(response => {
                if(!isMounted.current){
                    return 0;
                }
                let debriefing = response.data
                let trainingEvent = createTrainingEventFromDebriefing(debriefing, "debriefing-event-"+debriefingId)
                updateDayToOpen(formatDate(new Date(trainingEvent.date), "yyyy-MM-dd"))
                updateTrainingEvent(trainingEvent)
                updateTrainingEventOpen(true)


                // Onglet à ouvrir
                let activeTab
                if (trainingEvent.debriefing_is_complete) {
                    switch (activitySessionId) {
                        case trainingEvent.activity_session_id:
                            activeTab = 'activity_session'
                            break
                        case trainingEvent.activity_session_2_id:
                            activeTab = 'activity_session_2'
                            break
                        case trainingEvent.activity_session_3_id:
                            activeTab = 'activity_session_3'
                            break
                        default:
                            break
                    }
                }
                updateActiveTabOpen(activeTab)
                if(isMobile) {
                    openCloseNotificationMessageList()
                }

            })
            .catch(error => {
                handleEndpointError(error)
            })
    }

    return (
        <div className={"pc-3 mcb-3 mcx-3 alert-div "+borderColor}>
            <div className="d-flex align-items-center">
                <DisciplineCube discipline={isDiscipline ? notification.disciplines[0].code : notification.entity_class ? notification.entity_class : 'nothing'}/>
                <div>
                    <p className={"mcl-2 mb-0 text-left alert-title "+titleColor}>{notification.title}</p>
                    <p className={"mcl-2 mct-1 mb-0 text-left alert-hour"}>{date}</p>
                </div>
            </div>
            <div className="mct-3 alert-desc text-left" dangerouslySetInnerHTML={showNotificationContent(notification.content)}>
            </div>
            {notification.entity_class === 'ActivitySessionPerformance' || notification.entity_class === 'Activity' || notification.entity_class === 'Debriefing' ?
            <span onClick={() => openTrainingEventWithActivitySession(debriefingId,activitySessionId)} className="debriefing-event-see-session pointer">{t('app:workout.panel.session')}</span>
                :
                <></>
                }
        </div>
    )
}
export default withRouter(withTranslation(['app'],{ wait: true })(NotificationMessage))