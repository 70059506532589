import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import generateHeaders from "../../../Functions/generateHeaders";
import axios from "axios";
import config from "../../../api";
import handleEndpointError from "../../../Functions/Alert";
import {MetricContext} from "../../../Contexts";
import {Metrics} from "../../../Functions/Metrics";
import CustomSelect from "../../Tools/CustomSelect";


const DistanceDurationDebriefing = ({hour, min, distance, onChangeDuration, onChangeDistance, discipline}) => {
    const {t} = useTranslation(['app'])

    const labelOption = (label, num) => {
        return num < 10 ? '0'+num+' '+label : num+' '+label
    }

    // Valeur du réglage métrique récupéré dans le contexte
    const {metric} = useContext(MetricContext)
    const metrics = Metrics(metric)

    const forSwimming = discipline && discipline.code === 'nat'
    const labelDistance = forSwimming ? metrics.distance_nat : metrics.distance_cap_velo

    const [durationsHours, setDurationsHours] = useState(null)
    const [durationsMins, setDurationsMins] = useState(null)
    const [hourObj, setHourObj] = useState({
        id: hour,
        label: labelOption('h', hour)
    })
    const [minObj, setMinObj] = useState({
        id: min,
        label: labelOption('min', min)
    })
    const [distanceValue, setDistanceValue] = useState(null)

    useEffect(() => {
        implementSelectDuration('h', 15, hour)
        implementSelectDuration('min', 59, min)
    }, [])

    useEffect(() => {
        if(distanceValue === null && distance) {
            setDistanceValue(distance)
        }
    }, [distance])

    useEffect(() => {
        setHourObj({
            id: hour,
            label: labelOption('h', hour)
        })
    }, [hour])

    useEffect(() => {
        setMinObj({
            id: min,
            label: labelOption('min', min)
        })
    }, [min])


    const implementSelectDuration = (label, maxValue, defaultValue) => {
        let options = []
        for (let i=0; i<=maxValue; i++) {
            let number = labelOption(label, i)
            let objDistance = {
                id: i,
                label: number
            }
            options.push(objDistance)
        }
        if (label === 'h') {
            setDurationsHours(options)
        } else if (label === 'min') {
            setDurationsMins(options)
        }
    }

    const updateDistance = (value) => {
        setDistanceValue(value)
        let defaultValueConverter = forSwimming ? 1000 : 1
        onChangeDistance(value / defaultValueConverter)
    }

    // const generateSelectDistance = (maxValue, defaultValue, disciplineValue) => {
    //
    //     let options = []
    //
    //     let forSwimming  = disciplineValue && disciplineValue.code === 'nat'
    //
    //     let startValue = forSwimming ? 100 : 1
    //     let lastValue = forSwimming ? 10000: maxValue
    //     let incrementationValue = forSwimming ? 100 : 1
    //     let label = forSwimming ? metrics.distance_nat : metrics.distance_cap_velo
    //     let defaultValueConverter = forSwimming ? 1000 : 1
    //
    //     options.push(<option key={label + "-empty"} value="0" defaultValue>-</option>)
    //
    //     for (let i = startValue; i <= lastValue; i = i + incrementationValue) {
    //         let number = forSwimming ? (i + ' '+label) : labelOption(label, i)
    //         let value = i / defaultValueConverter
    //
    //         if (defaultValue === value) {
    //             options.push(<option key={label + '-' + i} value={value} selected>{number}</option>)
    //         } else {
    //             options.push(<option key={label + '-' + i} value={value}>{number}</option>)
    //         }
    //     }
    //
    //     return options
    // }

    return (
        <>
            <div className="col-3 pct-3 d-flex align-items-center">
                <p className="gotham-15-black text-left">{t('app.activity_session.timescale')}</p>
            </div>
            <div className="col-9 pct-3 d-flex align-items-center">
                {/*<Form.Group id="formBasicHour" className="mb-0 form-input-height">
                    <Form.Control as="select" className="background-white form-login-input pcl-3 py-0 mcr-3"
                                  name="hour"
                                  onChange={event => onChangeDuration('hour', event.target.value)}
                                  required
                    >
                        <option key="h-default" value={hour} defaultValue>{labelOption('h', hour)}</option>
                        {generateSelectDuration('h', 15, hour)}
                    </Form.Control>
                </Form.Group>
                <Form.Group id="formBasicMin" className="mcl-3 mb-0 form-input-height">
                    <Form.Control as="select" className="background-white form-login-input pcl-3 py-0 mcr-3"
                                  name="min"
                                  onChange={event => onChangeDuration('min', event.target.value)}
                                  required
                    >
                        <option key="min-default" value={min} defaultValue>{labelOption('min', min)}</option>
                        {generateSelectDuration('min', 59, min)}
                    </Form.Control>
                </Form.Group>*/}
                <div className={"width-duration mcr-3"}>
                    <CustomSelect
                        className={"w-100 py-0 text-left"}
                        name="hour"
                        options={durationsHours}
                        defaultValue={hourObj}
                        value={hourObj}
                        onChange={(value) => onChangeDuration('hour', value)}
                    />
                </div>

                <div className={"width-duration mcr-3"}>
                    <CustomSelect
                        className={"w-100 py-0 text-left"}
                        name="min"
                        options={durationsMins}
                        defaultValue={minObj}
                        value={minObj}
                        onChange={(value) => onChangeDuration('min', value)}
                    />
                </div>
            </div>
            {discipline && ["renfo", "muscu"].indexOf(discipline.code) !== -1 ? <></> :
                <>
                    <div className="col-3 pct-3 d-flex align-items-center">
                        <p className="gotham-15-black text-left">{t('app:athlete.debriefing.distance')}</p>
                    </div>
                    <div className="col-9 pct-3 d-flex align-items-center">
                        <Form.Group id="formBasicDistance" className="mb-0 form-input-height">
                            <Form.Control type="number"
                                          step={forSwimming ? 100 : 0.1}
                                          className="background-white form-login-input pcl-3 py-0 mcr-3"
                                          name="distance"
                                          value={distanceValue}
                                          onChange={(event) => updateDistance(event.target.value)}
                                          required
                            >
                            </Form.Control>
                            <div className="relative-div distance-unity-indicator">
                                {labelDistance}
                            </div>
                        </Form.Group>
                    </div>
                </>
            }

        </>
    )
}

export default DistanceDurationDebriefing;