import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Chart from 'react-apexcharts'
import Loader from "react-loader-spinner";

const ActivitySessionSuccessIndex = ({successScore}) => {
    const {t} = useTranslation(['app'])

    const [loading, setLoading] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [message, setMessage] = useState(null)
    const [options, setOptions] = useState({})

    useEffect(() => {
        if(loading) {
            setTimeout(()=> {
                setIsShow(true)
                setLoading(false)
            }, 2000)
        }
    }, [loading])


    useEffect(() => {
        let m_indice = "app.calendar.success_index.message."
        if(successScore <=40) {
            m_indice += 0
        }
        else if(successScore <= 70) {
            m_indice += 1
        }
        else {
            m_indice += 2
        }
        let shortMessage = t( m_indice + ".short")
        setMessage(t( m_indice + ".long"))
        setOptions({
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 150,
                animateGradually: {
                    enabled: true,
                    delay: 150
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350
                }
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    hollow: {
                        margin: 5,
                        size: "60%",
                        padding: 5
                    },
                    dataLabels: {
                        textAnchor: 'middle',
                        name: {
                            offsetY: 30,
                            show: true,
                            color: "#007DFF",
                            fontFamily: 'Gotham',
                            fontSize: "16px"
                        },
                        value: {
                            offsetY: -16,
                            fontFamily: 'Caprina',
                            color: "#007DFF",
                            fontSize: "24px",
                            fontWeight: "bold",
                            show: true
                        }
                    },
                    color: "#007DFF",
                    dropShadow: {
                        enabled: true,
                        top: 0,
                        left: 0,
                        blur: 3,
                        opacity: 0.5
                    }
                }
            },

            stroke: {
                lineCap: "round",
            },
            labels: [shortMessage]
        })
    }, [successScore])



    const onShowScore = () => {
        setLoading(true)
    }

    return (
        <div>
            <h2 className="seance-title2 text-left">{t('app.calendar.success_index.title')}</h2>
            <div className="success-index-card">
                {isShow ?
                    <div>
                        <Chart options={options}
                               series={[successScore]}
                               type="radialBar"
                               height="230"
                               width="230"
                        />
                        <div className="text-gotham fs-12 success-index-text-under">
                            {successScore+t('app.calendar.success_index.success_rate')}
                            <br/>
                            {message}
                        </div>
                    </div>
                    :
                    <>
                        {loading ?
                            <div className="w-100 row justify-content-center align-items-center d-flex mb-5 py-5">
                                <Loader
                                    type="TailSpin"
                                    color="#0069d9"
                                    height={50}
                                    width={50}
                                    className="my-5"
                                />
                            </div>
                            :
                            <>
                                <p className="text-gotham-book fs-12">{t('app.calendar.success_index.answer')}</p>
                                <div className="btn-blue-white pointer" onClick={() => onShowScore()}>
                                    {t('app.calendar.success_index.see_score')}
                                </div>
                            </>
                        }
                    </>
                }
            </div>
        </div>
    );
};

export default ActivitySessionSuccessIndex;
