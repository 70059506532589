import React, {useEffect, useState} from 'react';
import CustomSelect from "../Components/Tools/CustomSelect"

const TrainingProgramDuration = ({duration, durations, onChangeDuration}) => {

    const [value, setValue] = useState(null)

    const onChangeValue = (value) => {
        setValue(value)
        onChangeDuration(value)
    }

    useEffect(() => {
        if(value !== duration) {
            setValue(duration)
        }
    }, [duration])


    return (
        <CustomSelect
            className="w-100 text-left"
            name="training_program_duration"
            options={durations}
            value={value}
            onChange={(value) => onChangeValue(value)}
        />
    )
}

export default TrainingProgramDuration;