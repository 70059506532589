import React, {useEffect, useState} from "react";
import '../../Css/Wellness.css';

const WellnessBar = ({iconLeft, iconRight, wellState, changeWellState, keyState, changeDefaultValues, changeIsSet, bubbleOpen, changeBubbleOpen, textBubble, title}) => {

    const [pressed, setPressed] = useState(false)
    const [move, setMove] = useState(false)
    const [showBubble, setShowBubble] = useState(false)

    useEffect(() => {
        if (bubbleOpen !== keyState) {
            setShowBubble(false)
        }
    }, [bubbleOpen, keyState])

    const handleEvent = (event) => {
        switch (event.type) {
            case "touchstart":
            case "mousedown":
                setPressed(true)
                break
            case "mousemove":
            case "touchmove":
                if (pressed === true) {
                    setMove(true)
                    clickProgress(event)
                }
                break
            case "mouseup":
            case "touchend":
                setPressed(false)
                break
            case "click":
                if (!move) {
                    clickProgress(event)
                }
                setMove(false)
                break
            default:
                break
        }
    }

    const clickProgress = (e) => {
        let slide = e.type === 'touchmove' || e.type === 'mousemove'
        let target = e.target, x
        if (target.classList.contains('state-bar')){
            target = target.parentNode
        }
        let posX = target.offsetLeft;
        let width = target.offsetWidth;
        let marginLeft = document.getElementById("wellness-card").offsetLeft;

        if (e.type === "touchmove") {
            x = e.touches[0].clientX-marginLeft-posX;
        } else {
            x = e.clientX-marginLeft-posX;
        }

        x = parseInt(x/width*100,10);
        x = x<0?0:x;
        x = x>100?100:x;

        //alert('clientX : '+e.clientX+' posX : '+posX+' width : '+width+ ' x : '+x+' marginLeft : '+marginLeft)

        if (x >= 0 && x <= 10 ) {
            if (wellState === 0 && !slide) {
                changeWellState(keyState, 25)
            } else {
                changeWellState(keyState, 0)
            }
        }
        if (x > 10 && x <= 37 ) {
            if (wellState === 25 && !slide) {
                if (x>25) {
                    changeWellState(keyState, 50)
                } else {
                    changeWellState(keyState, 0)
                }
            } else {
                changeWellState(keyState, 25)
            }
        }
        if (x > 37 && x <= 62 ) {
            if (wellState === 50 && !slide) {
                if (x>50) {
                    changeWellState(keyState, 75)
                } else {
                    changeWellState(keyState, 25)

                }
            } else {
                changeWellState(keyState, 50)
            }
        }
        if (x > 62 && x <= 87 ) {
            if (wellState === 75 && !slide) {
                if (x>75) {
                    changeWellState(keyState, 100)
                } else {
                    changeWellState(keyState, 50)
                }
            } else {
                changeWellState(keyState, 75)
            }
        }
        if (x > 87 && x <= 100 ) {
            if (wellState === 100 && !slide) {
                changeWellState(keyState, 75)
            } else {
                changeWellState(keyState, 100)
            }
        }

        onShowBubble()

        changeDefaultValues()

        changeIsSet()
    }

    const onShowBubble = () => {
        setShowBubble(true)
        changeBubbleOpen(keyState)
    }

    return (
        <div className="wellness-section py-3 row mb-3 justify-content-center">
            <div className="col-3">
                <p className="my-auto wellness-label text-left">{title}</p>
            </div>
            <div className="col-1 p-0">
                <img alt="emoji" className="icon-20" src={iconLeft}/>
            </div>

            <div className="col-7 wellness-state p-0" onClick={handleEvent}
                 onMouseDown={handleEvent} onMouseUp={handleEvent} onMouseMove={handleEvent}
                 onTouchStart={handleEvent} onTouchEnd={handleEvent} onTouchMove={handleEvent}>
                <div className="state-bar" style={wellState === 100 ?
                    { width: wellState + '%', height:'42px', borderRadius: '8px' } :
                    { width: wellState + '%', height:'42px' }}>
                    {showBubble &&
                        <div id="speechBubble1" className="speech-bubble d-flex justify-content-center align-items-center"
                              style={{left: wellState + '%'}}>
                            <p className='my-1 wellness-label-state d-flex align-items-center mx-4'>{textBubble}</p>
                        </div>
                    }
                </div>
            </div>

            <div className="col-1 p-0">
                <img alt="emoji" className="icon-20" src={iconRight}/>
            </div>
        </div>

    )
}
export default WellnessBar