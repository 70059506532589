import React, {useEffect, useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import Talk from "talkjs";
import {Link} from "react-router-dom";
import "../../Css/Home.css"
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import {gutai_login_desktop} from "../../img/img";

import {LoggedInContext, NavigationContext, TalkJSContext} from "../../Contexts"
import AddConversation from "./AddConversation";

const Chat = () => {
    // Traduction
    const {t} = useTranslation(['app']);

    // Résolution pour mobile ?
    const {isMobile} = useContext(NavigationContext)

    // Informations utilisateur TalkJS
    const loggedInContextValue = useContext(LoggedInContext)
    const limitedChat = loggedInContextValue.is_chat_limited
    const [remainingCoachMessageNumber, setRemainingCoachMessageNumber] = useState(loggedInContextValue.remaining_coach_message_number);

    // Widget Talkjs
    const addConversationElement = limitedChat ?(loggedInContextValue.remaining_coach_message_number ? true : false) : true
    const [grantAddConversation, setGrantAddConversation] = useState(addConversationElement)
    const {conversationId, toggleChat} = useContext(TalkJSContext)

    const [inbox, setInbox] = useState(null)

    const decrementRemainingCoachMessageNumber = (remainingMessageNumber) => {
        if(limitedChat) {
            setRemainingCoachMessageNumber(remainingMessageNumber)
        }
    }



    const initTalkInbox = () => {
        // Promise can be `then`ed multiple times
        Talk.ready
            .then(() => {

                let chat = window.talkSession.createInbox({
                    chatTitleMode: "participants",
                    chatSubtitleMode: "subject",
                    feedConversationTitleMode: "subject",
                    messageField: {
                        enterSendsMessage: false
                    },
                    feedFilter: {
                        custom: {   }
                    }
                });

                chat.mount(document.getElementById("talkjs-container")).then(function() {
                    if(conversationId) {
                        inbox.select(conversationId)
                    }
                });
                chat.on("sendMessage", function(e) {
                    decrementRemainingCoachMessageNumber(remainingCoachMessageNumber => remainingCoachMessageNumber -1);
                });


                setInbox(chat)

            })
            .catch(e => console.error(e));
    }

    useEffect(() => {
        initTalkInbox()

        if (inbox) {
            return inbox.destroy();
        }
    }, [])

    useEffect(() => {
        if(inbox && conversationId) {
            inbox.select(conversationId)
        }
    }, [conversationId])

    useEffect(() => {
        if(limitedChat) {
            setGrantAddConversation(remainingCoachMessageNumber ? true : false)
        }
    }, [remainingCoachMessageNumber])

    //
    function getMessageNumberInfoData(remainingMessageNumber) {
        const message = remainingMessageNumber ? t("athlete.conversation.limited_chat.allowed", { NUMBER : remainingMessageNumber })
                                               : t("athlete.conversation.limited_chat.not_allowed")
        return {
            message: message,
            messageClass: remainingMessageNumber? "alert-primary" : "alert-warning"
        }
    }
    function RemainingCoachMessageNumberInfo ({remainingMessageNumber}){

        const {message, messageClass} = getMessageNumberInfoData(remainingMessageNumber)
        return (
            <div id="remainingCoachMessageNumberInfo" maxWidth="420px" margin="0 auto">
                <div className={"alert mobile-fs-10 mt-2 "+ messageClass} role="alert">
                    {message}
                </div>
            </div>
        );
    }



    return (
        <div className="talk-container" onClick={toggleChat}>
            <div className="d-flex justify-content-center w-100 align-items-center position-absolute pc-2 top-left-0">
                <Link to={"#"}>
                    <img
                        src={gutai_login_desktop}
                        className="mct-1"
                        height={50}
                        alt="Gutai logo"
                    />
                </Link>
            </div>

            <span className="close-chat" onClick={toggleChat}>&nbsp;</span>
            {!isMobile &&
                <>
                    {grantAddConversation &&
                        <AddConversation
                            className="mcy-3"
                            decrementRemainingCoachMessageNumber={() => decrementRemainingCoachMessageNumber(remainingCoachMessageNumber => remainingCoachMessageNumber -1)}
                        />
                    }
                    {limitedChat &&
                    <RemainingCoachMessageNumberInfo remainingMessageNumber={remainingCoachMessageNumber} />
                    }
                </>
            }
            <div id="talkjs-container" style={{ height: (limitedChat && isMobile ? (remainingCoachMessageNumber ? '81%':'90%') : '93%')}}>
                <Loader
                    type="TailSpin"
                    color="#0069d9"
                    height={150}
                    width={150}
                    className="my-5"
                />
            </div>
            {isMobile &&
                <>
                    {grantAddConversation &&
                    <AddConversation
                        className="mcy-2"
                        decrementRemainingCoachMessageNumber={() => decrementRemainingCoachMessageNumber(remainingCoachMessageNumber => remainingCoachMessageNumber -1)}
                    />
                    }
                    {limitedChat &&
                    <RemainingCoachMessageNumberInfo remainingMessageNumber={remainingCoachMessageNumber} />
                    }
                </>
            }
        </div>
    );
}

export default Chat