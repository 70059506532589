import React, {useState, useEffect, useContext} from "react";
import Loader from "react-loader-spinner";

import SelectDiscipline from "../SelectDiscipline";
import SelectContext from "../SelectContext";
import {trash_grey} from "../../../img/img";
import {NavigationContext} from "../../../Contexts";

const AthleteAvailibilityContext = ({   selectedValue,
                                        disciplines,
                                        swimmingContexts,
                                        cyclingContexts,
                                        runningContexts,
                                        onChange,
                                        onDelete
}) => {

    const {isMobile} = useContext(NavigationContext)

    const getDataForContextValue = (contextValue)  => {
        let dataContexts = [
            {'disciplineCode' : 'nat', 'contexts' : swimmingContexts},
            {'disciplineCode' : 'velo', 'contexts' : cyclingContexts},
            {'disciplineCode' : 'cap', 'contexts' : runningContexts},
        ]
        for(let dataContext of dataContexts) {
            for(let c of dataContext.contexts) {
                if(c.id === contextValue) {
                    for(let d of disciplines) {
                        if(d.code === dataContext.disciplineCode) {
                            return {discipline: d, context: c, contexts: dataContext.contexts}
                        }
                    }
                }
            }
        }

        return null
    }

    const getDataForDisciplineCode = (disciplineCode) => {
        let disciplineContexts = null
        switch(disciplineCode) {
            case 'nat':
                disciplineContexts = swimmingContexts
                break
            case 'velo':
                disciplineContexts = cyclingContexts
                break
            case 'cap':
                disciplineContexts = runningContexts
                break
            default:
                break;
        }
        if(disciplineContexts) {
            return {
                'context' : disciplineContexts[0],
                'contexts' : disciplineContexts
            }
        }
        return null
    }


    // Discipline sélectionnée
    // let firstDiscipline = selectedValue ? getDisciplineForContext(selectedValue)
    //                                     : (disciplines ? disciplines[0] : null)
    const [discipline, setDiscipline] =  useState(null)
    // const [defaultDiscipline, setDefaultDiscipline] =  useState(firstDiscipline)
    // Liste des conditions à afficher dans la liste
    const [contexts, setContexts] =  useState(null)
    // Condition sélectionnée
    const [context, setContext] =  useState(null)
    // const [defaultContext, setDefaultContext] =  useState(null)

    const onChangeDiscipline = (value) => {
        setContexts(null)
        setDiscipline(value)
    }

    const onChangeContext = (value) => {
        setContext(value)
        onChange(value.id)
    }


    useEffect(() => {
        if(selectedValue) {
            let dataForContextValue = getDataForContextValue(selectedValue)
            if(dataForContextValue) {
                setContexts(dataForContextValue.contexts)
                setContext(dataForContextValue.context)
                setDiscipline(dataForContextValue.discipline)
            }
        }
        else {
            let firstDiscipline = disciplines ? disciplines[0] : null
            if (firstDiscipline) {
                onChangeDiscipline(firstDiscipline)
            }
        }
    }, [selectedValue])

    useEffect(() => {
        if(discipline && !contexts) {
            let dataForDisciplineCode = getDataForDisciplineCode(discipline.code)
            if(dataForDisciplineCode) {
                setContexts(dataForDisciplineCode.contexts)
                onChangeContext(dataForDisciplineCode.context)
                // setContext(dataForDisciplineCode.context)
            }
        }
    },[discipline, contexts])


    return (
        <>
        {context ?
            <div className="row mcb-3">
                <div className="pcl-2 d-flex align-items-center">
                    <img
                        className="icon-20 pointer"
                        src={trash_grey}
                        alt="arrow left icon"
                        onClick={() => onDelete()}
                    />
                </div>
                <div className="col-5">
                    <SelectDiscipline
                        disciplines={disciplines}
                        defaultDiscipline={discipline}
                        onChange={(value) => onChangeDiscipline(value)}
                    />
                </div>
                <div className={isMobile ? "col-5" :"col-6"}>
                    <SelectContext
                        contexts={contexts}
                        defaultContext={context}
                        onChange={(value) => onChangeContext(value)}
                    />
                </div>
            </div>
            :
            <Loader
                type="TailSpin"
                color="blue"
                height={50}
                width={50}
                className="my-1"
            />

        }
        </>
    )
}

export default AthleteAvailibilityContext;