import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import generateHeaders from "../../../Functions/generateHeaders";
import axios from "axios";
import config from "../../../api";
import handleEndpointError from "../../../Functions/Alert";
import {useIsMounted} from "../../Tools/CustomHooks";
import SelectDiscipline from "../SelectDiscipline";


const DisciplineDebriefing = ({discipline, onChangeDiscipline}) => {
    const {t} = useTranslation(['app'])

    const isMounted = useIsMounted()


    // Liste de toutes les disciplines
    const [disciplines, setDisciplines] = useState(null)

    // Déclenche la récupération des disciplines au montage du composant
    useEffect(() => {
        getDisciplines()
    }, [])

    /// Récupération des disciplines via l'API
    const getDisciplines = () => {
        // Liste des disciplines
        axios.get(
            config+"api/disciplines",
            {
                headers: {
                    'X-WSSE': generateHeaders()
                }
            },
        ).then((response) => {
            if(!isMounted.current){
                return 0;
            }
            let options = []

            for(let d of response.data.disciplines) {
                if (d.code !== 'tout-reco' && d.code !== 'autre') {
                    let option = {
                        'id': d.id,
                        'label': d.name,
                        'code': d.code
                    }
                    options.push(option)
                }
            }
            setDisciplines(options)

        })
            .catch(error => {
                handleEndpointError(error)
            })
    }


    return (
        <>
            <div className="col-3 pct-2 d-flex align-items-center mcb-4">
                <p className="gotham-15-black text-left">{t('app.athlete_perf.discipline')}</p>
            </div>


            <div className="col-9 pct-2 d-flex align-items-center mcb-4">
                <SelectDiscipline disciplines={disciplines}
                                  defaultDiscipline={discipline}
                                  onChange={(value) => onChangeDiscipline(value)}
                />
            </div>
        </>
    )
}

export default DisciplineDebriefing;