import firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyAPlBjCHJIc96ksAn6bQ3_ZERBpanoBohE",
  authDomain: "gutai-174113.firebaseapp.com",
  databaseURL: "https://gutai-174113.firebaseio.com",
  projectId: "gutai-174113",
  storageBucket: "gutai-174113.appspot.com",
  messagingSenderId: "327163948653",
  appId: "1:327163948653:web:93f4a1c87c57c5eed9d964"
});

let messaging = {
  unsupported: true,
};

if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
}

export { messaging };
