import React from "react";
import {useTranslation} from "react-i18next";
import '../../Css/Wellness.css';
import {arrow_decrease, arrow_increase, arrow_stable} from "../../img/img";


const WellnessScore = ({wellnessScore}) => {
    const {t} = useTranslation(['app'])
    let barPercent = wellnessScore.percent/2
    let barStyle = barPercent === 100 ?
        { width: barPercent + '%', height:'42px', borderRadius: '8px'} :
        { width: barPercent + '%', height:'42px'}

    if (barPercent < 37.5) {
        barStyle.background = "#FF001F"
    } else if (barPercent >= 37.5 && barPercent <= 62.5) {
        barStyle.background = "#00D254"
    } else {
        barStyle.background = "#007DFF"
    }


    const getVariationIcon = () => {
        let variationIcon
        switch (wellnessScore.variation) {
            case 'increase':
                variationIcon = arrow_increase
                break
            case 'decrease':
                variationIcon = arrow_decrease
                break
            case 'stable':
            default:
                variationIcon = arrow_stable
                break
        }

        return variationIcon
    }
    return (
        <>
            <div className="wellness-section pcy-3 row mc-3 justify-content-center mx-0">
                <div className="col-4">
                    <p className="my-auto wellness-label text-left">{t('app:athlete.wellness.general_condition')}</p>
                </div>

                <div className="col-7 wellness-state p-0">
                    <div className="state-bar-score d-flex align-items-center justify-content-center" style={barStyle}>
                        {barPercent !== 25 && <span className="trait-cursor" style={{left: "25%"}}/>}
                        <span className="percent-top" style={{left: "25%"}}>50%</span>

                        <span className="triangle-cursor" style={{left: "37.5%"}}/>

                        {barPercent !== 50 && <span className="trait-cursor" style={{left: "50%"}}/>}
                        <span className="percent-top" style={{left: "50%"}}>100%</span>
                        <span className="normal-condition" style={{left: "50%"}}>{t('app:athlete.wellness.normal_condition')}</span>

                        <span className="triangle-cursor" style={{left: "62.5%"}}/>

                        {barPercent !== 75 && <span className="trait-cursor" style={{left: "75%"}}/>}
                        <span className="percent-top" style={{left: "75%"}}>150%</span>
                    </div>
                </div>

                <div className="col-1 p-0">
                    <img alt="variation" className="icon-24" src={getVariationIcon()}/>
                </div>
            </div>
            {/*<div className="wellness-comment text-left pc-2">*/}
                {/*Message d’alerte!!<br/>*/}
                {/*+ conseils à appliquer<br/>*/}
                {/*(en quelques lignes pour une lecture plus rapide. )*/}
            {/*</div>*/}
        </>
    )
}

export default WellnessScore