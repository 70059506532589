import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form} from "react-bootstrap";
import moment from "moment";
import generateHeaders from "../../../Functions/generateHeaders";
import axios from "axios";
import config from "../../../api";
import handleEndpointError from "../../../Functions/Alert";
import {useIsMounted} from "../../Tools/CustomHooks"
import CustomSelect from "../../Tools/CustomSelect";

const WorkoutDebriefing = ({onChangeWorkout, debriefingId, workoutId, discipline}) => {
    const {t} = useTranslation(['app'])
    const emptyWorkout = {
        id: 0,
        label: t('app:workout.debriefing.none')
    }

    const isMounted = useIsMounted()

    const [workouts, setWorkouts] = useState([])
    const [workout, setWorkout] = useState(null)

    // Déclenche la récupération des disciplines au montage du composant
    useEffect(() => {
        if(workoutId && workoutId !== 0) {
            getWorkout()
        } else {
            setWorkout(emptyWorkout)
        }
    }, [workoutId])

    useEffect(() => {
        getWorkouts()
    }, [workout])

    // Récupération des workouts via l'API
    const getWorkouts = () => {
        let headers = generateHeaders()
        if (headers) {
            let weekAfter = moment().add(7, 'days').format("YYYY-MM-DD")
            let weekBefore = moment().subtract(7, 'days').format("YYYY-MM-DD")
            axios
                .get(
                    config+"api/calendar?type=workout&min_date="+weekBefore+"&max_date="+weekAfter,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    if (!isMounted.current) {
                        return 0;
                    }
                    let workouts = [emptyWorkout]
                    if (response.data.workouts) {
                        // Pour chaque Workout
                        for (let o=0; o < response.data.workouts.length; o++) {
                            let dataWorkout = response.data.workouts[o]

                            // if (dataWorkout.debriefing === null) {
                            //     // On vérifie que le Workout contient la discipline du Debriefing
                            //     let counter = 0
                            //     if (discipline) {
                            //         for (let p = 0; p < dataWorkout.disciplines.length; p++) {
                            //             let disciplineWorkout = dataWorkout.disciplines[p].id
                            //             if (disciplineWorkout === discipline.id
                            //                 || discipline.code === 'vtt' && disciplineWorkout === 6
                            //                 || discipline.code === 'trail' && disciplineWorkout === 7
                            //             ) {
                            //                 counter++
                            //             }
                            //         }
                            //     }

                                // On ajoute à la liste
                                // if (counter > 0 && (!workout || workout.id !== dataWorkout.id)) {
                                let objWorkout = {
                                    id: dataWorkout.id,
                                    label: `[${moment(dataWorkout.planned_date).format('DD/MM/YYYY HH:mm')}] ${dataWorkout.name}`
                                }
                                workouts.push(objWorkout)

                                // }
                            // }
                        }

                        setWorkouts(workouts.reverse())
                    }
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    // Récupération du workout via l'API
    const getWorkout = () => {
        let headers = generateHeaders()
        if (headers) {
            axios
                .get(
                    config+"api/workouts/"+workoutId,
                    {
                        headers: {
                            'X-WSSE': headers
                        }
                    },
                )
                .then(response => {
                    if(!isMounted.current){
                        return 0;
                    }
                    let objWorkout = {
                        id: response.data.id,
                        label: `[${moment(response.data.planned_date).format('DD/MM/YYYY HH:mm')}] ${response.data.name}`
                    }
                    setWorkout(objWorkout)
                })
                .catch(error => {
                    handleEndpointError(error)
                })
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <p className="w-100 text-left text-p-cr mcb-2">{t('app:workout.debriefing.workout')} :</p>
                <CustomSelect
                    className={"select-workout pcl-3 py-0 mcr-3"}
                    name="workouts"
                    options={workouts}
                    defaultValue={workout}
                    value={workout}
                    onChange={(value) => onChangeWorkout(value)}
                />
            </div>
        </div>
    )
}

export default WorkoutDebriefing;